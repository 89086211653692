import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { REFUND_ORDER } from '../../../../../graphql/order.mutations';
import { Button, Dialog, Heading3, snackbar, Text } from '@ifood/pomodoro-components';
function HandleProcessRefund({ orderId, onCloseAction }) {
  const defaultErrorMessage = 'Não foi possível processar o reembolso'
  const defaultSuccessMessage = 'Processamento realizado com sucesso'
  const defaultWarningMessage = 'Ops, desculpe pelo transtorno, tente novamente mais tarde'
  const [attempts, setAttempts] = useState(0)
  const [refundOrder, { error, data, loading }] = useMutation(REFUND_ORDER, {
    onCompleted: async () => {
      showSnackBar(defaultSuccessMessage, "success")
      setAttempts(2)
      setTimeout(() => window.location.reload(false), 2000)
    },
    onError: _error => {
      showSnackBar((attempts >= 2 ? defaultWarningMessage : defaultErrorMessage), attempts >= 2 ? "warning" : "error")
    },
  });

  const processRefund = async () => {
    setAttempts(attempts + 1)
    await refundOrder({
      variables: {
        orderId
      },
    });
  }

  const showSnackBar = (message, variant) => {
    snackbar({
      message: message,
      variant: variant,
      timeout: 2000,
      autoClose: true,
    });
  };

  const disableSubmitButton = () => {
    return attempts >= 2 || loading
  }

  return (
    <>
      <Dialog open={true} onClose={() => !loading ? onCloseAction() : null} closeOnOverlayClick={false}>
        <Dialog.Header>
          <Heading3 my={0} variant="medium">
            Processar reembolso
          </Heading3>
        </Dialog.Header>
        <Dialog.Body>
          <Text>
            Ao confirmar o processamento, a rotina de reembolso de valores será iniciada e os valores pendentes devolvidos ao cliente, a página será recarregada ao fim do processamento.
          </Text>
        </Dialog.Body>
        <Dialog.Footer>
          <Button disabled={disableSubmitButton()} isLoading={loading} onClick={() => processRefund()} ml={10} variant="primary">
            Confirmar
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
}

export default HandleProcessRefund;
