import FormatHelper from '@helpers/FormatHelper';

const renderAddress = (objAddress, typeAddress, obs) => {
  if (objAddress) {
    const { name, neighborhood, city, state, postalCode } = objAddress;
    return (
      <div className="order-detail__card-list">
        <p className="order-detail__card-item">
          {`Endereço de ${typeAddress === 'billing' ? 'cobrança' : 'entrega/retirada'}:`}
        </p>
        {name && <p className="order-detail__card-item order-detail__card-item--strong">{name}</p>}

        <p className="order-detail__card-item">
          {`${getAddressText(objAddress) || ''}`} <br />
          {neighborhood && neighborhood} <br />
          {`${city && city} ${state && '/' + state}`} <br />
          {FormatHelper.format(postalCode && postalCode, 'cep')}
        </p>
        {obs && <p className="order-detail__card-item order-detail__card-item--strong">{`Observação: ${obs || ''}`}</p>}
      </div>
    );
  }
};

const getAddressText = objAddress => {
  if (objAddress) {
    const { address, number, complement } = objAddress;
    return `${address}, ${number} ${complement ? '- ' + complement : ''}`;
  }
};

function OrderCustomer({ customer, billingAddress, shippingAddress, isSeller, obs }) {
  return (
    <div className="order-detail__card">
      <h3 className="order-detail__card-title">Dados do cliente</h3>
      <div className="order-detail__card-list">
        <p className="order-detail__card-item">
          Nome:
          <span className="order-detail__card-value">{customer?.name}</span>
        </p>
        <p className="order-detail__card-item">
          Documento:
          <span className="order-detail__card-value">{FormatHelper.format(customer?.document, 'document')}</span>
        </p>
        <p className="order-detail__card-item">
          Inscrição Estadual:
          <span className="order-detail__card-value">{customer?.stateRegistration}</span>
        </p>
        {customer?.phone && (
          <p className="order-detail__card-item">
            Telefone: <span className="order-detail__card-value">{FormatHelper.format(customer?.phone, 'phone')}</span>
          </p>
        )}
        {customer?.mobilePhone && (
          <p className="order-detail__card-item">
            Celular:{' '}
            <span className="order-detail__card-value">
              {FormatHelper.format(customer?.mobilePhone, 'mobile-phone')}
            </span>
          </p>
        )}
        <p className="order-detail__card-item">
          Email: <span className="order-detail__card-value">{customer?.email}</span>
        </p>
        {!isSeller && (
          <p className="order-detail__card-item">
            Executivo: <span className="order-detail__card-value">{customer?.owner}</span>
          </p>
        )}
        {!isSeller && (
          <>
            <p className="order-detail__card-item">
              ID Cliente: <span className="order-detail__card-value">{customer?.customerInfo?.id}</span>
            </p>
            <p className="order-detail__card-item">
              ID Estabelecimento: <span className="order-detail__card-value">{billingAddress?.establishmentId}</span>
            </p>
          </>
        )}
        {customer?.customerInfo?.iderp === 'iFood' && (
          <p className="order-detail__card-item order-detail__card-item--strong order-detail__card-item--partner">
            Parceiro iFood
          </p>
        )}
        {customer.customerInfo?.isFraudster && (
          <p className="order-detail__card-item order-detail__card-item--strong">Fraudador</p>
        )}
      </div>
      {renderAddress(billingAddress, 'billing')}
      {renderAddress(shippingAddress, 'shipping', obs)}
    </div>
  );
}

export default OrderCustomer;
