import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Button } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

import schema from './schema';
import './style.scss';

function HeaderCarriersSearch() {
  const {
    dataSellers,
    getSellerByName,
    formSearch,
    setFormSearch,
    setPageSearch,
    roleSellerWriteRead,
    roleSellerReadOnly
  } = useCarrierFreightRangeContext();

   const optionsActive = [
     { value: true, label: 'Habilitado' },
     { value: false, label: 'Desabilitado' },
     { value: null, label: 'Todos' }
   ];

   useEffect(() => {
    if(formSearch?.active) {
      setValue('active', {
        value: formSearch?.active?.value,
        label: formSearch?.active?.label,
      })
    }
   }, []);

   const { register, handleSubmit, setValue, control } = useForm({
     resolver: yupResolver(schema),
     mode: "onSubmit",
     defaultValues: {
      active: {
        value: true,
        label: 'Habilitado'
      }
    }
   })

  const searchCarrier = data => {
    setFormSearch(data);
    setPageSearch(0);
  }

  return (
    <div className="header-customer-group header-customer-group--src header-search-carrier">
      <div className="header-customer-group__info">
        <h2 className="section-title">Transportadora</h2>
      </div>
      <NavLink to="/fornecedores/transportadoras/cadastro" className="link">Criar nova transportadora</NavLink>

      <form className="header-customer-group__form" onSubmit={handleSubmit(searchCarrier)}>
        <div className="header-customer-group__form-input">
          <OutlinedInput
            {...register('name')} 
            name="name" 
            placeholder="Nome" 
            border
          />
          {(!roleSellerWriteRead && !roleSellerReadOnly) &&
            <div className="dropdown-label-wrap">
              <Autocomplete
                id="seller"
                className="dropdown-ifood dropdown-ifood--autocomplete"
                popupIcon={<span className="dropdown-ifood__arrow" />}
                noOptionsText="Sem resultados"
                options={dataSellers?.getSellerByName || []}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lojista"
                    variant="outlined"
                  />
                )}
                onSelect={(e) => {
                  if(!e.target.value.length) {
                    setValue('sellerId');
                  }
                  getSellerByName({ variables: { name: e.target.value} })}
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setValue('sellerId', newValue.id);
                  } else {
                    setValue('sellerId', null);
                  }
                }}
              />
            </div>
          }

          <Controller
            name="active"
            control={control}
            render={({ field }) => (
            <div className="dropdown-label-wrap">
              <Dropdown
                {...field}
                options={optionsActive}
                placeholder="Estado"
                className="dropdown-ifood"
                controlClassName="dropdown-ifood__control"
                arrowClassName="dropdown-ifood__arrow"
              />
              <label className="dropdown-label">Estado</label>
            </div>
            )}
          />
        </div>

        <div className="header-customer-group__actions">
          <Button type="submit">Filtrar Transportadora</Button>
        </div>
      </form>
    </div>
  );
}

export default HeaderCarriersSearch;
