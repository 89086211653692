import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SET_SELLER_ADMIN_NOTIFICATION } from '../../../../graphql/seller.mutations';
import { Dialog, Heading2, Button } from '@ifood/pomodoro-components';

import ModalFlowAcceptstep1 from './ModalFlowAcceptstep1';
import ModalFlowAcceptstep2 from './ModalFlowAcceptstep2';
import ModalFlowAcceptstep3 from './ModalFlowAcceptstep3';

interface IModalProps {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (v: boolean) => void;
}

type TStepObjectValues = {
  title: string;
  textButton: string;
  content: JSX.Element;
};

type TStepObject = {
  default: TStepObjectValues;
  [key: number]: TStepObjectValues;
};

const initialModal = 0;
const lastModal = 2;
const stepObject: TStepObject = {
  default: {
    title: 'Agora você pode editar o peso e a quantidade de itens de um pedido!',
    textButton: 'Saiba mais',
    content: <ModalFlowAcceptstep1 />,
  },
  1: {
    title: 'Edite seu o pedido de forma mais rápida ',
    textButton: 'Próximo passo',
    content: <ModalFlowAcceptstep2 />,
  },
  2: {
    title: 'Edite seu o pedido de forma mais rápida',
    textButton: 'Entendi',
    content: <ModalFlowAcceptstep3 />,
  },
};

const ModalFlowAccept = ({ isOpen, setIsOpen }: IModalProps): JSX.Element => {
  const [updateModalFlowAccept] = useMutation(SET_SELLER_ADMIN_NOTIFICATION);
  const [stateStep, setStateStep] = useState(initialModal);
  const stepData = stepObject[stateStep] || stepObject.default;
  const handleClickStep = (step: string) => {
    setStateStep(prevState => ++prevState);

    if (step === 'remember') {
      handleUpdate(false);
    }
    if (stateStep === lastModal && step !== 'remember') {
      handleUpdate(true);
    }
  };

  const handleUpdate = (modalAdmin: boolean) => {
    setIsOpen(false);
    updateModalFlowAccept({
      variables: { modalAdmin },
    });
  };

  return (
    <Dialog open={isOpen} size={'l'}>
      <Dialog.Header>
        <Heading2 m={0} variant="medium">
          {stepData.title}
        </Heading2>
      </Dialog.Header>
      <Dialog.Body>{stepData.content}</Dialog.Body>
      <Dialog.Footer>
        <Button variant="secondary" onClick={() => handleClickStep('remember')}>
          Me lembre depois
        </Button>
        <Button ml={16} onClick={() => handleClickStep('step')}>
          {stepData.textButton}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default ModalFlowAccept;
