import { Aboyeur } from '@ifood/aboyeur';

export const SessionMenu = new Aboyeur('sessao_menu', {
	menu: {
		/**
		 * @when Sessao menu is rendered
		 */
		viewAdminMenu: () => ({
			label: 'sessao_menu',
			metadata: {},
		}),
		/**
		 * @when Click orders in menu
		 */
		clickOrdersMenu: () => ({
			label: 'click_orders_menu',
			metadata: {},
		}),
		/**
		 * @when Click catalog in menu
		 */
		clickCatalogMenu: () => ({
			label: 'click_catalog_menu',
			metadata: {},
		}),
	},
}).withMetadata({ revision: 1 });
