import { Aboyeur } from '@ifood/aboyeur';

export const SessionOrders = new Aboyeur('sessao_orders', {
	orders: {
		/**
		 * @when Sessao orders is rendered
		 */
		viewAdminOrders: () => ({
			label: 'sessao_orders',
			metadata: {},
		}),
		clickToggleLateOrders: () => ({
			label: 'click_toggle_late_orders',
			metadata: {},
		}),
		clickOrderStatus: () => ({
			label: 'click_order_status',
			metadata: {},
		}),
		clickOrderStatusImport: () => ({
			label: 'click_order_status_import',
			metadata: {},
		}),
		clickOrderStatusExport: () => ({
			label: 'click_order_status_export',
			metadata: {},
		}),
		/**
		 * @when Clicou em 'Remover produto' filtro de produtos
		 */  
		 clickButtonMultipleQuantityEdit: () => ({
			label: 'click_button_multiple_quantity_edit',
			metadata: {},
		}),
		/**
		 * @when Clicou em 'Concluir remoção' modal de edição de quantidade (rupture in lote)
		 */  
		 clickButtonMultipleQuantityConfirm: (obj) => ({
			label: 'click_button_multiple_quantity_confirm',
			metadata: obj,
		}),
		/**
		 * @when Clicou em 'Editar itens' tabela item da coluna Edição
		 */  
		clickButtonEditOrder: (obj) => ({
			label: 'click_button_edit_order',
			metadata: obj,
		}),
		/**
		 * @when Clicou em ID Pai item da tabela
		 */
		clickButtonViewOrderDetail: (obj) => ({
			label: 'click_button_view_order_detail',
			metadata: obj,
		}),
	},
}).withMetadata({ revision: 5 });
