import React, {useState, useEffect} from 'react';
import { OutlinedInput, Button , Modal } from 'ifoodshop-react-ui';
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import OutlinedInputCurrency from '../../../../../components/OutlinedInputCurrency';
import FormRegisterSubsidy from '../../../components/FormRegisterSubsidy';
import OutlinedTextarea from '../../../../../components/OutlinedTextarea';

import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import {CREATE_VOUCHER, UPDATE_VOUCHER, GET_VOUCHER} from '../../../../../graphql/voucher.queries'

import ptbr from 'date-fns/locale/pt-BR';
import './formRegisterVoucher.scss'


const FormRegisterVoucher = (props) => {
  const [modal, setModal] = useState();
  const [nameVoucher, setNameVoucher] = useState();
  const [document, setDocument] = useState();
  const [cnpj, setCnpj] = useState(false);
  const [documentValid, setDocumentValid] = useState()
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [voucherCode, setVoucherCode] = useState();
  const [voucherValue, setVoucherValue] = useState();
  const [availableValue, setAvailableValue] = useState();
  const [comment, setComment] = useState();
  const [dataSubsidy, setDataSubsidy] = useState();
  const [dataSubsidyUpdate, setDataSubsidyUpdate] = useState();
  const [selectSearch, setSelectSearch] = useState();
  const [active, setActive] = useState(true);
  const [dataVoucher, setDataVoucher] = useState(null)
  const [sellerId, setSellerId] = useState()

  const [createVoucher, { data: dataCreate }] = useMutation(CREATE_VOUCHER);
  if(dataCreate) {
    window.location.href = "/campanhas/vouchers/"
  }

  const [updateVoucherMutation, { data: dataUpdate }] = useMutation(UPDATE_VOUCHER);
  if(dataUpdate) {
    window.location.href = "/campanhas/vouchers/"
  }

  const [getVoucher, { data: dataGetVoucher, error: errorGetVoucher }] = useLazyQuery(GET_VOUCHER);
  if(dataGetVoucher && dataVoucher === null) {
    setDataVoucher(dataGetVoucher.getVoucher)
    setActive(dataGetVoucher.getVoucher.active)
    setNameVoucher(dataGetVoucher.getVoucher.name)
    setInitialDate(new Date(dataGetVoucher.getVoucher.startDate))
    setFinalDate(new Date(dataGetVoucher.getVoucher.endDate))
    setVoucherCode(dataGetVoucher.getVoucher.voucherCode)
    setVoucherValue(dataGetVoucher.getVoucher.value)
    setDataSubsidyUpdate(dataGetVoucher.getVoucher.subsidies)
    setDocument(dataGetVoucher.getVoucher.document)
    if (dataGetVoucher.getVoucher.document.length > 11) {
      setCnpj(true)
    }
    if (dataGetVoucher.getVoucher.seller){
      setSelectSearch(dataGetVoucher.getVoucher.seller.name)
      setSellerId(dataGetVoucher.getVoucher.seller.id)
    } else {
      setSelectSearch('')
      setSellerId('')
    }
    if (dataGetVoucher.getVoucher.comment)
      setComment(dataGetVoucher.getVoucher.comment)
    dataGetVoucher.getVoucher.balance
      ?
        setAvailableValue(dataGetVoucher.getVoucher.balance)
      :
        setAvailableValue(dataGetVoucher.getVoucher.value)
  }

  useEffect(() =>{
    if(props.idVoucher) {
      getVoucher({variables: { voucherId: props.idVoucher }});
    }
  } ,[]);

  function registerVoucher(e) {
    e.preventDefault()

    if(initialDate){
      var startDate = initialDate;
      var iDateFormated = new Date(startDate.setTime( startDate.getTime() - startDate.getTimezoneOffset()*60*1000 ));
    }

    if(finalDate){
      var untilDate = finalDate;
      var fDateFormated = new Date(untilDate.setTime( untilDate.getTime() - untilDate.getTimezoneOffset()*60*1000));
    }

    if(voucherValue)
      var _voucherValue = voucherValue.toString();

    if(_voucherValue){
      var voucherValuePrefix = _voucherValue.replace("R$ ", "")
      var voucherValueDot = voucherValuePrefix.replace(".", "")
      var voucherValueDecimal = voucherValueDot.replace(",", ".")
      var voucherValueEdited = parseFloat(voucherValueDecimal, 2)
    }

    var sumPercentSubsidy = 0;
    let subsidiesList = JSON.parse(JSON.stringify(dataSubsidy));
    if (subsidiesList.length) {
      subsidiesList.map(item => {
        sumPercentSubsidy += item.percentage;
        delete item.name;
        delete item.key;
      });
    }

    if(!nameVoucher || !document || !documentValid || !initialDate || !finalDate || !voucherCode || !voucherValue){
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>Preencha todos os campos do formulário</p>
        </Modal>
      )
    } else if (sumPercentSubsidy !== 100) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>A soma percentual dos subsídios deve ser igual a 100%</p>
        </Modal>
      )
    } else {
      createVoucher(
        {variables: {
          name: nameVoucher,
          document: document,
          voucherCode: voucherCode,
          startDate: iDateFormated,
          endDate: fDateFormated,
          value: voucherValueEdited,
          comment: comment,
          sellerId: sellerId,
          subsidies: subsidiesList,
          active: true,
        }}
      )
    }
  }
  function updateVoucher(e){
    e.preventDefault();
    var isActive

    if(active)
      isActive = false
    else
      isActive = true

    if(initialDate){
      var startDate = initialDate;
      var iDateFormated = new Date(startDate.setTime( startDate.getTime() - startDate.getTimezoneOffset()*60*1000 ));
    }

    if(finalDate){
      var untilDate = finalDate;
      var fDateFormated = new Date(untilDate.setTime( untilDate.getTime() - untilDate.getTimezoneOffset()*60*1000));
    }

    if(voucherValue)
      var _voucherValue = voucherValue.toString();

    if(_voucherValue){
      var voucherValuePrefix = _voucherValue.replace("R$ ", "")
      var voucherValueDot = voucherValuePrefix.replace(".", "")
      var voucherValueDecimal = voucherValueDot.replace(",", ".")
      var voucherValueEdited = parseFloat(voucherValueDecimal, 2)
    }

    var sumPercentSubsidy = 0;
    let subsidiesList = JSON.parse(JSON.stringify(dataSubsidy));
    if (subsidiesList.length) {
      subsidiesList.map(item => {
        sumPercentSubsidy += item.percentage;
        delete item.name;
        delete item.key;
      });
    }

    var sellerObj = {
      name: selectSearch,
      id: sellerId
    }

    updateVoucherMutation(
      {variables: {
        voucherId: props.idVoucher,
        active: isActive,
        name: nameVoucher,
        document: document,
        seller: sellerObj.id,
        voucherCode: voucherCode,
        startDate: iDateFormated,
        endDate: fDateFormated,
        value: voucherValueEdited,
        balance: availableValue,
        comment: comment,
        subsidies: subsidiesList,
      }}
    )
  }

  function validateDocument(e) {
    var document = e.target.value.replace(/[^\d]+/g,'');

    setDocument(document)

    if(document.length > 11 ) {
      setCnpj(true)
      setDocumentValid(false)

      var cnpj = document

      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999") {
          setDocumentValid(false)
          return false;
      }

      // Valida DVs
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0,tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) {
        setDocumentValid(false)
        return false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) {
        setDocumentValid(false)
        return false;
      }

      setDocumentValid(true)
    } else {
      setCnpj(false)
      setDocumentValid(false)

      var cpf = document
      var Soma;
      var Resto;

      Soma = 0;
      if (
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      ) {
        return false;
      }

      for (let i = 1; i <= 9; i++)
        Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11))
        Resto = 0;

      if (Resto != parseInt(cpf.substring(9, 10)) ) {
        return false;
      }

      Soma = 0;

      for (let i = 1; i <= 10; i++)
        Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11))
        Resto = 0;
      if (Resto != parseInt(cpf.substring(10, 11) ) ){
        return false;
      }

      setDocumentValid(true)
      return true;
    }
  }

  function generateCoupon(e) {
    e.preventDefault();

    let stringRandom = "";
    let couponRandom = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    let date = new Date();
    let day = date.getDate()
    let dayString = day < 10 ? "0"+day.toString() : day.toString()
    let month = date.getMonth()+1
    let monthString = month < 10 ? "0"+month.toString() : month.toString()
    let year = date.getFullYear().toString().substr(2,2)
    let dateString = dayString + monthString + year

    for (var i = 0; i < 6; i++)
      stringRandom += possible.charAt(Math.floor(Math.random() * possible.length));

    couponRandom = stringRandom + dateString

    setVoucherCode(couponRandom.toUpperCase());
  }

  function handleValue(e) {
    setVoucherValue(e.target.value)
    setAvailableValue(e.target.value)
  }

  function selectedSeller(e) {
    let aux = null;
    aux = props.sellersList.find(subsidy => subsidy.name === e.target.value);

    if(aux)
      setSellerId(aux.id)

    setSelectSearch(e.target.value)
  }

  return(
    <>
    {!props.idVoucher
      ?
      <form onSubmit={e => registerVoucher(e)} className="form-admin form-admin--discount form-admin--voucher">

        <div className="form-admin__group">
          <OutlinedInput
            placeholder="Nome do voucher"
            border
            maxlength="50"
            id="voucherName"
            value={nameVoucher}
            onChange={(e) => setNameVoucher(e.target.value)}
          />
        </div>

        <div className="form-admin__group">
          <div className="outlined-label-wrap">
            <InputMask
              mask={cnpj ? "99.999.999/9999-99" : "999.999.999-999"}
              maskChar={null}
              className="input outlined-label-field outlined-label-field--border"
              value={document}
              onChange={(e) => validateDocument(e)}
              placeholder="CPF / CNPJ"
            />
            <label className="outlined-label">CPF / CNPJ{documentValid === false && '*'}</label>
          </div>
          { documentValid === false &&
            <span className="form-add-customer--label-error">* {cnpj ? 'CNPJ' : 'CPF'} Inválido </span>
          }
        </div>

        <div className="form-admin__group">
          <OutlinedInput
            placeholder="Código"
            border
            maxlength="12"
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value.toUpperCase())}
          />
          <Button onClick={e => generateCoupon(e)} className="btn-secondary">Gerar código</Button>
        </div>

        <div className="form-admin__group">
          <div className="dropdown-label-wrap">
              <Autocomplete
                id="subsidySearch"
                freeSolo
                onSelect={e => selectedSeller(e)}
                className="autocomplete-ifood"
                noOptionsText="Digite para procurar"
                options={props.sellersList ? props.sellersList.map((option) => option.name) : []}
                renderInput={(params) => <TextField {...params} label="Fornecedor (opcional)" variant="outlined" />}
              />
          </div>
        </div>

        <div className="form-admin__group">
          <div className={`outlined-label-wrap outlined-date ${initialDate ? 'active' : ''}`}>
            <DatePicker
              className="form-brand__input"
              onChange={date => setInitialDate(new Date(date))}
              locale={ptbr}
              showTimeSelect
              timeIntervals={15}
              selected={initialDate}
              dateFormat="dd/MM/yyyy hh:mm aa"
            />
            <label className="outlined-label">Data Inicial</label>
          </div>
          <div className={`outlined-label-wrap outlined-date ${finalDate ? 'active' : ''}`}>
            <DatePicker
              className="form-brand__input"
              onChange={date => setFinalDate(new Date(date))}
              locale={ptbr}
              showTimeSelect
              timeIntervals={15}
              selected={finalDate}
              dateFormat="dd/MM/yyyy hh:mm aa"
            />
            <label className="outlined-label">Data Final</label>
          </div>
        </div>

        <div className="form-admin__group">
          <OutlinedInputCurrency
            value={voucherValue}
            placeholder="Valor"
            onChange={(e) => handleValue(e)}
          />
          <OutlinedInputCurrency
            value={availableValue}
            disabled
            placeholder="Saldo disponível"
            onChange={(e) => setAvailableValue(e.target.value)}
          />
        </div>

        <div className="form-admin__area form-admin__area--subsidy">
          <FormRegisterSubsidy dataSubsidy={e => setDataSubsidy(e)} role={props.role} title="Subsídio" />
        </div>

        <div className="form-admin__area form-admin__group--comments">
          <OutlinedTextarea
            placeholder="Comentários (opcional)"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

        <div className="form-admin__group">
          <Button type="submit">Salvar voucher</Button>
        </div>


      </form>

      :

      <form onSubmit={e => updateVoucher(e)} className="form-admin form-admin--discount form-admin--voucher">

        <div className="form-admin__group">
          <OutlinedInput
            disabled
            placeholder="Nome do Voucher"
            border
            maxlength="50"
            id="voucherName"
            value={nameVoucher}
            onChange={(e) => setNameVoucher(e.target.value)}
          />
        </div>

        <div className="form-admin__group">
          <div className="outlined-label-wrap">
            <InputMask
              disabled
              mask={cnpj ? "99.999.999/9999-99" : "999.999.999-999"}
              maskChar={null}
              className="input outlined-label-field outlined-label-field--border"
              value={document}
            />
            <label className="outlined-label">CPF / CNPJ</label>
          </div>
        </div>

        <div className="form-admin__group">
          <OutlinedInput
            disabled
            placeholder="Código"
            border
            maxlength="12"
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value.toUpperCase())}
          />
        </div>

        <div className="form-admin__group">
          <div className="dropdown-label-wrap">
            <OutlinedInput
              disabled
              placeholder="Fornecedor"
              border
              value={selectSearch}
            />
          </div>
        </div>

        <div className="form-admin__group">
          <div className={`outlined-label-wrap outlined-date ${initialDate ? 'active' : ''}`}>
            <DatePicker
              disabled
              className="form-brand__input"
              onChange={date => setInitialDate(new Date(date))}
              locale={ptbr}
              showTimeSelect
              timeIntervals={15}
              selected={initialDate}
              dateFormat="dd/MM/yyyy hh:mm aa"
            />
            <label className="outlined-label">Data Inicial</label>
          </div>
          <div className={`outlined-label-wrap outlined-date ${finalDate ? 'active' : ''}`}>
            <DatePicker
              disabled
              className="form-brand__input"
              onChange={date => setFinalDate(new Date(date))}
              locale={ptbr}
              showTimeSelect
              timeIntervals={15}
              selected={finalDate}
              dateFormat="dd/MM/yyyy hh:mm aa"
            />
            <label className="outlined-label">Data Final</label>
          </div>
        </div>

        <div className="form-admin__group">
          <OutlinedInputCurrency
            disabled
            value={voucherValue}
            placeholder="Valor"
            onChange={(e) => setVoucherValue(e.target.value)}
          />
          <OutlinedInputCurrency
            value={availableValue}
            disabled
            placeholder="Saldo disponível"
            onChange={(e) => setAvailableValue(e.target.value)}
          />
        </div>

        <div className="form-admin__area form-admin__area--subsidy">
          <FormRegisterSubsidy disabled dataSubsidyUpdate={dataSubsidyUpdate} dataSubsidy={e => setDataSubsidy(e)} role={props.role} title="Subsídio" />
        </div>

        <div className="form-admin__area form-admin__group--comments">
          <OutlinedTextarea
            disabled
            placeholder="Comentários (opcional)"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

        <div className="form-admin__group">
          <Button type="submit">
            {active
              ?
                'Inativar voucher'
              :
                'Habilitar voucher'
            }

          </Button>
        </div>


      </form>
    }

    {modal}

    {/* {loading || loadingUpdateDiscount || loadingGetDiscount
      ? <Loading />
      : null
    } */}
    </>
  )
}

export default FormRegisterVoucher
