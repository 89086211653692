import gql from 'graphql-tag';

const GET_PARENTS = gql `
  query {
    getAllParentCategories{
        id
        name
        active
        haveChildren
      }
    }
  `;

const GET_CATEGORIES_BY_NAME = gql `
  query getCategoriesByName($name: String!){
    getCategoriesByName(name: $name){
      id
      name
      active
      haveChildren
    }
  }
`

const GET_LEGACY_CATEGORIES_BY_NAME_OR_ID = gql `
  query getCategoriesByNameOrId($searchTerm: String){
    getCategoriesByNameOrId(searchTerm: $searchTerm){
      id
      name
    }
  }
`

const GET_CATEGORY_FORM_TREE = gql `
  query getCategoriesToCreateTree($parent: ID){
    getCategoriesToCreateTree(parent: $parent){
      id
      name
      active
      haveChildren
      parentId
    }
  }
`

const GET_CATEGORIES_TREE = gql `
  query getCategoriesTree($name: String!){
    getCategoriesTree(name: $name){
      id
      name
      active
      subCategories
    }
}
`;

const GET_SUBCATEGORIES = gql `
  query getAllSubcategoriesByCategoryId($id:ID!){
    getAllSubcategoriesByCategoryId(id: $id){
      id
      name
      active
      haveChildren
    }
  }
`;

const GET_CATEGORY = gql`
  query getCategoryDetailById($id: ID!, $fieldsType: String){
    getCategoryDetailById(id: $id, fieldsType: $fieldsType){
      category{
        id
        name
        active
        parentId
        menus {
          id
          rank
          menu {
            id
            name
          }
        }
      }
      fields
    }
  }
`;

const GET_CATEGORYMENU_BY_MENUID = gql `
  query getAllCategoryMenuByMenuId(
    $id: ID!
  ){
    getAllCategoryMenuByMenuId(
      id: $id
    ){
      id
      rank
      category {
        id
        name
      }
    }
  }
`;

export {GET_PARENTS, GET_CATEGORY, GET_SUBCATEGORIES, GET_CATEGORIES_BY_NAME, GET_LEGACY_CATEGORIES_BY_NAME_OR_ID, GET_CATEGORIES_TREE, GET_CATEGORY_FORM_TREE, GET_CATEGORYMENU_BY_MENUID}
