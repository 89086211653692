import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  GET_ORDER_BY_SELLER_MONTHLY_PREVISION,
  GET_ORDER_BY_SELLER_PERFORMANCE,
  GET_PENDING_ORDERS,
} from "@graphql/order.queries";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Help from "@material-ui/icons/Help";
import TableOrderDashboard from "./components/TableAllOrders";
import TableSellerDashboard from "@components/Seller/Dashboard/TableSellerDashboard";
import Loading from "@components/Loading";
import { Modal } from "ifoodshop-react-ui";
import Formatter from "@helpers/FormatHelper";
import Roles from "@helpers/Roles";
import ptbr from "date-fns/locale/pt-BR";
import "./dashboard.scss";
import { getRoles, getSellerId } from "@helpers/rolesUtils";
import Highlight from "./components/Highlight";

function Dashboard(props) {
  const roles = getRoles();
  const sellerId = getSellerId();

  const roleOrderWR = roles.includes(Roles.ORDER_WR);
  const roleOrderRO = roles.includes(Roles.ORDER_RO);
  const roleDashboardReadOnly = roles.includes(Roles.DASHBOARD_RO);
  const roleDashboardWR = roles.includes(Roles.DASHBOARD_WR);

  if (!roleDashboardReadOnly && !roleDashboardWR) {
    window.location.href = "/";
  }

  // FILTER DATES
  const todayDate = new Date();

  // last and first day in month current
  const firstDayDate = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    1
  );
  const lastDayinMonth = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth() + 1,
    0
  );

  const [data, setData] = useState(null);
  const [dataPayments, setDataPayments] = useState(null);
  const [nextDayPayment, setNextDayPayment] = useState();
  const [open, setOpen] = useState(false);
  const [openSales, setOpenSales] = useState(false);
  const [openPendingOrders, setOpenPendingOrders] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [salesTotal, setSalesTotal] = useState();
  const [salesAvg, setSalesAvg] = useState();
  const [ordersTotal, setOrdersTotal] = useState();
  const [ordersDelivered, setOrdersDelivered] = useState();
  const [amountReceivable, setAmountReceivable] = useState();
  const [initialDatePayments, setInitialDatePayments] = useState(firstDayDate);
  const [finalDatePayments, setFinalDatePayments] = useState(lastDayinMonth);
  const [initialDatePrevision, setInitialDatePrevision] = useState();
  const [finalDatePrevision, setFinalDatePrevision] = useState();
  const [initialDateFilterPerformance, setInitialDateFilterPerformance] = useState();
  const [finalDateFilterPerformance, setFinalDateFilterPerformance] = useState();
  const [modal, setModal] = useState(null);

  const startDatePickerRange = new Date("01/01/2019");
  const rangeOfDays = 31;
  const miliseconds = 1000;
  const seconds = 60;
  const minutes = 60;
  const hours = 24;

  const {
    loading,
    data: dataOrderMonthlyPrevision,
  } = useQuery(GET_ORDER_BY_SELLER_MONTHLY_PREVISION, {
    fetchPolicy: "no-cache",
    variables: {
      sellerId: parseInt(props.match.params.id) || parseInt(sellerId),
    },
    onCompleted: () => {
      if (dataOrderMonthlyPrevision?.getOrdersBySellerMonthlyPrevision && data == null) {
        setData(dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision);
        setInitialDatePayments(
          dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyStartDate + "T12:00"
        );
        setFinalDatePayments(dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyEndDate + "T12:00");
       
      // eslint-disable-next-line no-lone-blocks
      {/* Todo: Implementar após definição do financeiro - Valor total a receber
    */}
        setAmountReceivable(dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyAmountPrevision);

        setNextDayPayment(
          dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyDatePrevision + "T12:00"
        );

        setInitialDatePrevision(
          new Date(dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyStartDate + "T12:00")
        );
        setFinalDatePrevision(
          new Date(dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyEndDate + "T12:00")
        );
        setInitialDateFilterPerformance(
          new Date(dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyStartDate + "T12:00")
        );
        setFinalDateFilterPerformance(
          new Date(dataOrderMonthlyPrevision.getOrdersBySellerMonthlyPrevision.monthlyEndDate + "T12:00")
        );
      }
    }
  });

  const {
    loading: loadingPerformance,
    data: dataOrderPerformance,
    refetch,
  } = useQuery(GET_ORDER_BY_SELLER_PERFORMANCE, {
    fetchPolicy: "no-cache",
    variables: {
      sellerId: parseInt(props.match.params.id) || parseInt(sellerId),
      startDate: Formatter.format(initialDateFilterPerformance, "date-formated"),
      endDate: Formatter.format(finalDateFilterPerformance, "date-formated"),
    },
  });

  const [getPendingOrders, { loading: loadingPendingOrders, data: dataPendingOrders }] = useLazyQuery(GET_PENDING_ORDERS, {
    fetchPolicy: "no-cache",
    variables: {
      sellerId: parseInt(props.match.params.id) || null,
    },
    onCompleted: () => {
      if (dataPendingOrders.getPendingOrders.content) {
        const ordersDone = dataPendingOrders.getPendingOrders.content.reduce(
          (acc, item) => {
            if (
              item.status !== "CANCELLED" &&
              item.status !== "WAITING_PAYMENT"
            ) {
              return [...acc, item];
            }
            return [...acc];
          },
          []
        );

        setDataPayments(ordersDone);
      }
    },
  });

  useEffect(() => {
    if (dataOrderPerformance) {
      handleDataFilter(initialDateFilterPerformance, finalDateFilterPerformance);
    }
  }, [initialDateFilterPerformance, finalDateFilterPerformance]);

  function handleDate(date, temp) {
    if (temp === "initial" && Date.parse(date) <= Date.parse(finalDateFilterPerformance)) {
      let initialDate = new Date(date);
      let finalDate = new Date(finalDateFilterPerformance);

      let diffDate = Math.abs(finalDate - initialDate); 
      let days = Math.ceil(diffDate / (miliseconds * seconds * minutes * hours));
      
      if(days > rangeOfDays) {
        let tempDate = initialDate.setDate(initialDate.getDate() + rangeOfDays);
        tempDate = new Date(tempDate);
        setFinalDateFilterPerformance(tempDate);
      }   
      
      setInitialDateFilterPerformance(new Date(date));

    } else if (
      temp === "final" &&
      Date.parse(date) >= Date.parse(initialDateFilterPerformance)
    ) {
      let initialDate = new Date(date);
      let finalDate = new Date(initialDateFilterPerformance);

      let diffDate = Math.abs(finalDate - initialDate); 
      let days = Math.ceil(diffDate / (miliseconds * seconds * minutes * hours));
      
      if(days > rangeOfDays) {
        
        let tempDate = initialDate.setDate(initialDate.getDate() - rangeOfDays);
        tempDate = new Date(tempDate);
        setInitialDateFilterPerformance(tempDate);
      }
      
        setFinalDateFilterPerformance(new Date(date));
    
    } else
      handleModal("Atenção", "A data inicial não pode ser maior que a final.");
  }
  

  function handleClose() {
    setOpen(false);
    setOpenSales(false);
    setOpenPendingOrders(false);
  }

  function handleOpen() {
    setOpen(true);
  }
  
  function handleOpenSales() {
    setOpenSales(true);
  }
  
  function handleOpenPendingOrders() {
    setOpenPendingOrders(true);
  }

  function handleExpand() {
    setExpanded(!expanded);
  }

  function handleDataFilter(initialDateFilterPerformance, finalDateFilterPerformance) {
    if (initialDateFilterPerformance < finalDateFilterPerformance) {
      refetch({
        startDate: Formatter.format(initialDateFilterPerformance, "date-formated"),
        endDate: Formatter.format(finalDateFilterPerformance, "date-formated"),
      }).then((response) => {
        //TODO
        /*          
          Removida tabela com pedidos atrasados e pendentes
          Implementação pendente
        */

        setSalesTotal(response?.data?.getOrdersBySellerPerformance?.sellAmount);
        setSalesAvg(response?.data?.getOrdersBySellerPerformance?.averageTicketPrice);
        setOrdersTotal(response?.data?.getOrdersBySellerPerformance?.sellQuantity);
        setOrdersDelivered(response?.data?.getOrdersBySellerPerformance?.sellDone);

        getPendingOrders();
      });
    }
  }
    /**
   * TODO
   * Lista de pedidos atrasados e pendentes
   *
   */
  // const filterOpen = () => {
  //   setListaPedidoFiltrada(ordersOpen);
  //   setTitleTable("Pedidos abertos");
  // };
  // const filterLate = () => {
  //   setListaPedidoFiltrada(ordersDelayed);
    //   setTitleTable("Pedidos atrasados");
  // };

  const handleModal = (title, message) => {
    setModal(
      <Modal title={title} onCloseAction={() => setModal(null)}>
        <p>{message}</p>
      </Modal>
    );
  };

  return (
    <>
      {(roleDashboardReadOnly || roleDashboardWR) && (
        <>
          <NavLink
            className="go-back"
            to={`/fornecedores${roleDashboardWR ? "/detail" : "/"}`}
          />
          <div className="content-body">
            <h2 className="section-title">Dashboard</h2>
            <div className="dashboard">
              <React.Fragment>
                <Dialog open={open} onClose={() => handleClose()}>
                  <DialogContent>
                    <DialogContentText>
                      {dataPayments
                        ? "Valores referentes aos pedidos faturados no período de " +
                          Formatter.format(initialDatePayments, "simple-date") +
                          " a " +
                          Formatter.format(finalDatePayments, "simple-date") +
                          "."
                        : "Não temos informações para sua conta"}
                      <br />
                      <b>
                        Sujeito variação devido a ocorrências de crédito ou
                        débito (cancelamentos) realizadas dentro do mesmo
                        período.
                      </b>
                      <br />
                      <br />
                      <i>* Comissão será retida apenas no repasse.</i>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button
                      onClick={() => handleClose()}
                      color="primary"
                      autoFocus
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openSales} onClose={() => handleClose()}>
                  <DialogContent>
                    <DialogContentText>
                      <h4>
                        Período considerado:{" "}
                        <b>{`${Formatter.format(
                          initialDatePrevision,
                          "simple-date"
                        )} à ${Formatter.format(
                          finalDatePrevision,
                          "simple-date"
                        )}`}</b>
                      </h4>
                      <br />
                      Indicadores:
                      <ul>
                        <li>
                          <b>Total de vendas</b>: valor total das vendas
                          recebidas no período.
                        </li>
                        <li>
                          <b>Todos pedidos</b>: número de pedidos captados no
                          período.
                        </li>
                        <li>
                          <b>Ticket médio</b>: valor total dividido pela
                          quantidade de pedidos do período.
                        </li>
                        <li>
                          <b>Entregues</b>: quantidade de pedidos entregues no
                          período.
                        </li>
                      </ul>
                      <br />
                      <br />
                      <i>* Pedidos sujeito à aprovação de pagamento.</i>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleClose()}
                      color="primary"
                      autoFocus
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openPendingOrders} onClose={() => handleClose()}>
                  <DialogContent>
                    <DialogContentText>
                      Indicadores:
                      <div>
                        <ul>
                          <li>
                            <b>Abertos</b>: Pedidos pagos que ainda não foram
                            entregues.
                          </li>
                          <li>
                            <b>Atrasados</b>: Pedidos pagos que ultrapassaram o
                            prazo de entrega e ainda não foram entregues.
                          </li>
                        </ul>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleClose()}
                      color="primary"
                      autoFocus
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                <Grid container spacing={32}>
                {/* Todo: Implementar depois, após definição do financeiro

                  <Grid item xs={12}>
                    <h3 className="dashboard__title">
                      A receber
                      <IconButton
                        onClick={() => handleOpen()}
                        size="small"
                        className="dashboard__help-icon"
                      >
                        <Help color="inherit" />
                      </IconButton>
                    </h3>
                  </Grid>
            
                  {amountReceivable > 0 ? (
                    <div className="dashboard__receivable">
                      <Grid
                        item
                        sm={4}
                        xl={6}
                        xs={12}
                        className="dashboard__grid-item"
                      >
                        <div className="dashboard__title-rightDiv">
                          <h4 className="dashboard__title-right">
                            Valor previsto
                          </h4>
                          <h2 className="dashboard__title-paidValue">
                            {Formatter.format(amountReceivable, "currency")}
                          </h2>
                        </div>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        xl={6}
                        xs={12}
                        className="dashboard__grid-item"
                      >
                        <div className="dashboard__title-rightDivBorder">
                          <h4 className="dashboard__title-right">
                            Próxima data de recebimento
                          </h4>
                          <h4 className="dashboard__title-rightBold">
                            {Formatter.format(nextDayPayment, "simple-date")}
                          </h4>
                        </div>
                      </Grid>
                      <Grid item sm={4} xl={6} xs={12}>
                        {dataPayments && (
                          <>
                            <div className="dashboard__title-rightDiv">
                              <h4 className="dashboard__title-right">
                                Pedidos entregues entre{" "}
                              </h4>
                              <h4 className="dashboard__title-right">
                                {Formatter.format(
                                  initialDatePayments,
                                  "simple-date"
                                ) +
                                  " e " +
                                  Formatter.format(
                                    finalDatePayments,
                                    "simple-date"
                                  )}
                              </h4>
                            </div>
                          </>
                        )}
                      </Grid>
                      

                      <Grid item sm={4} xl={6} xs={12}>
                      {/* Todo: Implementar depois - Botão VER PEDIDOS 
                        <ButtonBase
                          className="dashboard__button"
                          onClick={() => handleExpand()}
                        >
                          <h4 className="dashboard__rightListOrder">
                            Ver pedidos
                          </h4>
                        </ButtonBase>
                      </Grid>
                    </div>
                  ) : (
                    <React.Fragment>
                      <Grid item xs={12} className="dashboard__grid-item">
                        <Highlight
                          className="dashboard__card"
                          value={"Não temos informações para sua conta ainda"}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <TableSellerDashboard
                        title={`Pedidos entregues de ${Formatter.format(
                          initialDatePayments,
                          "simple-date"
                        )} a ${Formatter.format(
                          finalDatePayments,
                          "simple-date"
                        )}`}
                        dataTable={dataPayments}
                      />
                    </Collapse>
                  </Grid>             
              */}

                  <Grid item xs={6}>
                    <h3 className="dashboard__title">Performance</h3>
                  </Grid>

                  <Grid item xs={6} alignItems="flex-end">
                    <div
                      className={`outlined-label-wrap outlined-date ${
                        initialDateFilterPerformance ? "active" : ""
                      }`}
                    >
                      <DatePicker
                        className="form-brand__input"
                        onChange={(date) => handleDate(date, "initial")}
                        locale={ptbr}
                        minDate={startDatePickerRange}
                        maxDate={todayDate}
                        selected={initialDateFilterPerformance}
                        dateFormat="dd/MM/yyyy"
                      />
                      <label className="outlined-label">Data Inicial</label>
                    </div>
                    <div
                      className={`outlined-label-wrap outlined-date ${
                        finalDateFilterPerformance ? "active" : ""
                      }`}
                    >
                      <DatePicker
                        className="form-brand__input"
                        onChange={(date) => handleDate(date, "final")}
                        locale={ptbr}
                        minDate={startDatePickerRange}
                        maxDate={todayDate}
                        selected={finalDateFilterPerformance}
                        dateFormat="dd/MM/yyyy"
                      />
                      <label className="outlined-label">Data Final</label>
                    </div>
                  </Grid>
                  <div className="dashboard__title-sellerRequest">
                  <h4 className="dashboard__title-titleSeller">
                    Vendas
                    <IconButton
                      onClick={() => handleOpenSales()}
                      size="small"
                      className="dashboard__help-icon"
                    >
                      <Help color="inherit" />
                    </IconButton>
                  </h4>
                  <Grid item xl={12} xs={12} className="MuiGrid-item"  >
                  {ordersTotal ? (
                    <React.Fragment>
                      <Grid item sm={6} xl={6} xs={12} className="dashboardCardInformation">
                        <Highlight
                          className="dashboard__card"
                          label="Total de vendas"
                          value={Formatter.format(salesTotal, "currency")}
                          icon="money"
                        />
                      </Grid>

                      <Grid item sm={6} xl={6} xs={12} className="dashboardCardInformation">
                        <Highlight
                          className="dashboard__card"
                          label="TOTAL DE PEDIDOS"
                          value={ordersTotal}
                          icon="orders"
                        />
                      </Grid>

                      <Grid item sm={6} xl={6} xs={12} className="dashboardCardInformation">
                        <Highlight
                          className="dashboard__card"
                          label="Ticket médio"
                          value={Formatter.format(salesAvg, "currency")}
                          icon="chart"
                        />
                      </Grid>

                      <Grid item sm={6} xl={6} xs={12} className="dashboardCardInformation">
                        <Highlight
                          className="dashboard__card"
                          label="ENTREGUES"
                          value={ordersDelivered}
                          icon="done"
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={12} className="dashboard__grid-item">
                      <Highlight
                        className="dashboard__card"
                        value={`Não houve vendas no período: ${Formatter.format(
                          initialDateFilterPerformance,
                          "simple-date"
                        )} à ${Formatter.format(
                          finalDateFilterPerformance,
                          "simple-date"
                        )}`}
                        variant="primary"
                      />
                    </Grid>
                  )}
                  </Grid>
                  </div>
                  <Grid item xs={12}>
                    <h3 className="dashboard__title">
                      Pedidos pendentes
                      <IconButton
                        onClick={() => handleOpenPendingOrders()}
                        size="small"
                        className="dashboard__help-icon"
                      >
                        <Help color="inherit" />
                      </IconButton>
                    </h3>
                  </Grid>
                  <div className="dashboard__grid-request">
                    <TableOrderDashboard
                      sellerId={props.match.params.id}
                      rolesOrder={{ roleOrderRO, roleOrderWR }}
                      initialDate={initialDateFilterPerformance}
                      finalDate={finalDateFilterPerformance}
                    />
                  </div>
                </Grid>
              </React.Fragment>
            </div>
          </div>
          {(loading || loadingPerformance || loadingPendingOrders) && <Loading />}
        </>
      )}
      {modal}
    </>
  );
}

export default Dashboard;
