import gql from 'graphql-tag';

const CREATE_OFFER = gql`
  mutation createOffer(
    $variation: Int
    $seller: Int
    $sellerCode: String
    $stock: Int
    $active: Boolean
    $price: Float
    $pricePerKg: Float
    $isPricePerKg: Boolean
    $variableWeight: Boolean
    $limitQuantity: Int
    $specialPrices: [SpecialPriceInput]
  ) {
    createOffer(
      input: {
        variationId: $variation
        sellerId: $seller
        sellerCode: $sellerCode
        stock: $stock
        active: $active
        price: $price
        pricePerKg: $pricePerKg
        isPricePerKg: $isPricePerKg
        variableWeight: $variableWeight
        limitQuantity: $limitQuantity
        specialPrices: $specialPrices
      }
    ) {
      id
      price
      pricePerKg
      isPricePerKg
      stock
      active
      sellerId
      variableWeight
      specialPrices {
        id
        price
        active
        legalNatureCode
      }
      error
      errorCode
    }
  }
`;

const UPDATE_OFFER = gql`
  mutation updateOffer(
    $id: ID!
    $variation: Int
    $seller: Int
    $sellerCode: String
    $stock: Int
    $limitQuantity: Int
    $active: Boolean
    $price: Float
    $pricePerKg: Float
    $isPricePerKg: Boolean
    $variableWeight: Boolean
    $specialPrices: [SpecialPriceInput]
    $offerPrices: [OfferPricesInput]
  ) {
    updateOffer(
      id: $id
      input: {
        id: $id
        variationId: $variation
        sellerId: $seller
        sellerCode: $sellerCode
        stock: $stock
        active: $active
        price: $price
        pricePerKg: $pricePerKg
        isPricePerKg: $isPricePerKg
        variableWeight: $variableWeight
        limitQuantity: $limitQuantity
        specialPrices: $specialPrices
        offerPrices: $offerPrices
      }
    ) {
      id
      price
      pricePerKg
      isPricePerKg
      stock
      active
      sellerId
      variableWeight
      specialPrices {
        id
        price
        active
        legalNatureCode
      }
      error
      errorCode
    }
  }
`;

export { CREATE_OFFER, UPDATE_OFFER };
