import { ReactComponent as WomanImage } from '../../../assets/icons/woman.svg';
import { ReactComponent as Paper } from '../../../assets/icons/paper-grey.svg';
import TemplateCsv from '../../../assets/files/template.csv';
import { Link } from 'ifoodshop-react-ui';
import { Container } from './style';

export function EmptStateClientGroup() {
  return (
    <Container data-testid={'empt-container'}>
      <WomanImage data-testid={'empt-container-image'} />
      <span className="title">Inclua clientes ao grupo</span>
      <span className="description" data-testid={'empt-container-description'}>
        Clique em <label>"importar lista"</label> para importar
        <br /> uma lista de cliente ou em <label>"incluir cliente"</label>
        <br /> para inserir um por vez.
      </span>

      <div className="link-download" data-testid={'empt-container-link'}>
        <Paper />
        Baixe o modelo para importar sua lista de clientes. <Link href={TemplateCsv}>Baixar</Link>
      </div>
    </Container>
  );
}
