import { NavLink } from 'react-router-dom';
import { Button, Modal, Typography } from 'ifoodshop-react-ui';

import './modalsuggestion.scss';

const ModalSuggestion = ({ setModal }) => {
  return (
    <div className="modal-suggestion">
      <Modal onCloseAction={() => setModal(null)} className="modal-suggestion__content">
        <div className="modal-suggestion--action">
          <div className="modal-suggestion__title">
            <Typography size="24px" weight="regular" line="32px" color="black">
              Cadastrar novo produto
            </Typography>
          </div>
          <div className="modal-suggestion__description">
            <Typography size="18px" weight="Light" line="24px" color="heavygrey">
              Para cadastrar um produto, você será redirecionado para uma nova página. Os dados preenchidos nesta página
              não ficarão salvos.
              <p>Tem certeza que deseja sair?</p>
            </Typography>
          </div>
          <div className="modal-suggestion--btn">
            <Button className="btn-secondary" onClick={() => setModal(null)}>
              Cancelar
            </Button>
            <NavLink to="/catalogos/sugestao/cadastro/produto" className="btn-primary">
              Confirmar
            </NavLink>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalSuggestion;
