import React from 'react'
import CurrencyFormat from 'react-currency-format'

const OutlinedNumberInput = (props) => {
  return(
    <>
      <div className="outlined-label-wrap">
        <CurrencyFormat
          disabled={props.disabled}
          value={props.value}
          placeholder={props.placeholder}
          className={`${props.className ? props.className+' ' : ''}input outlined-label-field outlined-label-field--border`}
          onChange={props.onChange}
          displayType={'input'}
          thousandSeparator="."
          prefix={props.prefix}
          decimalScale={props.decimalScale}
          decimalSeparator=","
          min={ props.min }
          step={ props.step }
          allowNegative={false}
        />
        <label className="outlined-label">{props.placeholder}</label>
      </div>
    </>
  )
}

export default OutlinedNumberInput
