import React, { useState } from 'react';
import { Link } from 'ifoodshop-react-ui';
import { useQuery } from '@apollo/react-hooks';
import { useDataSellerContext } from '../../DataSellerContext';
import { GET_LOGISTIC_TYPES_ALLOWED } from '@graphql/seller.queries';
import './sellerContracts.scss';
import { handleTypes } from '../dataSellerUtils';

const SellerContracts = ({ data }) => {
  const { setStep, setLogisticTypes } = useDataSellerContext();

  const [contractType, setContractType] = useState('');

  const { data: dataLogistics, loading } = useQuery(GET_LOGISTIC_TYPES_ALLOWED, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setLogisticTypes(dataLogistics);
      handleContractType(dataLogistics);
    },
  });

  function handleContractType() {
    const contracts = dataLogistics?.getLogisticTypesAllowed.map(item => {
      if (item.logistic.name.toUpperCase() === 'CORREIOS' || 'SELLER') return item.logistic.name;
    });

    if (contracts.length > 0) setContractType(handleTypes(contracts));
  }

  return (
    <>
      {!loading && dataLogistics?.getLogisticTypesAllowed && (
        <div className="sellerContracts">
          <h2 className="sellerContracts__title">Tipo de Contrato</h2>
          {dataLogistics?.getLogisticTypesAllowed?.length > 0 && (
            <div className="sellerContracts__contract-type">
              <span className="sellerContracts__attribute">Tipo de Contrato:</span>
              <span className="sellerContracts__value">{contractType}</span>
            </div>
          )}
          <div className="sellerContracts__contract-options">
            <div className="edit-contract">
              <Link className="link" onClick={e => setStep(1)}>
                Editar Tipo de Contrato
              </Link>
            </div>
            {/* {data?.getSellerById.shippingByShop && (
              <div className="see-contract">
                <Link className="link" onClick={(e) => setStep(2)}>
                  Ver contrato
                </Link>
              </div>
            )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default SellerContracts;
