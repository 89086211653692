import styled from 'styled-components';
import { Card, Heading } from '@ifood/pomodoro-components';
import { NavLink } from 'react-router-dom';

export const LinkWrapper = styled(NavLink)`
  text-decoration: none;
`;

export const CatalogItem = styled(Card)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.small};
  width: 425px;
  transition-property: box-shadow;
  transition-timing-function: ${({ theme }) => theme.timingFunctions.ease};
  transition-duration: ${({ theme }) => theme.durations.promptly};

  &:hover {
    box-shadow: ${props => props.theme.shadows.elevate};
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.small};
`;

export const Title = styled(Heading)`
  margin: inherit;
`;

export const ImageBox = styled.div`
  width: 130px;
  height: 120px;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
`;
