import { NavLink } from 'react-router-dom';
import { Typography } from 'ifoodshop-react-ui';

import { useProductSuggestionContext } from '@components/_context/ProductSuggestioContext';

import './stepproductsuggestion.scss';

const StepProductSuggestion = () => {
  const { step, setStep, productId, price, quantityAvailable, sku } = useProductSuggestionContext();

  return (
    <div className="header">
      <div className="header__itens">
        <NavLink
          onClick={() => (productId ? setStep(1) : '')}
          to={productId ? '/catalogos/sugestao/cadastro' : '/catalogos/sugestao/cadastro'}
          className={`header__step ${step === 1 ? 'header__step--active' : '/catalogos/sugestao/cadastro'}`}
        />
        <NavLink
          onClick={() => (productId ? setStep(2) : '')}
          to={productId ? '/catalogos/sugestao/cadastro/condicao' : '/catalogos/sugestao/cadastro'}
          className={`header__step ${step === 2 ? 'header__step--active' : ''} `}
        />
        <NavLink
          onClick={() => (productId && price && quantityAvailable && sku ? setStep(3) : '')}
          to={
            productId && price && quantityAvailable && sku
              ? '/catalogos/sugestao/cadastro/resumo'
              : '/catalogos/sugestao/cadastro/condicao'
          }
          className={`header__step ${step === 3 ? 'header__step--active' : ''}`}
        />
      </div>
      <div className="header header--step">
        <Typography size="16px" weight="regular" line="24px" color="heavygrey">
          Passo {step} de 3
        </Typography>
      </div>
      <div className="header-customer-group header-customer-group--src">
        <div className="header-customer-group__info">
          <div className="section-title">
            {step === 1 ? (
              <Typography size="24px" weight="regular" line="48px" color="black">
                Identificando o produto
              </Typography>
            ) : step === 2 ? (
              <Typography size="24px" weight="regular" line="48px" color="black">
                Condições de venda
              </Typography>
            ) : (
              <Typography size="24px" weight="regular" line="48px" color="black">
                Resumo da oferta
              </Typography>
            )}
          </div>
          <p className="header-customer-group__description">
            {step === 1 ? (
              <Typography size="18px" weight="normal" line="24px" color="heavygrey">
                Digite abaixo o nome do produto que gostaria de cadastrar
              </Typography>
            ) : step === 2 ? (
              <Typography size="18px" weight="normal" line="24px" color="heavygrey">
                Preencha os campos abaixo para o cadastro da nova oferta
              </Typography>
            ) : (
              <Typography size="18px" weight="normal" line="24px" color="heavygrey">
                Estes são os dados completos do produto que existem no nosso sistema para este produto. Revise as
                informações da oferta antes de cadastrá-la.
              </Typography>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepProductSuggestion;
