import gql from "graphql-tag";

const CREATE_COMMISSION = gql`
    mutation createCommission(
        $sellerId: Int, $linkedId: Int,
        $description:String, $commissionType: String,
        $startDate: String, $endDate: String, $percentage: Float, $active: Boolean){
        createCommission(
            input:{
                sellerId: $sellerId, linkedId: $linkedId,
                description: $description, commissionType: $commissionType,
                startDate: $startDate, endDate: $endDate, percentage: $percentage,
                active: $active
            }
        )
        {
            id
            sellerId
            startDate
            endDate
            percentage
            active
            createdAt
            updatedAt
            error
        }
    }
`;

const UPDATE_COMMISSION = gql `
    mutation updateCommission(
        $id: ID, $sellerId: Int, $linkedId: Int,
        $description:String, $commissionType: String,
        $startDate: String, $endDate: String, 
        $percentage: Float, $active: Boolean){
        updateCommission(
            input:{
                id: $id, sellerId: $sellerId, linkedId: $linkedId,
                description: $description, commissionType: $commissionType,
                startDate: $startDate, endDate: $endDate, percentage: $percentage, active: $active
            }
        )
        {
            id
            sellerId
            startDate
            endDate
            percentage
            active
            createdAt
            updatedAt
            error
        }
    }
`;

export {CREATE_COMMISSION, UPDATE_COMMISSION};
