/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';

interface CheckboxProps {
  type?: string;
  name?: string;
  id?: string;
  onChange?: (data: unknown) => void;
  onClick: (data: unknown) => void;
  checked?: boolean;
  document?: string | null | undefined;
}

const Checkbox = ({ type = 'checkbox', name, id, onChange, onClick, checked, document, ...props }: CheckboxProps) => {
  function handleCheck(e: unknown) {
    onClick(e);
    return;
  }

  return (
    <div className="checkbox-ifood" data-testid={'checkbox-ifood'}>
      <input
        {...props}
        checked={checked}
        className="checkbox-ifood__input"
        data-testid={'checkbox-ifood__input'}
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        onClick={e => handleCheck(e)}
      />
      <span className="checkbox-ifood__mask"></span>
    </div>
  );
};

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
  document: PropTypes.string,
};

export default Checkbox;
