export default class MenusHnHelper {
  static handleNameMenu(nameMenu) {
    switch(nameMenu) {
      case 'menu_br':
        return 'Menu Nacional';
      case 'menu_sp':
        return 'Menu SP';
      case 'menu_rio':
        return 'Menu RJ';
      case 'menu_uber':
        return 'Menu Uber';
      case 'menu_loop':
        return 'Menu Loop';
      default:
        return nameMenu
    }
  }
}