/* istanbul ignore file */
import { Timeline, TimelineItem } from '@components/Timeline';
import { useStatementDetailCardHook } from '../StatementDetailCardHook';
import { StatementDetailPaymentType } from './StatementDetailPaymentType';
import { Button, Loading } from '@ifood/pomodoro-components';
import * as S from './StatementDetailTimeline.styles';
import { useState } from 'react';
import StatementDetailDialog from '../StatementDetailDialog';

export function StatementDetailTimeline() {
  const [openDialog, setOpenDialog] = useState(false);

  const { bonusShopStatementData, loadingBonusShopStatement, errorBonusShopStatement } = useStatementDetailCardHook();

  if (loadingBonusShopStatement) {
    return <Loading color="primary" variant="large" />;
  }

  if (errorBonusShopStatement || !bonusShopStatementData?.content?.length) {
    return (
      <>
        <S.TextNotFound as={'p'}>
          Não houveram movimentações nos últimos 30 dias, clique em ver mais para movimentações anteriores
        </S.TextNotFound>
        <Button type="button" variant="tertiary" onClick={() => setOpenDialog(true)}>
          Ver mais itens
        </Button>
        {openDialog && <StatementDetailDialog close={() => setOpenDialog(false)} isOpen={openDialog} />}
      </>
    );
  }

  return (
    <>
      <S.Box>
        <Timeline>
          {bonusShopStatementData?.content?.map(item => (
            <TimelineItem key={item?.transactionDate}>
              <StatementDetailPaymentType data={item} />
            </TimelineItem>
          ))}
        </Timeline>
      </S.Box>
      <Button type="button" variant="tertiary" onClick={() => setOpenDialog(true)}>
        Ver mais itens
      </Button>
      {openDialog && <StatementDetailDialog close={() => setOpenDialog(false)} isOpen={openDialog} />}
    </>
  );
}
