import gql from 'graphql-tag';

const CREATE_COMMERCIAL_CONDITION = gql`
  mutation createCommercialCondition(
    $name: String,
    $isEnabled: Boolean,
    $rules: [RulesInput]
  ){
    createCommercialCondition(input: {name: $name, isEnabled: $isEnabled, rules: $rules}){
      id
      name
    }
  }
`;

const UPDATE_COMMERCIAL_CONDITION = gql `
  mutation updateCommercialCondition (
    $id: ID!,
    $name: String,
    $isEnabled: Boolean,
    $rules: [RulesInput]
  ){
    updateCommercialCondition(input: {id: $id, name: $name, isEnabled: $isEnabled, rules: $rules}){
      id
      name
    }
  }
`;



export {CREATE_COMMERCIAL_CONDITION, UPDATE_COMMERCIAL_CONDITION}