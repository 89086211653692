/* istanbul ignore file */
import { Grid, Flex } from '@ifood/pomodoro-components';
import { LockFilled } from '@ifood/pomodoro-icons';
import { CustomerDetailHeader } from './components/CustomerDetailHeader/CustomerDetailHeader';
import { EstablishmentListCard } from './components/EstablishmentListCard';
import { ContactDataCard } from './components/ContactDataCard';
import { RegistrationDataCard } from './components/RegistrationDataCard';
import * as S from './CustomerDetail.styles';
import { useCustomerDetailHook } from './customerDetailHook';
import { StatementDetailCard } from './components/StatementDetailCard';

const CustomerDetailPage = (): JSX.Element => {
  const { customerData, onCancel, onEdit, onSubmit, isEditMode, isSavingContact, form } = useCustomerDetailHook();

  return (
    <S.Wrapper>
      <S.Form onSubmit={form.handleSubmit(onSubmit)}>
        <CustomerDetailHeader
          customerData={customerData}
          isEditMode={isEditMode}
          isFormValid={form.formState.isValid}
          isSavingContact={isSavingContact}
          onCancel={onCancel}
          onEdit={onEdit}
        />
        {(customerData?.name || customerData?.hasActiveFraud) && (
          <Flex alignItems={'center'} flexWrap={'wrap'} mb={16}>
            {customerData?.name && <S.TitleDetail>{customerData?.name}</S.TitleDetail>}
            {customerData?.hasActiveFraud && (
              <S.Tag size="s">
                <Flex alignItems="center" gap={4}>
                  <LockFilled />
                  Conta desativada por fraude
                </Flex>
              </S.Tag>
            )}
          </Flex>
        )}
        <Grid gridGap={30} gridTemplateColumns="1fr 1fr">
          <RegistrationDataCard customerData={customerData} form={form} isEditMode={isEditMode} />
          <ContactDataCard customerData={customerData} />
          <EstablishmentListCard />
          <StatementDetailCard />
        </Grid>
      </S.Form>
    </S.Wrapper>
  );
};

export default CustomerDetailPage;
