import styled from 'styled-components';
import { EmptyState, Heading } from '@ifood/pomodoro-components';

export const FormArea = styled.div`
  margin-bottom: ${({ theme }) => theme.space.largest};
`;

export const FormAreaTitle = styled(Heading)`
  margin-bottom: ${({ theme }) => theme.space.large};
`;

export const FormAreaGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.regular};
  margin-bottom: ${({ theme }) => theme.space.large};

  .cuisine-input-wrapper {
    display: flex;
    align-items: flex-end;
    gap: ${({ theme }) => theme.space.regular};
  }

  .form-list-selection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .autocomplete-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: ${({ theme }) => theme.space.regular};
    align-items: flex-end;
    margin-bottom: ${({ theme }) => theme.space.regular};
  }

  .page-content-container {
    display: flex;
    flex-grow: 1;
    margin-top: ${({ theme }) => theme.space.regular};

    > div {
      width: 100%;
    }

    table {
      td {
        white-space: nowrap;
        padding: ${({ theme }) => theme.space.small};
      }
    }
  }

  .list-owners-radio-group {
    display: flex;
    gap: ${({ theme }) => theme.space.regular};
    flex-direction: row;

    [for='stablishment-option'] {
      color: #b0b0b0;
    }
  }

  .seller-autocomplete {
    width: 400px;
  }

  .product-autocomplete {
    flex-grow: 1;
  }

  .owner-autocomplete {
    width: 500px;
  }
`;

export const ResponseFallback = styled(EmptyState)`
  margin-top: ${({ theme }) => theme.space.largest};

  img {
    width: 300px;
  }
`;
