import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AttachMoney from '@material-ui/icons/AttachMoney';
import BarChart from '@material-ui/icons/BarChart';
import Warning from '@material-ui/icons/Warning';
import TagFacesOutlined from '@material-ui/icons/TagFacesOutlined';

// Shared components
import Paper from '../../../../../components/Seller/Dashboard/Paper';

// Component styles
import styles from './styles';

import ShoppingIcon from '@material-ui/icons/ShoppingBasketOutlined';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import Done from '@material-ui/icons/Done';


class Highlight extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, className, warning, ...rest } = this.props;

    const rootClassName = classNames(classes[this.props.variant], classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <Typography
            className={classes.title}
            variant="body2"
          >
            {this.props.label}
          </Typography>
          <div className={classes.details}>
            <Typography variant="h4" className={classes.value}>{this.props.value}</Typography>
            {this.props.variance ? (<Typography
              className={classes.difference}
              variant="body2"
            >
              <ArrowDropDownIcon />
              {this.props.variance}
            </Typography>) : null}
            <Typography variant="h5" className={classNames(classes.value, classes.plus)}>{this.props.plus}</Typography>
          </div>
        </div>
        <div>
          {
            this.props.icon === 'money' ?
              (<AttachMoney className={classes.icon} />) :
              this.props.icon === 'chart' ?
                (<BarChart className={classes.icon} />) :
                this.props.icon === 'orders' ?
                  (<ShoppingIcon className={classes.icon} />) :
                  this.props.icon === 'date' ?
                    (<CalendarToday className={classes.icon} />) :
                    this.props.icon === 'late' ?
                      (<AssignmentLate className={classes.icon} />) :
                      this.props.icon === 'warning' ?
                        (<Warning className={classes.icon} />) :
                        this.props.icon === 'done' ?
                          (<Done className={classes.icon} />) :
                          this.props.icon === 'happy' ?
                            (<TagFacesOutlined className={classes.icon} />) :
                            null
          }
        </div>

        {warning && <span className={classes.span}>{warning}</span>}

      </Paper>
    );
  }
}

Highlight.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'primary'])
};

export default withStyles(styles)(Highlight);
