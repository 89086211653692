var jwt_decode = require('jwt-decode');

export default class Decoder {
    static decode(token) {
        try{
            return jwt_decode(token);
        }
        catch(error){
            console.error(`Token is invalid: ${error}`)

            window.location.reload()
        }             
    }
}