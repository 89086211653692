import { useEffect } from 'react';
import { Button } from 'ifoodshop-react-ui';

import { useOrderContext } from '@components/_context/OrderContext';

import check from '../../../../assets/icons/check.svg';
import warning from '../../../../assets/icons/warning.svg';

import './ModalWantCancelOrderConfirmation.scss';

function ModalWantCancelOrderConfirmation({ setModalRupture, success }) {
  const { setModalTitle } = useOrderContext();

  useEffect(() => {
    setModalTitle('');
  }, [setModalTitle]);

  function closeModal() {
    setModalRupture(null);
  }

  const closeConfirmation = () => {
    closeModal();
    window.location.reload();
  };

  return (
    <div className="modal-content-order">
      {success ? (
        <>
          <img src={check} alt="Removido" className="modal-content-order__check" />
          <h1 className="modal-content-order__title">Pedido cancelado</h1>
          <p className="modal-content-order__description">
            As informações do pedido serão atualizadas e <br />o estorno será realizado para o cliente
          </p>
          <Button onClick={() => closeConfirmation()} className="button-lg">
            Ok, entendi
          </Button>
        </>
      ) : (
        <>
          <img src={warning} alt="Removido" className="modal-content-order__check" />
          <h1 className="modal-content-order__title">Erro ao remover o produto</h1>
          <p className="modal-content-order__description">Ocorreu um erro ao remover este produto.</p>
          <Button onClick={() => closeModal()} className="button btn--white">
            Tentar novamente
          </Button>
        </>
      )}
    </div>
  );
}

export default ModalWantCancelOrderConfirmation;
