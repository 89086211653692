import gql from 'graphql-tag';

const GET_ALL_COLLECTIONS = gql`
  query getAllCollections(
    $pageRequest: Int,
    $pageSize: Int,
    $name: String,
    $productId: Int,
    $active: Boolean,
  ){
    getAllCollections(
      page: $pageRequest,
      size: $pageSize,
      name: $name,
			productId:$productId,
      active: $active
    ){
      totalPages,
      content {
        id
				name
        startDate
        endDate
			}
    }
  }
`;

const GET_COLLECTION = gql `
  query getCollection(
    $idCollection: ID!
  ) {
    getCollection (
      collectionId: $idCollection
    ) {
      id
      name
      startDate
      endDate
      active
      productCollectionOrders {
        id
        productOrder
        product {
          id
          name
        }
      }
    }
  }
`;

const CHECK_UNIOQUE_NAME = gql`
  query checkUniqueName(
    $name: String
  ){
    checkUniqueName(
      name: $name
    )
  }
`;

const CREATE_COLLECTION = gql`
  mutation createCollection(
    $name: String,
    $productId: [ProductCollectionOrderInput],
    $startDate: String,
    $endDate: String,
    $active: Boolean,
    $userName: String
  ){
    createCollection(
      userName: $userName
      collection: {
        name: $name,
        productCollectionOrders: $productId,
        startDate: $startDate,
        endDate: $endDate,
        active: $active,
      }
    ){
      id
    }
  }
`;

const EDIT_COLLETION = gql`
  mutation updateCollection(
    $collectionId: Int
    $name: String,
    $productId: [ProductCollectionOrderInput],
    $startDate: String,
    $endDate: String,
    $active: Boolean,
    $userName: String
  ){
    updateCollection(
      userName: $userName
      collection: {
        id: $collectionId,
        name: $name,
        productCollectionOrders: $productId,
        startDate: $startDate,
        endDate: $endDate,
        active: $active,
      }
    ){
      id
    }
  }
`;



export { GET_COLLECTION, CREATE_COLLECTION, EDIT_COLLETION, GET_ALL_COLLECTIONS, CHECK_UNIOQUE_NAME}