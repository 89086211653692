import React, {useEffect, useState} from "react";
import {Button, OutlinedInput} from "ifoodshop-react-ui";
import { GET_PAGINATE_SELLERS_BY_NAME } from "@graphql/seller.queries";
import { useLazyQuery } from "@apollo/react-hooks";

const FormSearchSellers = ({ data, currentpage, roles }) => {

	const { roleCommissionWR, roleCommissionRO } = roles;
	const PAGE_SIZE = 20;
	const [sellerName, setSellerName] = useState(null);
	const [getSellers, {data: dataGetSellers}] = useLazyQuery(GET_PAGINATE_SELLERS_BY_NAME, {
		onCompleted: () => {
			data(dataGetSellers.getPaginateSellerByName)
		}
	});
	
	const searchSellers = (e) => {
		e.preventDefault()
		handleRequest(0);
	}
	
	useEffect(() => {
		handleRequest()
	}, [currentpage])
	
	const handleRequest = (forcepage = null) => {
		getSellers({
			variables: {
				name: sellerName,
				page: forcepage != null ? forcepage : currentpage,
				size: PAGE_SIZE
			}
		})
	}

	return (
		<>
			{
				(roleCommissionRO || roleCommissionWR) &&
				<form className="form-brand" onSubmit={searchSellers}>
					<div className="form-row">
						<OutlinedInput
							className="form-brand__input"
							placeholder="Fornecedor"
							border
							onChange={e => setSellerName(e.target.value)}
						/>
					
					</div>
					<div className="form-row">
						<div className="col-2">
							<Button type="submit">Filtrar fornecedores</Button>
						</div>
					</div>
				</form>
			}
		</>
	)
}

export default FormSearchSellers;
