import { Aboyeur } from '@ifood/aboyeur';

export const SessionCatalog = new Aboyeur('sessao_catalog', {
  catalog: {
    /**
     * @when Acessou a tela de catálogo
     */
    viewAdminCatalog: () => ({
      label: 'sessao_catalog',
      metadata: {},
    }),
  },
  product: {
    /**
     * @when Acessou a tela de "produto" no catálogo
     */
    viewAdminCatalogProduct: () => ({
      label: 'sessao_catalog_product',
      metadata: {},
    }),
    /**
     * @when Clicou em "Ver mais ações" em produto no catálogo
     */
    clickButtomSeeActions: () => ({
      label: 'click_buttom_see_actions',
      metadata: {},
    }),
    /**
     * @when Clicou em "Preço/Estoque - Importação"
     */
    clickPriceStockImport: () => ({
      label: 'click_price_stock_import',
      metadata: {},
    }),
    /**
     * @when Clicou em "Preço/Estoque - Exportação"
     */
    clickPriceStockExport: () => ({
      label: 'click_price_stock_export',
      metadata: {},
    }),
    /**
     * @when Clicou em "Editar" no produto na página de produtos do Catálogo
     */
    clickButtomCatalogEdit: params => ({
      label: 'click_button_catalog_edit',
      metadata: {
        ...params,
      },
    }),
  },
  offer: {
    /**
     * @when Verifica as ofertas que possuem preço por Kg na página de edição da oferta
     */
    verifyPricePerKgEditOffer: obj => ({
      label: 'verify_price_kg_edit_offer',
      metadata: obj,
    }),
    /**
     * @when Clicou em "Preço por Kg" na criação da oferta e criou a oferta
     */
    clickPricePerKgCreateOffer: obj => ({
      label: 'click_price_kg_create_offer',
      metadata: obj,
    }),
    /**
     * @when Clicou em "Preço por Kg" na edição da oferta e editou a oferta
     */
    clickPricePerKgEditOffer: obj => ({
      label: 'click_price_kg_edit_offer',
      metadata: obj,
    }),
  },
}).withMetadata({ revision: 4 });
