import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

const TableSuggests = ({ suggests, roleSuggest }) => {
  return suggests && suggests.length > 0 ? (
    <Fragment>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">Fornecedor</th>
            <th className="table-admin__title">Produto</th>
            <th className="table-admin__title">Data de criação</th>
            <th className="table-admin__title">Status</th>
            <th className="table-admin__title">Motivo rejeição</th>
          </tr>
        </thead>
        <tbody>
          {suggests.map(suggest => (
            <tr id={suggest.id} key={suggest.id}>
              <td className="table-admin__data">{suggest.sellerId}</td>
              <td className="table-admin__data">{suggest.name}</td>
              <td className="table-admin__data">{new Date(suggest.createdAt).toLocaleDateString('pt-BR')}</td>
              <td className="table-admin__data">
                {suggest.approved == null ? 'Pendente' : suggest.approved ? 'Aprovado' : 'Reprovado'}{' '}
              </td>
              <td className="table-admin__data">{suggest.cancelMotive ? suggest.cancelMotive : ''}</td>
              <td className="table-admin__data">
                {!suggest.approved ? (
                  roleSuggest ? (
                    <NavLink
                      className={suggest.approved !== false ? 'link' : 'link link--disabled'}
                      to={suggest.approved !== false ? '/catalogos/sugestao/editar/' + suggest.id : '#'}
                    >
                      Abrir
                    </NavLink>
                  ) : (
                    <Tooltip title="Você não tem permissão para editar sugestões.">
                      <a className="link--disabled">Abrir</a>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title="Já aprovado">
                    <a className="link--disabled">Abrir</a>
                  </Tooltip>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  ) : null;
};

export default TableSuggests;
