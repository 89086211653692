import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { OutlinedInput, Link, Button, Typography } from 'ifoodshop-react-ui';

import { useProductSuggestionContext } from '@components/_context/ProductSuggestioContext';
import TinyEditor from '@components/TextEditor';
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';
import DragDrop from '@components/DragDrop';
import OutlinedNumberInput from '@components/OutlinedNumberInput';
import ProductCustomFields from '../../ProductCustomFields';
import ModalSuggestion from '../ModalSuggestion';

import ImgAlert from '../../../../../assets/icons/warning.svg';

import './formsuggestion.scss';

const FormSuggestion = () => {
  const history = useHistory();
  const {
    productId,
    productName,
    setStep,
    price,
    brandName,
    categoryName,
    categoryId,
    quantityAvailable,
    sku,
    description,
    width,
    unity,
    height,
    length,
    weight,
    barcode,
    multiples,
    modal,
    setModal,
    productImage,
    variationImage,
    getProductImages,
    getVariationImages,
    createOffer,
    getCategoryDetail,
    getSpecs,
    fields,
    fieldKeys,
    dataSpecs,
    setFieldsValues,
    id_seller,
    variationId,
  } = useProductSuggestionContext();

  useEffect(() => {
    if (productId && price && quantityAvailable && sku) {
      getProductImages({
        variables: {
          product: productId,
        },
      });
      getVariationImages({
        variables: {
          variation: parseInt(variationId),
        },
      });
      getCategoryDetail({
        variables: {
          id: categoryId,
          fieldsType: 'PRODUCT',
        },
      });
      getSpecs({
        variables: {
          product: productId,
        },
      });
    } else {
      history.push('/catalogos/sugestao/cadastro');
    }
  }, []);

  const [variableWeight, setVariableWeight] = useState('Habilitado');
  const registerOffer = e => {
    e.preventDefault();

    let floatprice = parseFloat(price.replace('R$ ', '').replaceAll('.', '').replace(',', '.'));

    let sendItems = {
      seller: id_seller,
      sellerCode: sku,
      price: floatprice,
      active: true,
      stock: parseInt(quantityAvailable),
      variation: parseInt(variationId),
      variableWeight: true,
      limitQuantity: null,
    };

    createOffer({ variables: sendItems });
  };

  function handleModal() {
    setModal(<ModalSuggestion setModal={setModal} />);
  }

  return (
    <div>
      <form className="form-register form-suggestion" id="frm-reg__suggestion">
        <div className="header-product__description">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Informações do Produto
          </Typography>
        </div>
        <div className="product-name">
          <OutlinedInput disabled={true} placeholder="Nome do Produto" value={productName} border />
        </div>

        <TinyEditor readonly="disabled" width={1045} initialValue={description} />

        <div className="autocomplete">
          <div className="autocomplete__brand">
            <OutlinedInput disabled={true} placeholder="Marca" value={brandName} border />

            <OutlinedInput disabled={true} placeholder="Categoria" value={categoryName} border />
          </div>
          <div className="product-itens">
            <OutlinedInput disabled={true} placeholder="SKU" border value={sku} />

            <OutlinedInput disabled={true} placeholder="Código de barras" border value={barcode} />
          </div>
        </div>
        <div className="product-unity">
          <OutlinedInput disabled={true} placeholder="Unidade" border value={unity} />

          <OutlinedInput disabled={true} placeholder="Quantidade múltiplos" border value={multiples} />
        </div>
        <p className="product-section"></p>
        <div className="header-product__description">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Preço
          </Typography>
        </div>
        <div className="product-price">
          <OutlinedInputCurrency disabled={true} value={price} placeholder="Preço" />
          <OutlinedInput disabled={true} value={variableWeight} placeholder="Peso Variável" />
        </div>
        <p className="product-section"></p>
        <div className="header-product__description">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Estoque
          </Typography>
        </div>
        <div className="product-inventory">
          <OutlinedInput disabled={true} placeholder="Quantidade disponível" border value={quantityAvailable} />
        </div>
        <p className="product-section"></p>
        <div className="header-product__description">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Dimensões para cálculo de frete
          </Typography>
        </div>
        <div className="dimensions">
          <OutlinedNumberInput disabled={true} placeholder="Altura (cm)" border value={height || ''} />

          <OutlinedNumberInput disabled={true} placeholder="Largura (cm)" border value={width || ''} />
          <OutlinedNumberInput disabled={true} placeholder="Comprimento (cm)" border value={length || ''} />
          <OutlinedNumberInput disabled={true} placeholder="Peso (g)" border value={weight || ''} />
        </div>
        {fieldKeys ? (
          <>
            <p className="product-section"></p>
            <div className="header-product__description__title">
              <Typography size="20px" weight="regular" line="24px" color="heavygrey">
                Ficha Técnica
              </Typography>
            </div>
            <div className="header-product__description">
              <Typography size="18px" weight="normal" line="24px" color="heavygrey">
                Com a ficha completa, seus compradores terão todas as informações necessárias para comprarem de você
              </Typography>
            </div>
            <div className="product-fields">
              {fields ? (
                <ProductCustomFields
                  readonly="true"
                  fields={fields}
                  fieldKeys={fieldKeys}
                  fielddata={dataSpecs}
                  data={e => setFieldsValues(e)}
                  header="false"
                />
              ) : null}
            </div>
          </>
        ) : null}
        <p className="product-section"></p>
        <div className="header-product__description__title">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Imagens
          </Typography>
        </div>
        <div className="product-images">
          {variationImage && variationImage.length > 0 ? (
            <div className="product-images__item">
              <DragDrop variationId={variationId} readonly="disabled" />
            </div>
          ) : productImage.length > 0 ? (
            <div className="product-images__item">
              <DragDrop productid={productId} readonly="disabled" />
            </div>
          ) : null}
        </div>
        <p className="product-section"></p>
        <div className="button-suggestion">
          <NavLink onClick={() => setStep(2)} to="/catalogos/sugestao/cadastro/condicao" className="btn-secondary">
            Voltar
          </NavLink>
          <Button className="btn btn-primary" onClick={registerOffer}>
            Cadastrar
          </Button>
        </div>
        <div className="suggestion-prod">
          <div className="suggestion-prod__header">
            <div>
              <img className="suggestion-prod__img" src={ImgAlert} alt="Alerta" />
            </div>
            <div className="suggestion-prod__title">
              <p>Os dados não estão corretos?</p>
            </div>
          </div>
          <div className="suggestion-prod__description">
            <Typography size="16px" weight="normal" line="24px" color="heavygrey">
              Caso haja divergência em alguma informação, será necessário cadastrar um novo produto sujeito a aprovação
              do time de cadastro do iFood Shop. Para isso, clique no botão{' '}
              {
                <Link onClick={handleModal} className="link">
                  Quero cadastrar um produto novo
                </Link>
              }{' '}
              e preencha todas as informações necessárias.
            </Typography>
          </div>
        </div>
        {modal}
      </form>
    </div>
  );
};

export default FormSuggestion;
