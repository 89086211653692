import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPGRADE_ORDER_DELIVERY_STATUS_INVOICE } from '../../../../../graphql/order-delivery.mutations';
import { OutlinedInput, Button, Typography } from 'ifoodshop-react-ui';
import DatePicker from 'react-datepicker';
import ptbr from 'date-fns/locale/pt-BR';

import Loading from '@components/Loading';
import OutlinedTextarea from '@components/OutlinedTextarea';

import { formatDateToIsoLocal } from '../../orderUtils';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/order/arrow-right.svg';

function HandleBilled({ orderDeliveryId, buttonText }) {
  const [errorPage, setErrorPage] = useState(null);
  const [issuedDate, setIssuedDate] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [number, setNumber] = useState(null);
  const [errorNumber, setErrorNumber] = useState(null);
  const [series, setSeries] = useState(null);
  const [errorSeries, setErrorSeries] = useState(null);
  const [url, setUrl] = useState(null);
  const [updateObservation, setUpdateObservation] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  const [updateOrderStatus, { error, data, loading }] = useMutation(UPGRADE_ORDER_DELIVERY_STATUS_INVOICE);
  if (data) window.location.reload(false);
  if (error) {
    setErrorPage('Não Foi Possivel Alterar O Status Da Entrega');
  }

  function toBilled(e) {
    setDisabledButton(true);
    e.preventDefault();
    setErrorNumber(null);
    setErrorSeries(null);
    setErrorDate(null);

    !number && setErrorNumber('Campo Obrigatório');
    !series && setErrorSeries('Campo Obrigatório');
    !issuedDate && setErrorDate('Campo Obrigatório');

    if (number && series && issuedDate)
      updateOrderStatus({
        variables: {
          orderDeliveryId,
          updateStatus: 'BILLED',
          updateTime: formatDateToIsoLocal(issuedDate),
          updateObservation,
          issuedDate: formatDateToIsoLocal(issuedDate),
          number,
          series,
          url,
          trackingCode: null,
        },
      });
  }

  function checkDate(date) {
    const dateNow = new Date();

    if (dateNow < date) {
      setDisabledButton(true);
      setAlertMessage(true);
    } else {
      setIssuedDate(date);
      setDisabledButton(false);
      setAlertMessage(false);
    }
  }

  return (
    <>
      <h2 className="modal-alter-status__title">
        Em separação <ArrowRight className="alter-status-arrow" /> Faturado
      </h2>
      <h4 className="modal-alter-status__form__error">{errorPage}</h4>
      <form className="modal-alter-status__form">
        <div>
          <OutlinedInput placeholder="Número da NF *" border value={number} onChange={e => setNumber(e.target.value)} />{' '}
          {/* OBRIGATÓRIO*/}
          <p className="modal-alter-status__form__error">{errorNumber}</p>
        </div>
        <div>
          <OutlinedInput placeholder="Série da NF *" border value={series} onChange={e => setSeries(e.target.value)} />{' '}
          {/* OBRIGATÓRIO*/}
          <p className="modal-alter-status__form__error">{errorSeries}</p>
        </div>
        <OutlinedInput placeholder="URL da NF" border value={url} onChange={e => setUrl(e.target.value)} />
        <div>
          <div className={`outlined-label-wrap outlined-date ${issuedDate ? 'active' : ''}`}>
            <DatePicker
              className="form-brand__input"
              onChange={date => checkDate(date)}
              locale={ptbr}
              showTimeSelect
              timeIntervals={15}
              selected={issuedDate}
              dateFormat="dd/MM/yyyy hh:mm aa"
              maxDate={new Date()}
            />
            <label className="outlined-label">Data de emissão da NF*</label>
          </div>
          <p className="modal-alter-status__form__error">{errorDate}</p>
        </div>
        <div className="form-edit__input-group">
          <OutlinedTextarea
            placeholder="Observação"
            value={updateObservation}
            onChange={e => setUpdateObservation(e.target.value)}
          />
        </div>
        {alertMessage && (
          <Typography className="modal-alter-status__error-date" size="12px" weight="thin">
            Data de altereção de status não pode ser superior a data atual
          </Typography>
        )}
        <Button className="modal-alter-status__submit" disabled={disabledButton} onClick={e => toBilled(e)}>
          {buttonText}
        </Button>
      </form>
      {loading && <Loading />}
    </>
  );
}

export default HandleBilled;
