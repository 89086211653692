import { NavLink } from 'react-router-dom';

import FormatHelper from '@helpers/FormatHelper';

const ModalDiscounts = ({ subsidies }) => {
  return (
    <>
      <h3>Subsídios:</h3>
      {subsidies?.map(
        (subsidy, index) =>
          subsidy.amount > 0 && (
            <div
              className="order-detail__card-list"
              key={`${subsidy.orderSellerId}-${index}`}
              id={`${subsidy.orderSellerId}-${index}`}
            >
              <p className="order-detail__card-item">
                Nome:
                <NavLink
                  className="order-detail__link"
                  target="_blank"
                  to={`/campanhas/descontos/editar/${subsidy?.discount?.id}`}
                >
                  <span className="order-detail__delivery-value">
                    {subsidy?.discount?.isCoupon ? subsidy?.discount?.couponCode : subsidy?.discount?.name}
                  </span>
                </NavLink>
              </p>
              <p className="order-detail__card-item">
                Tipo do desconto:
                <span className="order-detail__delivery-value">
                  {subsidy.type === 'SELLER' ? 'Fornecedor' : 'Centro de Custo'}
                </span>
              </p>
              <p className="order-detail__card-item">
                Total:
                <span className="order-detail__delivery-value discount-colored">
                  {FormatHelper.format(subsidy.amount, 'currency')}
                </span>
              </p>
            </div>
          )
      )}
    </>
  );
};

export default ModalDiscounts;
