import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal } from 'ifoodshop-react-ui';
import { APPROVED_SUGGEST } from '../../../graphql/suggestion.mutation';

import { getRoles } from '@helpers/rolesUtils';

import FormEditSuggestion from '../components/FormEditSuggestion';
import HeaderRegisterProduct from '../components/HeaderRegisterProduct';
import FormReject from '@components/RejectSuggest';

import './suggestion.scss';

const SuggestionEdit = props => {
  const roles = getRoles();

  if (roles.includes('SELLER-WR') || roles.includes('SELLER-RO')) window.location.href = '/';

  const [modal, setModal] = useState(null);
  const [approveSuggest] = useMutation(APPROVED_SUGGEST);

  function changeModal(message) {
    setModal(<Modal onCloseAction={() => setModal(null)} title={message} />);
  }

  function rejectSuggestion(e) {
    e.preventDefault();
    setModal(
      <Modal onCloseAction={() => setModal(null)} title="Motivos para rejeição">
        <FormReject suggestionId={props.match.params.suggestionid} responseModal={e => changeModal(e)} />
      </Modal>
    );
  }

  const approveSuggestion = () => {
    approveSuggest({
      variables: {
        id: parseInt(props.match.params.suggestionid),
      },
    })
      .then(res => {
        window.location.href = '/catalogos/sugestoes';
      })
      .catch(error => {
        changeModal('Erro ao aprovar a sugestão!');
      });
  };

  return (
    <>
      {!roles.includes('SELLER-WR') && !roles.includes('SELLER-RO') && (
        <>
          <NavLink to="/catalogos/sugestoes" className="go-back" />
          {roles.includes('SUGGESTION-APR') ? (
            <>
              <div className="content-body suggestion-register">
                {modal}
                <HeaderRegisterProduct
                  title="Sugestão de produtos"
                  description="Verifique os campos abaixo para editar uma sugestão de produto"
                />
                <FormEditSuggestion suggestionid={props.match.params.suggestionid} />
                <div className="button-suggestion ">
                  <Button className="btn-secondary" onClick={e => rejectSuggestion(e)}>
                    Rejeitar
                  </Button>
                  <Button className="btn" onClick={e => approveSuggestion(e)}>
                    Aprovar
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="content-body suggestion-register">
                <HeaderRegisterProduct
                  title="Sugestão de produtos"
                  description="Você não tem permissão para editar uma sugestão."
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default SuggestionEdit;
