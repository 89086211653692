import { Button } from 'ifoodshop-react-ui';

import FormatHelper from '@helpers/FormatHelper';

import { useOrderContext } from '@components/_context/OrderContext';

import './ModalWantCancelSellerOrder.scss';

function ModalWantCancelSellerOrder({ items, modal, setModalRupture, orderChild, openModal }) {
  const { isSeller } = useOrderContext();

  function handleTotalValue({ sellerUnitPrice, quantity, unitPrice }) {
    return isSeller ? sellerUnitPrice * quantity : unitPrice * quantity;
  }

  const handleClose = () => {
    setModalRupture(null);
  };

  let listItems = orderChild.deliveries[0].items;

  return (
    <div className="containerOrder">
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">SKU</th>
            <th className="table-admin__title">Produto</th>
            <th className="table-admin__title">Qtd.</th>
            <th className="table-admin__title">Total</th>
          </tr>
        </thead>
        <tbody>
          {listItems.map(function (item) {
            return (
              <tr>
                <td className="table-admin__data">{item.variation?.referenceCode}</td>
                <td className="table-admin__data">{item.name}</td>
                <td className="table-admin__data">{item.quantity}</td>
                <td className="table-admin__data">{FormatHelper.format(handleTotalValue(item), 'currency')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="containerOrder__modalbtns">
        <Button className="buttonOrder btn-secondary" onClick={() => handleClose()}>
          Fechar
        </Button>
        <Button type="submit" onClick={() => openModal('WANT_REASON')} className="buttonOrder">
          Quero continuar
        </Button>
      </div>
      {modal}
    </div>
  );
}

export default ModalWantCancelSellerOrder;
