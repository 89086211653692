import { useState, useEffect, useCallback } from 'react';
import { Button, Modal } from 'ifoodshop-react-ui';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrderDetail } from '@analytics/aboyeur/entities/sessionOrderDetail';

import ModalWantCancelOrderReason from '../ModalWantCancelOrderReason';
import ModalWantCancelOrder from '../ModalWantCancelOrder';
import ModalWantCancelOrderConfirmation from '../ModalWantCancelOrderConfirmation';
import BounceLoader from '@components/Loader/Bounce';

import './ModalQuantity.scss';

function ModalQuantity({ item, items, disableEdit, onConfirm, qtdProductsOrder, setModalRupture }) {
  const [quantity, setQuantity] = useState(item.quantity);
  const [qtdProd, setQtdProd] = useState(item.quantity);
  const [edit, setEdit] = useState(true);
  const [modal, setModal] = useState(null);
  const [originalQuantity, setOriginalQuantity] = useState(item.quantity);
  const [hasHistory, setHasHistory] = useState(false);
  const [modalTitle, setModalTitle] = useState('Diminuir Quantidade');
  const roles = getRoles();
  const roleCancelOrder = roles.includes(Roles.CANCEL_ORDER);
  //faster
  const sessionOrderDetail = useAboyeur(SessionOrderDetail);

  let qtdProductActive = items.filter(item => item.quantity !== 0);

  const hasUpdateItems = items.filter(({ hasHistory }) => hasHistory);

  const handleModalTitle = useCallback(
    () => setModalTitle(qtdProductActive.length === 1 && quantity === 0 ? 'Remover produto' : 'Diminuir Quantidade'),
    [quantity]
  );

  useEffect(() => {
    handleModalTitle();
  }, [quantity]);

  function showMessageQtdUnit() {
    if (qtdProductActive.length === 1 && quantity === 1 && !roleCancelOrder) {
      return (
        <p className="modal-content-quantity__description">
          Este é o último item do pedido e, por esse motivo, não pode ser removido.
        </p>
      );
    } else if (qtdProductActive.length === 1 && quantity === 0) {
      return (
        <p className="modal-content-quantity__description">
          Você tem certeza que gostaria de <strong>Cancelar este pedido</strong>?
          <br />
          <br />
          <strong>Atenção:</strong> Caso queira remover todos os itens e cancelar o pedido, clique em Continuar.
        </p>
      );
    } else {
      return (
        <p className="modal-content-quantity__description">
          Este produto possui mais de uma unidade. Remova até chegar na quantidade desejada.
          <br />
          <br />
          {hasUpdateItems.length === 0 && roleCancelOrder ? (
            <>
              <strong>
                Se deseja cancelar o pedido, remova até zero até o último produto ou clique em Cancelar pedido acima.
              </strong>{' '}
              <br />
              <br />
            </>
          ) : null}
          <strong>Atenção:</strong> Uma vez que a quantidade for removida, ela não poderá ser adicionada novamente.
        </p>
      );
    }
  }

  const handleClickConfirm = () => {
    qtdProductActive.length === 1 && quantity === 0 ? openModal('WANT_CANCEL') : onConfirm(quantity);
    //faster - Session
    sessionOrderDetail.events.order_detail.clickQuantityConfirm({
      //Propriedades > Metadata
      itemId: item.id,
      newItemQuantity: quantity,
    });
  };

  useEffect(() => {
    if (items) {
      items.forEach(data => {
        if (data.hasHistory) {
          setHasHistory(true);
        } else {
          setHasHistory(false);
        }
      });
    }
  }, [items]);

  function closeModal() {
    setModal(null);
    setModalRupture(null);
    window.location.reload(false);
  }

  function openModal(step, success) {
    setModal(
      <Modal
        onCloseAction={
          step === 'WANT_CONFIRM'
            ? () => {
                closeModal();
              }
            : setModal(null)
        }
        title={step !== 'WANT_CONFIRM' ? 'Deseja cancelar esse pedido?' : null}
      >
        {RuptureModalSteps(step, success)}
      </Modal>
    );
  }

  const RuptureModalSteps = (step, success) => {
    if (step === 'WANT_CANCEL')
      return (
        <ModalWantCancelOrder
          items={items}
          item={item}
          qtdProductsOrder={qtdProductsOrder}
          modal={modal}
          setModalRupture={setModalRupture}
          openModal={openModal}
        />
      );
    else if (step === 'WANT_REASON')
      return (
        <ModalWantCancelOrderReason
          items={items}
          item={item}
          modal={modal}
          setModalRupture={setModalRupture}
          openModal={openModal}
        />
      );
    else if (step === 'WANT_CONFIRM')
      return (
        <ModalWantCancelOrderConfirmation
          success={success}
          items={items}
          item={item}
          setModalRupture={setModalRupture}
        />
      );
  };

  function increment() {
    setQuantity(quantity + 1);
  }

  function decrement() {
    setQuantity(quantity - 1);
    setQtdProd(qtdProd - 1);
  }

  return (
    <>
      {edit ? (
        <div className="modal-content-quantity">
          <h2 className="modal-content-quantity__title">{modalTitle}</h2>
          {showMessageQtdUnit()}
          <div className="modal-content-quantity__count">
            <h3 className="count-name">{item.name}</h3>
            <div className="count-increment-decrement">
              <button
                onClick={decrement}
                disabled={
                  roleCancelOrder
                    ? (qtdProductActive.length === 1 && quantity === 0) || quantity === 0
                    : quantity === 0 || (qtdProductActive.length === 1 && quantity === 1)
                }
                className={
                  quantity === 0 ||
                  (qtdProductActive.length === 1 && quantity === 0) ||
                  (!roleCancelOrder && quantity === 1 && qtdProductActive.length === 1)
                    ? 'counter-button counter_minus--inactive'
                    : 'counter-button counter_minus--active'
                }
              />
              <h2 className="count-number"> {quantity} UN.</h2>
              <button
                onClick={increment}
                disabled={quantity === originalQuantity}
                className={
                  quantity === originalQuantity
                    ? 'counter-button counter_plus--inactive'
                    : 'counter-button counter_plus--active'
                }
              />
            </div>
          </div>
          <Button
            disabled={quantity === originalQuantity}
            className={quantity === originalQuantity ? 'button--disabled' : 'button'}
            onClick={() =>
              qtdProductActive.length === 1 && quantity === 0 && roleCancelOrder
                ? openModal('WANT_CANCEL')
                : setEdit(false)
            }
          >
            Continuar
          </Button>
        </div>
      ) : (
        <div className="modal-content-quantity">
          <h2 className="modal-content-quantity__title">{modalTitle}</h2>
          <p className="modal-content-quantity__description">
            Você tem certeza que gostaria de <strong>remover a quantidade deste produto</strong>?
          </p>
          <p className="modal-content-quantity__description">
            Atenção: Uma vez que a quantidade for removida, ela não poderá ser adicionada novamente.
          </p>
          <Button disabled={disableEdit} onClick={handleClickConfirm} className="modal-content-quantity_confirm">
            {disableEdit ? <BounceLoader className="button-loader"/> : 'Confirmar'}
          </Button>
        </div>
      )}
      {quantity === 0 ? modal : null}
    </>
  );
}

export default ModalQuantity;
