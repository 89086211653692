export default class Formatter {
    static currency(value) {
        if (value == 0) return 'R$ 0,00'
        if (!value) return ' - ';

        let val = (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.');
        let decimal = val.lastIndexOf('.');
        return 'R$ ' + val.substring(0, decimal) + ',' + val.substring(decimal + 1);
    }

    static ptBrDate(value, type) {
        let zeroPad = (v) => parseInt(v) < 10 ? '0' + v : v;
        let getDate = (d) => zeroPad(d.getDate()) + '/' + zeroPad(d.getMonth() + 1) + '/' + d.getFullYear();
        let getHour = (d) => zeroPad(d.getHours()) + ':' + zeroPad(d.getMinutes());

        if (value) {
            let data = new Date(value);
            return type === 'simple' ? getDate(data) : getDate(data) + ' ' + getHour(data);
        }

        return '';
    }

    static ptBrDateFormated(value) {
        let date = new Date(value)
        let year = date.getFullYear();
        let month = date.getMonth();
        month++
        let monthFormated = month < 10 ? '0'+ month.toString() : month
        let day = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate();

        return `${year}-${monthFormated}-${day}`
    }

    static nFormat(input, v) {
        let pattern = input + '';
        for (let i = 0; i < v.length; i++)
            pattern = pattern.replace('{' + i + '}', v[i]);
        return pattern;
    }

    static document (value) {
        if (value) {
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,function( regex, arg1, arg2, arg3, arg4, arg5) {
                return `${arg1}.${arg2}.${arg3}/${arg4}-${arg5}`;
            });
        } else {
            return value
        }
    }

    static phone (value) {
        if(!value)
            return null;

        return value.replace(/(\d{2})(\d{4})(\d{4})/,function( regex, arg1, arg2, arg3) {
            return `(${arg1}) ${arg2}-${arg3}` ;
        });
    }

    static mobile_phone (value) {
        if(!value)
            return null;

        return value.replace(/(\d{2})(\d{5})(\d{4})/,function( regex, arg1, arg2, arg3) {
            return `(${arg1}) ${arg2}-${arg3}` ;
        });
    }

    static cep (postalCode) {
        if (postalCode) {
            postalCode = postalCode.toString().replace('-', '');
            let start = postalCode.slice(0, postalCode.length-3);
            const end = postalCode.slice(-3);
            start = start.padStart(5, '0');
            return `${start}-${end}`;
        }

        return '';
    }

    static formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
    }

    static format(value, type) {
        switch (type) {
            case 'currency':
                return Formatter.currency(value);
            case 'date':
                return Formatter.ptBrDate(value);
            case 'simple-date':
                return Formatter.ptBrDate(value, 'simple');
            case 'date-formated':
                return Formatter.ptBrDateFormated(value)
            case 'document':
                return Formatter.document(value);
            case 'mobile-phone':
                return Formatter.mobile_phone(value);
            case 'phone':
                return Formatter.phone(value);
            case 'cep':
                return Formatter.cep(value);
            case 'bytes':
                return Formatter.formatBytes(value);
            default:
                return value;
        }
    }
}


export const transformDateBR = (data) => {
    if (data) {
      const newData = data.split('-');
      if (newData.length == 3) {
        const formatData = `${newData[2]}/${newData[1]}/${newData[0]}`;
        return formatData;
      }
    }
    return '';
  };


export const transformDateUS = (initialDate) => {
    if (initialDate) {
      const year = initialDate.getFullYear();
      let month = (initialDate.getMonth() + 1).toString();
      let day = initialDate.getDate().toString();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return `${year}-${month}-${day}`;
    }
    return '';
  };
  
export const invertMonthDay = (date) => {
    const isSlashDate = date.match(/\//g).length === 2;

    if (isSlashDate) {
        return `${date.substring(3, 5)}/${date.substring(0, 2)}/${date.substring(6, 10)}`
    }

    return date;
}


export   const dateFormatUS = (date) => {
    let acceptedFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    if (date){
        if (acceptedFormat.test(date)){
            const dateReverse = date?.split("/").reverse().join("-").concat('T00:01');
            const newData = new Date(dateReverse)
            return newData
        }
        return new Date(date)
    }
    return null;
}