import gql from 'graphql-tag';

const GET_CAMPAIGN_GROUPS = gql `
  query getAllCampaignGroups{
    getAllCampaignGroups{
        id
        name
      }
  }
`;

export {GET_CAMPAIGN_GROUPS};