/* istanbul ignore file */
import { Flex, Text, Toggle } from '@ifood/pomodoro-components';

interface IEstablishmentSintegraBypass {
  sintegrawsBypass: boolean;
  updateSintegrawsBypass: (value: boolean) => void;
  hasEditingRule: boolean;
}

export default function EstablishmentSintegraBypass({
  sintegrawsBypass,
  updateSintegrawsBypass,
  hasEditingRule,
}: IEstablishmentSintegraBypass) {
  return (
    <Flex gap={8} mb={32}>
      <Toggle
        checked={sintegrawsBypass}
        disabled={!hasEditingRule}
        width={'auto'}
        onChange={() => updateSintegrawsBypass(!sintegrawsBypass)}
      />
      <Text>Isenção de validação do CNPJ no Sintegra</Text>
    </Flex>
  );
}
