import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_ORDER } from '../../../graphql/order.queries';
import { Grid, Link, Modal } from 'ifoodshop-react-ui';
import Tooltip from '@material-ui/core/Tooltip';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrderDetail } from '@analytics/aboyeur/entities/sessionOrderDetail';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import OrderHelper from '@helpers/OrderHelper';
import FormatHelper from '@helpers/FormatHelper';

import { OrderProvider } from '@components/_context/OrderContext';
import Loading from '@components/Loading';
import OrderChild from '../components/OrderChild';
import OrderCustomer from '../components/OrderCustomer';
import OrderPayments from '../components/OrderPayments';
import OrderResume from '../components/OrderResume';
import PixChargeback from '../components/PixChargeback';
import HandleCancelOrder from '../components/AlterStatus/HandleCancelOrder';
import HandleProcessRefund from '../components/AlterStatus/HandleProcessRefund';

import { ReactComponent as InfoIcon } from '../../../assets/icons/Info.svg';
import './orderDetail.scss';

function OrderDetail(props) {
  const [order, setOrder] = useState();
  const [isSeller, setIsSeller] = useState(false);
  const [hasPendingRefund, setHasPendingRefund] = useState(false);
  const [variableWeight, setVariableWeight] = useState(false);
  const [modal, setModal] = useState();
  const [modalTitle, setModalTitle] = useState('Diminuir Quantidade');
  const [original, setOriginal] = useState(false);

  const orderId = props.match.params.id;
  const history = useHistory();
  const roles = getRoles();

  const sessionOrderDetail = useAboyeur(SessionOrderDetail);

  const roleOrderWrite = roles.includes(Roles.ORDER_WR);
  const roleSellerWrite = roles.includes(Roles.SELLER_WR);
  const roleSellerReadOnly = roles.includes(Roles.SELLER_RO);
  const rupturePaymentProcessed = roles.includes(Roles.RUPTURE_PAYMENT_PROCESSED);

  if (!roleOrderWrite) history.push('/');
  if ((roleSellerWrite || roleSellerReadOnly) && !isSeller) setIsSeller(true);

  const { loading, data, refetch } = useQuery(GET_ORDER, {
    variables: { orderId },
    onError: error => {
      if (error.graphQLErrors[0].extensions['code'] === 'FORBIDDEN') history.push('/pedidos');
    },
  });

  if (data && order == null) {
    setOrder(data.getOrder);

    setHasPendingRefund(data?.getOrder?.hasPendingRefund);

    data?.getOrder?.orderSellers?.forEach(seller => {
      seller.deliveries.forEach(delivery => {
        delivery.items.forEach(item => {
          if (item.variableWeight === true) {
            setVariableWeight(true);
          }
        });
      });
    });
  }
  const orderProps = {
    refetch,
    setOrder,
    setModal,
    modal,
    order,
    isSeller,
    modalTitle,
    setModalTitle,
    original,
    setOriginal,
  };

  const isNewIntegratedSeller = useMemo(() => {
    return order?.orderSellers[0].seller?.isNewIntegration;
  }, [order]);

  const handleCancelOrder = () => {
    setModal(
      <Modal className="modal-alter-status" onCloseAction={() => setModal(null)}>
        <HandleCancelOrder orderId={orderId} />
      </Modal>
    );
  };

  const handleProcessRefund = () => {
    setModal(<HandleProcessRefund orderId={orderId} onCloseAction={() => setModal(null)} />);
  };

  useEffect(() => {
    sessionOrderDetail.events.order_detail.viewAdminOrderDetail();
  }, [sessionOrderDetail]);

  const hasCanceledOrderChild = useMemo(() => {
    const orderChilds = order?.orderSellers;

    const deliveries = orderChilds?.map(orderChild => orderChild?.deliveries[0]);
    const canceledDeliveries = deliveries?.some(delivery => delivery.step === 'CANCELLED');

    return order?.paymentProcessed || canceledDeliveries;
  }, [order]);

  return (
    <>
      {roleOrderWrite && data && (
        <OrderProvider {...orderProps}>
          <button className="go-back go-back--btn" onClick={history.goBack} />
          {order && (
            <div className="content-body">
              <h2 className="section-title">
                Detalhes do Pedido - #{order && order.id}
                {variableWeight && (
                  <Tooltip title="Contém item com peso variavel">
                    <a className="link--disabled">
                      {' '}
                      <InfoIcon className="icon-trash" width={20} />
                    </a>
                  </Tooltip>
                )}
              </h2>
              <div className="order-detail">
                <>
                  {!isSeller && (
                    <div className="order-detail__summary">
                      <p className="order-detail__summary-title">Resumo do pedido</p>
                      <div className="order-detail__summary-value">
                        <p className="order-detail__summary-value-item">
                          Status:
                          <span>{OrderHelper.handleOrderDeliveryStatus(data?.getOrder?.step)}</span>
                        </p>
                        <p className="order-detail__summary-value-item">
                          Valor dos produtos:
                          <span>{FormatHelper.format(order.itemsAmount + order.itemsDiscountAmount, 'currency')}</span>
                        </p>
                        <p className="order-detail__summary-value-item">
                          Frete:
                          <span>
                            {FormatHelper.format(order.shippingAmount + order.shippingDiscountAmount, 'currency')}
                          </span>
                        </p>
                        {order.totalDiscountAmount > 0 && (
                          <p className="order-detail__summary-value-item">
                            Total dos descontos:
                            <span>{FormatHelper.format(order.totalDiscountAmount, 'currency')}</span>
                          </p>
                        )}

                        <p className="order-detail__summary-value-item">
                          Total pedido:
                          <span>{FormatHelper.format(order.totalAmount, 'currency')}</span>
                        </p>
                        {!isNewIntegratedSeller && (
                            <p className="order-detail__summary-value-item">
                            {data?.getOrder?.step !== 'CANCELLED' && !isSeller ? (
                              <Link onClick={() => handleCancelOrder()}>Cancelar pedido</Link>
                            ) : (
                              ''
                            )}
                          </p>
                        )}

                        {hasPendingRefund && rupturePaymentProcessed ? (
                          <p className="order-detail__summary-value-item">
                            <Link onClick={() => handleProcessRefund()}>Processar Reembolso</Link>
                          </p>
                        ) : (
                          ''
                        )}

                        {!isNewIntegratedSeller && order.payments?.some(({ type }) => type === 'PIX') && hasCanceledOrderChild && (
                          <div className="order-detail__summary-value-item">
                            <PixChargeback refetch={refetch} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    className={`order-detail__payments-customer ${
                      order?.payments?.length > 1 || isSeller ? 'cards-stretch' : ''
                    }`}
                  >
                    <Grid wrap style={{ margin: 0 }}>
                      <Grid.Item xs={6}>
                        <Grid wrap>
                          <Grid.Item xs={12}>
                            <OrderResume isSeller={isSeller} orderChild={order.orderSellers} />
                          </Grid.Item>
                          {!isSeller && (
                            <Grid.Item xs={12}>
                              <OrderPayments payments={order.payments} original={original} orderChild={order} />
                            </Grid.Item>
                          )}
                        </Grid>
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <OrderCustomer
                          customer={order.customer}
                          billingAddress={order.billingAddress}
                          shippingAddress={order.shippingAddress}
                          isSeller={isSeller}
                          obs={order.note}
                        />
                      </Grid.Item>
                    </Grid>
                  </div>
                  {order?.orderSellers?.map((orderChild, index) => (
                    <OrderChild
                      key={index}
                      orderChild={orderChild}
                      isSeller={isSeller}
                      index={index}
                      step={order.step}
                      payments={order.payments}
                    />
                  ))}
                </>
              </div>
            </div>
          )}
          {modal}
        </OrderProvider>
      )}
      {loading && <Loading />}
    </>
  );
}

export default OrderDetail;
