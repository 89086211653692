import { useEffect } from 'react';
import CatalogItem from '../../components/CatalogItem';
import ImageCustomer from '../../assets/images/home-customers.svg';
import ImageCampaign from '../../assets/images/home-campaign.svg';
import ImageCatalog from '../../assets/images/home-catalog.svg';
import ImageOrder from '../../assets/images/home-order.svg';
import ImageSeller from '../../assets/images/home-seller.svg';
import { SessionHome } from '../../analytics/aboyeur/entities/sessionHome';
import { useAboyeur } from '../../helpers/useAboyeur';
import { CatalogGroup, CampaingGroup, ClientRoles, SellerRoles, OrderRoles } from '../../helpers/RoleGroups';

import './home.scss';
import { getRoles } from '../../helpers/rolesUtils';

const Home = () => {
  const roles = getRoles();

  const sessionHome = useAboyeur(SessionHome);

  const orderRoles = roles.filter(item => OrderRoles.includes(item));
  const clientRoles = roles.filter(item => ClientRoles.includes(item));
  const sellerRoles = roles.filter(item => SellerRoles.includes(item));
  const catalogRoles = roles.filter(item => CatalogGroup.includes(item));
  const campaingRoles = roles.filter(item => CampaingGroup.includes(item));

  useEffect(() => {
    sessionHome.events.home.viewAdminHome();
  }, [sessionHome]);

  const items = [
    {
      title: 'Clientes',
      image: ImageCustomer,
      description: 'Cadastro e edição de grupos de clientes',
      link: 'clientes/grupos-clientes',
      showSeller: !!clientRoles.length,
    },
    {
      title: 'Campanhas',
      image: ImageCampaign,
      description: 'Cadastro e edição de descontos, vouchers e regiões',
      link: 'campanhas',
      showSeller: !!campaingRoles.length,
    },
    {
      title: 'Catálogos',
      image: ImageCatalog,
      description: 'Cadastro e edição de produtos, marcas e categorias',
      link: 'catalogos',
      showSeller: !!catalogRoles.length,
    },
    {
      title: 'Pedidos',
      image: ImageOrder,
      description: 'Consulta e edição de pedidos.',
      link: 'pedidos',
      showSeller: !!orderRoles.length,
    },
    {
      title: 'Fornecedores',
      image: ImageSeller,
      description: 'Pedido mínimo, centro de distribuição, comissões.',
      link: 'fornecedores',
      showSeller: !!sellerRoles.length,
    },
  ];
  return (
    <div className="content-body home">
      <div className="content-body__box">
        {items.map(item => {
          const { image, title, description, link, showSeller } = item;
          if (showSeller) {
            return <CatalogItem image={image} title={title} description={description} link={link} />;
          }
        })}
      </div>
    </div>
  );
};

export default Home;
