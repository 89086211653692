import Decoder from '@helpers/Decoder';
import Roles from '@helpers/Roles';
import {
  DiscountTypeKeys,
  DiscountTypeValue,
} from '@pages/Campaign/Discount/DiscountsSearch/interfaces/Discounts.interfaces';

const initialPageSize = 10;
const isOne = 1;
const secondPageSize = 25;
const lastPageSize = 50;

export const optionsState = [
  {
    name: 'Habilitado',
    value: 'active',
  },
  {
    name: 'Desabilitado',
    value: 'inactive',
  },
  {
    name: 'Todos',
    value: 'all',
  },
];

export const optionsValid = [
  {
    name: 'Vigente',
    value: 'current',
  },
  { name: 'Expirado', value: 'expired' },
  {
    name: 'Todos',
    value: 'all',
  },
];

export const formatDiscountType: Record<DiscountTypeKeys, DiscountTypeValue> = {
  ABSOLUTE_ON_CART: 'Absoluto carrinho',
  PERCENTUAL_ON_FREIGHT: 'Percentual entrega',
  PERCENTUAL_ON_PRODUCT: 'Percentual produto',
};

export const initialFilterParams = {
  active: 'active',
  couponCode: '',
  highlight: '',
  type: '',
  valid: 'current',
  valueSrcInput: '',
};

export const initialPaginationParams = {
  currentPage: isOne,
  pageSize: initialPageSize,
  pageSizes: [initialPageSize, secondPageSize, lastPageSize],
  totalItems: isOne,
};

export const initialSegmentOptions = {
  ABSOLUTE_ON_CART: false,
  PERCENTUAL_ON_FREIGHT: false,
  PERCENTUAL_ON_PRODUCT: false,
};

export const handleDiscountState = (option: string) => {
  switch (option) {
    case 'active':
      return true;
    case 'inactive':
      return false;
    default:
      return null;
  }
};

export const handleDiscountValidaty = (option: string) => {
  switch (option) {
    case 'current':
      return true;
    case 'expired':
      return false;
    default:
      return null;
  }
};

export const getDiscountRules = (): { roleWR: string | undefined; roleRO: string | undefined } => {
  const tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  const roleDiscount = tokenDecoded.resource_access.manager.roles.includes(Roles.DISCOUNT_WR);
  const roleDiscountReadOnly = tokenDecoded.resource_access.manager.roles.includes(Roles.DISCOUNT_RO);

  return { roleWR: roleDiscount, roleRO: roleDiscountReadOnly };
};

export function hasDiscountsRules(): boolean {
  const { roleWR, roleRO } = getDiscountRules();

  return !!roleRO || !!roleWR;
}
