export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    alignItems: 'center',
    width: 'inherit',
    minHeight: '118px',
    flexFlow: 'row wrap',
    height: "100%"
  },
  content: {
    flexGrow: 1,
    textAlign: 'left'
  },
  title: {
    fontWeight: 700,
    color: 'inherit'
  },
  details: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    alignItems: 'center'
  },
  value: {
    color: 'inherit'
  },
  primary: {
    color: '#004085',
    backgroundColor: '#cce5ff',
    borderColor: '#b8daff'
  },
  success: {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb'
  },
  warning: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba'
  },
  error: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb'
  },
  icon: {
    color: 'inherit',
    opacity: 0.6,
    width: '4rem',
    height: '4rem',
    fontSize: '4rem'
  },
  plus: {
    marginLeft: 'auto'
  },
  span: {
    width: '100%',
    marginTop: 8,
    fontSize: 12
  }
});
