import { transformDateBR } from '@helpers/FormatHelper';
import React from 'react';
import { NavLink } from 'react-router-dom';

const TableSearchCommercialCondition = ({ data, role }) => {
  const titleColums = ['ID', 'Nome', 'Data da criação', 'Estado'];
  return (
    <>
      {data?.length > 0 && (
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              {titleColums.map((item) => (
                <th className="table-admin__title">{item}</th>
              ))}
              <th className="table-admin__title"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr key={item?.id}>
                  <td className="table-admin__data">{item?.id}</td>
                  <td className="table-admin__data">{item?.name}</td>
                  <td className="table-admin__data">
                    {transformDateBR(item?.creationDate)}
                  </td>
                  <td className="table-admin__data">
                    {item.isEnabled ? 'Habilitado' : 'Desabilitado'}
                  </td>
                  <td className="table-admin__data">
                    <NavLink
                      to={
                        role
                          ? '/campanhas/condicao-comercial/editar/' + item?.id
                          : '#'
                      }
                      className={role ? 'link' : 'link--disabled'}
                    >
                      Editar
                    </NavLink>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TableSearchCommercialCondition;
