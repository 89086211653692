import HandleBilled from './HandleBilled';
import HandleInSeparation from './HandleInSeparation';
import HandleDispatched from './HandleDispatched';
import HandleWaitingWithdrawal from './HandleWaitingWithdrawal';
import HandleDeliveredWithdrawn from './HandleDeliveredWithdrawn';
import RequestVehicle from './RequestVehicle';

import './AlterStatus.scss';

function AlterStatus({ orderDeliveryId, deliveryStatus, deliveryType, phraseVariation, setModal, ownerType }) {
  const BUTTON_TEXT = 'Alterar Status';

  function handleDeliveryType(deliveryType) {
    if (ownerType === 'IFOOD') return <RequestVehicle delivery={{ orderDeliveryId, setModal }} />;
    if (deliveryType !== 'PICKUP')
      return <HandleDispatched orderDeliveryId={orderDeliveryId} buttonText={BUTTON_TEXT} />;

    return <HandleWaitingWithdrawal orderDeliveryId={orderDeliveryId} buttonText={BUTTON_TEXT} />;
  }

  switch (deliveryStatus) {
    case 'PAYMENT_ACCEPTED':
      return (
        <HandleInSeparation
          orderDeliveryId={orderDeliveryId}
          buttonText={BUTTON_TEXT}
          phraseVariation={phraseVariation}
        />
      );
    case 'BILLED':
      return handleDeliveryType(deliveryType);
    case 'IN_STOCK_SEPARATION':
      return <HandleBilled orderDeliveryId={orderDeliveryId} buttonText={BUTTON_TEXT} />;
    case 'IN_TRANSPORTATION':
      return <HandleDeliveredWithdrawn orderDeliveryId={orderDeliveryId} buttonText={BUTTON_TEXT} />;
    case 'WAITING_PICKUP':
      return <HandleDeliveredWithdrawn orderDeliveryId={orderDeliveryId} buttonText={BUTTON_TEXT} />;
    default:
      return null;
  }
}

export default AlterStatus;
