import { Aboyeur } from '@ifood/aboyeur';

export const SessionOrderDetail = new Aboyeur('sessao_order_detail', {
  order_detail: {
    /**
     * @when Sessao order detail is rendered
     */
    viewAdminOrderDetail: () => ({
      label: 'sessao_order_detail',
      metadata: {},
    }),

    /**
     * @when Clicou em 'Alterar status do pedido' no pedido
     */
    clickUpdateOrderStatus: () => ({
      label: 'click_update_order_status',
      metadata: {},
    }),

    /**
     * @when Clicou em 'Editar pedido' tabela de pedido
     */
    clickQuantityEdit: (obj) => ({
      label: 'click_button_quantity_edit',
      metadata: obj,
    }),
    
    /**
     * @when Clicou em 'Editar peso' tabela de pedido
     */
    clickWeightEdit: (obj) => ({
      label: 'click_button_weight_edit',
      metadata: obj,
    }),

    /**
     * @when Clicou em 'Confirmar' modal de edição de quantidade
     */
    clickQuantityConfirm: (obj) => ({
      label: 'click_button_quantity_confirm',
      metadata: obj,
    }),

    /**
     * @when Clicou em 'Confirmar' modal de edição de peso
     */  
    clickWeightConfirm: (obj) => ({
      label: 'click_button_weight_confirm',
      metadata: obj,
    }),

    /**
     * @when Clicou em 'Cancelar pedido' o link chama fluxo de Cancelamento
     */
     clickButtonCancel: (obj) => ({
      label: 'click_button_cancel',
      metadata: obj,
    }),

    /**
     * @when Clicou em 'Continuar a cancelar' primeiro modal no fluxo de Cancelamento
     */
     clickButtonContinueCancel: (obj) => ({
      label: 'click_button_continue_cancel',
      metadata: obj,
    }),

    /**
     * @when Clicou em 'Confirma o cancelamento' segundo modal que confirma o Cancelamento
     */
     clickButtonCompleteCancel: (obj) => ({
      label: 'click_button_complete_cancel',
      metadata: obj,
    }),

  },
}).withMetadata({ revision: 4 });
