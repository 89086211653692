import styled from 'styled-components';

export const MainHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 72px 72px 0;
  flex-direction: column;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: ${({ theme }) => theme.space.regular};

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ActionsContainer = styled.div`
  display: flex;
`;
