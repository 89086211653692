import gql from 'graphql-tag';

const GET_PRODUCTS = gql`
  query GetAllProducts(
    $page: Int
    $size: Int
    $productName: String
    $createdAt: String
    $active: Boolean
    $categoryId: Int
    $brandId: Int
    $id: Int
    $sellerId: Int
    $referenceCode: String
  ) {
    getAllProducts(
      page: $page
      pageSize: $size
      productName: $productName
      createdAt: $createdAt
      active: $active
      categoryId: $categoryId
      brandId: $brandId
      id: $id
      sellerId: $sellerId
      referenceCode: $referenceCode
    ) {
      content {
        id
        name
        active
        description
        referenceCode
        category {
          id
          name
        }
        brand {
          id
          name
        }
      }
      totalPages
      size
    }
  }
`;

const GET_ALL_PRODUCTS = gql`
  query getAllProducts(
    $page: Int
    $size: Int
    $productName: String
    $createdAt: String
    $active: Boolean
    $categoryId: Int
    $brandId: Int
    $id: Int
  ) {
    getAllProducts(
      page: $page
      pageSize: $size
      productName: $productName
      createdAt: $createdAt
      active: $active
      categoryId: $categoryId
      brandId: $brandId
      id: $id
    ) {
      content {
        id
        name
        active
      }
      totalPages
      size
    }
  }
`;

const GET_PRODUCT_BY_ID = gql`
  query getProductV2($id: ID!) {
    getProductV2(productId: $id) {
      id
      name
      active
      visible
      description
      brand {
        id
        name
      }
      category {
        id
        name
      }
      error
      errorCode
    }
  }
`;

const GET_PRODUCTS_BY_NAME = gql`
  query getProductsByNameOrId($name: String, $id: Int) {
    getProductsByNameOrId(name: $name, id: $id) {
      id
      name
    }
  }
`;

const GET_PRODUCTS_BY_NAME_OR_ID = gql`
  query getProductsByNameOrId($name: String, $id: Int) {
    getProductByNameOrId(name: $name, id: $id) {
      id
      name
      active
      visible
      description
      brand {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`;

const GET_PRODUCT_IMAGES = gql`
  query findAllProductImage($product: Int) {
    findAllProductImage(productId: $product) {
      id
      imagePath
      active
      order
    }
  }
`;

export {
  GET_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_PRODUCTS_BY_NAME,
  GET_PRODUCTS_BY_NAME_OR_ID,
  GET_PRODUCT_IMAGES,
  GET_PRODUCT_BY_ID,
};
