import { useQuery } from '@apollo/react-hooks';
import { GET_BUSINESS_TYPES } from '@graphql/seller.queries';

export const useBusinessTypeOptions = () => {
  const { data } = useQuery(GET_BUSINESS_TYPES);

  const businessTypeOptions = data?.getBusinessTypes?.map((item: { name: string; value: string }) => ({
    name: item.name,
    value: item.value,
  }));

  return {
    businessTypeOptions,
  };
};
