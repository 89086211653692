import { Aboyeur } from '@ifood/aboyeur';

export const SessionHome = new Aboyeur('sessao_home', {
	home: {
		/**
		 * @when Sessao home is rendered
		 */
		viewAdminHome: () => ({
			label: 'sessao_home',
			metadata: {},
		}),
		/**
		 * @when Click orders in home
		 */
		clickOrdersHome: () => ({
			label: 'click_orders_home',
			metadata: {},
		}),
		/**
		 * @when Click catalog in home
		 */
		clickCatalogHome: () => ({
			label: 'click_catalog_home',
			metadata: {},
		}),
	},
}).withMetadata({ revision: 3 });
