import React, { useState } from 'react';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GET_SELLERS } from '../../../../../../graphql/seller.queries';
import { useLazyQuery } from '@apollo/react-hooks';

import './formSearchRegion.scss';

const FormSearchRegion = ({ onChangeFilter, isSeller }) => {
  const [filters, setFilters] = useState({
    name: null,
    ownerType: null,
    active: true,
    ownerId: null,
  });

  const [getSeller, { data: dataSeller }] = useLazyQuery(GET_SELLERS);

  const optionsActive = [
    { value: true, label: 'Habilitado' },
    { value: false, label: 'Desabilitado' },
    { value: null, label: 'Todos' },
  ];

  const optionsOwnerType = [
    { value: null, label: 'Geral' },
    { value: 'SELLER', label: 'Fornecedor' },
  ];

  function handleSeller(newValue) {
    if (newValue && dataSeller?.getSellerByName) {
      const idSeller = dataSeller.getSellerByName.find(
        (item) => item.name === newValue,
      );
      setFilters({
        ...filters,
        ownerId: idSeller?.id,
      });
    } else {
      setFilters({
        ...filters,
        ownerId: null,
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    onChangeFilter(filters);
  }

  return (
    <>
      <div className="header-customer-group header-customer-group--src form-search">
        <form
          className="header-customer-group__form form-commercial"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="header-customer-group__form-input">
            <OutlinedInput
              name="name"
              placeholder="Nome da região"
              border
              value={filters.name || ''}
              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            />

            {!isSeller && (
              <div className="dropdown-label-wrap">
                <Dropdown
                  options={optionsOwnerType}
                  value={
                    optionsOwnerType.find(
                      (o) => o.value === filters.ownerType,
                    ) || optionsOwnerType[0]
                  }
                  onChange={(e) =>
                    setFilters({ ...filters, ownerType: e.value })
                  }
                  placeholder="Estado"
                  className="dropdown-ifood"
                  controlClassName="dropdown-ifood__control"
                  placeholderClassName="dropdown-ifood__placeholder"
                  menuClassName="dropdown-ifood__menu"
                  arrowClassName="dropdown-ifood__arrow"
                />
                <label className="dropdown-label">Proprietário</label>
              </div>
            )}

            {filters.ownerType === 'SELLER' && (
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="sellerSearch"
                  freeSolo
                  onChange={(event, newValue) => handleSeller(newValue)}
                  className="dropdown-ifood form-search__autocomplete"
                  noOptionsText="Digite para procurar"
                  options={
                    dataSeller?.getSellerByName
                      ? dataSeller?.getSellerByName.map((option) => option.name)
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      className="form-search__autocomplete"
                      {...params}
                      label="Digite aqui para buscar um fornecedor"
                      variant="outlined"
                      onChange={(e) =>
                        getSeller({ variables: { name: e.target.value } })
                      }
                    />
                  )}
                />
              </div>
            )}

            <div className="dropdown-label-wrap">
              <Dropdown
                options={optionsActive}
                value={
                  optionsActive.find((o) => o.value === filters.active) ||
                  optionsActive[0]
                }
                onChange={(e) => setFilters({ ...filters, active: e.value })}
                placeholder="Estado"
                className="dropdown-ifood"
                controlClassName="dropdown-ifood__control"
                placeholderClassName="dropdown-ifood__placeholder"
                menuClassName="dropdown-ifood__menu"
                arrowClassName="dropdown-ifood__arrow"
              />
              <label className="dropdown-label">Estado</label>
            </div>
          </div>
          <div className="header-customer-group__actions">
            <Button type="submit">Filtrar regiões</Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormSearchRegion;
