import { useEffect, useState } from 'react';
import * as S from './SellerRegistration.styles';
import { useSellerFormContext } from '../context/SellerFormContext';
import SellerRegistrationHeader from '../components/SellerRegistrationHeader';
import RegistrationDataForm from '../components/FormSteps/RegistrationDataForm';
import AdressDataForm from '../components/FormSteps/AdressDataForm';
import BankDataForm from '../components/FormSteps/BankDataForm';
import ContractualDataForm from '../components/FormSteps/ContractualDataForm';
import ModalBackRegister from '../components/ModalBackRegister';
import { pageSteps } from '../SellerFormType';
import { useSellerRegistration } from './sellerRegistrationHook';

const POSITION_ZERO = 0;

const SellerRegistration = (): JSX.Element => {
  const [modalBackRegister, setModalBackRegister] = useState(false);
  const { state } = useSellerFormContext();
  const { dispatchTrigger, onSubmitCreate, isCreatingSeller } = useSellerRegistration();

  const urlReturnPage = '/fornecedores/detail';

  const handleBackRegisterModal = () => {
    setModalBackRegister(true);
  };

  const PageSteps = {
    [pageSteps.RegistrationStep]: <RegistrationDataForm triggerChangeEvent={dispatchTrigger} />,
    [pageSteps.AddressStep]: <AdressDataForm triggerChangeEvent={dispatchTrigger} />,
    [pageSteps.BankStep]: <BankDataForm triggerChangeEvent={dispatchTrigger} />,
    [pageSteps.ContractualStep]: (
      <ContractualDataForm
        isSubmitLoading={isCreatingSeller}
        triggerChangeEvent={dispatchTrigger}
        onSubmitParent={onSubmitCreate}
      />
    ),
  };
  const PageStepComponent = () => PageSteps[state.currentStep];

  useEffect(() => {
    window.scrollTo(POSITION_ZERO, POSITION_ZERO);
  }, [state.currentStep]);

  return (
    <S.Wrapper>
      <S.GoBackArrow onClick={handleBackRegisterModal} />
      <SellerRegistrationHeader actualStep={state.currentStep} titleHeader="Novo Fornecedor" />
      <PageStepComponent data-testid="seller-register-content" />
      <ModalBackRegister
        description="Ao voltar, você perderá toda as informações que ainda não foram salvas do cadastro do fornecedor."
        isOpen={modalBackRegister}
        setIsOpen={setModalBackRegister}
        title="Deseja realmente voltar?"
        url={urlReturnPage}
      />
    </S.Wrapper>
  );
};

export default SellerRegistration;
