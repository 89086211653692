import { useMemo, useState } from 'react';
import { Table, Text } from '@ifood/pomodoro-components';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import DraggableTableRow from './DraggableTableRow';
import { ISortableTableColum } from '@components/sortableDnDTable/types';

type Props<T> = {
  columns: ISortableTableColum<T>[];
  uniqueField?: string;
  data: T[];
  setData: (v: T[]) => void;
};

const SortableDnDTable = ({
  columns,
  data,
  setData,
  uniqueField = 'id',
}: Props<Record<string, unknown>>): JSX.Element => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>();

  const items = useMemo(() => data?.map(item => item[uniqueField]), [data, uniqueField]);

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      const newArr = arrayMove(data, oldIndex, newIndex);

      setData(newArr);
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    return data.find(item => item.uiId === activeId);
  }, [activeId, data]);

  const selectedRowPosition = useMemo(() => {
    if (!selectedRow) return;

    return data.findIndex(item => item.uiId === selectedRow.uiId);
  }, [selectedRow, data]);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            {columns.map(colum => (
              <Table.Header key={colum.name as string}>
                <Text as="p" style={{ textAlign: colum.alignment || 'left' }}>
                  {colum.label}
                </Text>
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>

        <Table.Body>
          <SortableContext items={items as string[]} strategy={verticalListSortingStrategy}>
            {data.map((item, index) => (
              <DraggableTableRow key={item[uniqueField] as string} columns={columns} item={item} position={index} />
            ))}
          </SortableContext>
        </Table.Body>
      </Table>
      <DragOverlay>
        {activeId && (
          <Table>
            <Table.Body>
              <DraggableTableRow isStatic columns={columns} item={selectedRow} position={selectedRowPosition} />
            </Table.Body>
          </Table>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableDnDTable;
