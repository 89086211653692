import React from "react";
import "./sellerBankAccount.scss";

const SellerBankAccount = ({ data }) => {

  return (
    <div className="sellerBank">
      <h2 className="sellerBank__title">Dados Bancários</h2>
      <div className="sellerBank__name">
        <span className="sellerBank__attribute">Tipo de Conta:</span>
        <span className="sellerBank__value">
          {data?.accountType}
        </span>
      </div>
      <div className="sellerBank__tel">
        <span className="sellerBank__attribute">Banco:</span>
        <span className="sellerBank__value">
          {data?.bankId + " - " + data?.bankName}
        </span>
      </div>
      <div className="sellerBank__cel">
        <span className="sellerBank__attribute">Agência:</span>
        <span className="sellerBank__value">{data?.agency}</span>
      </div>
      <div className="sellerBank__name">
        <span className="sellerBank__attribute">Conta Corrente:</span>
        <span className="sellerBank__value">{data?.account}</span>
      </div>
    </div>
  );
};

export default SellerBankAccount;
