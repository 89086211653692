/* istanbul ignore file */
import { useStatementDetailCardHook } from '../StatementDetailCardHook';
import { Flex } from '@ifood/pomodoro-components';
import Formatter from '@helpers/FormatHelper';
import * as S from './StatementDetailAmount.styles';

export function StatementDetailAmount() {
  const { bonusShopBalance } = useStatementDetailCardHook();
  const valueDefault = 0;
  const shopBalanceItem = bonusShopBalance?.length ? bonusShopBalance : [{}];
  const [{ availableAmount = valueDefault, bonusShopPercentage }] = shopBalanceItem;
  const availableAmountformatter = Formatter.currency(availableAmount);
  return (
    <S.Card>
      <Flex flexDirection={'column'}>
        <S.Heading variant="smallest">Bônus Shop</S.Heading>
        <S.AvailableAmount title={availableAmountformatter} value={availableAmount}>
          {availableAmountformatter}
        </S.AvailableAmount>
        {bonusShopPercentage && (
          <S.BonusShopPercentage as={'p'}>Porcentagem de uso {bonusShopPercentage}%</S.BonusShopPercentage>
        )}
      </Flex>
    </S.Card>
  );
}
