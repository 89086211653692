import gql from 'graphql-tag';

const GET_PRODUCT_SUGGESTS = gql`
  query getAllProductSuggestions(
    $page: Int
    $pageSize: Int
    $name: String
    $sellerId: Int
    $categoryId: Int
    $brandId: Int
    $category: String
    $brand: String
    $createdAt: String
  ) {
    getAllProductSuggestions(
      page: $page
      pageSize: $pageSize
      productName: $name
      sellerId: $sellerId
      categoryId: $categoryId
      brandId: $brandId
      category: $category
      brand: $brand
      createdAt: $createdAt
    ) {
      content {
        id
        name
        description
        approved
        sellerId
        createdAt
        updatedAt
        cancelMotive
      }
      totalPages
      size
    }
  }
`;

const GET_PRODUCT_SUGGESTS_BY_ID = gql`
  query getProductSuggestionById($suggestionId: ID!) {
    getProductSuggestionById(id: $suggestionId) {
      name
      description
      approved
      category
      brand
      categoryinfo {
        id
        name
        parentId
      }
      brandinfo {
        id
        name
      }
      price
      width
      weight
      height
      length
      quantity
      barCode
      unity
      sellerId
      sku
      stock
      productImageList
      productSpecificationList
      skuSpecificationList
      variationImageList
      createdAt
      updatedAt
      cancelMotive
      variableWeight
      productType
    }
  }
`;

const GET_CATEGORIES_SUGGESTS = gql`
  query getCategoriesSuggestion($name: String!) {
    getCategoriesSuggestion(name: $name)
  }
`;

const GET_BRANDS_SUGGESTS = gql`
  query getBrandsSuggestion($name: String) {
    getBrandsSuggestion(name: $name)
  }
`;

const GET_REJECTION_REASON = gql`
    query getRejectionReason{
        getRejectionReason{
            id
            name
        } 
    }
`;

export { GET_PRODUCT_SUGGESTS, GET_PRODUCT_SUGGESTS_BY_ID, GET_CATEGORIES_SUGGESTS, GET_BRANDS_SUGGESTS, GET_REJECTION_REASON };
