import { FormEvent } from 'react';
import type { UseFormSetValue, FieldValues, FieldPath, FieldPathValue } from 'react-hook-form';

interface IMaskInput {
  mask: string;
  value: string;
  reverse?: boolean;
  minSize?: number;
}

interface IMaskFunction<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> {
  mask: string | 'percentage';
  event: FormEvent<HTMLInputElement>;
  setValue: UseFormSetValue<TFieldValues>;
  fieldName: TFieldName;
}

export const handleMaskSetValue = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  mask,
  event,
  setValue,
  fieldName,
}: IMaskFunction<TFieldValues, TFieldName>) => {
  const {
    currentTarget: { value },
  } = event;

  const valueMask = maskInput({ value, mask });

  setValue(fieldName, valueMask as FieldPathValue<TFieldValues, TFieldName>);
};

export const maskInput = ({ mask, value = '', reverse }: IMaskInput) => {
  const one = 1;
  const two = 2;

  if (mask === 'percentage') {
    return parseFloat(value).toFixed(two).toString();
  }

  const charType: { [key: string]: RegExp } = {
    A: /[A-z]/,
    '9': /\d/,
    '*': /./,
  };
  let indexRecursive = 0;
  const valueFinal: string[] = [];
  const maskSplit = mask.split('');
  const valueSplit = String(value).split('');
  const fnMask = ({ index, value }: { index: number; value: string }) => {
    const maskCurrent = maskSplit[index];
    const charCurrent = charType[maskCurrent];
    if (maskCurrent === value) {
      valueFinal.push(maskCurrent);
    } else if (charCurrent) {
      if (charCurrent.test(value)) {
        valueFinal.push(value);
      }
    } else if (maskCurrent) {
      valueFinal.push(maskCurrent);
      if (charType.A.test(value) || charType['9'].test(value)) {
        indexRecursive++;
      }
      fnMask({ index: index + one, value });
    }
  };
  const fnMaskReverse = ({ index, value }: { index: number; value: string }) => {
    const maskNext = maskSplit[maskSplit.length - index - two];
    const indexReverse = maskNext === value ? ++indexRecursive : indexRecursive;
    const maskCurrent = maskSplit[maskSplit.length - index - one + indexReverse];
    const charCurrent = charType[maskCurrent];
    if (new RegExp(charCurrent).test(value)) {
      if (charCurrent) {
        valueFinal.unshift(value);
      } else {
        valueFinal.unshift(maskCurrent);
        fnMaskReverse({ index: index + one, value });
      }
    }
  };
  maskSplit.forEach((_, i) => {
    if (reverse) {
      const limitValue = valueSplit[valueSplit.length - one - i];
      if (limitValue) {
        fnMaskReverse({ index: i, value: limitValue });
      }
    } else {
      const limitValue = valueSplit[i];
      if (limitValue) {
        fnMask({ index: i + indexRecursive, value: limitValue });
      }
    }
  });
  return valueFinal.join('');
};
