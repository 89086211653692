import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELLER, GET_ADDRESS } from '@graphql/seller.queries';
import { GET_BANK } from '@graphql/bank_account.queries';
import { Box, Tabs, Tab, Typography } from '@material-ui/core';

import Roles from '@helpers/Roles';
import { getRoles, getSellerId } from '@helpers/rolesUtils';

import { useDataSellerContext } from '../DataSellerContext';
import MainData from './MainData';
import AccessData from './AccessData';
import SellerLogo from './SellerLogo';
import SellerContracts from './SellerContracts';
import EditSeller from './EditSeller';
import SellerAddress from './SellerAddress';
import SellerBankAccount from './SellerBankAccount';
import Loading from '@components/Loading';

const DataSellerComponent = ({ role, props }) => {
  const [openEdit, setOpenEdit] = useState({
    type: '',
    edit: false,
  });

  const roles = getRoles();
  const sellerId = getSellerId();
  const sellerRole = roles.includes(Roles.SELLER_RO) || roles.includes(Roles.SELLER_WR);

  // Tabs properties
  const indexList = {
    defaultTab: 0,
    firstTab: 1,
    secondTab: 2,
    thirdTab: 3,
  };

  const [active, setActive] = useState(0);

  const history = useHistory();
  const { setSellerData, setSellerBank, setSellerAddress, logisticAllowedWR, logisticAllowedRO } =
    useDataSellerContext();

  const [isGetSellerId, { data, loading, refetch }] = useLazyQuery(GET_SELLER, {
    fetchPolicy: 'no-cache',
    variables: {
      id: parseInt(props.match.params.id) || parseInt(sellerId),
    },
    onCompleted: () => {
      setSellerData(data.getSellerById);
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.extensions['code'] === 'FORBIDDEN') history.push('/fornecedores');
    },
  });

  const [getBankSeller, { data: dataBank, loadingBank }] = useLazyQuery(GET_BANK, {
    fetchPolicy: 'no-cache',
    variables: {
      sellerId: parseInt(props.match.params.id) || parseInt(sellerId),
    },
    onCompleted: () => {
      setSellerBank(dataBank.getBankBySellerId);
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.extensions['code'] === 'FORBIDDEN') history.push('/fornecedores');
    },
  });

  const [getAdressSeller, { data: dataAddress, loadingAddress }] = useLazyQuery(GET_ADDRESS, {
    fetchPolicy: 'no-cache',
    variables: {
      sellerId: parseInt(props.match.params.id) || parseInt(sellerId),
    },
    onCompleted: () => {
      setSellerAddress(dataAddress.getAddressBySellerId);
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.extensions['code'] === 'FORBIDDEN') history.push('/fornecedores');
    },
  });

  useEffect(() => {
    if (sellerRole) {
      isGetSellerId({
        variables: { id: parseInt(sellerId) },
      });

      getBankSeller({
        variables: { id: parseInt(sellerId) },
      });

      getAdressSeller({
        variables: { id: parseInt(sellerId) },
      });
    }
  }, []);

  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={active !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  };

  const tabProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };

  return (
    <>
      <div className="menu-myData">
        <Tabs className="menu-myData-tabs" value={active} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Fornecedor" {...tabProps(indexList.defaultTab)} />
          <Tab label="Acesso" {...tabProps(indexList.firstTab)} />
          <Tab label="Conta bancária" {...tabProps(indexList.secondTab)} />
          <Tab label="Endereço" {...tabProps(indexList.thirdTab)} />
        </Tabs>

        <TabPanel value={active} index={indexList.defaultTab}>
          <MainData role={role} setEdit={setOpenEdit} data={data?.getSellerById} />
          <SellerLogo role={role} data={data} refetch={refetch} />
        </TabPanel>

        <TabPanel value={active} index={indexList.firstTab}>
          <AccessData role={role} setEdit={setOpenEdit} data={data?.getSellerById} />
        </TabPanel>

        <TabPanel value={active} index={indexList.secondTab}>
          <SellerBankAccount data={dataBank?.getBankBySellerId} />
        </TabPanel>

        <TabPanel value={active} index={indexList.thirdTab}>
          <SellerAddress data={dataAddress?.getAddressBySellerId} />
        </TabPanel>

        {active === indexList.defaultTab && (
          <span className="contractsSellerMyData">
            {(logisticAllowedRO || logisticAllowedWR) && (
              <SellerContracts roles={{ logisticAllowedRO, logisticAllowedWR }} data={data} />
            )}
          </span>
        )}

        {openEdit.edit && (
          <EditSeller typeEdit={openEdit.type} setOpenEdit={setOpenEdit} data={data?.getSellerById} refetch={refetch} />
        )}
        {(loading || loadingBank || loadingAddress) && <Loading />}
      </div>
    </>
  );
};

export default DataSellerComponent;
