/* istanbul ignore file */
import { useCallback, useMemo, useState } from 'react';
import { Button, LinkText } from '@ifood/pomodoro-components';
import { v4 as uuid } from 'uuid';

import apolloClient from '@services/apolloClient';
import { ITableColum, Seller, ShoppingListItem, Variation } from '@pages/Catalogs/ShoppingListDetail/index.d';
import Autocomplete from '@components/autocomplete';
import SortableDnDTable from '@components/sortableDnDTable/SortableDnDTable';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';

const isZero = 0;
const isOne = 1;
type Props = {
  onSearchSeller: LazyQueryExecFunction<any, OperationVariables>;
  isFetchingSeller: boolean;
  onSearchProduct: LazyQueryExecFunction<any, OperationVariables>;
  isFetchingProduct: boolean;
  onChange: (v: ShoppingListItem[]) => void;
  value: ShoppingListItem[];
};

const FormAddItems = ({
  onSearchSeller,
  isFetchingSeller,
  onSearchProduct,
  isFetchingProduct,
  onChange,
  value = [],
}: Props): JSX.Element => {
  const [sellerOptions, setSellerOptions] = useState<Seller[]>([]);
  const [productOptions, setProductsOptions] = useState<Variation[]>([]);

  const [selectedSeller, setSelectedSeller] = useState<Seller>();
  const [selectedProduct, setSelectedProduct] = useState<Variation>();

  const handleInclude = () => {
    if (!selectedProduct) return;
    const selectedOption: ShoppingListItem = {
      id: null,
      uiId: uuid(),
      variation: selectedProduct,
      seller: selectedSeller,
    };

    if (value.some(listItem => listItem.variation.id === selectedProduct?.id)) return;
    onChange([...value, selectedOption]);
  };

  const handleRemove = (listItemVariationId: string | number) => {
    onChange(value.filter(listItem => listItem.variation.id !== listItemVariationId));
  };

  const handleSearchSeller = useCallback(
    (value: string) => {
      onSearchSeller({
        variables: {
          page: 0,
          size: 5,
          name: value?.toLowerCase().trim(),
        },
      })
        .then(({ data, error }) => {
          if (error) {
            console.log(error);
          } else {
            const { sellers: { content = [] } = {} } = data;
            setSellerOptions(content);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    [onSearchSeller]
  );

  const handleSearchProduct = useCallback(
    (value: string) => {
      onSearchProduct({
        client: apolloClient,
        variables: {
          page: 0,
          size: 5,
          sellerId: selectedSeller ? Number(selectedSeller?.id) : null,
          name: value?.toLowerCase().trim(),
        },
      })
        .then(({ data, error }) => {
          if (error) {
            console.log(error);
          } else {
            const { variations: { content = [] } = {} } = data;
            setProductsOptions(content);
          }
        })
        .catch(err => console.log(err));
    },
    [onSearchProduct, selectedSeller]
  );

  const tableColumns: ITableColum<ShoppingListItem>[] = [
    {
      id: 1,
      name: 'position',
      label: 'Ordem',
      alignment: 'left',
      formatter: (_item: ShoppingListItem, index) => (index ? index + isOne : isOne).toString(),
    },
    {
      id: 2,
      name: 'seller',
      label: 'Fornecedor',
      alignment: 'left',
      formatter: ({ seller }: ShoppingListItem) => seller?.name,
    },
    {
      id: 3,
      name: 'variation',
      label: 'Produto',
      alignment: 'left',
      formatter: ({ variation }: ShoppingListItem) => variation.name,
    },
    {
      id: 4,
      name: 'actions',
      label: '',
      alignment: 'right',
      formatter: ({ variation }: ShoppingListItem) => (
        <LinkText onClick={() => handleRemove(variation.id)}>Remover</LinkText>
      ),
    },
  ];

  const isNotEmpty = useMemo(() => value.length > isZero, [value]);

  return (
    <div className="form-list-selection">
      <div className="autocomplete-container">
        <Autocomplete
          classes="seller-autocomplete"
          inputLabel="Nome do fornecedor"
          loading={isFetchingSeller}
          optionLabel={option => option.name as string}
          optionValue={(option, value) => option.id === value.id}
          options={sellerOptions}
          onSearch={handleSearchSeller}
          onSelect={(v: unknown) => setSelectedSeller(v as Seller)}
        />

        <Autocomplete
          classes="product-autocomplete"
          inputLabel="Nome do produto"
          loading={isFetchingProduct}
          optionLabel={option => option.name as string}
          optionValue={(option, value) => option.id === value.id}
          options={productOptions}
          onSearch={handleSearchProduct}
          onSelect={(v: unknown) => setSelectedProduct(v as Variation)}
        />

        <Button type="button" onClick={handleInclude}>
          Adicionar
        </Button>
      </div>

      {isNotEmpty && (
        <div className="table-container">
          <SortableDnDTable
            columns={tableColumns as any}
            data={value}
            setData={v => onChange(v as ShoppingListItem[])}
            uniqueField="uiId"
          />
        </div>
      )}
    </div>
  );
};

export default FormAddItems;
