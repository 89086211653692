import * as yup from 'yup';

export type CustomerFraudRemoveType = {
  note: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomerFraudRemoveTypeT = { [key in keyof CustomerFraudRemoveType]: any };

// SCHEMA
const schemaRemoveFraud = yup.object().shape({
  note: yup.string(),
} as CustomerFraudRemoveTypeT);

export default schemaRemoveFraud;
