import gql from 'graphql-tag';

const UPGRADE_ORDER_DELIVERY_STATUS_INVOICE = gql`
	mutation upgradeOrderDelivery(
		$orderDeliveryId: ID!,
		$trackingCode: String,
		$updateStatus: String,
		$updateTime: String,
		$issuedDate: String,
		$updateObservation: String,
		$number: String,
		$series: String,
		$url: String
	){
		upgradeOrderDelivery(
			orderDeliveryId: $orderDeliveryId,
			input: {
				updateObservation: $updateObservation,
				trackingCode: $trackingCode,
				updateStatus: $updateStatus,
				updateTime: $updateTime,
				invoiceData: {
					issuedDate: $issuedDate,
					number: $number,
					series: $series,
					url: $url
				},
			}
		)
  	}
`;

const UPGRADE_ORDER_DELIVERY_STATUS = gql`
	mutation upgradeOrderDelivery(
		$orderDeliveryId: ID!,
		$trackingCode: String,
		$updateStatus: String,
		$updateTime: String,
		$updateObservation: String,
	){
		upgradeOrderDelivery(
			orderDeliveryId: $orderDeliveryId,
			input: {
				updateObservation: $updateObservation,
				trackingCode: $trackingCode,
				updateStatus: $updateStatus,
				updateTime: $updateTime,
				invoiceData: null,
			}
		)
  	}
`;

const CANCEL_ORDER_DELIVERY = gql`
	mutation cancelOrderDelivery(
		$orderDeliveryId: ID!,
		$reason: String,
	){
		cancelOrderDelivery(
			orderDeliveryId: $orderDeliveryId,
			reason:  $reason
		)
	}
`;

export { 
	UPGRADE_ORDER_DELIVERY_STATUS, 
	UPGRADE_ORDER_DELIVERY_STATUS_INVOICE,
	CANCEL_ORDER_DELIVERY
}