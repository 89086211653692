import { useEffect } from 'react';
import { NavLink } from 'react-router-dom'

import HeaderForm from '../../components/HeaderForm';
import FormCarrier from '../../components/FormCarrier';

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

function EditCarrier() {
  const { getCarrierById, carrierId } = useCarrierFreightRangeContext();

  useEffect(() => {
    carrierId && 
    getCarrierById();
    
  }, [])

  return (
    <>
      <NavLink to="/fornecedores/transportadoras" className="go-back" />
      <div className="content-body">
        <HeaderForm />
        <FormCarrier />
      </div>
    </>
  );
}

export default EditCarrier;