import { gql } from 'graphql-tag';

const CHANGE_ESTABLISHMENT_STATUS = gql`
  mutation changeEstablishmentStatus($input: ChangeEstablishmentStatusInput!) {
    changeEstablishmentStatus(input: $input) {
      success
    }
  }
`;

export { CHANGE_ESTABLISHMENT_STATUS };
