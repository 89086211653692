import Search from './Search';
import RegisterCarrier from './Carrier/RegisterCarrier';
import EditCarrier from './Carrier/EditCarrier';
import RegisterFreightRange from './FreightRange/RegisterFreightRange'
import EditFreightRange from './FreightRange/EditFreightRange'
import ListFreightRange from './FreightRange/ListFreightRange';

import { CarrierFreightRangeProvider } from '@components/_context/CarrierFreightRangeContext'

function CarrierFreightRange({page, match, isEdit}) {
  const carrierId = parseInt(match?.params?.id);

  const propsContext = {
    carrierId,
    page,
    isEdit
  }

  return (
    <CarrierFreightRangeProvider {...propsContext}>
      {
        {
          "search" : <Search />,
          "registerCarrier" : <RegisterCarrier />,
          "editCarrier": <EditCarrier />,
          "listFreightRange": <ListFreightRange />,
          "registerFreightRange": <RegisterFreightRange />,
          "editFreightRange": <EditFreightRange />
        }[page]
      }
    </CarrierFreightRangeProvider>
  );
}

export default CarrierFreightRange;