/* istanbul ignore file */
import { useGetCustomerByIdQuery, useUpdateContactNameMutation } from '@services/bff/GraphqlHooks';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './schema';
import { CustomerDetailsFormPropsT } from './types';
import { snackbar } from '@ifood/pomodoro-components';

export function useCustomerDetailHook() {
  const [isEditMode, setIsEditMode] = useState(false);
  const params = useParams<{ id: string }>();
  const { data, refetch } = useGetCustomerByIdQuery({ variables: { customerId: params.id } });
  const customerData = data?.getCustomerById;
  const [saveContact, { loading: isSavingContact }] = useUpdateContactNameMutation();

  const form = useForm<CustomerDetailsFormPropsT>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {},
  });

  function onEdit() {
    setIsEditMode(true);
  }

  useEffect(() => {
    if (customerData) {
      form.reset({
        owner: customerData.owner,
      });
    }
  }, [customerData, form]);

  async function onSubmit(values: CustomerDetailsFormPropsT) {
    try {
      await saveContact({ variables: { contact: { id: params.id, executive: values.owner } } });
      setIsEditMode(false);
      refetch();
      snackbar({
        message: 'Salvo com sucesso!',
        variant: 'success',
        timeout: 4000,
        autoClose: true,
      });
    } catch (error) {
      snackbar({
        message: 'Erro ao salvar, por favor tente novamente mais tarde',
        variant: 'error',
        timeout: 4000,
        autoClose: true,
      });
    }
  }

  function onCancel() {
    setIsEditMode(false);
  }

  return {
    onEdit,
    onCancel,
    onSubmit,
    isEditMode,
    isSavingContact,
    form,
    customerData,
    params,
  };
}
