import styled from 'styled-components';
import { NavLink as BaseNavLink } from 'react-router-dom';

export const Link = styled(BaseNavLink)`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary};

  span {
    color: inherit;
  }
`;
