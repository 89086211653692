import React, { useState } from 'react';
import { Button } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GET_SELLERS } from '../../../graphql/seller.queries';
import { GET_ALL_REGIONS } from '../../../graphql/region.queries';
import { useLazyQuery } from '@apollo/react-hooks';

import './formSearchPriceMinimum.scss';

const FormSearchPriceMinimum = ({ onChangeFilter, isSeller}) => {
  const [showRegionSearch, setShowRegionSearch] = useState(false);
  const [filters, setFilters] = useState({
    active: true,
    sellerId: null,
    regionId: null,
  });

  const optionsActive = [
    { value: true, label: 'Habilitado' },
    { value: false, label: 'Desabilitado' },
    { value: null, label: 'Todos' },
  ];

  const [getAllRegions, { data: dataRegion }] = useLazyQuery(GET_ALL_REGIONS);
  const [getSeller, { data: dataSeller }] = useLazyQuery(GET_SELLERS);

  function handleSeller(sellerName) {
    if (sellerName && dataSeller?.getSellerByName) {
      const idSeller = dataSeller.getSellerByName.find(
        (item) => item.name === sellerName,
      );
      setFilters({
        ...filters,
        sellerId: idSeller?.id,
      });
      getAllRegions({
        variables: { ownerId: idSeller?.id },
      });
      setShowRegionSearch(true);
    } else {
      setFilters({
        ...filters,
        sellerId: null,
        regionId: null
      });
      setShowRegionSearch(false);
    }
  }

  function handleRegion(regionName) {
    if (regionName && dataRegion?.getAllRegions?.content) {
      const idRegion = dataRegion.getAllRegions.content.find(
        (item) => item.name === regionName,
      );
      setFilters({
        ...filters,
        regionId: parseInt(idRegion?.id),
      });
    } else {
      setFilters({
        ...filters,
        regionId: null,
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    onChangeFilter(filters);
  }

  return (
    <>
      <div className="header-customer-group header-customer-group--src form-search">
        <form
          className="header-customer-group__form form-commercial"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="header-customer-group__form-input">
            {!isSeller && (
              <div className="dropdown-label-wrap">
                <Autocomplete
                  onChange={(event, sellerName) => handleSeller(sellerName)}
                  className="dropdown-ifood form-search__autocomplete"
                  noOptionsText="Nenhum fornecedor encontrado"
                  options={
                    dataSeller?.getSellerByName
                      ? dataSeller?.getSellerByName.map((option) => option.name)
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      className="form-search__autocomplete"
                      {...params}
                      label="Busque um fornecedor"
                      variant="outlined"
                      onChange={(e) =>
                        getSeller({ variables: { name: e.target.value } })
                      }
                    />
                  )}
                />
              </div>
            )}

            {(showRegionSearch || isSeller) && (
              <div className="dropdown-label-wrap">
                <Autocomplete
                  onChange={(event, regionName) => handleRegion(regionName)}
                  className="dropdown-ifood form-search__autocomplete"
                  noOptionsText="Nenhuma região encontrada"
                  options={
                    dataRegion?.getAllRegions?.content
                      ? dataRegion?.getAllRegions?.content.map(
                          (option) => option.name,
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      className="form-search__autocomplete"
                      {...params}
                      label="Busque uma região"
                      variant="outlined"
                      onChange={(e) =>
                        getAllRegions({
                          variables: {
                            name: e.target.value,
                            ownerId: filters.sellerId,
                          },
                        })
                      }
                    />
                  )}
                />
              </div>
            )}

            <div className="dropdown-label-wrap">
              <Dropdown
                options={optionsActive}
                value={
                  optionsActive.find((o) => o.value === filters.active) ||
                  optionsActive[0]
                }
                onChange={(e) => setFilters({ ...filters, active: e.value })}
                placeholder="Estado"
                className="dropdown-ifood"
                controlClassName="dropdown-ifood__control"
                placeholderClassName="dropdown-ifood__placeholder"
                menuClassName="dropdown-ifood__menu"
                arrowClassName="dropdown-ifood__arrow"
              />
              <label className="dropdown-label">Estado</label>
            </div>
          </div>
          <div className="header-customer-group__actions">
            <Button type="submit">Filtrar pedido mínimo</Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormSearchPriceMinimum;
