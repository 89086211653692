import React, { useState, useEffect } from 'react';
import { Link, Button, OutlinedInput, Modal } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom'
import Loading from '../../../../../../components/Loading'
import AuthorizationHeader from '../../../../../../helpers/AuthorizationHeader';
import TemplateVoucherCsv from '../../../../../../assets/files/templateVoucher.csv'

import { useLazyQuery } from '@apollo/react-hooks'
import { GET_ALL_VOUCHERS } from '../../../../../../graphql/voucher.queries'

import './headerVoucherSearch.scss'
import { api } from '@services/api';



const HeaderVoucherSearch = (props) => {
  const optionsState = [
    'Habilitado', 'Desabilitado', 'Todos'
  ];
  const optionsValid = [
    'Vigente', 'Expirado', 'Todos'
  ];
  const defaultOptionState = optionsState[0];
  const defaultOptionValid = optionsValid[0];

  const [voucherName, setVoucherName] = useState(null);
  const [code, setCode] = useState(null);
  const [state, setState] = useState(defaultOptionState);
  const [valid, setValid] = useState(defaultOptionValid);
  const [voucher, setVoucher] = useState();
  const [modal, setModal] = useState(false)
  const pageSize = 10;
  let stateActive = null;
  let validActive = null

  

  var page = props.pageCurrent

  if (state === 'Habilitado')
    stateActive = true
  else if(state === 'Desabilitado')
    stateActive = false
  else
    stateActive = null

  if (valid === 'Vigente')
    validActive = true
  else if(valid === 'Expirado')
    validActive = false
  else
    validActive = null

  const [getVouchers, { error, data, refetch }] = useLazyQuery(
    GET_ALL_VOUCHERS,
    {variables:{pageRequest: page, pageSize: pageSize, valueSrcInput: voucherName, voucherCode: code, active: stateActive, valid: validActive}}
  )
  if (data && voucher == null)
    setVoucher(data.getAllVouchers);

  useEffect(()=> {
    setVoucher(null);
    getVouchers();
  }, [page]);

  const handleSubmit = (e) => {
    e.preventDefault();

    refetch()
      .then(res => setVoucher(res.data.getAllVouchers))
      .catch(error => {return error});
  }

  if(voucher) {
    props.dataVoucher(voucher)
  }

  const handleBlobImportExport = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();  //afterwards we remove the element again
    setModal(null);
    refetchCustomersAfterThen();
  }

  const refetchCustomersAfterThen = () => {
    refetch()
      .then(res => setVoucher(res.data.getAllVouchers))
      .catch(error => {return error});
  }

  const importVoucher = async (e) => {
    e.preventDefault();
    setModal(<Loading />);

    let formData = new FormData();
    const fileImport = e.target.files[0];
    formData.append(`file`, fileImport);

    api.post('/voucher/import', formData)
      .then(res => res.blob())
      .then(blob => handleBlobImportExport(blob, "result-import.csv"))
      .catch(error => {
        setModal(
          <Modal onCloseAction={() => setModal(null)} title="Erro ao importar arquivo" />
        );
      });
  }

  return(
    <>
    <div className="header-customer-group header-customer-group--src header-voucher">
      <div className="header-customer-group__info">
        <h2 className="section-title">Vouchers</h2>
        <p className="header-customer-group__description">Busque um voucher</p>
      </div>
      <div className="header-customer-group__new">
        {props.roleVoucher
          ?
            <>
              <Link className="header-customer-group__actions-link" href={TemplateVoucherCsv}>Modelo para importação</Link>
              <label className="link header-customer-group__actions-link" htmlFor="importFile">Importar lista</label>
              <input type="file" id="importFile" className="header-customer-group__actions-import" onChange={(e) => importVoucher(e)} />
              <NavLink to="/campanhas/vouchers/cadastro" className="link">Criar novo voucher</NavLink>
            </>
          : <Tooltip title="Você não tem permissão para criar novo Voucher">
              <span className="link--disabled">Criar novo Voucher</span>
            </Tooltip>
        }
      </div>
      <form className="header-customer-group__form" onSubmit={handleSubmit}>
        <div className="header-customer-group__form-input">
          <OutlinedInput
            name="name"
            placeholder="Nome"
            border
            onChange={e => setVoucherName(e.target.value)}
          />

          <OutlinedInput
            name="code"
            placeholder="Código"
            border
            onChange={e => setCode(e.target.value)}
          />

          <div className="dropdown-label-wrap">
            <Dropdown
              options={optionsState}
              value={state}
              onChange={e => setState(e.value)}
              placeholder="Estado"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName='dropdown-ifood__placeholder'
              menuClassName="dropdown-ifood__menu"
              arrowClassName='dropdown-ifood__arrow'
            />
            <label className="dropdown-label">Estado</label>
          </div>

          <div className="dropdown-label-wrap">
            <Dropdown
              options={optionsValid}
              value={valid}
              onChange={e => setValid(e.value)}
              placeholder="Válido"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName='dropdown-ifood__placeholder'
              menuClassName="dropdown-ifood__menu"
              arrowClassName='dropdown-ifood__arrow'
            />
            <label className="dropdown-label">Válido</label>
          </div>
        </div>
        <div className="header-customer-group__actions">
          <Button type="submit">Filtrar voucher</Button>
        </div>
      </form>
    </div>
    {modal}
    </>
  )
}

export default HeaderVoucherSearch