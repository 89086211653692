import React from 'react'

import './outlined-textarea.scss'

const OutlinedTextarea = (props) => {
  return (
    <div className="outlined-label-wrap outlined-textarea">
      {!props.disabled
        ?
        <textarea className="input outlined-textarea outlined-label-field outlined-label-field--border no-resize"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          rows={props.rows}
          cols={props.cols}
          maxLength={props.maxLength}
        />
        :
        <textarea className="input outlined-textarea outlined-label-field outlined-label-field--border no-resize"
          disabled
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          rows={props.rows}
          cols={props.cols}
          maxLength={props.maxLength}
        />
      }
      <label className="outlined-label">{props.placeholder}</label>
    </div>
  )
}

export default OutlinedTextarea
