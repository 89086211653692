import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CANCEL_ORDER } from '../../../../../graphql/order.mutations';
import { Button } from 'ifoodshop-react-ui';

import OutlinedTextarea from '@components/OutlinedTextarea';
import Loading from '@components/Loading';

function HandleCancelOrder({ orderId }) {
  const [errorPage, setErrorPage] = useState(null);
  const [reason, setReason] = useState(null);
  const [errorreason, setErrorreason] = useState(null);

  const [cancelMainOrder, { error, data, loading }] = useMutation(CANCEL_ORDER);

  if (data) window.location.reload(false);
  if (error) {
    setErrorPage('Não foi possível alterar o status');
  }

  function rollbackStatus(e) {
    e.preventDefault();

    if (!reason) {
      setErrorreason('Campo obrigatório');
      return;
    }

    cancelMainOrder({
      variables: {
        orderId,
        reason,
      },
    });
  }

  return (
    <>
      <h2 className="modal-alter-status__title">Cancelar pedido</h2>
      <h4 className="modal-alter-status__form__error">{errorPage}</h4>
      <form className="form-rollback-status">
        <div className="form-edit__input-group outlined-textarea--full">
          <OutlinedTextarea
            placeholder="Observação"
            maxLength={350}
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
          <p className="modal-alter-status__form__error">{errorreason}</p>
        </div>
        <Button className="modal-alter-status__submit" onClick={e => rollbackStatus(e)}>
          Cancelar pedido
        </Button>
      </form>
      {loading && <Loading />}
    </>
  );
}

export default HandleCancelOrder;
