import { ReactNode } from 'react';
import * as S from './style';

type Props = {
  children: ReactNode;
};

const MainContent = ({ children }: Props): JSX.Element => {
  return <S.Wrapper>{children}</S.Wrapper>;
};

export default MainContent;
