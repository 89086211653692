/* istanbul ignore file */

import ModalForm from '@components/ModalForm';
import { useGetOrdersByEstablishmentIdLazyQuery } from '@services/bff/GraphqlHooks';
import { EstablishmentDetailSection } from './EstablishmentDetailModalData';
import { EstablishmentOrdersList } from './EstablishmentOrdersList';
import { EstablishmentOrdersSearch } from './EstablishmentOrdersSearch';
import * as S from '../../CustomerDetail.styles';
import { Loading } from '@ifood/pomodoro-components';
import { GET_ESTABLISHMENT_BY_ID } from '@pages/Customers/bff/queries/getEstablishmentById';
import EstablishmentPayments from './EstablishmentPayments/EstablishmentPayments';
import { sortPayments } from './EstablishmentPayments/utils';
import EstablishmentSintegraBypass from './EstablishmentSintegraBypass';
import { useCallback, useState } from 'react';
import { BFFGetEstablishmentByIdQuery } from '@services/bff/GraphqlOperations';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import { useQuery } from '@apollo/react-hooks';

interface IModalFormProps {
  isOpen: boolean;
  establishmentId: string;
  onClose: () => void;
}

const INITIAL_PAGE = 0;
const PAGE_SIZE = 10;

export default function EstablishmentDetailModal({ isOpen, onClose, establishmentId }: IModalFormProps) {
  const [establishment, setEstablishment] = useState<BFFGetEstablishmentByIdQuery['getEstablishmentById']>();

  const [payments, setPayments] = useState<string[]>();
  const [sintegrawsBypass, setSintegrawsBypass] = useState(false);

  const { data, loading } = useQuery(GET_ESTABLISHMENT_BY_ID, {
    variables: { establishmentId },
    skip: !establishmentId,
    onCompleted: () => {
      if (!data?.getEstablishmentById) return;

      const { paymentTypes, sintegrawsBypass } = data.getEstablishmentById;
      setEstablishment(data.getEstablishmentById);
      setPayments(paymentTypes.length ? [...paymentTypes] : []);
      setSintegrawsBypass(sintegrawsBypass);
    },
    onError: error =>
      console.error(`[Error - GET_ESTABLISHMENT_BY_ID]: ${error.graphQLErrors[INITIAL_PAGE] || error.message}`),
  });

  const [getOrdersByEstablishmentId, { data: ordersResponse, loading: isLoadingOrders }] =
    useGetOrdersByEstablishmentIdLazyQuery({
      variables: { establishmentId, pageable: { page: INITIAL_PAGE, size: PAGE_SIZE } },
    });

  const updatePayments = useCallback((list: string[]) => {
    setPayments([...list]);
  }, []);

  const sintegrawsByPassRules = [Roles.SINTEGRAWS_BY_PASS_RO, Roles.SINTEGRAWS_BY_PASS_WR];
  const paymentTypesRules = [Roles.ESTABLISHMENT_PAYMENT_TYPES_RO, Roles.ESTABLISHMENT_PAYMENT_TYPES_WR];

  const hasSintegraWsRules = (getRoles() || []).some((rule: string) => sintegrawsByPassRules.includes(rule));
  const hasPaymentTypesRules = (getRoles() || []).some((rule: string) => paymentTypesRules.includes(rule));

  return (
    <ModalForm
      isOpen={isOpen}
      modalProps={{ size: '80%' }}
      title={`Pedidos - ${establishment?.name || `...`}`}
      onClose={onClose}
    >
      <S.ModalWrapper>
        {loading && !establishment?.id ? (
          <Loading color="primary" variant="large" />
        ) : (
          <>
            <EstablishmentDetailSection establishment={establishment} />
            {hasSintegraWsRules && (
              <EstablishmentSintegraBypass
                hasEditingRule={getRoles().includes(Roles.SINTEGRAWS_BY_PASS_WR)}
                sintegrawsBypass={sintegrawsBypass}
                updateSintegrawsBypass={setSintegrawsBypass}
              />
            )}

            {hasPaymentTypesRules && payments !== undefined && (
              <EstablishmentPayments
                hasEditingRule={getRoles().includes(Roles.ESTABLISHMENT_PAYMENT_TYPES_WR)}
                payments={payments?.sort(sortPayments)}
                updatePayments={updatePayments}
              />
            )}

            <EstablishmentOrdersSearch establishmentId={establishmentId} fetchOrders={getOrdersByEstablishmentId} />
            <EstablishmentOrdersList
              closeModal={onClose}
              establishment={{ paymentTypes: payments, sintegrawsBypass }}
              establishmentId={establishmentId}
              fetchOrders={getOrdersByEstablishmentId}
              isLoading={isLoadingOrders}
              ordersResponse={ordersResponse?.getOrdersByEstablishmentId}
            />
          </>
        )}
      </S.ModalWrapper>
    </ModalForm>
  );
}
