/* eslint-disable */
import * as Types from './GraphqlOperations';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export const GetDiscountV2Document = gql`
  query getDiscountV2($idDiscount: ID!) {
    getDiscountV2(discountId: $idDiscount) {
      id
      couponBatchSize
      couponBatchOwnerId
      name
      friendlyName
      type
      active
      startDate
      endDate
      highlight
      isCumulative
      campaignGroupUniqueDiscount
      campaignGroupId
      value
      minimumValue
      maxValue
      isCoupon
      couponCode
      couponMaxUsage
      customerMaxUsage
      couponUsage
      description
      rules {
        id
        type
        filter
        values {
          id
          name
        }
      }
      showSite
      newCustomer
      store
      subsidies {
        id
        active
        percentage
        type
        seller {
          id
          name
        }
        costCenter {
          id
          name
          code
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetDiscountV2Query__
 *
 * To run a query within a React component, call `useGetDiscountV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountV2Query({
 *   variables: {
 *      idDiscount: // value for 'idDiscount'
 *   },
 * });
 */
export function useGetDiscountV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BFFGetDiscountV2Query, Types.BFFGetDiscountV2QueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BFFGetDiscountV2Query, Types.BFFGetDiscountV2QueryVariables>(
    GetDiscountV2Document,
    baseOptions
  );
}
export function useGetDiscountV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.BFFGetDiscountV2Query, Types.BFFGetDiscountV2QueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.BFFGetDiscountV2Query, Types.BFFGetDiscountV2QueryVariables>(
    GetDiscountV2Document,
    baseOptions
  );
}
export type GetDiscountV2QueryHookResult = ReturnType<typeof useGetDiscountV2Query>;
export type GetDiscountV2LazyQueryHookResult = ReturnType<typeof useGetDiscountV2LazyQuery>;
export type GetDiscountV2QueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetDiscountV2Query,
  Types.BFFGetDiscountV2QueryVariables
>;
export const ReportFraudLogDocument = gql`
  mutation reportFraudLog($fraud: Fraud!) {
    reportFraudLog(fraud: $fraud)
  }
`;
export type BFFReportFraudLogMutationFn = ApolloReactCommon.MutationFunction<
  Types.BFFReportFraudLogMutation,
  Types.BFFReportFraudLogMutationVariables
>;

/**
 * __useReportFraudLogMutation__
 *
 * To run a mutation, you first call `useReportFraudLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportFraudLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFraudLogMutation, { data, loading, error }] = useReportFraudLogMutation({
 *   variables: {
 *      fraud: // value for 'fraud'
 *   },
 * });
 */
export function useReportFraudLogMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BFFReportFraudLogMutation,
    Types.BFFReportFraudLogMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.BFFReportFraudLogMutation, Types.BFFReportFraudLogMutationVariables>(
    ReportFraudLogDocument,
    baseOptions
  );
}
export type ReportFraudLogMutationHookResult = ReturnType<typeof useReportFraudLogMutation>;
export type ReportFraudLogMutationResult = ApolloReactCommon.MutationResult<Types.BFFReportFraudLogMutation>;
export type ReportFraudLogMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.BFFReportFraudLogMutation,
  Types.BFFReportFraudLogMutationVariables
>;
export const RemoveFraudBlockDocument = gql`
  mutation removeFraudBlock($inactivation: Inactivation!) {
    removeFraudBlock(inactivation: $inactivation)
  }
`;
export type BFFRemoveFraudBlockMutationFn = ApolloReactCommon.MutationFunction<
  Types.BFFRemoveFraudBlockMutation,
  Types.BFFRemoveFraudBlockMutationVariables
>;

/**
 * __useRemoveFraudBlockMutation__
 *
 * To run a mutation, you first call `useRemoveFraudBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFraudBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFraudBlockMutation, { data, loading, error }] = useRemoveFraudBlockMutation({
 *   variables: {
 *      inactivation: // value for 'inactivation'
 *   },
 * });
 */
export function useRemoveFraudBlockMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BFFRemoveFraudBlockMutation,
    Types.BFFRemoveFraudBlockMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.BFFRemoveFraudBlockMutation, Types.BFFRemoveFraudBlockMutationVariables>(
    RemoveFraudBlockDocument,
    baseOptions
  );
}
export type RemoveFraudBlockMutationHookResult = ReturnType<typeof useRemoveFraudBlockMutation>;
export type RemoveFraudBlockMutationResult = ApolloReactCommon.MutationResult<Types.BFFRemoveFraudBlockMutation>;
export type RemoveFraudBlockMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.BFFRemoveFraudBlockMutation,
  Types.BFFRemoveFraudBlockMutationVariables
>;
export const GetCustomerByIdDocument = gql`
  query getCustomerById($customerId: ID!) {
    getCustomerById(customerId: $customerId) {
      id
      name
      contactName
      email
      owner
      phone
      phoneDialCode
      iderp
      kind
      hasFraudHistory
      hasActiveFraud
      canReceiveNewsletter
    }
  }
`;

/**
 * __useGetCustomerByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByIdQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BFFGetCustomerByIdQuery, Types.BFFGetCustomerByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BFFGetCustomerByIdQuery, Types.BFFGetCustomerByIdQueryVariables>(
    GetCustomerByIdDocument,
    baseOptions
  );
}
export function useGetCustomerByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetCustomerByIdQuery,
    Types.BFFGetCustomerByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BFFGetCustomerByIdQuery, Types.BFFGetCustomerByIdQueryVariables>(
    GetCustomerByIdDocument,
    baseOptions
  );
}
export type GetCustomerByIdQueryHookResult = ReturnType<typeof useGetCustomerByIdQuery>;
export type GetCustomerByIdLazyQueryHookResult = ReturnType<typeof useGetCustomerByIdLazyQuery>;
export type GetCustomerByIdQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetCustomerByIdQuery,
  Types.BFFGetCustomerByIdQueryVariables
>;
export const GetBonusShopBalanceDocument = gql`
  query getBonusShopBalance($customerId: ID!) {
    getBonusShopBalance(customerId: $customerId) {
      type
      availableAmount
      bonusShopPercentage
    }
  }
`;

/**
 * __useGetBonusShopBalanceQuery__
 *
 * To run a query within a React component, call `useGetBonusShopBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBonusShopBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBonusShopBalanceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetBonusShopBalanceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetBonusShopBalanceQuery,
    Types.BFFGetBonusShopBalanceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BFFGetBonusShopBalanceQuery, Types.BFFGetBonusShopBalanceQueryVariables>(
    GetBonusShopBalanceDocument,
    baseOptions
  );
}
export function useGetBonusShopBalanceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetBonusShopBalanceQuery,
    Types.BFFGetBonusShopBalanceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BFFGetBonusShopBalanceQuery, Types.BFFGetBonusShopBalanceQueryVariables>(
    GetBonusShopBalanceDocument,
    baseOptions
  );
}
export type GetBonusShopBalanceQueryHookResult = ReturnType<typeof useGetBonusShopBalanceQuery>;
export type GetBonusShopBalanceLazyQueryHookResult = ReturnType<typeof useGetBonusShopBalanceLazyQuery>;
export type GetBonusShopBalanceQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetBonusShopBalanceQuery,
  Types.BFFGetBonusShopBalanceQueryVariables
>;
export const GetBonusShopStatementDocument = gql`
  query getBonusShopStatement($customerId: ID!) {
    getBonusShopStatement(customerId: $customerId) {
      content {
        customerId
        type
        orderId
        walletId
        amount
        startDate
        endDate
        description
        transactionType
        transactionDate
      }
    }
  }
`;

/**
 * __useGetBonusShopStatementQuery__
 *
 * To run a query within a React component, call `useGetBonusShopStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBonusShopStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBonusShopStatementQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetBonusShopStatementQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetBonusShopStatementQuery,
    Types.BFFGetBonusShopStatementQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BFFGetBonusShopStatementQuery, Types.BFFGetBonusShopStatementQueryVariables>(
    GetBonusShopStatementDocument,
    baseOptions
  );
}
export function useGetBonusShopStatementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetBonusShopStatementQuery,
    Types.BFFGetBonusShopStatementQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BFFGetBonusShopStatementQuery,
    Types.BFFGetBonusShopStatementQueryVariables
  >(GetBonusShopStatementDocument, baseOptions);
}
export type GetBonusShopStatementQueryHookResult = ReturnType<typeof useGetBonusShopStatementQuery>;
export type GetBonusShopStatementLazyQueryHookResult = ReturnType<typeof useGetBonusShopStatementLazyQuery>;
export type GetBonusShopStatementQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetBonusShopStatementQuery,
  Types.BFFGetBonusShopStatementQueryVariables
>;
export const GetEstablishmentsByCustomerIdDocument = gql`
  query getEstablishmentsByCustomerId($params: EstablishmentByCustomerInput!) {
    getEstablishmentsByCustomerId(params: $params) {
      __typename
      ... on EstablishmentByCustomerResponse {
        totalElements
        content {
          id
          name
        }
      }
      ... on NotFound {
        error
        message
      }
    }
  }
`;

/**
 * __useGetEstablishmentsByCustomerIdQuery__
 *
 * To run a query within a React component, call `useGetEstablishmentsByCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstablishmentsByCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstablishmentsByCustomerIdQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetEstablishmentsByCustomerIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetEstablishmentsByCustomerIdQuery,
    Types.BFFGetEstablishmentsByCustomerIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.BFFGetEstablishmentsByCustomerIdQuery,
    Types.BFFGetEstablishmentsByCustomerIdQueryVariables
  >(GetEstablishmentsByCustomerIdDocument, baseOptions);
}
export function useGetEstablishmentsByCustomerIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetEstablishmentsByCustomerIdQuery,
    Types.BFFGetEstablishmentsByCustomerIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BFFGetEstablishmentsByCustomerIdQuery,
    Types.BFFGetEstablishmentsByCustomerIdQueryVariables
  >(GetEstablishmentsByCustomerIdDocument, baseOptions);
}
export type GetEstablishmentsByCustomerIdQueryHookResult = ReturnType<typeof useGetEstablishmentsByCustomerIdQuery>;
export type GetEstablishmentsByCustomerIdLazyQueryHookResult = ReturnType<
  typeof useGetEstablishmentsByCustomerIdLazyQuery
>;
export type GetEstablishmentsByCustomerIdQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetEstablishmentsByCustomerIdQuery,
  Types.BFFGetEstablishmentsByCustomerIdQueryVariables
>;
export const GetWalletsBalanceByEstablishmentIdDocument = gql`
  query getWalletsBalanceByEstablishmentId($establishmentIds: [String!]!) {
    getWalletsBalanceByEstablishmentId(establishmentIds: $establishmentIds) {
      type
      availableAmount
    }
  }
`;

/**
 * __useGetWalletsBalanceByEstablishmentIdQuery__
 *
 * To run a query within a React component, call `useGetWalletsBalanceByEstablishmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletsBalanceByEstablishmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletsBalanceByEstablishmentIdQuery({
 *   variables: {
 *      establishmentIds: // value for 'establishmentIds'
 *   },
 * });
 */
export function useGetWalletsBalanceByEstablishmentIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetWalletsBalanceByEstablishmentIdQuery,
    Types.BFFGetWalletsBalanceByEstablishmentIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.BFFGetWalletsBalanceByEstablishmentIdQuery,
    Types.BFFGetWalletsBalanceByEstablishmentIdQueryVariables
  >(GetWalletsBalanceByEstablishmentIdDocument, baseOptions);
}
export function useGetWalletsBalanceByEstablishmentIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetWalletsBalanceByEstablishmentIdQuery,
    Types.BFFGetWalletsBalanceByEstablishmentIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BFFGetWalletsBalanceByEstablishmentIdQuery,
    Types.BFFGetWalletsBalanceByEstablishmentIdQueryVariables
  >(GetWalletsBalanceByEstablishmentIdDocument, baseOptions);
}
export type GetWalletsBalanceByEstablishmentIdQueryHookResult = ReturnType<
  typeof useGetWalletsBalanceByEstablishmentIdQuery
>;
export type GetWalletsBalanceByEstablishmentIdLazyQueryHookResult = ReturnType<
  typeof useGetWalletsBalanceByEstablishmentIdLazyQuery
>;
export type GetWalletsBalanceByEstablishmentIdQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetWalletsBalanceByEstablishmentIdQuery,
  Types.BFFGetWalletsBalanceByEstablishmentIdQueryVariables
>;
export const GetOrdersByEstablishmentIdDocument = gql`
  query getOrdersByEstablishmentId(
    $establishmentId: ID!
    $pageable: PageableInputSmall
    $orderId: ID
    $minCreatedAt: String
  ) {
    getOrdersByEstablishmentId(
      establishmentId: $establishmentId
      pageable: $pageable
      orderId: $orderId
      minCreatedAt: $minCreatedAt
    ) {
      totalElements
      content {
        orderId
        sellerName
        totalAmount
        status
        paymentType
        bonusShopAmount
        repasseAmount
        createdAt
        discount
      }
    }
  }
`;

/**
 * __useGetOrdersByEstablishmentIdQuery__
 *
 * To run a query within a React component, call `useGetOrdersByEstablishmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersByEstablishmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersByEstablishmentIdQuery({
 *   variables: {
 *      establishmentId: // value for 'establishmentId'
 *      pageable: // value for 'pageable'
 *      orderId: // value for 'orderId'
 *      minCreatedAt: // value for 'minCreatedAt'
 *   },
 * });
 */
export function useGetOrdersByEstablishmentIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetOrdersByEstablishmentIdQuery,
    Types.BFFGetOrdersByEstablishmentIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.BFFGetOrdersByEstablishmentIdQuery,
    Types.BFFGetOrdersByEstablishmentIdQueryVariables
  >(GetOrdersByEstablishmentIdDocument, baseOptions);
}
export function useGetOrdersByEstablishmentIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetOrdersByEstablishmentIdQuery,
    Types.BFFGetOrdersByEstablishmentIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BFFGetOrdersByEstablishmentIdQuery,
    Types.BFFGetOrdersByEstablishmentIdQueryVariables
  >(GetOrdersByEstablishmentIdDocument, baseOptions);
}
export type GetOrdersByEstablishmentIdQueryHookResult = ReturnType<typeof useGetOrdersByEstablishmentIdQuery>;
export type GetOrdersByEstablishmentIdLazyQueryHookResult = ReturnType<typeof useGetOrdersByEstablishmentIdLazyQuery>;
export type GetOrdersByEstablishmentIdQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetOrdersByEstablishmentIdQuery,
  Types.BFFGetOrdersByEstablishmentIdQueryVariables
>;
export const GetEstablishmentByIdDocument = gql`
  query getEstablishmentById($establishmentId: ID!) {
    getEstablishmentById(establishmentId: $establishmentId) {
      id
      name
      stateRegistration
      companyName
      municipalRegistration
      document
      phone
      phoneDialCode
      address {
        id
        address
        number
        city
        state
        postalCode
        neighborhood
      }
    }
  }
`;

/**
 * __useGetEstablishmentByIdQuery__
 *
 * To run a query within a React component, call `useGetEstablishmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstablishmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstablishmentByIdQuery({
 *   variables: {
 *      establishmentId: // value for 'establishmentId'
 *   },
 * });
 */
export function useGetEstablishmentByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetEstablishmentByIdQuery,
    Types.BFFGetEstablishmentByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BFFGetEstablishmentByIdQuery, Types.BFFGetEstablishmentByIdQueryVariables>(
    GetEstablishmentByIdDocument,
    baseOptions
  );
}
export function useGetEstablishmentByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetEstablishmentByIdQuery,
    Types.BFFGetEstablishmentByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BFFGetEstablishmentByIdQuery, Types.BFFGetEstablishmentByIdQueryVariables>(
    GetEstablishmentByIdDocument,
    baseOptions
  );
}
export type GetEstablishmentByIdQueryHookResult = ReturnType<typeof useGetEstablishmentByIdQuery>;
export type GetEstablishmentByIdLazyQueryHookResult = ReturnType<typeof useGetEstablishmentByIdLazyQuery>;
export type GetEstablishmentByIdQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetEstablishmentByIdQuery,
  Types.BFFGetEstablishmentByIdQueryVariables
>;
export const UpdateContactNameDocument = gql`
  mutation updateContactName($contact: Contact!) {
    updateCustomerContactNames(contact: $contact) {
      id
    }
  }
`;
export type BFFUpdateContactNameMutationFn = ApolloReactCommon.MutationFunction<
  Types.BFFUpdateContactNameMutation,
  Types.BFFUpdateContactNameMutationVariables
>;

/**
 * __useUpdateContactNameMutation__
 *
 * To run a mutation, you first call `useUpdateContactNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactNameMutation, { data, loading, error }] = useUpdateContactNameMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateContactNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BFFUpdateContactNameMutation,
    Types.BFFUpdateContactNameMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.BFFUpdateContactNameMutation, Types.BFFUpdateContactNameMutationVariables>(
    UpdateContactNameDocument,
    baseOptions
  );
}
export type UpdateContactNameMutationHookResult = ReturnType<typeof useUpdateContactNameMutation>;
export type UpdateContactNameMutationResult = ApolloReactCommon.MutationResult<Types.BFFUpdateContactNameMutation>;
export type UpdateContactNameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.BFFUpdateContactNameMutation,
  Types.BFFUpdateContactNameMutationVariables
>;
export const GetCustomerFraudListDocument = gql`
  query getCustomerFraudList($params: FraudHistoryInput) {
    getCustomerFraudList(params: $params) {
      totalElements
      content {
        id
        customerId
        fraudType
        reportDetails
        reportedAt
        reportedBy
        inactivatedAt
        inactivatedBy
        inactivationDetails
        active
      }
    }
  }
`;

/**
 * __useGetCustomerFraudListQuery__
 *
 * To run a query within a React component, call `useGetCustomerFraudListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFraudListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFraudListQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCustomerFraudListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetCustomerFraudListQuery,
    Types.BFFGetCustomerFraudListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BFFGetCustomerFraudListQuery, Types.BFFGetCustomerFraudListQueryVariables>(
    GetCustomerFraudListDocument,
    baseOptions
  );
}
export function useGetCustomerFraudListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetCustomerFraudListQuery,
    Types.BFFGetCustomerFraudListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BFFGetCustomerFraudListQuery, Types.BFFGetCustomerFraudListQueryVariables>(
    GetCustomerFraudListDocument,
    baseOptions
  );
}
export type GetCustomerFraudListQueryHookResult = ReturnType<typeof useGetCustomerFraudListQuery>;
export type GetCustomerFraudListLazyQueryHookResult = ReturnType<typeof useGetCustomerFraudListLazyQuery>;
export type GetCustomerFraudListQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetCustomerFraudListQuery,
  Types.BFFGetCustomerFraudListQueryVariables
>;
export const SellerChangeStatusDocument = gql`
  mutation sellerChangeStatus($sellerStatus: SellerStatusInput) {
    sellerChangeStatus(sellerStatus: $sellerStatus) {
      id
    }
  }
`;
export type BFFSellerChangeStatusMutationFn = ApolloReactCommon.MutationFunction<
  Types.BFFSellerChangeStatusMutation,
  Types.BFFSellerChangeStatusMutationVariables
>;

/**
 * __useSellerChangeStatusMutation__
 *
 * To run a mutation, you first call `useSellerChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellerChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellerChangeStatusMutation, { data, loading, error }] = useSellerChangeStatusMutation({
 *   variables: {
 *      sellerStatus: // value for 'sellerStatus'
 *   },
 * });
 */
export function useSellerChangeStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BFFSellerChangeStatusMutation,
    Types.BFFSellerChangeStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.BFFSellerChangeStatusMutation,
    Types.BFFSellerChangeStatusMutationVariables
  >(SellerChangeStatusDocument, baseOptions);
}
export type SellerChangeStatusMutationHookResult = ReturnType<typeof useSellerChangeStatusMutation>;
export type SellerChangeStatusMutationResult = ApolloReactCommon.MutationResult<Types.BFFSellerChangeStatusMutation>;
export type SellerChangeStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.BFFSellerChangeStatusMutation,
  Types.BFFSellerChangeStatusMutationVariables
>;
export const GetInactivationReasonsDocument = gql`
  query getInactivationReasons {
    getInactivationReasons {
      id
      disableUser
      reason
    }
  }
`;

/**
 * __useGetInactivationReasonsQuery__
 *
 * To run a query within a React component, call `useGetInactivationReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInactivationReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInactivationReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInactivationReasonsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetInactivationReasonsQuery,
    Types.BFFGetInactivationReasonsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BFFGetInactivationReasonsQuery, Types.BFFGetInactivationReasonsQueryVariables>(
    GetInactivationReasonsDocument,
    baseOptions
  );
}
export function useGetInactivationReasonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetInactivationReasonsQuery,
    Types.BFFGetInactivationReasonsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BFFGetInactivationReasonsQuery,
    Types.BFFGetInactivationReasonsQueryVariables
  >(GetInactivationReasonsDocument, baseOptions);
}
export type GetInactivationReasonsQueryHookResult = ReturnType<typeof useGetInactivationReasonsQuery>;
export type GetInactivationReasonsLazyQueryHookResult = ReturnType<typeof useGetInactivationReasonsLazyQuery>;
export type GetInactivationReasonsQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetInactivationReasonsQuery,
  Types.BFFGetInactivationReasonsQueryVariables
>;
export const GetSellerV2Document = gql`
  query getSellerV2($sellerV2Id: Int) {
    getSellerV2(sellerV2Id: $sellerV2Id) {
      active
      id
      cnae
      mcc
      name
      companyName
      document
      businessType
      email
      user {
        id
        name
        email
        active
        mobilePhone
        dddMobilePhone
        cellPhone
        dddCellPhone
        type
        addressId
      }
      shippingByShop
      commission
      stateRegistration
      cityRegistration
      minimumOrderPrice
      owner
      postalCode
      handlingTime
      order
      carouselOrder
      cutOffTime
      darkstore
      logo
      type {
        id
        name
      }
      apiIntegration
      bankAccount {
        bankAccountId
        bankId
        bankCode
        bankName
        agency
        account
        accountType
      }
      address {
        id
        address
        number
        complement
        neighborhood
        city
        state
        stateName
        zipcode
        country
        latitude
        longitude
      }
      inactivationReason {
        id
        disableUser
        observation
        reason
      }
      modalAdmin
      merchantId
      frnId
      isNewIntegration
      isTestMerchant
      updatedAt
    }
  }
`;

/**
 * __useGetSellerV2Query__
 *
 * To run a query within a React component, call `useGetSellerV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerV2Query({
 *   variables: {
 *      sellerV2Id: // value for 'sellerV2Id'
 *   },
 * });
 */
export function useGetSellerV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BFFGetSellerV2Query, Types.BFFGetSellerV2QueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BFFGetSellerV2Query, Types.BFFGetSellerV2QueryVariables>(
    GetSellerV2Document,
    baseOptions
  );
}
export function useGetSellerV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.BFFGetSellerV2Query, Types.BFFGetSellerV2QueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.BFFGetSellerV2Query, Types.BFFGetSellerV2QueryVariables>(
    GetSellerV2Document,
    baseOptions
  );
}
export type GetSellerV2QueryHookResult = ReturnType<typeof useGetSellerV2Query>;
export type GetSellerV2LazyQueryHookResult = ReturnType<typeof useGetSellerV2LazyQuery>;
export type GetSellerV2QueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetSellerV2Query,
  Types.BFFGetSellerV2QueryVariables
>;
export const UpdateSellerV2Document = gql`
  mutation updateSellerV2($sellerV2: SellerV2UpdateInput) {
    updateSellerV2(sellerV2: $sellerV2) {
      active
      id
      cnae
      name
      companyName
      document
      businessType
      email
      user {
        id
        name
        email
        active
        mobilePhone
        dddMobilePhone
        cellPhone
        dddCellPhone
        type
        addressId
      }
      shippingByShop
      commission
      stateRegistration
      cityRegistration
      minimumOrderPrice
      owner
      postalCode
      handlingTime
      order
      carouselOrder
      cutOffTime
      darkstore
      logo
      type {
        id
        name
      }
      apiIntegration
      bankAccount {
        bankAccountId
        bankId
        bankCode
        bankName
        agency
        account
        accountType
      }
      address {
        id
        address
        number
        complement
        neighborhood
        city
        state
        stateName
        zipcode
        country
      }
      modalAdmin
      merchantId
      frnId
      isNewIntegration
      isTestMerchant
      updatedAt
    }
  }
`;
export type BFFUpdateSellerV2MutationFn = ApolloReactCommon.MutationFunction<
  Types.BFFUpdateSellerV2Mutation,
  Types.BFFUpdateSellerV2MutationVariables
>;

/**
 * __useUpdateSellerV2Mutation__
 *
 * To run a mutation, you first call `useUpdateSellerV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSellerV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSellerV2Mutation, { data, loading, error }] = useUpdateSellerV2Mutation({
 *   variables: {
 *      sellerV2: // value for 'sellerV2'
 *   },
 * });
 */
export function useUpdateSellerV2Mutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BFFUpdateSellerV2Mutation,
    Types.BFFUpdateSellerV2MutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.BFFUpdateSellerV2Mutation, Types.BFFUpdateSellerV2MutationVariables>(
    UpdateSellerV2Document,
    baseOptions
  );
}
export type UpdateSellerV2MutationHookResult = ReturnType<typeof useUpdateSellerV2Mutation>;
export type UpdateSellerV2MutationResult = ApolloReactCommon.MutationResult<Types.BFFUpdateSellerV2Mutation>;
export type UpdateSellerV2MutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.BFFUpdateSellerV2Mutation,
  Types.BFFUpdateSellerV2MutationVariables
>;
export const CreateSellerV2Document = gql`
  mutation createSellerV2($sellerV2: SellerV2Input!) {
    createSellerV2(sellerV2: $sellerV2) {
      active
      id
      cnae
      name
      companyName
      document
      email
      businessType
      user {
        id
        name
        active
        mobilePhone
        dddMobilePhone
        cellPhone
        dddCellPhone
        type
        addressId
      }
      shippingByShop
      commission
      stateRegistration
      cityRegistration
      minimumOrderPrice
      owner
      postalCode
      handlingTime
      order
      carouselOrder
      cutOffTime
      darkstore
      logo
      type {
        id
        name
      }
      apiIntegration
      bankAccount {
        bankAccountId
        bankId
        bankCode
        bankName
        agency
        account
        accountType
      }
      address {
        id
        address
        number
        complement
        neighborhood
        city
        state
        stateName
        zipcode
        country
      }
      modalAdmin
      merchantId
      frnId
      isNewIntegration
      isTestMerchant
      updatedAt
    }
  }
`;
export type BFFCreateSellerV2MutationFn = ApolloReactCommon.MutationFunction<
  Types.BFFCreateSellerV2Mutation,
  Types.BFFCreateSellerV2MutationVariables
>;

/**
 * __useCreateSellerV2Mutation__
 *
 * To run a mutation, you first call `useCreateSellerV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSellerV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSellerV2Mutation, { data, loading, error }] = useCreateSellerV2Mutation({
 *   variables: {
 *      sellerV2: // value for 'sellerV2'
 *   },
 * });
 */
export function useCreateSellerV2Mutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BFFCreateSellerV2Mutation,
    Types.BFFCreateSellerV2MutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.BFFCreateSellerV2Mutation, Types.BFFCreateSellerV2MutationVariables>(
    CreateSellerV2Document,
    baseOptions
  );
}
export type CreateSellerV2MutationHookResult = ReturnType<typeof useCreateSellerV2Mutation>;
export type CreateSellerV2MutationResult = ApolloReactCommon.MutationResult<Types.BFFCreateSellerV2Mutation>;
export type CreateSellerV2MutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.BFFCreateSellerV2Mutation,
  Types.BFFCreateSellerV2MutationVariables
>;
export const GetSellerInfoByCnpjDocument = gql`
  query getSellerInfoByCnpj($cnpj: String!) {
    getSellerValidationCnpj(cnpj: $cnpj) {
      cnae {
        code
        text
      }
    }
  }
`;

/**
 * __useGetSellerInfoByCnpjQuery__
 *
 * To run a query within a React component, call `useGetSellerInfoByCnpjQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerInfoByCnpjQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerInfoByCnpjQuery({
 *   variables: {
 *      cnpj: // value for 'cnpj'
 *   },
 * });
 */
export function useGetSellerInfoByCnpjQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BFFGetSellerInfoByCnpjQuery,
    Types.BFFGetSellerInfoByCnpjQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BFFGetSellerInfoByCnpjQuery, Types.BFFGetSellerInfoByCnpjQueryVariables>(
    GetSellerInfoByCnpjDocument,
    baseOptions
  );
}
export function useGetSellerInfoByCnpjLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BFFGetSellerInfoByCnpjQuery,
    Types.BFFGetSellerInfoByCnpjQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BFFGetSellerInfoByCnpjQuery, Types.BFFGetSellerInfoByCnpjQueryVariables>(
    GetSellerInfoByCnpjDocument,
    baseOptions
  );
}
export type GetSellerInfoByCnpjQueryHookResult = ReturnType<typeof useGetSellerInfoByCnpjQuery>;
export type GetSellerInfoByCnpjLazyQueryHookResult = ReturnType<typeof useGetSellerInfoByCnpjLazyQuery>;
export type GetSellerInfoByCnpjQueryResult = ApolloReactCommon.QueryResult<
  Types.BFFGetSellerInfoByCnpjQuery,
  Types.BFFGetSellerInfoByCnpjQueryVariables
>;
export const SellerResetPasswordDocument = gql`
  mutation sellerResetPassword($sellerId: Int!) {
    sellerResetPassword(sellerId: $sellerId) {
      pwLink
    }
  }
`;
export type BFFSellerResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  Types.BFFSellerResetPasswordMutation,
  Types.BFFSellerResetPasswordMutationVariables
>;

/**
 * __useSellerResetPasswordMutation__
 *
 * To run a mutation, you first call `useSellerResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellerResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellerResetPasswordMutation, { data, loading, error }] = useSellerResetPasswordMutation({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useSellerResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.BFFSellerResetPasswordMutation,
    Types.BFFSellerResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.BFFSellerResetPasswordMutation,
    Types.BFFSellerResetPasswordMutationVariables
  >(SellerResetPasswordDocument, baseOptions);
}
export type SellerResetPasswordMutationHookResult = ReturnType<typeof useSellerResetPasswordMutation>;
export type SellerResetPasswordMutationResult = ApolloReactCommon.MutationResult<Types.BFFSellerResetPasswordMutation>;
export type SellerResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.BFFSellerResetPasswordMutation,
  Types.BFFSellerResetPasswordMutationVariables
>;
