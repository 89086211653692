import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';

import '../../../../components/HeaderGroups/header-groups.scss';
import './product-search.scss';

const FormSearchProductVariation = props => {
  const [active, setActive] = useState(null);
  const [name, setName] = useState(null);
  const options = ['Todos', 'Habilitado', 'Desabilitado'];

  const sendData = e => {
    e.preventDefault();
    props.data({ variationName: name, active: handleActive(active) });
  };

  function handleActive(active) {
    if (active === 'Habilitado') return true;
    else if (active === 'Desabilitado') return false;
    return null;
  }

  return (
    <form className="form-variation" onSubmit={e => sendData(e)}>
      <div className="form-row">
        <div className="outlined-label-wrap">
          <OutlinedInput
            name="productName"
            className="form-brand__input"
            placeholder="Variação"
            border
            maxLength={50}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <div className="dropdown-label-wrap">
          <Dropdown
            options={options}
            onChange={e => setActive(e.value)}
            value={active ? active : null}
            placeholder={options[0]}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Estado</label>
        </div>
        <Button className="btn-secondary" type="submit">
          Filtrar variações
        </Button>
        {props.role ? (
          <NavLink className="link" to={'/catalogos/produtos/' + props.id + '/variacoes/cadastro'}>
            Criar nova variação
          </NavLink>
        ) : null}
      </div>
    </form>
  );
};

export default FormSearchProductVariation;
