import { useEffect } from 'react';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionCustomer } from '@analytics/aboyeur/entities/sessionCustomer';

import CatalogItem from '@components/CatalogItem';

import ImageCustomer from '../../../assets/images/home-customers.png';

const HomeCustomers = () => {
  const sessionCustomer = useAboyeur(SessionCustomer);

  useEffect(() => {
    sessionCustomer.events.customer.viewAdminCustomer();
  }, [sessionCustomer]);

  const items = [
    {
      title: 'Grupos de Clientes',
      image: ImageCustomer,
      description: 'cadastro e edição de grupos de clientes',
      link: 'clientes/grupos-clientes',
    },
  ];

  return (
    <div className="content-body home">
      <div className="content-body__box">
        {items.map(item => {
          const { image, title, description, link } = item;
          return <CatalogItem image={image} title={title} description={description} link={link} />;
        })}
      </div>
    </div>
  );
};

export default HomeCustomers;
