import React, { useState } from 'react';
import { Link, Button, OutlinedInput } from 'ifoodshop-react-ui';
import Tooltip from '@material-ui/core/Tooltip';
import { StatesList } from "../../../../../../helpers/States";
import './headerDistributionAddressSearch.scss';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

function HeaderDistributionAddressSearch({ role, onSubmitFilters }) {
	const [filters, setFilters] = useState({
		postalCode: null,
		city: null,
		state: null
	});

	function handleSubmit(e) {
		e.preventDefault();
		onSubmitFilters(filters);
	}

	return (
		<div className="header-customer-group header-customer-group--src header-distribution-address">
			<div className="header-customer-group__info">
				<h2 className="section-title">Centro de Distribuição</h2>
				<p className="header-customer-group__description">Busque um Centro de Distribuição</p>
			</div>
			<div className="header-customer-group__new">
				{
					role
						? <Link href="/fornecedores/enderecos-distribuicao/cadastro">Criar novo Centro de Distribuição</Link>
						: <Tooltip title="Você não tem permissão para criar novo Centro de Distribuição">
							<a className="link--disabled">Criar novo Centro de Distribuição</a>
						</Tooltip>
				}
			</div>

			<form className="header-customer-group__form" onSubmit={handleSubmit}>
				<div className="header-customer-group__form-input">
					<OutlinedInput
						mask="99999-999"
						type="text"
						name="postalCode"
						placeholder="CEP"
						border
						onChange={(e) => setFilters({
							...filters, 
							postalCode: e.target.value ? 
								parseInt(e.target.value.replace('-', '')) : null
						} )}
					/>

					<OutlinedInput
						name="city"
						placeholder="Cidade"
						border
						onChange={e => setFilters({
							...filters,
							city: e.target.value || null
						} )}
					/>
					<div className="dropdown-label-wrap">
						<Autocomplete
							name="state"
							className="autocomplete-ifood"
							noOptionsText="Sem resultados"
							options={StatesList || []}
							getOptionLabel={(option) => option}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Estado"
									variant="outlined"
								/>
							)}
							onSelect={(e) => setFilters({ ...filters, state: e.target.value || null})}
						/>
					</div>
				</div>
				<div className="header-customer-group__actions">
					<Button type="submit">Filtrar Centro de Distribuição</Button>
				</div>
			</form>
		</div>
	)
}

export default HeaderDistributionAddressSearch;
