import { EditSellerForm } from '../SellerEdit/components/EditForm/EditForm';
import { useBankTypeOptions } from './bankTypeOptionsHook';
import { useSellerTypeOptions } from './sellerTypeOptionsHook';
import { ContractualFormValueType, CreateSellerDataType } from '../SellerRegistration/types';
import { BFFSellerV2Input, BFFSellerV2UpdateInput } from '@services/bff/GraphqlTypes';

const FIRST_POS = 0;
const SECOND_POS = 1;
type AdditionalSellerDataT = {
  sellerId: number;
  addressId: number;
  userId: number;
};

export function useSellerPayload() {
  const { sellerTypeOptions } = useSellerTypeOptions();
  const { bankTypeOptions } = useBankTypeOptions();

  function getBankTypeByName(bankName: string) {
    const { id, code } = bankTypeOptions.find((obj: { value: string }) => obj.value.toString() === bankName);

    return {
      bankId: id,
      bankCode: code,
    };
  }

  function getSellerTybeBySegment(segment: string) {
    const sellerType = sellerTypeOptions?.find(
      (sellerType: { name: string; id: number }) => sellerType.name === segment
    );

    return {
      sellerSegmentName: sellerType.name,
      sellerSegmentId: sellerType.id,
    };
  }

  function getContractualData(contractualData: Omit<ContractualFormValueType, 'comercialEmail' | 'comercialName'>) {
    return {
      commission: parseFloat(contractualData.commission),
      minimumOrderPrice:
        typeof contractualData.minimumOrderPrice === 'string'
          ? parseFloat(contractualData.minimumOrderPrice?.replace('.', '').replace(',', '.').replace('R$ ', ''))
          : contractualData.minimumOrderPrice,
      handlingTime: parseInt(contractualData.handlingTime),
    };
  }

  function getOnlyPhoneNumber(number?: string) {
    return number?.split(' ')[SECOND_POS];
  }

  function getOnlyDddNumber(number?: string) {
    return number?.split(' ')[FIRST_POS]?.replace(/\D/g, '');
  }

  function getUserPhoneInfo(cellPhone?: string, mobilePhone?: string) {
    return {
      mobilePhone: getOnlyPhoneNumber(mobilePhone),
      dddMobilePhone: getOnlyDddNumber(mobilePhone),
      cellPhone: getOnlyPhoneNumber(cellPhone),
      dddCellPhone: getOnlyDddNumber(cellPhone),
    };
  }

  function getEditSellerPayload(
    sellerData: EditSellerForm,
    additionalSellerData: AdditionalSellerDataT
  ): BFFSellerV2UpdateInput {
    const { bankCode, bankId } = getBankTypeByName(sellerData.bankName);
    const { sellerSegmentId, sellerSegmentName } = getSellerTybeBySegment(sellerData.segment);
    const { commission, minimumOrderPrice, handlingTime } = sellerData;
    const { userId, addressId, sellerId } = additionalSellerData;

    return {
      sellerDTO: {
        address: {
          id: addressId,
          zipcode: sellerData.zipcode,
          address: sellerData.address,
          number: sellerData.number,
          complement: sellerData.complement,
          neighborhood: sellerData.neighborhood,
          city: sellerData.city,
          state: sellerData.state,
        },
        bankAccount: {
          bankId: bankId,
          accountType: sellerData.accountType,
          bankCode: bankCode,
          bankName: sellerData.bankName,
          agency: sellerData.agency,
          account: sellerData.account,
        },
        user: {
          id: userId,
          name: sellerData.comercialName,
          email: sellerData.comercialEmail,
          ...getUserPhoneInfo(sellerData.cellPhone, sellerData.mobilePhone),
          login: sellerData.email,
          ...(sellerData.password && { password: sellerData.password }),
        },
        type: {
          id: sellerSegmentId,
          name: sellerSegmentName,
        },
        cnae: sellerData.cnae,
        businessType: sellerData.businessType,
        email: sellerData.email,
        apiIntegration: sellerData.integrated === 'yes',
        stateRegistration: sellerData.stateRegistration,
        cityRegistration: sellerData.cityRegistration,
        companyName: sellerData.companyName,
        postalCode: sellerData.zipcode,
        document: sellerData.document,
        name: sellerData.sellerName,
        isTestMerchant: sellerData.isTestMerchant === 'yes',
        isNewIntegration: sellerData.isNewIntegration === 'yes',
        merchantId: sellerData?.merchantId,
        frnId: sellerData.frnId ? parseInt(sellerData.frnId) : undefined,
        id: sellerId,
        ...getContractualData({ commission, minimumOrderPrice, handlingTime }),
      },
    };
  }

  function getCreateSellerPayload(sellerData: CreateSellerDataType): BFFSellerV2Input {
    const { bankCode, bankId } = getBankTypeByName(sellerData.bankFormData.bankName);
    const { sellerSegmentId, sellerSegmentName } = getSellerTybeBySegment(sellerData.registrationFormData.segment);

    return {
      sellerDTO: {
        address: { ...sellerData.addressFormData },
        bankAccount: {
          ...sellerData.bankFormData,
          bankCode,
          bankId,
        },
        user: {
          name: sellerData.contractualFormData.comercialName,
          email: sellerData.contractualFormData.comercialEmail,
          ...getUserPhoneInfo(sellerData.registrationFormData.cellPhone, sellerData.registrationFormData.mobilePhone),
          login: sellerData.registrationFormData.email,
          password: sellerData.registrationFormData.password,
        },
        type: {
          id: sellerSegmentId,
          name: sellerSegmentName,
        },
        businessType: sellerData.registrationFormData.businessType,
        cnae: sellerData.registrationFormData.cnae,
        email: sellerData.registrationFormData.email,
        apiIntegration: sellerData.registrationFormData.integrated === 'yes',
        stateRegistration: sellerData.registrationFormData.stateRegistration,
        cityRegistration: sellerData.registrationFormData.cityRegistration,
        companyName: sellerData.registrationFormData.companyName,
        postalCode: sellerData.addressFormData.zipcode,
        document: sellerData.registrationFormData.document,
        name: sellerData.registrationFormData.sellerName,
        isTestMerchant: sellerData.registrationFormData.isTestMerchant === 'yes',
        isNewIntegration: sellerData.registrationFormData.isNewIntegration === 'yes',
        merchantId: sellerData.registrationFormData.merchantId,
        frnId: sellerData.registrationFormData?.frnId ? parseInt(sellerData.registrationFormData.frnId) : undefined,
        ...getContractualData(sellerData.contractualFormData),
      },
    };
  }

  return {
    getEditSellerPayload,
    getCreateSellerPayload,
  };
}
