import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';

import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as DataInfoIcon } from "@assets/icons/infoAccess.svg"

import './accessData.scss';

const AccessData = ({ role, setEdit, data }) => {
  const content = useRef();

  return (
    <div className="accessData">
      <h2 className="accessData__title">Dados de Acesso</h2>
      <div className="accessData__email">
        <span className="mainData__attribute">
          E-mail:
          <Tooltip id="accessInfoTooltip" title="E-mail para recebimento de cópias dos pedidos realizados. Para alterá-lo entre em contato com o time de suporte." placement="right">
             <span id="icon-access-info" className="link--disabled">
                <DataInfoIcon width={16} />
             </span>
          </Tooltip> 
        </span>
        <span className="mainData__value">{data?.email}</span>
      </div>
      <div className="accessData__password">
        <span className="mainData__attribute">Senha:</span>
        <span className="mainData__value">**********</span>
      </div>
      <div className="mainData__link">
        <NavLink
          ref={content}
          onClick={
            role ? () => setEdit({ edit: true, type: content.current?.id }) : ''
          }
          id="AccessData"
          to="#"
          className={role ? 'link' : 'link--disabled'}
        >
          Alterar senha
        </NavLink>
      </div>
    </div>
  );
};

export default AccessData;
