import React, { useEffect, useState } from "react";
import ptbr from 'date-fns/locale/pt-BR';
import DatePicker from "react-datepicker/es";
import InfiniteScroll from '@components/InfiniteScroll';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Modal } from 'ifoodshop-react-ui'
import { OutlinedInput } from "ifoodshop-react-ui";
import Roles from "@helpers/Roles";
import Decoder from "@helpers/Decoder";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { GET_OFFERS_BY_VARIATION } from "@graphql/offer.queries";
import { GET_VARIATIONS_BY_PRODUCT } from "@graphql/variation.queries";
import { CREATE_COMMISSION } from "@graphql/commission.mutations";
import TinyEditor from "@components/TextEditor";
import AutocompleteCategory from "@components/AutocompleteCategory";

const FormRegisterCommission = (props) => {

  let today = new Date()

  //AUTHORIZATION
  const tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  const roleProductWR = tokenDecoded.resource_access["manager"].roles.includes(Roles.PRODUCT_WR);
  const roleCategoryWR = tokenDecoded.resource_access["manager"].roles.includes(Roles.CATEGORY_WR);

  if (!roleProductWR && !roleCategoryWR) window.location.href = "/fornecedores"

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [percent, setPercent] = useState(null);
  const [modal, setModal] = useState(null);
  const [description, setDescription] = useState(null)
  const [inputVariationValue, setInputVariationValue] = useState("")
  const [createCommission] = useMutation(CREATE_COMMISSION, {
    onCompleted: data => {
      if(data?.createCommission?.error === "COMMISSION_CONFLICT"){
        changeModal("Conflito de comissões!", "Atenção")
      }else{
        window.location.href = "/fornecedores/" + props.sellerId + "/comissoes"
      }
    },
    onError: error => {
      changeModal("Erro ao criar a comissão!", "Atenção")
    }
  });

  const changeModal = (message, title) => {
    setModal(<Modal onCloseAction={ () => setModal(null) } title={ title }>
      <p>{ message }</p>
    </Modal>)
  }

  const percentageLimit = (value) => {
    if (value > 100) {
      setPercent(100)
    } else {
      setPercent(value)
    }
  }

  const checkDate = (date, name) => {
    let tempDate = date?.toISOString().split("T")[0]
    if (tempDate >= today.toISOString().split("T")[0] || tempDate == today.toISOString().split("T")[0]) {
      if (name === "initialDate") {
        finalDate ?
          finalDate >= date ? setInitialDate(new Date(date)) : changeModal("Data inicial deve ser menor ou igual que a data final", "Atenção")
          :
          setInitialDate(new Date(date))
      } else if (name === "finalDate") {
        date >= initialDate ? setFinalDate(new Date(date)) : changeModal("Data final deve ser maior ou igual que a data inicial", "Atenção")
      }
    } else {
      changeModal("A data deve ser igual ou posterior a data atual!", "Atenção")
    }
  }

  //PRODUCTS
  const [productName, setProductName] = useState(null)
  const [productId, setProductId] = useState(null)
  const [name, setName] = useState(null)

  const productSelected = (e) => {
    const { id, name } = e
    setName(name);
    setProductId(id);
    setOfferId(null)
    setVariationId(null)
    setVariationOptions([])
    setOfferOptions([])
    setInputVariationValue("")
  }

  //CATEGORIES
  const [categoryId, setCategoryId] = useState(null)

  //VARIATIONS
  const [variationOptions, setVariationOptions] = useState([])
  const [variationId, setVariationId] = useState(null)
  const [getVariationsByProduct, { data: dataGetVariations }] = useLazyQuery(GET_VARIATIONS_BY_PRODUCT, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setVariationOptions(dataGetVariations.getAllProductVariations.content)
    }
  })

  useEffect(() => {
    setOfferOptions(null)
    setVariationOptions([])
    if (productId !== null) {
      getVariationsByProduct({
        variables: {
          productId: parseInt(productId)
        }
      })
    }
  }, [productId])

  //OFFERS
  const [offerId, setOfferId] = useState(null);
  const [offerOptions, setOfferOptions] = useState("");
  const [getOffers, { data: dataGetOffers }] = useLazyQuery(GET_OFFERS_BY_VARIATION, {fetchPolicy: "no-cache"});

  useEffect(() => {
    setOfferOptions("")
    setOfferId(null)
    variationId &&
    getOffers({
        variables: {
          variation: parseInt(variationId)
        }
      }
    )
  }, [variationId])

  const changeProductName = (e) => {
    setProductName(e.target.value);
    setName(null);
    setVariationOptions([])
    setInputVariationValue("")
    setVariationId(null)
    setProductId(null)
  }

  useEffect(() => {
    setOfferId(null)
    if (dataGetOffers) {
      let control = 0
      if (dataGetOffers.findOffersByVariationId.length > 0) {
        dataGetOffers.findOffersByVariationId.forEach((item, index) => {
            if (item.sellerId === parseInt(props.sellerId)) {
              control = 1
              setOfferOptions(item.sellerCode)
              setOfferId(item.id)
            } else if ((index === dataGetOffers.findOffersByVariationId.length - 1) && control === 0) {
              changeModal("Não existe oferta cadastrada na variação selecionada", "Atenção")
            }
          }
        )
      } else {
        changeModal("Não existe oferta cadastrada na variação selecionada", "Atenção")
      }
    } else {
      setOfferOptions([])
    }
  }, [dataGetOffers])

  const registerCommission = (e) => {
    e.preventDefault();

    let validacao = 1;

    let sendItems = {
      sellerId: parseInt(props.sellerId),
      startDate: initialDate ? initialDate.toISOString().split("T")[0] : null,
      endDate: finalDate ? finalDate.toISOString().split("T")[0] : null,
      percentage: parseFloat(percent),
      active: true,
      description: description ? description : ""
    }

    if (props.typeCommission === "produtos") {
      sendItems = { ...sendItems, linkedId: parseInt(offerId), commissionType: "OFFER" }
    } else if (props.typeCommission === "categorias") {
      sendItems = { ...sendItems, linkedId: parseInt(categoryId), commissionType: "CATEGORY" }
    } else {
      sendItems = { ...sendItems, commissionType: "DEFAULT" }
    }

    if (validacao === 1) {
      Object.keys(sendItems).map(function (objectkey, index) {
        var size = Object.keys(sendItems).length - 1;
        var value = sendItems[objectkey]
        if (value === null || value === "" || Number.isNaN(value)) {
          changeModal("Todos os campos são obrigatórios", "Atenção")
          validacao = 0;
        }
        if (objectkey === "percentage" && value <= 0) {
          changeModal("A porcentagem deve ser maior que zero.", "Atenção")
          validacao = 0;
        }
        if (index === size && validacao === 1) {
          createCommission({ variables: sendItems })
        }
      })
    }
  }

  return (
    <>
      { (roleCategoryWR && roleProductWR) &&
      <>
        { modal }
        <form className="form-admin form-admin--commissions" id="frm-reg__commission"
              onSubmit={ e => registerCommission(e) }>
          {
            props.typeCommission === 'produtos' ?
              <>
                <div className="form-admin__group">
                  <div className="product-name">
                    <OutlinedInput
                      placeholder="Produto *"
                      value={ name }
                      border
                      onChange={ e => changeProductName(e)}
                    />
                  </div>
                  {
                    productName && productName.length > 2 ?
                      <InfiniteScroll
                        productName={ productName }
                        selected={ (e) => productSelected(e) }
                      />
                      : null
                  }
                </div>
                <div className="form-admin__group">
                  <div className="dropdown-label-wrap__category">
                    <Autocomplete
                      id="productOffer"
                      inputValue={ inputVariationValue }
                      onInputChange={ (event, newInputValue) => {
                        setInputVariationValue(newInputValue);
                      } }
                      freeSolo
                      className="dropdown-ifood"
                      options={ variationOptions.length > 0 ? variationOptions : [] }
                      getOptionLabel={ (option) => option?.name || "" }
                      renderInput={ (params) => <TextField { ...params } label="Variações" variant="outlined"/> }
                      onChange={ (e, b) => {
                        setVariationId(b ? b.id : null);
                      } }
                      onInput={ e => setVariationId(e.target.value) }
                    />
                  </div>
                </div>
                <div className="form-admin__group">
                  <div className="product-name">
                    <OutlinedInput
                      placeholder="Oferta - SKU"
                      value={ offerOptions }
                      border
                      disabled
                    />
                  </div>
                </div>
              </>
              :
              <>
                {
                  props.typeCommission === "categorias" ?
                    <div className="form-admin__group">
                      <AutocompleteCategory selected={ e => setCategoryId(e) }/>
                    </div>
                    : null
                }
              </>
          }
          <div className="form-admin__group">
            <OutlinedInput
              placeholder="Percentual (máx 100%)"
              type="number"
              step="0.01"
              min="0.01"
              max="100.00"
              border
              value={ percent }
              onChange={ e => percentageLimit(e.target.value) }
            />
          </div>
          <div className="form-admin__group">
            <div className={ `outlined-label-wrap outlined-date ${ initialDate ? 'active' : '' }` }>
              <DatePicker
                className="form-brand__input"
                onChange={ date => checkDate(date, "initialDate") }
                locale={ ptbr }
                selected={ initialDate }
                dateFormat="dd/MM/yyyy"
              />
              <label className="outlined-label">Data inicial</label>
            </div>
          </div>
          <div className="form-admin__group">
            <div className={ `outlined-label-wrap outlined-date ${ finalDate ? 'active' : '' }` }>
              <DatePicker
                className="form-brand__input"
                onChange={ date => checkDate(date, "finalDate") }
                locale={ ptbr }
                selected={ finalDate }
                dateFormat="dd/MM/yyyy"
              />
              <label className="outlined-label">Data final</label>
            </div>
          </div>
          <TinyEditor data={ e => setDescription(e) }/>
        </form>
      </>
      }
    </>
  )
}

export default FormRegisterCommission;
