import { UploadableFile } from '@ifood/pomodoro-components';
import { useCallback, useState } from 'react';

const FIRST_FILE = 0;

interface UseImageUploadI {
  onChange?: (file?: UploadableFile) => void;
  selectedImage?: UploadableFile;
}

export function useImageUpload({ onChange, selectedImage }: UseImageUploadI = {}) {
  const [image, setImage] = useState('');

  const onImageChange = useCallback(
    (accFiles: UploadableFile[]) => {
      const hasFiles = accFiles.length > FIRST_FILE;
      const isSameFile = hasFiles && selectedImage?.file?.path === accFiles[FIRST_FILE].file.path;

      if (!hasFiles || isSameFile) return;

      const reader = new FileReader();
      reader.onloadend = () => {
        const isSameImage = reader.result === image;
        const isStringImage = typeof reader.result === 'string';

        if (isStringImage && !isSameImage) {
          setImage(reader.result);
          onChange && onChange(accFiles[FIRST_FILE]);
        }
      };
      reader.readAsDataURL(accFiles[FIRST_FILE].file);
    },
    [image, onChange, selectedImage?.file?.path]
  );

  const onDeleteImage = () => {
    setImage('');
    onChange && onChange();
  };

  return {
    onImageChange,
    onDeleteImage,
    image,
  };
}
