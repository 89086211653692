import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_TOTAL_WEIGHT_ITEM } from '@graphql/order.mutations';
import { OutlinedInput, Button, Typography } from 'ifoodshop-react-ui';
import BounceLoader from '@components/Loader/Bounce';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrderDetail } from '@analytics/aboyeur/entities/sessionOrderDetail';

import { useOrderContext } from '@context/OrderContext';

import { RuptureError } from '@helpers/RuptureHelper';

import './modalVariableWeight.scss';

function ModalVariableWeight({ item, setConfirm, setSuccess, setErrorMessage }) {
  const { order } = useOrderContext();
  const [weightProduct, setWeightProduct] = useState('');
  const [stateWeightError, setStateWeightError] = useState(false);
  const [stateWeightDisabled, setStateWeightDisabled] = useState(true);
  const [stateWeightInformed, setStateWeightInformed] = useState('');
  const [stateWeightMessage, setStateWeightMessage] = useState('');
  const originalWeight = item.weight * item.quantity;
  const weightInformed = item.informedTotalWeight;
  const replaceComma = text => text.replace(',', '.');
  const replaceDot = text => text.replace('.', ',');
  //faster
  const sessionOrderDetail = useAboyeur(SessionOrderDetail);

  const [updateVariableWeight, { loading }] = useMutation(UPDATE_TOTAL_WEIGHT_ITEM, {
    onCompleted: data => {
      const {
        updateTotalWeightItem: { success, errorCode },
      } = data;

      setErrorMessage(RuptureError(errorCode));
      setSuccess(success);
      setConfirm(true);
    },
    onError: error => {
      console.error(`[UPDATE_TOTAL_WEIGHT_ITEM]: ${error}`);

      setSuccess(false);
      setConfirm(true);
    },
  });

  const setVariableWeight = (e, value) => {
    e.preventDefault();
    const changedWeight = parseFloat(replaceComma(weightProduct));
    updateVariableWeight({ variables: { itemId: item.id, variableWeightInformed: changedWeight } });
    setStateWeightDisabled(true);
    //faster - Session
    sessionOrderDetail.events.order_detail.clickWeightConfirm({
      //Propriedades > Metadata
      itemId: item.id,
      newItemWeight: changedWeight,
    });
  };

  const handleChangeValue = e => {
    const {
      target: { value },
    } = e;

    let valueFormat = replaceDot(
      String(
        value
          .replace(/(?!(\d|,|\.)).+/, '')
          .replace(/(\d)(\.|,)+/g, '$1,')
          .replace(/^(,|\.)+/, '')
      )
    );

    if (/(,|\.)\d/.test(valueFormat)) {
      const [valueInteger, valueDecimal] = String(valueFormat).split(',');
      valueFormat = `${valueInteger},${valueDecimal.substring(0, 3)}`;
    }

    const valueNumber = Number(replaceComma(valueFormat));
    const InformedNumber = Number(replaceComma(stateWeightInformed));
    const inputHasValue = Boolean(valueFormat.length);

    setWeightProduct(valueFormat);
    setStateWeightDisabled(!inputHasValue);

    if (inputHasValue) {
      if (valueNumber <= InformedNumber || valueNumber == originalWeight || valueNumber !== weightInformed) {
        setStateWeightMessage('Lembre-se ao alterar o peso do produto, o valor total do pedido será alterado.');
        setStateWeightError(false);
      }
      if (order?.paymentProcessed && valueNumber > weightInformed) {
        setStateWeightMessage(
          'Não é possível inserir um peso maior do que o atual para pedidos com pagamentos já processados.'
        );
        setStateWeightError(true);
      }
      if (valueNumber > originalWeight) {
        setStateWeightMessage('Não é possível inserir um peso maior do que o original.');
        setStateWeightError(true);
      }
      if (valueNumber === weightInformed) {
        setStateWeightMessage('Valor informado igual ao peso atual.');
        setStateWeightError(true);
      }
    } else {
      setStateWeightMessage('');
    }
  };

  const handleBlurValue = e => {
    const {
      target: { value },
    } = e;
    const valueReplace = value.replace(/(,|\.)$/, '');
    setWeightProduct(valueReplace);
  };

  const toFixedDecimal = (number, fixed = 3) => {
    const regex = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return replaceDot(number.toString().match(regex)[0]);
  };

  useEffect(() => {
    if (item.variableWeightInformed != null) {
      const valueInfomed = replaceDot(toFixedDecimal(weightInformed));
      setWeightProduct(valueInfomed);
      setStateWeightInformed(valueInfomed);
    }

    //faster - Session
    const weightInformedFixed = toFixedDecimal(weightInformed);
    sessionOrderDetail.events.order_detail.clickWeightEdit({
      childOrderId: item.deliveryId,
      itemId: item.id,
      itemWeight: weightInformedFixed,
    });
  }, [item.variableWeightInformed]);

  useEffect(() => {
    if (stateWeightError) {
      setStateWeightDisabled(true);
    }
  }, [stateWeightError]);

  return (
    <form onSubmit={e => setVariableWeight(e, weightProduct)} className="order-detail__modal">
      <Typography variant="h3" weight="medium" size="22px">
        {' '}
        Atualizar peso variável do item
      </Typography>
      <div className="order-detail__card-list">
        <Typography variant="p" className="order-detail__card-item">
          Insira o valor correto do peso do produto (considere o valor de todas as unidades juntas).
        </Typography>
        <Typography variant="p" className="order-detail__card-item--input">
          <OutlinedInput
            placeholder="Peso do produto (kg)"
            border
            error
            maxLength="50"
            id="weight"
            value={weightProduct}
            onChange={handleChangeValue}
            onBlur={handleBlurValue}
            className={`weight-form ${stateWeightError ? 'weight-form--error-input' : ''}`}
          />
        </Typography>
        <Typography className="order-detail__card-item--reduced-space" color="mediumgrey" size="12px">
          Peso original: {toFixedDecimal(originalWeight)} kg
        </Typography>
        <Typography
          className={`order-detail__card-item ${stateWeightError ? 'order-detail__card-item--error-input' : ''}`}
          size="12px"
          weight="thin"
        >
          {stateWeightMessage}
        </Typography>
        <Button type="submit" disabled={stateWeightDisabled || stateWeightError} className="order-detail__card-button">
          {loading ? <BounceLoader className="button-loader" /> : 'Alterar'}
        </Button>
      </div>
    </form>
  );
}
export default ModalVariableWeight;
