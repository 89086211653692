import React from 'react';
import CatalogItem from '../../../components/CatalogItem';
import Roles from '../../../helpers/Roles';

import ImageDiscount from '../../../assets/images/campaign-discount.svg';
import ImageVouchers from '../../../assets/images/campaign-vouchers.svg';
import ImageRegion from '../../../assets/images/campaign-region.svg';
import ImageCondition from '../../../assets/images/campaign-condition.svg';
import ImageFixePrice from '../../../assets/images/campaign-fixe-price.svg';
import ImageColection from '../../../assets/images/campaign-colection.svg';
import { getRoles } from "../../../helpers/rolesUtils";

const HomeCampaign = () => {
  const roles = getRoles();
  

  const items = [
    {
      title: 'Descontos',
      image: ImageDiscount,
      description: 'Cadastro e edição de descontos',
      link: 'campanhas/descontos',
      showSeller: (roles.includes(Roles.DISCOUNT_RO) || roles.includes(Roles.DISCOUNT_WR)),
    },
    {
      title: 'Vouchers',
      image: ImageVouchers,
      description: 'Cadastro e edição de vouchers',
      link: 'campanhas/vouchers',
      showSeller: (roles.includes(Roles.VOUCHER_RO) || roles.includes(Roles.VOUCHER_WR)),
    },
    {
      title: 'Regiões',
      image: ImageRegion,
      description: 'Cadastro e edição de regiões',
      link: 'campanhas/regioes',
      showSeller: (roles.includes(Roles.REGION_RO) || roles.includes(Roles.REGION_WR)),
    },
    {
      title: 'Condição Comercial',
      image: ImageCondition,
      description: 'Cadastro e edição de condição comercial',
      link: 'campanhas/condicao-comercial',
      showSeller: (roles.includes(Roles.COMMERCIAL_CONDITION_RO) || roles.includes(Roles.COMMERCIAL_CONDITION_WR)),
    },
    {
      title: 'Coleções',
      image: ImageColection,
      description: 'Cadastro e edição de coleções',
      link:'campanhas/colecoes',
      showSeller: (roles.includes(Roles.COLLECTION_RO) || roles.includes(Roles.COLLECTION_WR)),
    }

  ];
  return (
    <div className="content-body home">
      <div className="content-body__box">
        {items.map((item) => {
          const { image, title, description, link, showSeller } = item;
          if (showSeller) {
            return (
              <CatalogItem
                image={image}
                title={title}
                description={description}
                link={link}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default HomeCampaign;
