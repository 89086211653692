import { createContext, useContext } from 'react';

// Context
const CustomerListContext = createContext();

// Provider
export const CustomerListProvider = ({ children, ...props }) => {
  return <CustomerListContext.Provider value={{ ...props }}>{children}</CustomerListContext.Provider>;
};

// Context Hook
export const useCustomerListContext = () => {
  const context = useContext(CustomerListContext);
  return context;
};
