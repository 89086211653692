import { Text } from '@ifood/pomodoro-components';

import * as S from './style';

type Props = {
  image: string;
  title: string;
  link: string;
  description: string;
};

const CatalogItem = ({ image, title, link, description }: Props): JSX.Element => {
  return (
    <S.LinkWrapper to={link}>
      <S.CatalogItem>
        <S.ImageBox>
          <S.Image alt={`Imagem ${title}`} src={image} />
        </S.ImageBox>

        <S.TextBox>
          <S.Title forwardedAs="h3" variant="regular">
            {title}
          </S.Title>
          <Text color="grayDarker" variant="medium">
            {description}
          </Text>
        </S.TextBox>
      </S.CatalogItem>
    </S.LinkWrapper>
  );
};

export default CatalogItem;
