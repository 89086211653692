import { useState } from 'react';
import InputMask from 'react-input-mask';
import { Button } from 'ifoodshop-react-ui';
import { useMutation } from '@apollo/react-hooks';
import { ADD_CUSTOMERS } from '@graphql/customer.queries';
import { cpfValidator, cnpjValidator } from './validator';
import { notify } from '@helpers/notify';

import './addCustomer.scss';

const AddCustomer = props => {
  const [cnpj, setCnpj] = useState(true);
  const [document, setDocument] = useState();
  const [documentValid, setDocumentValid] = useState(false);
  const [customerNotFound, setCustomerNotFound] = useState(false);

  var idGroup = parseInt(props.idGroup);

  const [addCustomers] = useMutation(ADD_CUSTOMERS, {
    onCompleted: data => {
      if (data?.createCustomerGroupCustomer?.statusCode === 'NOT_FOUND') {
        return setCustomerNotFound(true);
      }
      if (data?.createCustomerGroupCustomer?.customers) {
        notify('Cliente incluído');
        return props.cancel();
      }
    },
  });

  function AddCustomerItem(e) {
    e.preventDefault();

    addCustomers({
      variables: { document: document, idGroupValue: idGroup },
    });
  }

  function validateDocument(e) {
    var document = e.target.value.replace(/[^\d]+/g, '');

    setCustomerNotFound(false);
    setDocument(document);
    setCnpj(!document?.length || document?.length > 11);

    if (document.length > 11) {
      setDocumentValid(cnpjValidator(document));
    } else {
      setDocumentValid(cpfValidator(document));
    }
  }

  const showError = () => {
    let errorMessage = '';

    if (document?.length === 11 && !documentValid) {
      errorMessage = '* CPF inválido';
    }
    if (document?.length === 14 && !documentValid) {
      errorMessage = '* CNPJ inválido';
    }
    if (customerNotFound) {
      errorMessage = 'Não encontramos esse número na base do iFood Shop. Verifique se está correto.';
    }

    if (errorMessage) {
      return (
        <>
          <InputMask
            mask={cnpj ? '99.999.999/9999-99' : '999.999.999-999'}
            maskChar={null}
            className="input--error outlined-label-field outlined-label-field--border"
            value={document}
            onChange={e => validateDocument(e)}
          />
          <span className="form-add-customer--label-error">{errorMessage}</span>
        </>
      );
    }

    return (
      <>
        <InputMask
          mask={cnpj ? '99.999.999/9999-99' : '999.999.999-999'}
          maskChar={null}
          className="input outlined-label-field outlined-label-field--border"
          value={document}
          onChange={e => validateDocument(e)}
        />
        <label className="ad">*Campo obrigatório</label>
      </>
    );
  };

  return (
    <form onSubmit={e => AddCustomerItem(e, document)} className="form-add-customer">
      <div className="outlined-label-wrap">
        <label>CPF/CNPJ*</label>
        {showError()}
      </div>
      <div className="form-add-customer__actions">
        <Button onClick={() => props.cancel()} className="form-add-customer__actions__cancel">
          Cancelar
        </Button>
        <Button disabled={documentValid ? 0 : 1} type="submit">
          Incluir
        </Button>
      </div>
    </form>
  );
};

export default AddCustomer;
