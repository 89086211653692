/* eslint-disable prettier/prettier */
import { SetStateAction, useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Table,
  Pagination,
  Input,
  Select,
  Button,
  Loading,
  snackbar,
  Text,
  DatePicker as PomodoroDatePicker,
} from '@ifood/pomodoro-components';
import { Controller, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';

import { GET_SHOPPING_LISTS } from '@pages/Catalogs/ShoppingListsSearch/operations';
import apolloClient from '@services/apolloClient';
import { getRoles } from '@helpers/rolesUtils';
import Roles from '@helpers/Roles';
import MainHeader from '@components/mainHeader';
import MainContent from '@components/mainContent';
import FileUpload from '@components/fileUpload';
import Link from '@components/link';
import {
  ShoppingList,
  Column,
  ISearchQueryState,
  IPaginationState,
  FormState,
  ChangePagination,
  ObjectStatus,
  ObjectValidity,
} from './index.d';
import { columns, validityOptions, statusOptions, headerMoreOptions } from './utils';
import TemplateShoppingListsCsv from '@assets/files/templateShoppingLists.csv';
import listEmptyImage from '@images/empty-shopping-list.svg';
import { api } from '@services/api';
import * as S from './style';
import { dateFormatUS } from '@helpers/FormatHelper';

const PAGINATION_INDEX_BASE = 1;
const MAX_FILE_TO_UPLOAD = 1;
const TIMEOUT_TIMER = 2000;
const URL_IMPORT = '/shopping-list/import';

const Lists = (props: { history: string[] }): JSX.Element => {
  const roles = getRoles();
  const downloadLink = useRef<HTMLAnchorElement>(null);
  const initialState = { page: 0, size: 10 };
  const roleShoppingListWrite = roles.includes(Roles.SHOPPING_LIST_WR);
  const roleShoppingListReadOnly = roles.includes(Roles.SHOPPING_LIST_RO);

  const [emptyResult, setEmptyResult] = useState<boolean>(false);
  const [selectedPageOption, setSelectedPageOption] = useState<string>('');
  const [importExportLoading, setImportExportLoading] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [searchQueryState, setSearchQueryState] = useState<ISearchQueryState>(initialState);
  const [paginationState, setPaginationState] = useState<IPaginationState>({
    number: 0,
    size: 0,
    totalElements: 0,
    // eslint-disable-next-line no-magic-numbers
    pageSizes: [10, 25, 50],
  });

  const { register, control, watch, handleSubmit } = useForm<FormState>({ mode: 'onBlur' });

  const startDateField = dateFormatUS(watch('startDate'));
  const endDateField = dateFormatUS(watch('endDate'));
  const validityField = watch('validity');

  const {
    data: { shoppingLists: { content: shoppingLists = [] } = {} } = {},
    loading: isFetching,
    error,
  } = useQuery(GET_SHOPPING_LISTS, {
    client: apolloClient,
    variables: {
      ...searchQueryState,
    },
    onCompleted: ({ shoppingLists }) => {
      const { error, number, size, totalElements, empty } = shoppingLists;

      setEmptyResult(empty);

      if (error) {
        snackbar({
          variant: 'error',
          message: 'Desculpe, tivemos um problema ao carregar as listas',
          autoClose: true,
          timeout: TIMEOUT_TIMER,
        });
        console.log(error);
      }

      setPaginationState(prevState => {
        return {
          ...prevState,
          number: number + PAGINATION_INDEX_BASE,
          size,
          totalElements,
        };
      });
    },
    onError: error => {
      console.log(error);
    },
  });

  const handleImport = (data: FormData) => {
    const okStatus = 200;
    setImportExportLoading(true);
    //eslint-disable-next-line prettier/prettier
    api
      .post(URL_IMPORT, data)
      .then(response => {
        if (response.status === okStatus) {
          return response.blob();
        }
        snackbar({
          variant: 'error',
          message: 'Houve um erro ao importar o arquivo',
          autoClose: true,
          timeout: TIMEOUT_TIMER,
        });
        console.log('erro');
      })
      .then(blob => {
        if (!blob) return;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'suggestedListsExport.csv';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })

      .catch(error => {
        snackbar({
          variant: 'error',
          message: 'Houve um erro ao importar o arquivo',
          autoClose: true,
          timeout: TIMEOUT_TIMER,
        });
        console.log(error);
      })
      .finally(() => {
        setUploadDialogOpen(false);
        setSelectedPageOption('');
        setImportExportLoading(false);
      });
  };

  const handleChangePageOptions = (value: SetStateAction<string>) => {
    setSelectedPageOption(value);
    switch (value) {
      case 'download':
        downloadLink?.current?.click();
        setTimeout(() => setSelectedPageOption(''), TIMEOUT_TIMER);
        break;
      case 'import':
        setUploadDialogOpen(true);
        break;
      default:
        return false;
    }
  };

  const handleChangePagination = ({ currentPage, pageSize }: ChangePagination) => {
    setSearchQueryState({
      ...searchQueryState,
      size: pageSize,
      page: pageSize === searchQueryState?.size ? currentPage - PAGINATION_INDEX_BASE : initialState?.page,
    });
  };

  const onSubmit = (formValues: FormState) => {
    setSearchQueryState(prevState => ({
      ...prevState,
      ...formValues,
      endDate: dateFormatUS(formValues?.endDate),
      startDate: dateFormatUS(formValues?.startDate),
      page: initialState.page,
      status: formValues?.status === ObjectStatus.ALL ? null : formValues.status,
      validity: formValues?.validity === ObjectValidity.ALL ? null : formValues.validity,
    }));
  };

  const tableActionColumnText = useMemo(
    () => (roleShoppingListWrite ? 'Editar' : 'Visualizar'),
    [roleShoppingListWrite]
  );

  const headerOptions = useMemo(() => {
    return headerMoreOptions.map(option => {
      if (option.type === 'command' && !roleShoppingListWrite) {
        return {
          ...option,
          disabled: true,
        };
      }
      return {
        ...option,
      };
    });
  }, [roleShoppingListWrite]);

  const showDateInterval = useMemo(() => validityField === ObjectValidity.ALL, [validityField]);

  const isLoading = useMemo(() => importExportLoading || isFetching, [importExportLoading, isFetching]);

  if (!roleShoppingListReadOnly && !roleShoppingListWrite) {
    props.history.push('');
  }

  return (
    <>
      <MainHeader
        actions={
          <S.Controllers>
            {roleShoppingListWrite ? (
              <Link text="Cadastrar nova lista" to="listas-sugeridas/cadastrar" variant="medium" />
            ) : (
              <Text color="grayDarker" title="Você não tem permissão para cadastrar listas">
                Cadastrar nova lista
              </Text>
            )}

            <Select
              items={headerOptions}
              name="color"
              placeholder="Ver mais ações"
              value={headerOptions.find(x => x.value === selectedPageOption)}
              width={240}
              onChange={({ target }) => handleChangePageOptions(target.value)}
            />
            <a ref={downloadLink} download hidden href={TemplateShoppingListsCsv} id="download" />
          </S.Controllers>
        }
        subTitle="Busque uma lista sugerida"
        title="Listas Sugeridas"
      />
      <MainContent>
        <S.Wrapper data-testid="lists-search-content">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <S.FormRow>
              <Input defaultValue="" label="Nome da lista" minWidth={536} {...register('name')} />

              <Controller
                control={control}
                defaultValue={ObjectStatus.ALL}
                name="status"
                render={({ field }) => (
                  <Select
                    {...field}
                    items={statusOptions}
                    label="Estado"
                    value={statusOptions.find(x => x.value === field.value)}
                  />
                )}
              />

              <Controller
                control={control}
                defaultValue={ObjectValidity.ALL}
                name="validity"
                render={({ field }) => (
                  <Select
                    {...field}
                    items={validityOptions}
                    label="Validade"
                    value={validityOptions.find(x => x.value === field.value)}
                  />
                )}
              />
            </S.FormRow>

            <S.FormRow>
              {showDateInterval && (
                <>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }, { onChange, value } = field) => (
                      <PomodoroDatePicker
                        disabledDays={endDateField ? [{ after: endDateField }] : []}
                        format="dd/MM/yyyy"
                        label="Data inicial"
                        value={value?.toString()}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }, { onChange, value } = field) => (
                      <PomodoroDatePicker
                        disabledDays={startDateField ? [{ before: startDateField }] : []}
                        format="dd/MM/yyyy"
                        label="Data final"
                        value={value?.toString()}
                        onChange={onChange}
                      />
                    )}
                  />
                </>
              )}
              <Button type="submit">Filtrar Listas</Button>
            </S.FormRow>
          </form>
        </S.Wrapper>

        {(isFetching || isLoading) && <Loading color="primary" variant="large" />}

        {emptyResult && (
          <S.ResponseFallback
            description="Não encontramos nenhuma lista com os critérios especificados"
            imgAlt="Listas não encontradas"
            imgSrc={listEmptyImage}
            title="Nenhuma lista encontrada"
          />
        )}

        {error && (
          <S.ResponseFallback
            description="Desculpe mas não conseguimos carregar as listas"
            imgAlt="Listas não encontradas"
            imgSrc={listEmptyImage}
            title="Ops, Tivemos um problema"
          />
        )}

        <S.Wrapper className="table-container">
          {!isFetching && !error && (
            <Table>
              <Table.Head>
                <Table.Row>
                  {columns.map(column => (
                    <Table.Header key={column.id} style={{ textAlign: 'left' }}>
                      {column.label}
                    </Table.Header>
                  ))}
                  <Table.Header></Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {shoppingLists.map((item: ShoppingList) => (
                  <Table.Row key={item.id}>
                    {columns.map((column: Column) => (
                      <Table.Data key={column.id} style={{ textAlign: 'left' }}>
                        <>{column.formatter(item)}</>
                      </Table.Data>
                    ))}
                    <Table.Data>
                      <Link text={tableActionColumnText} to={`listas-sugeridas/editar/${item.id}`} />
                    </Table.Data>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </S.Wrapper>

        {paginationState && !error && !emptyResult && (
          <S.Wrapper>
            <Pagination.Numbered
              showArrowLabel
              current={paginationState.number}
              pageSize={paginationState.size}
              pageSizes={paginationState.pageSizes}
              position="right"
              totalItems={paginationState.totalElements}
              onChange={handleChangePagination}
            />
          </S.Wrapper>
        )}

        <FileUpload
          isOpen={uploadDialogOpen}
          loading={importExportLoading}
          title="Importação planilha de listas sugeridas"
          uploadFileProps={{
            accept: '.csv',
            maxFiles: MAX_FILE_TO_UPLOAD,
            multiple: false,
          }}
          onClose={() => {
            if (!importExportLoading) {
              setSelectedPageOption('');
              setUploadDialogOpen(false);
            }
          }}
          onSubmit={handleImport}
        />
        <DevTool control={control} />
      </MainContent>
    </>
  );
};

export default Lists;
