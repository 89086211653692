import { useMemo } from 'react';
import { Status as CustomStatus } from './style';
import { ReactComponent as OpenedEye } from '@assets/icons/opened_eye.svg';
import { ReactComponent as ClosedEye } from '@assets/icons/closed_eye.svg';

interface StatusProps {
  active?: boolean;
}

export function Status({ active }: StatusProps) {
  const StatusTitle = useMemo(() => {
    return `Campanha ${active ? 'publicada' : 'desativada'}`;
  }, [active]);

  const Icon = useMemo(() => {
    if (active) return <OpenedEye data-testid={'opened-eye'} />;

    return <ClosedEye data-testid={'closed-eye'} />;
  }, [active]);

  return (
    <CustomStatus active={active}>
      {Icon}
      <p>{StatusTitle}</p>
    </CustomStatus>
  );
}
