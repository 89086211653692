import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_CATEGORIES_BY_NAME, GET_CATEGORIES_TREE } from '../../../../graphql/category.queries';
import { CREATE_CATEGORY } from '../../../../graphql/category.mutation';
import Dropdown from 'react-dropdown';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';

import 'react-dropdown/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';
import './formRegister.scss';

const FormRegisterCategory = () => {
  const [options, setOptions] = useState(null);
  const optionsState = ['Habilitado', 'Desabilitado'];
  const [modal, setModal] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [state, setState] = useState(optionsState[0]);
  const [parentCategoryName, setParentCategoryName] = useState(null);
  const [getCategories, { data: dataCategories }] = useLazyQuery(GET_CATEGORIES_BY_NAME);
  const [getCategoryTree, { data: dataCategoryTree }] = useLazyQuery(GET_CATEGORIES_TREE);
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [createCategory, { data: dataCreate }] = useMutation(CREATE_CATEGORY, {
    onCompleted: data => {
      if (data.createCategory.error === 'CATEGORY_NAME_NOT_AVAILABLE') {
        changeModal('Categoria já cadastrada com o mesmo nome!', 'Atenção');
      } else {
        window.location.href = '/catalogos/categorias';
      }
    },
    onError: error => {
      changeModal('Erro ao atualizar a categoria.', 'Atenção');
    },
  });

  const changeModal = (message, title) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  useEffect(() => {
    setOptions([]);
    getCategoryTree({
      variables: {
        name: parentCategoryName || '',
      },
    });
  }, [parentCategoryName]);

  const mountOptions = (category, parentName = null) => {
    setOptions(options => [
      ...options,
      {
        id: category.id,
        name: parentName ? parentName + ' > ' + category.name : category.name,
      },
    ]);
    if (category.subCategories) {
      category.subCategories.map(subCategory => {
        mountOptions(subCategory, parentName ? parentName + ' > ' + category.name : category.name);
      });
    }
  };

  useEffect(() => {
    setOptions([]);
    if (dataCategoryTree) {
      dataCategoryTree.getCategoriesTree.map(category => {
        mountOptions(category);
      });
    }
  }, [dataCategoryTree]);

  useEffect(() => {
    getCategories({ variables: { name: '' } });
  }, []);
  if (dataCategories && options == null) {
    setOptions(dataCategories.getCategoriesByName);
  }

  const onChangeCategoryName = e => {
    setParentCategoryName(e.target.innerText);

    options.map(category => {
      if (category.name == e.target.innerText) {
        setParentCategoryId(category.id);
      }
    });
  };

  const registerCategory = e => {
    e.preventDefault();

    createCategory({
      variables: {
        name: categoryName,
        parent: parentCategoryId,
        active: state === 'Habilitado',
      },
    });
  };

  return (
    <div>
      {modal}
      <form className="form-register form-register--category" onSubmit={registerCategory}>
        <OutlinedInput
          placeholder="Nome da categoria"
          border
          value={categoryName}
          onChange={e => setCategoryName(e.target.value)}
        />

        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsState}
            onChange={e => setState(e.value)}
            placeholder=""
            value={optionsState[0]}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Estado</label>
        </div>

        <div className="dropdown-label-wrap__category">
          <Autocomplete
            id="parentCategory"
            className="dropdown-ifood"
            options={options || []}
            getOptionLabel={option => option.name || ''}
            renderInput={params => <TextField {...params} label="Categoria superior" variant="outlined" />}
            onChange={e => onChangeCategoryName(e)}
            onInputChange={e => setParentCategoryName(e ? e.target.value : '')}
          />
        </div>

        <Button className="btn-save" type="submit">
          Salvar categoria
        </Button>
      </form>
    </div>
  );
};

export default FormRegisterCategory;
