import React, { useState, useEffect } from 'react';
import { GET_ALL_REGIONS } from '../../../../../../graphql/region.queries';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Dropdown from 'react-dropdown';
import { Button, Modal } from 'ifoodshop-react-ui';
import { textAutoComplete } from './transformData';
import TableRegisterEditCommercialCondition from './TableRegisterEditCommercialCondition';
import { useLazyQuery } from '@apollo/react-hooks';

const RulesAdd = ({ ruleList, onChangeList }) => {
  const [modal, setModal] = useState();
  const [list, setList] = useState(ruleList || []);
  const [ruleSelected, setRuleSelected] = useState({
    ruleFilter: 'INCLUDE',
    ruleType: null,
    region: null,
  });

  useEffect(() => {
    setList(ruleList || []);
  }, [ruleList]);

  const [getAllRegions, { data }] = useLazyQuery(GET_ALL_REGIONS);

  const optionsRules = [
    { value: '', label: 'Selecione' },
    { value: 'REGION', label: 'Região' }
  ];

  const handleChange = (e) => {
    const { value } = e.target;
    getAllRegions({
      variables: {
        valueSrcInput: value,
      },
    });
  };

  const handleSelect = (value) => {
    let newValue = null;

    if (value) {
      const separator = value.split(' - ');
      const id = separator.length ? separator[0] : null;
      const name = separator.length > 1 ? separator[1] : null;
      newValue = data?.getAllRegions?.content?.length ?
        data.getAllRegions.content.find( (opt) => opt.id === id || opt.name === name )
        : null;
     }
    setRuleSelected({
      ...ruleSelected,
      region: newValue,
    });
  };

  const addRule = (e) => {
    e.preventDefault();

    if (
      !ruleSelected?.ruleFilter ||
      !ruleSelected?.region ||
      !ruleSelected?.ruleType
    ) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Preencha todos os campos.
        </Modal>,
      );
    }
    const existRule = list.findIndex(
      (i) =>
        i.ruleFilter == ruleSelected.ruleFilter &&
        i.ruleType == ruleSelected.ruleType &&
        i.region?.name == ruleSelected.region?.name &&
        i.region?.id == ruleSelected.region?.id,
    );
    if (existRule !== -1) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Já existe uma regra igual cadastrada.
        </Modal>,
      );
    }
    const conflictRule = list.findIndex(
      (i) =>
        i.ruleFilter !== ruleSelected.ruleFilter &&
        i.ruleType == ruleSelected.ruleType &&
        i.region?.name == ruleSelected.region?.name &&
        i.region?.id == ruleSelected.region?.id,
    );
    if (conflictRule !== -1) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Essa regra contradiz uma regra já adicionada.
        </Modal>,
      );
    }
    setList([...list, ruleSelected]);
    onChangeList([...list, ruleSelected]);
  };

  const handleDelete = (item) => {
    const index = list.findIndex((i) => i == item);
    if (index !== -1) {
      const newData = [...list];
      newData.splice(index, 1);
      setList(newData);
      onChangeList(newData);
    }
  };

  return (
    <div>
      <h2 className="section-title">Regras</h2>
      <p className="header-customer-group__description form-register__description">
        Adicione as regras das condições comerciais.
      </p>

      <div className="form-register__flex">
        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsRules}
            onChange={(e) =>
              setRuleSelected({ ...ruleSelected, ruleType: e.value })
            }
            value={
              optionsRules.find((o) => o.value === ruleSelected.ruleType) ||
              optionsRules[0]
            }
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Tipo de regra</label>
        </div>
        <div className="dropdown-label-wrap__category">
          <Autocomplete
            id="productCategory"
            freeSolo
            disabled={!ruleSelected?.ruleType}
            className="dropdown-ifood form-register__flex--autocomplete"
            onChange={(event, newValue) => handleSelect(newValue || null)}
            options={
              data?.getAllRegions?.content?.length
                ? data.getAllRegions.content.map(
                    (o) => `${o.id} - ${o.name || 'Região sem nome'}`,
                  )
                : []
            }
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={textAutoComplete(ruleSelected.ruleType)}
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
            )}
          />
        </div>
        <div className="header-customer-group__form form-register__toggle-button">
          <ToggleButtonGroup
            className="togglebutton-ifood"
            value={ruleSelected?.ruleFilter || 'INCLUDE'}
            onChange={(e, value) =>
              setRuleSelected({ ...ruleSelected, ruleFilter: value })
            }
            exclusive
            aria-label="tipo de regras"
          >
            <ToggleButton
              className="togglebutton-ifood__item"
              value="INCLUDE"
              aria-label="Inclusão"
            >
              Inclusão
            </ToggleButton>
            <ToggleButton
              className="togglebutton-ifood__item"
              value="EXCLUDE"
              aria-label="Exclusão"
            >
              Exclusão
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Button
          onClick={(e) => addRule(e)}
          className="btn btn-secondary form-register__button"
        >
          Adicionar
        </Button>
      </div>
      {list.length > 0 && (
        <TableRegisterEditCommercialCondition
          deleteCommercialCondition={handleDelete}
          data={list}
        />
      )}
      {modal}
    </div>
  );
};

export default RulesAdd;
