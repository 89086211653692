import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_OFFER } from '../../../../graphql/offer.mutations';
import Dropdown from 'react-dropdown';
import { Button, Modal } from 'ifoodshop-react-ui';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';

import { convertPriceStringToFloat, specialPriceErrorMessage } from '@helpers/SpecialPriceUtils';

import { useOfferContext } from '@components/_context/OfferContext';
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';
import BounceLoader from '@components/Loader/Bounce';
import CardGrey from '@components/CardGrey';

import { ReactComponent as RectangleGray } from '../../../../assets/icons/rectangle-gray.svg';
import './formRegisterSpecialPrice.scss';

registerLocale('ptBR', ptBR);

const FormRegisterSpecialPrice = ({ edit }) => {
  const [modal, setModal] = useState(null);
  const optionCommercialCondition = ['Nenhuma', 'MEI'];
  const optionsState = ['Habilitado', 'Desabilitado'];
  const [commercialCondition, setCommercialCondition] = useState('Nenhuma');
  const [statePrice, setStatePrice] = useState('Habilitado');

  const history = useHistory();
  const {
    offer,
    productId,
    variationId,
    refetchSpecialPrices,
    setSpecialPriceList,
    price,
    setPrice,
    variation,
    variationImage,
    setDrawerOpen,
  } = useOfferContext();

  useEffect(() => {
    setCommercialCondition(price?.commercialConditionId === 1 ? 'MEI' : 'Nenhuma');
    setStatePrice(
      price?.active === true || price?.active === undefined || price?.active === null ? 'Habilitado' : 'Desabilitado'
    );
  }, [price]);

  const [createSpecialPrice, { loading }] = useMutation(UPDATE_OFFER, {
    onCompleted: data => {
      if (data.updateOffer.errorCode) {
        changeModal(
          'Atenção',
          specialPriceErrorMessage(data.updateOffer.errorCode) || 'Erro ao cadastrar preço especial.'
        );
      } else {
        refetchSpecialPrices().then(res => setSpecialPriceList(res.data.getOfferPricesByOfferId));
        setDrawerOpen(false);
        history.push('/catalogos/produtos/' + productId + '/variacoes/' + variationId + '/ofertas/editar/' + offer.id);
      }
    },

    onError: error => {
      changeModal('Atenção', 'Erro ao cadastrar o preço especial');
    },
  });

  function validatePrice() {
    if (!price.price) {
      changeModal('Atenção', 'O preço não pode ser vazio ou igual a zero.');
      return;
    }

    let floatPrice = typeof price.price === 'string' ? convertPriceStringToFloat(price?.price) : price.price;

    if (floatPrice <= 0.99) {
      changeModal('Atenção', 'O preço da condição especial deve ser maior que R$ 1,00.');
      return;
    }

    return floatPrice > 0.99 ? floatPrice : null;
  }
  const registerSpecialPrice = e => {
    e.preventDefault();
    let validacao = 1;
    let floatPrice = validatePrice();

    if (!price.startDate || !price.endDate) {
      changeModal('Atenção', 'Selecione uma data de vigência.');
      validacao = 0;
      return;
    }

    if (price.startDate && !price.endDate) {
      changeModal('Atenção', 'Informe uma data final para o preço especial.');
      validacao = 0;
      return;
    }

    if (Date.parse(price?.startDate) >= Date.parse(price?.endDate)) {
      changeModal('Atenção', 'A data inicial não pode ser maior que a data final.');
      validacao = 0;
      return;
    }

    if (validacao === 1 && floatPrice) {
      let sendItems = {
        ...offer,
        variation: parseInt(variationId),
        seller: offer.sellerId,
        offerPrices: [
          {
            id: parseInt(price.id),
            price: floatPrice,
            commercialConditionId: price.commercialConditionId,
            startDate: !price?.id
              ? new Date(price.startDate.getTime() - price.startDate.getTimezoneOffset() * 60000)
              : price.startDate,
            endDate: !price?.id
              ? new Date(price.endDate.getTime() - price.endDate.getTimezoneOffset() * 60000)
              : price.endDate,
            active: price?.active === true || price?.active === undefined || price?.active === null ? true : false,
          },
        ],
      };
      createSpecialPrice({ variables: sendItems });
    } else {
      changeModal('Atenção', 'Ocorreu um erro ao cadastrar o preço.');
    }
  };

  function changeModal(title, message) {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  }

  return (
    <form className="form-register" onSubmit={registerSpecialPrice}>
      <div className="form-register__special-price">
        <div className="progressive-price">
          <div className="price-line">
            <OutlinedInputCurrency
              value={price?.price || ''}
              placeholder="Preço (R$)"
              onChange={e =>
                setPrice(prevState => ({
                  ...prevState,
                  price: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <CardGrey className="card-container">
          <div>
            <h2 className="price-title">Preço exibido atualmente no site</h2>
            <div className="body-container">
              {variationImage[0]?.imagePath ? (
                <img className="image-container" src={variationImage[0].imagePath} />
              ) : (
                <RectangleGray />
              )}

              <div className="body-text-container">
                <text>{variation.name}</text>
                <text>
                  {variation.quantity} {variation.quantity > 1 ? 'unidades' : 'unidade'}
                </text>
              </div>
            </div>
          </div>

          <div className="price-container">
            <h2>R$ {offer.price}</h2>
            <h2>R$ {Math.round((offer.price / variation.quantity + Number.EPSILON) * 100) / 100} /un</h2>
          </div>
        </CardGrey>
        <div className="condition-container">
          <div className="subtitle-container second">
            <text className="subtitle">Está atrelado a alguma condição comercial?</text>
          </div>
          <div className="form-register dropdown-label-wrap">
            <Dropdown
              options={optionCommercialCondition}
              onChange={e =>
                setPrice(prevState => ({
                  ...prevState,
                  commercialConditionId: e.value === 'MEI' ? 1 : null,
                }))
              }
              placeholder="Selecione"
              value={commercialCondition}
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Condição Comercial</label>
          </div>
          <div className="form-register dropdown-label-wrap second-dropdown">
            <Dropdown
              options={optionsState}
              onChange={e =>
                setPrice(prevState => ({
                  ...prevState,
                  active: e.value === 'Habilitado' ? true : false,
                }))
              }
              placeholder="Selecione"
              value={statePrice}
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Estado</label>
          </div>
        </div>

        <div className="date-container">
          <div className="subtitle-container second">
            <text className="subtitle">Data de validade do preço</text>
          </div>
          <div className="input-line">
            <div className={`outlined-label-wrap outlined-date ${price?.startDate ? 'active' : ''}`}>
              <DatePicker
                className="form-brand__input"
                onChange={date =>
                  setPrice(price => ({
                    ...price,
                    startDate: date,
                  }))
                }
                locale="ptBR"
                showTimeSelect
                timeIntervals={15}
                selected={price?.startDate ? new Date(price?.startDate) : ''}
                dateFormat="dd/MM/yyyy HH:mm"
              />
              <label className="outlined-label">Data Inicial</label>
            </div>
            <div className={`outlined-label-wrap outlined-date ${price?.endDate ? 'active' : ''}`}>
              <DatePicker
                className="form-brand__input"
                onChange={date =>
                  setPrice(price => ({
                    ...price,
                    endDate: date,
                  }))
                }
                popperPlacement="top-end"
                locale="ptBR"
                selected={price?.endDate ? new Date(price?.endDate) : ''}
                showTimeSelect
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
              />
              <label className="outlined-label">Data Final</label>
            </div>
          </div>
        </div>
      </div>
      <div className="special-price special-price__submit button-container">
        <Button className="btn-save" type="submit">
          {loading && <BounceLoader />}
          {`${!price?.id ? 'Criar' : 'Editar'} preço`}
        </Button>
      </div>

      {modal}
    </form>
  );
};

export default FormRegisterSpecialPrice;
