import { Card, Flex, Heading3, Text } from '@ifood/pomodoro-components';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSellerDetailsData } from '../../SellerDetailsHook';

const EnrollmentDataDetails = (): JSX.Element => {
  const { sellerResponse } = useSellerDetailsData();
  const theme = useContext(ThemeContext);

  return (
    <Card>
      <Flex flexDirection="column">
        <Heading3 mb={24} mt={0} variant="smallest">
          Inscrições
        </Heading3>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Inscrição estadual
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.stateRegistration}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Inscrição municipal
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.cityRegistration}
        </Text>

        <Heading3 mb={24} mt={0} variant="smallest">
          Contato comercial
        </Heading3>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Nome do responsável
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.user?.name}
        </Text>
        <Text color={theme.colors.grayDarkest} data-testid="comercial-email-label" variant="regular">
          Email
        </Text>
        <Text data-testid="comercial-email" mb={24} variant="large">
          {sellerResponse?.getSellerV2?.user?.email}
        </Text>
      </Flex>
    </Card>
  );
};

export default EnrollmentDataDetails;
