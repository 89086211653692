import React from "react";
import {Button} from "ifoodshop-react-ui";
import FormRegisterCommission from "./components/FormRegisterCommission";
import {NavLink} from "react-router-dom";
import './commissionregister.scss';

const CommissionRegister = (props)=>{

  const typeCommission = props.match.params.tipo

  return(
    <>
      <NavLink className="go-back" to={`/fornecedores/${props.match.params.id}/comissoes`} />
      <div className="content-body" >
          <h2 className="section-title">Cadastro de comissões</h2>
          <p className="header-customer-group__description">Use os campos abaixo para cadastrar uma comissão</p>

        <FormRegisterCommission typeCommission={typeCommission} sellerId={props.match.params.id}/>

        <Button className="btn" form="frm-reg__commission">Salvar comissão</Button>
      </div>
    </>
  )
}

export default CommissionRegister;