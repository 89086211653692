import { useState, useEffect } from 'react';
import { OutlinedInput } from 'ifoodshop-react-ui';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TinyEditor from '@components/TextEditor';
import Header from '../HeaderRegisterProduct';

import './productCustomFields.scss';

let fieldsValues = {};
const ProductCustomFields = props => {
  useEffect(() => {
    fieldsValues = {};
  }, []);

  if (props.fielddata && props.fielddata.getAllSpecificationByProduct) {
    Object.keys(props.fielddata.getAllSpecificationByProduct).map(function (objectKey, index) {
      if (!fieldsValues[props.fielddata.getAllSpecificationByProduct[index].field.id]) {
        fieldsValues[props.fielddata.getAllSpecificationByProduct[index].field.id] = {
          id: props.fielddata.getAllSpecificationByProduct[index].field.id,
          value: props.fielddata.getAllSpecificationByProduct[index].value,
        };
      }
    });
  }

  if (props.fielddata && props.fielddata.getAllSpecificationByVariation) {
    Object.keys(props.fielddata.getAllSpecificationByVariation).map(function (objectKey, index) {
      if (!fieldsValues[props.fielddata.getAllSpecificationByVariation[index].field.id]) {
        fieldsValues[props.fielddata.getAllSpecificationByVariation[index].field.id] = {
          id: props.fielddata.getAllSpecificationByVariation[index].field.id,
          value: props.fielddata.getAllSpecificationByVariation[index].value,
        };
      }
    });
  }

  const readonly = props.readonly;
  const [render, setRender] = useState(Math.random().toString());

  const onChangeCustomField = (e, fieldId, type) => {
    props.fieldKeys.map(key => {
      props.fields[key].map(field => {
        if (fieldId == field.id) {
          if (type?.toUpperCase() === 'MULTIVALUE') {
            fieldsValues[fieldId] = { id: fieldId, value: e.join(',') };
          } else {
            fieldsValues[fieldId] = { id: fieldId, value: e };
          }

          setRender(Math.random().toString());
        }
      });
    });
  };

  useEffect(() => {
    props.data(fieldsValues);
  }, [render]);

  const handleMultiValue = (field, value) => {
    let list = field.fieldListValue.split(',');
    let tempFields = [];

    value.forEach(v => {
      tempFields = [...tempFields, list.find(f => f === v)];
    });
    onChangeCustomField(tempFields, field.id, field.fieldType.toUpperCase());
  };

  const renderByFormat = field => {
    if (field.fieldType === 'TEXTFIELD') {
      return (
        <div key={'"field' + field.id + '"'} className="list-fields">
          <div className="custom-field">
            <OutlinedInput
              id={'field' + field.id}
              placeholder={`${field.name}${field.required ? '*' : ''}`}
              value={fieldsValues[field.id] ? fieldsValues[field.id].value : field.value ? field.value : ''}
              border
              onChange={e => onChangeCustomField(e.target.value, field.id)}
              disabled={!!readonly}
            />
          </div>
        </div>
      );
    } else if (field.fieldType === 'LIST') {
      return (
        <div key={'"field' + field.id + '"'} className="list-fields">
          <Autocomplete
            id={'field' + field.id}
            freeSolo
            className="dropdown-ifood"
            options={field.fieldListValue ? field.fieldListValue.split(',') : []}
            value={fieldsValues[field.id] ? fieldsValues[field.id].value : null}
            getOptionLabel={option => option}
            renderInput={params => (
              <TextField {...params} label={`${field.name}${field.required ? '*' : ''}`} variant="outlined" />
            )}
            onChange={e => onChangeCustomField(e.target.innerText, field.id)}
            disabled={!!readonly}
          />
        </div>
      );
    } else if (field.fieldType === 'TEXTAREA') {
      return (
        <div key={'"field' + field.id + '"'} className="list-fields">
          <div>
            <label>{field.name}</label>
          </div>
          <TinyEditor
            disabled={readonly}
            data={e => onChangeCustomField(e, field.id)}
            initialValue={fieldsValues[field.id] ? fieldsValues[field.id].value : null}
          />
        </div>
      );
    } else if (field.fieldType === 'MULTIVALUE') {
      return (
        <div className="product-specifications__autocomplete">
          <Autocomplete
            id={'field' + field?.id}
            multiple
            filterSelectedOptions
            className="dropdown-ifood"
            options={field?.fieldListValue ? field.fieldListValue.split(',') : []}
            value={
              fieldsValues[field.id] && fieldsValues[field.id]?.value ? fieldsValues[field.id].value.split(',') : []
            }
            getOptionLabel={option => option}
            renderInput={params => (
              <TextField {...params} label={`${field.name}${field.required ? '*' : ''}`} variant="outlined" />
            )}
            disabled={!!readonly}
            onChange={(e, newValue) => handleMultiValue(field, newValue)}
          />
        </div>
      );
    }
  };

  if (props.fieldKeys && props.fields) {
    return (
      <>
        {props.header !== 'false' && (
          <div>
            <Header description="Especificações"></Header>
          </div>
        )}
        <div className="product-specifications">
          {render ? props.fieldKeys.map(key => props.fields[key].map(field => renderByFormat(field))) : null}
        </div>
      </>
    );
  } else return null;
};

export default ProductCustomFields;
export { ProductCustomFields };
