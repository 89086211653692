import React, { useState } from 'react';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import './modal-save.scss';

const ModalSaveEdit = ({ handleConfirm, sendItems }) => {
  const [password, setPassword] = useState('');

  return (
    <div className="modal-save">
      <p className="modal-save__title">Salvar edição</p>
      <span className="modal-save__description">
        Para salvar, é necessário inserir a senha
      </span>
      <div className="modal-save__input">
        <OutlinedInput
          className="outlined-label-wrap"
          placeholder="Senha"
          border
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button
        className="btn"
        type="submit"
        onClick={() => handleConfirm(sendItems, password)}
      >
        Salvar
      </Button>
    </div>
  );
};

export default ModalSaveEdit;
