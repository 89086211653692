import { Typography, Grid, Link } from 'ifoodshop-react-ui';

import './modalUpdateImportNotice.scss';

const ModalUpdateImportNotice = ({ onImport, onExport }) => {
  return (
    <div className="modal-update-import-notice">
      <Grid wrap>
        <Grid.Item xs={12}>
          <div className="modal-update-import-notice__subtitle">
            <Typography size="16px" weight="regular" line="16px" color="black">
              Antes de importar um lote de atualização de status, você precisa exportar os pedidos.
            </Typography>
          </div>
        </Grid.Item>
        <Grid.Item xs={12}>
          <div className="modal-update-import-notice__subtitle">
            <Typography size="14px" weight="thin" line="14px" color="heavygrey">
              Se você ainda não fez isso, clique aqui para&nbsp;
              <Link className="modal-update-import-notice__link" onClick={onExport}>
                exportar os pedidos.
              </Link>
            </Typography>
          </div>
        </Grid.Item>
      </Grid>
      <Grid wrap>
        <Grid.Item xs={12}>
          <div className="modal-update-import-notice__button-import">
            <label className="button" for="importUpdateOffers">
              Importar
            </label>
            <input
              type="file"
              id="importUpdateOffers"
              className="header-customer-group__actions-import"
              onChange={e => onImport(e)}
            />
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default ModalUpdateImportNotice;
