import React, { useState } from 'react';
import FormRegisterVoucher from '../components/FormRegisterVoucher';
import { NavLink } from 'react-router-dom';

import Roles from '../../../../helpers/Roles';
import { getRoles } from '../../../../helpers/rolesUtils';

const VoucherEdit = props => {
  const idVoucher = parseInt(props.match.params.discountId);

  const roles = getRoles();
  const roleVoucher = roles.includes(Roles.VOUCHER_WR);
  const roleVoucherReadOnly = roles.includes(Roles.VOUCHER_RO);

  const [modal, setModal] = useState(null);

  if (!roleVoucherReadOnly && !roleVoucher) {
    window.location.href = '/';
  }

  return (
    <>
      {(roleVoucherReadOnly || roleVoucher) && (
        <>
          <NavLink to="/campanhas/vouchers" className="go-back" />
          {roleVoucher ? (
            <>
              <div className="content-body voucher">
                <h2 className="section-title">Editar voucher</h2>
                <p className="description-form">Use os campos abaixo para editar este voucher</p>

                <FormRegisterVoucher idVoucher={idVoucher} role={roleVoucher} />
              </div>
              {modal}
            </>
          ) : (
            <>
              <div className="content-body voucher">
                <h2 className="section-title">Editar voucher</h2>
                <p className="description-form">Você não tem permissão para editar este voucher</p>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default VoucherEdit;
