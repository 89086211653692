import { useState, useMemo, useCallback } from 'react';
import { OutlinedInput, Button, Typography } from 'ifoodshop-react-ui';
import OutlinedTextarea from '../OutlinedTextarea';
import Dropdown from 'react-dropdown';
import client from '../../services/apollo';
import gql from 'graphql-tag';
import { Flex } from '@ifood/pomodoro-components';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { notify } from '../../helpers/notify';
import { useHistory } from 'react-router-dom';

import 'react-dropdown/style.css';
import './formRegister.scss';
import '../../components/DropDownIfood/dropdown-ifood.scss';

const FormRegister = props => {
  const options = ['Habilitado', 'Desabilitado'];
  const [groupName, setGroupName] = useState();
  const [groupDesc, setGroupDesc] = useState();
  const [hasBlurError, setHasBlurError] = useState(false);
  const [state, setState] = useState(options[0]);
  const history = useHistory();

  const validateInputLenght = value => {
    return value?.length <= 255 || !value;
  };

  const registerGroup = e => {
    e.preventDefault();
    let stateActive = true;

    if (state === 'Habilitado') {
      stateActive = true;
    } else if (state === 'Desabilitado') {
      stateActive = false;
    }

    if (validateInputLenght(groupName) && validateInputLenght(groupDesc)) {
      const CREATE_CUSTOMER_GROUP = gql`
        mutation createCustomerGroup($groupNameValue: String, $groupDescValue: String, $stateActiveValue: Boolean) {
          createCustomerGroup(
            group: { name: $groupNameValue, description: $groupDescValue, active: $stateActiveValue }
          ) {
            id
          }
        }
      `;

      client
        .mutate({
          mutation: CREATE_CUSTOMER_GROUP,
          variables: { groupNameValue: groupName, groupDescValue: groupDesc, stateActiveValue: stateActive },
        })
        .then(res => {
          var idNewGroup = res.data.createCustomerGroup.id;
          notify('Grupo de clientes criado');

          history.push(`/clientes/grupos-clientes/detalhe/${idNewGroup}`);
        })
        .catch(err => {
          return err;
        });
    }
  };

  const checkBlur = useCallback(() => {
    return setHasBlurError(!groupName);
  }, [groupName]);

  const ShowBlurError = useMemo(() => {
    if (hasBlurError) {
      return (
        <Flex alignContent={'center'} gap={'6px'} marginTop={'6px'}>
          <ErrorIcon style={{ margin: 'auto 0' }} />
          <span className="form-add-customer--label-error">*Campo obrigatório</span>
        </Flex>
      );
    }

    return;
  }, [hasBlurError]);

  return (
    <form className="form-register" onSubmit={registerGroup}>
      <div className="form-register__input-group">
        <Typography className="form-register__input-group__title">Nome do grupo*</Typography>
        <OutlinedInput
          border
          maxLength="255"
          className={hasBlurError ? 'input-blur-error' : ''}
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
          onBlur={checkBlur}
        />

        {!validateInputLenght(groupName) && (
          <span className="form-add-customer--label-error">O limite para este campo é de 255 de caracteres</span>
        )}
        {ShowBlurError}
      </div>
      <div className="form-register__input-group">
        <Typography className="form-register__input-group__title">Descrição</Typography>
        <OutlinedTextarea
          value={groupDesc}
          rows="4"
          cols="50"
          maxLength="255"
          onChange={e => setGroupDesc(e.target.value)}
        />
        {!validateInputLenght(groupDesc) && (
          <span className="form-add-customer--label-error">O limite para este campo é de 255 de caracteres</span>
        )}
      </div>
      <div className="form-register__input-group">
        <div className="wrap">
          <label className="dropdown-label">Estado do grupo</label>
          <div className="dropdown-label-wrap">
            <Dropdown
              options={options}
              value={state}
              onChange={e => setState(e.value)}
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
          </div>
        </div>
      </div>
      <Button type="submit" disabled={!validateInputLenght(groupName) || !validateInputLenght(groupDesc)}>
        Criar grupo
      </Button>
    </form>
  );
};

export default FormRegister;
