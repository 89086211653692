import { useEffect, useState } from 'react';
import { OutlinedInput, Modal } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TableDiscountSubsidy from '../../../../components/TableDiscountSubsidy';
import Loading from '../../../../components/Loading';
import { Button, Flex, Segment } from '@ifood/pomodoro-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELLERS } from '../../../../graphql/seller.queries';
import { GET_COST_CENTER } from '../../../../graphql/costCenter.queries';
import { formatCostCenterName } from './utils/subsidy';

const FormRegisterSubsidy = props => {
  const [typeSubsidy, setTypeSubsidy] = useState('IFOOD');
  const [percentSubsidy, setPercentSubsidy] = useState();
  const [dataSubsidy, setDataSubsidy] = useState([]);
  const [dataSubsidyUpdate, setDataSubsidyUpdate] = useState(null);
  const [allSubsidy, setAllSubsidy] = useState([]);
  const [modal, setModal] = useState(null);
  const [selectSearch, setSelectSearch] = useState('');
  const [autocompleteKey, setAutocompleteKey] = useState(Math.random() * 10);
  const [editSubsidy, setEditSubsidy] = useState(false);

  props.dataSubsidy(dataSubsidy);

  const [getSeller, { loading: loadingSeller, data: dataSeller }] = useLazyQuery(GET_SELLERS, {
    onCompleted: () => {
      setAllSubsidy(dataSeller.getSellerByName);
    },
  });

  const [getCostCenter, { loading: loadingCostCenter, data: dataCostCenter }] = useLazyQuery(GET_COST_CENTER, {
    onCompleted: () => {
      setAllSubsidy(dataCostCenter.getAllCostCenters);
    },
  });

  useEffect(() => getCostCenter(), []);

  if (props.dataSubsidyUpdate && dataSubsidyUpdate === null) {
    setDataSubsidyUpdate(props.dataSubsidyUpdate);
    let dataSubsidyTemp = [];
    props.dataSubsidyUpdate.map(subsidy => {
      if (subsidy.seller || subsidy.costCenter) {
        if (subsidy.costCenter) {
          delete subsidy.costCenter.__typename;
          delete subsidy.costCenter.createdAt;
          delete subsidy.costCenter.updatedAt;
        }

        dataSubsidyTemp.push({
          active: subsidy.active,
          percentage: parseInt(subsidy.percentage),
          type: subsidy.type,
          sellerId: subsidy.type === 'SELLER' && subsidy.seller ? subsidy.seller.id : null,
          costCenter: subsidy.type === 'IFOOD' && subsidy.costCenter ? subsidy.costCenter : null,
          id: props.cloneId ? null : subsidy.id,
          name: subsidy.seller ? subsidy.seller.name : subsidy.costCenter.name,
          key: Math.random() * 10,
        });

        setDataSubsidy(dataSubsidyTemp);
        setEditSubsidy(true);
      } else {
        setDataSubsidy(props.dataSubsidyUpdate);
      }
    });
  }

  function handleTypeSubsidy(e) {
    e.preventDefault();

    const {
      target: { value },
    } = e;
    setTypeSubsidy(value);

    setSelectSearch('');
    setAllSubsidy([]);

    if (value === 'IFOOD') {
      getCostCenter();
    } else if (value === 'SELLER') {
      getSeller({ variables: { name: '' } });
    }
  }

  function isInactiveCostCenter(subsidy) {
    if (subsidy.active) return false;

    setModal(
      <Modal onCloseAction={() => setModal(null)}>
        <p>Não é possível adicionar um subsídio inativo</p>
      </Modal>
    );
    return true;
  }

  function addSubsidy(e) {
    e.preventDefault();

    let sumPercentSubsidy = 0;
    if (dataSubsidy.length) {
      dataSubsidy.forEach(item => {
        sumPercentSubsidy += item.percentage;
      });
    }
    if (sumPercentSubsidy + parseFloat(percentSubsidy) > 100) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>A soma dos subsídio deve ser no máximo de 100%</p>
        </Modal>
      );
      return;
    }

    if (typeSubsidy && selectSearch && percentSubsidy) {
      let aux = null;
      if (allSubsidy)
        aux = allSubsidy.find(({ id, name, active }) => formatCostCenterName(id, name, active) === selectSearch);

      if (typeSubsidy === 'IFOOD' && isInactiveCostCenter(aux)) return;

      if (aux) {
        const { __typename, ...rest } = aux;

        setDataSubsidy([
          ...dataSubsidy,
          {
            active: true,
            percentage: parseInt(percentSubsidy),
            type: typeSubsidy,
            sellerId: typeSubsidy === 'SELLER' && aux ? aux.id : null,
            costCenter: typeSubsidy === 'IFOOD' && aux ? rest : null,
            id: null,
            name: selectSearch,
            key: Math.random() * 10,
          },
        ]);

        setTypeSubsidy('IFOOD');
        setSelectSearch('');
        setAutocompleteKey(Math.random() * 10);
        setPercentSubsidy('');
        setAllSubsidy([]);
      } else {
        setModal(
          <Modal onCloseAction={() => setModal(null)}>
            <p>Você precisa selecionar um subsídio válido</p>
          </Modal>
        );
      }
    } else {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>Você precisa preencher todos os campos de subsídio</p>
        </Modal>
      );
    }
  }

  function removeSubsidy(e) {
    setDataSubsidy(dataSubsidy.filter(item => item.key !== e));
  }

  function valuePercent(e) {
    if (e.target.value.includes('-')) {
      setPercentSubsidy('');
    } else if (e.target.value > 100) {
      setPercentSubsidy(100);
    } else {
      setPercentSubsidy(e.target.value);
    }
  }

  function handleInput(e) {
    if (typeSubsidy === 'IFOOD') {
      getCostCenter({ variables: { name: e } });
    } else if (typeSubsidy === 'SELLER') {
      getSeller({ variables: { name: e, active: true } });
    }

    document.getElementById('subsidySearch').click();
  }

  return (
    <>
      <h2 className="section-title">{props.title}</h2>

      {!editSubsidy && <p className="subtitle-subsidy">Adicione até completar 100%</p>}

      {!props.disabled && (
        <div className="form-admin__group">
          <Flex flexDirection={'column'}>
            <Segment.Group marginBottom="large" onChange={handleTypeSubsidy}>
              <Segment.Button
                checked={typeSubsidy === 'IFOOD'}
                label="Centro de custo"
                name="subsidy-type"
                value={'IFOOD'}
              />
              <Segment.Button
                checked={typeSubsidy === 'SELLER'}
                label="Fornecedor"
                name="subsidy-type"
                value={'SELLER'}
              />
            </Segment.Group>

            <Flex>
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="subsidySearch"
                  freeSolo
                  key={autocompleteKey}
                  inputValue={selectSearch}
                  onInputChange={(event, newInputValue) => setSelectSearch(newInputValue)}
                  className="autocomplete-ifood"
                  noOptionsText="Digite para procurar"
                  options={
                    allSubsidy.length > 0
                      ? allSubsidy.map(({ id, name, active }) => formatCostCenterName(id, name, active))
                      : []
                  }
                  getOptionLabel={option => option || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      onChange={e => handleInput(e.target.value)}
                      label="Centro de custo ou Fornecedor"
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <OutlinedInput
                placeholder="%"
                type="number"
                className="form-admin__input-percent"
                border
                value={percentSubsidy}
                onChange={e => valuePercent(e)}
              />
              <Button variant="secondary" size="small" height={48} onClick={addSubsidy}>
                Adicionar
              </Button>
            </Flex>
          </Flex>
        </div>
      )}
      {dataSubsidy?.length > 0 && <TableDiscountSubsidy removeSubsidy={e => removeSubsidy(e)} subsidy={dataSubsidy} />}
      {(loadingSeller || loadingCostCenter) && <Loading />}
      {modal}
    </>
  );
};

export default FormRegisterSubsidy;
