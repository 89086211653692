import { useHistory, NavLink } from 'react-router-dom';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import HeaderRegisterCategory from '../components/HeaderRegisterCategory';
import FormEditSpecification from '../components/FormEditSpecification';

import './edit-specification.scss';

const SpecificationEdit = props => {
  const history = useHistory();
  const roles = getRoles();
  const roleCategoryWR = roles.includes(Roles.CATEGORY_WR);
  const roleCategoryRO = roles.includes(Roles.CATEGORY_RO);

  if (!roleCategoryRO && !roleCategoryWR) history.push('/catalogos');

  return (
    <>
      <NavLink to={`/catalogos/categorias/detalhe/${props.match.params.id}`} className="go-back" />
      <div className="content-body edit-specification">
        <HeaderRegisterCategory
          title="Editar uma especificação"
          description="Use os campos abaixo para editar a especificação."
        />
        <FormEditSpecification categoryId={props.match.params.id} fieldId={props.match.params.id_cd} />
      </div>
    </>
  );
};

export default SpecificationEdit;
