import React, {useState, useEffect} from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Link } from 'ifoodshop-react-ui';
import {ReactComponent as DragDropIcon} from '@assets/icons/drag-drop.svg'
import './dragProductVariation.scss';

const DragProductVariation = ({data, onChange, remove}) => {
  const [items, setItems] = useState(data);

  useEffect(() => {
    setItems(data)
  }, [data]);

  useEffect(() => {
    onChange(items)
  }, [items])

  // create button drag
  const DragHandle = sortableHandle(() => <DragDropIcon className="drag-handle"/>);

  // element container
  const SortableContainer = sortableContainer( ({children}) => {
    return (
      <table className="table-admin table-admin--sample table-admin--drag-drop">
        {children}
      </table>
    );
  });

  // elements Body
  const SortableItem = sortableElement( ({value, indexItem}) => {
    return(
      <tr className="table-admin__row-drag-drop">
        <td className="table-admin__data">
          <DragHandle />
        </td>
        <td className="table-admin__data">
          {indexItem+1}
        </td>
        <td className="table-admin__data">
          {value.product}
        </td>
        {remove &&
          <td className="table-admin__data">
            <Link onClick={() => remove(value.key)} >Remover</Link>
          </td>
        }
      </tr>
    )
  });


  const onSortEnd = ({oldIndex, newIndex}) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };



  return (
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      <thead>
        <tr>
          <td className="table-admin__title"></td>
          <td className="table-admin__title">Ordem</td>
          <td className="table-admin__title">Produto</td>
          <td className="table-admin__title"></td>
        </tr>
      </thead>
      <tbody>
        {items.map((value, index) => {
          return(
            <SortableItem key={`item-${value.key}`} value={value} index={index} indexItem={index} />
          )
        })}
      </tbody>
    </SortableContainer>
  )
}

export default DragProductVariation;