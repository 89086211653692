import { gql } from '@apollo/client';

const GET_SHOPPING_LISTS = gql`
  query ShoppingLists(
    $page: Int
    $size: Int
    $name: String
    $startDate: Date
    $endDate: Date
    $status: ObjectStatus
    $validity: ObjectValidity
  ) {
    shoppingLists: getSuggestedShoppingLists(
      page: $page
      size: $size
      name: $name
      startDate: $startDate
      endDate: $endDate
      status: $status
      validity: $validity
    ) {
      ... on PaginatedShoppingList {
        content {
          id
          name
          status
          rules {
            startDate
            endDate
          }
        }
        numberOfElements
        totalPages
        totalElements
        number
        size
        first
        last
        empty
      }
      ... on CustomError {
        code
        error
        message
      }
    }
  }
`;

export { GET_SHOPPING_LISTS };
