import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import FormSearchSellers from './components/FormSearchSellers';
import TableSellers from './components/TableSellers';
import ReactPaginate from 'react-paginate';
import IconArrow from '@assets/images/arrow.png';
import { getRoles } from '@helpers/rolesUtils';
import Roles from '@helpers/Roles';
import { Button, Flex } from '@ifood/pomodoro-components';
import * as S from './SellersSearch.styles';
import TemplateOwnerSeller from '@files/templateOwnerSeller.csv';
import BulkImport from '@components/BulkImport';
import { EnumHeaderOptionsType } from '@components/BulkImport';

const SellersSearch = () => {
  const roles = getRoles();

  const roleCommissionWR = roles.includes(Roles.COMMISSION_WR);
  const roleCommissionRO = roles.includes(Roles.COMMISSION_RO);
  const roleCadSellerWR = roles.includes(Roles.CAD_SELLER_WR);
  const roleCadSellerRO = roles.includes(Roles.CAD_SELLER_RO);
  const roleSellerOwnerWR = roles.includes(Roles.SELLER_OWNER_WR);

  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);

  const history = useHistory();

  const registerSellerPage = () => {
    history.push('/fornecedores/cadastro');
  };

  return (
    <>
      <NavLink className="go-back" to="/fornecedores" />
      {roleCommissionWR || roleCommissionRO ? (
        <div className="content-body">
          <Flex justifyContent="space-between">
            <div className="header-customer-group__info">
              <h2 className="section-title">Fornecedores</h2>
              <p>Use os campos abaixo para fazer a busca de fornecedores.</p>
            </div>
            {roleSellerOwnerWR && (
              <BulkImport
                dialogTitle="Importar alteração de owner"
                label="Alteração de owner"
                options={[
                  {
                    name: 'Modelo alteração de owner',
                    value: 'seller-owner-download',
                    template: TemplateOwnerSeller,
                    roles: [Roles.SELLER_OWNER_WR],
                    type: EnumHeaderOptionsType.DOWNLOAD,
                  },
                  {
                    name: 'Alteração de owner',
                    value: 'seller-owner-import',
                    roles: [Roles.SELLER_OWNER_WR],
                    type: EnumHeaderOptionsType.UPLOAD,
                  },
                ]}
                uploadUrl="/seller/upload-update-owner-seller"
              />
            )}
          </Flex>

          <div className="header-customer-group__actions">
            <FormSearchSellers
              roles={{ roleCommissionWR, roleCommissionRO }}
              data={e => setData(e)}
              currentpage={page}
            />

            {roleCadSellerWR && (
              <S.Container>
                <Button onClick={registerSellerPage}>Novo fornecedor</Button>
              </S.Container>
            )}
          </div>

          <div className="table-box">
            {data && data.content.length ? (
              <TableSellers data={data} role={{ roleCommissionWR, roleCadSellerRO }} />
            ) : (
              <>Nenhum resultado encontrado.</>
            )}
            {data && data.totalPages > 1 ? (
              <div className="search-brand-paginate">
                <ReactPaginate
                  className="dropdown-ifood"
                  containerClassName="pagination-ifoodshop"
                  pageClassName="pagination-ifoodshop__item"
                  pageLinkClassName="pagination-ifoodshop__item-link"
                  activeLinkClassName="active"
                  previousLabel={<img className="previous-arrow" src={IconArrow} />}
                  nextLabel={<img className="next-arrow" src={IconArrow} />}
                  pageRangeDisplayed={3}
                  pageCount={data.totalPages}
                  marginPagesDisplayed={1}
                  initialPage={data.page}
                  forcePage={data.number != page ? data.number : page}
                  onPageChange={e => setPage(e.selected)}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="content-body">
          <div className="header-customer-group__info">
            <h2 className="section-title">Fornecedores</h2>
            <p>Você não tem permissão para acessar essa página.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SellersSearch;
