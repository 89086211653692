import gql from 'graphql-tag';

const CREATE_VARIATION_IMAGE = gql`
    mutation createVariationImage($imagePath: String, $active: Boolean, $order: Int, $variation: Int) {
        createVariationImage(input: {imagePath: $imagePath, active: $active, order: $order, variationId: $variation}) {
            id
            imagePath
            order
        }    
    }
`;

const UPDATE_VARIATION_IMAGES = gql`
    mutation updateVariationImages($listImages: [VariationImageInput]) {
        updateVariationImages(listImages: $listImages) {
            id
            imagePath
            order
        }    
    }
`;

const DISABLE_VARIATION_IMAGE = gql`
    mutation disableVariationImages($listImages: [VariationImageInput]) {
        disableVariationImages(listImages: $listImages) {
            id
            imagePath
            order
        }    
    }
`;

export { CREATE_VARIATION_IMAGE, UPDATE_VARIATION_IMAGES, DISABLE_VARIATION_IMAGE }