export default {
  primary: {
    contrastText: '#fff',
    light: '#EF5753',
    main: '#EA1D2C',
    dark: '#C31835',
  },
  secondary: {
    contrastText: '#3E3E3E',
    light: '#EF5753',
    main: '#fff',
    dark: '#C31835',
  },
  error: {
    main: '#FF7752',
  },
};
