import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_CATEGORY } from '../../../../graphql/category.queries';
import { GET_BRANDS } from '../../../../graphql/brand.queries';
import { CREATE_PRODUCT } from '../../../../graphql/product.mutations';
import { CREATE_SPECIFICATION } from '../../../../graphql/specification.mutation';
import { CREATE_BRAND } from '../../../../graphql/brand.mutations';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Toggle from 'react-toggle';
import Dropdown from 'react-dropdown';

import TinyEditor from '@components/TextEditor';
import AutocompleteCategory from '@components/AutocompleteCategory';
import CustomFieldTable from '../../components/TableCustomField';
import ProductCustomFields from '../ProductCustomFields';

import 'react-dropdown/style.css';
import 'react-toggle/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';
import './formRegister.scss';

const FormRegisterProduct = () => {
  const [fieldKeys, setFieldKeys] = useState(null);
  const [fields, setFields] = useState(null);
  const [brandOptions, setBrandOptions] = useState(null);
  const optionsState = ['Habilitado', 'Desabilitado'];
  const [modal, setModal] = useState(null);
  const [productName, setProductName] = useState('');
  const [state, setState] = useState(optionsState[0]);
  const [visible, setVisible] = useState(true);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryCustomFields, setCategoryCustomFields] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [fieldsValues, setFieldsValues] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [productDescription, setDescription] = useState(null);
  const [brandSugestion, setBrandSugestion] = useState(true);
  const [requiredFields, setRequiredFields] = useState([]);

  const [getCategoryDetail, { error: errorCategory, data: dataCategoryDetail }] = useLazyQuery(GET_CATEGORY, {
    onCompleted: () => {
      setFields(
        dataCategoryDetail.getCategoryDetailById.fields ? dataCategoryDetail.getCategoryDetailById.fields : null
      );
      setFieldKeys(
        Object.keys(
          dataCategoryDetail.getCategoryDetailById.fields ? dataCategoryDetail.getCategoryDetailById.fields : []
        )
      );
      if (dataCategoryDetail?.getCategoryDetailById.fields) {
        Object.values(dataCategoryDetail.getCategoryDetailById.fields).forEach(content => {
          content.forEach(item => {
            item.required && setRequiredFields(prevState => [...prevState, item]);
          });
        });
      }
    },
  });

  const [getBrands, { data: dataBrands }] = useLazyQuery(GET_BRANDS, {
    onCompleted: () => {
      setBrandOptions(dataBrands.getAllBrands.content);
    },
  });

  const [createSpecification] = useMutation(CREATE_SPECIFICATION, {
    onError: error => {
      changeModal('Atenção', 'Erro ao cadastrar especificação.');
    },
  });

  const [createBrand, { error: errorCreateBrand, data: dataCreateBrand }] = useMutation(CREATE_BRAND, {
    onCompleted: data => {
      setBrandId(data?.createBrand.id);
      changeModal('', 'Marca criada com sucesso!');
      setBrandSugestion(true);
    },
    onError: error => {
      if (error.graphQLErrors && error.graphQLErrors[0].message === 'BRAND_NAME_NOT_AVAIABLE') {
        changeModal('Atenção', 'Marca já cadastrada!');
      } else {
        changeModal('Atenção', 'Erro ao adicionar marca');
      }
    },
  });

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: data => {
      if (data?.createProduct?.error === 'PRODUCT_NAME_NOT_AVAILABLE') {
        changeModal('Atenção', 'Já existe um produto cadastrado com o mesmo nome.');
      } else {
        if (Object.keys(fieldsValues).length > 0) registerSpecifications(data);
        else window.location.href = '/catalogos/produtos/editar/' + data.createProduct.id;
      }
    },
    onError: () => {
      changeModal('Atenção', 'Erro ao cadastrar o produto.');
    },
  });

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  useEffect(() => {
    categoryId &&
      getCategoryDetail({
        variables: {
          id: categoryId,
          fieldsType: 'PRODUCT',
        },
      });
  }, [categoryId]);

  useEffect(() => {
    getBrands({
      variables: {
        brandName: brandName,
      },
    });
  }, [brandName]);

  function registerSpecifications(data) {
    Object.keys(fieldsValues).map(field => {
      let fieldValue = fieldsValues[field].value;

      Object.values(fields).map(content => {
        content.forEach(subField => {
          if (subField.id === parseInt(field) && fieldValue) {
            createSpecification({
              variables: {
                product: parseInt(data.createProduct.id),
                field: parseInt(field),
                value: fieldValue,
              },
            });
          }
        });
      });
    });

    window.location.href = '/catalogos/produtos/editar/' + data.createProduct.id;
  }

  function checkRequiredFields() {
    let empty = 1;
    if (Object.values(fieldsValues).length < requiredFields.length) {
      empty = -1;
    } else {
      Object.values(fieldsValues).forEach(item => {
        if (item.value === '' || item.value === undefined) {
          empty = -1;
        }
      });
    }

    if (empty === 1) return empty;
    else {
      changeModal('Atenção', "As especificações com '*' são obrigatórias.");
      return empty;
    }
  }

  const registerProduct = e => {
    if (checkRequiredFields() === 1) {
      e.preventDefault();
      let validacao = 1;
      let senditems = {
        name: productName,
        categoryId: categoryId,
        brandId: brandId,
        active: state === 'Habilitado',
        searchVisible: visible,
        description: productDescription ? productDescription : null,
      };
      Object.keys(senditems).map(function (objectKey, index) {
        let size = Object.keys(senditems).length - 1;
        let value = senditems[objectKey];
        if (value === '' || value == null) {
          changeModal('Atenção', 'Todos os campos são obrigatórios');
          validacao = 0;
        }
        if (validacao === 1 && index === size) {
          createProduct({ variables: senditems });
        }
      });
    }
  };

  if (document.body.querySelector('#productBrand')) {
    document.body.querySelector('#productBrand').addEventListener('blur', function () {
      if (!brandId && document.getElementById('productBrand').value !== '') {
        setBrandSugestion(false);
      } else {
        setBrandSugestion(true);
      }
    });
  }

  const brandCreate = e => {
    e.preventDefault();
    let validacao = 1;
    let sendItems = {
      name: brandName,
      active: true,
    };
    Object.keys(sendItems).map((object, index) => {
      let size = Object.keys(sendItems).length - 1;
      let value = sendItems[object];
      if (value == null || value == '') {
        setModal(<Modal onCloseAction={() => setModal(null)} title="Dados para criar Marca incorretos!"></Modal>);
        validacao = 0;
      }
      if (index == size && validacao == 1) {
        createBrand({ variables: sendItems });
      }
    });
  };

  return (
    <div>
      {modal}
      <form className="form-register form-register--product">
        <OutlinedInput
          id="productNameFormRegister"
          placeholder="Nome do Produto"
          border
          onChange={e => setProductName(e.target.value)}
        />

        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsState}
            onChange={e => setState(e.value)}
            placeholder=""
            value={optionsState[0]}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Estado</label>
        </div>

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Visível</span>
          <Toggle defaultChecked={visible} value={visible} onChange={v => setVisible(v.target.checked)} />
        </div>

        <div className="dropdown-label-wrap__category">
          <AutocompleteCategory selected={(id, name) => (setCategoryId(id), setCategoryName(name))} />
        </div>

        <div className="autocomplete-brand">
          <Autocomplete
            id="productBrand"
            freeSolo
            className="dropdown-ifood"
            options={dataBrands && dataBrands.getAllBrands ? dataBrands.getAllBrands.content : []}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Marca" variant="outlined" />}
            onChange={(e, b) => {
              setBrandId(b ? b.id : null);
              setBrandName(b ? b.name : '');
            }}
            onInput={e => setBrandName(e.target.value)}
          />
          <Button className="btn-secondary" disabled={brandSugestion} onClick={e => brandCreate(e)}>
            Criar marca
          </Button>
        </div>
        <TinyEditor data={e => setDescription(e)} />

        <div>
          {fieldKeys ? (
            <ProductCustomFields fields={fields} fieldKeys={fieldKeys} data={e => setFieldsValues(e)} />
          ) : null}
        </div>
        <Button className="btn-save" type="button" onClick={e => registerProduct(e)}>
          Adicionar produto
        </Button>
      </form>
      {categoryCustomFields ? <CustomFieldTable customFields={categoryCustomFields} idCategory={categoryId} /> : null}
    </div>
  );
};

export default FormRegisterProduct;
