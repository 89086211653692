import { Modal, Typography, Button } from 'ifoodshop-react-ui';

import check from '../../../../../../assets/icons/check.svg';

import './pixChargebackSuccess.scss';

const PixChargebackSuccess = ({ onClose }) => {
  const componentClassName = 'pix-chargeback-success';

  return (
    <Modal onCloseAction={onClose}>
      <section className={componentClassName}>
        <img src={check} alt="Sucesso" />

        <Typography size="20px" weight="medium" line="24px" color="avocado">
          Estorno solicitado
        </Typography>

        <Typography size="16px" line="24px" className={`${componentClassName}__description`}>
          As informações do pedido serão atualizadas e o estorno será realizado para o cliente.
        </Typography>

        <Button onClick={onClose} className={`${componentClassName}__button`}>
          Ir para o pedido
        </Button>
      </section>
    </Modal>
  );
};

export default PixChargebackSuccess;
