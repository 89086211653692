import gql from 'graphql-tag';

const CREATE_PRODUCT_VARIATION = gql`
  mutation createProductVariation(
    $name: String
    $product: Int
    $width: Float
    $weight: Float
    $height: Float
    $length: Float
    $quantity: Float
    $barCode: String
    $unity: String
    $active: Boolean
  ) {
    createProductVariation(
      input: {
        name: $name
        productId: $product
        width: $width
        weight: $weight
        height: $height
        length: $length
        quantity: $quantity
        barCode: $barCode
        unity: $unity
        active: $active
      }
    ) {
      id
      name
      barCode
      unity
      error
    }
  }
`;

const UPDATE_PRODUCT_VARIATION = gql`
  mutation updateProductVariation(
    $id: ID!
    $name: String
    $product: Int
    $width: Float
    $weight: Float
    $height: Float
    $length: Float
    $quantity: Float
    $barCode: String
    $unity: String
    $active: Boolean
  ) {
    updateProductVariation(
      id: $id
      input: {
        name: $name
        productId: $product
        width: $width
        weight: $weight
        height: $height
        length: $length
        quantity: $quantity
        barCode: $barCode
        unity: $unity
        active: $active
      }
    ) {
      id
      name
      barCode
      unity
      error
    }
  }
`;

export { CREATE_PRODUCT_VARIATION, UPDATE_PRODUCT_VARIATION };
