import gql from 'graphql-tag';

const GET_SELLERS = gql`
  query getSellerByName($name: String, $active: Boolean) {
    getSellerByName(name: $name, active: $active) {
      id
      companyName
      name
    }
  }
`;

const GET_SELLER = gql`
  query getSellerById($id: ID) {
    getSellerById(id: $id) {
      id
      active
      removed
      name
      companyName
      document
      email
      commission
      stateRegistration
      owner
      logo
      shippingByShop
      user {
        id
        login
        residentialPhone
        mobilePhone
      }
      mcc
      cnae
      updatedAt
      createdAt
      modalAdmin
      rememberMe
    }
  }
`;

const GET_ADDRESS = gql`
  query getAddressBySellerId($sellerId: Int!) {
    getAddressBySellerId(sellerId: $sellerId) {
      address
      city
      complement
      country
      neighborhood
      number
      state
      stateName
      zipcode
    }
  }
`;

const UPDATE_SELLER = gql`
  mutation updateSeller(
    $id: Int
    $idUser: Int
    $active: Boolean
    $removed: Boolean
    $name: String
    $login: String
    $password: String
    $companyName: String
    $document: String
    $email: String
    $residentialPhone: String
    $mobilePhone: String
    $commission: Float
    $stateRegistration: String
    $owner: String
    $mcc: String
    $cnae: String
  ) {
    updateSeller(
      sellerVO: {
        sellerDTO: {
          id: $id
          active: $active
          removed: $removed
          name: $name
          companyName: $companyName
          document: $document
          email: $email
          commission: $commission
          stateRegistration: $stateRegistration
          owner: $owner
          mcc: $mcc
          cnae: $cnae
          user: {
            id: $idUser
            login: $login
            password: $password
            residentialPhone: $residentialPhone
            mobilePhone: $mobilePhone
          }
        }
      }
    ) {
      id
    }
  }
`;

const GET_PAGINATE_SELLERS = gql`
  query getPaginateSeller($companyName: String, $page: Int, $size: Int) {
    getPaginateSeller(companyName: $companyName, page: $page, size: $size) {
      content {
        id
        active
        name
        user {
          id
          login
          residentialPhone
          mobilePhone
        }
        companyName
        document
        email
        stateRegistration
        owner
        updatedAt
        createdAt
      }
      empty
      first
      last
      number
      numberOfElements
      size
      totalElements
      totalPages
    }
  }
`;

const GET_PAGINATE_SELLERS_BY_NAME = gql`
  query getPaginateSellerByName($name: String, $page: Int, $size: Int) {
    getPaginateSellerByName(name: $name, page: $page, size: $size) {
      content {
        id
        active
        name
        user {
          id
          login
          residentialPhone
          mobilePhone
        }
        userKeycloakInfo {
          entityId
          type
          pwLink
          error
          expiresAt
          status
        }
        companyName
        document
        email
        stateRegistration
        owner
        updatedAt
        createdAt
      }
      empty
      first
      last
      number
      numberOfElements
      size
      totalElements
      totalPages
    }
  }
`;

const GET_LOGISTIC_TYPES_ALLOWED = gql`
  query getLogisticTypesAllowed {
    getLogisticTypesAllowed {
      logistic {
        id
        name
        active
      }
    }
  }
`;

const VALIDATION_CNPJ = gql`
  query getSellerValidationCnpj($document: String!) {
    getSellerValidationCnpj(cnpj: $document) {
      addressComplement
      addressNumber
      city
      cnpj
      company
      companyName
      neighbourhood
      street
      uf
      zipcode
      situation
      message
      errorCode
    }
  }
`;

const GET_SELLER_TYPES = gql`
  query getSellerTypes($id: Int, $name: String) {
    getSellerTypes(id: $id, name: $name) {
      id
      name
    }
  }
`;

const GET_BUSINESS_TYPES = gql`
  query getBusinessTypes {
    getBusinessTypes {
      value
      name
    }
  }
`;

export {
  GET_SELLERS,
  GET_SELLER,
  GET_ADDRESS,
  UPDATE_SELLER,
  GET_PAGINATE_SELLERS,
  GET_PAGINATE_SELLERS_BY_NAME,
  GET_LOGISTIC_TYPES_ALLOWED,
  VALIDATION_CNPJ,
  GET_SELLER_TYPES,
  GET_BUSINESS_TYPES,
};
