import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELLER } from '../../../../graphql/seller.queries';
import { GET_BY_ORDER_SELLER_IDS } from '../../../../graphql/order-seller.queries';
import { Typography, Modal, Link } from 'ifoodshop-react-ui';
import { Tooltip } from '@ifood/pomodoro-components';

import FormatHelper from '@helpers/FormatHelper';
import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrders } from '@analytics/aboyeur/entities/sessionOrders';

import { ReactComponent as InfoIcon } from '../../../../assets/icons/Info.svg';
import { ReactComponent as DataInfoIcon } from '../../../../assets/icons/InfoData.svg';
import { ReactComponent as TrashCanIcon } from '../../../../assets/icons/trash-can.svg';

import Loading from '@components/Loading';
import CheckboxIfood from '@components/CheckboxIfood';
import TooltipContent from '@components/TooltipContent';
import { orderStatusLabel } from '../orderUtils';
import RuptureInLote from '../Rupture/InLote';
import ModalFlowAccept from '../ModalFlowAccept';

import './tableOrderSearch.scss';

const TableOrderSearch = ({ orders, rolesOrder, isSeller, idSeller, renderTitle, variables }) => {
  const { roleOrderWR, roleOrderRO } = rolesOrder;
  const [checkedRupture, setCheckedRupture] = useState([]);
  const [checkedAllOrders, setCheckedAllOrders] = useState(false);
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [showModalFlowAccept, setShowModalFlowAccept] = useState(false);
  const currentDate = FormatHelper.format(new Date(), 'date-formated');
  const yesterday = FormatHelper.format(new Date().setDate(-1), 'date-formated');

  //faster
  const sessionOrders = useAboyeur(SessionOrders);

  const stepsEnableRupture = ['PAYMENT_ACCEPTED', 'IN_STOCK_SEPARATION'];

  const [getOrder, { loading: loadingOrder, data: dataOrder }] = useLazyQuery(GET_BY_ORDER_SELLER_IDS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      openModalRupture('PRODUCT');
    },
  });

  const [getSellerId, { data: dataSellerId }] = useLazyQuery(GET_SELLER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      const seller = dataSellerId?.getSellerById;
      const previousDate = FormatHelper.format(
        new Date(seller?.rememberMe ?? yesterday.concat('T00:01')),
        'date-formated'
      );
      const modalAdmin = Boolean(seller?.modalAdmin);

      setShowModalFlowAccept(isSeller && !modalAdmin && previousDate < currentDate);
    },
  });

  const updateBorderTag = () => {
    if (variables?.isLater) return { borderRight: '0px' };
  };
  const ruptureLote = variables?.product?.length > 0;

  const enableRupture = order => stepsEnableRupture.includes(order?.status) && order?.paymentProcessed === false;

  const handleCheckedRupture = order => {
    const { sellerSetId } = order;
    const index = checkedRupture.findIndex(find => find.sellerSetId === sellerSetId);

    if (index >= 0) {
      checkedRupture.forEach(item => {
        if (item.sellerSetId === sellerSetId)
          setCheckedRupture(checkedRupture.filter(filter => filter.sellerSetId !== item.sellerSetId));
      });
    } else {
      setCheckedRupture([...checkedRupture, order]);
    }
  };

  const handleCheckedAllOrders = () => {
    if (!checkedAllOrders) {
      orders.forEach(order => {
        const index = checkedRupture.findIndex(find => find.sellerSetId === order.sellerSetId);
        if (index < 0) setCheckedRupture(prevState => [...prevState, order]);
      });
    } else setCheckedRupture([]);

    setCheckedAllOrders(!checkedAllOrders);
  };

  const checkedInitial = id => {
    let checked = false;
    checkedRupture.find(item => {
      if (item.sellerSetId === id) checked = true;
    });
    return checked;
  };

  const handleRuptureInLote = (e, stepModal) => {
    e.preventDefault();
    let newArray = [];
    if (checkedRupture.length && stepModal === 'PRODUCT') {
      checkedRupture.forEach(item => {
        newArray.push(item.sellerSetId);
      });
      getOrder({
        variables: {
          ids: newArray,
          skuSeller: variables?.product,
        },
      });
      //faster - Session
      sessionOrders.events.orders.clickButtonMultipleQuantityEdit();
    }
  };

  const openModalRupture = stepModal => {
    if (!loadingOrder) {
      setModal(
        <Modal
          className="modal-steps-order"
          onCloseAction={() => {
            setModal(null);
          }}
        >
          <RuptureInLote
            step={stepModal}
            setModal={() => setModal(null)}
            checkedRupture={checkedRupture}
            dataOrders={dataOrder?.getByOrderSellerIds}
            isSeller={isSeller}
            sellerCode={variables?.product}
          />
        </Modal>
      );
    }
  };

  const handleEditItens = ({ sellerSetId }) => {
    const childOrderId = String(sellerSetId);
    //faster - Session
    sessionOrders.events.orders.clickButtonEditOrder({
      childOrderId,
    });
  };

  const handleViewOrderDetail = ({ sellerSetId }) => {
    const childOrderId = String(sellerSetId);
    //faster - Session
    sessionOrders.events.orders.clickButtonViewOrderDetail({
      childOrderId,
    });
  };

  useEffect(() => {
    if (isSeller) {
      getSellerId({
        variables: { idSeller },
      });
    }
  }, [isSeller, idSeller]);

  const trRender = order => {
    return (
      <tr id={order.id} key={order.id}>
        <td className="table-admin__data">
          <div className="table-admin__item">
            {ruptureLote && (
              <CheckboxIfood
                checkboxSecondary
                key={order.id}
                checked={checkedInitial(order.sellerSetId)}
                onChange={() => handleCheckedRupture(order)}
              />
            )}
            {roleOrderWR ? (
              <Link href={`/pedidos/detalhe/${order.orderId}`} 
                title={order.orderId} 
                onClick={() => handleViewOrderDetail(order)}>
                {order.orderId}
              </Link>
            ) : (
              <Tooltip content={'Você não tem permissão para abrir um pedido'} placement="top">
                <span className="link--disabled">{order.orderId}</span>
              </Tooltip>
            )}
            {order.hasVariableWeight && order.status === 'IN_STOCK_SEPARATION' && (
              <Tooltip content={'Contém um item com peso variável'} placement="top">
                <InfoIcon width={20} />
              </Tooltip>
            )}
          </div>
        </td>
        <td className="table-admin__data">{order.sellerSetId}</td>
        <td className="table-admin__data tableOrderStatus" style={updateBorderTag()}>
          {orderStatusLabel[order.status] || order.status}
        </td>
        {variables?.isLater && (
          <td className="orderDelayedTable">
            <span className="orderDelayedTag">Atrasado</span>
          </td>
        )}
        <td className="table-admin__data">{order.customerName}</td>
        {!isSeller && <td className="table-admin__data">{order.seller.name}</td>}
        <td className="table-admin__data">{FormatHelper.format(order.sellerSetTotalValue, 'currency')}</td>
        {isSeller && (
          <td className="table-admin__data">
            {enableRupture(order) ? (
              <Link
                href={`/pedidos/detalhe/${order.orderId}?link=${order.sellerSetId}`}
                title="Editar itens"
                onClick={() => handleEditItens(order)}
              >
                Editar itens
              </Link>
            ) : (
              <span className="link--disabled">Editar itens</span>
            )}
          </td>
        )}
        <td className="table-admin__data">{FormatHelper.format(order.orderDate, 'date')}</td>
        <td className="table-admin__data">{FormatHelper.format(order.deliveryExpectedDate, 'simple-date')}</td>
      </tr>
    );
  };

  return (
    <>
      {showModalFlowAccept && <ModalFlowAccept isOpen={isOpen} setIsOpen={setIsOpen} />}
      <div className="orderSearch orderSearch--title-search">
        {renderTitle && <h3 className="section-title title-table-groups">Resultado do Filtro</h3>}
        {ruptureLote && (
          <Tooltip
            content={
              <TooltipContent
                content="Selecione ao menos um pedido para continuar"
                width={'156px'}
                maxWidth={'100%'}
              />
            }
            placement="top"
          >
            <Link
              className="orderSearch__link"
              disabled={!checkedRupture.length}
              onClick={e => handleRuptureInLote(e, 'PRODUCT')}
            >
              <TrashCanIcon className="icon-trash-can" />

              Remover produto
            </Link>
          </Tooltip>
        )}
      </div>
      {ruptureLote && (
        <div className="orderSearch orderSearch--description-filter">
          <Typography size="18px" weight="normal" line="24px" color="heavygrey">
            Apenas pedidos com "Pagamento Confirmado" e "Em separação" serão exibidos ao filtrar por um produto
            específico
          </Typography>
        </div>
      )}
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">
              {ruptureLote && (
                <CheckboxIfood checkboxSecondary checked={checkedAllOrders} onChange={() => handleCheckedAllOrders()} />
              )}
              ID Pai
            </th>
            <th className="table-admin__title">ID</th>
            <th className="table-admin__title" style={updateBorderTag()}>
              Status
            </th>
            {variables?.isLater && <th className="orderDelayedTitle"></th>}
            <th className="table-admin__title">Cliente</th>
            {!isSeller && <th className="table-admin__title">Lojista</th>}
            <th className="table-admin__title">Total</th>
            {isSeller && (
              <th className="table-admin__title">
                <div className="table-admin__tooltip">
                  Edição
                  <div className="table-admin__tooltip-icon">
                    <Tooltip
                      content={
                        <TooltipContent
                          content="Edite a quantidade e peso variável dos itens de um pedido"
                          width={'156px'}
                          maxWidth={'100%'}
                        />
                      }
                      placement="top"
                    >
                      <DataInfoIcon width={14} />
                    </Tooltip>
                  </div>
                </div>
              </th>
            )}
            <th className="table-admin__title">
              <div className="table-admin__tooltip">
                Data
                <div className="table-admin__tooltip-icon">
                  <Tooltip
                    content={
                      <TooltipContent
                        content="Data e horário em que o pedido foi realizado pelo restaurante no iFood Shop"
                        width={'212px'}
                        maxWidth={'100%'}
                      />
                    }
                    placement="top"
                  >
                    <DataInfoIcon width={14} />
                  </Tooltip>
                </div>
              </div>
            </th>
            <th className="table-admin__title">
              <div className="table-admin__tooltip">
                Previsão de entrega
                <div className="table-admin__tooltip-icon">
                  <Tooltip
                    content={
                      <TooltipContent
                        content="Previsão de entrega dos pedidos com pagamento aprovado"
                        width={'204px'}
                        maxWidth={'100%'}
                      />
                    }
                    placement="top"
                  >
                    <DataInfoIcon width={14} />
                  </Tooltip>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <>{trRender(order)}</>
          ))}
        </tbody>
      </table>
      {loadingOrder && <Loading />}
      {modal}
    </>
  );
};

export default TableOrderSearch;
