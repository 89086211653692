import { ReactComponent as IconSucess } from '../../assets/icons/icon-success.svg';

import './labelSuccess.scss';

const LabelSuccess = props => {
  return (
    <div className="label-snackbar label-snackbar--success">
      <span className="label-snackbar__icon-box">
        <IconSucess className="label-snackbar__icon" />
      </span>
      {props.content}
    </div>
  );
};

export default LabelSuccess;
