import gql from "graphql-tag";

const CREATE_CARRIER = gql`
  mutation createCarrier($input: CarrierInput) {
    createCarrier(input: $input) {
      id
    }
  }
`;

const UPDATE_CARRIER = gql`
  mutation updateCarrier($input: CarrierInput) {
    updateCarrier(input: $input) {
      id
    }
  }
`;

const CREATE_FREIGHT_RANGE = gql`
  mutation createFreightValue($input: FreightValueInput!) {
    createFreightValue(input: $input) {
      region
    }
  }
`;

const UPDATE_FREIGHT_RANGE = gql`
  mutation updateFreightValue($input: FreightValueInput!) {
    updateFreightValue(input: $input) {
      region
    }
  }
`;

export { CREATE_CARRIER, UPDATE_CARRIER, CREATE_FREIGHT_RANGE, UPDATE_FREIGHT_RANGE };