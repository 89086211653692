import { useState, useEffect, Fragment } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { VEHICLE_TRACKING } from '../../../../graphql/vehicle.queries';
import { Link, Modal } from 'ifoodshop-react-ui';

import Formatter from '@helpers/FormatHelper';
import { orderDeliveryStatus, validSteps } from '../orderUtils';

import ProgressBar from '../AlterStatus/RequestVehicle/ProgressBar';
import ModalAuditHistory from '../DeliveryHistory/ModalAuditHistory';

import AwaitingPayment from '../../../../assets/icons/order/awaiting_payment.svg';
import InSeparation from '../../../../assets/icons/order/in_separation.svg';
import OrderBilled from '../../../../assets/icons/order/order_billed.svg';
import OrderConcluded from '../../../../assets/icons/order/order_concluded.svg';
import OrderConfirmed from '../../../../assets/icons/order/order_confirmed.svg';
import Transport from '../../../../assets/icons/order/transport.svg';
import WaitingPickup from '../../../../assets/icons/order/waiting_pickup.svg';
import arrowRight from '../../../../assets/icons/order/arrow-right.svg';

import './deliveryHistory.scss';

function DeliveryHistory({ delivery, payments }) {
  const { steps, audit, ownerType } = delivery;
  const lastStep = steps.length;
  const [modal, setModal] = useState(null);
  const [phraseVariation, setPhraseVariation] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [getSteps, { data: dataVehicleTracking }] = useLazyQuery(VEHICLE_TRACKING, {
    fetchPolicy: 'no-cache',
    variables: { deliveryId: delivery.id },
    onCompleted: () => {
      let trackingSteps = [];
      if (dataVehicleTracking.getVehicleSteps.steps !== null) {
        let valid = validSteps.map(v => dataVehicleTracking.getVehicleSteps.steps.find(s => v === s.code));
        valid.forEach(t => t && trackingSteps.push(t));
      }

      ModalVehicleTracking(trackingSteps);
    },
  });

  const showAuditHistory = () => {
    const history = audit.sort((a, b) => a.id - b.id);

    setModal(
      <Modal onCloseAction={() => setModal(null)}>
        <ModalAuditHistory history={history} phraseVariation={phraseVariation} />
      </Modal>
    );
  };

  useEffect(() => {
    payments.map(payment => payment.type === 'POST_PAID_BANK_SLIP' && setPhraseVariation(true));

    setCurrentStep(orderDeliveryStatus[steps.find(item => item.active)?.name]);
  }, [payments, steps]);

  function handleStepInfoValid(step, info) {
    return step[info] && currentStep >= orderDeliveryStatus[step['name']];
  }

  function ModalVehicleTracking(a) {
    setModal(
      <Modal onCloseAction={() => setModal(null)} className="modal-vehicle">
        <ProgressBar
          deliveryStep={steps}
          setModal={() => setModal(null)}
          validSteps={a}
          quoteCode={dataVehicleTracking.getVehicleSteps.laasDeliveryCode}
        />
      </Modal>
    );
  }

  return (
    <>
      <div className="order-detail__history">
        <div className="order-detail__steps">
          {steps.map((step, index) => {
            var stepItem = { name: '', icon: '' };
            switch (step.name) {
              case 'WAITING_PAYMENT':
                if (phraseVariation === true) stepItem = { name: 'Aguardando', icon: AwaitingPayment };
                else
                  stepItem = {
                    name: 'Aguardando pagamento',
                    icon: AwaitingPayment,
                  };
                break;
              case 'PAYMENT_ACCEPTED':
                if (phraseVariation === true) stepItem = { name: 'Confirmado', icon: OrderConfirmed };
                else
                  stepItem = {
                    name: 'Pagamento confirmado',
                    icon: OrderConfirmed,
                  };
                break;
              case 'BILLED':
                stepItem = { name: 'Pedido faturado', icon: OrderBilled };
                break;
              case 'IN_STOCK_SEPARATION':
                stepItem = { name: 'Em separação', icon: InSeparation };
                break;
              case 'IN_TRANSPORTATION':
                stepItem = {
                  name: ownerType === 'IFOOD' ? 'Veículo solicitado' : 'Encaminhado para transporte',
                  icon: Transport,
                };
                break;
              case 'WAITING_PICKUP':
                stepItem = { name: 'Aguardando retirada', icon: WaitingPickup };
                break;
              case 'FINISHED':
                stepItem = { name: 'Pedido concluido', icon: OrderConcluded };
                break;
              case 'CANCELLED':
                stepItem = { name: 'Cancelado', icon: OrderConcluded };
                break;
              default:
                stepItem = { name: step.name };
                break;
            }
            return (
              <Fragment key={Math.random()}>
                <div key={step.name} className={`order-detail__steps-item ${step.active ? 'active' : ''}`}>
                  <div className="order-detail__steps-box-icon">
                    <img src={stepItem.icon} alt="icone historico de entrega" className="order-detail__steps-icon" />
                  </div>
                  <span className="order-detail__steps-label">{stepItem.name}</span>
                  {handleStepInfoValid(step, 'date') && (
                    <span className="order-detail__steps-info">{Formatter.format(step.date, 'date')}</span>
                  )}
                  {handleStepInfoValid(step, 'login') && <span className="order-detail__steps-info">{step.login}</span>}
                  {step.name === 'IN_TRANSPORTATION' && step.active && ownerType === 'IFOOD' && (
                    <Link onClick={() => getSteps()}>Acompanhar status</Link>
                  )}
                </div>
                {lastStep !== index + 1 && (
                  <img src={arrowRight} alt="icon right" className="order-detail__steps-arrow" />
                )}
              </Fragment>
            );
          })}
        </div>
        <div className="order-detail__history-audit">
          <Link className="order-detail__history-link" onClick={showAuditHistory}>
            Ver histórico de alterações
          </Link>
        </div>
      </div>
      {modal}
    </>
  );
}

export default DeliveryHistory;
