import { NavLink } from 'react-router-dom';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import { ProductSuggestionProvider } from '@components/_context/ProductSuggestioContext';
import HeaderRegisterProduct from '../components/HeaderRegisterProduct';
import StepProductSuggestion from '../components/ProductSuggestion/StepProductSuggestion';
import IdentifyProduct from '../components/ProductSuggestion/IdentifyProduct';
import SaleCondition from '../components/ProductSuggestion/ProductSaleConditions';
import ResumeSuggestion from '../components/ProductSuggestion/ResumeSuggestion';

import './productsuggestion.scss';

const ProductSuggestion = ({ page }) => {
  const roles = getRoles();
  const roleSuggest = roles.includes(Roles.SUGGESTION_WR);

  if (!roleSuggest) window.location.href = '/';

  return (
    <ProductSuggestionProvider {...page}>
      {roleSuggest && (
        <>
          <NavLink to="/catalogos/sugestoes" className="go-back" />
          {roleSuggest ? (
            <div className="content-body suggestion-register">
              <HeaderRegisterProduct title="Cadastro de produtos" />
              <StepProductSuggestion />
              {
                {
                  identifyProduct: <IdentifyProduct />,
                  saleCondition: <SaleCondition />,
                  resumeSuggestion: <ResumeSuggestion />,
                }[page]
              }
            </div>
          ) : (
            <div className="content-body suggestion-register">
              <HeaderRegisterProduct
                title="Sugestão de produtos"
                description="Você não tem permissão para criar uma sugestão."
              />
            </div>
          )}
        </>
      )}
    </ProductSuggestionProvider>
  );
};

export default ProductSuggestion;
