import { Text as TextPomodoro } from '@ifood/pomodoro-components';
import styled from 'styled-components';

interface IFigureBox {
  marginBottom?: string;
}

export const FigureBox = styled.figure<IFigureBox>`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 180px;
  margin: 0 0 ${({ theme, marginBottom }) => marginBottom || theme.space.larger};
`;

export const Image = styled.img`
  object-fit: cover;
  max-width: 100%;
`;

export const TextColor = styled(TextPomodoro)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.m};
  color: ${({ theme }) => theme.colors.grayDarkest};
  strong {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Heading = styled(TextColor)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: ${({ theme }) => `0 0 ${theme.space.largest}`};
`;

export const Text = styled(TextColor)`
  margin: ${({ theme }) => `0 0 ${theme.space.loose}`};
`;
