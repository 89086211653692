import React from 'react'

const HeaderRegisterGroup = (props) => {
  return(
    <div className="header-customer-group">
       <div className="header-customer-group__info">
         <h2 className="section-title">{props.title}</h2>
         <p className="header-customer-group__description">{props.description}</p>
       </div>
    </div>
  )
}

export default HeaderRegisterGroup;