import React, { useState } from 'react';
import IconArrow from '@assets/images/arrow.png';
import ReactPaginate from 'react-paginate';
import { useQuery } from '@apollo/react-hooks';
import Loading from '@components/Loading';
import TableSearchCommercialCondition from './TableSearchCommercialCondition';
import { GET_COMMERCIAL_CONDITION } from '../../../../../../graphql/commercial_condition.queries';
import FormSearchCommercialCondition from './FormSearchCommercialCondition';
import { transformDateUS } from '@helpers/FormatHelper';

const ListCommercialCondition = ({ role }) => {

  const [variables, setVariables] = useState({
    page: 0,
    size: 10,
    isEnabled: true,
  });


  const { data, loading } = useQuery(GET_COMMERCIAL_CONDITION, {
    variables
  });

  return (
    <>
      <FormSearchCommercialCondition
        onChangeFilter={(e) =>
          setVariables({
            ...variables,
            page: 0,
            idOrName: e.idOrName,
            creationDate: transformDateUS(e.creationDate),
            isEnabled: e.isEnabled,
          })
        }
      />
      <TableSearchCommercialCondition
        data={data?.getAllCommercialCondition?.content || []}
        role={role}
      />
      <div className="search-customer-paginate">
        {data?.getAllCommercialCondition?.totalPages > 1 && (
          <ReactPaginate
            containerClassName="pagination-ifoodshop"
            pageClassName="pagination-ifoodshop__item"
            pageLinkClassName="pagination-ifoodshop__item-link"
            activeLinkClassName="active"
            previousLabel={<img className="previous-arrow" src={IconArrow} />}
            nextLabel={<img className="next-arrow" src={IconArrow} />}
            onPageChange={(e) =>
              setVariables({ ...variables, page: e.selected })
            }
            pageRangeDisplayed={3}
            pageCount={data?.getAllCommercialCondition?.totalPages}
            marginPagesDisplayed={1}
            initialPage={variables.page}
            forcePage={data.getAllCommercialCondition.number}
          />
        )}
      </div>
      {loading && <Loading />}
    </>
  );
};

export default ListCommercialCondition;
