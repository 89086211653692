import { useState } from 'react';
import Roles from '@helpers/Roles';
import Header from '@components/Header';
import DataSellerComponent from './components/';
import { useHistory } from 'react-router-dom';
import { getRoles } from '@helpers/rolesUtils';
import ListOfContracts from './components/ListOfContracts';
import { DataSellerProvider } from './DataSellerContext';

const DataSeller = props => {
  const [step, setStep] = useState(0);
  const [updatedLogisticTypes, setUpdatedLogisticTypes] = useState([]);
  const [sellerData, setSellerData] = useState(null);
  const [sellerBank, setSellerBank] = useState(null);
  const [sellerAddress, setSellerAddress] = useState(null);
  const [logisticTypes, setLogisticTypes] = useState([]);

  const history = useHistory();
  const roles = getRoles();

  const dataSellerWR = roles.includes(Roles.DATA_SELLER_WR);
  const dataSellerRO = roles.includes(Roles.DATA_SELLER_RO);
  const logisticAllowedWR = roles.includes(Roles.LOGISTICS_ALLOWED_WR);
  const logisticAllowedRO = roles.includes(Roles.LOGISTICS_ALLOWED_RO);

  if (!dataSellerRO && !dataSellerWR) history.push('/fornecedores');

  const dataSellerProps = {
    step,
    setStep,
    logisticTypes,
    setLogisticTypes,
    sellerData,
    setSellerData,
    sellerBank,
    setSellerBank,
    sellerAddress,
    setSellerAddress,
    logisticAllowedWR,
    logisticAllowedRO,
    updatedLogisticTypes,
    setUpdatedLogisticTypes,
  };

  const handlePage = () => {
    if (step === 0) return <DataSellerComponent role={dataSellerWR} props={props} />;
    else if (step === 1) return <ListOfContracts />;
  };

  return (
    <DataSellerProvider {...dataSellerProps}>
      <div className="content-body">
        <Header
          title="Meus Dados"
          backRoute={`/fornecedores`}
          description="Edição dos dados do fornecedor."
          isStep
          step={step}
          setStep={setStep}
        />
        {handlePage()}
      </div>
    </DataSellerProvider>
  );
};

export default DataSeller;
