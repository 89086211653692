import { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom'

import Loading from '../Loading';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ALL_CARRIERS, GET_CARRIER_BY_ID, GET_FREIGHT_VALUE_BY_CARRIER_ID_PAGED, GET_CARRIER_DELIVERY_TYPE } from '../../graphql/logistics.queries'
import { CREATE_CARRIER, UPDATE_CARRIER, CREATE_FREIGHT_RANGE, UPDATE_FREIGHT_RANGE } from '../../graphql/logistics.mutations'
import { GET_SELLERS, GET_SELLER } from '../../graphql/seller.queries';

import Decoder from '../../helpers/Decoder';
import Roles from '../../helpers/Roles';

export const CarrierFreightRangeContext = createContext()

export function CarrierFreightRangeProvider({children, ...props}) {
  const [pageSearch, setPageSearch] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [formSearch, setFormSearch] = useState({});
  const [modal, setModal] = useState(null);
  const [freightRangeSelected, setFreightRangeSelected] = useState(null)
  const [freightRangeFilterCalled, setFreightRangeFilterCalled] = useState(false);
  const [pageSearchFreightRange, setPageSearchFreightRange] = useState(0);
  const [pageSizeFreightRange, setPagesizeFreightRange] = useState(10);
  const [lastPageFreightRange, setLastPageFreightRange] = useState(1);
  const history = useHistory()


  //
  // Roles
  const tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  const userSellerId = tokenDecoded?.id_seller;
  const roleSellerWriteRead = tokenDecoded.resource_access["manager"].roles.includes(Roles.SELLER_WR);
  const roleSellerReadOnly = tokenDecoded.resource_access["manager"].roles.includes(Roles.SELLER_RO);
  const roleCarrierWriteRead = tokenDecoded.resource_access["manager"].roles.includes(Roles.CARRIER_WR);
  const roleCarrierReadOnly = tokenDecoded.resource_access["manager"].roles.includes(Roles.CARRIER_RO);

  if (!roleCarrierWriteRead && !roleCarrierReadOnly) {
    history.push('');
  }


  //
  // Queries
  const [getSellerByName, { data: dataSellers }] = useLazyQuery(GET_SELLERS);
  const [getSellerById, { data: dataSellerById }] = useLazyQuery(GET_SELLER);
  const [getAllCarriers, {data: dataCarriers, loading}] = useLazyQuery(GET_ALL_CARRIERS, {
    fetchPolicy: "no-cache",
    variables: {
      page: pageSearch,
      size: pageSize,
      ...formSearch,
      active: formSearch?.active ? formSearch?.active?.value : true
    },
    onCompleted: () => {
      setLastPage(dataCarriers.getAllCarriers.totalPages)
    }
  })
  const [requestCarrierById, { data: dataCarrier, loading: loadingGetCarrierById }] = useLazyQuery(GET_CARRIER_BY_ID, {
    fetchPolicy: "no-cache"
  })

  const { data: dataCarrierDeliveryType } = useQuery(GET_CARRIER_DELIVERY_TYPE);

  const [createCarrier, {loading: loadingCreateCarrier}] = useMutation(CREATE_CARRIER, {
    onCompleted: data => {
      const { id } = data.createCarrier
      if(id) history.push(`/fornecedores/transportadoras/cadastro/${id}/faixa-frete`)
    }
  });
  const [updateCarrier, {loading: loadingUpdateCarrier}] = useMutation(UPDATE_CARRIER, {
    onCompleted: data => {
      const { id } = data.updateCarrier
      if(id) history.push(`/fornecedores/transportadoras/editar/${id}/faixa-frete`)
    }
  });
  const [createFreightRange, {loading: loadingCreateFreightRange}] = useMutation(CREATE_FREIGHT_RANGE);
  const [updateFreightRange, {loading: loadingUpdateFreightRange}] = useMutation(UPDATE_FREIGHT_RANGE, {
    onCompleted: data => {
      const { region } = data.updateFreightValue;
      if(region) history.push(`/fornecedores/transportadoras/editar/${props.carrierId}/faixa-frete`)
    }
  });

  const getCarrierById = ( () => {
    props.carrierId &&
    requestCarrierById({
      variables: {
      id: props.carrierId
    }}
    )
  })

  const [requestFreightRangeByCarrierIdPaged, {data: dataFreightRangePaged, loading: loadingFreightRangePaged}] = useLazyQuery(GET_FREIGHT_VALUE_BY_CARRIER_ID_PAGED, {
    fetchPolicy: "no-cache",
    variables: {
      carrierId: props.carrierId,
      page: pageSearchFreightRange,
      size: pageSizeFreightRange,
      ...formSearch
    },
    onCompleted: () => {
      setLastPageFreightRange(dataFreightRangePaged.getAllFreightValueByCarrierIdPaged.totalPages)
    }
  })

  const getFreightRangeByCarrierId = ( () => {
    props.carrierId &&
    requestFreightRangeByCarrierIdPaged({
      variables: {
      carrierId: props.carrierId
    }}
    )
  })

  return (
    <>
      <CarrierFreightRangeContext.Provider value={{
        pageSearch,
        setPageSearch,
        lastPage,
        setLastPage,
        pageSearchFreightRange,
        setPageSearchFreightRange,
        lastPageFreightRange,
        setLastPageFreightRange,
        formSearch,
        setFormSearch,
        getAllCarriers,
        dataCarriers,
        getCarrierById,
        dataCarrier,
        dataCarrierDeliveryType,
        createCarrier,
        updateCarrier,
        createFreightRange,
        updateFreightRange,
        getSellerByName,
        dataSellers,
        getSellerById,
        dataSellerById,
        requestFreightRangeByCarrierIdPaged,
        getFreightRangeByCarrierId,
        dataFreightRangePaged,
        modal,
        setModal,
        freightRangeSelected,
        setFreightRangeSelected,
        roleSellerWriteRead,
        roleSellerReadOnly,
        roleCarrierWriteRead,
        roleCarrierReadOnly,
        userSellerId,
        freightRangeFilterCalled,
        setFreightRangeFilterCalled,
        ...props
      }}>
        {children}
      </CarrierFreightRangeContext.Provider>

      {(
        loading ||
        loadingCreateCarrier ||
        loadingUpdateCarrier ||
        loadingGetCarrierById ||
        loadingFreightRangePaged ||
        loadingCreateFreightRange ||
        loadingUpdateFreightRange
       ) &&
       <Loading />
      }

      {modal}
    </>
  )
}

export function useCarrierFreightRangeContext() {
  const context = useContext(CarrierFreightRangeContext)
  return context
}