import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'ifoodshop-react-ui';
import LogoBackground from '@assets/icons/Combined Shape.svg';
import './seller-logo.scss';
import Loading from '@components/Loading';
import ModalSaveEdit from '../ModalSaveEdit';
import AuthorizationHeader from '@helpers/AuthorizationHeader';
import AlertModal from '@components/AlertModal';
import { api } from '@services/api';

let formData = new FormData();

const SellerLogo = ({ role, data, refetch }) => {
  const background = LogoBackground;
  const [logo, setLogo] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [validation, setValidation] = useState({});

  

  useEffect(() => {
    if (data) {
      setLogo(data.getSellerById.logo);
    }
  }, [data, refetch]);

  function handleFileChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      formData = new FormData();
      formData.append('logo', files[0]);
      let reader = new FileReader();

      reader.onloadstart = () => setLoading(true);

      reader.onload = (event) => {
        setImage(event.target.result);
        setLogo(event.target.result);
        setLoading(false);

        let image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          setValidation({
            width: this.width,
            height: this.height,
            size: files[0].size,
          });
        };
      };
      reader.readAsDataURL(files[0]);
    }
  }

  function handleImage() {
    if (data?.getSellerById.logo) return data.getSellerById.logo;
    else if (logo && !data.getSellerById.logo) return logo;
    else return background;
  }

  function handleConfirm(_, pass) {
    setModal(<Loading />);

    if (pass) {
      formData.append('password', btoa(pass));

      api.post(`/seller/upload-logo/${data.getSellerById.id}`, formData)
        .then((response) => {
          if (response.status !== 200) return response.json();
          return response;
        })
        .then((data) => {
          if (data && data.statusCode === 'NOT_ACCEPTABLE') {
            setModal(
              <AlertModal
                title="Atenção!"
                description="Senha atual incorreta!"
                titleButton="Ok, entendi!"
                setModal={setModal}
                actionButton={() => {
                  setModal(null);
                  formData.delete('password');
                }}
              />,
            );
            return;
          }
          if (data && data.status !== 200) {
            throw new Error();
          } else {
            setModal(
              <AlertModal
                title="Beleza!"
                description="Logo importado com sucesso!"
                titleButton="Ok, entendi!"
                setModal={setModal}
                actionButton={() => {
                  setModal(null);
                  setImage(null);
                }}
              />,
            );
            refetch();
          }
        })
        .catch(() => {
          setModal(
            <AlertModal
              title="Atenção!"
              description="Erro ao importar imagem!"
              titleButton="Ok, entendi!"
              setModal={setModal}
              actionButton={() => {
                setModal(null);
                setImage(null);
                setLogo(null);
              }}
            />,
          );
        });
    } else {
      setModal(null);
      setImage(null);
      setLogo(null);
    }
  }

  function handleSubmitImg(e) {
    e.preventDefault();

    if (logo) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <ModalSaveEdit handleConfirm={handleConfirm} />
        </Modal>,
      );
    }
    setImage(null);
  }

  return (
    <>
      {modal}
      <div className="seller-logo">
        <p className="seller-logo__subtitle">Logo</p>
        <p className="seller-logo__subtitle--description">
          Carregue a imagem do logotipo que deseja
        </p>
        {!image ? (
          <div className="logo-input">
            <div className={'seller-logo__preview'}>
              <img
                className={'seller-logo__preview--img'}
                src={handleImage()}
                alt="preview para logotipo do seller"
              />
            </div>
            <div className="logo-input__btn">
              <input
                disabled={!role}
                id="image-input"
                className="btn--white__input"
                type="file"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(e) => handleFileChange(e)}
              />
              <label
                className={role ? 'btn--white' : 'button--disabled'}
                htmlFor="image-input"
              >
                Escolher a imagem
              </label>
              <div className="logo-input__info">
                <span>
                  Formatos:
                  <span className="logo-input__desc">
                    JPG, JPEG, PNG e HEIC
                  </span>
                </span>
                <span>
                  Peso máximo: <span className="logo-input__desc">5 MB</span>
                </span>
                <span>
                  Tamanho mínimo:
                  <span className="logo-input__desc">130x120px</span>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="logo-input--selected">
              <div>
                {validation &&
                (validation.width < 130 ||
                  validation.height < 120 ||
                  validation.size > 5e6) ? (
                  <span className="size-alert">
                    Imagem fora das especificações estipuladas <br />
                    Peso máximo: 5MB <br />
                    Tamanho mínimo: 130x120px
                  </span>
                ) : (
                  <div className="seller-logo__preview">
                    <img
                      className={'seller-logo__preview--img'}
                      src={logo || background}
                      alt="preview para logotipo do seller"
                    />
                  </div>
                )}

                <div className="logo-input__btn">
                  <input
                    id="image-input2"
                    className="btn--white__input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <label className="link" htmlFor="image-input2">
                    Escolher outra imagem
                  </label>
                </div>
              </div>
              {validation &&
                validation.width >= 130 &&
                validation.height >= 120 &&
                validation.size <= 5e6 && (
                  <Button className="btn" onClick={(e) => handleSubmitImg(e)}>
                    Salvar
                  </Button>
                )}
            </div>
          </>
        )}
        {loading && <Loading />}
      </div>
    </>
  );
};

export default SellerLogo;
