import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_RUPTURE } from '../../../../graphql/order.mutations';

import { RuptureError } from '@helpers/RuptureHelper';

import ModalQuantity from '../ModalQuantity';
import ModalRuptureConfirm from '../ModalRuptureConfirm';
import ModalRuptureConfirmation from '../ModalRuptureConfirmation';
import ModalVariableWeight from '../ModalVariableWeight';

function Rupture({ item, items, setModal, modal, qtdProduct, qtdProductsOrder, type, setStepModal }) {
  const [confirm, setConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(null);
  const [disableEdit, setDisableEdit] = useState(false);
  const [update] = useMutation(UPDATE_RUPTURE, {
    onCompleted: data => {
      if (!data.updateRupture.errorCode) {
        setSuccess(true);
        setConfirm(true);
      } else {
        setSuccess(false);
        setConfirm(true);
        setErrorMessage(RuptureError(data.updateRupture.errorCode));
      }
    },
  });

  function onConfirm(data) {
    setDisableEdit(true);
    update({
      variables: {
        itemId: item.id,
        quantity: parseInt(data),
      },
    });
  }

  const showModalRupture = () => {
    if (confirm) {
      setStepModal('CONFIRM');
      return (
        <ModalRuptureConfirmation
          errorMessage={errorMessage}
          success={success}
          successFooterAlign="center"
          errorFooterAlign="center"
          setModal={() => setModal(null)}
        />
      );
    } else if (type === 'WEIGHT')
      return (
        <ModalVariableWeight
          item={item}
          setConfirm={setConfirm}
          setSuccess={setSuccess}
          setErrorMessage={setErrorMessage}
        />
      );
    else
      return item.quantity === 1 ? (
        <ModalRuptureConfirm
          onConfirm={data => onConfirm(0)}
          type="delete"
          success={success}
          item={item}
          items={items}
          disableEdit={disableEdit}
          qtdProduct={qtdProduct}
          qtdProductsOrder={qtdProductsOrder}
          modalRupture={modal}
          setModalRupture={setModal}
        />
      ) : (
        <ModalQuantity
          onConfirm={data => onConfirm(data)}
          item={item}
          items={items}
          disableEdit={disableEdit}
          qtdProduct={qtdProduct}
          qtdProductsOrder={qtdProductsOrder}
          modalRupture={modal}
          setModalRupture={setModal}
        />
      );
  };

  return <div>{showModalRupture()}</div>;
}

export default Rupture;
