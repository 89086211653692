import { Modal } from 'ifoodshop-react-ui';

import './loading.scss';

const Loading = () => {
  return (
    <Modal className="modal-loading">
      <div className="loading">
        <div className="loading__box">
          <div className="loading__animate"></div>
        </div>
      </div>
    </Modal>
  );
};

export default Loading;
