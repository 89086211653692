import { ThemeContext } from 'styled-components';
import { Card, Flex, Heading3, Text } from '@ifood/pomodoro-components';
import { useContext } from 'react';
import { useSellerDetailsData } from '../../SellerDetailsHook';

const InactivationReasonDetails = (): JSX.Element => {
  const { sellerResponse } = useSellerDetailsData();
  const theme = useContext(ThemeContext);

  return (
    <Card>
      <Flex flexDirection="column">
        <Heading3 mb={24} mt={4} variant="smallest">
          Fornecedor inativado
        </Heading3>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Motivo de inativação
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.inactivationReason?.reason}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Observação
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.inactivationReason?.observation}
        </Text>
      </Flex>
    </Card>
  );
};

export default InactivationReasonDetails;
