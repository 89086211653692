import gql from 'graphql-tag';

const GET_SPECIAL_PRICE = gql`
query getOfferPricesByOfferId($id: ID!){
  getOfferPricesByOfferId(offerId: $id){
    id
    offerId
    price
    active
    commercialConditionId
    startDate
    endDate
    minQuantity
  }
}
`;

export { GET_SPECIAL_PRICE };