import gql from 'graphql-tag';

const GET_COST_CENTER = gql `
  query getAllCostCenters($name: String){
      getAllCostCenters(searchTerm: $name){
        id
        name
        code
        active
      }
  }
`;

export {GET_COST_CENTER};