import EditForm from './components/EditForm';
import * as S from './SellerEdit.styles';

const SellerEdit = (): JSX.Element => {
  return (
    <S.Wrapper>
      <EditForm />
    </S.Wrapper>
  );
};

export default SellerEdit;
