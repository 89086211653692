import React, { createContext, useContext, useState } from 'react';
const Context = createContext();

export function OrderProvider({ children, ...props }) {

    const [stepVehicle, setStepVehicle] = useState(0)

    return <Context.Provider value={{stepVehicle, setStepVehicle, ...props }}>{children}</Context.Provider>;
}

export function useOrderContext() {
    const context = useContext(Context);
    return context;
}