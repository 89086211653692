/* istanbul ignore file */
import AuthorizationHeader from '@helpers/AuthorizationHeader';

const fetchApi = (url: string, options?: RequestInit) => {
  const ENDPOINT_BASE = window?.config?.AdminAPI || 'https://shop.ifood-devel.com.br/admin-api/api';
  return fetch(`${ENDPOINT_BASE}/${url}`, {
    ...options,
    headers: {
      authorization: AuthorizationHeader.getHeaderToken(),
      ...options?.headers,
    },
  });
};

export const api = {
  get: (url: string, options?: RequestInit) => fetchApi(url, { method: 'GET', ...options }),
  post: (url: string, payload?: any, options?: RequestInit) =>
    fetchApi(url, { method: 'POST', body: payload, ...options }),
  put: (url: string, payload?: any, options?: RequestInit) =>
    fetchApi(url, { method: 'PUT', body: payload, ...options }),
  delete: (url: string, options?: RequestInit) => fetchApi(url, { method: 'DELETE', ...options }),
};
