import { Typography, Button } from "ifoodshop-react-ui";

import { useOrderContext } from '@components/_context/OrderContext'
import Arrow from "@components/Arrow";

import "./requestModal.scss";

const ChangeOrderStatus = () => {

  const {setStepVehicle} = useOrderContext();

  return (
    <div className="request-vehicle">
      <div className="request-vehicle__header">
        <div>
          <Typography size="24px" line="32px" weight="regular">
            Pedido Faturado
          </Typography>
        </div>
        <Arrow />
        <div>
          <Typography size="24px" line="32px" weight="regular">
            Veículo solicitado
          </Typography>
        </div>
      </div>
      <div className="request-vehicle__order">
        <div className="request-vehicle__order-first">
          <Typography size="16px" weight="thin" color="heavygray">
            Este pedido foi realizado com a modalidade{" "}
          </Typography>
          <Typography size="16px" weight="regular" color="heavygray">
            Logística iFood. <br />
          </Typography>
          <Typography size="16px" weight="thin" color="heavygray">
            Isso significa que o restaurante optou por receber este pedido{" "}
            <br />
            através de veículos da rede logística do iFood.
          </Typography>
        </div>
        <Typography size="16px" weight="thin" color="heavygray">
          Após o pedido faturado, você deve{" "}
        </Typography>
        <Typography size="16px" weight="regular" color="heavygray">
          solicitar um veículo{" "}
        </Typography>
        <Typography size="16px" weight="thin" color="heavygray">
          para <br />
          realizar a entrega para o restaurante.
        </Typography>
      </div>
      <div className="request-vehicle__next-right">
        <Button onClick={() => setStepVehicle(1)}>Avançar</Button>
      </div>
    </div>
  );
};

export default ChangeOrderStatus;
