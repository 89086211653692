/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { Divider, Icon } from '@ifood/pomodoro-components';

type Props = {
  alignItems: string;
};

const ContentBlock = styled.div<Props | any>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
`;

const DividerSection = styled(Divider)`
  border-bottom-color: ${({ theme }) => theme.colors.grayLight};
  border-width: 0 0 2px 0;
`;

const Form = styled.form`
  width: 100%;
  padding: 0 0.625rem 2.5rem 13.438rem;
`;

const ErrorDisclaimerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ErrorDisclaimerIcon = styled(Icon)`
  align-self: baseline;
  color: ${({ theme }) => theme.colors.error};
  fill: none;
  height: 16px;
  margin-left: 8px;
  width: 16px;
`;

const ErrorDisclaimerTextMessage = styled.span`
  font-family: iFood RC Textos, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
`;

export { ContentBlock, DividerSection, Form, ErrorDisclaimerWrap, ErrorDisclaimerIcon, ErrorDisclaimerTextMessage };
