import Formatter from '@helpers/FormatHelper';
import { Box, Flex, Grid, Heading3, Text } from '@ifood/pomodoro-components';
import { BFFGetEstablishmentByIdQuery } from '@services/bff/GraphqlOperations';

interface EstablishmentDetailSectionProps {
  establishment: BFFGetEstablishmentByIdQuery['getEstablishmentById'];
}

export function EstablishmentDetailSection({ establishment }: EstablishmentDetailSectionProps) {
  const address = establishment?.address;

  return (
    <>
      <Grid gridGap={30} gridTemplateColumns="1fr 1fr">
        <Box mb={20}>
          <Heading3 m={'0 0 12px'} variant="small">
            Dados da unidade
          </Heading3>

          <Flex alignItems="baseline" mb={4}>
            <Text fontWeight="bold" mr="5px" variant="medium">
              ID:
            </Text>
            {establishment?.id && <Text variant="medium">{establishment?.id}</Text>}
          </Flex>

          <Flex alignItems="baseline" mb={4}>
            <Text fontWeight="bold" mr="5px" variant="medium">
              Razão social:
            </Text>
            {establishment?.companyName && (
              <Box flex="1">
                <Text variant="medium">{establishment?.companyName}</Text>
              </Box>
            )}
          </Flex>

          <Flex alignItems="baseline" mb={4}>
            <Text fontWeight="bold" mr="5px" variant="medium">
              CNPJ:
            </Text>
            {establishment?.document && <Text variant="medium">{Formatter.document(establishment?.document)}</Text>}
          </Flex>

          <Flex alignItems="baseline" mb={4}>
            <Text fontWeight="bold" mr="5px" variant="medium">
              Inscrição estadual:
            </Text>
            {establishment?.stateRegistration && <Text variant="medium">{establishment?.stateRegistration}</Text>}
          </Flex>

          <Flex alignItems="baseline" mb={4}>
            <Text fontWeight="bold" mr="5px" variant="medium">
              Inscrição municipal:
            </Text>
            <Text variant="medium">{establishment?.municipalRegistration}</Text>
          </Flex>
          <Flex alignItems="baseline" mb={4}>
            <Text fontWeight="bold" mr="5px" variant="medium">
              Telefone:
            </Text>
            {(establishment?.phoneDialCode || establishment?.phone) && (
              <Text variant="medium">
                {establishment?.phoneDialCode ? `(${establishment?.phoneDialCode}) ` : ''}
                {establishment?.phone}
              </Text>
            )}
          </Flex>
        </Box>
        <Box mb={32}>
          <Heading3 m={'0 0 12px'} variant="small">
            Endereço de entrega
          </Heading3>

          {establishment?.address?.neighborhood && (
            <Flex alignItems="baseline" mb={4}>
              <Text variant="medium">{establishment?.address?.neighborhood}</Text>
            </Flex>
          )}
          {(address?.address || address?.number) && (
            <Flex alignItems="baseline" mb={4}>
              <Text variant="medium">{`${address?.address}, ${address?.number}`}</Text>
            </Flex>
          )}
          {(address?.city || address?.state) && (
            <Flex alignItems="baseline" mb={4}>
              <Text variant="medium">{`${address?.city} / ${address?.state}`}</Text>
            </Flex>
          )}

          <Flex alignItems="baseline" mb={4}>
            <Text fontWeight="bold" mr="5px" variant="medium">
              CEP:
            </Text>
            {address?.postalCode && <Text variant="medium">{Formatter.cep(address?.postalCode)}</Text>}
          </Flex>
        </Box>
      </Grid>
    </>
  );
}
