import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PARENTS, GET_CATEGORIES_TREE, GET_CATEGORY } from '../../../../graphql/category.queries';
import { GET_FIELD } from '../../../../graphql/field.queries';
import { UPDATE_FIELD } from '../../../../graphql/field.mutations';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Dropdown from 'react-dropdown';
import Toggle from 'react-toggle';

import 'react-dropdown/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';
import './formSpecification.scss';

const FormEditSpecification = ({ categoryId, fieldId }) => {
  const [specificationName, setSpecificationName] = useState(null);
  const optionsState = ['Habilitado', 'Desabilitado'];
  const optionsType = ['Produto', 'SKU'];
  const [state, setState] = useState(null);
  const [modal, setModal] = useState(null);
  const [filter, setFilter] = useState(null);
  const [order, setOrder] = useState(null);
  const [fieldTypeValue, setFieldTypeValue] = useState(null);
  const [type, setType] = useState('Produto');
  const [categoryName, setCategoryName] = useState(null);
  const [getCategories, { error: errorCategories }] = useLazyQuery(GET_PARENTS);
  const [getField, { error: errorField, data: dataField }] = useLazyQuery(GET_FIELD);
  const [updateField, { error, data }] = useMutation(UPDATE_FIELD);
  const [requiredOption, setRequiredOption] = useState(null);
  const [selectorType, setSelectorType] = useState(null);

  const [category, setCategory] = useState(null);
  const [getCategory, { data: dataCategory }] = useLazyQuery(GET_CATEGORY);

  useEffect(() => {
    getCategory({
      variables: {
        id: categoryId,
      },
    });
  }, []);

  useEffect(() => {
    getCategoryTree({
      variables: {
        name: categoryName || '',
      },
    });
  }, [categoryName]);

  if (dataCategory && category === null) {
    setCategory(dataCategory.getCategoryDetailById.category);
  }

  const [options, setOptions] = useState(null);
  const [categoryid, setCategoryid] = useState(null);

  const [getCategoryTree, { data: dataCategoryTree }] = useLazyQuery(GET_CATEGORIES_TREE);

  const mountOptions = (category, parentName = null) => {
    setOptions(options => [
      ...options,
      { id: category.id, name: parentName ? parentName + ' > ' + category.name : category.name },
    ]);
    if (category.subCategories) {
      category.subCategories.map(subCategory => {
        mountOptions(subCategory, parentName ? parentName + ' > ' + category.name : category.name);
      });
    }
  };

  useEffect(() => {
    setOptions([]);
    if (dataCategoryTree) {
      dataCategoryTree.getCategoriesTree.map(category => {
        mountOptions(category);
      });
    }
  }, [dataCategoryTree]);

  useEffect(() => {
    getField({ variables: { id: parseInt(fieldId) } });
    getCategories();
  }, []);

  if (dataField && specificationName == null) {
    setSpecificationName(dataField.getCustomField.name);
    setOrder(dataField.getCustomField.order);
    setType(dataField.getCustomField.type);
    setFieldTypeValue(
      dataField.getCustomField.fieldType.charAt(0).toUpperCase() +
        dataField.getCustomField.fieldType.slice(1).toLowerCase()
    );
    setFilter(dataField.getCustomField.isFilter);
    dataField.getCustomField.type === 'PRODUCT' ? setType('Produto') : setType('SKU');
    dataField.getCustomField.active ? setState('Habilitado') : setState('Desabilitado');
    setFilter(dataField.getCustomField.isFilter);
    setRequiredOption(dataField.getCustomField.required);
    setSelectorType(dataField.getCustomField.selector);
  }

  if (errorCategories);
  if (error && modal == null) {
    setModal(<Modal onCloseAction={() => setModal('')} title="Erro ao atualizar Especificação"></Modal>);
  }
  if (data && modal == null) {
    window.location.href = `/catalogos/categorias/detalhe/ ${categoryId}`;
  }

  const editSpecification = e => {
    e.preventDefault();
    updateField({
      variables: {
        id: parseInt(fieldId),
        name: specificationName,
        categoryId: parseInt(categoryId),
        active: state === 'Habilitado',
        isFilter: filter,
        order: parseInt(order),
        fieldValueType: fieldTypeValue.toUpperCase(),
        type: type == 'Produto' ? 'PRODUCT' : 'SKU',
        required: requiredOption,
        selector: selectorType,
      },
    });
  };

  return (
    <div>
      {modal}

      <form className="form-register form-register--specification" onSubmit={editSpecification}>
        <OutlinedInput
          placeholder="Nome da especificação"
          border
          value={specificationName}
          onChange={e => setSpecificationName(e.target.value)}
        />
        <OutlinedInput
          placeholder="Ordem"
          border
          value={order}
          type="number"
          onChange={e => setOrder(e.target.value)}
        />
        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsState}
            onChange={e => setState(e.value)}
            placeholder=""
            value={state}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Estado</label>
        </div>
        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsType}
            onChange={e => setType(e.value)}
            placeholder=""
            value={type}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Tipo</label>
        </div>

        <OutlinedInput placeholder="Tipo de campo" border disabled value={fieldTypeValue} />

        <div className="dropdown-label-wrap__category">
          <Autocomplete
            id="productCategory"
            freeSolo
            className="dropdown-ifood"
            options={options || []}
            value={category}
            getOptionLabel={option => option.name || ''}
            renderInput={params => <TextField {...params} label="Categoria" variant="outlined" />}
            onChange={(e, c) => {
              setCategoryid(c ? c.id : null);
              setCategoryName(c ? c.name : '');
            }}
            onInputChange={e => setCategoryName(e ? e.target.value : '')}
          />
        </div>

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Filtrar</span>
          <Toggle defaultChecked={filter} checked={filter} value={filter} onChange={v => setFilter(v.target.checked)} />
        </div>

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Seletor</span>
          <Toggle
            defaultChecked={selectorType}
            checked={selectorType}
            value={selectorType}
            onChange={v => setSelectorType(v.target.checked)}
          />
        </div>

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Obrigatório</span>
          <Toggle
            defaultChecked={requiredOption}
            checked={requiredOption}
            value={requiredOption}
            onChange={v => setRequiredOption(v.target.checked)}
          />
        </div>

        <Button className="btn-save" type="submit">
          Salvar especificação
        </Button>
      </form>
    </div>
  );
};

export default FormEditSpecification;
