import { Tooltip } from '@ifood/pomodoro-components';
import { NavLink } from 'react-router-dom';
import { EnumTableActionTitles, TableActionTypes } from '../../../interfaces/Discounts.interfaces';
import { getDiscountRules } from '../../../Search.utils';
import { actionURLs } from '../Table.utils';

interface IActionLinks {
  action: TableActionTypes;
  id: number;
  name?: TableActionTypes;
}

export default function ActionLinks({ action, id, name }: IActionLinks): JSX.Element {
  const renderLinkDisabled = (): JSX.Element => {
    return (
      <Tooltip
        className="link--disabled"
        content={`Você não tem permissão para ${EnumTableActionTitles[name || 'EDIT'].toLocaleLowerCase()} descontos.`}
      >
        {name ? EnumTableActionTitles[name] : id}
      </Tooltip>
    );
  };

  const renderActionLink = (): JSX.Element => {
    return getDiscountRules()?.roleWR ? (
      <NavLink className="link" to={actionURLs[action](id)}>
        {name ? EnumTableActionTitles[name] : id}
      </NavLink>
    ) : (
      renderLinkDisabled()
    );
  };

  return renderActionLink();
}
