import { NavLink } from 'react-router-dom'

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

function TableCarrierSearch() {
  const { dataCarriers } = useCarrierFreightRangeContext();

  return (
    <>
      <h3 className="section-title title-table-groups">Resultado do Filtro</h3>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">ID</th>
            <th className="table-admin__title">Nome</th>
            <th className="table-admin__title">Lojista</th>
            <th className="table-admin__title">Estado</th>
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {dataCarriers?.getAllCarriers?.content?.length 
            ? dataCarriers?.getAllCarriers?.content.map(item => (
                <tr>
                  <td className="table-admin__data">{item.id}</td>
                  <td className="table-admin__data">{item.name}</td>
                  <td className="table-admin__data">{item.seller.name}</td>
                  <td className="table-admin__data">{item.active ? 'Habilitado' : 'Desabilitado'}</td>
                  <td className="table-admin__data">
                    <NavLink className="link" to={`/fornecedores/transportadoras/editar/${item.id}`}>Abrir</NavLink>
                  </td>
                </tr>
            ))
            : null
          }
        </tbody>
      </table>
    </>
  );
}

export default TableCarrierSearch;
