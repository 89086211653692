import { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Modal, Button } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_CATEGORY, GET_CATEGORIES_BY_NAME, GET_CATEGORIES_TREE } from '../../../graphql/category.queries';
import { UPDATE_CATEGORY } from '../../../graphql/category.mutation';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import { categoryErrorMessage } from '@helpers/CategoryUtils';

import HeaderEditCategory from '../components/HeaderEditCategory';
import CustomFieldTable from '../components/TableCustomField';
import CategoryRankTable from '../components/TableCategoryRank';

import './editcategory.scss';

const CategoryEdit = props => {
  const history = useHistory();
  const roles = getRoles();
  const roleCategoryWriter = roles.includes(Roles.CATEGORY_WR);

  if (!roleCategoryWriter) history.push('/catalogos');

  const categoryId = parseInt(props.match.params.id);
  const optionsState = ['Habilitado', 'Desabilitado'];
  const [options, setOptions] = useState(null);
  const [modal, setModal] = useState(null);
  const [menuRankArray, setMenuRankArray] = useState([]);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryParentName, setCategoryParentName] = useState(null);
  const [categoryParentId, setCategoryParentId] = useState();
  const [state, setState] = useState(null);
  const [categories, setCategories] = useState(null);
  const [categoriesNames, setCategoriesNames] = useState([]);
  const [categoryCustomFields, setCategoryCustomFields] = useState([]);
  const [category, setCategory] = useState(null);
  const autoC = useRef(null);

  const [getCategory, { data: dataCategory }] = useLazyQuery(GET_CATEGORY);
  const [getCategories, { data: dataCategories }] = useLazyQuery(GET_CATEGORIES_BY_NAME);
  const [getCategoryTree, { data: dataCategoryTree }] = useLazyQuery(GET_CATEGORIES_TREE);
  const [getParentCategory, { data: dataParentCategory }] = useLazyQuery(GET_CATEGORY);

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    onCompleted: data => {
      let errorCode = data.updateCategory?.errorCode;
      if (errorCode) {
        changeModal(categoryErrorMessage(errorCode), 'Atenção');
        if (errorCode === 'CAT003') clearParentCategory();
      } else {
        window.location.href = '/catalogos/categorias';
      }
    },
    onError: error => {
      changeModal('Erro ao atualizar a categoria.', 'Atenção');
    },
  });

  const changeModal = (message, title) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  useEffect(() => {
    setOptions([]);
    getCategoryTree({
      variables: {
        name: categoryParentName || '',
      },
    });
  }, [categoryParentName, getCategoryTree]);

  const mountOptions = (category, parentName = null) => {
    setOptions(options => [
      ...options,
      {
        id: category.id,
        name: parentName ? parentName + ' > ' + category.name : category.name,
      },
    ]);
    if (category.subCategories) {
      category.subCategories.map(subCategory => {
        mountOptions(subCategory, parentName ? parentName + ' > ' + category.name : category.name);
      });
    }
  };

  useEffect(() => {
    setOptions([]);
    if (dataCategoryTree) {
      dataCategoryTree.getCategoriesTree.map(category => mountOptions(category));
    }
  }, [dataCategoryTree]);

  useEffect(() => {
    if (props?.match?.params?.id) {
      getCategory({
        variables: {
          id: parseInt(props.match.params.id),
          fieldsType: null,
        },
      });
    }

    getCategories({ variables: { name: '' } });
  }, [getCategories, getCategory, categoryId]);

  if (dataCategories && options == null) {
    setOptions(dataCategories.getCategoriesByName);
  }

  const editCategory = () => {
    updateCategory({
      variables: {
        id: parseInt(props.match.params.id),
        name: categoryName,
        active: state === 'Habilitado',
        parent: categoryParentId,
        menus: menuRankArray,
      },
    });
  };

  if (dataCategory && categoryName == null) {
    setCategoryName(dataCategory.getCategoryDetailById.category.name);
    setCategoryParentId(dataCategory.getCategoryDetailById.category.parentId);
    setMenuRankArray(getCategoryMenu(dataCategory.getCategoryDetailById.category.menus));
    dataCategory.getCategoryDetailById.category.active ? setState('Habilitado') : setState('Desabilitado');

    if (dataCategory.getCategoryDetailById.fields) {
      const fields = Object.values(dataCategory.getCategoryDetailById.fields);
      fields.forEach(function (entry) {
        entry.forEach(function (data) {
          setCategoryCustomFields(prevState => [...prevState, data]);
        });
      });
    }

    if (dataCategory?.getCategoryDetailById?.category?.parentId) {
      getParentCategory({
        variables: {
          id: parseInt(dataCategory.getCategoryDetailById.category.parentId),
          fieldsType: null,
        },
      });
    }
  }

  function getCategoryMenu(menus) {
    return menus.reduce(
      (acc, categoryMenu) => [
        ...acc,
        {
          id: categoryMenu.id,
          rank: categoryMenu.rank,
          menu: {
            id: categoryMenu.menu.id,
            name: categoryMenu.menu.name,
          },
        },
      ],
      []
    );
  }

  useEffect(() => {
    if (
      dataParentCategory &&
      dataParentCategory.getCategoryDetailById &&
      dataParentCategory.getCategoryDetailById.category
    ) {
      setCategory(dataParentCategory.getCategoryDetailById.category);
    }
  }, [dataParentCategory]);

  if (dataCategories && categories == null) {
    let names = [''];
    setCategories(dataCategories.getCategoriesByName);
    dataCategories.getCategoriesByName.map(category => {
      names.push(category.name);
      if (category.id == categoryParentId) {
        setCategoryParentName(category.name);
      }
    });
    setCategoriesNames(names);
  }

  useEffect(() => {
    if (dataCategories) {
      dataCategories.getCategoriesByName.map(category => {
        if (category.name === categoryParentName) setCategoryParentId(category.id);
      });
    }
  }, [categoryParentName, dataCategories]);

  function clearParentCategory() {
    setCategory('');
    setCategoryParentId(null);
    setCategoryParentName('');

    const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
  }

  const onChangeCategoryName = c => {
    if (c) {
      if (parseInt(props.match.params.id) !== c.id) {
        setCategoryParentId(c.id);
        setCategoryParentName(c.name);
      } else {
        clearParentCategory();
        changeModal('Categoria superior não pode ser a mesma categoria', 'Atenção');
      }
    } else {
      setCategoryParentId(null);
      setCategoryParentName('');
    }
  };

  return (
    <>
      <NavLink to="/catalogos/categorias" className="go-back" />
      <div className="content-body edit-category">
        {modal}
        <HeaderEditCategory title="Editar a categoria" description="Use os campos abaixo para editar a categoria" />
        <form className="form-edit form-edit--category" onSubmit={editCategory}>
          <OutlinedInput
            placeholder="Categoria"
            border
            value={categoryName || ''}
            onChange={e => setCategoryName(e.target.value)}
          />

          <div className="dropdown-label-wrap__category">
            <Autocomplete
              id="productCategory"
              className="dropdown-ifood"
              freeSolo
              value={category}
              ref={autoC}
              options={options || []}
              getOptionLabel={option => option.name || ''}
              renderInput={params => <TextField {...params} label="Categoria superior" variant="outlined" />}
              onChange={(event, newValue) => onChangeCategoryName(newValue)}
              onInputChange={e => setCategoryParentName(e ? e.target.value : '')}
            />
          </div>

          <div className="dropdown-label-wrap">
            <Dropdown
              options={optionsState}
              value={state}
              onChange={e => setState(e.value)}
              placeholder="Estado"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              categoryParentName
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Estado</label>
          </div>
        </form>

        {categoryCustomFields ? <CustomFieldTable customFields={categoryCustomFields} idCategory={categoryId} /> : null}

        <CategoryRankTable
          menuRankArray={menuRankArray}
          setMenuRankArray={setMenuRankArray}
          categoryName={categoryName}
          categoryId={categoryId}
        />

        <div className="edit-category__action-space">
          <Button className="btn-category" type="submit" onClick={editCategory}>
            Salvar edição
          </Button>
          <NavLink
            className="button btn--white"
            to={'/catalogos/categorias/detalhe/' + categoryId + '/especificacao/cadastro'}
          >
            {' '}
            Nova especificação
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default CategoryEdit;
