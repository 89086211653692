import './texteditor.scss';

import { Editor } from '@tinymce/tinymce-react';

const TinyEditor = props => {
  const readonly = () => {
    var retorno = props.readonly == 'disabled' ? true : false;
    return retorno;
  };

  const handleEditorChange = (content, editor) => {
    props.data(content);
  };

  return (
    <div className="html-editor">
      <Editor
        disabled={readonly()}
        apiKey={process.env.REACT_APP_TINY_MCE_KEY || window?.config?.TextEditorKey}
        value={props.initialValue}
        init={{
          height: 300,
          width: props.width ? props.width : 1000,
          menubar: false,
          placeholder: props.placeholder,
          plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern',
          ],

          toolbar1:
            'insertfile undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | image media | preview',
          image_advtab: true,
          media_advtab: true,
          media_live_embeds: true,

          video_template_callback: function (data) {
            return (
              '<video width="' +
              data.width +
              '" height="' +
              data.height +
              '"' +
              (data.poster ? ' poster="' + data.poster + '"' : '') +
              ' controls="controls">\n' +
              '<source src="' +
              data.source1 +
              '"' +
              (data.source1mime ? ' type="' + data.source1mime + '"' : '') +
              ' />\n' +
              (data.source2
                ? '<source src="' +
                  data.source2 +
                  '"' +
                  (data.source2mime ? ' type="' + data.source2mime + '"' : '') +
                  ' />\n'
                : '') +
              '</video>'
            );
          },

          file_picker_callback: function (callback, value, meta) {
            document.getElementById('upload').click();
            document.getElementById('upload').addEventListener('change', function () {
              var file = this.files[0];
              var reader = new FileReader();

              if (meta.filetype === 'media') {
                reader.onload = function (e) {
                  callback(e.target.result, {
                    alt: '',
                  });
                };
                reader.readAsDataURL(file);
              }
              if (meta.filetype === 'image') {
                reader.onload = function (e) {
                  callback(e.target.result, {
                    alt: 'teste',
                    source: e.target.result,
                  });
                };
                reader.readAsDataURL(file);
              }
            });
          },
        }}
        onEditorChange={handleEditorChange}
      />
    </div>
  );
};

export default TinyEditor;
