import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 40px 0 16px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
  }
`;
