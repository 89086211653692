import gql from 'graphql-tag';

const UPDATE_SINTEGRA_BYPASS = gql`
  mutation updateSintegrawsBypass($input: UpdateSintegrawsBypassInput) {
    updateSintegrawsBypass(input: $input) {
      success
      error
      message
    }
  }
`;

export { UPDATE_SINTEGRA_BYPASS };
