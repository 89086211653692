import { useState, useEffect } from 'react';
import { Button, Modal, Link } from 'ifoodshop-react-ui';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import OrderHelper from '@helpers/OrderHelper';
import FormatHelper from '@helpers/FormatHelper';
import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrderDetail } from '@analytics/aboyeur/entities/sessionOrderDetail';

import { useOrderContext } from '@components/_context/OrderContext';
import DeliveryHistory from '../DeliveryHistory';
import DeliveryItemTable from '../DeliveryItemTable';
import AlterStatus from '../AlterStatus';
import ModalWantCancelOrderReason from '../ModalWantCancelOrderReason';
import ModalWantCancelOrderConfirmation from '../ModalWantCancelOrderConfirmation';

import './orderChildDelivery.scss';

function OrderChildDelivery({ delivery, index, step, payments, orderChild }) {
  const [modal, setModal] = useState(null);
  const [phraseVariation, setPhraseVariation] = useState(false);
  
  const { original } = useOrderContext();
  const { subsidies } = orderChild?.subsidies;
  const isNewIntegratedSeller = orderChild?.seller?.isNewIntegration;

  const roles = getRoles();
  const roleCancelOrder = roles.includes(Roles.CANCEL_ORDER);
  const isSeller = roles.includes(Roles.SELLER_RO) || roles.includes(Roles.SELLER_WR);
  const isAbleToCancelOrder = (roleCancelOrder && delivery.step !== 'CANCELLED' && delivery.step !== 'WAITING_PAYMENT' && !isSeller);
  const rupturePaymentProcessed = roles.includes(Roles.RUPTURE_PAYMENT_PROCESSED);

  const orderStatus = [
    "DELIVERY_PENDING",
    "IN_TRANSPORTATION",
    "WAITING_PICKUP",
  ]
  
  const isLogisticsIfood = isSeller && delivery.ownerType === 'IFOOD' && orderStatus.includes(delivery.step);

  const sessionOrderDetail = useAboyeur(SessionOrderDetail);
  
  function handleUpdateStatus(step) {
    return step !== 'FINISHED' && step !== 'WAITING_PAYMENT' && step !== 'CANCELLED' && !original;
  }

  function handleAlterStatus() {
    setModal(
      <Modal
        className={
          delivery.step === 'BILLED' && delivery.ownerType === 'IFOOD' ? 'modal-vehicle' : 'modal-alter-status'
        }
        onCloseAction={() => setModal(null)}
      >
        <AlterStatus
          orderDeliveryId={delivery.id}
          deliveryType={delivery.type}
          deliveryStatus={delivery.step}
          phraseVariation={phraseVariation}
          ownerType={delivery.ownerType}
          setModal={() => setModal(null)}
        />
      </Modal>
    );

    sessionOrderDetail.events.order_detail.clickUpdateOrderStatus();
  }

  function openCancelConfirmationModal(step, success, orderChild) {
    setModal(
      <Modal
        onCloseAction={() => {
          setModal(null);
        }}
        title={null}
      >
        <ModalWantCancelOrderConfirmation success={success} setModalRupture={setModal} />
      </Modal>
    );
  }

  function handleCancelOrder() {
    setModal(
      <Modal className="modal-alter-status-reason" onCloseAction={() => setModal(null)}>
        <ModalWantCancelOrderReason
          items={delivery.items}
          modal={modal}
          setModalRupture={setModal}
          openModal={openCancelConfirmationModal}
          orderDeliveryId={delivery.id}
        />
      </Modal>
    );
  }

  useEffect(() => {
    payments.map(payment => {
      if (payment.type === 'POST_PAID_BANK_SLIP') {
        setPhraseVariation(true);
      }
    });
  }, []);

  return (
    <div className="order-detail__delivery">
      <div className="order-detail__delivery-info">
        <div className="order-detail__delivery-info-left">
          {delivery?.invoiceNumber && delivery?.invoiceIssuedDate && (
            <div className="display-flex order-detail__delivery-invoice">
              <h4 className="order-detail__delivery-title">Informações da Nota Fiscal</h4>
              <div className="order-detail__delivery-list">
                <p className="order-detail__delivery-item">
                  Número da NF:
                  <span className="order-detail__delivery-value">{delivery.invoiceNumber}</span>
                </p>
                <p className="order-detail__delivery-item">
                  Série da NF:
                  <span className="order-detail__delivery-value">{delivery.invoiceSeries}</span>
                </p>
                <p className="order-detail__delivery-item">
                  Emitida em :
                  <span className="order-detail__delivery-value">
                    {FormatHelper.format(delivery.invoiceIssuedDate, 'date')}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className="display-flex">
            <h4 className="order-detail__delivery-title">{`Entrega ${index + 1}: `}</h4>
            <div className="order-detail__delivery-list">
              <p className="order-detail__delivery-item">
                Status:
                <span className="order-detail__delivery-value">
                  {OrderHelper.handleOrderDeliveryStatus(delivery.step, phraseVariation)}
                </span>
              </p>
              {!isSeller && (
                <p className="order-detail__delivery-item">
                  Lojista:
                  <span className="order-detail__delivery-value">{orderChild?.seller?.name}</span>
                </p>
              )}
              <p className="order-detail__delivery-item">
                Nome do Entregador:
                <span className="order-detail__delivery-value">{delivery.name}</span>
              </p>
              {!isSeller && delivery.shippingTime && (
                <p className="order-detail__delivery-item">
                  Prazo de entrega:
                  <span className="order-detail__delivery-value">{delivery.shippingTime}</span>
                </p>
              )}
              {delivery.deliveryExpectedDate && (
                <p className="order-detail__delivery-item">
                  Previsão de entrega:
                  <span className="order-detail__delivery-value">
                    {FormatHelper.format(delivery.deliveryExpectedDate, 'simple-date')}
                  </span>
                </p>
              )}
              {delivery.deliveryDate && (
                <p className="order-detail__delivery-item">
                  Data da Entrega:
                  <span className="order-detail__delivery-value">
                    {FormatHelper.format(delivery.deliveryDate, 'simple-date')}
                  </span>
                </p>
              )}
              {delivery?.deliveryPeriods?.length > 0 && (
                <p className="order-detail__delivery-item">
                  Período de Entrega:
                  {delivery?.deliveryPeriods.map((item, index) => (
                    <span className="order-detail__delivery-periods">{`${index > 0 ? ' ou' : ''} ${item}`}</span>
                  ))}
                </p>
              )}
              {delivery?.laasQuote?.deliveryCode && (
                <p className="order-detail__delivery-item">
                  Código de entrega:
                  <span className="order-detail__delivery-value">{`${delivery.laasQuote.deliveryCode}`}</span>
                </p>
              )}
              {delivery?.laasQuote?.laasOrderId && (
                <p className="order-detail__delivery-item">
                  Código do pedido:
                  <span className="order-detail__delivery-value">{`${delivery.laasQuote.laasOrderId}`}</span>
                </p>
              )}
              {delivery?.collectorName && (
                <p className="order-detail__delivery-item">
                  Responsável pela retirada:
                  <span max="3" className="order-detail__delivery-value">
                    {`${delivery?.collectorName || ''}`}
                  </span>
                </p>
              )}
              {isAbleToCancelOrder && !isNewIntegratedSeller && (
                <Link onClick={handleCancelOrder} className="btn-cancel">
                  Cancelar pedido
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="order-detail__delivery-actions">
          {handleUpdateStatus(delivery.step) && (
            <>
              {!isLogisticsIfood && <Button onClick={() => handleAlterStatus()}>Alterar status do pedido</Button>}
            </>
          )}
        </div>
      </div>

      <DeliveryHistory delivery={delivery} payments={payments} />

      <DeliveryItemTable
        items={delivery.items}
        step={delivery.step}
        updateItems={delivery.updateItems}
        subsidies={subsidies}
        rupturePaymentProcessed={rupturePaymentProcessed}
      />

      {modal}
    </div>
  );
}

export default OrderChildDelivery;
