import { Link } from 'react-router-dom';
import {Link as LinkIfood} from 'ifoodshop-react-ui';
import Grid from '@material-ui/core/Grid';

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

import SelectLinks from '@components/SelectLinks';
import ModalImportFile from '@components/ModalImportFile';

import templateFreightRangeImport from '../../../../assets/files/templateFreightRange.csv';
import './style.scss';

function HeaderForm() {
  const { page, carrierId, isEdit, setModal } = useCarrierFreightRangeContext();

  function handleImport(e) {
    e.preventDefault()

    setModal(
      <ModalImportFile
        setModal={setModal}
        fileType="csv"
        urlImport="freight/value/import"
        title="Importação planilha de Faixa Frete"
        description="Aqui você pode fazer a importação da planilha de Faixa Frete."
      />
    )
  }

  return (
    <Grid container spacing={2} justify="space-between" className="header-form header-form--carrier">
      <Grid item xs={6}>
        <h2 className="section-title">Transportadoras</h2>
      </Grid>
      <Grid item xs={3} justify="flex-end">
        <SelectLinks disabled={(page === 'registerCarrier' || page === 'editCarrier')}>
          <Link className="item link" onClick={e => handleImport(e)}>Faixa de frete - Importação</Link>
          <LinkIfood className="item link" href={templateFreightRangeImport}>Faixa de frete - Modelo Importação</LinkIfood>
        </SelectLinks>
      </Grid>
      <Grid container className="container">
        <Grid item xs={12}>
          <Grid container className="header-form__nav">
            <Grid item xs={7}>
              <Link
                to={`/fornecedores/transportadoras/editar/${carrierId}`}
                className={`header-form__nav-item ${(page === 'registerCarrier' || page === 'editCarrier') ? 'active': ''}`}
              >
                {!isEdit
                  ? 'Cadastro de transportadora'
                  : 'Transportadoras'
                }
              </Link>
            </Grid>
            <Grid item xs={5}>
            <Link
              disabled={!isEdit && page !== 'registerFreightRange'}
              to={isEdit && `/fornecedores/transportadoras/editar/${carrierId}/faixa-frete`}
              className={`
                header-form__nav-item
                ${(
                    page === 'registerFreightRange' ||
                    page === 'editFreightRange' ||
                    page === 'listFreightRange'
                  )
                    ? 'active'
                    : ''
                }
              `}
            >
                {!isEdit
                  ? 'Cadastro de faixa frete'
                  : 'Faixa frete'
                }
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderForm;