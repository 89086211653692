/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { download } from '@helpers/Download';
import { Checkbox, Flex, Input, LinkText, Segment, Text } from '@ifood/pomodoro-components';
import { api } from '@services/api';
import { Dispatch, FormEventHandler, SetStateAction } from 'react';
import * as S from './FormCouponFields.styles';

export interface IFormCoupomFieldsProps {
  couponTypeValue: string;
  handleCouponType: FormEventHandler<HTMLDivElement>;
  couponInput: string;
  maxCouponsValue: string;
  handleMaxCoupon: (e: string) => void;
  isShowSiteCheckbox: boolean;
  handleIsShowSiteCheckbox: Dispatch<SetStateAction<boolean>>;
  couponsActive: number;
  handleGenerateCoupon: (e: any) => void;
  couponHasExist: boolean;
  couponBatchSizeField: number;
  discountId: number;
  handleCouponBatchSize: (e: number) => void;
  isCouponSaved: boolean;
  setCouponInput: (e: string) => void;
  maxCouponsUsage: number;
  handleMaxCouponsUsage: (e: number) => void;
}

const FormCouponFields = ({
  couponTypeValue,
  handleCouponType,
  couponInput,
  maxCouponsValue,
  handleMaxCoupon,
  isShowSiteCheckbox,
  handleIsShowSiteCheckbox,
  couponsActive,
  handleGenerateCoupon,
  couponHasExist,
  couponBatchSizeField,
  handleCouponBatchSize,
  discountId,
  isCouponSaved,
  setCouponInput,
  maxCouponsUsage,
  handleMaxCouponsUsage,
}: IFormCoupomFieldsProps): JSX.Element => {
  const shouldShowExporButton = discountId && couponTypeValue === 'batchCoupon';
  const KEY_CODE_UP = 38;
  const KEY_CODE_DOWN = 40;

  function couponInputSubtitleText(): string {
    if (couponTypeValue === 'unity') {
      return 'Crie um código personalizado, ex: PRIMEIRACOMPRA ou clique em "gerar código".';
    }
    return '';
  }

  async function onExportCoupom() {
    const response = await api.get(`discount/coupon-batch/${discountId}/export`);
    const blob = await response.blob();
    const date = new Date();
    const name = `Coupom-${date.toLocaleDateString()}-${date.toLocaleTimeString()}.csv`;

    download(blob, name);
  }

  function disableArrowKeys(event: { keyCode: number; preventDefault: () => void }) {
    if (event.keyCode === KEY_CODE_UP || event.keyCode === KEY_CODE_DOWN) {
      event.preventDefault();
    }
  }

  return (
    <Flex flexDirection="column" maxWidth={380}>
      <Segment.Group marginBottom="large" onChange={handleCouponType}>
        <Segment.Button
          checked={couponTypeValue === 'unity'}
          disabled={isCouponSaved && !!discountId}
          label="Unitário"
          name="couponTypeValue"
          value={'unity'}
        />
        <Segment.Button
          checked={couponTypeValue === 'batchCoupon'}
          disabled={isCouponSaved && !!discountId}
          label="Cupom em lote"
          name="couponTypeValue"
          value={'batchCoupon'}
        />
      </Segment.Group>

      {shouldShowExporButton && (
        <LinkText marginBottom={20} onClick={onExportCoupom}>
          Exportar códigos
        </LinkText>
      )}

      <Flex width={500}>
        <Input
          className={{ couponHasExist } && 'outlined-label-field--error'}
          defaultValue={couponInput}
          label={couponTypeValue === 'unity' ? 'Código do Cupom*' : 'Identificador*'}
          marginBottom="large"
          name="couponInput"
          supportText={couponInputSubtitleText()}
          value={couponInput}
          onChange={e => setCouponInput(e.target.value.toUpperCase())}
        />
        {couponTypeValue === 'unity' && (
          <Flex flexDirection="column" width={150}>
            <LinkText onClick={e => handleGenerateCoupon(e)}>Gerar cupom</LinkText>
            {couponHasExist && (
              <p className="form-add-customer--label-error form-add-customer--label-error--block">
                Este cupom já existe
              </p>
            )}
          </Flex>
        )}
      </Flex>
      {couponTypeValue === 'unity' && (
        <Checkbox.Button
          checked={isShowSiteCheckbox}
          id="show-site"
          label="Exibir no site"
          marginBottom="large"
          name="showOnSite"
          value={isShowSiteCheckbox?.toString()}
          onChange={v => handleIsShowSiteCheckbox(v.target.checked)}
        />
      )}
      <S.InputNumber
        label={couponTypeValue === 'unity' ? 'Limite de utilização por cliente' : 'Máximo de utilização*'}
        marginBottom="large"
        name="maxCouponsPerClient"
        type="number"
        value={maxCouponsValue}
        onChange={e => handleMaxCoupon(e.target.value)}
        onKeyDown={disableArrowKeys}
        onKeyUp={disableArrowKeys}
        onWheel={event => event.currentTarget.blur()}
      />
      {couponTypeValue === 'unity' ? (
        <>
          <S.InputNumber
            label="Limite de utilizações"
            marginBottom="large"
            name="maxCoupons"
            type="number"
            value={maxCouponsUsage}
            onChange={e => handleMaxCouponsUsage(Number(e.target.value))}
            onKeyDown={disableArrowKeys}
            onKeyUp={disableArrowKeys}
            onWheel={event => event.currentTarget.blur()}
          />
          {!!discountId && (
            <Text fontWeight="700" variant="medium">
              Utilizações disponíveis: {couponsActive}
            </Text>
          )}
        </>
      ) : (
        <S.InputNumber
          disabled={!!discountId}
          label="Quantidade de cupons*"
          marginBottom="large"
          name="couponBatchSize"
          type="number"
          value={couponBatchSizeField}
          onChange={e => handleCouponBatchSize(Number(e.target.value))}
          onKeyDown={disableArrowKeys}
          onKeyUp={disableArrowKeys}
          onWheel={event => event.currentTarget.blur()}
        />
      )}
    </Flex>
  );
};

export default FormCouponFields;
