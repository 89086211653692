import { Fragment } from 'react';
import './tableCustomer.scss';
import Checkbox from '@components/Checkbox';

const TableCustomer = ({ customers, onClick, role }) => {
  let customerData = customers;

  function handleClick(e) {
    onClick(e);
  }

  return (
    <Fragment>
      <table className="table-admin table-admin--customer-group">
        <thead>
          <tr>
            <th className="table-admin__title" />
            <th className="table-admin__title">ID</th>
            <th className="table-admin__title">Razão Social</th>
            <th className="table-admin__title">Email</th>
            <th className="table-admin__title">CPF / CNPJ</th>
          </tr>
        </thead>
        <tbody>
          {customerData?.map(customer => (
            <tr key={customer.id}>
              <td className="table-admin__data">
                {role && (
                  <Checkbox
                    name={customer.nome}
                    id={customer.id}
                    document={customer.document}
                    onClick={e => handleClick(e)}
                  />
                )}
              </td>
              <td className="table-admin__data">{customer.id}</td>
              <td className="table-admin__data">{customer.name}</td>
              <td className="table-admin__data">{customer.login}</td>
              <td className="table-admin__data">
                {customer.document &&
                  (customer.document.length > 11
                    ? customer.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                    : customer.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default TableCustomer;
