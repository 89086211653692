import styled from 'styled-components';
import iconArrow from '@assets/icons/icon-goback.svg';
import Grid from '@material-ui/core/Grid';

export const Wrapper = styled.section`
  width: 98vw;
`;
export const GoBackArrow = styled.button`
  position: absolute;
  top: 40px;
  left: 210px;
  background: url(${iconArrow});
  width: 10px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
`;

export const GridStyled = styled(Grid)``;
