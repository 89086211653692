/* istanbul ignore file */
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useGetBonusShopBalanceQuery } from '@services/bff/GraphqlHooks';
import { BFFBonusShopBalance, BFFBonusShopStatementContent } from '@services/bff/GraphqlTypes';
import { GET_BONUS_SHOP_STATEMENT } from './operations';

const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 3;
interface IGetBonusShopStatement {
  customerId: string;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
}

interface IUseStatementDetailCardHook {
  pageSize?: number;
}

export const useStatementDetailCardHook = ({ pageSize }: IUseStatementDetailCardHook = {}) => {
  const params = useParams<{ id: string }>();

  const {
    data: dataBonusShopBalance,
    loading: loadingBonusShopBalance,
    error: errorBonusShopBalance,
  } = useGetBonusShopBalanceQuery({
    variables: { customerId: params.id },
  });

  const {
    data: dataBonusShopStatement,
    loading: loadingBonusShopStatement,
    error: errorBonusShopStatement,
    refetch: refetchBonusShopStatement,
  } = useQuery<any, { input: IGetBonusShopStatement }>(GET_BONUS_SHOP_STATEMENT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        customerId: params.id,
        page: INITIAL_PAGE,
        size: pageSize || DEFAULT_PAGE_SIZE,
      },
    },
  });

  const bonusShopBalance = dataBonusShopBalance?.getBonusShopBalance as BFFBonusShopBalance[];
  const bonusShopStatementData: {
    content: BFFBonusShopStatementContent[];
    size: number;
    totalElements?: number;
    totalPages?: number;
    pageable: {
      pageNumber: number;
      pageSize: number;
      offset: number;
    };
  } = dataBonusShopStatement?.getBonusShopStatement;

  return {
    bonusShopBalance,
    loadingBonusShopBalance,
    errorBonusShopBalance,
    bonusShopStatementData,
    loadingBonusShopStatement,
    errorBonusShopStatement,
    refetchBonusShopStatement,
  };
};
