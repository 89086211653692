import React from 'react'
import './check.scss'

const Check = () => {

  return (
    <div className="check-component">
      <span className="right"></span>
      <span className="left"></span>
    </div>
  );
}

export default Check