import { useState, useMemo, useEffect } from 'react';
import { Link, Modal } from 'ifoodshop-react-ui';
import { Tooltip } from '@ifood/pomodoro-components';
import moment from 'moment';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import OrderHelper from '@helpers/OrderHelper';
import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrderDetail } from '@analytics/aboyeur/entities/sessionOrderDetail';
import { getFeatureFlag } from '@helpers/FeatureFlag';
import FormatHelper from '@helpers/FormatHelper';

import { useOrderContext } from '@components/_context/OrderContext';
import TooltipContent from '@components/TooltipContent';
import OrderChildDelivery from '../OrderChildDelivery';
import ModalWantCancelSellerOrder from '../ModalWantCancelSellerOrder';
import ModalWantCancelOrderReason from '../ModalWantCancelOrderReason';
import ModalWantCancelOrderConfirmation from '../ModalWantCancelOrderConfirmation';
import { STATUS_NOT_ALLOWED_TO_CANCEL } from './constants';

import '../../../../pages/Order/OrderDetail/orderDetail.scss';
import './orderChild.scss';

function OrderChild({ orderChild, index, isSeller, step, payments, qtdProductsOrder, item, items }) {
  const [modal, setModal] = useState(null);
  const roles = getRoles();
  const { original } = useOrderContext();
  const roleCancelOrder = roles.includes(Roles.CANCEL_ORDER);
  const [cancelButtonFlag, setCancelButtonFlag] = useState();
  //faster
  const sessionOrderDetail = useAboyeur(SessionOrderDetail);

  function handleOrderNumber(number) {
    const orderNumber = number.toString().padStart(4, '0');
    return `Pedido #${orderNumber}`;
  }

  function handleDiscountSeller() {
    return (
      orderChild?.itemsAmount +
      orderChild?.itemsDiscountAmount +
      orderChild?.shippingAmount +
      orderChild?.shippingDiscountAmount -
      orderChild?.sellerAmount
    );
  }

  function closeModal() {
    setModal(null);
    window.location.reload(false);
  }

  function openModal(step, success, orderChild) {
    // Faster
    if (step === 'WANT_CANCEL') {
      sessionOrderDetail.events.order_detail.clickButtonCancel({
        //Propriedades > Metadata
        childOrderId: orderChild.deliveries[0].id,
        totalValue: orderChild.itemsAmount,
      });
    } else if (step === 'WANT_REASON') {
      sessionOrderDetail.events.order_detail.clickButtonContinueCancel({
        childOrderId: orderDeliveryId,
      });
    }

    setModal(
      <Modal
        onCloseAction={() => {
          closeModal();
        }}
        title={step !== 'WANT_CONFIRM' ? 'Deseja cancelar esse pedido?' : null}
      >
        {RuptureModalSteps(step, success, orderChild)}
      </Modal>
    );
  }

  const createOrder = moment(orderChild.deliveries[0].items[0].createdAt);
  const toDay = moment().format('YYYY-MM-DD');
  const moreFourDays = moment(createOrder).add(3, 'days').format('YYYY-MM-DD');

  const myItems = orderChild.deliveries[0].items;
  const hasUpdateItems = myItems.filter(({ hasHistory }) => hasHistory);

  const shouldShowCancelButton = useMemo(
    () =>
      cancelButtonFlag
        ? roleCancelOrder &&
          !original &&
          orderChild.deliveries.every(delivery => !STATUS_NOT_ALLOWED_TO_CANCEL.includes(delivery.step))
        : toDay <= moreFourDays &&
          hasUpdateItems.length === 0 &&
          roleCancelOrder &&
          !original &&
          orderChild.deliveries.every(
            delivery => delivery.step === 'PAYMENT_ACCEPTED' || delivery.step === 'IN_STOCK_SEPARATION'
          ),
    [orderChild.deliveries, original, roleCancelOrder, cancelButtonFlag]
  );

  const orderDeliveryId = isSeller ? orderChild.deliveries[0].items[0].deliveryId : null;

  const RuptureModalSteps = (step, success) => {
    if (step === 'WANT_CANCEL')
      return (
        <ModalWantCancelSellerOrder
          items={items}
          item={item}
          modal={modal}
          openModal={openModal}
          orderChild={orderChild}
          qtdProductsOrder={qtdProductsOrder}
          modalRupture={modal}
          setModalRupture={setModal}
        />
      );
    else if (step === 'WANT_REASON') 
      return <ModalWantCancelOrderReason
              modal={modal}
              openModal={openModal}
              setModalRupture={setModal}
              orderDeliveryId={orderDeliveryId}
            />
    else if (step === 'WANT_CONFIRM')
      return <ModalWantCancelOrderConfirmation success={success} item={item} setModalRupture={setModal} />;
  };

  useEffect(async () => {
    const cancelButtonFeature = await getFeatureFlag('cancel_button');
    setCancelButtonFlag(cancelButtonFeature);
  }, []);

  return (
    <div className="order-detail__order">
      <div className="order-detail__info">
        <h3 className="order-detail__info-title">{handleOrderNumber(orderChild.id || index)}</h3>
        {isSeller && (
          <div className="order-detail__info-detail">
            {/* Seller / If Seller, show name */}
            <p className="order-detail__info-item">
              Status:
              <span className="order-detail__info-value">{OrderHelper.handleOrderDeliveryStatus(step)}</span>
            </p>
            <p className="order-detail__info-item">
              Lojista:
              <span className="order-detail__info-value">{orderChild.seller?.name}</span>
            </p>
            <p className="order-detail__info-item">
              Executivo:
              <span className="order-detail__info-value">{orderChild.owner}</span>
            </p>
            <p className="order-detail__info-item">
              Valor dos produtos:
              <span className="order-detail__info-value">
                {FormatHelper.format(orderChild.itemsAmount + orderChild.itemsDiscountAmount, 'currency')}
              </span>
            </p>
            <p className="order-detail__info-item">
              Frete:
              <span className="order-detail__info-value">
                {FormatHelper.format(orderChild.shippingAmount + orderChild.shippingDiscountAmount, 'currency')}
              </span>
            </p>
            <p className="order-detail__info-item">
              Total dos descontos:
              <span className="order-detail__info-value">
                {FormatHelper.format(handleDiscountSeller(), 'currency')}
              </span>
            </p>
            <p className="order-detail__info-item">
              Total pedido:
              <span className="order-detail__info-value">
                {FormatHelper.format(orderChild.sellerAmount, 'currency')}
              </span>
            </p>
            {isSeller && shouldShowCancelButton && (
              <div className="order-detail__info-link">
                <Tooltip
                  content={
                    <TooltipContent
                      title="Cancele pedidos"
                      content="Que tiveram alterações de maneira prática e ágil"
                    />
                  }
                  placement="right-end"
                  trigger="hover"
                >
                  <Link key={index} onClick={e => openModal('WANT_CANCEL', e, orderChild)}>
                    Cancelar pedido
                  </Link>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={`order-detail__deliveries ${isSeller && 'order-detail__deliveries-seller'}`}>
        {orderChild.deliveries.map((delivery, index) => (
          <OrderChildDelivery
            key={delivery.id}
            delivery={delivery}
            index={index}
            step={step}
            payments={payments}
            orderChild={orderChild}
          />
        ))}
      </div>
      {modal}
    </div>
  );
}

export default OrderChild;
