import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

const tableProd = props => {
  const roles = getRoles();
  const rolePrdWriter = roles.includes(Roles.PRODUCT_WR);
  const rolePrdReader = roles.includes(Roles.PRODUCT_RO);

  if (props.products.length > 0) {
    return (
      <Fragment>
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">ID</th>
              <th className="table-admin__title">SKU</th>
              <th className="table-admin__title">Nome</th>
              <th className="table-admin__title">Categoria</th>
              <th className="table-admin__title">Marca</th>
              <th className="table-admin__title">Ativo</th>
              <th className="table-admin__title"></th>
            </tr>
          </thead>
          <tbody>
            {props.products.map((prod, index) => (
              <tr id={index} key={index}>
                <td className="table-admin__data">{prod.id}</td>
                <td className="table-admin__data">{prod.referenceCode || ''}</td>
                <td className="table-admin__data">{prod.name}</td>
                <td className="table-admin__data">{prod.category.name}</td>
                <td className="table-admin__data">{prod.brand.name}</td>
                <td className="table-admin__data">{prod.active ? <a> Habilitado</a> : <a>Desabilitado</a>} </td>
                <td className="table-admin__data">
                  <NavLink
                    className={rolePrdReader || rolePrdWriter ? 'link' : 'link--disabled'}
                    to={rolePrdReader || rolePrdWriter ? `/catalogos/produtos/editar/${prod.id}` : '#'}
                  >
                    Editar
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  } else return null;
};

export default tableProd;
