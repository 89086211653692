import { useCallback } from 'react';
import { handleMaskSetValue } from '@helpers/mask';
import { Flex, Grid, Input, Select } from '@ifood/pomodoro-components';
import { BankFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useBankTypeOptions } from '@pages/Partners/Seller/hooks/bankTypeOptionsHook';

interface IBankDataFormFields {
  form: UseFormReturn<BankFormValueType, any>;
  bankFormData: BankFormValueType;
}

export default function BankDataFormFields({ form, bankFormData }: IBankDataFormFields) {
  const { control, setValue, register, formState } = form;
  const { errors } = formState;
  const { bankTypeOptions } = useBankTypeOptions();

  const options = [
    { name: 'Pessoa Física', value: 'Pessoa Física' },
    { name: 'Pessoa Juridica', value: 'Pessoa Juridica' },
  ];

  const handleBankName = useCallback(
    (value: string) => {
      if (bankFormData?.bankName === value)
        return {
          name: bankFormData?.bankName,
          value: bankFormData?.bankName,
        };
      return bankTypeOptions?.find((option: { name: string; value: string }) => option.value === value);
    },
    [bankFormData?.bankName, bankTypeOptions]
  );

  return (
    <Flex flexDirection="column">
      <Controller
        control={control}
        name="accountType"
        render={({ field: { onChange, value } }) => (
          <Select
            error={errors?.accountType?.message}
            items={options}
            label="Tipo de conta*"
            marginBottom="large"
            placeholder="Selecione o tipo de conta do fornecedor"
            value={options.find(o => o.name === value)}
            onChange={onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="bankName"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            error={error?.message}
            items={bankTypeOptions}
            label="Banco*"
            marginBottom="large"
            placeholder="Selecione o banco do fornecedor"
            value={handleBankName(value)}
            onChange={onChange}
          />
        )}
      />
      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Input
          {...register('agency')}
          error={errors?.agency?.message}
          label="Agência*"
          marginBottom="large"
          name="agency"
          onInput={e =>
            handleMaskSetValue({
              mask: '9999999',
              event: e,
              fieldName: 'agency',
              setValue,
            })
          }
        />
        <Input
          {...register('account')}
          error={errors?.account?.message}
          label="Conta corrente*"
          marginBottom="large"
          name="account"
        />
      </Grid>
    </Flex>
  );
}
