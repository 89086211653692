import { useEffect, useRef, useState } from 'react';
import * as S from './PasswordCriterea.styles';

type TCritereas = {
  [key: string]: { active: boolean; regex: RegExp };
};

interface IPasswordCriterea {
  input: string;
}

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*_-]).{8,}$/;

const PasswordCriterea = (props: IPasswordCriterea) => {
  const { input } = props;
  const [stateLoadedInputs, setStateLoadedInputs] = useState(false);
  const intervalInput = useRef<number | undefined>(undefined);
  let intervalArray: number[] = [];
  const [stateActive, setStateActive] = useState<TCritereas>({
    upper: { active: false, regex: /[A-Z]/ },
    lower: { active: false, regex: /[a-z]/ },
    number: { active: false, regex: /[0-9]/ },
    special: { active: false, regex: /[^\w\s]|_/ },
    minChar: { active: false, regex: /.{8,}/ },
  });
  useEffect(() => {
    if (stateLoadedInputs) {
      const delayLoaded = 1;
      setTimeout(() => {
        const inputElement = document.querySelector<HTMLInputElement>(input);
        if (inputElement) {
          inputElement.addEventListener('input', () => {
            const { value } = inputElement;
            const critereaArray = Object.entries(stateActive);
            const critereaObject: any = {};
            critereaArray.forEach(item => {
              const [itemKey, itemValue] = item;
              const { regex } = itemValue;
              critereaObject[itemKey] = {
                active: regex.test(value),
                regex,
              };
            });
            setStateActive(critereaObject);
          });
        }
      }, delayLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, stateLoadedInputs]);
  useEffect(() => {
    const delayInput = 10;
    const firstItemArray = 0;
    intervalInput.current = window.setInterval(() => {
      const inputs = document.querySelectorAll('input, select, text');
      const firstItem = intervalArray[firstItemArray];
      if (firstItem === inputs.length) {
        intervalArray.push(inputs.length);
      } else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intervalArray = [inputs.length];
      }
      if (intervalArray.length === delayInput) {
        setStateLoadedInputs(true);
        clearInterval(intervalInput.current);
      }
    }, delayInput);
  }, []);
  return (
    <S.StyleWrapper>
      <S.StyleItem active={stateActive.lower.active}>abc</S.StyleItem>
      <S.StyleItem active={stateActive.upper.active}>ABC</S.StyleItem>
      <S.StyleItem active={stateActive.number.active}>123</S.StyleItem>
      <S.StyleItem active={stateActive.special.active}>!@#</S.StyleItem>
      <S.StyleItem active={stateActive.minChar.active}>8+</S.StyleItem>
    </S.StyleWrapper>
  );
};

export default PasswordCriterea;
