import { useHistory } from 'react-router-dom';

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

function TableFreightRangeSearch() {
  const {dataFreightRangePaged, setFreightRangeSelected, carrierId, freightRangeFilterCalled} = useCarrierFreightRangeContext();
  const history = useHistory();

  function handleEditFreightRange(item) {
    setFreightRangeSelected(item);
    history.push(`/fornecedores/transportadoras/editar/${carrierId}/faixa-frete/${item.freightIdLegacy}`)
  }

  return (
    <>
    {freightRangeFilterCalled && <h3 className="section-title title-table-groups">Resultado do Filtro</h3>}
      <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">Região</th>
              <th className="table-admin__title">CEP Inicial</th>
              <th className="table-admin__title">CEP Final</th>
              <th className="table-admin__title"></th>
            </tr>
          </thead>
          <tbody>
            {dataFreightRangePaged && dataFreightRangePaged?.getAllFreightValueByCarrierIdPaged?.content?.some(item => item.carrierId)
              ? dataFreightRangePaged?.getAllFreightValueByCarrierIdPaged?.content?.map((item) => (
                  <tr key={item.id}>
                    <td className="table-admin__data">{item.region}</td>
                    <td className="table-admin__data">{item.initialPostalCode.toString().padStart(8, '0')}</td>
                    <td className="table-admin__data">{item.finalPostalCode.toString().padStart(8, '0')}</td>
                    <td className="table-admin__data">
                      <span
                        onClick={() => handleEditFreightRange(item)}
                        className="link"
                      >
                        Editar
                      </span>
                    </td>
                  </tr>
                ))

              : <tr>
                  <td className="table-admin__data table-admin__data--empty" colSpan="4">Nenhuma faixa de frete cadastrada</td>
                </tr>
            }
          </tbody>
        </table>
      </>
  );
}

export default TableFreightRangeSearch;

//