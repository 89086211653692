import React from 'react'
import { NavLink, useHistory } from "react-router-dom";
import FormRegisterDistributionAddress from '../components/FormRegisterDistributionAddress'
import Roles from '@helpers/Roles'
import { getRoles } from "@helpers/rolesUtils";

function DistributionAddressDetail(props) {
	const distributionAddressId = parseInt(props.match.params.id);
	const history = useHistory();
	const roles = getRoles();
	const roleDistributionWR = roles.includes(Roles.DISTRIBUTION_ADDRESS_WR);

	if (!roleDistributionWR) {
		history.push('');
	}

	return (
		<>
			{(roleDistributionWR) &&
				<>
					<NavLink to="/fornecedores/enderecos-distribuicao" className="go-back"/>

					{
						distributionAddressId &&
							<div className="content-body">
								<h2 className="section-title">Detalhes Centro de Distribuição</h2>
								<FormRegisterDistributionAddress id={distributionAddressId} />
							</div>
					}
				</>
			}
		</>
	);
}

export default DistributionAddressDetail;
