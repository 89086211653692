/* istanbul ignore file */
import { Button, Flex, snackbar } from '@ifood/pomodoro-components';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_SINTEGRA_BYPASS } from '@pages/Customers/bff/mutations/updateSintegraBypass';
import { UPDATE_PAYMENT_TYPES } from '@pages/Customers/bff/mutations/updatePaymentTypes';
import { GET_ESTABLISHMENT_BY_ID } from '@pages/Customers/bff/queries/getEstablishmentById';

interface IEstablishmentEdit {
  establishment: any;
  closeModal: () => void;
}

export default function EstablishmentEdit({ establishment, closeModal }: IEstablishmentEdit) {
  const { sintegrawsBypass, paymentTypes, establishmentId } = establishment;

  const onSuccessCallback = () => {
    snackbar({ message: 'Dados atualizados' });
    closeModal();
  };

  const onErrorCallback = () => {
    snackbar({ message: 'Erro ao salvar, tente novamente', variant: 'error' });
    closeModal();
  };

  const [updatePaymentTypesMutation, { loading: loadingUpdatePaymentTypes }] = useMutation(UPDATE_PAYMENT_TYPES, {
    onCompleted: () => onSuccessCallback(),
    onError: () => onErrorCallback(),
    refetchQueries: [{ query: GET_ESTABLISHMENT_BY_ID, variables: { establishmentId } }],
  });

  const [updateSintegraBypassMutation, { loading: loadingUpdateSintegraBypass }] = useMutation(UPDATE_SINTEGRA_BYPASS, {
    onCompleted: () => updatePaymentTypesMutation({ variables: { input: { paymentTypes, establishmentId } } }),
    onError: () => onErrorCallback(),
  });

  function handleUpdateEstablishment(e: { preventDefault: () => void }) {
    e.preventDefault();

    updateSintegraBypassMutation({
      variables: { input: { sintegraByPass: sintegrawsBypass, establishmentId } },
    });
  }

  return (
    <Flex>
      <Button variant="primary-inverted" onClick={closeModal}>
        Cancelar
      </Button>
      <Button isLoading={loadingUpdatePaymentTypes || loadingUpdateSintegraBypass} onClick={handleUpdateEstablishment}>
        Salvar
      </Button>
    </Flex>
  );
}
