import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionCatalog } from '@analytics/aboyeur/entities/sessionCatalog';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import TableProducts from '../components/TableProducts';
import FormSearchProduct from '../components/FormSearchProduct';

import IconArrow from '../../../assets/images/arrow.png';
import './productsearch.scss';

const ProductSearch = () => {
  const roles = getRoles();
  const roleProduct = roles.includes(Roles.PRODUCT_WR);
  const roleProductRO = roles.includes(Roles.PRODUCT_RO);

  const sessionCatalog = useAboyeur(SessionCatalog);

  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    sessionCatalog.events.product.viewAdminCatalogProduct();
  }, [sessionCatalog]);

  return (
    <>
      {modal}
      {(roleProductRO || roleProduct) && (
        <>
          <NavLink to="/catalogos" className="go-back" />
          <div className="content-body search-product">
            <div className="header-customer-group__actions">
              <FormSearchProduct data={e => setData(e)} currentpage={page} />
            </div>

            <div className="row">
              <div className="table-box">
                {data && data.content ? <TableProducts products={data.content} /> : <a> </a>}

                {data && data.totalPages > 1 ? (
                  <div className="search-brand-paginate">
                    <ReactPaginate
                      containerClassName="pagination-ifoodshop"
                      pageClassName="pagination-ifoodshop__item"
                      pageLinkClassName="pagination-ifoodshop__item-link"
                      activeLinkClassName="active"
                      previousLabel={<img className="previous-arrow" src={IconArrow} />}
                      nextLabel={<img className="next-arrow" src={IconArrow} />}
                      pageRangeDisplayed={3}
                      pageCount={data.totalPages}
                      marginPagesDisplayed={1}
                      initialPage={data.page}
                      forcepage={data.currentpage != page ? data.currentpage : page}
                      onPageChange={e => setPage(e.selected)}
                    />
                  </div>
                ) : (
                  <a> </a>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductSearch;
