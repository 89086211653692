/* istanbul ignore file */
import styled, { css } from 'styled-components';
import { Dialog, Heading3 } from '@ifood/pomodoro-components';

export const DialogWrapper = styled(Dialog)`
  width: 90%;
  max-width: inherit;
`;

export const Title = styled(Heading3)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.l};
    line-height: ${theme.lineHeights.l};
    margin-block: 0 32px;
  `}
`;
