import { ReactComponent as Alien } from '../../../assets/images/alien.svg';
import { Container } from './style';

export function FallbackSearch() {
  return (
    <Container data-testid={'fallback-container'}>
      <Alien data-testid={'fallback-container-image'} />
      <span className="title">Nenhum cliente encontrado</span>
      <span className="description">Verifique se as informações estão corretas.</span>
    </Container>
  );
}
