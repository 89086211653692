/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import FormatHelper from '@helpers/FormatHelper';
import * as S from './TableCustomerList.styles';

const TableCustomerList = ({ customers, variables, onSubmitFilters }: any) => {
  const customerListRender = (customer: any) => {
    return (
      <tr key={customer?.id} id={customer?.id}>
        <S.FirstTableContentItem className="table-admin__data">
          <a className="link" href={`/clientes/detalhe/${customer?.id}`}>
            {customer?.name}
          </a>
        </S.FirstTableContentItem>
        <S.TableContentItem className="table-admin__data">{customer?.id}</S.TableContentItem>
        <S.TableContentItem className="table-admin__data">
          {customer?.mobilePhoneDialCode && `(${customer?.mobilePhoneDialCode}) `}
          {customer?.mobilePhone && FormatHelper.format(customer?.mobilePhone, 'phone')}
        </S.TableContentItem>
        <td className="table-admin__data">{customer?.login}</td>
      </tr>
    );
  };
  return (
    <>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <S.TableHeadItem className="table-admin__title">Nome</S.TableHeadItem>
            <S.TableHeadItem className="table-admin__title">ID</S.TableHeadItem>
            <S.TableHeadItem className="table-admin__title">Contato</S.TableHeadItem>
            <th className="table-admin__title">E-mail</th>
          </tr>
        </thead>
        <tbody>
          {customers ? (
            customers?.map((customer: any) => <>{customerListRender(customer)}</>)
          ) : (
            <>Nenhum resultado encontrado.</>
          )}
        </tbody>
      </table>
    </>
  );
};

export default TableCustomerList;
