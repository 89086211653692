import * as yup from 'yup';
import { Customer, Establishment, ITableColum, LIST_OWNERS } from './index.d';

const listItemsSchema = yup.object().shape({
  id: yup.number().nullable(),
  variation: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .required(),
  seller: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .nullable(),
});

const customersSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  email: yup.string().nullable(),
  phoneDialCode: yup.string().nullable(),
  phone: yup.string().nullable(),
});

const establishmentsSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  address: yup
    .object()
    .shape({
      address: yup.string().nullable(),
      number: yup.string().nullable(),
      complement: yup.string().nullable(),
      neighborhood: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup.string().nullable(),
      country: yup.string().nullable(),
    })
    .required(),
  phoneDialCode: yup.string().nullable(),
  phone: yup.string().nullable(),
});

export const shoppingListSchema = yup.object().shape({
  id: yup.number().nullable(),
  name: yup.string().required('O nome é obrigatório'),
  status: yup.string().oneOf(['ENABLED', 'DISABLED']).required(),
  items: yup.array().of(listItemsSchema),
  rules: yup
    .object()
    .shape({
      id: yup.number().nullable(),
      customers: yup.array().of(customersSchema),
      establishments: yup.array().of(establishmentsSchema),
      cuisine: yup.string().nullable(),
    })
    .required(),
});

export const formTextsConfig = {
  insert: {
    headerTitle: 'Cadastro de Lista Sugerida',
    headerSubTitle: 'Use os campos abaixo para criar uma nova lista sugerida',
    submitBtnLabel: 'Criar lista sugerida',
    successFeedback: 'Lista cadastrada com sucesso',
    errorFeedback: 'Houve um erro ao cadastrar a lista, tente novamente mais tarde',
  },
  edit: {
    headerTitle: 'Edição de Lista Sugerida',
    headerSubTitle: 'Use os campos abaixo para editar uma lista sugerida',
    submitBtnLabel: 'Editar lista sugerida',
    successFeedback: 'Lista atualizada com sucesso',
    errorFeedback: 'Houve um erro ao atualizada a lista, tente novamente mais tarde',
  },
};

const customerTableColumns: ITableColum<Customer>[] = [
  {
    id: 1,
    name: 'id',
    label: 'ID',
    alignment: 'left',
    formatter: ({ id }: Customer) => id,
  },
  {
    id: 2,
    name: 'name',
    label: 'Cliente',
    alignment: 'left',
    formatter: ({ name }: Customer) => name,
  },
  {
    id: 3,
    name: 'email',
    label: 'Email',
    alignment: 'left',
    formatter: ({ email }: Customer) => (email ? email : 'Não informado'),
  },
  {
    id: 4,
    name: 'phone',
    label: 'Telefone',
    alignment: 'left',
    formatter: ({ phoneDialCode, phone }: Customer) =>
      !phone || !phoneDialCode ? 'Não informado' : `(${phoneDialCode}) ${phone}`,
  },
];

const establishmentTableColumns: ITableColum<Establishment>[] = [
  {
    id: 1,
    name: 'id',
    label: 'ID',
    alignment: 'left',
    formatter: ({ id }: Establishment) => id,
  },
  {
    id: 2,
    name: 'name',
    label: 'Estabelecimento',
    alignment: 'left',
    formatter: ({ name }: Establishment) => name,
  },
  {
    id: 3,
    name: 'address',
    label: 'Endereço',
    alignment: 'left',
    formatter: ({ address }: Establishment) =>
      `${address?.address}, ${address?.number}, ${address?.neighborhood}, ${address?.city}, ${address?.state}`,
  },
  {
    id: 4,
    name: 'phone',
    label: 'Telefone',
    alignment: 'left',
    formatter: ({ phoneDialCode, phone }: Establishment) =>
      !phone || !phoneDialCode ? 'Não informado' : `(${phoneDialCode}) ${phone}`,
  },
];

export const cleanPayload = (payloadWithTypeName: string) => {
  return JSON.parse(
    JSON.stringify(payloadWithTypeName, (name, val) => {
      if (name === '__typename') {
        delete val[name];
      } else {
        return val;
      }
    })
  );
};

export const LIST_OWNERS_CONFIG = {
  [LIST_OWNERS.CUSTOMER]: {
    field: 'rules.customers',
    label: 'ID, nome ou e-mail do cliente',
    ownerType: LIST_OWNERS.CUSTOMER,
    tableColumns: customerTableColumns,
  },
  [LIST_OWNERS.ESTABLISHMENT]: {
    field: 'rules.establishments',
    label: 'Nome do estabelecimento',
    ownerType: LIST_OWNERS.ESTABLISHMENT,
    tableColumns: establishmentTableColumns,
  },
};
