import React from 'react'
import HeaderRegisterGroup from '../components/HeaderRegisterGroup';
import FormEditGroup from './components/FormEditGroup/FormEditGroup';
import { NavLink } from 'react-router-dom';
import Decoder from '@helpers/Decoder'
import Roles from '@helpers/Roles'

const EditCustomerGroup = (props) => {
  const {id} = props.match.params

  var tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  var roleCustomer = tokenDecoded.resource_access["manager"].roles.includes(Roles.CUSTOMER_WR);
  var roleCustomerReadOnly = tokenDecoded.resource_access["manager"].roles.includes(Roles.CUSTOMER_RO);

  if (!roleCustomerReadOnly && !roleCustomer) {
    window.location.href = '/'
  }

  return(
    <>
      {(roleCustomerReadOnly || roleCustomer) &&
        <>
          {roleCustomer
            ?
              <>
                <NavLink to={"/clientes/grupos-clientes/detalhe/"+id} className="go-back" />
                <div className="content-body register-group">
                  <HeaderRegisterGroup
                    title="Editar grupo de clientes"
                    description="Use os campos abaixo para editar o grupo de cliente selecionado"
                  />
                  <FormEditGroup paramId={id}/>
                </div>
              </>
            :
              <>
                <a href={"/clientes/grupos-clientes/detalhe/"+id} className="go-back"></a>
                <div className="content-body register-group">
                  <HeaderRegisterGroup
                    title="Editar grupo de clientes"
                    description="Você não tem permissão para editar um grupo de clientes"
                  />
                </div>
              </>
          }
        </>
      }
    </>
  )
}

export default EditCustomerGroup
