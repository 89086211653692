import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getSellerId } from '@helpers/rolesUtils';
import ScrollToTop from '@helpers/ScrollToTop';
import { ThemeProvider } from '@material-ui/core';

import NavAside from './components/NavAside';

import GlobalStyle from '@styles/global';
import { theme } from './theme';
import BadWordsEdit from './pages/Catalogs/BadWordsEdit';
import BadWordsRegister from './pages/Catalogs/BadWordsRegister';
import BadWordsSearch from './pages/Catalogs/BadWordsSearch';
import { BetaHeader } from './components/Header/Beta';
import BrandEdit from './pages/Catalogs/BrandEdit';
import BrandRegister from './pages/Catalogs/BrandRegister';
import CarrierFreightRange from './pages/CarrierFreightRange';
import Categories from './pages/Catalogs/Categories';
import CollectionEdit from './pages/Campaign/Collection/CollectionEdit';
import CommercialConditionRegister from './pages/Campaign/ComercialCondition/CommercialConditionRegister';
import Commission from './pages/Partners/Comission/CommissionSearch';
import CommissionRegister from './pages/Partners/Comission/CommissionRegister';
import CostumerGroup from './pages/Customers/CostumerGroup';
import CostumerGroupDetail from './pages/Customers/CostumerGroup/components/CostumerGroupDetail';
import CustomerList from '@pages/Customers/CustomerList';
import CustomerDetail from '@pages/Customers/CustomerDetail';
import CreateOffer from './pages/Catalogs/CreateOffer';
import Dashboard from './pages/Partners/Dashboard';
import DataSeller from './pages/Partners/DataSeller';
import DiscountEdit from './pages/Campaign/Discount/DiscountEditOld';
import DiscountClone from './pages/Campaign/Discount/DiscountClone';
import DiscountRegister from '@pages/Campaign/Discount/DiscountRegister';
import DiscountSearch from './pages/Campaign/Discount/DiscountsSearch';
import { DiscountDetails } from '@pages/Campaign/Discount/DiscountDetails';
import DistributionAddressDetail from './pages/Partners/Distribution/DistributionAddressDetail';
import DistributionAddressRegister from './pages/Partners/Distribution/DistributionAddressRegister';
import DistributionAddressSearch from './pages/Partners/Distribution/DistributionAddressSearch';
import EditCategory from './pages/Catalogs/EditCategory';
import EditCustomerGroup from './pages/Customers/EditCustomerGroup';
import EditOffer from './pages/Catalogs/EditOffer';
import EditProductCategory from './pages/Catalogs/EditProductCategory';
import EditProductVariation from './pages/Catalogs/ProductVariationEdit';

import Home from './pages/Home/Home';
import HomeCampaign from './pages/Campaign/HomeCampaign';
import HomeCatalogs from './pages/Catalogs/HomeCatalogs';
import HomeCustomers from './pages/Customers/HomeCustomers';
import HomeSeller from './pages/Partners/Seller/HomeSeller';
import Logout from './pages/Logout';
import OrderDetail from './pages/Order/OrderDetail';
import OrderSearch from './pages/Order/OrderSearch';
import PriceMinimumRegister from './pages/Partners/PriceMinimum/PriceMinimumRegister';
import PriceMinimumSearch from './pages/Partners/PriceMinimum/PriceMinimumSearch';
import ProductEdit from './pages/Catalogs/ProductEdit';
import ProductSearch from './pages/Catalogs/ProductSearch';
import ProductSuggestion from './pages/Catalogs/ProductSuggestion';

import RegionRegister from './pages/Campaign/Region/RegionRegister';
import RegionSearch from './pages/Campaign/Region/RegionSearch';
import RegisterCategory from './pages/Catalogs/RegisterCategory';
import RegisterCustomerGroup from './pages/Customers/RegisterCustomerGroup';
import RegisterProduct from './pages/Catalogs/ProductRegister';
import RegisterProductVariation from './pages/Catalogs/ProductVariationRegister';

import SearchBrand from './pages/Catalogs/SearchBrand';
import SellersSearch from './pages/Partners/Seller/SellersSearch';
import SpecificationEdit from './pages/Catalogs/SpecificationEdit';
import SpecificationRegister from './pages/Catalogs/SpecificationRegister';
import SuggestSearch from './pages/Catalogs/SuggestSearch';
import SuggestionEdit from './pages/Catalogs/SuggestionEdit';
import SuggestionRegister from './pages/Catalogs/SuggestionRegister';
import UploadImage from './pages/Home/UploadImage';
import VoucherEdit from './pages/Campaign/Voucher/VoucherEdit';
import VoucherRegister from './pages/Campaign/Voucher/VoucherRegister';
import VoucherSearch from './pages/Campaign/Voucher/VoucherSearch';

import SellerRegistration from '@pages/Partners/Seller/SellerRegistration';
import SellerDetails from '@pages/Partners/Seller/SellerDetails';
import SellerEdit from '@pages/Partners/Seller/SellerEdit';
import CollectionRegister from '@pages/Campaign/Collection/CollectionRegister';
import CollectionSearch from '@pages/Campaign/Collection/CollectionSearch';
import CommercialConditionSearch from '@pages/Campaign/ComercialCondition/CommercialConditionSearch';
import ShoppingListSearch from '@pages/Catalogs/ShoppingListsSearch';
import ShoppingListDetail from '@pages/Catalogs/ShoppingListDetail';

const Routes = props => {
  let keycloak = props.keycloak;
  return (
    <BrowserRouter basename="/">
      <NavAside keycloak={keycloak} />
      {Cookies.get('adminShopBeta') === '1' && <BetaHeader />}
      <ScrollToTop>
        <Switch>
          <Route exact path="/logout" render={props => <Logout {...props} keycloak={keycloak} />} />

          <Route exact path="/" render={props => <Home {...props} keycloak={keycloak} />} />
          <Route exact path="/cadastrar-image" component={UploadImage} />

          <Route exact path="/clientes" component={HomeCustomers} />
          <Route exact path="/clientes/grupos-clientes" component={CostumerGroup} />
          <Route exact path="/clientes/grupos-clientes/detalhe/:id" component={CostumerGroupDetail} />
          <Route exact path="/clientes/grupos-clientes/cadastro" component={RegisterCustomerGroup} />
          <Route exact path="/clientes/grupos-clientes/editar/:id" component={EditCustomerGroup} />
          <Route exact path="/clientes/listagem-clientes" component={CustomerList} />
          <Route exact path="/clientes/detalhe/:id" component={CustomerDetail} />

          <Route exact path="/campanhas" component={HomeCampaign} />

          <Route exact path="/campanhas/regioes" component={RegionSearch} />
          <Route exact path="/campanhas/regioes/cadastro" component={RegionRegister} />
          <Route exact path="/campanhas/regioes/editar/:id" component={RegionRegister} />

          <Route exact path="/campanhas/condicao-comercial" component={CommercialConditionSearch} />
          <Route exact path="/campanhas/condicao-comercial/editar/:id" component={CommercialConditionRegister} />
          <Route exact path="/campanhas/condicao-comercial/cadastro" component={CommercialConditionRegister} />

          <Route exact path="/campanhas/descontos" component={DiscountSearch} />
          <Route
            exact
            path="/campanhas/descontos/cadastro"
            render={props => <DiscountRegister {...props} keycloak={keycloak} />}
          />
          <Route
            exact
            path="/campanhas/descontos/editar/:id"
            render={props => <DiscountEdit {...props} keycloak={keycloak} />}
          />
          <Route exact path="/campanhas/descontos/:id" component={DiscountDetails} />
          <Route
            exact
            path="/campanhas/descontos/duplicar/:cloneId"
            render={props => <DiscountClone {...props} keycloak={keycloak} />}
          />

          <Route exact path="/campanhas/vouchers" component={VoucherSearch} />
          <Route exact path="/campanhas/vouchers/cadastro" component={VoucherRegister} />
          <Route exact path="/campanhas/vouchers/editar/:id" component={VoucherEdit} />

          <Route exact path="/campanhas/colecoes" component={CollectionSearch} />
          <Route
            exact
            path="/campanhas/colecoes/cadastro"
            render={props => <CollectionRegister {...props} keycloak={keycloak} />}
          />
          <Route
            exact
            path="/campanhas/colecoes/editar/:id"
            render={props => <CollectionEdit {...props} keycloak={keycloak} />}
          />
          <Route
            exact
            path="/beta/:toogle"
            render={props => {
              if (props.match.params?.toogle === 'on') Cookies.set('adminShopBeta', 1);
              if (props.match.params?.toogle === 'off') Cookies.remove('adminShopBeta');
              window.location.href = '/';
            }}
          />
          <Route exact path="/pedidos" component={OrderSearch} />
          <Route exact path="/pedidos/detalhe/:id" component={OrderDetail} />

          <Route exact path="/fornecedores" component={HomeSeller} />
          <Route exact path={`/fornecedores/dashboard${getSellerId() ? '' : '/:id'}`} component={Dashboard} />
          <Route exact path="/fornecedores/detail" component={SellersSearch} />

          <Route exact path="/fornecedores/detalhe/:id" component={SellerDetails} />

          <Route exact path="/fornecedores/editar/:id" component={SellerEdit} />

          <Route exact path={`/fornecedores/meus-dados`} component={DataSeller} />

          <Route exact path={`/fornecedores/cadastro`} component={SellerRegistration} />
          <Route exact path="/fornecedores/enderecos-distribuicao" component={DistributionAddressSearch} />
          <Route exact path="/fornecedores/enderecos-distribuicao/cadastro" component={DistributionAddressRegister} />
          <Route exact path="/fornecedores/enderecos-distribuicao/detalhes/:id" component={DistributionAddressDetail} />
          <Route exact path="/fornecedores/:id/comissoes" component={Commission} />
          <Route exact path="/fornecedores/:id/comissoes/cadastro/:tipo" component={CommissionRegister} />

          <Route exact path="/fornecedores/pedido-minimo" component={PriceMinimumSearch} />
          <Route exact path="/fornecedores/pedido-minimo/cadastro" component={PriceMinimumRegister} />

          <Route
            exact
            path="/fornecedores/transportadoras"
            render={props => <CarrierFreightRange {...props} page="search" isEdit={false} />}
          />
          <Route
            exact
            path="/fornecedores/transportadoras/cadastro"
            render={props => <CarrierFreightRange {...props} page="registerCarrier" isEdit={false} />}
          />
          <Route
            exact
            path="/fornecedores/transportadoras/editar/:id"
            render={props => <CarrierFreightRange {...props} page="editCarrier" isEdit={true} />}
          />
          <Route
            exact
            path="/fornecedores/transportadoras/cadastro/:id/faixa-frete"
            render={props => <CarrierFreightRange {...props} page="registerFreightRange" isEdit={false} />}
          />
          <Route
            exact
            path="/fornecedores/transportadoras/editar/:id/faixa-frete"
            render={props => <CarrierFreightRange {...props} page="listFreightRange" isEdit={true} />}
          />
          <Route
            exact
            path="/fornecedores/transportadoras/editar/:id/faixa-frete/:freightId"
            render={props => <CarrierFreightRange {...props} page="editFreightRange" isEdit={true} />}
          />

          <Route exact path="/catalogos" component={HomeCatalogs} />
          <Route exact path="/catalogos/marcas" component={SearchBrand} />
          <Route exact path="/catalogos/marcas/cadastro" component={BrandRegister} />
          <Route exact path="/catalogos/marcas/editar/:id" component={BrandEdit} />

          <Route exact path="/catalogos/categorias" component={Categories} />
          <Route exact path="/catalogos/categorias/detalhe/:id" component={EditCategory} />
          <Route exact path="/catalogos/categorias/cadastro" component={RegisterCategory} />
          <Route exact path="/catalogos/categorias/editar/:id" component={EditProductCategory} />
          <Route
            exact
            path="/catalogos/categorias/detalhe/:id/especificacao/cadastro"
            component={SpecificationRegister}
          />
          <Route
            exact
            path="/catalogos/categorias/detalhe/:id/especificacao/editar/:id_cd"
            component={SpecificationEdit}
          />

          <Route exact path="/catalogos/produtos/" component={ProductSearch} />
          <Route exact path="/catalogos/produtos/cadastro" component={RegisterProduct} />
          <Route exact path="/catalogos/produtos/:productid/variacoes/cadastro" component={RegisterProductVariation} />
          <Route
            exact
            path="/catalogos/produtos/:productid/variacoes/editar/:variationid"
            component={EditProductVariation}
          />
          <Route exact path="/catalogos/produtos/editar/:id" component={ProductEdit} />
          <Route
            exact
            path="/catalogos/produtos/:productid/variacoes/:variationid/ofertas/criar"
            render={props => <CreateOffer {...props} keycloak={keycloak} />}
          />
          <Route
            exact
            path="/catalogos/produtos/:productid/variacoes/:variationid/ofertas/editar/:offerid"
            render={props => <EditOffer {...props} keycloak={keycloak} />}
          />
          <Route
            exact
            path="/catalogos/sugestao/cadastro/produto"
            render={props => <SuggestionRegister {...props} keycloak={keycloak} />}
          />
          <Route
            exact
            path="/catalogos/sugestao/cadastro"
            render={props => <ProductSuggestion {...props} page="identifyProduct" keycloak={keycloak} />}
          />
          <Route
            exact
            path="/catalogos/sugestao/cadastro/condicao"
            render={props => <ProductSuggestion {...props} page="saleCondition" keycloak={keycloak} />}
          />
          <Route
            exact
            path="/catalogos/sugestao/cadastro/resumo"
            render={props => <ProductSuggestion {...props} page="resumeSuggestion" keycloak={keycloak} />}
          />
          <Route
            exact
            path="/catalogos/sugestao/editar/:suggestionid"
            render={props => <SuggestionEdit {...props} keycloak={keycloak} />}
          />
          <Route exact path="/catalogos/sugestoes" component={SuggestSearch} />
          <Route exact path="/catalogos/badwords/cadastro" component={BadWordsRegister} />
          <Route exact path="/catalogos/badwords/editar/:id" component={BadWordsEdit} />
          <Route exact path="/catalogos/badwords/" component={BadWordsSearch} />

          <Route
            exact
            render={props => (
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <div className="page-wrapper">
                  <ShoppingListSearch {...props} keycloak={keycloak} />
                </div>
              </ThemeProvider>
            )}
            path="/catalogos/listas-sugeridas"
          />

          <Route
            exact
            render={props => (
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <div className="page-wrapper">
                  <ShoppingListDetail {...props} keycloak={keycloak} />
                </div>
              </ThemeProvider>
            )}
            path="/catalogos/listas-sugeridas/cadastrar"
          />

          <Route
            exact
            render={props => (
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <div className="page-wrapper">
                  <ShoppingListDetail {...props} />
                </div>
              </ThemeProvider>
            )}
            path="/catalogos/listas-sugeridas/editar/:id"
          />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;
