import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { useCarrierFreightRangeContext } from '../../../components/_context/CarrierFreightRangeContext'
import HeaderCarriersSearch from '../components/HeaderCarriersSearch';
import TableCarrierSearch from '../components/TableCarrierSearch';

import IconArrow from '../../../assets/images/arrow.png';

function Search() {
  const { 
    pageSearch, 
    setPageSearch, 
    lastPage, 
    getAllCarriers,
  } = useCarrierFreightRangeContext();  

  useEffect(() => {
    getAllCarriers();
  }, []);

  return (
    <>
      <NavLink to="/fornecedores" className="go-back" />
      <div className="content-body">
        <HeaderCarriersSearch />

        <div className="table-box">
          <TableCarrierSearch />

          <div className="search-customer-paginate">
            <ReactPaginate
              containerClassName="pagination-ifoodshop"
              pageClassName="pagination-ifoodshop__item"
              pageLinkClassName="pagination-ifoodshop__item-link"
              activeLinkClassName="active"
              previousLabel={
                <img className="previous-arrow" src={IconArrow} />
              }
              nextLabel={
                <img className="next-arrow" src={IconArrow} />
              }
              onPageChange={(e) => setPageSearch(e.selected)}
              pageRangeDisplayed={3}
              pageCount={lastPage}
              marginPagesDisplayed={1}
              initialPage={pageSearch}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;