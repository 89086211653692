const transformRuleType = (ruleType) => {
  switch (ruleType) {
    case 'TAXATION':
      return 'Tributação';
    case 'REGION':
      return 'Região';
    case 'KITCHEN_TYPE':
      return 'Tipo de cozinha';
  }
};

const transformRuleFilter = (ruleFilter) => {
  switch (ruleFilter) {
    case 'INCLUDE':
      return 'Inclusão';
    case 'EXCLUDE':
      return 'Exclusão';
    default:
      return 'Tipo';
  }
};

const textAutoComplete = (text) => {
  switch (text) {
    case 'TAXATION':
      return 'Busque uma tributação';
    case 'REGION':
      return 'Busque uma região';
    case 'KITCHEN_TYPE':
      return 'Busque um tipo de cozinha';
    default:
      return 'Selecione um tipo';
  }
};


export {
  transformRuleFilter,
  textAutoComplete,
  transformRuleType,
};
