import gql from 'graphql-tag';

const GET_ALL_DISCOUNTS = gql `
  query getAllDiscounts(
    $pageRequest: Int,
    $pageSize: Int,
    $valueSrcInput: String,
    $type: String,
    $highlight: String,
    $valid: Boolean,
    $active: Boolean,
    $couponCode: String
  ){
    getAllDiscounts(
      page: $pageRequest,
      size: $pageSize,
      name: $valueSrcInput,
      type: $type,
      highlight: $highlight,
      valid: $valid,
      active: $active,
      couponCode: $couponCode
    ){
      totalPages,
      content {
        id
        type
        name
        highlight
        active
        startDate
        endDate
        isCoupon
        couponCode
      }
      totalPages
    }
  }
`;

const GET_ALL_DISCOUNTS_V2 = gql `
  query getAllDiscountsV2(
    $pageRequest: Int,
    $pageSize: Int,
    $valueSrcInput: String,
    $type: String,
    $highlight: String,
    $valid: Boolean,
    $active: Boolean,
    $couponCode: String
  ){
    getAllDiscountsV2(
      page: $pageRequest,
      size: $pageSize,
      name: $valueSrcInput,
      type: $type,
      highlight: $highlight,
      valid: $valid,
      active: $active,
      couponCode: $couponCode
    ){
      
      content {
        id
        couponBatchSize
        type
        name
        highlight
        active
        startDate
        endDate
        isCoupon
        couponCode
      }
      totalElements,
      totalPages
    }
  }
`;

const GET_DISCOUNT_ID = gql `
  query getDiscount($idDiscount: ID!){
    getDiscount(discountId:$idDiscount){
      id
      name
      friendlyName
      type
      active
      startDate
      endDate
      highlight
      isCumulative
      campaignGroupUniqueDiscount
      campaignGroupId
      value
      minimumValue
      maxValue
      isCoupon
      couponCode
      couponMaxUsage
      customerMaxUsage
      couponUsage
      description
      rules {
        id
        type
        filter
        values {
          id
          name
        }

      }
      showSite
      newCustomer
      store
      subsidies {
        id
        active
        percentage
        type
        seller {
          id
          name
        }
        costCenter {
          id
          name
          code
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_DISCOUNT_ID_V2 = gql `
  query getDiscountV2($idDiscount: ID!){
    getDiscountV2(discountId:$idDiscount){
      id
      couponBatchSize,
      couponBatchOwnerId
      name
      friendlyName
      type
      active
      startDate
      endDate
      highlight
      isCumulative
      campaignGroupUniqueDiscount
      campaignGroupId
      value
      minimumValue
      maxValue
      isCoupon
      couponCode
      couponMaxUsage
      customerMaxUsage
      couponUsage
      description
      rules {
        id
        type
        filter
        values {
          id
          name
        }

      }
      showSite
      newCustomer
      store
      subsidies {
        id
        active
        percentage
        type
        seller {
          id
          name
        }
        costCenter {
          id
          name
          code
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

const CREATE_DISCOUNT = gql `
  mutation createDiscount(
    $name: String,
    $friendlyName: String,
    $type: String,
    $active: Boolean,
    $startDate: String,
    $endDate: String,
    $highlight: String,
    $isCumulative: Boolean,
    $value: Float,
    $minimumValue: Float,
    $maxValue: Float,
    $rules: [RuleInput],
    $showSite: Boolean,
    $newCustomer: Boolean,
    $store: String,
    $subsidies: [SubsidyInput],
    $isCoupon: Boolean,
    $couponCode: String,
    $couponMaxUsage: Int,
    $customerMaxUsage: Int,
    $userName: String!,
    $campaignGroupUniqueDiscount: Boolean,
    $campaignGroupId: Int,
    $description: String
  ){
    createDiscount(
      userName: $userName,
      discount: {
        name: $name,
        friendlyName: $friendlyName
        type: $type,
        active: $active,
        startDate: $startDate,
        endDate: $endDate,
        highlight: $highlight
        isCumulative: $isCumulative,
        value: $value,
        minimumValue: $minimumValue,
        maxValue: $maxValue,
        rules: $rules,
        showSite:$showSite,
        newCustomer:$newCustomer,
        store:$store,
        subsidies: $subsidies,
        isCoupon: $isCoupon,
        couponCode: $couponCode,
        couponMaxUsage: $couponMaxUsage,
        customerMaxUsage: $customerMaxUsage,
        campaignGroupUniqueDiscount: $campaignGroupUniqueDiscount,
        campaignGroupId: $campaignGroupId,
        description: $description
      }
    )
    {
      id
    }
}
`

const CREATE_DISCOUNT_V2 = gql `
  mutation createDiscountV2(
    $active: Boolean,
    $campaignGroupId: Int,
    $campaignGroupUniqueDiscount: Boolean,
    $couponBatchSize: Int,
    $couponBatchOwnerId: Int,
    $couponCode: String,
    $couponMaxUsage: Int,
    $customerMaxUsage: Int,
    $description: String,
    $endDate: String,
    $finalPostalCode: Int,
    $friendlyName: String,
    $highlight: String,
    $id: Int,
    $initialPostalCode: Int,
    $isCoupon: Boolean,
    $isCumulative: Boolean,
    $maxValue: Float,
    $minimumValue: Float,
    $name: String,
    $newCustomer: Boolean,
    $rules: [RuleInput],
    $showSite: Boolean,
    $startDate: String,
    $store: String,
    $subsidies: [SubsidyInput],
    $type: String,
    $value: Float,
    $userName: String!,
  ){
    createDiscountV2(
      userName: $userName,
      discount: {
        active: $active,
        campaignGroupId: $campaignGroupId,
        campaignGroupUniqueDiscount: $campaignGroupUniqueDiscount,
        couponBatchSize: $couponBatchSize,
        couponBatchOwnerId: $couponBatchOwnerId
        couponCode: $couponCode,
        couponMaxUsage: $couponMaxUsage,
        customerMaxUsage: $customerMaxUsage,
        description: $description
        endDate: $endDate,
        finalPostalCode: $finalPostalCode
        friendlyName: $friendlyName
        highlight: $highlight
        id: $id
        initialPostalCode: $initialPostalCode
        isCoupon: $isCoupon,
        isCumulative: $isCumulative,
        maxValue: $maxValue,
        minimumValue: $minimumValue,
        name: $name,
        newCustomer:$newCustomer,
        rules: $rules,
        showSite:$showSite,
        startDate: $startDate,
        store:$store,
        subsidies: $subsidies,
        type: $type,
        value: $value,
      }
    )
    {
      id
    }
}`

const UPDATE_DISCOUNT = gql `
  mutation updateDiscount(
    $id: Int,
    $name: String,
    $friendlyName: String,
    $type: String,
    $active: Boolean,
    $startDate: String,
    $endDate: String,
    $highlight: String,
    $isCumulative: Boolean,
    $value: Float,
    $minimumValue: Float,
    $maxValue: Float,
    $rules: [RuleInput],
    $showSite: Boolean,
    $newCustomer: Boolean,
    $store: String,
    $subsidies: [SubsidyInput],
    $isCoupon: Boolean,
    $couponCode: String,
    $couponMaxUsage: Int,
    $customerMaxUsage: Int,
    $userName: String!
    $campaignGroupUniqueDiscount: Boolean,
    $campaignGroupId: Int,
    $description: String
  ){
    updateDiscount(
      userName: $userName,
      discount: {
        id: $id,
        name: $name,
        friendlyName: $friendlyName,
        type: $type,
        active: $active,
        startDate: $startDate,
        endDate: $endDate,
        highlight: $highlight
        isCumulative: $isCumulative,
        value: $value,
        minimumValue: $minimumValue,
        maxValue: $maxValue,
        rules: $rules,
        showSite: $showSite
        subsidies: $subsidies,
        isCoupon: $isCoupon,
        newCustomer: $newCustomer,
        store: $store,
        couponCode: $couponCode,
        couponMaxUsage: $couponMaxUsage,
        customerMaxUsage: $customerMaxUsage,
        campaignGroupUniqueDiscount: $campaignGroupUniqueDiscount,
        campaignGroupId: $campaignGroupId,
        description: $description
      }
    )
  {
    id
  }
}
`
const UPDATE_DISCOUNT_V2 = gql `
  mutation updateDiscountV2(
    $active: Boolean,
    $couponBatchSize: Int
    $couponBatchOwnerId: Int
    $campaignGroupId: Int,
    $campaignGroupUniqueDiscount: Boolean,
    $couponCode: String,
    $couponMaxUsage: Int
    $customerMaxUsage: Int,
    $description: String
    $endDate: String,
    $friendlyName: String,
    $highlight: String,
    $id: Int,
    $isCoupon: Boolean,
    $isCumulative: Boolean,
    $maxValue: Float,
    $minimumValue: Float,
    $name: String,
    $newCustomer: Boolean,
    $rules: [RuleInput],
    $showSite: Boolean,
    $startDate: String,
    $store: String,
    $subsidies: [SubsidyInput],
    $type: String,
    $value: Float,
    $userName: String!
  ){
    updateDiscountV2(
      userName: $userName,
      discount: {
        active: $active,
        couponBatchSize: $couponBatchSize,
        couponBatchOwnerId: $couponBatchOwnerId,
        campaignGroupId: $campaignGroupId,
        campaignGroupUniqueDiscount: $campaignGroupUniqueDiscount,
        couponCode: $couponCode,
        couponMaxUsage: $couponMaxUsage,
        customerMaxUsage: $customerMaxUsage,
        description: $description
        endDate: $endDate,
        friendlyName: $friendlyName,
        highlight: $highlight
        id: $id,
        isCoupon: $isCoupon,
        isCumulative: $isCumulative,
        maxValue: $maxValue,
        minimumValue: $minimumValue,
        name: $name,
        newCustomer: $newCustomer,
        rules: $rules,
        showSite: $showSite
        startDate: $startDate,
        store: $store,
        subsidies: $subsidies,
        type: $type,
        value: $value,
      }
    )
  {
    id
  }
}
`
export {GET_ALL_DISCOUNTS, CREATE_DISCOUNT, UPDATE_DISCOUNT, GET_DISCOUNT_ID,  CREATE_DISCOUNT_V2, GET_DISCOUNT_ID_V2, GET_ALL_DISCOUNTS_V2, UPDATE_DISCOUNT_V2}
