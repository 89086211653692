import gql from 'graphql-tag';
const GET_BRANDS = gql `
          query GetAllBrands($page: Int, $size: Int, $brandName: String, $createdAt: String, $active: Boolean){
              getAllBrands(page: $page,  size: $size, brandName: $brandName, createdAt: $createdAt, active: $active){
                  content{
                      id
                      name
                      active
                  }
                  totalPages
                  size
              }
          }
`;
const GET_BRAND = gql `
      query GetBrand($id: ID!){
        getBrand(id: $id){
          name
          active
          createdAt
        }
      }
    `;
export {GET_BRANDS, GET_BRAND};