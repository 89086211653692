import { useEffect, useState } from 'react';
import { Button, Modal } from 'ifoodshop-react-ui';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import { useOrderContext } from '@components/_context/OrderContext';
import ModalWantCancelOrder from '../ModalWantCancelOrder';
import ModalWantCancelOrderReason from '../ModalWantCancelOrderReason';
import ModalWantCancelOrderConfirmation from '../ModalWantCancelOrderConfirmation';
import BounceLoader from '@components/Loader/Bounce';
import './ModalRupture.scss';

function ModalRuptureConfirm({ item, items, onConfirm, qtdProductsOrder, setModalRupture, orderChild, disableEdit }) {
  const { setModalTitle } = useOrderContext();
  const [quantity, setQuantity] = useState(item.quantity);
  const [modal, setModal] = useState(null);
  const [hasHistory, setHasHistory] = useState(false);
  const roles = getRoles();
  const roleCancelOrder = roles.includes(Roles.CANCEL_ORDER);

  let qtdProductActive = items.filter(item => item.quantity !== 0);
  const disableCancelOrder = qtdProductActive?.length === 1 && quantity === 1 && !roleCancelOrder;
  useEffect(() => {
    setModalTitle('Remover Produto');
  }, [setModalTitle]);

  useEffect(() => {
    if (items) {
      items.forEach(data => {
        if (data.hasHistory) {
          setHasHistory(true);
        } else {
          setHasHistory(false);
        }
      });
    }
  }, [items]);

  function closeModal() {
    setModal(null);
    setModalRupture(null);
    window.location.reload(false);
  }

  function openModal(step, success, orderChild) {
    setModal(
      <Modal
        onCloseAction={
          step === 'WANT_CONFIRM'
            ? () => {
                closeModal();
              }
            : setModal(null)
        }
        title={step !== 'WANT_CONFIRM' ? 'Deseja cancelar esse pedido?' : null}
      >
        {RuptureModalSteps(step, success, orderChild)}
      </Modal>
    );
  }

  const RuptureModalSteps = (step, success) => {
    if (step === 'WANT_CANCEL')
      return (
        <ModalWantCancelOrder
          items={items}
          item={item}
          qtdProductsOrder={qtdProductsOrder}
          modal={modal}
          setModalRupture={setModalRupture}
          openModal={openModal}
          orderChild={orderChild}
        />
      );
    else if (step === 'WANT_REASON')
      return (
        <ModalWantCancelOrderReason
          items={items}
          item={item}
          modal={modal}
          setModalRupture={setModalRupture}
          openModal={openModal}
          orderChild={orderChild}
        />
      );
    else if (step === 'WANT_CONFIRM')
      return <ModalWantCancelOrderConfirmation success={success} item={item} setModalRupture={setModalRupture} />;
  };

  function showMessageQtdUnit() {
    if (disableCancelOrder) {
      return (
        <p className="modal-content-quantity__description">
          Este é o último item do pedido e, por esse motivo, não pode ser removido.
        </p>
      );
    } else if (qtdProductActive.length === 1 && quantity === 1) {
      return (
        <>
          <p className="modal-content-confirm__description">
            Você tem certeza que gostaria de <strong>cancelar este pedido</strong>?
            <br />
            <br />
            <strong>Atenção:</strong> Caso queira remover todos os itens e cancelar o pedido, clique em Confirmar.
          </p>
        </>
      );
    } else {
      return (
        <p className="modal-content-confirm__description">
          Você tem certeza que gostaria de remover a quantidade deste produto?
          <br />
          <br />
          <strong>Atenção:</strong> Uma vez que a quantidade for removida, ela não poderá ser adicionada novamente.
        </p>
      );
    }
  }

  return (
    <div className="modal-content-confirm">
      <h2 className="modal-content-confirm__title">Remover produto</h2>
      {showMessageQtdUnit()}
      <Button
        disabled={disableCancelOrder || disableEdit}
        className={
          quantity === 0 && quantity === 1
            ? 'counter-button counter_minus--inactive'
            : 'counter-button counter_minus--active'
        }
        onClick={
          qtdProductActive.length === 1 && quantity === 1 && roleCancelOrder
            ? () => {
                openModal('WANT_CANCEL');
              }
            : () => {
                onConfirm(quantity);
              }
        }
      >
        {disableEdit ? <BounceLoader className="button-loader"/> : 'Confirmar'}
      </Button>
      {quantity === 1 ? modal : null}
    </div>
  );
}

export default ModalRuptureConfirm;
