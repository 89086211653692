import { Button, Modal } from 'ifoodshop-react-ui';

import './alertModal.scss';

const AlertModal = ({ title, description, titleButton, actionButton, setModal, className }) => {
  return (
    <div className={`modal-alert ${className ? className : ''}`}>
      <Modal onCloseAction={() => setModal(null)}>
        <div className="modal-alert--action">
          <p className="modal-alert__title">{title}</p>
          <span className="modal-alert__description">{description}</span>
          {titleButton && (
            <div className="modal--btn">
              <Button onClick={actionButton ? actionButton : () => setModal(null)}>{titleButton}</Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AlertModal;
