import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'ifoodshop-react-ui';

import IconArrow from '../../assets/images/arrow.png';

const TableDiscountRules = ({ removeRule, rules }) => {
  const [page, setPage] = useState(0);

  function clickRemoveRule(e, rule) {
    e.preventDefault();
    removeRule(rule);
  }

  function getCurrentPage() {
    let initialPageElement = 0
    let resultArray = [];

    if (rules.length > 10)
      initialPageElement = (page * 10);

    resultArray = rules.slice(initialPageElement, (initialPageElement + 10));

    if (resultArray.length) {
      return resultArray;
    } else {
      setPage(page - 1);
      return rules.slice((initialPageElement - 10), initialPageElement);
    }
  }

  return (
    <>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">Categoria</th>
            <th className="table-admin__title">Nome</th>
            <th className="table-admin__title">Tipo</th>
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {
            rules.length > 0 ?
              getCurrentPage().map(rule => {
                return (
                  <tr id={rule.id} key={rule.key}>
                    <td className="table-admin__data">{rule.category}</td>
                    <td className="table-admin__data">{rule.name}</td>
                    <td className="table-admin__data">{rule.filter}</td>
                    <td className="table-admin__data"><Link onClick={e => clickRemoveRule(e, rule.key)} >Remover</Link></td>
                  </tr>
                )
              })
              :
              <tr>
                <td></td>
                <td>não existe nenhuma regra</td>
              </tr>
          }
        </tbody>
      </table>
      {
        rules.length > 10 &&
        <div className="search-customer-paginate">
          <ReactPaginate
            key={page}
            containerClassName="pagination-ifoodshop"
            pageClassName="pagination-ifoodshop__item"
            pageLinkClassName="pagination-ifoodshop__item-link"
            activeLinkClassName="active"
            previousLabel={<img className="previous-arrow" src={IconArrow} />}
            nextLabel={<img className="next-arrow" src={IconArrow} />}
            onPageChange={(e) => setPage(e.selected)}
            pageRangeDisplayed={3}
            pageCount={rules.length / 10}
            marginPagesDisplayed={1}
            initialPage={page}
          />
        </div>
      }
    </>
  )
}

export default TableDiscountRules;