/* eslint-disable react-hooks/exhaustive-deps */
import ModalForm from '@components/ModalForm';
import { DatePicker, Radio, Label, Box } from '@ifood/pomodoro-components';
import { CustomerFraudReportType } from './schema';
import * as S from './CustomerFraudReportModal.styles';
import { useCustomerFraudModalsHook } from '../customerFraudModalsHook';
import { useEffect } from 'react';

interface IModalFormProps {
  isOpen: boolean;
  onNext: (type: CustomerFraudReportType) => void;
  onClose: () => void;
}

const CustomerFraudReportModal = ({ isOpen, onNext, onClose }: IModalFormProps): JSX.Element => {
  const { formReportFraud } = useCustomerFraudModalsHook();

  const {
    register,
    getValues,
    formState: { isValid },
  } = formReportFraud;

  useEffect(() => {
    if (!isOpen) {
      formReportFraud.reset();
    }
  }, [isOpen]);

  const handleClickFraud = () => {
    const getValuesData = getValues();
    onNext(getValuesData);
  };

  return (
    <ModalForm isOpen={isOpen} modalProps={{ size: '700px' }} title="Informe o motivo de fraude" onClose={onClose}>
      <Box mb={12}>
        <Label id="RADIO_FRAUD">Motivo*</Label>
      </Box>
      <S.RadioGroup aria-labelledby="relatar-fraudes-motivos" data-testid="fraud-report-reasons" mb={10} role="group">
        <Radio.Button
          {...register('reasons')}
          id={'REASONS_RADIO_FRAUD'}
          label={'Fraude por cupom'}
          mb={12}
          value={'COUPON_FRAUD'}
        />
        <Radio.Button
          {...register('reasons')}
          id={'REASONS_CHARGEBACK_FRAUD'}
          label={'Fraude por chargeback'}
          mb={12}
          ml={24}
          value={'CHARGEBACK_FRAUD'}
        />
      </S.RadioGroup>
      <DatePicker
        disabled
        label="Data do bloqueio"
        mb={24}
        mt={15}
        value={new Date().toLocaleDateString('pt-br')}
        width={'70%'}
      />
      <S.CustomTextArea
        {...register('note')}
        label="Motivo de inclusão de fraude"
        placeholder="Digite o motivo da inclusão de fraude"
      />
      <S.ButtonsRow alignItems="center" justifyContent="flex-end">
        <S.ButtonCancel name="close" variant="secondary" onClick={onClose}>
          Fechar
        </S.ButtonCancel>
        <S.ButtonSave disabled={!isValid} onClick={handleClickFraud}>
          Relatar fraude
        </S.ButtonSave>
      </S.ButtonsRow>
    </ModalForm>
  );
};

export default CustomerFraudReportModal;
