import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CANCEL_ORDER_DELIVERY } from '../../../../graphql/order-delivery.mutations';
import { SessionOrderDetail } from '@analytics/aboyeur/entities/sessionOrderDetail';
import { Grid } from '@material-ui/core';
import { Button } from 'ifoodshop-react-ui';
import { useAboyeur } from '@helpers/useAboyeur';
import Radio from '@components/Radio/Input';
import OutlinedTextarea from '@components/OutlinedTextarea';
import BounceLoader from '@components/Loader/Bounce';

import './ModalWantCancelOrderReason.scss';

const ModalWantCancelOrderReason = ({setModalRupture, modal, openModal, orderDeliveryId }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasOtherReason, setHasOtherReason] = useState(false);
  const otherReasonDescription = 'Outro motivo';
  const [otherReason, setOtherReason] = useState('');
  const [checkedSubReasons, setCheckedSubReasons] = useState('');

  const reasonChecked = () => {
    if (isChecked === otherReasonDescription) return otherReason.length === 0 ? otherReasonDescription : `${otherReasonDescription}: ${otherReason}`;
    else if (isChecked.hasSubReasons) return `${isChecked.title} - ${checkedSubReasons}`
    return isChecked.title

  }
  //faster
  const sessionOrderDetail = useAboyeur(SessionOrderDetail);

  const [cancelOrderDelivery] = useMutation(CANCEL_ORDER_DELIVERY, {
    onCompleted: data => {
      if (!data?.updateRupture?.errorCode) {
        openModal('WANT_CONFIRM', true);
      } else {
        openModal('WANT_CONFIRM', false);
      }
    },
  });

  const contentReasons = [
    { title: 'O CNPJ de compra está inapto para faturamento',
      hasSubReasons: true,
      subReasons: [
        'CNPJ Inapto',
        'Incidência de ST',
        'Outro'
      ]
    },
    {
      title: 'Ocorreu uma falha na entrega',
      hasSubReasons: true,
      subReasons:[
        'Restaurante fechado',
        'Endereço não localizado',
        'Problemas no veículo',
        'Extravio',
        'Outro'
      ]
    },
   { 
     title: 'Cliente desistiu da compra',
     hasSubReasons:false,
     subReasons:[],
   },
    {
      title: 'Cliente comprou errado',
      hasSubReasons:false,
      subReasons: []
    },
   { 
     title:'Endereço divergente',
     hasSubReasons: false,
     subReasons:[]
   },
    {
      title: 'Cliente recusou o recebimento',
      hasSubReasons: false,
      subReasons:[]
    },
   { 
     title: 'Área de risco',
     hasSubReasons: false,
     subReasons: []
    },
    {
      title: 'Ruptura de estoque',
      hasSubReasons:false,
      subReasons:[]
    },
    {
      title: 'Erro de anúncio',
      hasSubReasons: false,
      subReasons: []
    }
  ];

  const allReasons = contentReasons.map((reason, index) => (
  <>
    <Grid xs={12} key={index} className="containerReason__radios">
      <Grid item>
        <Radio
          id={index}
          className="containerReason__radios"
          checked={isChecked.title === reason.title}
          value={reason.title}
          onChange={() => setIsChecked(reason)}
          onClick={() => {setHasOtherReason(false),setCheckedSubReasons('')}}
        />
      </Grid>
      <Grid item xs={12} className="containerReason__reasons">
        <label htmlFor={index}>{reason.title}</label>
      </Grid>
    </Grid>
    {isChecked.hasSubReasons && isChecked.title === reason.title && 
      isChecked.subReasons.map((subReasons, index) => (
        <Grid xs={12}key={index}  className="containerReason__subReasons">
          <Grid item key={subReasons} >
            <Radio
              id={index}
              key={index}
              className="containerReason__radios"
              checked={checkedSubReasons === subReasons}
              value={subReasons}
              onChange={() => setCheckedSubReasons(subReasons)}
            />
          </Grid>
          <Grid item xs={12} className="containerReason__reasons">
            <label htmlFor={index}>{subReasons}</label>
          </Grid>
        </Grid>
      ))
    }
  </>
  ));

  async function handleSubmit(e) {
    e.preventDefault();
    const reason = reasonChecked();
    setIsLoading(true);
    // Faster
    sessionOrderDetail.events.order_detail.clickButtonCompleteCancel({
      childOrderId: orderDeliveryId,
      cancellationReason: reason,
    });
    await cancelOrderDelivery({
      variables: {
        orderDeliveryId,
        reason,
      },
    });
    setIsLoading(false);
  }

  const handleOtherReason = (e) => {
    const value = e.target.value
    setOtherReason(value)
    !value.trim() && setOtherReason(''); 
  }

  const disabledButton = () => {
    if (!isChecked || isLoading) return true
    else if (isChecked.hasSubReasons && checkedSubReasons.length === 0) return true
    return false
  }

  return (
    <div className="containerReason">
      <h2 className="containerReason__title">Informe o motivo da desistência do pedido:</h2>
      <form>
        <Grid container spacing={2} className="containerReason__containerRadios">
        <div className='containerReason__content'>
            {allReasons}
            <Grid xs={12} className="containerReason__radios">
              <Grid item>
                <Radio
                  id="outrosMotivos"
                  value={otherReasonDescription}
                  checked={isChecked === otherReasonDescription}
                  onChange={e => {
                    setIsChecked(e.target.value);
                  }}
                  onClick={() => {setHasOtherReason(true),setCheckedSubReasons('')}}
                />
              </Grid>
              <Grid item xs={12} className="containerReason__reasons">
                <label htmlFor="outrosMotivos">{otherReasonDescription}</label>
              </Grid>
            </Grid>
            {hasOtherReason && (
              <Grid xs={12} className="containerReason__textarea">
                <Grid item xs={12}>
                  <OutlinedTextarea
                    placeholder="Digite aqui o motivo do cancelamento"
                    value={otherReason}
                    maxLength={350}
                    onChange={e => handleOtherReason(e)}
                  />
                </Grid>
              </Grid>
            )}
        </div>
          <Grid xs={12} className="containerReason__modalbtns">
            <Button className="btn-secondary buttonOrderReason" onClick={() => setModalRupture(null)}>
              Fechar
            </Button>
            <Button disabled={disabledButton()} onClick={e => handleSubmit(e)} className="buttonOrderReason">
              {isLoading ? <BounceLoader /> : 'Quero cancelar'}
            </Button>
          </Grid>
        </Grid>
      </form>
      {modal}
    </div>
  );
};

export default ModalWantCancelOrderReason;
