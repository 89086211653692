/* istanbul ignore file */
import { snackbar, UploadableFile } from '@ifood/pomodoro-components';
import { pageSteps, SellerFormActions } from '../SellerFormType';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSellerFormContext } from '../context/SellerFormContext';
import { api } from '@services/api';
import { useSellerPayload } from '../hooks/sellerPayloadHook';
import { CreateSellerDataType } from './types';
import { useCreateSellerV2Mutation } from '@services/bff/GraphqlHooks';

export function useSellerRegistration() {
  const history = useHistory();
  const [isCreatingSeller, setIsCreatingSeller] = useState<boolean>(false);
  const { dispatch, state } = useSellerFormContext();
  const [createSeller] = useCreateSellerV2Mutation({
    onCompleted: () => {
      snackbar({
        message: 'Salvo com sucesso!',
        variant: 'success',
        timeout: 5000,
        autoClose: true,
      });
    },
    onError: onCreateError,
  });
  const { getCreateSellerPayload } = useSellerPayload();

  useEffect(() => {
    const stepperSpanContent = document?.querySelector('#stepper span') as HTMLElement | null;

    if (stepperSpanContent !== null) {
      stepperSpanContent.innerText = stepperSpanContent.innerText.replace('Step', 'Passo');
      stepperSpanContent.innerText = stepperSpanContent.innerText.replace('of', 'de');
    }
  }, [state.currentStep]);

  const dispatchTrigger = (data: pageSteps | object, triggerName: SellerFormActions) => {
    dispatch({
      type: triggerName,
      payload: data,
    });
  };

  async function onSubmitCreate(sellerData: CreateSellerDataType) {
    setIsCreatingSeller(true);

    const sellerV2 = getCreateSellerPayload(sellerData);
    const { data } = (await createSeller({ variables: { sellerV2 } })) || {};
    const sellerId = data?.createSellerV2?.id;

    if (!sellerId) {
      onCreateError();
      setIsCreatingSeller(false);
      return;
    }

    if (sellerData.registrationFormData.logo) {
      uploadLogo(sellerId, sellerData.registrationFormData.logo);
    } else {
      setIsCreatingSeller(false);
      redirectToDetails(sellerId);
    }
  }

  async function uploadLogo(sellerId: number, logo: UploadableFile) {
    if (logo?.file) {
      const formData = new FormData();
      formData.append('logo', logo.file);

      await api.post(`seller/logo/internal/${sellerId}`, formData);
    }

    setIsCreatingSeller(false);
    redirectToDetails(sellerId);
  }

  function redirectToDetails(id: number) {
    history.push({
      pathname: `/fornecedores/detalhe/${id}`,
      state: { success: true },
    });
  }

  function onCreateError() {
    setIsCreatingSeller(false);

    snackbar({
      message: 'Erro ao cadastrar seller, por favor tente novamente.',
      variant: 'error',
      timeout: 4000,
      autoClose: true,
    });
  }

  return {
    onSubmitCreate,
    dispatchTrigger,
    isCreatingSeller,
  };
}
