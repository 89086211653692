import React, { useState } from 'react';
import { Button, Modal } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Loading from '@components/Loading';
import TableAddProductVariation from './TableAddProductVariation';
import debounce from 'lodash.debounce';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_PRODUCTS } from '@graphql/product.queries';
import { GET_VARIATIONS_BY_PRODUCT } from '@graphql/variation.queries';
import DragProductVariation from './DragProductVariation';

function FormAddProductVariation(props) {
  const [allProducts, setAllProducts] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const [productId, setProductId] = useState(null);
  const [variationSelected, setVariationSelected] = useState(null);
  const [autocompleteVariationKey, setAutocompleteVariationKey] = useState(Math.random() * 10);
  const [dataProductVariation, setDataProductVariation] = useState([]);
  const [editFixedPrice, setEditFixedPrice] = useState(false);
  const [loadingVariations, setLoadingVariations] = useState(null);
  const [modal, setModal] = useState();

  const [getProducts, { loading: loadingProducts, data: dataProducts }] = useLazyQuery(GET_PRODUCTS);
  if (dataProducts && allProducts === null) {
    setAllProducts(dataProducts.getAllProducts.content);
  }

  props.dataAddVariation(dataProductVariation);

  if (props.productVariation.length > 0 && dataProductVariation.length < 1 && !editFixedPrice) {
    setDataProductVariation(props.productVariation);
    setEditFixedPrice(true);
  }

  function setSelectProduct(event, newValue) {
    if (newValue) {
      const aux = allProducts.find(item => item.name === newValue);
      const productSelectedID = aux ? parseInt(aux.id) : null;
      setProductSelected(newValue);
      if (productSelectedID)
        setProductId(productSelectedID);
    } else {
      if (document.getElementById('variationSearch')) {
        document.getElementById('variationSearch').value = "";
      };
      setVariationSelected(null);
      setProductSelected(null);
    }
  }

  const handleSearchProduct = debounce(e => {
    setAllProducts(null);
    getProducts({ variables: { productName: e } });
  }, 1000);

  function setSelectVariation(event, newValue) {
    setVariationSelected(newValue);
  }

  function addProductVariation(e) {
    e.preventDefault();
    let listData = dataProductVariation
    if (!props.dragContent) {

      if (productSelected && variationSelected) {
        if (!dataProductVariation.find(data => data.variation === variationSelected)) {
          listData.push({
            product: productSelected,
            variation: variationSelected,
            key: Math.random() * 10
          })
        } else {
          setModal(
            <Modal onCloseAction={() => setModal(null)}>
              Variação já selecionada
            </Modal>
          );
        };
      }

    } else {
      if (productSelected) {
        listData.push({
          product: productSelected,
          key: productId
        })
      }
    }
    setDataProductVariation(listData);
    if (props.dataAddVariation) {
      props.dataAddVariation(listData)
    }
    setAutocompleteVariationKey(Math.random() * 10);
  }

  function removeProductVariation(e) {
    setDataProductVariation(dataProductVariation.length > 1 ? dataProductVariation.filter(item => item.key !== e) : [])
  }

  function handleDrag(e) {
    setDataProductVariation(e)
  }

  return (
    <>
      <h2 className="section-title">{props.title}</h2>
      {props.description &&
        <p>{props.description}</p>
      }
      {
        !props.disabled &&
        <>
          {
            (props.productVariation.length === 0 || props.multipleProducts || props.dragContent) &&
            <div className="form-admin__group">
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="productSearch"
                  freeSolo
                  disabled={(dataProductVariation.length > 0 && !props.multipleProducts)}
                  onChange={(event, newValue) => setSelectProduct(event, newValue)}
                  className="autocomplete-ifood"
                  noOptionsText="Digite para procurar"
                  options={allProducts ? allProducts.map((option) => option.name) : []}
                  renderInput={(params) => <TextField {...params} onChange={(e) => handleSearchProduct(e.target.value)} label="Produto" variant="outlined" />}
                />
              </div>

              {(!props.dragContent) &&
                (productSelected) && <SelectProductVariation productId={productId} autocompleteVariationKey={autocompleteVariationKey} setSelectVariation={(e, newValue) => setSelectVariation(e, newValue)} setLoadingData={loading => setLoadingVariations(loading)}></SelectProductVariation>
              }

              <Button className="btn btn-secondary" onClick={e => addProductVariation(e)}>Adicionar</Button>
            </div>
          }
          <div className="form-admin__group">

            {dataProductVariation.length > 0
              ?
              props.dragContent
                ?
                <DragProductVariation data={dataProductVariation} onChange={e => handleDrag(e)} remove={e => removeProductVariation(e)} />
                :
                <TableAddProductVariation removeProductVariation={e => removeProductVariation(e)} productVariation={dataProductVariation} editFixedPrice={editFixedPrice} />
              :
              null
            }
          </div>
        </>
      }
      { (loadingVariations || loadingProducts) && <Loading />}
      {modal}
    </>
  )
}

function SelectProductVariation({ productId, autocompleteVariationKey, setSelectVariation, setLoadingData }) {
  let allVariation = [];

  const { loading, error, data } = useQuery(GET_VARIATIONS_BY_PRODUCT, { variables: { productId } });
  if (data)
    allVariation = data.getAllProductVariations.content;

  setLoadingData(loading);

  return (
    <div className="dropdown-label-wrap">
      <Autocomplete
        id="variationSearch"
        key={autocompleteVariationKey}
        disabled={!productId}
        freeSolo
        onChange={(event, newValue) => setSelectVariation(event, newValue)}
        className="autocomplete-ifood"
        noOptionsText="Digite para procurar"
        options={allVariation ? allVariation.map((option) => option.name ? `${option.id} - ${option.name}` : `${option.id} - variação sem nome`) : []}
        renderInput={(params) => <TextField {...params} label="Variaçao" variant="outlined" className={!productId && 'disabled'} />}
      />
    </div>
  );
}

export default FormAddProductVariation