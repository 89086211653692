import styled, { keyframes, css } from 'styled-components';

interface IStyleItem {
  active: boolean;
}

const animateActive = () => keyframes`
  0% { color: #717171; transform: scale(1); }
  60% { transform: scale(1.3); }
  100% { color: #27824C; transform: scale(1); }
`;

const animateInactive = () => keyframes`
  0% { color: #27824C; }
  100% { color: #717171; }
`;

const StyleWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  padding: 16px 16px 22px;
`;

const StyleItem = styled.div<IStyleItem>`
  width: 40px;
  text-align: center;
  font-size: 18px;
  border-bottom: 2px solid;
  border-bottom-color: ${({ active }) => (active ? '#27824C' : '#717171')};
  animation: ${({ active }) =>
    active
      ? css`
          ${animateActive()} 0.3s forwards
        `
      : css`
          ${animateInactive()} 0.3s forwards
        `};
`;

export { StyleWrapper, StyleItem };
