import React, { useState } from 'react';
import TableBrand from '../../../components/TableBrand';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import ptbr from 'date-fns/locale/pt-BR';
import ReactPaginate from 'react-paginate';
import Dropdown from 'react-dropdown';
import client from '../../../services/apollo';

import '../../../components/HeaderGroups/header-groups.scss'
import IconArrow from '../../../assets/images/arrow.png'
import './pagination.scss'
import './search.scss';
import 'react-dropdown/style.css';
import '../../../components/DropDownIfood/dropdown-ifood.scss';
import { GET_BRANDS } from '../../../graphql/brand.queries';
import { NavLink, useHistory } from "react-router-dom";
import Roles from "../../../helpers/Roles";
import { getRoles } from "../../../helpers/rolesUtils";

const SearchBrand = (props) => {

  const history = useHistory();
  const roles = getRoles();
  const roleBrandWriter = roles.includes(Roles.BRAND_WR);
  const roleBrandReader = roles.includes(Roles.BRAND_RO);

  if(!roleBrandReader && !roleBrandWriter)
    history.push('/catalogos')

  const [brands, setBrands] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [active, setActive] = useState('Habilitado');
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const PAGE_SIZE = 10;


  const onPageChange = (e) => {
    setPage(e.selected);
    handleRequest(e.selected)
  }
  const handleRequest = (p = page) => {
    client.query({
      query: GET_BRANDS,
      variables: {
        page: p,
        size: PAGE_SIZE,
        brandName: brandName,
        createdAt: createdAt ? createdAt.toISOString().split('T')[0] : null,
        active: active == 'Todos' ? null : active == 'Habilitado'
      }
    }).then(res => {
      setBrands(res.data.getAllBrands.content);
      setLastPage(Math.round(res.data.getAllBrands.totalPages));

    }).catch(err => {return err});
  }
  const getBrands = (event) => {
    event.preventDefault();
    setPage(0);
    handleRequest(0);
  }

  if (brands == null) {
    handleRequest()
  }
  return (
    <>
      <NavLink to="/catalogos" className="go-back" />
      <div className="content-body search-brand">
        <div className="header-customer-group header-customer-group--src">
          <div className="header-customer-group__info">
            <h2 className="section-title">Marca</h2>
            <p className="header-customer-group__description">Use os campos abaixo para fazer a busca das marcas.</p>
          </div>
          {
            <div className="header-customer-group__new">
              <NavLink
                className={  roleBrandWriter ? "link" : "link--disabled" }
                to={ roleBrandWriter ? "/catalogos/marcas/cadastro" : "#" }>
                Criar nova marca
              </NavLink>
            </div>
          }
          <div className="header-customer-group__actions">
            <form className="form-brand" onSubmit={getBrands} >
              <div className="form-row">
                <OutlinedInput
                  name="brandName"
                  className="form-brand__input"
                  placeholder="Marca"
                  border
                  maxLength={50}
                  onChange={e => setBrandName(e.target.value)}
                />
                <div className={`outlined-label-wrap outlined-date ${createdAt ? 'active' : ''}`}>
                  <DatePicker
                    className="form-brand__input"
                    onChange={e => setCreatedAt(e)}
                    locale={ptbr}
                    selected={createdAt}
                    dateFormat="dd/MM/yyyy"
                  />
                  <label className="outlined-label">Data</label>
                </div>

                <div className="dropdown-label-wrap">
                  <Dropdown
                    options={['Todos', 'Habilitado', 'Desabilitado']}
                    onChange={e => setActive(e.value)}
                    value={active ? active : null}
                    placeholder="Estado"
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    arrowClassName="dropdown-ifood__arrow"
                  />
                  <label className="dropdown-label">Estado</label>
                </div>

              </div>
              <div className="form-row">
                <div className="col-2">
                  <Button type="submit">Filtrar segmentos</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="table-box">
            {brands ? <TableBrand brands={brands} roles={ { roleBrandWriter,roleBrandReader } }/> : <a> </a>}

            {brands && lastPage > 1
              ?
              <div className="search-brand-paginate">
                <ReactPaginate
                  containerClassName="pagination-ifoodshop"
                  pageClassName="pagination-ifoodshop__item"
                  pageLinkClassName="pagination-ifoodshop__item-link"
                  activeLinkClassName="active"
                  previousLabel={<img className="previous-arrow" src={IconArrow} />}
                  nextLabel={<img className="next-arrow" src={IconArrow} />}
                  onPageChange={e => onPageChange(e)}
                  pageRangeDisplayed={3}
                  pageCount={lastPage}
                  marginPagesDisplayed={1}
                  initialPage={page}
                  forcePage={page}
                />
              </div>
              :
              <a> </a>}
          </div>
        </div>

      </div>
    </>
  );

}

export default SearchBrand;
