import React, { useMemo } from 'react';
import Formatter from '@helpers/FormatHelper';
import { TableAdmin } from '@components/Table/Table.styles';
import { Flex, Loading, Pagination, PaginationChangeData } from '@ifood/pomodoro-components';
import { BFFGetEstablishmentByIdQuery, BFFGetOrdersByEstablishmentIdQuery } from '@services/bff/GraphqlOperations';
import { BFFPageableInputSmall, Exact, Maybe } from '@services/bff/GraphqlTypes';
import { NoItemsFound } from '../../CustomerDetail.styles';
import { QueryLazyOptions } from '@apollo/react-hooks';
import OrderHelper from '@helpers/OrderHelper';
import EstablishmentEdit from './EstablishmentEdit';
import { getRoles } from '@helpers/rolesUtils';
import Roles from '@helpers/Roles';

interface EstablishmentOrdersListProps {
  establishment: Partial<BFFGetEstablishmentByIdQuery['getEstablishmentById']>;
  establishmentId: string;
  isLoading: boolean;
  ordersResponse: BFFGetOrdersByEstablishmentIdQuery['getOrdersByEstablishmentId'];
  fetchOrders: (
    options?:
      | QueryLazyOptions<
          Exact<{
            establishmentId: string;
            pageable?: Maybe<BFFPageableInputSmall>;
            orderId?: Maybe<string>;
            minCreatedAt?: Maybe<string>;
          }>
        >
      | undefined
  ) => void;
  closeModal: () => void;
}

const ZERO = 0;
const ONE = 1;
const PAGE_SIZE = 10;
const { Table, Th, Td } = TableAdmin;

export function EstablishmentOrdersList({
  establishment,
  establishmentId,
  isLoading,
  ordersResponse,
  fetchOrders,
  closeModal,
}: EstablishmentOrdersListProps) {
  const [pagination, setPagination] = React.useState<PaginationChangeData>({
    currentPage: 1,
    pageSize: PAGE_SIZE,
    nextOffset: 0,
    previousOffset: 0,
  });
  const orders = ordersResponse?.content;
  const hasOrders = orders && orders?.length > ZERO;

  function onPaginationChange(paginationData: PaginationChangeData) {
    setPagination(paginationData);
    fetchOrders({
      variables: { establishmentId, pageable: { page: paginationData.currentPage - ONE, size: PAGE_SIZE } },
    });
  }

  const hasEditingRoles = useMemo(() => {
    const rules = [Roles.ESTABLISHMENT_PAYMENT_TYPES_WR, Roles.SINTEGRAWS_BY_PASS_WR];

    return (getRoles() || []).some((rule: string) => rules.includes(rule));
  }, []);

  return (
    <>
      {isLoading && <Loading color="primary" variant="large" />}
      {!isLoading && !hasOrders && <NoItemsFound>Nenhum Pedido encontrado</NoItemsFound>}
      {!isLoading && hasOrders && (
        <>
          <Table>
            <thead>
              <tr>
                <Th>ID pedido filho</Th>
                <Th>Lojista</Th>
                <Th>Valor total</Th>
                <Th>Data do pedido</Th>
                <Th>Forma de pagamento</Th>
                <Th>Desconto</Th>
              </tr>
            </thead>
            <tbody>
              {orders?.map(order => {
                return order ? (
                  <tr key={order.orderId}>
                    <Td>{order?.orderId}</Td>
                    <Td>{order.sellerName}</Td>
                    <Td>{Formatter.currency(order.totalAmount)}</Td>
                    <Td>{Formatter.ptBrDate(order.createdAt)}</Td>
                    <Td>{OrderHelper.handlePaymentType(order.paymentType)}</Td>
                    <Td>{order.discount}</Td>
                  </tr>
                ) : (
                  <></>
                );
              })}
            </tbody>
          </Table>
        </>
      )}

      <Flex justifyContent="space-between" mb="60px" mt="60px">
        <Pagination.Numbered
          showArrowLabel
          showCounter
          current={pagination.currentPage}
          nextLabel="Próximo"
          ofLabel="de"
          pageSize={pagination.pageSize}
          previousLabel="Anterior"
          showingLabel="Exibindo"
          totalItems={ordersResponse?.totalElements || ZERO}
          onChange={onPaginationChange}
        />
        {hasEditingRoles && (
          <EstablishmentEdit closeModal={closeModal} establishment={{ ...establishment, establishmentId }} />
        )}
      </Flex>
    </>
  );
}
