/* eslint-disable no-magic-numbers */
import Formatter from '@helpers/FormatHelper';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  useGetInactivationReasonsQuery,
  useGetSellerV2Query,
  useSellerChangeStatusMutation,
} from '@services/bff/GraphqlHooks';
import { Maybe } from 'graphql/jsutils/Maybe';
import { snackbar } from '@ifood/pomodoro-components';

const VALID_DDD_LENGTH = 2;

export function useSellerDetailsData() {
  const { id } = useParams<{ id: string }>();
  const [stringImgBytes, setStringImgBytes] = useState<string>('');
  const [isActiveWord, setIsActiveWord] = useState<string>('');
  const {
    data: sellerResponse,
    loading: isLoadingDetails,
    refetch,
  } = useGetSellerV2Query({
    variables: { sellerV2Id: parseInt(id) },
  });

  const [sellerChangeStatus] = useSellerChangeStatusMutation({
    onCompleted: () => {
      snackbar({
        message: `Status ${isActiveWord} com sucesso!`,
        variant: 'success',
        timeout: 5000,
        autoClose: true,
      });
      refetch();
    },
    onError: () => {
      snackbar({
        message: 'Erro ao alterar status do seller, por favor tente novamente.',
        variant: 'error',
        timeout: 4000,
        autoClose: true,
      });
    },
  });

  const { data: sellerReasonResponse, loading: isLoadingReasons } = useGetInactivationReasonsQuery();

  async function imageSettingsHandle(url: Maybe<string>) {
    if (!url) return;

    const fileImg = await fetch(url).then(r => r.blob());
    const formattedImgBytes = Formatter.format(fileImg.size, 'bytes');
    setStringImgBytes(formattedImgBytes);
  }

  function getPhoneNumberWithDDD(number?: string, ddd?: string) {
    if (!number) return;

    const dddString = ddd?.length === VALID_DDD_LENGTH ? `(${ddd})` : '';

    return number.match(/\(..\)./) ? number : `${dddString} ${number}`;
  }

  return {
    sellerResponse,
    imageSettingsHandle,
    stringImgBytes,
    isLoadingDetails,
    refetch,
    sellerReasonResponse,
    isLoadingReasons,
    sellerChangeStatus,
    setIsActiveWord,
    getPhoneNumberWithDDD,
  };
}
