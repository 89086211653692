import React from 'react';
import { useHistory } from 'react-router-dom';
import ListRegion from './components/ListRegion';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import Header from '@components/Header';

const RegionSearch = () => {
  const history = useHistory();
  const roles = getRoles();
  const roleRegion = roles.includes(Roles.REGION_WR);
  const roleRegionReadOnly = roles.includes(Roles.REGION_RO);
  const roleSeller = roles.includes(Roles.SELLER_WR);

  if (!roleRegionReadOnly && !roleRegion) {
    history.push( '/');
  }

  return (
    <>
      {(roleRegionReadOnly || roleRegion) && (
        <div className="content-body">
          <Header
            role={roleRegion}
            isSeller={roleSeller}
            title="Regiões"
            description="Use os campos abaixo para filtrar as busca das regiões."
            backRoute="/campanhas"
            createRoute="/campanhas/regioes/cadastro"
            titleLink="Criar nova região"
          />
          <ListRegion roles={ { roleRegion, roleRegionReadOnly } } isSeller={roleSeller} />
        </div>
      )}
    </>
  );
};

export default RegionSearch;
