import React, {useState, useEffect} from 'react';
import { OutlinedInput, Modal, Button } from 'ifoodshop-react-ui';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DatePicker from "react-datepicker";
import Dropdown from 'react-dropdown';
import Toggle from 'react-toggle'
import FormRegisterSubsidy from '../../../components/FormRegisterSubsidy';
import FormRegisterRules from './FormRegisterRules'
import OutlinedInputCurrency from '../../../../../components/OutlinedInputCurrency'
import OutlinedTextarea from '../../../../../components/OutlinedTextarea'
import LabelSuccess from '../../../../../components/LabelSuccess';
import Loading from '../../../../../components/Loading';
import FormCouponFields from '../FormCouponFields/FormCouponFields'

import "react-datepicker/src/stylesheets/datepicker.scss";
import './formRegisterDiscount.scss'
import ptbr from 'date-fns/locale/pt-BR';

import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { CREATE_DISCOUNT, CREATE_DISCOUNT_V2, UPDATE_DISCOUNT, UPDATE_DISCOUNT_V2, GET_DISCOUNT_ID_V2, GET_ALL_DISCOUNTS_V2 } from '../../../../../graphql/discounts.queries'
import { GET_CAMPAIGN_GROUPS } from '../../../../../graphql/campaignGroup.queries'



const FormRegisterDiscountV2 = (props) => {

  const [dataDiscount, setDataDiscount] = useState(null);
  const [nameDiscount, setNameDiscount] = useState(null);
  const [friendlyNameDiscount, setFriendlyNameDiscount] = useState(null);
  const [typeDiscount, setTypeDiscount] = useState("PERCENTUAL_ON_PRODUCT");
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [visible, setVisible] = useState(true);
  const [cumulative, setCumulative] = useState(true);
  const [campaignGroupUniqueDiscount, setCampaignGroupUniqueDiscount] = useState(false);
  const [campaignGroupId, setCampaignGroupId] = useState(null);
  const [campaignGroupName, setCampaignGroupName] = useState('Selecione');
  const [optionsCampaignGroup, setOptionsCampaignGroup] = useState([]);
  const [stamp, setStamp] = useState(null);
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [ivl, setIvl] = useState();
  const [dataSubsidy, setDataSubsidy] = useState();
  const [dataSubsidyUpdate, setDataSubsidyUpdate] = useState()
  const [dataRules, setDataRules] = useState(null);
  const [dataRulesUpdate, setDataRulesUpdate] = useState(null);
  const [modal, setModal] = useState(null);
  const [authorEdited, setAuthorEdited] = useState();
  const [dateEdited, setDateEdited] = useState();
  const [isCoupon, setIsCoupon] = useState(false);
  const [isShowSite, setIsShowSite] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [store, setStore] = useState(null);
  const [isAppExclusive, setIsAppExclusive] = useState(store != null ? true : false)
  const [couponHasExist, setCouponHasExist] = useState()
  const [couponInput, setCouponInput] = useState();
  const [customerMaxCoupons, setCustomerMaxCoupons] = useState();
  const [maxCouponsUsage, setMaxCouponsUsage] = useState();
  const [couponsActive, setCouponsActive] = useState();
  const [labelTeste, setLabelTeste] = useState();
  const [description, setDescription] = useState();
  const [showDiscountWarning, setShowDiscountWarning] = useState(true);
  const [couponType, setCouponType] = useState('unity');
  const [couponBatchSizeValue, setCouponBatchSizeValue] = useState();
  const [isCouponSaved, setIsCouponSaved] = useState(false);


  var userName = props.userName;

  function onCompletedDiscountCreate (id) {
    setLabelTeste(
      <LabelSuccess success content="Desconto salvo com sucesso!" />
    )

    setTimeout(() => {
      setLabelTeste(null)
      window.location.href = `/campanhas/descontos/editar/${id}`
    }, 1000);

  }

  const [createDiscount, { loading, data }] = useMutation(CREATE_DISCOUNT, {
    onCompleted: (data) => {
      onCompletedDiscountCreate(data.createDiscount.id)
    }
  });

  const [createDiscountV2, { loading: loadingCreateV2, data: dataV2 }] = useMutation(CREATE_DISCOUNT_V2, {
    onCompleted: (data) => {
      onCompletedDiscountCreate(data.createDiscountV2.id)
    }
  });

  const [updateDiscount, { loading: loadingUpdateDiscount, data: dataUpdateDiscount, error: errorUpdateDiscount }] = useMutation(UPDATE_DISCOUNT, {
    onCompleted: () => {
      setLabelTeste(
        <LabelSuccess success content="Desconto salvo com sucesso!" />
      )
      setTimeout(() => {
        setLabelTeste(null)
        window.location.reload();
      }, 1000);
    }
  });

  const [updateDiscountV2, { loading: loadingUpdateDiscountV2, data: dataUpdateDiscountV2, error: errorUpdateDiscountV2 }] = useMutation(UPDATE_DISCOUNT_V2, {
    onCompleted: () => {
      setLabelTeste(
        <LabelSuccess success content="Desconto salvo com sucesso!" />
      )
      setTimeout(() => {
        setLabelTeste(null)
        window.location.reload();
      }, 1000);
    }
  });

  const [getAllDiscountsV2, { data: dataAllDiscountsV2 }] = useLazyQuery(GET_ALL_DISCOUNTS_V2, {
    onCompleted: () => {
      if(dataAllDiscountsV2.getAllDiscountsV2.content.length) {
        dataAllDiscountsV2.getAllDiscountsV2.content.forEach(item => {
          if(item.couponCode.toUpperCase() === couponInput.toUpperCase()) {
            setCouponHasExist(true);
          } else {
            setCouponHasExist(false)
          }
        });
      } else {
        setCouponHasExist(false)
      }
    },
    fetchPolicy: "no-cache"
  })

  const [getAllCampaignGroups, { data: dataAllCampaignGroups }] = useLazyQuery(GET_CAMPAIGN_GROUPS, {
    onCompleted: () => {

      if(dataAllCampaignGroups.getAllCampaignGroups.length) {
        setOptionsCampaignGroup(dataAllCampaignGroups.getAllCampaignGroups.map(t => t.name));
      }else{
        setCampaignGroupUniqueDiscount(false)
      }
    },
    fetchPolicy: "no-cache"
  })

  function findCampaignGroupId (groupName) {

    if (groupName && campaignGroupUniqueDiscount) {
      if (dataAllCampaignGroups?.getAllCampaignGroups) {
        let selectecCampaignGroup =  dataAllCampaignGroups.getAllCampaignGroups.find(f =>
          f.name.toUpperCase() === groupName.toUpperCase()
        )
        if (selectecCampaignGroup) return selectecCampaignGroup.id;
      }
    }

    return null;

  }

  function findCampaignGroupName (campaignId) {

    if (campaignId) {
      if (dataAllCampaignGroups?.getAllCampaignGroups) {
        let selectecCampaignGroup =  dataAllCampaignGroups.getAllCampaignGroups.find(f =>
          f.id === campaignId
        )
        if (selectecCampaignGroup) return selectecCampaignGroup.name;
      }
    }

    return 'Selecione'

  }

  const [getDiscountV2, { loading: loadingGetDiscount, data: dataGetDiscount, error: errorGetDiscount }] = useLazyQuery(GET_DISCOUNT_ID_V2);
  if(dataGetDiscount && dataDiscount === null) {
    setDataDiscount(dataGetDiscount.getDiscountV2)
    setTypeDiscount(dataGetDiscount.getDiscountV2.type)
    setNameDiscount(dataGetDiscount.getDiscountV2.name)
    setFriendlyNameDiscount(dataGetDiscount.getDiscountV2.friendlyName)
    setVisible(dataGetDiscount.getDiscountV2.active)
    setStamp(dataGetDiscount.getDiscountV2.highlight)
    setCumulative(dataGetDiscount.getDiscountV2.isCumulative)
    setCampaignGroupUniqueDiscount(dataGetDiscount.getDiscountV2.campaignGroupUniqueDiscount ?? false)
    setCampaignGroupId(dataGetDiscount.getDiscountV2.campaignGroupId)
    setCampaignGroupName(findCampaignGroupName(dataGetDiscount.getDiscountV2.campaignGroupId))
    setIvl(dataGetDiscount?.getDiscountV2?.value?.toString().replace('.', ','))
    setInitialDate(new Date(dataGetDiscount.getDiscountV2.startDate))
    setFinalDate(new Date(dataGetDiscount.getDiscountV2.endDate))
    setDataRules(dataGetDiscount.getDiscountV2.rules)
    setDataRulesUpdate(dataGetDiscount.getDiscountV2.rules)
    setDescription(dataGetDiscount.getDiscountV2.description)
    setDataSubsidyUpdate(dataGetDiscount.getDiscountV2.subsidies)
    setIsCoupon(dataGetDiscount.getDiscountV2.isCoupon)
    setIsCouponSaved(dataGetDiscount.getDiscountV2.isCoupon);
    setIsShowSite(dataGetDiscount.getDiscountV2.showSite)
    setIsNewCustomer(dataGetDiscount.getDiscountV2.newCustomer)
    setStore(dataGetDiscount.getDiscountV2.store)
    setIsAppExclusive(dataGetDiscount.getDiscountV2.store != null ? true : false)
    if(dataGetDiscount.getDiscountV2.isCoupon === true) {
      setCouponInput(dataGetDiscount.getDiscountV2.couponCode)
      setCustomerMaxCoupons(dataGetDiscount.getDiscountV2.customerMaxUsage)
      setMaxCouponsUsage(dataGetDiscount.getDiscountV2.couponMaxUsage)
      if (dataGetDiscount.getDiscountV2.couponMaxUsage) {
        const couponsRemaining = dataGetDiscount.getDiscountV2.couponMaxUsage - dataGetDiscount.getDiscountV2.couponUsage;
        setCouponsActive(couponsRemaining)
      } else {
        setCouponsActive(dataGetDiscount.getDiscountV2.couponUsage)
      }
    }
    if (dataGetDiscount.getDiscountV2.minimumValue)
      setMinValue(dataGetDiscount.getDiscountV2.minimumValue)
    if (dataGetDiscount.getDiscountV2.maxValue)
      setMaxValue(dataGetDiscount.getDiscountV2.maxValue)

    if (props.cloneId) {
      setInitialDate(null);
      setFinalDate(null);
      setCouponInput();
      setCouponsActive();
    }

    if(dataGetDiscount?.getDiscountV2?.couponBatchSize){
      setCouponBatchSizeValue(dataGetDiscount?.getDiscountV2?.couponBatchSize);
      setCouponType('batchCoupon')
    }
  }

  useEffect(() =>{
    if(props.idDiscount || props.cloneId) {
      getDiscountV2({variables: { idDiscount: props.idDiscount ? props.idDiscount : props.cloneId }});
    }
    getAllCampaignGroups();

  } ,[]);

  useEffect(() =>{
    if (dataGetDiscount?.getDiscountV2?.campaignGroupId) {
      setCampaignGroupName(findCampaignGroupName(dataGetDiscount.getDiscountV2.campaignGroupId))
    }
  } ,[optionsCampaignGroup]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(couponType === 'unity' && couponInput !== dataGetDiscount?.getDiscountV2?.couponCode){
          getAllDiscountsV2({
            variables: {
            active: true,
            valid: true,
            couponCode: couponInput.toUpperCase()
          }
        });
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [couponInput])

  function registerDiscount(e) {
    e.preventDefault();

    let rulesList = JSON.parse(JSON.stringify(dataRules));
    let rulesListAfterReduce = [];
    if (rulesList.length) {
      rulesList.sort((a,b) => {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        if (a.filter < b.filter) return -1;
        if (a.filter > b.filter) return 1;
        return 0;
      });

      rulesListAfterReduce.push(rulesList.reduce((rule, item) => {
        if (rule.type === item.type && rule.filter === item.filter) {
          rule.values.push(item.values[0]);
          if (rule.id === null && item.id !== null)
            rule.id = item.id;
          return rule;
        }
        rulesListAfterReduce.push(rule);
        return item;
      }));

      rulesListAfterReduce.map(item => delete item.key);
    }

    var sumPercentSubsidy = 0;
    let subsidiesList = JSON.parse(JSON.stringify(dataSubsidy));
    if (subsidiesList.length) {
      subsidiesList.map(item => {
        sumPercentSubsidy += item.percentage;
        delete item.name;
        delete item.key;
      });
    }

    if(initialDate){
      var startDate = initialDate;
      var iDateFormated = new Date(startDate.setTime( startDate.getTime() - startDate.getTimezoneOffset()*60*1000 ));
    }

    if(finalDate){
      var untilDate = finalDate;
      var fDateFormated = new Date(untilDate.setTime( untilDate.getTime() - untilDate.getTimezoneOffset()*60*1000));
    }

    if ((initialDate && finalDate) && (fDateFormated < iDateFormated)) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>A data inicial não pode ser maior que a data final</p>
        </Modal>
      )
      return
    }

    let minValueEdited;
    if( typeof(minValue) === 'string' ){
      const minValuePrefix = minValue.replace("R$ ", "")
      const minValueDot = minValuePrefix.replaceAll(".", "")
      const minvalueDecimal = minValueDot.replace(",", ".")
      minValueEdited = parseFloat(minvalueDecimal, 2)
    } else {
      minValueEdited = minValue
    }

    let maxValueEdited;
    if( typeof(maxValue) === 'string' ){
      const maxValuePrefix = maxValue.replace("R$ ", "")
      const maxValueDot = maxValuePrefix.replaceAll(".", "")
      const maxvalueDecimal = maxValueDot.replace(",", ".")
      maxValueEdited = parseFloat(maxvalueDecimal, 2)
    } else {
      maxValueEdited = maxValue
    }

    let ivlEdited;
    if( typeof(ivl) === 'string' ){
      const ivlPrefix = ivl.replace("R$ ", "")
      const ivlDot = ivlPrefix.replaceAll(".", "")
      const ivlDecimal = ivlDot.replace(",", ".")
      ivlEdited = parseFloat(ivlDecimal, 2)
    } else {
      ivlEdited = ivl
    }

    if(!typeDiscount || !nameDiscount || !initialDate || !finalDate || !ivlEdited){
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>Preencha todos os campos do formulário</p>
        </Modal>
      )
      return
    } else if (sumPercentSubsidy !== 100) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>A soma percentual dos subsídios deve ser igual a 100%</p>
        </Modal>
      )
      return
    } else if(dataRules < 1){
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>Não existe nenhuma regra adicionada</p>
        </Modal>
      )
      return
    } else if(isCoupon && couponType === 'unity' && (!customerMaxCoupons || !couponInput) ||
              isCoupon && couponType === 'batchCoupon' && (!customerMaxCoupons || !couponInput || !couponBatchSizeValue)) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>Preencha todos os campos de Cupom</p>
        </Modal>
      )
      return
    } else if(couponHasExist) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>Cupom em uso, por favor insira outro valor</p>
        </Modal>
      )
      return
    } else {
      if(props.idDiscount) {
	      const discountCheck = ((ivlEdited - dataGetDiscount?.getDiscountV2?.value) * 2 >= dataGetDiscount?.getDiscountV2?.value) ||
          (ivlEdited * 2 <= dataGetDiscount?.getDiscountV2?.value) ||
          ((minValueEdited - dataGetDiscount?.getDiscountV2?.minimumValue) * 2 >= dataGetDiscount?.getDiscount?.minimumValue) ||
          (minValueEdited * 2 <= dataGetDiscount?.getDiscountV2?.minimumValue);

        if (showDiscountWarning && discountCheck) {
          setModal(
            <Modal onCloseAction={() => setModal(null)}>
              <h3>Atenção</h3>
              <p>Valide se a alteração no desconto supera o valor de 50%.</p>

              <div className="form-admin--discount__btn-group">
                  <Button onClick={() => {
                      setShowDiscountWarning(false);
                      setModal(null);
                  }}>
                      Entendi
                  </Button>
              </div>
            </Modal>
          )
        } else {
          const updateDiscountObject =
            {variables: {
              id: parseInt(props.idDiscount),
              name: nameDiscount,
              friendlyName: friendlyNameDiscount,
              type: typeDiscount,
              active: visible,
              startDate: iDateFormated,
              endDate: fDateFormated,
              highlight: stamp,
              isCumulative: cumulative,
              campaignGroupId: findCampaignGroupId(campaignGroupName),
              campaignGroupUniqueDiscount: campaignGroupUniqueDiscount,
              value: ivlEdited,
              minimumValue: minValueEdited,
              maxValue: maxValueEdited,
              subsidies: subsidiesList,
              rules: rulesListAfterReduce,
              showSite: isShowSite,
              isCoupon: isCoupon,
              newCustomer: isNewCustomer,
              store: store,
              couponCode: couponInput,
              customerMaxUsage: customerMaxCoupons ? parseInt(customerMaxCoupons) : null,
              couponMaxUsage: maxCouponsUsage ? parseInt(maxCouponsUsage) : null,
              couponUsage: couponsActive ? parseInt(couponsActive) : null,
              userName: userName,
              description: description,
              couponBatchSize: couponBatchSizeValue ? parseInt(couponBatchSizeValue) : null,
              cuponBatchOwnerId: couponType === 'batchCoupon' ? dataDiscount.id : null
            }}
            switch (couponType) {
              case 'unity':
                updateDiscount(updateDiscountObject)
                return true;
              case 'batchCoupon':
                updateDiscountV2(updateDiscountObject)
                return true;
              default:
                return null;
            }

        }
      } else {
        const createDiscountObject =  {variables: {
          couponBatchSize: couponBatchSizeValue ? parseInt(couponBatchSizeValue) : null,
          name: nameDiscount,
          friendlyName: friendlyNameDiscount,
          type: typeDiscount,
          active: visible,
          startDate: iDateFormated,
          endDate: fDateFormated,
          highlight: stamp,
          isCumulative: cumulative,
          campaignGroupId: findCampaignGroupId(campaignGroupName),
          campaignGroupUniqueDiscount: campaignGroupUniqueDiscount,
          value: ivlEdited,
          minimumValue: minValueEdited,
          maxValue: maxValueEdited,
          subsidies: subsidiesList,
          rules: rulesListAfterReduce,
          showSite: isShowSite,
          newCustomer: isNewCustomer,
          store: store,
          isCoupon: isCoupon,
          couponCode: couponInput,
          customerMaxUsage: customerMaxCoupons ? parseInt(customerMaxCoupons) : null,
          couponMaxUsage: maxCouponsUsage ? parseInt(maxCouponsUsage) : null,
          couponUsage: couponsActive ? parseInt(couponsActive) : null,
          userName: userName,
          description: description
        }}

        switch (couponType) {
          case 'unity':
            createDiscount(createDiscountObject)
            return true;
          case 'batchCoupon':
            createDiscountV2(createDiscountObject)
            return true;
          default:
            return null;
        }
      }
    }
  }

  function percentDiscount(e) {
    const percent = e.replace(/[^\d,]+/g, '')
    if (percent > 100)
      setIvl(100);
    else
      setIvl(percent);

  }

  function generateCoupon(e) {
    e.preventDefault();

    let stringRandom = "";
    let couponRandom = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    let date = new Date();
    let day = date.getDate()
    let dayString = day < 10 ? "0"+day.toString() : day.toString()
    let month = date.getMonth()+1
    let monthString = month < 10 ? "0"+month.toString() : month.toString()
    let year = date.getFullYear().toString().substr(2,2)
    let dateString = dayString + monthString + year

    for (var i = 0; i < 6; i++)
      stringRandom += possible.charAt(Math.floor(Math.random() * possible.length));

    couponRandom = stringRandom + dateString

    setCouponInput(couponRandom.toUpperCase());
  }

  function maxCoupon(e) {
    e = e.replace(/[^\d,]+/g, '');

    if(e === '0') {
      setCustomerMaxCoupons(1)
    } else {
      setCustomerMaxCoupons(e)
    }
  }

  function handleToggleButton(newTypeDiscount) {
    setMinValue('');
    setIvl('');
    setTypeDiscount(newTypeDiscount);
    if (newTypeDiscount !== 'PERCENTUAL_ON_PRODUCT' && maxValue !== null) setMaxValue(null);
  }

  function handleCouponType(e) {
    setCouponInput('');
    setCustomerMaxCoupons('');
    setCouponsActive('');
    setIsShowSite(false);
    setCouponBatchSizeValue('');
    setCouponType(e.target.value);
    };

  return(
    <>
    <form className="form-admin form-admin--discount">

      <div className="form-admin__group">
        <ToggleButtonGroup
          className="togglebutton-ifood"
          value={typeDiscount}
          exclusive
          onChange={(e, newTypeDiscount) => handleToggleButton(newTypeDiscount)}
          aria-label="tipo de descontos"
        >
          <ToggleButton className="togglebutton-ifood__item" value="PERCENTUAL_ON_PRODUCT" aria-label="% produto">
            % Produto
          </ToggleButton>
          <ToggleButton className="togglebutton-ifood__item" value="PERCENTUAL_ON_FREIGHT" aria-label="% frete">
            % Frete
          </ToggleButton>
          <ToggleButton className="togglebutton-ifood__item" value="ABSOLUTE_ON_CART" aria-label="$ absoluto">
            $ Absoluto
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className="form-admin__group">
        <OutlinedInput
          placeholder="Nome do Desconto"
          border
          maxlength="50"
          id="discountName"
          value={nameDiscount}
          onChange={(e) => setNameDiscount(e.target.value)}
        />
      </div>

      <div className="form-admin__group">
        <OutlinedInput
          placeholder="Nome de Exibição"
          border
          maxlength="80"
          id="discountFriendlyName"
          value={friendlyNameDiscount}
          onChange={(e) => setFriendlyNameDiscount(e.target.value)}
        />
      </div>

      <div className="form-admin__group">
        <OutlinedInput
          placeholder="Selo no produto"
          border
          maxlength="22"
          value={stamp}
          onChange={(e) => setStamp(e.target.value)}
        />
      </div>

      <div className="form-admin__input-group">
        <OutlinedTextarea
          placeholder="Descrição complementar do desconto"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="form-admin__group form-admin__group--title-conditions">
        <h2 className="section-title section-title--discount-form">Condições</h2>
        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Ativo</span>
          <Toggle checked={visible} value={visible} onChange={v => setVisible(v.target.checked)} />
        </div>
      </div>

      <div className="form-admin__group">
        <div className={`outlined-label-wrap outlined-date ${initialDate ? 'active' : ''}`}>
          <DatePicker
            className="form-brand__input"
            onChange={date => setInitialDate(new Date(date))}
            locale={ptbr}
            showTimeSelect
            timeIntervals={15}
            selected={initialDate}
            dateFormat="dd/MM/yyyy hh:mm aa"
          />
          <label className="outlined-label">Data Inicial</label>
        </div>
        <div className={`outlined-label-wrap outlined-date ${finalDate ? 'active' : ''}`}>
          <DatePicker
            className="form-brand__input"
            onChange={date => setFinalDate(new Date(date))}
            locale={ptbr}
            showTimeSelect
            timeIntervals={15}
            selected={finalDate}
            dateFormat="dd/MM/yyyy hh:mm aa"
          />
          <label className="outlined-label">Data Final</label>
        </div>
      </div>

      <div className="form-admin__group">
        <OutlinedInputCurrency
          value={minValue}
          placeholder="Valor mínimo (R$)*"
          onChange={(e) => setMinValue(e.target.value)}
        />
        <OutlinedInputCurrency
          value={maxValue ?? ''}
          placeholder="Valor máximo (R$)*"
          onChange={(e) => setMaxValue(e.target.value)}
          disabled={typeDiscount!=="PERCENTUAL_ON_PRODUCT"}
        />
        <span className="form-add-customer--label-error">* campos opcionais</span>
      </div>

      <div className="form-admin__group">
        {
          typeDiscount === 'PERCENTUAL_ON_PRODUCT' || typeDiscount === 'PERCENTUAL_ON_FREIGHT'
          ?
            <OutlinedInput
              placeholder="Valor do desconto (%)"
              type="text"
              border
              value={ivl}
              onChange={(e) => percentDiscount(e.target.value)}
            />
          :
          <OutlinedInputCurrency
            value={ivl}
            placeholder="Valor do desconto (R$)"
            onChange={(e) => setIvl(e.target.value)}
          />
        }
      </div>

      <div className="form-admin__group form-admin__group--author">
        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Acumulativo</span>
          <Toggle checked={cumulative} value={cumulative} onChange={v => setCumulative(v.target.checked)} />
        </div>
        <div className="author">
          editado por: {authorEdited} | data: {dateEdited}
        </div>
      </div>

      <div className="form-admin__group form-admin__group--title-conditions">
        <h2 className="section-title section-title--discount-form">Grupo de Campanhas</h2>
        <div className="toggle-wrap">
          <Toggle checked={campaignGroupUniqueDiscount} value={campaignGroupUniqueDiscount} onChange={v => setCampaignGroupUniqueDiscount(v.target.checked)} />
        </div>
      </div>

      {campaignGroupUniqueDiscount &&

        <div className="form-admin__group">

          <div className="dropdown-label-wrap">
            <Dropdown
              options={optionsCampaignGroup}
              onChange={(e) => {setCampaignGroupName(e.value)}}
              value={campaignGroupName}
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Grupo de Campanhas</label>
          </div>
          <span className="form-add-customer--label-error">apenas uma campanha desse grupo será aplicável nos descontos</span>
        </div>


      }

      <div className="form-admin__group form-admin__group--title-conditions">
        <h2 className="section-title section-title--discount-form">Exclusivo para novos clientes: </h2>
        <div className="toggle-wrap">
          <Toggle checked={isNewCustomer} value={isNewCustomer} onChange={v => setIsNewCustomer(v.target.checked)} />
        </div>
      </div>

      <div className="form-admin__group form-admin__group--title-conditions">
        <h2 className="section-title section-title--discount-form">Exclusivo para APP: </h2>
        <div className="toggle-wrap">
          <Toggle checked={isAppExclusive} value={isAppExclusive} onChange={v => {
            if (v.target.checked) {
              setStore('app')
              setIsAppExclusive(true)
            } else {
              setStore(null)
              setIsAppExclusive(false)
            }
          }} />
        </div>
      </div>

      <div className="form-admin__group form-admin__group--title-conditions">
        <h2 className="section-title section-title--discount-form">Cupom</h2>
        <div className="toggle-wrap">
          <Toggle checked={isCoupon} value={isCoupon} onChange={v => setIsCoupon(v.target.checked)} />
        </div>
      </div>

      {isCoupon &&
        <FormCouponFields
          couponInput={couponInput}
          setCouponInput={setCouponInput}
          maxCouponsValue={customerMaxCoupons}
          handleMaxCoupon={maxCoupon}
          couponTypeValue={couponType}
          handleCouponType={handleCouponType}
          couponsActive={couponsActive}
          maxCouponsUsage={maxCouponsUsage}
          handleMaxCouponsUsage={setMaxCouponsUsage}
          isShowSiteCheckbox={isShowSite}
          handleIsShowSiteCheckbox={setIsShowSite}
          handleGenerateCoupon={generateCoupon}
          couponHasExist={couponHasExist}
          couponBatchSizeField={couponBatchSizeValue}
          discountId={props.idDiscount}
          handleCouponBatchSize={setCouponBatchSizeValue}
          isCouponSaved={isCouponSaved}
          />
      }

      <div className="form-admin__area form-admin__area--subsidy">
        <FormRegisterSubsidy dataSubsidyUpdate={dataSubsidyUpdate} cloneId={props.cloneId} dataSubsidy={e => setDataSubsidy(e)} role={props.role} title="Subsídio" />
      </div>

      <div className="form-admin__area">
        <FormRegisterRules dataRulesUpdate={dataRulesUpdate} cloneId={props.cloneId} dataRules={e => setDataRules(e)} title="Regras"/>
      </div>

      <div className="form-admin__area">
        <span type="submit" className="button" onClick={e => registerDiscount(e)}>Salvar desconto</span>
      </div>
    </form>
    {modal}

    {loading || loadingUpdateDiscount || loadingUpdateDiscountV2 || loadingGetDiscount || loadingCreateV2
      ? <Loading />
      : null
    }

      <div className="label-success--discount">
        {labelTeste}
      </div>
    </>
  )
}

export default FormRegisterDiscountV2;
