import { ApolloClient } from "apollo-client";
import { createHttpLink, HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import introspectionQueryResultData from './bff/schema.json';

export const urlGraphqlLocal = 'http://localhost:3001/graphql';
export const shouldMockApollo = process.env.REACT_APP_MOCK && process.env.NODE_ENV === 'development';
const urlGraphql = shouldMockApollo ? urlGraphqlLocal : window.config?.AdminGraphQL ?? process.env.REACT_APP_URL_GRAPHQL ?? "http://localhost:3001/graphql";
const uri = process.env.NODE_ENV === 'development' ? urlGraphql : '/admin-graphql';
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: { ...headers, authorization: token ? `Bearer ${token}` : "" },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {

    if (graphQLErrors) {
      let errors = graphQLErrors.map((e) => e.extensions.code);

      console.error(`[graphQLErrors]: ${errors}`);

      if (errors.includes("UNAUTHENTICATED")) {
        let oldHeaders = operation.getContext().headers;

        delete oldHeaders["Authorization"];

        operation.setContext({
          headers: {
            Authorization: "",
          },
        });

        window.location.reload()
      }

      return forward(operation);
    }

    if (networkError) console.error(`[Network Error]: ${networkError}`);
  }
);

const httpLink = createHttpLink({ uri: urlGraphql });
const fetchLink = new HttpLink({
  uri: urlGraphqlLocal,
  fetch: (...args) => fetch(...args),
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const client = new ApolloClient({
  link: shouldMockApollo ? fetchLink : ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;