import gql from 'graphql-tag';

const GET_COMMERCIAL_CONDITION = gql `
  query getAllCommercialCondition(
    $idOrName: String, 
    $isEnabled: Boolean, 
    $creationDate: String, 
    $page: Int, 
    $size: Int
    ){
    getAllCommercialCondition(
      idOrName: $idOrName, 
      isEnabled: $isEnabled, 
      creationDate: $creationDate, 
      page: $page, 
      size: $size
      ){
        content {
          id
          name
          isEnabled
          creationDate
        }
        totalPages
        size
        number
      }
  }
`;

const GET_COMMERCIAL_CONDITION_BY_ID = gql `
query getCommercialConditionById(
  $commercialConditionId: ID!){
  getCommercialConditionById(
    commercialConditionId: $commercialConditionId
    ){
      id
      name
      isEnabled
      creationDate
      rules {
        ruleFilter
        ruleType
        region {
          id
          name
        }
      }
  }
}`;

export {GET_COMMERCIAL_CONDITION, GET_COMMERCIAL_CONDITION_BY_ID};

