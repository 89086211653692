import { useState, useEffect } from 'react';
import { Button } from 'ifoodshop-react-ui'
import Tooltip from '@material-ui/core/Tooltip';

import './style.scss';

function MessageSendFile({resetModal, error, calledNumber}) {
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    if(openTooltip)
      setTimeout(() => setOpenTooltip(false), 1000)
  }, [openTooltip])

  function copyText(e) {
    e.preventDefault();

    const called = document.querySelector("#calledNumber").textContent;
    
    let textArea = document.createElement("textarea");
    textArea.value = called;
    
    document.body.appendChild(textArea);
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      if (successful)
        setOpenTooltip(true)
    } catch (err) {
      setOpenTooltip(false)
    }
    
    document.body.removeChild(textArea);

  }

  return (
    <div className="message">
      {!error 
        ? 
        <>
          <p>
            Tudo certo! Recebemos o seu arquivo
          </p>
          <p>
            Use o número de chamado abaixo para identificar a planilha importada. Em breve você irá receber por e-mail a planilha com este mesmo número de chamado.<br />
            Você pode copiar este código e salvar onde preferir também.
          </p>
          <span className="message__called" id="calledNumber">
            {calledNumber}
          </span>
          <p>
            <Tooltip
              open={openTooltip}
              title="Copiado"
              placement="bottom"
            >
              <span className="link" onClick={e => copyText(e)}>
                Copiar número de chamado
              </span>
            </Tooltip>
          </p>
        </>
        :
        <>
          <p>
            Ops! Erro ao importar seu arquivo
          </p>
          <p>
            Por favor, tente novamente.
          </p>
        </>
      }
      <div className="message__actions">
        <Button onClick={() => resetModal()}>{!error ? 'Enviar outro arquivo' : 'Enviar novamente'}</Button>
      </div>
    </div>
  );
}

export default MessageSendFile;