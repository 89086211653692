import React from "react";

export const ModalRuptureFooter = ({
  footerAlign,
  children,
}) => {
  return (
    <div
      className={`modal-content-footer modal-content-footer--${footerAlign}`}
    >
      {children}
    </div>
  );
};
