import { useHistory } from 'react-router-dom';
import { Modal, Button, Link } from 'ifoodshop-react-ui'

import './style.scss';
import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

function ModalCreateFreightRange({ resetForm, setModal }) {
  const history = useHistory();

  function backCarrier(e) {
    e.preventDefault();

    history.push('/fornecedores/transportadoras')
    setModal(false);
  }

  return (
    <Modal title="Atenção" className="modal-freight-range">
      <p className="modal-freight-range__description">Cadastro realizado com sucesso</p>

      <div className="modal-freight-range__actions">
        <Link onClick={e => backCarrier(e)} className="link">Voltar para transportadoras</Link>
        <Button onClick={() => resetForm()} >Criar nova faixa frete</Button>
      </div>
    </Modal>
  );
}

export default ModalCreateFreightRange;