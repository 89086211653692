import React, { useState, useEffect } from 'react';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Toggle from 'react-toggle';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_DISTRIBUTION_ADDRESS,
  CREATE_DISTRIBUTION_ADDRESS,
  UPDATE_DISTRIBUTION_ADDRESS,
} from '@graphql/distribution_address.queries.js';
import './formRegisterDistributionAddress.scss';
import { Typography } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { StatesList } from "@helpers/States";
import TextField from "@material-ui/core/TextField";

function FormRegisterDistributionAddress({ id }) {
  //TODO => it is missing to know how we are going to get the sellerId by the login;
  const [sellerId, setSellerId] = useState();
  const [distributionAddress, setDistributionAddress] = useState(null);
  const [address, setAddress] = useState(null);
  const [number, setNumber] = useState(null);
  const [complement, setComplement] = useState(null);
  const [neighbourhood, setNeighbourhood] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [reference, setReference] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [active, setActive] = useState(true);
  const [modal, setModal] = useState();
  const [cepError, setCepError] = useState(false);
  const history = useHistory();

  const [
    createDistributionAddress,
    { data: newDistributionAddressData },
  ] = useMutation(CREATE_DISTRIBUTION_ADDRESS);
  if (newDistributionAddressData) {
    history.push('/fornecedores/enderecos-distribuicao');
  }

  const [
    updateDistributionAddress,
    { data: updateDistributionAddressData },
  ] = useMutation(UPDATE_DISTRIBUTION_ADDRESS);
  if (updateDistributionAddressData) {
    window.location.href = '/fornecedores/enderecos-distribuicao';
  }

  const [getDistributionAddress, { data, error }] = useLazyQuery(
    GET_DISTRIBUTION_ADDRESS,
    {
      onError: (error) => {
        if(error.graphQLErrors[0].extensions['code'] === 'FORBIDDEN')
          history.push('/fornecedores')
      }
    }
  );

  useEffect(() => id && getDistributionAddress({ variables: { id } }), []);

  if (data && distributionAddress == null) {
    setDistributionAddress(data.getDistributionAddress);
    setAddress(data.getDistributionAddress.address);
    setNumber(data.getDistributionAddress.number);
    setComplement(data.getDistributionAddress.complement);
    setNeighbourhood(data.getDistributionAddress.neighbourhood);
    setPostalCode(data.getDistributionAddress.postalCode);
    setReference(data.getDistributionAddress.reference);
    setCity(data.getDistributionAddress.city);
    setState(data.getDistributionAddress.state);
    setCountry(data.getDistributionAddress.country);
    setActive(data.getDistributionAddress.active);
    setSellerId(data.getDistributionAddress.sellerId);
  }

  function handlePostalCode(postalCode) {
    return postalCode
      ? parseInt(postalCode.toString().replace(/[^\d]+/g, ''))
      : null;
  }

  function handleSaveAddress(e) {
    e.preventDefault();
    setCepError(false);

    if (
      !address ||
      !number ||
      !neighbourhood ||
      !postalCode ||
      !city ||
      !state ||
      !country
    ) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Preencha todos os campos obrigatórios.
        </Modal>,
      );
    }
    if (postalCode.toString().replace(/[^\d]+/g, '').length < 8) {
      return setCepError(true);
    }



    const variables = {
      distributionAddressId: id,
      id,
      sellerId,
      active,
      address,
      number,
      complement,
      neighbourhood,
      reference,
      city,
      state,
      country,
      postalCode: handlePostalCode(postalCode),
    };

    if (!id) {
      createDistributionAddress({ variables });
    } else {
      updateDistributionAddress({ variables });
    }
  }


  return (
    <>
      {((id && distributionAddress) || !id) && (
        <>
          <form className="form-admin" onSubmit={(e) => handleSaveAddress(e)}>
            <div className="form-admin__group">
              <div className="toggle-wrap">
                <span className="toggle-wrap__label">Ativo</span>
                <Toggle
                  checked={active}
                  value={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              </div>
            </div>

            <div className="form-admin__group">
              <div className="form-admin__group-address">
                <OutlinedInput
                  placeholder="Endereço *"
                  border
                  maxlength="50"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              <div className="form-admin__group-number">
                <OutlinedInput
                  placeholder="Número *"
                  type="number"
                  border
                  maxlength="50"
                  id="number"
                  value={number}
                  onChange={(e) => setNumber(parseInt(e.target.value))}
                />
              </div>

              <div className="form-admin__group-complement">
                <OutlinedInput
                  placeholder="Complemento"
                  border
                  maxlength="50"
                  id="complement"
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                />
              </div>
            </div>

            <div className="form-admin__group">
              <div className="form-admin__group-neighbourhood">
                <OutlinedInput
                  placeholder="Bairro *"
                  border
                  maxlength="50"
                  id="neighbourhood"
                  value={neighbourhood}
                  onChange={(e) => setNeighbourhood(e.target.value.replace(/\d/g,""))}
                />
              </div>

              <div className="form-admin__group-postal-code outlined-label-wrap">
                <InputMask
                  mask="99999-999"
                  className="input outlined-label-field outlined-label-field--border"
                  id="postalCode"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
                <label className="outlined-label">CEP *</label>
                {cepError && (
                  <span className="form-admin__group-label-error">
                    CEP inválido
                  </span>
                )}
              </div>

              <div className="form-admin__group-reference">
                <OutlinedInput
                  placeholder="Referência"
                  border
                  maxlength="50"
                  id="reference"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                />
              </div>
            </div>

            <div className="form-admin__group">
              <div className="form-admin__group-city">
                <OutlinedInput
                  placeholder="Cidade *"
                  type="text"
                  border
                  maxlength="50"
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value.replace(/\d/g,""))}
                />
              </div>

              <div className="form-admin__group-state">
                <Autocomplete
                  id="state"
                  className="autocomplete-ifood"
                  noOptionsText="Sem resultados"
                  options={StatesList || []}
                  value={state}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estado *"
                      variant="outlined"
                    />
                  )}
                  onSelect={(e) => setState(e.target.value)}
                />
              </div>

              <div className="form-admin__group-country">
                <OutlinedInput
                  placeholder="País *"
                  border
                  maxlength="50"
                  id="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value.replace(/\d/g,""))}
                />
              </div>
            </div>

            <Button className="form-admin__group-submit">
              Salvar Alterações
            </Button>
          </form>
          <Typography>(*) Campos obrigatórios</Typography>
        </>
      )}
      {modal}
    </>
  );
}

export default FormRegisterDistributionAddress;
