import RemoteConfig from '@ifood/faster-remote-config';

export async function getFeatureFlag(flagName: string) {
  try {
    const appKey = process.env.REACT_APP_API_KEY_FASTER || window?.config?.FasterK;
    const sdk = RemoteConfig.start({ appKey });
    await sdk.fetchSchemas(['shop_admin_feature_flag']);
    const schema = sdk.getSchema('shop_admin_feature_flag');
    const flagKey = `${flagName}_${process.env.NODE_ENV === 'production' ? 'prod' : 'dev'}`;
    const flagValue = schema && schema[flagKey];

    return flagValue;
  } catch (error) {
    return false;
  }
}

const LOCAL_FLAGS = {
  RESET_PASSWORD_SELLER: {
    prod: false,
    dev: false,
  },
};

type FlagsKeysT = keyof typeof LOCAL_FLAGS;

export function getLocalFeatureFlag(name: FlagsKeysT) {
  const env = process.env.NODE_ENV === `production` ? 'prod' : 'dev';

  return LOCAL_FLAGS[name][env];
}
