import gql from "graphql-tag";

const GET_BY_ORDER_SELLER_IDS = gql`
  query getByOrderSellerIds(
    $ids: [Int]
    $skuSeller: String
  ) {
    getByOrderSellerIds(
      ids: $ids
      skuSeller: $skuSeller
    ) {
        createdAt
        id
        itemsAmount 
        itemsDiscountAmount
        customer{
          id
          customerInfo{
            id
            login
            name
            document
            isFraudster
            iderp
            email
          }
          document
          stateRegistration
          email
          mobilePhone
          name
          owner
          phone
          createdAt
          updatedAt
        } 
        orderSellers
          {
            createdAt 
            deliveredDate 
            deliveries
              {
                audit
                  {
                    deletedAt 
                    id
                    login
                    name
                    observation
                    orderDeliveryId
                    status
                    stepDate
                  }
                
                cancellationReason 
                carrierId 
                collectorName 
                createdAt 
                deliveryDate 
                deliveryExpectedDate 
                deliveryId 
                deliveryPeriods
                invoiceIssuedDate 
                invoiceNumber 
                invoiceSeries 
                invoiceUrl 
                items 
                  {
                    basePrice {
                      id 
                      idRef 
                      orderItemId 
                      type 
                      value 
                    }
                    categoryId 
                    categoryName 
                    commission 
                    createdAt 
                    deliveryId 
                    discounts 
                      {
                        amount 
                        createdAt 
                        discountId 
                        discountType
                        id 
                        name 
                        orderItemId 
                        percentage 
                        updatedAt 
                      }
                    ean 
                    hasHistory 
                    height 
                    history
                      {
                        createdAt
                        id
                        newQuantity
                        newWeight
                        oldQuantity
                        oldSellPrice
                        oldSellerUnitPrice
                        oldTotalDiscount
                        oldUnitPrice
                        oldWeight
                        orderItemId
                        orderItemStatus
                        variableWeight
                      }
                    id 
                    imagePath 
                    length 
                    name 
                    offerId 
                    orderId 
                    productDescription 
                    quantity 
                    sellPrice 
                    sellerCode 
                    sellerUnitPrice 
                    sku 
                    skuIntern 
                    totalDiscount 
                    unitPrice 
                    updatedAt 
                    variableWeight 
                    variableWeightInformed 
                    variationId 
                    weight 
                    width 
                  }
                itemsAmount 
                itemsDiscountAmount 
                name 
                orderId 
                orderSellerId 
                ownerType 
                pickupAddress {
                  address 
                  addressId 
                  city 
                  complement 
                  country 
                  createdAt 
                  deliveryId 
                  establishmentId 
                  id 
                  latitude 
                  longitude 
                  name 
                  neighborhood 
                  number 
                  postalCode 
                  state 
                  updatedAt 
                }
                shippingAmount
                shippingDiscountAmount
                shippingTime
                step
                steps
                  {
                    active
                    date
                    login
                    name
                  }
                trackingCode
                type
                updatedAt
              }
            id
            itemsAmount
            itemsDiscountAmount
            orderId
            owner
            sellerAmount
            sellerId
            shippingAmount
            shippingDiscountAmount
            subsidies
              {
                amount
                costCenterId
                discountId
                discountType
                id
                orderSellerId
                percentage
                type
              }
            updatedAt 
          }
        paymentProcessed
        paymentProcessedMotive
        payments
          {
            bankSlipDigitableLine
            bankSlipExpirationDate
            bankSlipNumber
            bankSlipPdf
            bankSlipUrl
            chargeId
            chargeStatus
            createdAt
            creditCardBin
            creditCardExpirationMonth
            creditCardExpirationYear
            creditCardLastNumbers
            creditCardName
            creditCardToken
            currentStatus
            hasUpdated
            id
            installments
            orderId
            paymentStatusHistory
              {
                createdAt
                id
                isCurrent
                orderId
                orderPaymentId
                status
                updatedAt
              }
            pixExpirationDate
            pixLink
            pixQrcode
            redemptionCode
            totalAmount
            type
            updatedAt
          }
        shippingAddress {
          address 
          addressId 
          city 
          complement 
          country 
          createdAt 
          deliveryId 
          establishmentId 
          id 
          latitude 
          longitude 
          name 
          neighborhood 
          number 
          postalCode 
          state 
          updatedAt 
        }
        shippingAmount 
        shippingDiscountAmount 
        step
        store 
        telesalesId 
        totalAmount 
        totalDiscountAmount 
        updatedAt 
        userAgent 
        utmCampaign 
        utmMedium 
        utmSource 
        utmiCampaign 
        utmiPage 
        utmiPart 
        visitor 

    }
  }
`;

export {
    GET_BY_ORDER_SELLER_IDS
};
