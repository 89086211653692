import tokens from '@ifood/pomodoro-tokens';

export default {
  root: {
    padding: '5px !important',
    '&:hover $notchedOutline': {
      borderColor: `${tokens.colors.gray} !important`,
    },
  },
  notchedOutline: {
    borderColor: `${tokens.colors.gray} !important`,
  },
};
