import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPGRADE_ORDER_DELIVERY_STATUS } from '../../../../../graphql/order-delivery.mutations';
import { Button, Typography } from 'ifoodshop-react-ui';
import DatePicker from 'react-datepicker';
import ptbr from 'date-fns/locale/pt-BR';
import Loading from '@components/Loading';

import OutlinedTextarea from '@components/OutlinedTextarea';
import { formatDateToIsoLocal } from '../../orderUtils';

import { ReactComponent as ArrowRight } from '../../../../../assets/icons/order/arrow-right.svg';
import './handleInSeparation.scss';

function HandleInSeparation({ orderDeliveryId, buttonText, phraseVariation }) {
  const [errorPage, setErrorPage] = useState(null);
  const [updateTime, setUpdateTime] = useState(null);
  const [errorUpdateTime, setErrorUpdateTime] = useState(null);
  const [updateObservation, setUpdateObservation] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [valideteUpdateTime, setValideteUpdateTime] = useState(false)
  const [updateOrderStatus, { error, data, loading }] = useMutation(UPGRADE_ORDER_DELIVERY_STATUS);
  if (data) window.location.reload(false);
  if (error) {
    setErrorPage('Não Foi Possivel Alterar O Status Da Entrega');
  }

  function toInSeparation(e) {
    setDisabledButton(true);
    e.preventDefault();

    if (!updateTime) {
      setErrorUpdateTime('Campo Obrigatório');
      return;
    }

    updateOrderStatus({
      variables: {
        orderDeliveryId,
        updateStatus: 'IN_STOCK_SEPARATION',
        updateTime: formatDateToIsoLocal(updateTime),
        updateObservation,
        trackingCode: null,
      },
    });
  }

  function checkDate(date) {
    const dateNow = new Date();
    if (dateNow < date) {
      setValideteUpdateTime(true)
      setDisabledButton(true);
    } else {
      setUpdateTime(date);
      setValideteUpdateTime(false)
      setDisabledButton(false);
      setErrorUpdateTime(null);
    }
  }

  return (
    <>
      <h2 className="modal-alter-status__title">
        {phraseVariation ? 'Confirmado' : 'Pagamento confirmado'} <ArrowRight className="alter-status-arrow" /> 
        Em separação
      </h2>
      <h4 className="modal-alter-status__form__error">{errorPage}</h4>
      <form className="modal-alter-status__form modal-alter-status__form--in-separation">
        <div className={`outlined-label-wrap outlined-date ${updateTime ? 'active' : ''}`}>
          <DatePicker
            className="form-brand__input form-brand__input--start-date"
            onChange={date => checkDate(date)}
            locale={ptbr}
            showTimeSelect
            timeIntervals={15}
            selected={updateTime}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy hh:mm aa"
          />
          <label className="outlined-label">Data/hora início da separação</label>
          <p className="modal-alter-status__form__error">{errorUpdateTime}</p>
        </div>
        <div className="form-edit__input-group">
          <OutlinedTextarea
            placeholder="Observação"
            value={updateObservation}
            onChange={e => setUpdateObservation(e.target.value)}
          />
        </div>
        {valideteUpdateTime && (
          <Typography className="modal-alter-status__error-date" size="12px" weight="thin">
            Data de altereção de status não pode ser superior a data atual
          </Typography>
        )}
        <Button className="modal-alter-status__submit" disabled={disabledButton} onClick={e => toInSeparation(e)}>
          {buttonText}
        </Button>
      </form>
      {loading && <Loading />}
    </>
  );
}

export default HandleInSeparation;
