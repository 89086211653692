import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  UPDATE_REGION,
  CREATE_REGION,
} from '../../../../../../graphql/region.mutations';
import { GET_SELLERS } from '../../../../../../graphql/seller.queries';
import { GET_REGION_BY_ID } from '../../../../../../graphql/region.queries';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import Loading from '../../../../../../components/Loading';
import OutlinedTextarea from '../../../../../../components/OutlinedTextarea';
import PostalCodesAdd from './PostalCodesAdd';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import './formRegisterRegion.scss';

const FormRegisterRegion = ({ role, id, isSeller }) => {
  const edit = id ? true : false;
  const [region, setRegion] = useState(null);
  const [modal, setModal] = useState();
  const history = useHistory();

  const optionsActive = [
    { value: true, label: 'Habilitado' },
    { value: false, label: 'Desabilitado' },
  ];

  const optionsOwnerType = [
    { value: 'SHOP', label: 'Geral' },
    { value: 'SELLER', label: 'Fornecedor' },
  ];

  const [getSeller, { data: dataSeller }] = useLazyQuery(GET_SELLERS);

  const { data } = useQuery(GET_REGION_BY_ID, {
    variables: { regionId: id },
    skip: !edit,
    onError: (error) => {
      if(error.graphQLErrors[0].extensions['code'] === 'FORBIDDEN')
        history.push('/campanhas/regioes')
    }
  });

  const [createRegion, { loading: loadingCreate }] = useMutation(
    CREATE_REGION,
    {
      onCompleted: (data) => handleConflict(data?.createRegion?.error),
    },
  );

  const [updateRegion, { loading: loadingEdit }] = useMutation(UPDATE_REGION, {
    onCompleted: (data) => handleConflict(data?.updateRegion?.error),
  });

  function handleConflict(data) {
    if (data === 'REGION_CONFLICT') {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Já existe uma região ativa com esse nome.
        </Modal>,
      );
    } else {
      window.location.href = '/campanhas/regioes/';
    }
  }

  if (data?.getRegion && region === null) {
    setRegion(data.getRegion);
  }

  function handleSeller(newValue) {
    if (newValue) {
      if (dataSeller?.getSellerByName) {
        const Seller = dataSeller.getSellerByName.find(
          (item) => item.name === newValue,
        );
        setRegion({
          ...region,
          seller: Seller,
        });
        if (Seller.companyName != data?.getRegion?.seller?.name && edit) {
          setModal(
            <Modal title="Você está substituindo um fornecedor nessa região.">
              <div className="modal--button">
                <button onClick={() => setModal(null)} className="button">
                  Ok, entendi!
                </button>
              </div>
            </Modal>,
          );
        }
      }
    } else {
      setRegion({
        ...region,
        seller: null,
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!region?.name || !region?.description) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Preencha todos os campos.
        </Modal>,
      );
    } else if (region?.ownerType === 'SELLER' && !region?.seller?.id) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Adicione um fornecedor para aplicar a região.
        </Modal>,
      );
    } else if (!region?.postalCodes?.length) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Adicione pelo menos uma faixa de cep.
        </Modal>,
      );
    }

    function handleOwnerType() {
      if (region.ownerType === 'SELLER' || isSeller) {
        return 'SELLER';
      } else {
        return 'SHOP';
      }
    }

    function handleOwnerId() {
      if (region.ownerType === 'SELLER' && !isSeller) {
        return region.seller.id;
      } else {
        return null;
      }
    }

    let variables = {
      name: region.name,
      description: region.description,
      ownerType: handleOwnerType(),
      ownerId: handleOwnerId(),
      active: region.active != null ? region.active : true,
      postalCodes: region.postalCodes.map((r) => {
        return {
          initialPostalCode: parseInt(r.initialPostalCode),
          finalPostalCode: parseInt(r.finalPostalCode),
        };
      }),
    };

    if (edit) {
      variables = {
        ...variables,
        id: parseInt(region.id),
      };
      return updateRegion({ variables });
    }

    return createRegion({ variables });
  }

  return (
    <>
      {(role || isSeller) && (
        <>
          <div className="form-region">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="form-register form-register--product"
            >
              <OutlinedInput
                className="form-register__input--name"
                placeholder="Nome"
                id="name"
                maxlength="30"
                value={region?.name || ''}
                border
                onChange={(e) =>
                  setRegion({
                    ...region,
                    name: e.target.value,
                  })
                }
              />
              <OutlinedTextarea
                placeholder="Descrição"
                value={region?.description}
                onChange={(e) =>
                  setRegion({
                    ...region,
                    description: e.target.value,
                  })
                }
              />

              {!isSeller && (
                <div className="dropdown-label-wrap">
                  <Dropdown
                    options={optionsOwnerType}
                    onChange={(e) =>
                      setRegion({
                        ...region,
                        ownerType: e.value,
                      })
                    }
                    value={
                      optionsOwnerType.find(
                        (o) => o.value === region?.ownerType,
                      ) || optionsOwnerType[0]
                    }
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    placeholderClassName="dropdown-ifood__placeholder"
                    menuClassName="dropdown-ifood__menu"
                    arrowClassName="dropdown-ifood__arrow"
                  />
                  <label className="dropdown-label">Proprietário</label>
                </div>
              )}

              {region?.ownerType === 'SELLER' && !isSeller && (
                <div className="dropdown-label-wrap">
                  <Autocomplete
                    id="sellerSearch"
                    freeSolo
                    onChange={(event, newValue) => handleSeller(newValue)}
                    className="dropdown-ifood form-search__autocomplete"
                    value={data?.getRegion?.seller?.name || ''}
                    noOptionsText="Digite para procurar"
                    options={
                      dataSeller?.getSellerByName
                        ? dataSeller?.getSellerByName.map(
                            (option) => option.name,
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextField
                        className="form-search__autocomplete"
                        {...params}
                        label="Digite aqui para buscar um fornecedor"
                        variant="outlined"
                        onChange={(e) =>
                          getSeller({ variables: { name: e.target.value } })
                        }
                      />
                    )}
                  />
                </div>
              )}

              <div className="dropdown-label-wrap">
                <Dropdown
                  options={optionsActive}
                  onChange={(e) =>
                    setRegion({
                      ...region,
                      active: e.value,
                    })
                  }
                  value={
                    optionsActive.find((o) => o.value === region?.active) ||
                    optionsActive[0]
                  }
                  className="dropdown-ifood"
                  controlClassName="dropdown-ifood__control"
                  placeholderClassName="dropdown-ifood__placeholder"
                  menuClassName="dropdown-ifood__menu"
                  arrowClassName="dropdown-ifood__arrow"
                />
                <label className="dropdown-label">Estado</label>
              </div>
              <PostalCodesAdd
                ruleList={region?.postalCodes || []}
                onChangeList={(postalCodes) =>
                  setRegion({
                    ...region,
                    postalCodes,
                  })
                }
              />
              <div className="form-register__button-save">
                <Button type="submit">Salvar</Button>
              </div>
            </form>
          </div>
        </>
      )}
      {loadingCreate || (loadingEdit && <Loading />)}
      {modal}
    </>
  );
};

export default FormRegisterRegion;
