export enum EnumHeaderOptionsType {
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
}

type DownloadHeaderOption = {
  name: string;
  value: string;
  template: string;
  roles: string[];
  action?: (callback?: { closeModal: (value: any) => void }) => ReactNode;
  type: EnumHeaderOptionsType.DOWNLOAD;
};

type ImportHeaderOption = {
  name: string;
  value: string;
  roles: string[];
  action?: (callback?: { closeModal: (value: any) => void }) => ReactNode;
  type: EnumHeaderOptionsType.UPLOAD;
  template?: string;
};

export type HeaderOptionsType = DownloadHeaderOption | ImportHeaderOption;
