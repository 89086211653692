import { ApolloClient, InMemoryCache, createHttpLink, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const urlGraphqlLocal = 'http://localhost:3001/graphql';
export const shouldMockApollo = process.env.REACT_APP_MOCK && process.env.NODE_ENV === 'development';
// eslint-disable-next-line prettier/prettier
const urlGraphql = shouldMockApollo
  ? urlGraphqlLocal
  : window.config?.AdminGraphQL ?? process.env.REACT_APP_URL_GRAPHQL ?? 'http://localhost:3001/graphql';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: { ...headers, authorization: token ? `Bearer ${token}` : '' },
  };
});

const httpLink = createHttpLink({ uri: urlGraphql });
const fetchLink = new HttpLink({
  uri: urlGraphqlLocal,
  fetch: (...args) => fetch(...args),
});

export default new ApolloClient({
  link: shouldMockApollo ? fetchLink : ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});
