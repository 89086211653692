import gql from 'graphql-tag';

const CREATE_REGION = gql `
  mutation createRegion(
    $name: String
    $description: String
    $active: Boolean
    $ownerType: String
    $postalCodes: [PostalCodeInput]
    $ownerId: Int
  ) {
    createRegion(
      input: {
        name: $name,
        description: $description,
        active: $active,
        ownerType: $ownerType,
        postalCodes: $postalCodes,
        ownerId: $ownerId 
      }
    ) {
      error
    }
  }
`;

const UPDATE_REGION = gql `
  mutation updateRegion (
    $id: ID!
    $name: String
    $description: String
    $active: Boolean
    $ownerType: String
    $postalCodes: [PostalCodeInput]
    $ownerId: Int
  ){
    updateRegion(
      input: {
        id: $id,
        name: $name,
        description: $description,
        active: $active,
        ownerType: $ownerType,
        postalCodes: $postalCodes,
        ownerId: $ownerId 
      }
    ) {
      error
    }
  }
`;

export {CREATE_REGION, UPDATE_REGION};
