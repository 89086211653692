/* istanbul ignore file */
export const optionsUnits = [
  'Unidade',
  'Kg',
  'Litro',
  'Caixa',
  'Pacote',
  'Bandeja',
  'Galão',
  'Dúzia',
  'Rolo',
  'gr',
  'Fardo',
  'Peça',
  'Display',
  'Embalagem',
  'Cartela',
  'Cesta',
  'Garrafa',
  'Lata',
  'Pote',
  'Sachê',
  'Saco',
  'Kit',
  'Conjunto',
];
