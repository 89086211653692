import { Fragment, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link, Modal, Button, Typography } from 'ifoodshop-react-ui';
import ReactPaginate from 'react-paginate';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Download } from '@assets/icons/download.svg';
import { ReactComponent as DownloadGrey } from '@assets/icons/download-grey.svg';
import { ReactComponent as Paper } from '@assets/icons/paper.svg';
import { ReactComponent as Search } from '@assets/icons/search.svg';
import { ReactComponent as User } from '@assets/icons/user_red.svg';
import { ReactComponent as Trash } from '@assets/icons/trash_red.svg';
import { ReactComponent as TrashGrey } from '@assets/icons/trash-disable.svg';
import { ReactComponent as Clear } from '@assets/icons/clear.svg';
import { Flex } from '@ifood/pomodoro-components';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { DELETE_CUSTOMER, GET_CUSTOMERS, GET_CUSTOMERGROUP } from '@graphql/customer.queries';
import TableCustomer from './TableCustomer';
import AddCustomer from './AddCustomer';
import Loading from '@components/Loading';
import LabelSuccess from '@components/LabelSuccess';
import Roles from '@helpers/Roles';
import IconArrow from '@assets/images/arrow.png';
import { ReactComponent as ChevronDown } from '@assets/icons/chevron-down.svg';
import './headerCustomerGroup.scss';
import '../../../CostumerGroup/pagination.scss';
import './customerGroupDetail.scss';
import { getRoles } from '@helpers/rolesUtils';
import { EmptStateClientGroup } from '../../../EmptState';
import { FallbackSearch } from '../../../FallbackSearch';
import { api } from '@services/api';

const CostumerGroupDetail = props => {
  const [customerGroupCustomers, setCustomerGroupCustomers] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [groupDesc, setGroupDesc] = useState(null);
  const [state, setState] = useState();
  const [modal, setModal] = useState(null);
  const [srcInput, setSrcInput] = useState();

  const [dataImport, setDataImport] = useState(null);
  const [dataBatchRemove, setDataBatchRemove] = useState(null);
  const [seeMore, setSeeMore] = useState(false);
  const [showSearchFallback, setShowSearchFallback] = useState(false);
  const [showingResults, setShowingResults] = useState(false);
  const wrapperRef = useRef(null);
  const [optionsSelected, setOptionsSelected] = useState([]);

  const [paginationState, setPaginationState] = useState({ page: 0, pageSize: 25, totalPages: 0 });

  const root = document.getElementById('root');
  root.addEventListener('click', e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSeeMore(false);
    }
  });

  const idGroup = parseInt(props.match.params.id);
  const roles = getRoles();
  const roleCustomer = roles.includes(Roles.CUSTOMER_WR);
  const roleCustomerReadOnly = roles.includes(Roles.CUSTOMER_RO);
  const maxLengthNumberToDescription = 52;

  if (!roleCustomerReadOnly && !roleCustomer) {
    window.location.href = '/';
  }

  const isEmptCustomerList = useMemo(() => customerGroupCustomers?.length === 0, [customerGroupCustomers]);

  const [getGroup, { loading: loadingCustomerGroup, data: dataCustomerGroup }] = useLazyQuery(GET_CUSTOMERGROUP);
  if (dataCustomerGroup && groupName === null) {
    const { name, description, active } = dataCustomerGroup.getCustomerGroup;
    setGroupName(name);
    setGroupDesc(description);
    if (active) {
      setState('Habilitado');
    } else {
      setState('Desabilitado');
    }
  }

  const [getCustomer, { loading: loadingCustomers, refetch: refetchCustomers }] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      const { content, totalPages } = res?.getAllCustomerGroupCustomers || {};

      setCustomerGroupCustomers(content);
      setPaginationState(prevState => ({ ...prevState, totalPages: totalPages }));

      setShowSearchFallback(!content?.length && (srcInput || paginationState.page > 0));
    },
  });

  const [deleteCustomer, { loading: loadingDeleteCustomer, data: dataDeleteCustomer }] = useMutation(DELETE_CUSTOMER);
  if (dataDeleteCustomer) {
    window.location.reload();
  }

  const handleSearch = useCallback(
    (page = 0, input = null) => {
      getCustomer({
        variables: {
          pageRequest: page,
          pageSize: paginationState.pageSize,
          valueSrcInput: input,
          idGroup: idGroup,
        },
      });

      setPaginationState(prevState => ({ ...prevState, page: page }));
    },

    [idGroup, srcInput, paginationState]
  );

  useEffect(() => getGroup({ variables: { idGroup: idGroup } }), []);

  useEffect(() => handleSearch(), []);

  const fallBackRemocao = () => {
    setModal(
      <Modal
        className={'fallBackModal'}
        onCloseAction={() => setModal(null)}
        title="Não é possível fazer a remoção de clientes"
      >
        <div className="fallback">
          <Typography size={'16px'} color={'heavygrey'} line={'24px'}>
            Você só pode fazer a remoção quando você tiver clientes incluídos neste grupo.
          </Typography>

          <div>
            <Button onClick={() => setModal(null)}>Ok, entendi</Button>
          </div>
        </div>
      </Modal>
    );
  };

  const addCustomer = () => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title="Incluir novo cliente">
        <AddCustomer cancel={() => setModal(null)} idGroup={idGroup} />
      </Modal>
    );
  };

  const modalRemove = () => {
    setModal(
      <Modal
        onCloseAction={() => setModal(null)}
        className="modal-remove"
        title="Você tem certeza que deseja remover esse(s) clientes(s)?"
      >
        <span>Ao remover esse(s) clientes(s), essa ação não poderá ser desfeita. </span>
        <div className="modal-remove__actions">
          <Button className="modal-remove__actions__cancel" onClick={() => setModal(null)}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleteItemsChecked();
            }}
          >
            Sim, remover
          </Button>
        </div>
      </Modal>
    );
  };

  function deleteItemsChecked() {
    var checkboxes = document.getElementsByClassName('checkbox-ifood');
    var checkedItems = new Map();
    var arrayChekedItems = [];

    for (var x = 0; x < checkboxes.length; x++) {
      if (checkboxes[x].checked === true) {
        checkedItems.set(checkboxes[x].attributes.id.value, checkboxes[x].checked);
      }
    }
    checkedItems.forEach(function (value, key) {
      if (value === true) {
        arrayChekedItems.push(key);
        return key;
      }
    }, checkboxes);

    deleteCustomer({ variables: { listCustomerId: optionsSelected, idGroup: idGroup } });
  }

  const ImportCustomers = async e => {
    e.preventDefault();
    setModal(<Loading />);

    let formData = new FormData();
    const fileImport = e.target.files[0];
    formData.append(`file`, fileImport);

    api
      .post(`/group/${idGroup}/customer/import`, formData)
      .then(res => res.blob())
      .then(blob => handleBlobImportExport(blob, 'result-import.csv'))
      .catch(error => {
        setModal(<Modal onCloseAction={() => setModal(null)} title="Erro ao importar arquivo" />);
      });
  };

  const batchRemoveCustomers = async e => {
    if (customerGroupCustomers.length > 0) {
      setModal(<Loading />);

      var fileBatchRemove = e.target.files[0];
      var formData = new FormData();
      formData.append(`file`, fileBatchRemove);

      api
        .post(`/group/${idGroup}/customer/remove`, formData)
        .then(res => res.blob())
        .then(blob => handleBlobImportExport(blob, 'result-remove.csv'))
        .catch(error => {
          setModal(<Modal onCloseAction={() => setModal(null)} title="Erro ao importar arquivo" />);
        });
    } else {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>Não existe cliente para remover</p>
        </Modal>
      );
    }
  };

  const handleBlobImportExport = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
    setModal(null);
    refetchCustomersAfterThen();
  };

  const refetchCustomersAfterThen = () => {
    refetchCustomers()
      .then(res => setCustomerGroupCustomers(res.data.getAllCustomerGroupCustomers.content))
      .catch(error => {
        return error;
      });
  };

  const exportCustomers = useCallback(async (e, customers) => {
    e.preventDefault();
    if (customers?.length > 0) {
      setModal(<Loading />);

      api
        .get(`/group/${idGroup}/customer/export`)
        .then(res => res.blob())
        .then(blob => handleBlobImportExport(blob, 'export-customer-group.csv'))
        .catch(error => {
          setModal(<Modal onCloseAction={() => setModal(null)} title="Erro ao importar arquivo" />);
        });
    } else {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>Não existe nenhum cliente para exportar</p>
        </Modal>
      );
    }
  }, []);

  function seeMoreToggle() {
    if (seeMore) {
      setSeeMore(false);
    } else {
      setSeeMore(true);
    }
  }

  const clearSearch = useCallback(() => {
    setSrcInput('');
    handleSearch();
    setShowingResults(false);
  }, [handleSearch]);

  const handleClickCheckbox = useCallback(e => {
    setOptionsSelected(e);
  }, []);

  const fileUpload = useCallback(() => {
    document.getElementById('importFile').click();
  }, []);
  const batchRemove = useCallback(() => {
    document.getElementById('batchRemove').click();
  }, []);

  const ExportButton = useMemo(() => {
    if (isEmptCustomerList) {
      return (
        <Tooltip title="Você não possui clientes no grupo para exportar">
          <Button className={'header-customer-group__src__export__disabled'}>
            <DownloadGrey /> Exportar grupo
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        className={'header-customer-group__src__export'}
        onClick={e => {
          exportCustomers(e, customerGroupCustomers);
        }}
      >
        <Download /> Exportar grupo
      </Button>
    );
  }, [customerGroupCustomers, isEmptCustomerList]);

  const RemoveButton = useMemo(() => {
    if (optionsSelected.length === 0) {
      return (
        <Tooltip title="Selecione o(s) cliente(s) para conseguir remover">
          <Button color={'#A6A6A5'} className="button--disabled">
            <TrashGrey /> Remover cliente
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button onClick={() => modalRemove()}>
        <Trash /> Remover cliente
      </Button>
    );
  }, [optionsSelected]);

  const openDescriptionModal = useCallback(() => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} className="modal" title="Descrição do grupo">
        <div className="description">
          <span className={'description'}>{groupDesc}</span>
          <div>
            <Button onClick={() => setModal(null)}>Ok, entendi</Button>
          </div>
        </div>
      </Modal>
    );
  }, [groupDesc]);

  const renderPaginate = useMemo(() => {
    return (
      <div className="search-customer-paginate">
        {paginationState?.totalPages > 1 && (
          <ReactPaginate
            containerClassName="pagination-ifoodshop"
            pageClassName="pagination-ifoodshop__item"
            pageLinkClassName="pagination-ifoodshop__item-link"
            activeLinkClassName="active"
            previousLabel={<img className="previous-arrow" src={IconArrow} />}
            nextLabel={<img className="next-arrow" src={IconArrow} />}
            onPageChange={({ selected }) => selected !== paginationState.page && handleSearch(selected, srcInput)}
            pageRangeDisplayed={3}
            pageCount={paginationState?.totalPages}
            marginPagesDisplayed={1}
            initialPage={paginationState.page}
            forcePage={paginationState.page}
          />
        )}
      </div>
    );
  }, [paginationState, srcInput]);

  return (
    <Fragment>
      {(roleCustomerReadOnly || roleCustomer) && (
        <>
          <input
            type="file"
            id="importFile"
            className="header-customer-group__actions-import"
            onChange={e => ImportCustomers(e)}
          />
          <input
            type="file"
            id="batchRemove"
            className="header-customer-group__actions-import"
            onChange={e => batchRemoveCustomers(e)}
          />
          <NavLink to="/clientes/grupos-clientes/" className="go-back" />
          <div className="content-body customer-detail" ref={wrapperRef}>
            <div className="header-customer-group header-customer-group--detail">
              {modal}
              <div className="header-customer-group__wrapper">
                <div className="header-customer-group__info">
                  <div>
                    <h2 className="section-title">{groupName}</h2>
                  </div>
                  <span
                    className={
                      state === 'Habilitado'
                        ? 'header-customer-group__status habilitado'
                        : 'header-customer-group__status desabilitado'
                    }
                  >
                    {state}
                  </span>
                </div>
                {groupDesc?.length >= maxLengthNumberToDescription ? (
                  <div className="description-container">
                    <p className="header-customer-group__description">{groupDesc}</p>{' '}
                    <span className={'see-more'} onClick={() => openDescriptionModal()}>
                      Ver mais
                    </span>
                  </div>
                ) : (
                  <p className="header-customer-group__description">{groupDesc}</p>
                )}
              </div>
              <div className="header-customer-group__src">
                {ExportButton}

                <div className="header-customer-group__src__import-container">
                  <Button className={'header-customer-group__src__import'} onClick={() => seeMoreToggle()}>
                    <Paper />
                    <label>Importar lista</label>
                  </Button>

                  {seeMore && (
                    <div className="header-customer-group__src__import-container__options">
                      <Button onClick={fileUpload}>Para inclusão de clientes</Button>
                      <Button
                        className={'header-customer-group__src__import-container__options__remove'}
                        onClick={isEmptCustomerList ? fallBackRemocao : batchRemove}
                      >
                        Para remoção de clientes
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="header-customer-group__actions">
                {roleCustomer ? (
                  <>
                    <NavLink
                      className="header-customer-group__actions-link link"
                      to={'/clientes/grupos-clientes/editar/' + idGroup}
                    >
                      Editar informações
                    </NavLink>
                  </>
                ) : (
                  <>
                    <Tooltip title="você não tem permissão para editar este grupo">
                      <a className="header-customer-group__actions-link link--disabled">Editar informação</a>
                    </Tooltip>
                    <Tooltip title="você não tem permissão para estas ações">
                      <div ref={wrapperRef}>
                        <a className="header-customer-group__actions-link link--disabled">Selecionar todos</a>
                        <a className="header-customer-group__actions-link link--disabled">Adicionar usuário</a>
                        <a className="header-customer-group__actions-link link--disabled">Remover usuário</a>
                        <Link
                          className="header-customer-group__actions-link link-see-more"
                          onClick={() => seeMoreToggle()}
                        >
                          Ver mais ações{' '}
                          <ChevronDown fill="#EA1D2C" className={seeMore ? 'chevron-down open' : 'chevron-down'} />
                        </Link>
                        {seeMore ? (
                          <div className="header-customer-group__actions-more">
                            <label className="link header-customer-group__actions-link link--disabled">
                              Importar lista
                            </label>
                            <Link className="header-customer-group__actions-link link--disabled">Exportar lista</Link>
                            <label className="link header-customer-group__actions-link link--disabled">
                              Remover em lote
                            </label>
                            <Link className="header-customer-group__actions-link link--disabled">
                              Modelo para importação
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>

            <div className="subHeader">
              <Flex className="divider">
                <div className="subHeader__search">
                  <Search className="icon-search" />
                  <input
                    type="text"
                    placeholder="Pesquise por Email"
                    value={srcInput}
                    onChange={e => {
                      if (e.target.value.length === 0) {
                        clearSearch();
                      } else {
                        setSrcInput(e.target.value);
                      }
                    }}
                    className="input-secondary input__icon input__icon-src"
                  />
                  {srcInput && <Clear className="clear" onClick={clearSearch} />}
                </div>
                <Button
                  onClick={() => {
                    handleSearch(0, srcInput);
                    setShowingResults(true);
                  }}
                  disabled={!srcInput}
                >
                  Buscar
                </Button>
              </Flex>
              <div className="subHeader__actions">
                <Button onClick={() => addCustomer()}>
                  <User />
                  Incluir cliente
                </Button>
                {RemoveButton}
              </div>
            </div>

            {showingResults && (
              <div className={'results-label'}>
                <Typography color={'#3E3E3E'} line={'16px'} size={'14px'}>
                  Resultados encontrados:
                </Typography>
              </div>
            )}

            <div className="table-box">
              {showSearchFallback ? (
                <FallbackSearch />
              ) : !isEmptCustomerList ? (
                <>
                  <TableCustomer
                    customers={customerGroupCustomers}
                    role={roleCustomer}
                    onClick={e => handleClickCheckbox(e)}
                  />
                  {renderPaginate}
                </>
              ) : (
                <EmptStateClientGroup />
              )}
              {dataImport ||
                (dataBatchRemove && (
                  <LabelSuccess
                    success
                    content={dataImport ? 'Lista importada com sucesso' : 'Clientes removidos com sucesso'}
                  />
                ))}
            </div>
          </div>
        </>
      )}
      {(loadingCustomerGroup || loadingCustomers || loadingDeleteCustomer) && <Loading />}
    </Fragment>
  );
};

export default CostumerGroupDetail;
