import { PASSWORD_REGEX } from '@components/PasswordCriterea/PasswordCriterea';
import { RegistrationFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import * as yup from 'yup';

type RegistrationDataSchemaT = { [key in keyof RegistrationFormValueType]: any };
export type BasicFieldsSchemaT = Omit<RegistrationDataSchemaT, 'document' | 'password' | 'confirmPassword'>;

export const basicFieldsSchema = yup.object().shape({
  sellerName: yup.string().required('Fornecedor é obrigatório.'),
  companyName: yup.string().required('Razão Social é obrigatória.'),
  cnae: yup.string().required('CNAE é obrigatório.'),
  businessType: yup.string().required('Tipo de negócio é obrigatório.'),
  segment: yup.string().required('Segmento é obrigatório.'),
  stateRegistration: yup.string().nullable(),
  cityRegistration: yup.string().nullable(),
  email: yup.string().required('E-mail é obrigatório.').email('Formato de email inválido.'),
  integrated: yup.string().required('Integrado é obrigatório.').nullable(),
  isNewIntegration: yup.string().required('Nova integração é obrigatória.').nullable(),
  isTestMerchant: yup.string().required('Loja teste é obrigatório.').nullable(),
} as BasicFieldsSchemaT);

// SCHEMA
const schema = yup.object().shape({
  document: yup.string().required('CNPJ é obrigatório.'),
  password: yup.string().required('Senha é obrigatório.').matches(PASSWORD_REGEX, 'Padrão de senha incorreta.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais.')
    .required('Confirmar senha é obrigatório.'),
  ...basicFieldsSchema.fields,
} as RegistrationDataSchemaT);

export default schema;
