import React from 'react';
import { Flex, Input } from '@ifood/pomodoro-components';
import { UseFormReturn } from 'react-hook-form';
import { RegistrationFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';

type LegalRegistrationFormFieldsT = {
  form: UseFormReturn<RegistrationFormValueType, any>;
};

export default function LegalRegistrationDataFormFields({ form }: LegalRegistrationFormFieldsT) {
  const { register, formState } = form;
  const { errors } = formState;

  return (
    <Flex flexDirection="column">
      <Input
        {...register('stateRegistration')}
        error={errors?.stateRegistration?.message}
        label="Inscrição estadual"
        marginBottom="large"
        name="stateRegistration"
      />
      <Input
        {...register('cityRegistration')}
        error={errors?.cityRegistration?.message}
        label="Inscrição municipal"
        marginBottom="large"
        name="cityRegistration"
      />
    </Flex>
  );
}
