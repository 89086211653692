import React from 'react';
import './radioInput.scss';

const RadioButton = props => {
	return (
		<div className="radio-input">
			<label className="radio">
				<input type="radio" {...props} />
				<span></span>
			</label>
		</div>
	);
};

export default RadioButton;
