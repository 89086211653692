import { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_CUSTOMERSGROUP } from '@graphql/customer.queries';
import ReactPaginate from 'react-paginate';
import Tooltip from '@material-ui/core/Tooltip';
import IconArrow from '@assets/images/arrow.png';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import FilterCustomerTable from './components/FilterCustomerTable';
import CustomerGroupFilters from './components/CustomerGroupFilters';

import '../../../components/HeaderGroups/header-groups.scss';
import './pagination.scss';
import 'react-dropdown/style.css';
import '@components/DropDownIfood/dropdown-ifood.scss';

const CustomerGroup = () => {
  const [variables, setVariables] = useState({
    page: 0,
    pageSize: 25,
    state: true,
  });

  const { data } = useQuery(GET_ALL_CUSTOMERSGROUP, {
    variables,
  });

  const roles = getRoles();
  const roleCustomer = roles.includes(Roles.CUSTOMER_WR);
  const roleCustomerReadOnly = roles.includes(Roles.CUSTOMER_RO);

  if (!roleCustomerReadOnly && !roleCustomer) {
    window.location.href = '/'
  }

  return (
    <>
      {(roleCustomerReadOnly || roleCustomer) && (
        <>
          <NavLink to="/clientes" className="go-back" />
          <div className="content-body">
            <div className="header-customer-group header-customer-group--src">
              <div className="header-customer-group__info">
                <h2 className="section-title">Grupos de clientes</h2>
                <p className="header-customer-group__description">Descrição do grupo</p>
              </div>
              <div className="header-customer-group__new">
                {roleCustomer ? (
                  <NavLink to="/clientes/grupos-clientes/cadastro" className="link">
                    Criar novo grupo
                  </NavLink>
                ) : (
                  <Tooltip title="você não tem permissão criar novo grupo">
                    <a className="link--disabled">Criar novo grupo</a>
                  </Tooltip>
                )}
              </div>
              <CustomerGroupFilters
                onSubmitFilters={e =>
                  setVariables({
                    ...variables,
                    page: 0,
                    groupName: e.groupName || '',
                    state: e.state,
                  })
                }
              />
            </div>

            <div className="table-box">
              {data?.getAllCustomerGroups?.content.length > 0 && (
                <>
                  <FilterCustomerTable customerGroups={data.getAllCustomerGroups.content} />
                  {data?.getAllCustomerGroups?.totalPages > 1 && (
                    <div className="search-customer-paginate">
                      <ReactPaginate
                        containerClassName="pagination-ifoodshop"
                        pageClassName="pagination-ifoodshop__item"
                        pageLinkClassName="pagination-ifoodshop__item-link"
                        activeLinkClassName="active"
                        previousLabel={<img className="previous-arrow" src={IconArrow} />}
                        nextLabel={<img className="next-arrow" src={IconArrow} />}
                        onPageChange={e =>
                          setVariables({
                            ...variables,
                            page: e.selected,
                          })
                        }
                        pageRangeDisplayed={3}
                        pageCount={data.getAllCustomerGroups.totalPages}
                        marginPagesDisplayed={1}
                        initialPage={variables.page}
                        forcePage={data.getAllCustomerGroups.number}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(CustomerGroup);
