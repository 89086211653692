import React, { useState } from 'react';
import IconArrow from '../../../assets/images/arrow.png';
import ReactPaginate from 'react-paginate';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../Loading';
import { GET_ALL_MINPRICE } from '../../../graphql/price_minimum.queries';
import FormSearchPriceMinimum from '../FormSearchPriceMinimum';
import TableSearchPriceMinimum from '../TableSearchPriceMinimum';

const ListPriceMinimum = ({ role, isSeller }) => {

  const [variables, setVariables] = useState({
    page: 0,
    size: 10,
    active: true,
    sellerId: null,
  });

  const { data, loading, refetch } = useQuery(GET_ALL_MINPRICE, {
    variables,
  });

  return (
    <>
      <FormSearchPriceMinimum
        isSeller={isSeller}
        onChangeFilter={(e) =>
          setVariables({
            ...variables,
            page: 0,
            active: e.active,
            regionId: e.regionId,
            sellerId: e.sellerId
          })
        }
      />
      <TableSearchPriceMinimum
        data={data?.getAllMinPrice?.content || []}
        role={role}
        isSeller={isSeller}
        refetch={refetch}
      />
      <div className="search-customer-paginate">
        {data?.getAllMinPrice?.totalPages > 1 && (
          <ReactPaginate
            containerClassName="pagination-ifoodshop"
            pageClassName="pagination-ifoodshop__item"
            pageLinkClassName="pagination-ifoodshop__item-link"
            activeLinkClassName="active"
            previousLabel={<img className="previous-arrow" src={IconArrow} />}
            nextLabel={<img className="next-arrow" src={IconArrow} />}
            onPageChange={(e) =>
              setVariables({ ...variables, page: e.selected })
            }
            pageRangeDisplayed={3}
            pageCount={data?.getAllMinPrice?.totalPages}
            marginPagesDisplayed={1}
            initialPage={variables.page}
            forcePage={data.getAllMinPrice.number}
          />
        )}
      </div>
      {loading && <Loading />}
    </>
  );
};

export default ListPriceMinimum;
