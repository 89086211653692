import gql from "graphql-tag";

const GET_LOGISTIC_TYPES_PAGINATED = gql`
  query getLogisticTypesPaginated($page: Int, $size: Int) {
    getLogisticTypesPaginated(page: $page, size: $size) {
      content {
        id
        active
        name
        infoFileLink
      }
      empty
      first
      last
      number
      numberOfElements
      size
      totalElements
      totalPages
    }
  }
`;

const GET_ALL_CARRIERS = gql`
  query getAllCarriers ($name: String, $sellerId: Int, $active: Boolean, $page: Int, $size: Int) {
    getAllCarriers(name: $name, sellerId: $sellerId, active: $active, page: $page, size: $size) {
      content{
        id
        name
        active
        seller {
          id
          name
        }
        address {
          id
          carrierId
          postalCode
          address
          number
          complement
          neighborhood
          city
          state
        }
        type
        carrierType
        additionalDeliveryTime
        printBatchScheduleLabels
        warning
        code
        owner
        mobilePhone
        email
        phone
        branch
        ramal
        cubingFactor
        pickup
        lotLabel
        document
        stateRegistration
        cityRegistration
        site
        utils
        notes
      }
      totalPages
      size
      number
    }
  }
`;

const GET_CARRIER_BY_ID = gql`
  query getCarrierById($id: ID!) {
    getCarrierById(id: $id) {
      id
      name
      active
      seller {
        id
        name
      }
      address {
        id
        carrierId
        postalCode
        address
        number
        complement
        neighborhood
        city
        state
      }
      carrierPeriods{
      id
      name
      title
      carrierId
      startTime
      endTime
      }
      type
      carrierType
      additionalDeliveryTime
      warning
      code
      owner
      mobilePhone
      printBatchScheduleLabels
      email
      phone
      branch
      cubingFactor
      pickup
      lotLabel
      document
      stateRegistration
      cityRegistration
      site
      utils
      notes
    }

  }`;

const GET_FREIGHT_VALUE_BY_CARRIER_ID_PAGED = gql`
query getAllFreightValueByCarrierIdPaged(
  $carrierId: ID!,
  $offset: Int,
  $page: Int,
  $size: Int,
  $postcodeStart: Int,
  $postcodeEnd: Int,
  $region: String) {
    getAllFreightValueByCarrierIdPaged(
    carrierId: $carrierId,
    offset: $offset,
    page: $page,
    size: $size,
    postcodeStart: $postcodeStart,
    postcodeEnd: $postcodeEnd,
    region: $region
    ) {
      content {
        active
        additionalKGValue
        additionalPercentageValue
        carrierId
        deliveryTimeInHours
        initialPostalCode
        finalPostalCode
        region
        saturdayDelivery
        weightRange {
          id
          cost
          finalWeight
          initialWeight
          value
          volume
        }
        freightIdLegacy
      }
      totalElements,
      totalPages,
      last,
      size,
      number,
      first
    }
  }
`;

const GET_FREIGHT_ID_LEGACY = gql`
query getFreightIdLegacy(
  $freightIdLegacy: ID!,
 ) {
  getFreightIdLegacy(
    freightIdLegacy: $freightIdLegacy
    ) {
      active
      additionalKGValue
      additionalPercentageValue
      carrierId
      freightIdLegacy
      saturdayDelivery
      initialPostalCode
      finalPostalCode
      weightRange {
        id
        cost
        value
        volume
        initialWeight
        finalWeight
      }
    }
  }
`;


  const GET_CARRIER_DELIVERY_TYPE = gql`
   query getCarrierDeliveryType {
	  getCarrierDeliveryType
    }
  `;

export {
  GET_LOGISTIC_TYPES_PAGINATED,
  GET_ALL_CARRIERS, GET_CARRIER_BY_ID,
  GET_CARRIER_DELIVERY_TYPE,
  GET_FREIGHT_VALUE_BY_CARRIER_ID_PAGED,
  GET_FREIGHT_ID_LEGACY
};
