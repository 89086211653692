import { FC, ReactNode } from 'react';
import * as S from './Timeline.style';

interface ITimelineProps {
  children: ReactNode;
}

export const Timeline: FC<ITimelineProps> = props => {
  const { children } = props;
  return <S.Wrapper>{children}</S.Wrapper>;
};
export const TimelineItem: FC<ITimelineProps> = props => {
  const { children } = props;
  return <S.Item>{children}</S.Item>;
};
