import { useEffect, useState } from 'react';
import Checkbox from '@components/Checkbox';
import { Button, Link, Modal } from 'ifoodshop-react-ui';
import { useDataSellerContext } from '../../DataSellerContext';
import { cloneDeep } from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SET_LOGISTIC_TYPES_ALLOWED, ACCEPT_SHIPPING_BY_SHOP } from '@graphql/seller.mutations';
import Loading from '@components/Loading';
import './list-of-contracts.scss';
import { GET_LOGISTIC_TYPES_PAGINATED } from '@graphql/logistics.queries';
import { formatDataSeller } from '../dataSellerUtils';

const ListOfContracts = () => {
  const [contractList, setContractList] = useState([]);
  const [logisticAll, setLogisticAll] = useState([]);
  const [originLogistic, setOriginLogistic] = useState([]);
  const [buttonState, setButtonState] = useState(false);
  const [modal, setModal] = useState(null);
  const [accept, setAccept] = useState(true);
  const { setStep, sellerData, logisticTypes, logisticAllowedWR, setUpdatedLogisticTypes } = useDataSellerContext();

  const { data: logisticPaginated, loading: loadingPaginated } = useQuery(GET_LOGISTIC_TYPES_PAGINATED, {
    variables: { page: null, size: 20 },
  });

  const [updateLogisticTypes, { loading: loadingSetLogisticTypes }] = useMutation(SET_LOGISTIC_TYPES_ALLOWED, {
    onCompleted: data => {
      setStep(0);
    },
    onError: error => console.error(`[Error - updateLogisticTypes]: ${error.message}`),
  });

  const [acceptShipping, { loading: loadingAcceptShipping }] = useMutation(ACCEPT_SHIPPING_BY_SHOP, {
    onCompleted: () => {
      setStep(0);
    },
    onError: () => {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>Não foi possível habilitar a Logística iFood, por favor entre em contato com o seu executivo.</p>
        </Modal>
      );
    },
  });

  useEffect(() => {
    logisticTypes && setContractList(cloneDeep(logisticTypes?.getLogisticTypesAllowed.map(item => item.logistic)));
  }, [logisticTypes]);

  useEffect(() => {
    if (logisticPaginated && logisticTypes) {
      let logistics = logisticPaginated.getLogisticTypesPaginated.content
        .filter(item => item.active && item.name !== 'FULLSERVICE')
        .reduce((acc, item) => {
          const hasContract = logisticTypes.getLogisticTypesAllowed.find(contract => contract.logistic.id === item.id);

          return [
            ...acc,
            {
              ...item,
              selected: !!hasContract,
            },
          ];
        }, []);

      setLogisticAll(logistics);
      setOriginLogistic(cloneDeep(logistics));
    }
  }, [logisticPaginated, logisticTypes]);

  function handleCheck(item, index, e) {
    e.preventDefault();
    if (e.target.className === 'link link') downloadTable(item);
    else if (logisticAllowedWR) {
      let listItem = [...logisticAll];

      listItem[index].selected = !item.selected;

      setContractList(listItem);
    }
  }

  function handleButtonState(list) {
    const button = list.every(item => !item.selected);
    if (button) return false;
    return originLogistic.some(item =>
      logisticAll.find(contract => contract.id === item.id && contract.selected !== item.selected)
    );
  }

  useEffect(() => {
    setButtonState(handleButtonState(contractList));
  }, [contractList]);

  function handleListSubmit(e) {
    e.preventDefault();

    let ids = [];

    contractList.forEach(item => {
      if (item.selected && item.id) ids = [...ids, item.id];
    });

    if (!sellerData.shippingByShop && contractList.find(item => item.selected && item.id != 1)) {
      setUpdatedLogisticTypes(ids);
      setAccept(accept);

      acceptShipping({
        variables: {
          accept: accept,
          logisticTypes: ids,
        },
      });
    } else {
      updateLogisticTypes({
        variables: { ids: ids },
      });
    }
  }

  function downloadTable(item) {
    const link = document.createElement('a');
    link.href = item.infoFileLink;
    link.download = 'Correios.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      {logisticPaginated && contractList && !loadingPaginated && (
        <div className="contracts-list">
          <h2 className="contracts-list__title">Tipo de Contrato Logístico</h2>
          <h3 className="contracts-list__subtitle">Selecione abaixo qual o melhor contrato para sua necessidade</h3>
          <div className="contracts-list__items">
            {Object.values(logisticAll).map(
              (item, index) =>
                item.name.toUpperCase() !== 'CORREIOS' && (
                  <div
                    key={`${item.id}-${item.selected}`}
                    className={
                      logisticAllowedWR
                        ? `contracts-list__item ${item.selected ? 'contracts-list__item--active' : ''}`
                        : 'contracts-list__item contracts-list__item--disabled'
                    }
                    onClick={e => handleCheck(item, index, e)}
                  >
                    <Checkbox id={index} defaultChecked={item.selected} disabled={!logisticAllowedWR} />
                    <img
                      className={`logistic-img ${item.name === 'SELLER' ? 'logistic-img--default' : ''}`}
                      alt="logistica logo"
                      src={formatDataSeller('LOGO', item.name)}
                    ></img>
                    <label className="contracts-list__description">
                      <span className="contracts-list__item-title">{formatDataSeller('TITLE', item.name)}</span>
                      <span className="contracts-list__item-resume">{formatDataSeller('DESCRIPTION', item.name)}</span>
                      {item.name.toUpperCase() === 'CORREIOS' && (
                        <div className="file-download">
                          <span>Consulte a tabela de frete e prazo de entrega </span>
                          <Link className="link" disabled={logisticAllowedWR}>
                            aqui
                          </Link>
                        </div>
                      )}
                    </label>
                  </div>
                )
            )}
          </div>
          <>
            {modal}
            <div className="next-step">
              <Button
                className={`button ${!buttonState && 'button--disabled'}`}
                onClick={e => handleListSubmit(e)}
                disabled={!buttonState}
              >
                Confirmar
              </Button>
            </div>
          </>
        </div>
      )}
      {(loadingPaginated || loadingSetLogisticTypes || loadingAcceptShipping) && <Loading />}
    </>
  );
};

export default ListOfContracts;
