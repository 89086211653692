import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  UPDATE_COMMERCIAL_CONDITION,
  CREATE_COMMERCIAL_CONDITION,
} from '../../../../../../graphql/commercial_condition.mutations';
import { GET_COMMERCIAL_CONDITION_BY_ID } from '../../../../../../graphql/commercial_condition.queries';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import Loading from '../../../../../../components/Loading';
import RulesAdd from './RulesAdd';

import './formRegisterEditCommercialCondition.scss';
import { transformDateBR } from '@helpers/FormatHelper';

const FormEditRegisterCommercialCondition = ({ role, id }) => {
  const edit = id ? true : false;
  const [commercialCondition, setCommercialCondition] = useState(null);
  const [modal, setModal] = useState();

  const optionsActive = [
    { value: true, label: 'Habilitado' },
    { value: false, label: 'Desabilitado' },
  ];

  const [
    createCommercialCondition,
    { data: dataCreate, loading: loadingCreate },
  ] = useMutation(CREATE_COMMERCIAL_CONDITION);
  const [
    updateCommercialCondition,
    { data: dataEdit, loading: loadingEdit },
  ] = useMutation(UPDATE_COMMERCIAL_CONDITION);

  const { data } = useQuery(GET_COMMERCIAL_CONDITION_BY_ID, {
    variables: { commercialConditionId: id },
    skip: !edit,
  });

  if (dataCreate || dataEdit) {
    window.location.href = '/campanhas/condicao-comercial/';
  }

  if (data?.getCommercialConditionById && commercialCondition === null) {
    setCommercialCondition(data.getCommercialConditionById);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!commercialCondition?.name) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Preencha todos os campos.
        </Modal>,
      );
    } else if (!commercialCondition?.rules?.length) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Adicione pelo menos uma regra.
        </Modal>,
      );
    }

    let variables = {
      name: commercialCondition.name,
      isEnabled:
        commercialCondition.isEnabled != null
          ? commercialCondition.isEnabled
          : true,
      rules: commercialCondition.rules.map((r) => {
        return {
          regionId: parseInt(r.region.id),
          ruleFilter: r.ruleFilter,
          ruleType: r.ruleType,
        };
      }),
    };

    if (edit) {
      variables = {
        ...variables,
        id: parseInt(commercialCondition.id),
      };
      return updateCommercialCondition({ variables });
    }

    return createCommercialCondition({ variables });
  }

  return (
    <>
      {role && (
        <>
          <div className="form-commercialcondition">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="form-register form-register--product"
            >
              <OutlinedInput
                className="form-register__input"
                placeholder="Nome"
                id="name"
                value={commercialCondition?.name || ''}
                border
                onChange={(e) =>
                  setCommercialCondition({
                    ...commercialCondition,
                    name: e.target.value,
                  })
                }
              />
              {commercialCondition?.creationDate && edit && (
                <OutlinedInput
                  placeholder="Data de criação"
                  className="form-register__input"
                  disabled
                  value={transformDateBR(
                    commercialCondition?.creationDate || ''
                  )}
                  border
                />
              )}
              <div className="dropdown-label-wrap">
                <Dropdown
                  options={optionsActive}
                  onChange={(e) =>
                    setCommercialCondition({
                      ...commercialCondition,
                      isEnabled: e.value,
                    })
                  }
                  value={
                    optionsActive.find(
                      (o) => o.value === commercialCondition?.isEnabled,
                    ) || optionsActive[0]
                  }
                  className="dropdown-ifood"
                  controlClassName="dropdown-ifood__control"
                  placeholderClassName="dropdown-ifood__placeholder"
                  menuClassName="dropdown-ifood__menu"
                  arrowClassName="dropdown-ifood__arrow"
                />
                <label className="dropdown-label">Estado</label>
              </div>
              <RulesAdd
                ruleList={commercialCondition?.rules || []}
                onChangeList={(rules) =>
                  setCommercialCondition({
                    ...commercialCondition,
                    rules,
                  })
                }
              />
              <div className="form-register__button-save">
                <Button type="submit">Salvar</Button>
              </div>
            </form>
          </div>
        </>
      )}
      {loadingCreate || (loadingEdit && <Loading />)}
      {modal}
    </>
  );
};

export default FormEditRegisterCommercialCondition;
