import { Flex, Heading1 } from '@ifood/pomodoro-components';
import { useHistory } from 'react-router-dom';
import * as S from '../../CustomerDetail.styles';
import { BFFGetCustomerByIdQuery } from '@services/bff/GraphqlOperations';
import CustomerFraudReportModal from '@pages/Customers/CustomerDetail/components/CustomerDetailHeader/CustomerFraudReportModal';
import CustomerFraudFinishModal from '@pages/Customers/CustomerDetail/components/CustomerDetailHeader/CustomerFraudFinishModal';
import CustomerRemoveFraudModal from './CustomerRemoveFraudModal';
import { useCustomerFraudModalsHook } from './customerFraudModalsHook';
import { CustomerFraudReportType } from './CustomerFraudReportModal/schema';

export type CustomerDetailHeaderT = {
  onEdit: () => void;
  onCancel: () => void;
  isEditMode: boolean;
  isFormValid: boolean;
  isSavingContact: boolean;
  customerData: BFFGetCustomerByIdQuery['getCustomerById'];
};

export function CustomerDetailHeader({
  onCancel,
  onEdit,
  isEditMode,
  isSavingContact,
  isFormValid,
  customerData,
}: CustomerDetailHeaderT) {
  const {
    modalFraudReport,
    setModalFraudReport,
    modalFraudFinishReport,
    setModalFraudFinishReport,
    modalFraudRemove,
    setModalFraudRemove,
    onSubmitFraudReport,
    modalFraudData,
    setModalFraudData,
  } = useCustomerFraudModalsHook();
  const history = useHistory();

  const handleClickFraud = (data: CustomerFraudReportType) => {
    setModalFraudData(data);
    setModalFraudFinishReport(true);
    setModalFraudReport(false);
  };

  const handleCloseFinish = () => {
    setModalFraudFinishReport(false);
  };

  const handleSubmitFinish = () => {
    onSubmitFraudReport(modalFraudData);
  };

  const handleCloseRemove = () => {
    setModalFraudRemove(false);
  };

  const handleCloseReport = () => {
    setModalFraudReport(false);
  };

  function onBack() {
    history.push('/clientes/listagem-clientes');
  }

  return (
    <>
      <S.GoBackArrow data-testid="back-button" type="button" onClick={onBack} />
      <S.Header>
        <Flex>
          <Heading1 fontWeight="medium" my={10} variant="regular">
            Clientes
          </Heading1>
        </Flex>

        {isEditMode && (
          <Flex alignItems="center" justifyContent="flex-end">
            <>
              <S.ButtonCancel variant="tertiary" onClick={onCancel}>
                Cancelar
              </S.ButtonCancel>
              <S.ButtonSave disabled={isSavingContact || !isFormValid} isLoading={isSavingContact} type="submit">
                Salvar
              </S.ButtonSave>
            </>
          </Flex>
        )}

        {!isEditMode && (
          <Flex alignItems="center" justifyContent="flex-end">
            <S.ButtonCancel variant="secondary" onClick={onEdit}>
              Editar dados
            </S.ButtonCancel>
            {customerData?.hasActiveFraud ? (
              <S.ButtonSave type="button" onClick={() => setModalFraudRemove(true)}>
                Remover Bloqueio
              </S.ButtonSave>
            ) : (
              <S.ButtonSave type="button" onClick={() => setModalFraudReport(true)}>
                Relatar fraude
              </S.ButtonSave>
            )}
          </Flex>
        )}
      </S.Header>

      <CustomerFraudReportModal isOpen={modalFraudReport} onClose={handleCloseReport} onNext={handleClickFraud} />

      <CustomerFraudFinishModal
        isOpen={modalFraudFinishReport}
        onClose={handleCloseFinish}
        onFinish={handleSubmitFinish}
      />

      <CustomerRemoveFraudModal isOpen={modalFraudRemove} onClose={handleCloseRemove} />
    </>
  );
}
