import gql from "graphql-tag";

const GET_ALL_MENUS = gql`
    query {
        getAllHnMenus {
            id
            name
        }
    }
`;

export {GET_ALL_MENUS};