// eslint-disable-next-line prettier/prettier
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Input,
  Loading,
  Toggle,
  Radio,
  Select,
  snackbar,
  DatePicker as PomodoroDatePicker,
} from '@ifood/pomodoro-components';
import { useForm, Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import Formatter, { dateFormatUS } from '@helpers/FormatHelper';

import {
  GET_SHOPPING_LIST,
  GET_CUISINES,
  CREATE_SHOPPING_LIST,
  UPDATE_SHOPPING_LIST,
  GET_CUSTOMERS_BY_PARAMS,
  GET_ESTABLISHMENTS_BY_NAME,
  GET_PRODUCT_VARIATIONS_BY_SELLER,
  GET_SELLERS,
} from '@pages/Catalogs/ShoppingListDetail/operations';
import {
  cleanPayload,
  formTextsConfig,
  LIST_OWNERS_CONFIG,
  shoppingListSchema,
} from '@pages/Catalogs/ShoppingListDetail/utils';
import apolloClient from '@services/apolloClient';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import MainContent from '@components/mainContent';
import MainHeader from '@components/mainHeader';
import FormAddItems from '@components/shoppingList/formAddItems';
import FormAddOwners from '@components/shoppingList/formAddOwners';
import {
  LIST_OWNERS,
  LIST_STATUS,
  LIST_OWNERS_KEYS,
  ShoppingList,
  ShoppingListInput,
} from '@pages/Catalogs/ShoppingListDetail/index.d';
import * as S from './style';
import listEmptyImage from '@images/empty-shopping-list.svg';

const ListDetail = (props: { history: string[] }): JSX.Element => {
  const roles = getRoles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ id: string }>();

  const shoppingListId = params.id;
  const timeOutTimer = 3000;
  const roleShoppingListWrite = roles.includes(Roles.SHOPPING_LIST_WR);
  const roleShoppingListReadOnly = roles.includes(Roles.SHOPPING_LIST_RO);

  const [listOwnersType, setListOwnersType] = useState<LIST_OWNERS>(LIST_OWNERS.CUSTOMER);

  const {
    register,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ShoppingList>({
    resolver: yupResolver(shoppingListSchema),
    mode: 'onBlur',
  });

  const startDateField = dateFormatUS(watch('rules.startDate'));
  const endDateField = dateFormatUS(watch('rules.endDate'));

  const [searchCustomer, { loading: loadingCustomers }] = useLazyQuery(GET_CUSTOMERS_BY_PARAMS, {
    client: apolloClient,
  });
  const [searchEstablishment, { loading: loadingEstablishments }] = useLazyQuery(GET_ESTABLISHMENTS_BY_NAME, {
    client: apolloClient,
  });
  const [searchSeller, { loading: loadingSeller }] = useLazyQuery(GET_SELLERS, {
    client: apolloClient,
  });
  const [searchProduct, { loading: loadingProduct }] = useLazyQuery(GET_PRODUCT_VARIATIONS_BY_SELLER, {
    client: apolloClient,
  });
  const [createShoppingList, { loading: onCreateLoading }] = useMutation(CREATE_SHOPPING_LIST, {
    client: apolloClient,
  });
  const [updateShoppingList, { loading: onUpdateLoading }] = useMutation(UPDATE_SHOPPING_LIST, {
    client: apolloClient,
  });

  const {
    data: { cuisines: { content: cuisineTypes = [] } = {} } = {},
    loading: cuisineTypesLoading,
    error: cuisineTypesError,
  } = useQuery(GET_CUISINES, {
    client: apolloClient,
    variables: {
      country: 'BR',
    },
  });

  const {
    data: { shoppingList: shoppingListData = {} } = {},
    loading: shoppingListLoading,
    error: shoppingListError,
  } = useQuery(GET_SHOPPING_LIST, {
    client: apolloClient,
    skip: !shoppingListId,
    variables: { shoppingListId },
    onCompleted: ({ shoppingList = {} }) => {
      const parsedResult = cleanPayload(shoppingList);
      reset({
        ...parsedResult,
        items: parsedResult.items.content.map((item: any) => ({
          ...item,
          uiId: uuid(),
        })),
        rules: {
          ...parsedResult.rules,
          cuisine: parsedResult?.rules?.cuisine || '',
          startDate: parsedResult?.rules?.startDate ? new Date(parsedResult?.rules?.startDate) : null,
          endDate: parsedResult?.rules?.endDate ? new Date(parsedResult?.rules?.endDate) : null,
        },
      });
    },
  });

  const formStateTexts = useMemo(
    () => (shoppingListId ? formTextsConfig.edit : formTextsConfig.insert),
    [shoppingListId]
  );

  const handleChangeListOwners = (event: ChangeEvent<HTMLInputElement>) => {
    setListOwnersType(event.target.value as LIST_OWNERS_KEYS);
  };

  const handleUpdateShoppingList = (updateShoppingListInput: ShoppingListInput) => {
    updateShoppingList({
      variables: { updateShoppingListInput },
    })
      .then(() => {
        snackbar({
          message: formStateTexts.successFeedback,
          autoClose: true,
          timeout: timeOutTimer,
        });
        setTimeout(() => {
          history.push(location.pathname);
        }, timeOutTimer);
      })
      .catch(() => {
        snackbar({
          variant: 'error',
          message: formStateTexts.errorFeedback,
          autoClose: true,
          timeout: timeOutTimer,
        });
      });
  };

  const handleCreateShoppingList = (createShoppingListInput: ShoppingListInput) => {
    createShoppingList({
      variables: { createShoppingListInput },
    })
      .then(() => {
        snackbar({
          message: formStateTexts.successFeedback,
          autoClose: true,
          timeout: timeOutTimer,
        });
        setTimeout(() => {
          history.push(location.pathname);
        }, timeOutTimer);
      })
      .catch(() => {
        snackbar({
          variant: 'error',
          message: formStateTexts.errorFeedback,
          autoClose: true,
          timeout: timeOutTimer,
        });
      });
  };

  const onSubmit = (formValues: ShoppingList) => {
    if (!roleShoppingListWrite) return false;
    const {
      id,
      name,
      status,
      items,
      rules: { ...rules },
    } = formValues;

    const shoppingListInput = {
      name,
      status,
      items:
        items?.map(item => ({
          id: item.id,
          variationId: item.variation.id,
          sellerId: item.seller?.id || null,
          quantity: 1,
        })) || [],
      rules: {
        ...rules,
        startDate: dateFormatUS(rules.startDate),
        endDate: dateFormatUS(rules.endDate),
        customerId: rules.customers?.map(customer => customer.id) || [],
        establishmentId: rules.establishments?.map(establishment => establishment.id) || [],
      },
    };

    id
      ? handleUpdateShoppingList({ ...shoppingListInput, id: Number(id) })
      : handleCreateShoppingList(shoppingListInput);
  };

  const ownerInput = {
    [LIST_OWNERS.CUSTOMER]: {
      query: searchCustomer,
      loading: loadingCustomers,
    },
    [LIST_OWNERS.ESTABLISHMENT]: {
      query: searchEstablishment,
      loading: loadingEstablishments,
    },
  };

  useEffect(() => {
    if (!shoppingListId) {
      setValue('status', LIST_STATUS.ENABLED);
    }
  }, [setValue, shoppingListId]);

  if (!roleShoppingListWrite && !roleShoppingListReadOnly) {
    props.history.push('');
  }

  const returnDateFormated = (date: Date) => {
    if (date) {
      const acceptedFormat = /^\d{2}\/\d{2}\/\d{4}$/;
      if (acceptedFormat.test(date.toString())) return date;
      return Formatter.format(date, 'simple-date');
    }
  };

  return (
    <>
      <MainHeader
        back="/catalogos/listas-sugeridas"
        subTitle={formStateTexts.headerSubTitle}
        title={formStateTexts.headerTitle}
      />
      <MainContent>
        {(shoppingListError || shoppingListData.error) && (
          <S.ResponseFallback
            description="Desculpe mas não conseguimos carregar essa lista"
            imgAlt="Imagem Erro ao obter lista"
            imgSrc={listEmptyImage}
            title="Ops, Tivemos um problema"
          />
        )}
        {shoppingListLoading && <Loading color="primary" variant="large" />}

        {!shoppingListLoading && !shoppingListError && !shoppingListData.error && (
          <form autoComplete="off" data-testid="list-detail-content" onSubmit={handleSubmit(onSubmit)}>
            <S.FormArea>
              <Controller control={control} name="id" render={() => <input type="hidden" />} />

              <Controller
                control={control}
                name="status"
                render={({ field: { ref, value, onChange } }) => (
                  <Toggle
                    ref={ref}
                    checked={value === LIST_STATUS.ENABLED}
                    color="primary"
                    label="Ativo"
                    onChange={({ target }) => {
                      onChange(target.checked ? LIST_STATUS.ENABLED : LIST_STATUS.DISABLED);
                    }}
                  />
                )}
              />
            </S.FormArea>

            <S.FormArea>
              <S.FormAreaTitle forwardedAs="h2" variant="medium">
                Dados gerais sobre a lista sugerida
              </S.FormAreaTitle>

              <S.FormAreaGroup>
                <Input
                  {...register('name')}
                  // @ts-ignore
                  error={errors?.name?.message || ''}
                  label="Dê um nome para a lista"
                />

                <Controller control={control} name="rules.id" render={() => <input type="hidden" />} />

                <Controller
                  control={control}
                  defaultValue=""
                  name="rules.cuisine"
                  render={({ field: { value, ...rest } }) => (
                    <div className="cuisine-input-wrapper">
                      <Select
                        {...rest}
                        // @ts-ignore
                        error={errors.rules?.cuisine?.message}
                        items={
                          cuisineTypesError
                            ? [{ name: 'Não foi possível obter os tipos de cozinha', value: '' }]
                            : cuisineTypes
                        }
                        label="Tipo de cozinha"
                        width={300}
                      />
                      {cuisineTypesLoading && <Loading color="primary" variant="medium" />}
                    </div>
                  )}
                />
              </S.FormAreaGroup>

              <S.FormAreaGroup>
                <Controller
                  control={control}
                  name="rules.startDate"
                  render={({ field }, { onChange, value } = field) => (
                    <PomodoroDatePicker
                      disabledDays={endDateField ? [{ after: endDateField }] : []}
                      format="dd/MM/yyyy"
                      label="Data Inicial"
                      value={returnDateFormated(value)}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="rules.endDate"
                  render={({ field }, { onChange, value } = field) => (
                    <PomodoroDatePicker
                      disabledDays={startDateField ? [{ before: startDateField }] : []}
                      format="dd/MM/yyyy"
                      label="Data Final"
                      value={returnDateFormated(value)}
                      onChange={onChange}
                    />
                  )}
                />
              </S.FormAreaGroup>
            </S.FormArea>

            <S.FormArea>
              <S.FormAreaTitle forwardedAs="h2" variant="medium">
                Para quem a lista deverá ficar visível
              </S.FormAreaTitle>

              <S.FormAreaGroup>
                <Radio.Group
                  aria-labelledby="listOwnerSelection"
                  className="list-owners-radio-group"
                  defaultValue={LIST_OWNERS.CUSTOMER}
                  role="group"
                  onChange={handleChangeListOwners}
                >
                  <Radio.Button
                    defaultChecked={listOwnersType === LIST_OWNERS.CUSTOMER}
                    id="customer-option"
                    label="Clientes"
                    name="listOwnerSelection"
                    value={LIST_OWNERS.CUSTOMER}
                  />
                  <Radio.Button
                    disabled
                    defaultChecked={listOwnersType === LIST_OWNERS.ESTABLISHMENT}
                    id="stablishment-option"
                    label="Estabelecimentos"
                    name="listOwnerSelection"
                    value={LIST_OWNERS.ESTABLISHMENT}
                  />
                </Radio.Group>
              </S.FormAreaGroup>

              <S.FormAreaGroup>
                <Controller
                  control={control}
                  name={'rules.customers'}
                  render={({ field: { onChange, value } }) => (
                    <FormAddOwners
                      config={LIST_OWNERS_CONFIG[listOwnersType]}
                      isFetching={ownerInput[listOwnersType].loading}
                      value={value}
                      onChange={onChange}
                      onSearch={ownerInput[listOwnersType].query}
                    />
                  )}
                />
              </S.FormAreaGroup>
            </S.FormArea>

            <S.FormArea>
              <S.FormAreaTitle forwardedAs="h2" variant="medium">
                Produtos e variação
              </S.FormAreaTitle>

              <S.FormAreaGroup>
                <Controller
                  control={control}
                  name="items"
                  render={({ field: { onChange, value } }) => (
                    <FormAddItems
                      isFetchingProduct={loadingProduct}
                      isFetchingSeller={loadingSeller}
                      value={value}
                      onChange={onChange}
                      onSearchProduct={searchProduct}
                      onSearchSeller={searchSeller}
                    />
                  )}
                />
              </S.FormAreaGroup>
            </S.FormArea>

            <S.FormArea>
              <Button
                className="form-admin__shopping-list__btn-submit"
                disabled={!roleShoppingListWrite}
                isLoading={onCreateLoading || onUpdateLoading}
                type="submit"
              >
                {formStateTexts.submitBtnLabel}
              </Button>
            </S.FormArea>
          </form>
        )}
      </MainContent>

      {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
    </>
  );
};

export default ListDetail;
