import { useState, useEffect } from 'react';
import { OutlinedInput } from 'ifoodshop-react-ui';
import TinyEditor from '../../components/TextEditor';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash.svg';
import './suggestionCustomFields.scss';

let fieldsValues = {};
const SuggestionCustomFields = props => {
  if (props.fielddata && props.fielddata.getAllSpecificationByProduct) {
    Object.keys(props.fielddata.getAllSpecificationByProduct).map(function (objectKey, index) {
      if (!fieldsValues[props.fielddata.getAllSpecificationByProduct[index].field.id]) {
        fieldsValues[props.fielddata.getAllSpecificationByProduct[index].field.id] = {
          id: props.fielddata.getAllSpecificationByProduct[index].field.id,
          value: props.fielddata.getAllSpecificationByProduct[index].value,
        };
      }
    });
  }

  if (props.fielddata && props.fielddata.getAllSpecificationByVariation) {
    Object.keys(props.fielddata.getAllSpecificationByVariation).map(function (objectKey, index) {
      if (!fieldsValues[props.fielddata.getAllSpecificationByVariation[index].field.id]) {
        fieldsValues[props.fielddata.getAllSpecificationByVariation[index].field.id] = {
          id: props.fielddata.getAllSpecificationByVariation[index].field.id,
          value: props.fielddata.getAllSpecificationByVariation[index].value,
        };
      }
    });
  }

  const readonly = props.readonly;
  const [render, setRender] = useState(Math.random().toString());

  const onChangeCustomField = (e, fieldId, selected = true) => {
    if (!selected) {
      props.fieldKeys.map(key => {
        props.fields[key] = props.fields[key].filter(item => item.id !== fieldId);
      });
    } else {
      props.fieldKeys.map(key => {
        props.fields[key].map(field => {
          if (fieldId == field.id) {
            fieldsValues[fieldId] = { id: fieldId, value: e };
          }
        });
      });
    }
    setRender(Math.random().toString());
  };

  useEffect(() => {
    props.data(fieldsValues);
  }, [render]);

  const renderByFormat = field => {
    if (field.fieldType == 'TEXTFIELD') {
      return (
        <div key={'"field' + field.id + '"'} className="list-fields">
          <TrashIcon className="icon-trash" width={15} onClick={e => onChangeCustomField(e, field.id, false)} />
          <div className="custom-field">
            <OutlinedInput
              id={'field' + field.id}
              placeholder={field.name}
              value={fieldsValues[field.id] ? fieldsValues[field.id].value : field.value ? field.value : ''}
              border
              onChange={e => onChangeCustomField(e.target.value, field.id)}
              disabled={readonly}
            />
          </div>
        </div>
      );
    } else if (field.fieldType == 'LIST') {
      return (
        <div key={'"field' + field.id + '"'} className="list-fields">
          <Autocomplete
            id={'field' + field.id}
            freeSolo
            className="dropdown-ifood"
            options={field.fieldListValue ? field.fieldListValue.split(',') : []}
            value={fieldsValues[field.id] ? fieldsValues[field.id].value : null}
            getOptionLabel={option => option}
            renderInput={params => <TextField {...params} label={field.name} variant="outlined" />}
            onChange={e => onChangeCustomField(e.target.innerText, field.id)}
            disabled={readonly}
          />
        </div>
      );
    } else if (field.fieldType == 'TEXTAREA') {
      return (
        <div key={'"field' + field.id + '"'} className="list-fields">
          <div>
            <label>{field.name}</label>
          </div>

          <TinyEditor
            disabled={readonly}
            data={e => onChangeCustomField(e, field.id)}
            initialValue={fieldsValues[field.id] ? fieldsValues[field.id].value : null}
          />
        </div>
      );
    }
  };

  if (props?.fieldKeys?.length > 0 && props?.fields[0]?.length > 0) {
    return (
      <div className="product-specifications">
        {render && props.fieldKeys.map(key => props.fields[key].map(field => renderByFormat(field)))}
      </div>
    );
  } else return null;
};

export default SuggestionCustomFields;
export { SuggestionCustomFields };
