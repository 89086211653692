import { useState } from 'react';
import { Modal } from 'ifoodshop-react-ui';
import Tooltip from '@material-ui/core/Tooltip';
import Dropdown from 'react-dropdown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TableDiscountRules from '../../../../../components/TableDiscountRules';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_ALL_REGIONS } from '../../../../../graphql/region.queries';
import { GET_ALL_CUSTOMERSGROUP, GET_CUSTOMERLISTBYEMAIL } from '../../../../../graphql/customer.queries';
import { GET_BRANDS } from '../../../../../graphql/brand.queries';
import { GET_LEGACY_CATEGORIES_BY_NAME_OR_ID } from '../../../../../graphql/category.queries';
import { GET_SELLERS } from '../../../../../graphql/seller.queries';
import { GET_VARIATIONS_TERM } from '@graphql/variation.queries';

const FormRegisterRules = props => {
  const optionsRules = ['Região', 'Grupo de clientes', 'Categoria', 'Lista de produtos', 'Fornecedor', 'Cliente']; // Marca desabilitada até que a taxonomia dos produtos seja realizada
  let tableRules = [];

  const [allRules, setAllRules] = useState([]);
  const [dataRulesTable, setDataRulesTable] = useState(tableRules);
  const [dataRules, setDataRules] = useState(tableRules);
  const [rules, setRules] = useState('Selecione');
  const [rulesUpdate, setRulesUpdate] = useState(null);
  const [typeRules, setTypeRules] = useState();
  const [selectSearch, setSelectSearch] = useState();
  const [modal, setModal] = useState();
  const [autocompleteKey, setAutocompleteKey] = useState(Math.random * 10);
  const [showTooltip, setShowTooltip] = useState(false);

  const [getRegions, { data: dataRegions }] = useLazyQuery(GET_ALL_REGIONS, {
    onCompleted: () => {
      setAllRules(dataRegions.getAllRegions.content);
      document.getElementById('rulesSearch').focus();
    },
  });

  const [getCustomers, { data: dataCustomers }] = useLazyQuery(GET_ALL_CUSTOMERSGROUP, {
    onCompleted: () => {
      setAllRules(dataCustomers.getAllCustomerGroups.content);
      document.getElementById('rulesSearch').focus();
    },
  });

  const [getCustomerListByEmail, { data: dataCustomerListByEmail }] = useLazyQuery(GET_CUSTOMERLISTBYEMAIL, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      if (dataCustomerListByEmail?.getCustomerListByEmail) {
        dataCustomerListByEmail.getCustomerListByEmail.map(
          option => (option.name = option.email && `${option.name} - ${option.email}`)
        );

        setAllRules(dataCustomerListByEmail.getCustomerListByEmail);
      }
      document.getElementById('rulesSearch').focus();
    },
  });

  const [getBrands, { data: dataBrands }] = useLazyQuery(GET_BRANDS, {
    variables: { ownerType: 'SHOP' },
    onCompleted: () => {
      setAllRules(dataBrands.getAllBrands.content);
    },
  });

  const [getVariations] = useLazyQuery(GET_VARIATIONS_TERM, {
    onCompleted: data => {
      const { getFindByTerm } = data || {};

      setAllRules(
        getFindByTerm?.map(({ id, name }) => ({
          id: parseInt(id),
          name,
        }))
      );
      document.getElementById('rulesSearch').focus();
    },
  });

  const [getCategories, { data: dataCategories }] = useLazyQuery(GET_LEGACY_CATEGORIES_BY_NAME_OR_ID, {
    onCompleted: () => {
      setAllRules(dataCategories.getCategoriesByNameOrId);
      document.getElementById('rulesSearch').focus();
    },
  });

  const [getSeller, { data: dataSeller }] = useLazyQuery(GET_SELLERS, {
    onCompleted: () => {
      setAllRules(dataSeller.getSellerByName);
      document.getElementById('rulesSearch').focus();
    },
  });

  props.dataRules(dataRules);

  if (props.dataRulesUpdate && rulesUpdate === null) {
    setRulesUpdate(props.dataRulesUpdate);
    let dataRulesTableTemp = [];
    let dataRulesTemp = [];

    props.dataRulesUpdate.map(rule => {
      var i;
      for (i = 0; i < rule.values.length; i++) {
        var key = Math.random() * 10;

        if (rule.values[i]) {
          dataRulesTableTemp.push({
            name: rule.values[i].name,
            category: getRuleType(rule.type),
            filter: rule.filter === 'INCLUDE' ? 'Inclusão' : 'Exclusão',
            key: key,
          });

          dataRulesTemp.push({
            type: rule.type,
            filter: rule.filter,
            values: [parseInt(rule.values[i].id)],
            id: props.cloneId ? null : rule.id,
            key: key,
          });
        }
      }

      setDataRulesTable(dataRulesTableTemp);
      setDataRules(dataRulesTemp);
    });
  }

  function getRuleType(type) {
    switch (type) {
      case 'REGION':
        return 'Região';
      case 'CATEGORY':
        return 'Categoria';
      case 'BRAND':
        return 'Marca';
      case 'PRODUCT':
        return 'Lista de produtos';
      case 'SELLER':
        return 'Fornecedor';
      case 'CUSTOMER_GROUP':
        return 'Grupo de clientes';
      case 'CUSTOMER':
        return 'Cliente';
      default:
        return null;
    }
  }

  function handleDropdown(e) {
    setRules(e);

    setSelectSearch('');
    setAllRules([]);

    switch (e) {
      case 'Região':
        setShowTooltip(true);
        return 'REGION';
      case 'Grupo de clientes':
        setShowTooltip(true);
        return 'CUSTOMER_GROUP';
      case 'Categoria':
        setShowTooltip(true);
        return 'CATEGORY';
      case 'Marca':
        return 'BRAND';
      case 'Lista de produtos':
        setShowTooltip(true);
        return 'PRODUCT';
      case 'Fornecedor':
        setShowTooltip(true);
        return 'SELLER';
      case 'Cliente':
        setShowTooltip(true);
        return 'CUSTOMER';
      default:
        setAllRules('');
        return null;
    }
  }

  function handleSearch(e) {
    switch (rules) {
      case 'Região':
        if (e) getRegions({ variables: { name: e.target.value, ownerType: 'SHOP' } });
        break;
      case 'Grupo de clientes':
        if (e) getCustomers({ variables: { groupName: e.target.value } });
        break;
      case 'Categoria':
        if (e) getCategories({ variables: { searchTerm: e.target.value } });
        break;
      case 'Marca':
        getBrands({ variables: { brandName: e.target.value } });
        break;
      case 'Lista de produtos':
        if (e) getVariations({ variables: { term: e.target.value } });
        break;
      case 'Fornecedor':
        if (e) getSeller({ variables: { name: e.target.value } });
        break;
      case 'Cliente':
        if (e) getCustomerListByEmail({ variables: { email: e.target.value, page: 0 } });
        break;
      default:
        setAllRules('');
    }
  }

  function addRules(e) {
    e.preventDefault();
    let ruleSelectedId = null;
    let ruleSelectedType = null;
    if (allRules) {
      ruleSelectedId = allRules.find(item => `ID: ${item.id} - ${item.name}` === selectSearch)?.id;
      ruleSelectedType = allRules.find(item => `ID: ${item.id} - ${item.name}` === selectSearch)?.__typename;
    }

    let hasExist = false;
    if (dataRules.length)
      dataRules.forEach(item => {
        if (item.values.includes(parseInt(ruleSelectedId)) && item.type === ruleSelectedType.toUpperCase()) {
          hasExist = true;
        }
      });

    if (rules && selectSearch && ruleSelectedId && typeRules && !hasExist) {
      const key = Math.random() * 10;

      setDataRulesTable([
        ...dataRulesTable,
        {
          category: rules,
          name: selectSearch,
          filter: typeRules,
          key: key,
        },
      ]);

      setDataRules([
        ...dataRules,
        {
          type: handleDropdown(rules),
          filter: typeRules == 'Inclusão' ? 'INCLUDE' : 'EXCLUDE',
          values: [parseInt(ruleSelectedId)],
          id: null,
          key: key,
        },
      ]);

      setSelectSearch('');
      setAutocompleteKey(key);
      setTypeRules(null);
    } else if (!rules || !selectSearch || !typeRules) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>Você precisa preencher todos os campos de regra</p>
        </Modal>
      );
    } else if (!ruleSelectedId) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>Selecione uma regra válida</p>
        </Modal>
      );
    } else {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <p>Esta regra já foi adicionada</p>
        </Modal>
      );
    }
  }

  function removeRule(e) {
    setDataRulesTable(dataRulesTable.filter(item => item.key !== e));
    setDataRules(dataRules.filter(item => item.key !== e));
  }

  return (
    <>
      <h2 className="section-title">{props.title}</h2>

      <div className="form-admin__group">
        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsRules}
            onChange={e => {
              handleDropdown(e.value);
            }}
            value={rules}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Onde será aplicado</label>
        </div>

        <div className="dropdown-label-wrap">
          {showTooltip ? (
            <Tooltip title="Digite no mínimo 3 caracteres para a fazer a busca">
              <Autocomplete
                id="rulesSearch"
                freeSolo
                key={autocompleteKey}
                inputValue={selectSearch}
                noOptionsText="Nenhuma opção encontrada"
                onInputChange={(event, newInputValue) => setSelectSearch(newInputValue)}
                className="autocomplete-ifood"
                options={allRules ? allRules.map(option => `ID: ${option.id} - ${option.name}`) : []}
                getOptionLabel={option => option || ''}
                renderInput={params => (
                  <TextField {...params} onChange={e => handleSearch(e)} label="Busque uma regra" variant="outlined" />
                )}
              />
            </Tooltip>
          ) : (
            <Autocomplete
              id="rulesSearch"
              key={autocompleteKey}
              disabled={rules === 'Selecione'}
              onSelect={e => setSelectSearch(e.target.value)}
              className="autocomplete-ifood"
              options={allRules ? allRules.map(option => `ID: ${option.id} - ${option.name}`) : []}
              renderInput={params => (
                <TextField
                  {...params}
                  onChange={e => handleSearch(e)}
                  label={rules === 'Selecione' ? 'Selecione um item no menu ao lado' : 'Busque uma regra'}
                  variant="outlined"
                />
              )}
            />
          )}
        </div>

        <ToggleButtonGroup
          className="togglebutton-ifood"
          value={typeRules}
          exclusive
          onChange={(e, newTypeRules) => setTypeRules(newTypeRules)}
          aria-label="tipo de regra"
        >
          <ToggleButton className="togglebutton-ifood__item" value="Inclusão" aria-label="cost center">
            Inclusão
          </ToggleButton>
          <ToggleButton className="togglebutton-ifood__item" value="Exclusão" aria-label="fornecedor">
            Exclusão
          </ToggleButton>
        </ToggleButtonGroup>

        <span className="button btn btn-secondary" onClick={e => addRules(e)}>
          Adicionar
        </span>
      </div>

      {dataRulesTable.length > 0 && <TableDiscountRules removeRule={e => removeRule(e)} rules={dataRulesTable} />}
      {modal}
    </>
  );
};

export default FormRegisterRules;
