/* eslint-disable react/jsx-sort-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOMER_LIST } from '@graphql/customer.queries';

import { CustomerListProvider } from '../../context/CustomerListContext';
import CustomerListHeader from '../CustomerListHeader';
import FiltersCustomerList from '../FiltersCustomerList';
import TableCustomerList from '../TableCustomerList';
import Loading from '@components/Loading';

import * as S from './CustomerListForm.styles';
import { Pagination } from '@ifood/pomodoro-components';

const CustomerListForm = (): JSX.Element => {
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const paginationFix = 1;

  const [variables, setVariables] = useState({
    page: 0,
    size: 10,
    id: params.get('id') || null,
    login: params.get('login') || null,
    nome: params.get('nome') || null,
    document: params.get('document') || null,
    type: 'CLIENTE',
  });

  const {
    data: dataCustomerList,
    loading,
    refetch: refetchCustomerList,
  } = useQuery(GET_CUSTOMER_LIST, {
    variables,
  });

  const onSubmitFilters = (e: any) => {
    setVariables({
      ...variables,
      id: e.id,
      login: e.login,
      nome: e.nome,
      document: e.document,
    });
  };

  const customerListProps = { refetchCustomerList };

  const handleBackPage = () => {
    history.push('/clientes');
  };

  return (
    <>
      <CustomerListProvider {...customerListProps}>
        <S.HeaderCustomerList>
          <S.GoBackArrow type="button" onClick={handleBackPage} />
          <CustomerListHeader titleHeader="Clientes" />
        </S.HeaderCustomerList>
        <FiltersCustomerList
          data-testid="filter-customer"
          loading={loading}
          variables={variables}
          onSubmitFilters={onSubmitFilters}
        />
        <S.ContentCustomerList>
          <TableCustomerList
            customers={dataCustomerList?.getCustomerList?.content}
            variables={variables}
            onSubmitFilters={onSubmitFilters}
          />
        </S.ContentCustomerList>
        {loading && <Loading />}
        <Pagination.Numbered
          showArrowLabel
          showCounter
          current={variables.page + paginationFix}
          ofLabel="de"
          pageSize={variables.size}
          showingLabel="Exibindo"
          previousLabel="Anterior"
          nextLabel="Próximo"
          totalItems={dataCustomerList?.getCustomerList?.totalPages}
          onChange={e =>
            setVariables({
              ...variables,
              page: e.currentPage - paginationFix,
              size: e.pageSize,
            })
          }
        />
      </CustomerListProvider>
    </>
  );
};

export default CustomerListForm;
