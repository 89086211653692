import { Dialog, Button, Heading3, Text } from '@ifood/pomodoro-components';
import { useHistory } from 'react-router-dom';

interface IModalProps {
  isOpen: boolean;
  url: string;
  title: string;
  description: string;
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (v: boolean) => void;
}

const ModalBackRegister = ({ isOpen, url, title, description, setIsOpen }: IModalProps): JSX.Element => {
  const history = useHistory();

  const returnPage = () => {
    history.push(url);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Dialog.Header>
        <Heading3 my={2} variant="medium">
          {title}
        </Heading3>
      </Dialog.Header>
      <Dialog.Body>
        <Text>{description}</Text>
      </Dialog.Body>
      <Dialog.Footer>
        <Button marginRight={18} variant="secondary" onClick={() => setIsOpen(false)}>
          Cancelar
        </Button>
        <Button onClick={returnPage}>Confirmar</Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default ModalBackRegister;
