import { useState, useEffect } from 'react';
import { Modal, Button } from 'ifoodshop-react-ui';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Gallery from 'react-photo-gallery';

import './dragdrop.scss';

const DragDrop = props => {
  const imgWithClick = { cursor: 'pointer' };
  const [items, setItems] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [modal, setModal] = useState(null);
  const [render, setRender] = useState(Math.random());

  useEffect(() => {
    if (props.imageList) {
      let temparray = [];
      let size = Object.keys(props.imageList).length;
      let count = 0;

      Object.keys(props.imageList).map(function (objectKey, index) {
        let tempimage = {
          src: props.imageList[index],
          width: 4,
          height: 4,
          index: index,
        };

        temparray.push(tempimage);

        count++;
        if (count == size) {
          setItems(temparray);
          setRender(Math.random());
        }
      });
    }
  }, [props]);

  const disableImage = (e, index) => {
    e.preventDefault();
    props.imageOut(index);
    setModal(null);
  };

  const SortablePhoto = SortableElement(item => <Photo {...item} />);

  const SortableGallery = SortableContainer(({ items }) => (
    <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };

  const handleDisable = (e, data) => {
    // access to e.target here
    e.preventDefault();

    setModal(
      <Modal onCloseAction={() => setModal(null)}>
        Deseja remover esta imagem?
        <div className="modalbtns">
          <Button onClick={e => disableImage(e, data.index)}>Excluir</Button>
          <Button className="btn-secondary" onClick={() => setModal(null)}>
            Cancelar
          </Button>
        </div>
      </Modal>
    );
  };

  const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
    const imgStyle = { margin: margin };
    if (direction === 'column') {
      imgStyle.position = 'absolute';
      imgStyle.left = left;
      imgStyle.top = top;
    }

    const handleClick = event => {
      onClick(event, { photo, index });
    };

    return (
      <div className="image-list" id={'divphoto' + Math.floor(Math.random() * 1000).toString()}>
        <img
          id={'photo' + Math.floor(Math.random() * 1000).toString()}
          className="image-item"
          style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
          {...photo}
          onClick={onClick ? handleClick : null}
          alt="img"
        />
        {props.removeImage !== 'false' && (
          <button className="btn-trash" onMouseUp={e => handleDisable(e, photo)}></button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="grid-list-container">
        {render ? (
          <SortableGallery id={props.id} items={items} onSortEnd={onSortEnd} axis={'xy'} />
        ) : (
          <a id={props.id}> </a>
        )}
      </div>
      {modal}
    </>
  );
};

export { DragDrop };
