import React from 'react';
import { Link } from 'ifoodshop-react-ui';

const TableAddProductVariation = ({ removeProductVariation, productVariation, editFixedPrice }) => {


  function removeProductVariationLink(e, key) {
    e.preventDefault();
    removeProductVariation(key);
  }

  return (
    <table className="table-admin table-admin--sample">
      <thead>
        <tr>
          <th className="table-admin__title">Produto</th>
          <th className="table-admin__title">Variação</th>
          <th className="table-admin__title"></th>
        </tr>
      </thead>
      <tbody>
        {
          productVariation.length > 0 &&
            productVariation.map(productVariation => {
              return (
                <tr id={productVariation.key} key={productVariation.key}>
                  <td className="table-admin__data">{productVariation.product}</td>
                  <td className="table-admin__data">{productVariation.variation}</td>
                  <td className="table-admin__data">
                    {!editFixedPrice
                      ?
                        <Link onClick={e => removeProductVariationLink(e, productVariation.key)} >Remover</Link>
                      :
                        <Link className={editFixedPrice && 'link--disabled' } >Remover</Link>
                    }
                  </td>
                </tr>
              )
            })
        }
      </tbody>
    </table>
  )
}

export default TableAddProductVariation;