import React from 'react';
import { transformRuleFilter, transformRuleType } from './transformData';

const TableCommercialConditionRegister = ({
  data,
  deleteCommercialCondition,
}) => {
  const titleColums = ['Tipo de regra', 'Nome', 'Tipo'];

  return (
    <>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            {titleColums.map((item) => (
              <th className="table-admin__title">{item}</th>
            ))}
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((business, index) => {
            return (
              <tr key={index}>
                <td className="table-admin__data">
                  {transformRuleType(business.ruleType)}
                </td>
                <td className="table-admin__data">
                  {business.region?.name || ''}
                </td>
                <td className="table-admin__data">
                  {transformRuleFilter(business.ruleFilter)}
                </td>
                <td className="table-admin__data">
                  <a
                    onClick={() => deleteCommercialCondition(business)}
                    className="link"
                  >
                    Remover
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TableCommercialConditionRegister;
