import gql from 'graphql-tag';

const CREATE_PRODUCT_SUGGESTION = gql`
    mutation createProductSuggestion(
        $name: String,
        $description: String,
        $category: String ,
        $brand: String,
        $categoryId: Int,
        $brandId: Int,
        $price: Float,
        $width: Float,
        $weight: Float,
        $height: Float,
        $length: Float,
        $quantity: Int,
        $productType: String,
        $barCode: String,
        $unity: String,
        $sellerId: Int,
        $sku: String,
        $stock: Int,
        $productImageList: String,
        $productSpecificationList: String,
        $skuSpecificationList: String,
        $variationImageList: String,
        $variableWeight: Boolean
    ){
        createProductSuggestion(input: {
            name: $name,
            description: $description,
            category: $category,
            brand: $brand,
            categoryId: $categoryId,
            brandId: $brandId,
            price: $price,
            width: $width,
            weight: $weight,
            height: $height,
            length: $length,
            quantity: $quantity,
            barCode: $barCode,
            unity: $unity,
            productType: $productType
            sellerId: $sellerId,
            sku: $sku,
            stock: $stock,
            productImageList: $productImageList,
            productSpecificationList: $productSpecificationList,
            skuSpecificationList: $skuSpecificationList,
            variationImageList: $variationImageList,
            variableWeight: $variableWeight
        }){
            id
            name
            description
            approved
            category
            brand
            categoryinfo{
                id
                name
                parentId
            }
            brandinfo{
                id
                name
            }
            price
            width
            weight
            height
            length
            quantity
            barCode
            unity
            productType
            sellerId
            sku
            stock
            productImageList
            productSpecificationList
            skuSpecificationList
            variationImageList
            createdAt
            updatedAt
            cancelMotive
        }
    }

`;

const UPDATE_PRODUCT_SUGGESTION = gql`
    mutation updateProductSuggestion(
        $id: ID!,
        $name: String,
        $description: String,
        $approved: Boolean,
        $category: String,
        $brand: String,
        $categoryId: Int,
        $brandId: Int,
        $price: Float,
        $width: Float,
        $weight: Float,
        $height: Float,
        $length: Float,
        $quantity: Int,
        $barCode: String,
        $unity: String,
        $productType: String,
        $sellerId: Int,
        $sku: String,
        $stock: Int,
        $productImageList: String,
        $productSpecificationList: String,
        $skuSpecificationList: String,
        $variationImageList: String
    ){
        updateProductSuggestion(id: $id, input: {
            id: $id
            name: $name
            description: $description
            approved: $approved
            category: $category
            brand: $brand
            categoryId: $categoryId
            brandId: $brandId
            price: $price
            width: $width
            weight: $weight
            height: $height
            length: $length
            quantity: $quantity
            barCode: $barCode
            unity: $unity
            productType: $productType
            sellerId: $sellerId
            sku: $sku
            stock: $stock
            productImageList: $productImageList
            productSpecificationList: $productSpecificationList
            skuSpecificationList: $skuSpecificationList
            variationImageList: $variationImageList
        }){
            id
            name
            description
            approved
            category
            brand
            categoryinfo{
                id
                name
                parentId
            }
            brandinfo{
                id
                name
            }
            price
            width
            weight
            height
            length
            quantity
            barCode
            unity
            productType
            sellerId
            sku
            stock
            productImageList
            productSpecificationList
            skuSpecificationList
            variationImageList
            createdAt
            updatedAt
            rejectionReasonId
        }
    }
`;

const APPROVED_SUGGEST = gql`
    mutation approveProductSuggestion($id: ID!){
        approveProductSuggestion(id: $id)            
    }
`;

const DECLINE_SUGGEST = gql`
    mutation declineProductSuggestion($id: ID!, $rejectionReasonId: Int){
        declineProductSuggestion(id: $id, rejectionReasonId: $rejectionReasonId)    
    }
`;
export { CREATE_PRODUCT_SUGGESTION, UPDATE_PRODUCT_SUGGESTION, APPROVED_SUGGEST, DECLINE_SUGGEST }
