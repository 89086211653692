import CatalogItem from '@components/CatalogItem';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import ImageBrand from '../../../assets/images/catalog-brands.svg';
import ImageProduct from '../../../assets/images/catalog-product.svg';
import ImageSuggestion from '../../../assets/images/catalog-suggestion.svg';
import ImageCategories from '../../../assets/images/catalog-categories.svg';
import ImageBadWords from '../../../assets/images/catalog-bad-words.svg';

const HomeCatalogs = () => {
  const roles = getRoles();

  const items = [
    {
      title: 'Produto',
      image: ImageProduct,
      description: 'Cadastro e edição de produto',
      link: 'catalogos/produtos',
      showSeller: roles.includes(Roles.PRODUCT_RO) || roles.includes(Roles.PRODUCT_WR),
    },
    {
      title: 'Sugestão',
      image: ImageSuggestion,
      description: 'Sugestão de produtos',
      link: 'catalogos/sugestoes',
      showSeller:
        roles.includes(Roles.SUGGESTION_RO) ||
        roles.includes(Roles.SUGGESTION_WR) ||
        roles.includes(Roles.SUGGESTION_APR),
    },
    {
      title: 'Marca',
      image: ImageBrand,
      description: 'Cadastro e edição de marcas',
      link: 'catalogos/marcas',
      showSeller: roles.includes(Roles.BRAND_RO) || roles.includes(Roles.BRAND_WR),
    },
    {
      title: 'Categoria',
      image: ImageCategories,
      description: 'Cadastro e edição de categorias',
      link: 'catalogos/categorias',
      showSeller: roles.includes(Roles.CATEGORY_RO) || roles.includes(Roles.CATEGORY_WR),
    },
    {
      title: 'Palavras Proibidas',
      image: ImageBadWords,
      description: 'Cadastro e edição de palavaras proibidas',
      link: 'catalogos/badwords/',
      showSeller: roles.includes(Roles.BAD_WORDS_RO) || roles.includes(Roles.BAD_WORDS_WR),
    },
  ];
  return (
    <div className="content-body home">
      <div className="content-body__box">
        {items.map(item => {
          const { image, title, description, link, showSeller } = item;
          if (showSeller) {
            return <CatalogItem image={image} title={title} description={description} link={link} />;
          }
        })}
      </div>
    </div>
  );
};

export default HomeCatalogs;
