/* istanbul ignore file */
import styled from 'styled-components';
import iconArrow from '@assets/icons/icon-goback.svg';
import { Button, Heading1, Heading2, Tag as TagPomodoro } from '@ifood/pomodoro-components';

const TitleDetail = styled(Heading1)`
  margin: ${({ theme }) => `0 ${theme.space.regular} ${theme.space.regular} 0`};
`;

const TitleCard = styled(Heading2)`
  width: 100%;
  display: inline-block;
  margin: ${({ theme }) => `0 0 ${theme.space.regular}`};
`;

const Tag = styled(TagPomodoro)`
  white-space: nowrap;
  margin: ${({ theme }) => `0 0 ${theme.space.regular} 0`};
  svg {
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 38px 32px 32px 64px;
  margin-left: 180px;
`;

const RowWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Form = styled.form`
  width: 100%;
`;

const GoBackArrow = styled.button`
  position: absolute;
  top: 40px;
  left: 210px;
  background: url(${iconArrow});
  width: 10px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
`;

const ButtonCancel = styled(Button)`
  margin-left: 10px;
  margin-right: 24px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

const ButtonSave = styled(Button)`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

const Table = styled.table`
  min-width: 100%;
`;

const NoItemsFound = styled.div`
  text-align: center;
  width: 100%;
  margin: 8px;
`;

const ModalWrapper = styled.div`
  width: 80vw;
  max-width: 100%;
`;

export {
  TitleDetail,
  TitleCard,
  Tag,
  Wrapper,
  ModalWrapper,
  GoBackArrow,
  Header,
  ButtonSave,
  ButtonCancel,
  RowWrapper,
  Form,
  Table,
  NoItemsFound,
};
