import gql from 'graphql-tag';

const DELETE_VARIATION_SPECIFICATIONS = gql `
    mutation deleteSpecificationVariation($variation: Int) {
        deleteSpecificationVariation (variationId: $variation)
    }
`;

const CREATE_VARIATION_SPECIFICATION = gql `
    mutation createSpecificationVariation($variation: Int, $field: Int, $value: String) {
    	createSpecificationVariation(input: {variationId: $variation, fieldId: $field, value: $value}) {
			id
		}
    }
`;

const UPDATE_VARIATION_SPECIFICATION = gql `
    mutation updateSpecificationVariation($id: Int, $variation: Int, $field: Int, $value: String) {
    	updateSpecificationVariation(id: $id, input: {variationId: $variation, fieldId: $field, value: $value}) {
			id
		}
    }
`;

export {DELETE_VARIATION_SPECIFICATIONS, CREATE_VARIATION_SPECIFICATION, UPDATE_VARIATION_SPECIFICATION};
