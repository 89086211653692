import gql from 'graphql-tag';

const CREATE_PRODUCT = gql `
    mutation createProduct($name: String, $categoryId: Int, $brandId: Int, $active: Boolean, $searchVisible: Boolean, $description: String){
        createProduct(input: {name: $name, categoryId: $categoryId, brandId: $brandId, active: $active, searchVisible: $searchVisible, description: $description}){
            id
            name
            active
            visible
            description
            error
        }
    }
`;

const UPDATE_PRODUCT = gql `
    mutation updateProduct($id: Int,$name: String, $categoryId: Int, $brandId: Int, $active: Boolean, $searchVisible: Boolean, $description: String){
        updateProduct(input: {id: $id, name: $name, categoryId: $categoryId, brandId: $brandId, active: $active, searchVisible: $searchVisible, description: $description}){
            id
            name
            active
            visible
            description
            error
        }
    }
`;

const DISABLE_PRODUCT_IMAGE = gql `
    mutation updateProductImages($listImages: [ProductImageInput]) {
        updateProductImages(listImages: $listImages) {
            id
            imagePath
            order
        }    
    }
`;

const CREATE_PRODUCT_IMAGE = gql `
    mutation createProductImage($imagePath: String, $active: Boolean, $order: Int, $product: Int) {
        createProductImage(input: {imagePath: $imagePath, active: $active, order: $order, productId: $product}) {
            id
            imagePath
            order
        }    
    }
`;

const UPDATE_PRODUCT_IMAGES = gql `
    mutation updateProductImages($listImages: [ProductImageInput]) {
        updateProductImages(listImages: $listImages) {
            id
            imagePath
            order
        }    
    }
`;

export {CREATE_PRODUCT, UPDATE_PRODUCT, CREATE_PRODUCT_IMAGE, DISABLE_PRODUCT_IMAGE, UPDATE_PRODUCT_IMAGES}
