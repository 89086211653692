import { TableAdmin } from '@components/Table/Table.styles';
import Formatter from '@helpers/FormatHelper';
import { Box, Card, Flex, Loading, Pagination, Search, Toggle } from '@ifood/pomodoro-components';
import { NoItemsFound, RowWrapper, TitleCard } from '../../CustomerDetail.styles';
import EstablishmentDetailModal from '../EstablishmentDetailModal';
import { useEstablishmentListCardHook } from './establishmentListCardHook';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const DEFAULT_TOTAL_ITEMS = 0;

export function EstablishmentListCard() {
  const {
    establishments,
    hasEstablishments,
    walletTransferList,
    walletPostPaidList,
    fetchingEstablishment,
    changingStatus,
    isModalOpen,
    selectedEstablishmentId,
    establishmentsData,
    pagination,
    onSearch,
    setIsModalOpen,
    onOpenEstablishmentModal,
    onPaginationChange,
    onChangeStatus,
  } = useEstablishmentListCardHook();

  const { Table, Th, Td } = TableAdmin;
  const theme = useContext(ThemeContext);

  const toggleActivation = (id: number, evt: any) => {
    onChangeStatus(id, evt.target.checked);
  };

  return (
    <Box>
      <Card padding={20}>
        <TitleCard variant="regular">Estabelecimentos</TitleCard>

        <>
          <RowWrapper>
            <Search placeholder="Pesquisar" onSearch={onSearch} />
          </RowWrapper>

          {fetchingEstablishment && <Loading color="primary" variant="large" />}
          {!fetchingEstablishment && !hasEstablishments && (
            <RowWrapper>
              <NoItemsFound>Nenhum estabelecimento encontrado.</NoItemsFound>
            </RowWrapper>
          )}
          {!fetchingEstablishment && hasEstablishments && (
            <Table>
              <thead>
                <tr>
                  <Th>Unidade</Th>
                  <Th>Repasse</Th>
                  <Th>Boleto a prazo</Th>
                  <Th>Status</Th>
                </tr>
              </thead>
              <tbody>
                {establishments?.map((establishment: any, index: number) => {
                  return establishment ? (
                    <tr key={establishment.id}>
                      <Td>
                        <a className="link" onClick={() => onOpenEstablishmentModal(establishment.id)}>
                          {establishment?.name}
                        </a>
                      </Td>
                      <Td>{walletTransferList && Formatter.currency(walletTransferList[index]?.availableAmount)}</Td>
                      <Td>{walletPostPaidList && Formatter.currency(walletPostPaidList[index]?.availableAmount)}</Td>
                      <Td>
                        <Flex alignItems="flex-end">
                          <Toggle
                            checked={establishment?.active}
                            disabled={changingStatus}
                            label={establishment?.active ? 'Ativo' : 'Inativo'}
                            name="status"
                            onChange={v => toggleActivation(establishment.id, v)}
                          />
                          {changingStatus && <Loading color="primary" variant="small" />}
                        </Flex>
                      </Td>
                    </tr>
                  ) : (
                    <></>
                  );
                })}
              </tbody>
            </Table>
          )}

          <Flex justifyContent="center" m={`${theme.space.larger} 0 ${theme.space.smaller}`}>
            <Pagination.Numbered
              showArrowLabel
              showCounter
              current={pagination.currentPage}
              nextLabel="Próximo"
              ofLabel="de"
              pageSize={pagination.pageSize}
              previousLabel="Anterior"
              showingLabel="Exibindo"
              totalItems={establishmentsData?.totalElements || DEFAULT_TOTAL_ITEMS}
              onChange={onPaginationChange}
            />
          </Flex>
        </>
        {isModalOpen && (
          <EstablishmentDetailModal
            establishmentId={selectedEstablishmentId.current}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </Card>
    </Box>
  );
}
