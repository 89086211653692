import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import './tableCustomField.scss';

const tableCustomField = ({ customFields, idCategory }) => {
  if (customFields.length > 0) {
    return (
      <Fragment>
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">ID</th>
              <th className="table-admin__title">Nome</th>
              <th className="table-admin__title">Filtro</th>
              <th className="table-admin__title">Variação</th>
              <th className="table-admin__title">Ordem</th>
              <th className="table-admin__title">Estado</th>
            </tr>
          </thead>
          <tbody>
            {customFields.map(customField => (
              <tr key={customField.id}>
                <td className="table-admin__data">{customField.id}</td>
                <td className="table-admin__data">{customField.name}</td>
                <td className="table-admin__data">{customField.isFilter ? <a>Sim</a> : <a>Não</a>} </td>
                <td className="table-admin__data">
                  {customField.type == 'PRODUCT' ? <a>produto</a> : customField.type.toLowerCase()}
                </td>
                <td className="table-admin__data">{customField.order}</td>
                <td className="table-admin__data">{customField.active ? <a> Habilitado</a> : <a>Desabilitado</a>} </td>
                <td className="table-admin__data">
                  {customField.categoryId === idCategory ? (
                    <NavLink
                      className="link"
                      to={'/catalogos/categorias/detalhe/' + idCategory + '/especificacao/editar/' + customField.id}
                    >
                      Editar
                    </NavLink>
                  ) : (
                    <Tooltip title={`Para editar essa especificação acesse a categoria ${customField.categoryName}.`}>
                      <a className="link--disabled">Editar</a>
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  } else return null;
};
export default tableCustomField;
