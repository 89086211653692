import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';

import IconArrow from '../../../../assets/images/arrow.png';
import TableVoucherSearch from './components/TableVoucherSearch';
import HeaderVoucherSearch from './components/HeaderVoucherSearch';
import Decoder from '@helpers/Decoder';
import Roles from '@helpers/Roles';

const VoucherSearch = () => {
  const [voucher, setVoucher] = useState();
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  var tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  var roleVoucher = tokenDecoded.resource_access["manager"].roles.includes(Roles.VOUCHER_WR);
  var roleVoucherReadOnly = tokenDecoded.resource_access["manager"].roles.includes(
    Roles.VOUCHER_RO,
  );

  if (!roleVoucherReadOnly && !roleVoucher) {
    window.location.href = '/';
  }

  function handleVoucher(e) {
    const { content, totalPages } = e;
    setVoucher(content);
    setLastPage(totalPages);
  }

  return (
    <>
      {(roleVoucherReadOnly || roleVoucher) && (
        <>
          <NavLink to="/campanhas" className="go-back" />
          <div className="content-body">
            <HeaderVoucherSearch
              roleVoucher={roleVoucher}
              pageCurrent={page}
              dataVoucher={(e) => handleVoucher(e)}
            />

            {voucher && voucher.length > 0 && (
              <>
                <TableVoucherSearch
                  roleVoucher={roleVoucher}
                  vouchers={voucher}
                />

                <div className="search-customer-paginate">
                  {lastPage > 1 && (
                    <ReactPaginate
                      containerClassName="pagination-ifoodshop"
                      pageClassName="pagination-ifoodshop__item"
                      pageLinkClassName="pagination-ifoodshop__item-link"
                      activeLinkClassName="active"
                      previousLabel={
                        <img className="previous-arrow" src={IconArrow} />
                      }
                      nextLabel={<img className="next-arrow" src={IconArrow} />}
                      onPageChange={(e) => setPage(e.selected)}
                      pageRangeDisplayed={3}
                      pageCount={lastPage}
                      marginPagesDisplayed={1}
                      initialPage={page}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VoucherSearch;
