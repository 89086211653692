import React from 'react';
import Roles from '../../../../helpers/Roles';
import Header from '../../../../components/Header';
import FormRegisterPriceMinimum from '../../../../components/PriceMinimum/FormRegisterPriceMinimum';
import { getRoles } from "../../../../helpers/rolesUtils";


const PriceMinimumRegister = () => {
  const roles = getRoles()
  const roleMinPrice = roles.includes(Roles.PRICE_MINIMUM_WR);

  const roleSeller = roles.includes(Roles.SELLER_WR);

  if (!roleMinPrice) {
    window.location.href = '/';
  }

  return (
    <>
      {(roleMinPrice) && (
        <div className="content-body">
          <Header
            role={roleMinPrice}
            isSeller={roleSeller}
            title="Criar um novo pedido mínimo"
            description="Use os campos abaixo para criar um novo pedido mínimo."
            backRoute="/fornecedores/pedido-minimo"
          />
          <FormRegisterPriceMinimum
            role={roleMinPrice}
            isSeller={roleSeller}
          />
        </div>
      )}
    </>
  );
};

export default PriceMinimumRegister;
