import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_OFFER } from '../../../../graphql/offer.mutations';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { useOfferContext } from '@components/_context/OfferContext';
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';
import CardGrey from '@components/CardGrey';

import { ReactComponent as RectangleGray } from '../../../../assets/icons/rectangle-gray.svg';
import './progressive-price.scss';

registerLocale('ptBR', ptBR);

const FormRegisterProgressivePrice = () => {
  const [progressivePrice, setProgressivePrice] = useState({
    id: null,
    value: null,
    startDate: null,
    endDate: null,
    active: 'Habilitado',
    commercialCondition: '',
  });
  const history = useHistory();
  const {
    price,
    offer,
    productId,
    variationId,
    variation,
    setSpecialPriceList,
    variationImage,
    refetchSpecialPrices,
    setDrawerOpen,
    progressivePrices,
    setProgressivePrices,
  } = useOfferContext();

  const [modal, setModal] = useState(null);
  const optionCommercialCondition = ['Nenhuma', 'MEI'];
  const optionsState = ['Habilitado', 'Desabilitado'];
  const [commercialCondition, setCommercialCondition] = useState('Nenhuma');
  const [statePrice, setStatePrice] = useState('Habilitado');

  const [counter, setCounter] = useState(1);
  const [progressiveValues, setProgressiveValues] = useState([
    { price: undefined, minQuantity: undefined },
    { price: undefined, minQuantity: undefined },
    { price: undefined, minQuantity: undefined },
    { price: undefined, minQuantity: undefined },
  ]);

  useEffect(() => {
    setCommercialCondition(price?.commercialConditionId === 1 ? 'MEI' : 'Nenhuma');

    if (price) {
      changeValues(0, price?.price);
      changeQuantity(0, price?.minQuantity);
    }

    if (progressivePrice?.startDate) {
      new Date(progressivePrice?.startDate.getTime() - progressivePrice?.startDate.getTimezoneOffset() * 60000);
    }

    if (progressivePrice?.endDate) {
      new Date(progressivePrice?.endDate.getTime() - progressivePrice?.endDate.getTimezoneOffset() * 60000);
    }

    setProgressivePrices([...progressivePrices, starterPrice]);
  }, [price]);

  const [createPrice] = useMutation(UPDATE_OFFER, {
    onCompleted: data => {
      if (data?.createPrice?.error === 'PROGRESSIVE_PRICE_CONFLICT') {
        changeModal('Atenção', 'Já existe um preço progressivo no intervalo selecionado.');
      } else {
        refetchSpecialPrices().then(res => setSpecialPriceList(res.data.getOfferPricesByOfferId));
        setDrawerOpen(false);
        history.push('/catalogos/produtos/' + productId + '/variacoes/' + variationId + '/ofertas/editar/' + offer.id);
      }
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao cadastrar o preço progressivo');
    },
  });
  const starterPrice = (
    <div className="price-line">
      <OutlinedInputCurrency
        value={price?.price || progressiveValues[0]?.price}
        placeholder="Preço por unidade com desconto"
        onChange={e => changeValues(0, e.target.value)}
      />
      <OutlinedInput
        defaultValue={price?.minQuantity || progressiveValues[0]?.minQuantity}
        placeholder="Quantidade Mínima (Un)"
        border
        type="number"
        onChange={e => changeQuantity(0, e.target.value.replace(/\D/g, ''))}
      />
    </div>
  );

  const registerProgressivePrice = e => {
    e.preventDefault();

    let validacao = 1;

    progressiveValues.forEach(item => {
      if (item.price !== undefined && item.minQuantity !== undefined) {
        let floatPrice = parseFloat(item.price.toString().replace('R$ ', '').replaceAll('.', '').replace(',', '.'));

        let sendItems = {
          ...offer,
          variation: parseInt(variationId),
          seller: offer.sellerId,
          offerPrices: [
            {
              id: price?.id ? parseInt(price.id) : null,
              price: price?.price === item.price ? item.price : floatPrice,
              minQuantity: item.minQuantity,
              commercialConditionId: commercialCondition === 'MEI' ? 1 : null,
              startDate: progressivePrice?.startDate
                ? new Date(
                    progressivePrice.startDate.getTime() - progressivePrice.startDate.getTimezoneOffset() * 60000
                  )
                : null,
              endDate: progressivePrice?.endDate
                ? new Date(progressivePrice.endDate.getTime() - progressivePrice.endDate.getTimezoneOffset() * 60000)
                : null,
              active: progressivePrice?.active ? progressivePrice.active === 'Habilitado' : price.active,
            },
          ],
        };

        if (validacao === 1) {
          createPrice({ variables: sendItems });
          setProgressivePrices([]);
        }
      }
    });
  };

  function changeModal(title, message) {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  }

  const changeValues = (index, newValue) => {
    let floatPrice = newValue;
    let auxArr = [...progressiveValues];
    if (index === 0 || auxArr[index - 1].price > floatPrice) {
      auxArr[index].price = floatPrice;
    } else {
      if (auxArr[index - 1].price <= floatPrice) {
        changeModal('Atenção', 'O valor deve ser inferior ao da faixa anterior');
        auxArr[index].price = auxArr[index - 1].price - 1;
        setProgressivePrices(...progressivePrices);
        createNewPrice(index);
      }
    }
    setProgressiveValues(auxArr);
  };
  const changeQuantity = (index, value) => {
    const intValue = parseInt(value);
    let auxArr = [...progressiveValues];

    if (index === 0 || auxArr[index - 1].minQuantity < intValue) {
      auxArr[index].minQuantity = intValue;
    } else {
      if (auxArr[index - 1].minQuantity >= intValue) {
        changeModal('Atenção', 'A quantidade deve ser superior à da faixa anterior');
        auxArr[index].minQuantity = undefined;
        setProgressivePrices(...progressivePrices);
        createNewPrice(index);
      }
    }
    setProgressiveValues(auxArr);
  };

  const createNewPrice = index => {
    if (counter < 4) {
      const newPrice = (
        <div className="price-line">
          <OutlinedInputCurrency
            value={progressiveValues[index].value}
            placeholder="Preço por unidade com desconto"
            onChange={e => changeValues(index, e.target.value)}
          />
          <OutlinedInput
            placeholder="Quantidade Mínima (Un)"
            border
            type="number"
            onBlur={e => changeQuantity(index, e.target.value.replace(/\D/g, ''))}
          />
        </div>
      );
      setCounter(counter + 1);
      setProgressivePrices([...progressivePrices, newPrice]);
    }
  };

  return (
    <form className="form-register progressive-price" onSubmit={registerProgressivePrice}>
      <div className="price-container">{progressivePrices}</div>
      {progressivePrices?.length < 4 && !price && (
        <h2 className="clicable-text" onClick={() => createNewPrice(counter)}>
          + Adicionar nova faixa
        </h2>
      )}

      <CardGrey className="card-container">
        <div>
          <h2 className="price-title">Preço exibido atualmente no site</h2>
          <div className="body-container">
            {variationImage[0]?.imagePath ? (
              <img className="image-container" src={variationImage[0].imagePath} />
            ) : (
              <RectangleGray />
            )}

            <div className="body-text-container">
              <text>{variation.name}</text>
              <text>
                {variation.quantity} {variation.quantity > 1 ? 'unidades' : 'unidade'}
              </text>
            </div>
          </div>
        </div>

        <div className="price-container">
          <h2>R$ {offer.price}</h2>
          <h2>R$ {Math.round((offer.price / variation.quantity + Number.EPSILON) * 100) / 100} /un</h2>
        </div>
      </CardGrey>
      <div className="condition-container">
        <div className="subtitle-container second">
          <text className="subtitle">Está atrelado a alguma condição comercial?</text>
        </div>

        <div className="form-register dropdown-label-wrap">
          <Dropdown
            options={optionCommercialCondition}
            onChange={e => setCommercialCondition(e.value)}
            placeholder="Selecione"
            value={commercialCondition}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Condição Comercial</label>
        </div>
        <div className="form-register dropdown-label-wrap second-dropdown">
          <Dropdown
            options={optionsState}
            onChange={e =>
              setProgressivePrice(prevState => ({
                ...prevState,
                active: e.value,
              }))
            }
            placeholder="Selecione"
            value={statePrice}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Estado</label>
        </div>
      </div>

      <div className="date-container">
        <div className="subtitle-container second">
          <text className="subtitle">Data de validade do preço (opcional)</text>
        </div>
        <div className="input-line">
          <div
            className={`outlined-label-wrap outlined-date ${
              progressivePrice?.startDate ? 'active' : price?.startDate ? 'active' : ''
            }`}
          >
            <DatePicker
              className="form-brand__input"
              onChange={date =>
                setProgressivePrice(progressivePrice => ({
                  ...progressivePrice,
                  startDate: date,
                }))
              }
              locale="ptBR"
              showTimeSelect
              timeIntervals={15}
              selected={
                progressivePrice?.startDate
                  ? new Date(progressivePrice?.startDate)
                  : price?.startDate
                  ? new Date(price?.startDate)
                  : ''
              }
              dateFormat="dd/MM/yyyy hh:mm aa"
            />
            <label className="outlined-label">Data Inicial</label>
          </div>
          <div
            className={`outlined-label-wrap outlined-date ${
              progressivePrice?.endDate ? 'active' : price?.endDate ? 'active' : ''
            }`}
          >
            <DatePicker
              className="form-brand__input"
              onChange={date =>
                setProgressivePrice(progressivePrice => ({
                  ...progressivePrice,
                  endDate: date,
                }))
              }
              locale="ptBR"
              selected={
                progressivePrice?.endDate
                  ? new Date(progressivePrice?.endDate)
                  : price?.endDate
                  ? new Date(price?.endDate)
                  : ''
              }
              showTimeSelect
              timeIntervals={15}
              popperPlacement="top-end"
              dateFormat="dd/MM/yyyy hh:mm aa"
            />
            <label className="outlined-label">Data Final</label>
          </div>
        </div>
      </div>

      <div className="special-price special-price__submit button-container">
        <Button className="btn-save" type="submit">
          {`${!price?.id ? 'Criar' : 'Editar'} preço`}
        </Button>
      </div>
      {modal}
    </form>
  );
};

export default FormRegisterProgressivePrice;
