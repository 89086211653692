import { Typography } from 'ifoodshop-react-ui';

import OrderHelper from '@helpers/OrderHelper';
import Formatter from '@helpers/FormatHelper';

import './modalAuditHistory.scss';

const ModalAuditHistory = ({ history, phraseVariation }) => {
  return (
    <div className="audit-history">
      <Typography variant="h2">Histórico de alterações</Typography>
      <div className="audit-history__table">
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">Status</th>
              <th className="table-admin__title">Data do Status</th>
              <th className="table-admin__title">Atualizado em</th>
              <th className="table-admin__title">Usuário</th>
              <th className="table-admin__title">Motivo</th>
            </tr>
          </thead>
          <tbody>
            {history?.map((item, index) => (
              <tr key={`history-item--${index}`}>
                <td width="200">{OrderHelper.handleOrderDeliveryStatus(item.status, phraseVariation)}</td>
                <td width="160">{Formatter.format(item.stepDate, 'date')}</td>
                <td width="160">{Formatter.format(item.createdAt, 'date')}</td>
                <td width="50">{item.login}</td>
                <td>
                  <p className="audit-history__text audit-history__text--limit">{item.observation}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModalAuditHistory;
