import { useHistory, useParams } from 'react-router-dom';
import { Button, Flex, Grid, Heading2, Toggle, Text } from '@ifood/pomodoro-components';
import SellerRegistrationHeader from '../components/SellerRegistrationHeader';
import * as S from './SellerDetails.styles';
import RegistrationDataDetails from './components/RegistrationDataDetails';
import BankDataDetails from './components/BankDataDetails';
import EnrollmentDataDetails from './components/EnrollmentDataDetails';
import AddressDataDetails from './components/AddressDataDetails';
import AccessDataDetails from './components/AccessDataDetails';
import ContractualDataDetails from './components/ContractualDataDetails';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import { useSellerDetailsData } from './SellerDetailsHook';
import { pageSteps, SellerFormActions } from '../SellerFormType';
import { useSellerFormContext } from '../context/SellerFormContext';
import { useEffect, useState } from 'react';
import Loading from '@components/Loading';
import InactiveReasonModalForm from './components/InactiveReasonModalForm';
import InactivationReasonDetails from './components/InactivationReason/InactivationReasonDetails';

const SellerDetails = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const roles = getRoles();
  const roleCadSellerWR = roles.includes(Roles.CAD_SELLER_WR);
  const { dispatch } = useSellerFormContext();
  const { sellerChangeStatus, sellerResponse, setIsActiveWord, isLoadingDetails, refetch } = useSellerDetailsData();
  const [modalActivationReason, setModalActivationReason] = useState(false);
  const [ActivationToggle, setActivationToggle] = useState<boolean>();
  const isInactiveSeller = sellerResponse?.getSellerV2?.inactivationReason;

  const goToEdit = () => {
    history.push(`/fornecedores/editar/${id}`);
  };

  useEffect(() => {
    dispatch({
      type: SellerFormActions.resetAllFormData,
      payload: pageSteps.RegistrationStep,
    });
  }, [dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setActivationToggle(!!sellerResponse?.getSellerV2?.active);
  }, [sellerResponse?.getSellerV2?.active]);

  return (
    <S.Wrapper>
      <S.GoBackArrow to="/fornecedores/detail" />
      <SellerRegistrationHeader titleHeader="Fornecedores" />
      {isLoadingDetails && <Loading />}

      {!isLoadingDetails && (
        <S.Content data-testid="seller-details-content">
          <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
            <Flex alignItems="baseline" justifyContent="flex-start">
              <Heading2 mt={0} variant="medium">
                {sellerResponse?.getSellerV2?.name}
              </Heading2>
              {roleCadSellerWR && (
                <Flex pt={4}>
                  <Text ml={20}>Ativo</Text>
                  <Toggle
                    checked={ActivationToggle}
                    data-testid="ativo"
                    ml={8}
                    name="ativo"
                    onChange={e => {
                      {
                        if (e.target.checked) {
                          sellerChangeStatus({
                            variables: {
                              sellerStatus: {
                                sellerId: sellerResponse?.getSellerV2?.id,
                                isActive: true,
                              },
                            },
                          });
                          setActivationToggle(true);
                          setIsActiveWord('ativado');
                        } else {
                          setModalActivationReason(true);
                        }
                      }
                    }}
                  />
                </Flex>
              )}
            </Flex>
            <Flex alignItems="baseline" justifyContent="flex-end">
              {roleCadSellerWR && (
                <Button size="medium" variant="secondary" width={186} onClick={goToEdit}>
                  Editar
                </Button>
              )}
            </Flex>
          </Grid>
          <Grid gridGap={30} gridTemplateColumns="1fr 1fr" mt={30}>
            <Grid gridAutoRows="min-content" gridGap={30}>
              {isInactiveSeller && <InactivationReasonDetails />}
              <RegistrationDataDetails />
              <EnrollmentDataDetails />
              <AccessDataDetails />
            </Grid>
            <Grid gridAutoRows="min-content" gridGap={30}>
              <BankDataDetails />
              <AddressDataDetails />
              <ContractualDataDetails />
            </Grid>
          </Grid>
        </S.Content>
      )}

      <InactiveReasonModalForm
        isOpen={modalActivationReason}
        setIsOpen={setModalActivationReason}
        setToggle={setActivationToggle}
      />
    </S.Wrapper>
  );
};

export default SellerDetails;
