import { handleMaskSetValue } from '@helpers/mask';
import { Input, Select, Radio, Text } from '@ifood/pomodoro-components';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ImageUpload } from '@components/ImageUpload/ImageUpload';
import { useSellerTypeOptions } from '@pages/Partners/Seller/hooks/sellerTypeOptionsHook';
import { useBusinessTypeOptions } from '@pages/Partners/Seller/hooks/businessTypeOptionsHook';
import { RegistrationFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import { AlertFilled } from '@ifood/pomodoro-icons';
import * as S from '../RegistrationDataForm.styles';
import { BFFGetSellerV2Query } from '@services/bff/GraphqlOperations';
import { FormEvent, useEffect, useState } from 'react';

type BasicDataFormFieldsT = {
  form: UseFormReturn<RegistrationFormValueType, any>;
  sellerData?: BFFGetSellerV2Query['getSellerV2'];
};

export default function BasicDataFormFields({ form, sellerData }: BasicDataFormFieldsT) {
  const { setValue, register, formState, control } = form;
  const { errors } = formState;
  const { sellerTypeOptions } = useSellerTypeOptions();
  const { businessTypeOptions } = useBusinessTypeOptions();
  const logo = form.watch('logo');
  const isIntegrated = sellerData?.apiIntegration;

  const returnSegmentValue = (value: string) => {
    if (!value) return undefined;
    return form.getValues()?.segment === value
      ? {
          name: form.getValues()?.segment,
          value: form.getValues()?.segment,
        }
      : undefined;
  };

  const returnBusinessTypeValue = (value: string) => {
    if (!value) return undefined;

    const capitalizeCharAt = 0;
    const lowercaseSlice = 1;
    const formFieldValue = form.getValues()?.businessType;

    return formFieldValue === value
      ? {
          name:
            formFieldValue.charAt(capitalizeCharAt).toUpperCase() + formFieldValue.slice(lowercaseSlice).toLowerCase(),
          value: formFieldValue,
        }
      : undefined;
  };

  const [isTestMerchant, setIsTestMerchant] = useState(false);
  const handleTestRadio = (evt: FormEvent<HTMLDivElement>) => {
    const { value } = evt.target as HTMLInputElement;
    setIsTestMerchant(value === 'yes');
  };

  useEffect(() => {
    if (!isTestMerchant) return;
    setValue('merchantId', undefined);
    setValue('frnId', undefined);
  }, [isTestMerchant, setValue]);

  return (
    <>
      <Input
        {...register('cnae')}
        error={errors?.cnae?.message}
        label="CNAE*"
        marginBottom="large"
        name="cnae"
        onInput={e =>
          handleMaskSetValue({
            mask: '9999-9/99',
            event: e,
            fieldName: 'cnae',
            setValue: form.setValue,
          })
        }
      />

      <Input
        {...register('sellerName')}
        error={errors?.sellerName?.message}
        label="Nome do fornecedor*"
        marginBottom="large"
        name="sellerName"
      />

      <Input
        {...register('companyName')}
        error={errors?.companyName?.message}
        label="Razão Social*"
        marginBottom="large"
        name="companyName"
      />

      <Input
        {...register('mobilePhone')}
        error={errors?.mobilePhone?.message}
        label="Telefone fixo"
        marginBottom="large"
        name="mobilePhone"
        onInput={e =>
          handleMaskSetValue({
            mask: '(99) 99999-9999',
            event: e,
            fieldName: 'mobilePhone',
            setValue,
          })
        }
      />

      <Input
        {...register('cellPhone')}
        error={errors?.cellPhone?.message}
        label="Celular"
        marginBottom="large"
        name="cellPhone"
        onInput={e =>
          handleMaskSetValue({
            mask: '(99) 99999-9999',
            event: e,
            fieldName: 'cellPhone',
            setValue,
          })
        }
      />

      <Controller
        control={control}
        name="businessType"
        render={({ field, fieldState: { error } }) => (
          <Select
            error={error?.message}
            items={businessTypeOptions}
            label="Tipo de negócio*"
            marginBottom="large"
            name="businessType"
            placeholder="Selecione o tipo de negócio do fornecedor"
            value={returnBusinessTypeValue(field.value)}
            onChange={field.onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="segment"
        render={({ field, fieldState: { error } }) => (
          <Select
            error={error?.message}
            items={sellerTypeOptions}
            label="Segmento*"
            marginBottom="large"
            name="segment"
            placeholder="Selecione o segmento do fornecedor"
            value={returnSegmentValue(field.value)}
            onChange={field.onChange}
          />
        )}
      />

      <ImageUpload
        selectedImage={logo}
        uploadComponentProps={{
          buttonText: 'Adicionar logotipo',
          description: 'Arraste e solte a imagem JPG ou PNG aqui',
        }}
        onChange={file => setValue('logo', file || ('' as any))}
      />

      <Text id="integrado" marginTop="regular" variant="medium">
        Integrado Legado*
      </Text>
      <Radio.Group aria-labelledby="integrado" mb={20} role="group">
        <Radio.Button
          data-testid="integrado"
          id="input-positive"
          label="Sim"
          value="yes"
          {...register('integrated')}
          disabled={isIntegrated}
        />
        <Radio.Button id="input-negative" label="Não" value="no" {...register('integrated')} disabled={isIntegrated} />
        {formState.errors?.integrated && (
          <S.ErrorDisclaimerWrap>
            <S.ErrorDisclaimerIcon component={AlertFilled} />
            <S.ErrorDisclaimerTextMessage>{formState.errors?.integrated?.message}</S.ErrorDisclaimerTextMessage>
          </S.ErrorDisclaimerWrap>
        )}
      </Radio.Group>
      <Text id="new-integration" marginTop="regular" variant="medium">
        Nova integração?*
      </Text>
      <Radio.Group aria-labelledby="isNewIntegration" mb={20} role="group">
        <Radio.Button id="new-integration-positive" label="Sim" value="yes" {...register('isNewIntegration')} />
        <Radio.Button id="new-integration-negative" label="Não" value="no" {...register('isNewIntegration')} />
        {formState.errors?.isNewIntegration && (
          <S.ErrorDisclaimerWrap>
            <S.ErrorDisclaimerIcon component={AlertFilled} />
            <S.ErrorDisclaimerTextMessage>{formState.errors?.isNewIntegration?.message}</S.ErrorDisclaimerTextMessage>
          </S.ErrorDisclaimerWrap>
        )}
      </Radio.Group>
      <Text id="test-merchant" marginTop="regular" variant="medium">
        É uma loja teste?*
      </Text>
      <Radio.Group aria-labelledby="isTestMerchant" mb={20} role="group" onChange={evt => handleTestRadio(evt)}>
        <Radio.Button id="test-positive" label="Sim" value="yes" {...register('isTestMerchant')} />
        <Radio.Button id="test-negative" label="Não" value="no" {...register('isTestMerchant')} />
        {formState.errors?.isTestMerchant && (
          <S.ErrorDisclaimerWrap>
            <S.ErrorDisclaimerIcon component={AlertFilled} />
            <S.ErrorDisclaimerTextMessage>{formState.errors?.isTestMerchant?.message}</S.ErrorDisclaimerTextMessage>
          </S.ErrorDisclaimerWrap>
        )}
      </Radio.Group>
      <Input
        {...register('merchantId', { required: !isTestMerchant })}
        disabled={isTestMerchant}
        error={errors?.merchantId?.message}
        label={`Merchant ID${isTestMerchant ? '' : '*'}`}
        marginBottom="large"
        name="merchantId"
      />
      <Input
        {...register('frnId', { required: !isTestMerchant })}
        disabled={isTestMerchant}
        error={errors?.frnId?.message}
        label={`Fornecedor ID${isTestMerchant ? '' : '*'}`}
        marginBottom="large"
        name="frnId"
      />
    </>
  );
}
