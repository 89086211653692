import styled from 'styled-components';
import { Input } from '@ifood/pomodoro-components';

const InputNumber = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    opacity: 1;
  }
`;

export { InputNumber };
