import React from 'react';
import { NavLink } from 'react-router-dom';
import Formatter from '../../../../../../helpers/FormatHelper';

const TableSearchRegion = ({ data, roles, isSeller }) => {

  const { roleRegion } = roles

  return (
    <>
      {data?.length > 0 && (
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">Regiões</th>
              {!isSeller && <th className="table-admin__title">Proprietário</th>}
              <th className="table-admin__title">Data da criação</th>
              <th className="table-admin__title">Estado</th>
              <th className="table-admin__title"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr key={item.id}>
                  <td className="table-admin__data">{item?.name}</td>
                  {!isSeller && (
                    <td className="table-admin__data">
                      {item?.ownerType === 'SELLER'
                        ? item?.seller?.name
                        : 'Geral'}
                    </td>
                  )}
                  <td className="table-admin__data">
                  {Formatter.format(item?.createdAt, 'simple-date')}
                  </td>
                  <td className="table-admin__data">
                    {item?.active ? 'Habilitado' : 'Desabilitado'}
                  </td>
                  <td className="table-admin__data">
                    <NavLink
                      to={roleRegion ? '/campanhas/regioes/editar/' + item?.id : '#'}
                      className={roleRegion ? 'link' : 'link--disabled'}
                    >
                      Editar
                    </NavLink>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TableSearchRegion;
