/* istanbul ignore file */
import FormRegisterOffer from '../components/FormRegisterOffer';

import * as S from './CreateOffer.styles';

const CreateOffer = (props: { match: { params: { productid: string; variationid: string } } }): JSX.Element => {
  return (
    <S.Wrapper>
      <FormRegisterOffer productid={props.match.params.productid} variationid={props.match.params.variationid} />
    </S.Wrapper>
  );
};

export default CreateOffer;
