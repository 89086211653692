import gql from 'graphql-tag';

const CREATE_MINPRICE = gql`
  mutation createPriceMinimum(
    $regionId: ID
    $sellerId: ID
    $active: Boolean
    $minimumOrder: Float
  ) {
    createPriceMinimum(
      input: {
        regionId: $regionId
        sellerId: $sellerId
        active: $active
        minimumOrder: $minimumOrder
      }
    ) {
      error
    }
  }
`;

const UPDATE_MINPRICE = gql`
  mutation updatePriceMinimum($minPriceId: ID!, $active: Boolean) {
    updatePriceMinimum(minPriceId: $minPriceId, active: $active) {
      error
    }
  }
`;

export { CREATE_MINPRICE, UPDATE_MINPRICE };
