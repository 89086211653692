import { RegistrationFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import { useGetSellerInfoByCnpjLazyQuery } from '@services/bff/GraphqlHooks';
import { maskInput } from '@helpers/mask';
import { UseFormSetValue } from 'react-hook-form';

const CNPJ_LENGTH = 14;
const FIRST_POS = 0;

export function useRegistrationDataForm({ setValue }: { setValue: UseFormSetValue<RegistrationFormValueType> }) {
  const [getCnae] = useGetSellerInfoByCnpjLazyQuery({
    onCompleted: data => {
      const cnaeList = data?.getSellerValidationCnpj?.cnae;
      const cnae = (cnaeList && cnaeList[FIRST_POS]?.code) || '';
      const cnaeRaw = cnae.replace(/\D/g, '');
      const cnaeFormatted = maskInput({ value: cnaeRaw, mask: '9999-9/99' });

      setValue('cnae', cnaeFormatted);
    },
  });

  async function onCnpjInputChange(value: string) {
    const cnpjRaw = value.replace(/\D/g, '');

    if (cnpjRaw.length < CNPJ_LENGTH) return;

    getCnae({ variables: { cnpj: cnpjRaw } });
  }

  return {
    onCnpjInputChange,
  };
}
