import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './ContractualDataForm.styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ComponentCommonPropsType,
  ContractualFormValueType,
  CreateSellerDataType,
} from '@pages/Partners/Seller/SellerRegistration/types';
import { Grid, Flex, Heading3, Text, Button } from '@ifood/pomodoro-components';
import { pageSteps, SellerFormActions } from '../../../SellerFormType';

import schema from './schema';
import { useSellerFormContext } from '../../../context/SellerFormContext';
import ContractualDataFormField from './components/ContractualDataFormFields';
import ComercialContactDataFields from './components/ComercialContactDataFields';

type ContractualDataFormI = ComponentCommonPropsType & {
  onSubmitParent: (sellerData: CreateSellerDataType) => void;
  isSubmitLoading: boolean;
};

const ContractualDataForm = ({
  triggerChangeEvent,
  onSubmitParent,
  isSubmitLoading,
}: ContractualDataFormI): JSX.Element => {
  const { state } = useSellerFormContext();
  const form = useForm<ContractualFormValueType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      ...state.contractualFormData,
    },
  });

  const handlePreviousStep = () => {
    triggerChangeEvent(pageSteps.BankStep, SellerFormActions.setCurrentStep);
  };

  const onSubmit: SubmitHandler<ContractualFormValueType> = data => {
    triggerChangeEvent(data, SellerFormActions.setContractualFormData);
    onSubmitParent({
      ...state,
      contractualFormData: data,
    });
  };

  return (
    <S.Form onSubmit={form.handleSubmit(onSubmit)}>
      <S.DividerSection marginBottom="large" />
      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Flex flexDirection="column">
          <Heading3 my={4} variant="smallest">
            Dados contratuais
          </Heading3>
          <Text variant="regular">Dados e informações do contrato do fornecedor</Text>
        </Flex>
        <Flex flexDirection="column">
          <ContractualDataFormField form={form} />
        </Flex>
      </Grid>
      <S.DividerSection marginBottom="large" />

      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Flex flexDirection="column">
          <Heading3 my={4} variant="smallest">
            Contato comercial
          </Heading3>
          <Text variant="regular">Dados e informações do contato comercial</Text>
        </Flex>
        <Flex flexDirection="column">
          <ComercialContactDataFields form={form} />
        </Flex>
      </Grid>

      <S.DividerSection marginBottom="large" marginTop="large" />

      <Flex alignItems="flex-end" justifyContent="flex-end">
        <Button variant="primary-inverted" onClick={handlePreviousStep}>
          Voltar
        </Button>
        <Button disabled={isSubmitLoading} isLoading={isSubmitLoading} type="submit">
          Salvar
        </Button>
      </Flex>
    </S.Form>
  );
};

export default ContractualDataForm;
