/* istanbul ignore file */
import CustomerListForm from './components/CustomerListForm';

import * as S from './CustomerList.styles';

const CustomerList = (): JSX.Element => {
  return (
    <S.Wrapper>
      <CustomerListForm />
    </S.Wrapper>
  );
};

export default CustomerList;
