import React from 'react';
import Roles from '../../../../helpers/Roles';
import Header from '../../../../components/Header';
import FormRegisterRegion from './components/FormRegisterRegion';
import { getRoles } from "../../../../helpers/rolesUtils";
import { useHistory } from 'react-router-dom';

const RegisterRegion = (props) => {
  const { id } = props?.match?.params;
  const history = useHistory();
  const roles = getRoles();

  const roleRegion = roles.includes(Roles.REGION_WR);
  const roleSeller = roles.includes(Roles.SELLER_WR);
  const rolesValidation = [roleRegion, roleSeller]

  if (!roleRegion) {
    history.push('');
  }

  return (
    <>
      {(roleRegion) && (
        <div className="content-body">
          <Header
            title={id ? 'Editar região' : 'Criar uma nova região'}
            description={`Use os campos abaixo para ${id ? 'editar' : 'criar'}
           uma nova região.`}
            backRoute="/campanhas/regioes"
            role = {rolesValidation}
          />
          <FormRegisterRegion id={id} role={roleRegion} isSeller={roleSeller} />
        </div>
      )}
    </>
  );
};

export default RegisterRegion;
