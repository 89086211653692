export const specialPricesLabelsList = {
  "213-5": "Preço MEI",
};

const errorsSpecialPrice = {
  CA0013: "Preço especial não pode ser negativo",
  CA0014: "Erro nas condições do preço especial",
  CA0015: "Preço especial não encontrado",
  CA0016: "Variação em relação ao preço especial atual inválido.(50%)",
  CA0017: "Condição comercial ou data é obrigatorio",
  CA0018: "Período de vigência do preço especial com data inválida.",
  CA0019: "Condição comercial não encontrada",
  CA0020: "Condição comercial inválida",
  CA0021: "Preço especial inválido ou inexistente.",
  CA0022: "Já existe um preço especial que atende essas condições",
};

export function specialPriceErrorMessage(errorCode) {
  return errorsSpecialPrice[errorCode];
}

export function convertPriceStringToFloat(price) {
  return parseFloat(Number(price.replace("R$", "").replaceAll(".", "").replace(",", ".")));
}
