import { Card, Flex, Grid, Heading3, Text } from '@ifood/pomodoro-components';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSellerDetailsData } from '../../SellerDetailsHook';

const BankDataDetails = (): JSX.Element => {
  const { sellerResponse } = useSellerDetailsData();
  const theme = useContext(ThemeContext);

  return (
    <Card>
      <Flex flexDirection="column">
        <Heading3 mb={24} mt={4} variant="smallest">
          Dados bancários
        </Heading3>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Tipo de conta
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.bankAccount?.accountType}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Banco
        </Text>
        <Text mb={24} variant="large">
          {`${sellerResponse?.getSellerV2?.bankAccount?.bankCode} - ${sellerResponse?.getSellerV2?.bankAccount?.bankName}`}
        </Text>
        <Grid gridGap={6} gridTemplateColumns="1fr 1Fr">
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Agência
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.bankAccount?.agency}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Conta corrente:
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.bankAccount?.account}
            </Text>
          </Flex>
        </Grid>
      </Flex>
    </Card>
  );
};

export default BankDataDetails;
