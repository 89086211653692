import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { CREATE_PRODUCT_IMAGE } from '../../../graphql/product.mutations';
import { GET_VARIATIONS_BY_PRODUCT } from '../../../graphql/variation.queries';
import { GET_SPECIFICATIONS } from '../../../graphql/specification.queries';
import ReactPaginate from 'react-paginate';
import { Link, Button } from 'ifoodshop-react-ui';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import AuthorizationHeader from '@helpers/AuthorizationHeader';

import FormEditProduct from '../components/FormEditProduct';
import FormSearchProductVariation from '../components/FormSearchProductVariation';
import HeaderRegisterProduct from '../components/HeaderRegisterProduct';
import TableProductVariation from '../components/TableProductVariation';
import DragDrop from '@components/DragDrop';

import IconArrow from '../../../assets/images/arrow.png';
import './editproduct.scss';
import { api } from '@services/api';

const ProductEdit = props => {
  const history = useHistory();
  const roles = getRoles();
  const rolePrdWriter = roles.includes(Roles.PRODUCT_WR);
  const rolePrdReader = roles.includes(Roles.PRODUCT_RO);
  const roleVarWriter = roles.includes(Roles.VARIATION_WR);
  const roleVarReader = roles.includes(Roles.VARIATION_RO);

  if (!rolePrdWriter && !rolePrdReader) {
    history.push('/catalogos/produtos');
  }

  function ReadOnly(prdWriter) {
    if (!prdWriter) {
      return 'disabled';
    }
    return '';
  }

  
  const [page, setPage] = useState(0);
  const [name, setName] = useState(null);
  const [active, setActive] = useState(null);
  const [formData, setFormData] = useState(null);
  const [imageList, setImageList] = useState(1);
  const [activetab, setActivetab] = useState(0);
  const [createProductImage, { error: errorImage, data: dataImage }] = useMutation(CREATE_PRODUCT_IMAGE);
  const [lastPage, setLastPage] = useState(0);
  const [variations, setVariations] = useState([]);
  const [getVariations, { data: dataVariations }] = useLazyQuery(GET_VARIATIONS_BY_PRODUCT, {
    fetchPolicy: 'no-cache',
  });
  const [getSpecifications] = useLazyQuery(GET_SPECIFICATIONS);
  const [productEdit, setProductEdit] = useState(null);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={activetab !== index}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function BtnUpload() {
    return (
      <div>
        <form id="frmUpload" onSubmit={ImagesUpload}>
          <input type="file" accept="image/*" id="upload-input" hidden multiple onChange={ImagesUpload} />
        </form>
        <label className="btn-save" htmlFor="upload-input">
          <Link>Adicionar imagens</Link>
        </label>
      </div>
    );
  }

  function TabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    getVariations({
      variables: {
        name: name,
        page: page,
        size: 5,
        price: null,
        active: active,
        productId: parseInt(props.match.params.id),
        createdAt: null,
      },
    });
  }, [page, name, active]);
  useEffect(() => {
    getSpecifications({
      variables: { product: parseInt(props.match.params.id) },
    });
  }, []);
  useEffect(() => {
    if (formData) {
      setPage(0);
      setName(formData.variationName);
      setActive(formData.active);
    }
  }, [formData]);

  useEffect(() => {
    if (dataVariations) {
      setVariations(dataVariations.getAllProductVariations.content);
      setLastPage(dataVariations.getAllProductVariations.totalPages);
    }
  }, [dataVariations]);

  const handleTabChange = (event, newValue) => {
    setActivetab(newValue);
  };

  const ImagesUpload = e => {
    e.preventDefault();
    let uploaddata = new FormData();
    let ins = document.getElementById('upload-input').files.length;
    for (let x = 0; x < ins; x++) {
      uploaddata.append('file', document.getElementById('upload-input').files[x]);
    }
    uploaddata.append('productId', parseInt(props.match.params.id));
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');
    
    api.post('/product/image/upload', uploaddata)
      .then(res => res.json())
      .then(
        function (data) {
          let size = data.length;
          Object.keys(data).map(function (imagepath, index) {
            const orderImageValidation = index === index ? index + 1 : index;

            createProductImage({
              variables: {
                imagePath: data[index],
                active: true,
                order: orderImageValidation,
                product: parseInt(props.match.params.id),
              },
            })
              .then(res2 => {
                if (size == index + 1) {
                  setImageList(Math.random());
                }
              })
              .catch(err2 => {
                return err2;
              });
          });
        },
        function (err) {
          return err;
        }
      );
  };

  function btnEdit(e, random) {
    setProductEdit({ e, random });
  }

  return (
    <>
      <NavLink to="/catalogos/produtos/" className="go-back" />
      <div className="content-body edit-product">
        <HeaderRegisterProduct title="Edite um Produto" description="Use os campos abaixo para editar o produto." />
        <FormEditProduct id={props.match.params.id} readonly={ReadOnly(rolePrdWriter)} edit={productEdit} />

        {(roleVarWriter || roleVarReader) && (
          <div className="product-variations">
            <div>
              <h2 className="section-title">Variações</h2>
              <FormSearchProductVariation data={d => setFormData(d)} id={props.match.params.id} role={roleVarWriter} />
            </div>

            <div className="table-box">
              {variations ? (
                <TableProductVariation variations={variations} productid={props.match.params.id} role={roleVarWriter} />
              ) : (
                <a> </a>
              )}

              <div className="search-brand-paginate">
                {variations && lastPage > 1 ? (
                  <ReactPaginate
                    search-brand-paginate
                    containerClassName="pagination-ifoodshop"
                    pageClassName="pagination-ifoodshop__item"
                    pageLinkClassName="pagination-ifoodshop__item-link"
                    activeLinkClassName="active"
                    previousLabel={<img className="previous-arrow" src={IconArrow} />}
                    nextLabel={<img className="next-arrow" src={IconArrow} />}
                    onPageChange={e => setPage(e.selected)}
                    pageRangeDisplayed={3}
                    pageCount={lastPage}
                    marginPagesDisplayed={1}
                    initialPage={page}
                  />
                ) : (
                  <a></a>
                )}
              </div>
            </div>
          </div>
        )}

        <Tabs
          className="product-variation-tabs"
          value={activetab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label="Imagens" {...TabProps(0)} />
        </Tabs>

        <TabPanel value={activetab} index={0}>
          <div className="header-customer-group header-customer-group--src">
            <div className="header-customer-group__info">
              <h2 className="section-title">Lista de Imagens</h2>
            </div>

            {ReadOnly(rolePrdWriter) != 'disabled' ? BtnUpload() : null}
          </div>
          {imageList ? <DragDrop productid={props.match.params.id} readonly={ReadOnly(rolePrdWriter)} /> : <a></a>}
        </TabPanel>
        {ReadOnly(rolePrdWriter) != 'disabled' ? (
          <Button className="btn-save" form="frmeditproduct" type="button" onClick={e => btnEdit(e, Math.random())}>
            Salvar produto
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default ProductEdit;
