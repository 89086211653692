import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_REJECTION_REASON } from '../../graphql/product-suggest.queries';
import { DECLINE_SUGGEST } from '../../graphql/suggestion.mutation';
import Dropdown from 'react-dropdown';
import { Button } from 'ifoodshop-react-ui';

const FormReject = props => {
  const [modal, setModal] = useState(null);
  const [reason, setReason] = useState(null);
  const [reasonId, setReasonId] = useState(null);
  const [declineSuggest, { error: errorApproved, data: dataApproved }] = useMutation(DECLINE_SUGGEST);
  const [getRejectionReason, { data: dataRejectionReason }] = useLazyQuery(GET_REJECTION_REASON);

  useEffect(() => {
    getRejectionReason();
  }, []);

  function handleSelected(value) {
    const objSelected = dataRejectionReason?.getRejectionReason.find(item => item.name === value);
    setReasonId(objSelected?.id);
    setReason(objSelected?.name);
  }

  function editSuggestion(e) {
    e.preventDefault();

    declineSuggest({
      variables: {
        id: parseInt(props.suggestionId),
        rejectionReasonId: reasonId,
      },
    })
      .then(res => {
        window.location.href = '/catalogos/sugestoes';
      })
      .catch(error => {
        props.responseModal('Erro ao atualizar sugestão.');
      });
  }

  return (
    <form onSubmit={editSuggestion}>
      {modal}
      <div className="dropdown-label-wrap">
        <Dropdown
          value={reason}
          placeholder="Selecione"
          options={
            dataRejectionReason?.getRejectionReason
              ? dataRejectionReason?.getRejectionReason.reduce((acc, item) => [...acc, item['name']], [])
              : []
          }
          onChange={e => handleSelected(e.value)}
          className="dropdown-ifood"
          controlClassName="dropdown-ifood__control"
          placeholderClassName="dropdown-ifood__placeholder"
          menuClassName="dropdown-ifood__menu"
          arrowClassName="dropdown-ifood__arrow"
        />
        <label className="dropdown-label">Motivos para rejeição</label>
      </div>
      <Button className="btn-save">Salvar</Button>
    </form>
  );
};

export default FormReject;
