import { HeaderOptionsType, EnumHeaderOptionsType } from '@components/BulkImport';
import ModalImportFile from '@components/ModalImportFile';
import Roles from '@helpers/Roles';
import TemplateBonusShop from '@files/templateImportBonusShop.csv';

const bonusShopImportOptions: HeaderOptionsType[] = [
  {
    name: 'Modelo importação Bônus Shop',
    value: 'bonus-shop-download',
    template: TemplateBonusShop,
    roles: [Roles.BONUS_SHOP_IMPORT],
    type: EnumHeaderOptionsType.DOWNLOAD,
  },
  {
    name: 'Importação de Bônus Shop',
    type: EnumHeaderOptionsType.UPLOAD,
    value: 'bonus-shop-import',
    roles: [Roles.BONUS_SHOP_IMPORT],
    action: (callback?: { closeModal: (value: any) => void }) => {
      return (
        <ModalImportFile
          showConfirmationDialog
          description=""
          feedbackType="snackbar"
          fileType="csv"
          setModal={callback?.closeModal}
          showEmailInput={false}
          title="Importação de Bônus Shop"
          urlImport="wallet/import"
        />
      );
    },
  },
];

export default bonusShopImportOptions;
