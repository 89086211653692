import React from 'react';
import CatalogItem from '@components/CatalogItem';
import ImageDistributionCenter from '@assets/images/home-distribution-center.png';
import ImageDashboard from '@assets/images/seller-dashboard.svg';
import ImageMyData from '@assets/images/seller-data.svg';
import ImagePriceMinimum from '@assets/images/seller-price-minimum.svg';
import ImageSeller from '@assets/images/seller-image.svg';
import Roles from '@helpers/Roles';
import { getRoles } from "@helpers/rolesUtils";


const HomeSeller = () => {
  const roles = getRoles();

  const sellerRole = roles.includes(Roles.SELLER_RO) || roles.includes(Roles.SELLER_WR);

  const items = [
    {
      title: "Centro de distribuição",
      image: ImageDistributionCenter,
      description: "Centro de distribuição",
      link: "fornecedores/enderecos-distribuicao",
      showSeller:
        roles.includes(Roles.DISTRIBUTION_ADDRESS_RO) ||
        roles.includes(Roles.DISTRIBUTION_ADDRESS_WR),
    },
    {
      title: "Dashboard",
      image: ImageDashboard,
      description: "Dashboard",
      link: "fornecedores/dashboard",
      showSeller:
        roles.includes(Roles.DASHBOARD_RO) &&
        !roles.includes(Roles.DASHBOARD_WR) &&
        !sellerRole, // REMOVE TO ACTIVE DASHBOARD CARD
    },
    {
      title: "Meus dados",
      image: ImageMyData,
      description: "Meus dados",
      link: "fornecedores/meus-dados",
      showSeller:
        roles.includes(Roles.DATA_SELLER_RO) ||
        roles.includes(Roles.DATA_SELLER_WR),
    },
    {
      title: "Fornecedores",
      image: ImageSeller,
      description: "Consulta de fornecedores",
      link: "fornecedores/detail",
      showSeller:
        roles.includes(Roles.COMMISSION_RO) ||
        roles.includes(Roles.COMMISSION_WR),
    },
    {
      title: "Pedido mínimo",
      image: ImagePriceMinimum,
      description: "Pedido mínimo",
      link: "fornecedores/pedido-minimo",
      showSeller:
        roles.includes(Roles.PRICE_MINIMUM_RO) ||
        roles.includes(Roles.PRICE_MINIMUM_WR),
    },
  ];
  return (
    <div className="content-body home">
      <div className="content-body__box">
        {items.map((item) => {
          const { image, title, description, link, showSeller } = item;
          if (showSeller) {
            return (
              <CatalogItem
                image={image}
                title={title}
                description={description}
                link={link}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default HomeSeller;
