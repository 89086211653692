/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Flex } from '@ifood/pomodoro-components';

import * as S from './FiltersCustomerList.style';

const FiltersCustomerList = ({ variables, onSubmitFilters }: any): JSX.Element => {
  const [filters, setFilters] = useState(variables);

  const handleFilter = (name: any, value: any) => {
    setFilters({
      ...filters,
      [name]: name === 'String' ? parseInt(value) : value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmitFilters(filters);
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit}>
        <S.Content>
          <Flex>
            <S.InputsContent>
              <S.InputString
                marginLeft={35}
                name="nome"
                placeholder="Nome"
                value={filters.nome}
                onChange={e => handleFilter('nome', e.target.value)}
              />
              <S.InputString
                marginLeft={20}
                name="id"
                placeholder="ID"
                value={filters.id}
                onChange={e => handleFilter('id', e.target.value)}
              />
              <S.InputString
                marginLeft={20}
                name="email"
                placeholder="E-mail"
                value={filters.login}
                onChange={e => handleFilter('login', e.target.value)}
              />
              <S.InputString
                marginLeft={20}
                name="document"
                placeholder="Documento"
                value={filters.document}
                onChange={e => handleFilter('document', e.target.value)}
              />
            </S.InputsContent>

            <S.ButtonSearch marginLeft={20} type="submit">
              Buscar
            </S.ButtonSearch>
          </Flex>
        </S.Content>
      </S.Form>
    </>
  );
};

export default FiltersCustomerList;
