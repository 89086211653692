import Formatter from '@helpers/FormatHelper';

import './UpdatesHistoricModal.scss';

function UpdatesHistoricModal({ items }) {
  return (
    <div className="historic">
      <h1 className="historic__title">Histórico de alterações por ruptura de estoque</h1>
      <div className="historic__table">
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">Data</th>
              <th className="table-admin__title">Produto</th>
              <th className="table-admin__title">Alteração</th>
              <th className="table-admin__title">Login</th>
              <th className="table-admin__title">Pagamento Processado?</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map(
                item =>
                  item.hasHistory &&
                  item.history.map(itemHistory => (
                    <tr id={itemHistory.id} key={itemHistory.id}>
                      <td className="table-admin__data">{Formatter.ptBrDate(itemHistory.createdAt)}</td>
                      <td className="table-admin__data">{item.name}</td>
                      <td className="table-admin__data">
                        {itemHistory.orderItemStatus === 'QUANTITY_UPDATED'
                          ? `Remoção de ${itemHistory.oldQuantity - itemHistory.newQuantity} unidade${
                              itemHistory.oldQuantity - itemHistory.newQuantity > 1 ? 's' : ''
                            } do pedido. Quantidade final: ${itemHistory.newQuantity}`
                          : itemHistory.orderItemStatus === 'WEIGHT_UPDATE'
                          ? `Alterado peso variável do produto de ${
                              Math.round(itemHistory.oldQuantity * itemHistory.oldWeight * 1000) / 1000
                            } para ${Math.round(itemHistory.oldQuantity * itemHistory.newWeight * 1000) / 1000}`
                          : 'Produto removido do pedido'}
                      </td>
                      <td className="table-admin__data">{itemHistory.login}</td>
                      <td className="table-admin__data">{itemHistory.paymentProcessedStatus ? 'Sim' : 'Não'}</td>
                    </tr>
                  ))
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UpdatesHistoricModal;
