/* istanbul ignore file */
import { Heading3 as HeadingPomodoro, Text, Card as CardPomodoro } from '@ifood/pomodoro-components';
import styled, { css } from 'styled-components';
interface IAvailableAmount {
  value?: number;
}

export const Card = styled(CardPomodoro)`
  ${({ theme }) => css`
    padding: ${theme.space.regular} ${theme.space.large};
    box-shadow: ${theme.shadows.elevate};
  `}
`;
export const Heading = styled(HeadingPomodoro)`
  ${({ theme }) => css`
    color: ${theme.colors.grayDarkest};
    font-weight: ${theme.fontWeights.regular};
    margin: ${theme.space.none};
  `}
`;

export const AvailableAmount = styled(Text)<IAvailableAmount>`
  ${({ value, theme }) => css`
    color: ${value ? theme.colors.positive : theme.colors.grayDarker};
    font-size: ${theme.fontSizes.txl};
    font-weight: ${theme.fontWeights.medium};
    margin-top: ${theme.space.smallest};
  `}
`;

export const BonusShopPercentage = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grayDarker};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.fontSizes.xs};
    margin: ${`${theme.space.smaller} 0 0`};
  `}
`;
