import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal, OutlinedInput } from 'ifoodshop-react-ui';
import { Button, Text, snackbar } from '@ifood/pomodoro-components';

import AuthorizationHeader from '@helpers/AuthorizationHeader';
import { getSellerId } from '@helpers/rolesUtils';

import { useKeycloakContext } from '@context/KeycloakContext';
import MessageSendFile from './MessageSendFile';
import Loading from '@components/Loading';
import ConfirmationDialog from './ConfirmationDialog';

import IconImportXlsx from '../../assets/icons/import-xlsx.svg';
import IconImportCsv from '../../assets/icons/import-csv.svg';
import IconSpreadSheet from '../../assets/icons/spreadsheet.svg';
import IconSpreadSheetActive from '../../assets/icons/spreadsheet_red.svg';
import IconRefresh from '../../assets/icons/refresh.svg';

import './style.scss';
import { api } from '@services/api';

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const FIRST_INDEX = 0;
const LIMIT_FILE = 1;
const SUCCESS_STATUS_LIST = [200, 204];
const TIMEOUT_TIMER = 2000;

let formData = new FormData();

function ModalImportFile({
  setModal,
  fileType,
  urlImport,
  title,
  description,
  uploadS3 = '',
  showConfirmationDialog = false,
  showEmailInput = true,
  feedbackType = 'modal',
}) {
  const { keycloak } = useKeycloakContext();
  const user = keycloak?.tokenParsed?.email;
  const sellerId = getSellerId();
  const userName = keycloak?.tokenParsed?.preferred_username;

  const [drag, setDrag] = useState(false);
  const [dataFile, setDataFile] = useState(null);
  const [successFile, setSuccessFile] = useState(false);
  const [hasImport, setHasImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendFile, setSendFile] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: user,
    },
  });

  function dropHandler(e) {
    e.preventDefault();
    setDrag(false);

    let type;
    switch (fileType) {
      case 'csv':
        type = 'text/csv';
        break;
      default:
        type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
    }

    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    if (files && files.length === LIMIT_FILE) {
      if (files[FIRST_INDEX].type === type) {
        formData.append('file', files[FIRST_INDEX]);
        setSuccessFile(true);
        setDataFile(files[FIRST_INDEX]);
      } else {
        setSuccessFile(false);
        setDataFile('O arquivo não está no formato solicitado ou está corrompido');
      }
    } else if (files && files.length > LIMIT_FILE) {
      setSuccessFile(false);
      setDataFile('Voĉe deve selecionar apenas um arquivo');
    } else {
      setSuccessFile(false);
      setDataFile('Selecione um arquivo');
    }
  }

  function dragOverHandler(e) {
    e.preventDefault();
    setDrag(true);
  }

  const handleSnackBarFeedback = hasSuccess => {
    onClose();

    if (hasSuccess)
      snackbar({
        variant: 'success',
        message: 'Arquivo importado com sucesso',
        autoClose: true,
        timeout: TIMEOUT_TIMER,
      });
    else
      snackbar({
        variant: 'error',
        message: 'Houve um erro ao importar o arquivo',
        autoClose: true,
        timeout: TIMEOUT_TIMER,
      });
  };

  const onSubmit = data => {
    const { email } = data;
    const fields = {
      userEmail: email,
      user: userName,
      sellerId: sellerId,
      app: 'SUGGESTION',
    };
    const file = {
      dataFile,
    };

    setLoading(true);

    if (urlImport) {
      formData.append('email', email);

      api
        .post(urlImport, formData)
        .then(response => {
          setLoading(false);

          if (SUCCESS_STATUS_LIST.includes(response.status)) {
            if (feedbackType === 'modal')
              return response.text().then(res => {
                setHasImport(<MessageSendFile resetModal={resetModal} calledNumber={res} />);
              });

            return handleSnackBarFeedback(response.ok);
          }
          console.error(`[Unable to import file]: ${response.status} - ${response.statusText}`);

          return feedbackType === 'modal'
            ? setHasImport(<MessageSendFile resetModal={resetModal} error />)
            : handleSnackBarFeedback(response.ok);
        })
        .catch(error => {
          console.error(`[Unable to import file]: ${error?.message || error}`);
          setLoading(false);

          if (feedbackType === 'modal') return setHasImport(<MessageSendFile resetModal={resetModal} error />);

          return handleSnackBarFeedback(false);
        });
    } else {
      formData.append('fields', JSON.stringify(fields));
      formData.append('files', file);

      fetch(uploadS3, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: AuthorizationHeader.getHeaderToken(),
        },
      }).then(response => {
        setLoading(false);
        if (response.status === STATUS_OK)
          return response.text().then(res => {
            setHasImport(<MessageSendFile resetModal={resetModal} calledNumber={res} />);
          });
        else return setHasImport(<MessageSendFile resetModal={resetModal} error />);
      });
    }
  };

  function resetModal() {
    setHasImport(false);
    setSuccessFile(false);
    setDataFile(null);
    setSendFile(false);
    formData = new FormData();
  }

  const onClose = () => {
    setModal(null);
    resetModal();
  };

  const sendButtonProps = useMemo(
    () => ({
      type: showConfirmationDialog ? 'button' : 'submit',
      disabled: !isValid || !successFile,
      onClick: () => (showConfirmationDialog ? setSendFile(true) : null),
    }),
    [showConfirmationDialog, isValid, successFile]
  );

  return (
    <>
      <Modal onCloseAction={onClose} className="modal--import-product" title={title}>
        {!hasImport ? (
          <>
            <p className="modal__subtitle">{description}</p>

            <form onSubmit={handleSubmit(onSubmit)} id="file-submit">
              {showConfirmationDialog && sendFile ? (
                <ConfirmationDialog onClose={onClose} />
              ) : (
                <div style={{ width: '682px' }}>
                  <div
                    id="drop_zone"
                    className={`modal__import-zone ${drag ? 'modal__import-zone--active' : ''}`}
                    onDrop={e => dropHandler(e)}
                    onDragOver={e => dragOverHandler(e)}
                    onDragLeave={() => setDrag(false)}
                  >
                    <div className="modal__import-zone__header">
                      <span>{dataFile?.name || 'Nenhum arquivo inserido'}</span>
                      <span className="modal__import-zone__max-size">Tamanho máximo do arquivo: 12MB</span>
                    </div>

                    <div className="modal__import-zone__body">
                      <img
                        src={fileType === 'csv' ? IconImportCsv : IconImportXlsx}
                        className="icon"
                        alt="Importação de produtos"
                      />

                      {!dataFile && (
                        <p className="modal__import-zone__info">
                          <strong>Arraste um arquivo .{fileType.toUpperCase()} aqui</strong>
                          <span>
                            ou, se preferir,{' '}
                            <label className="modal__import-zone__label-import" htmlFor="importProducts">
                              selecione um arquivo .{fileType.toUpperCase()}
                            </label>
                          </span>
                        </p>
                      )}

                      <input
                        type="file"
                        id="importProducts"
                        accept={`.${fileType}`}
                        className="d-none"
                        onChange={e => dropHandler(e)}
                      />
                    </div>

                    {dataFile && (
                      <div
                        className={`
                  modal__import-zone__status
                  ${successFile ? 'modal__import-zone__status--success' : ''}
                  `}
                      >
                        <img
                          src={successFile ? IconSpreadSheetActive : IconSpreadSheet}
                          className="icon icon--sheet"
                          alt="Ícone planilha"
                        />

                        <div className="modal__import-zone__status__info">
                          <h4 className="modal__import-zone__status__title">
                            {dataFile?.name ? dataFile?.name : 'Arquivo com erro'}
                          </h4>
                          <label htmlFor="importProducts" className="modal__import-zone__status__refresh">
                            <span>Substituir arquivo</span>
                            <img src={IconRefresh} className="icon icon--refresh" alt="Ícone Refresh" />
                          </label>
                          <p
                            className={`modal__import-zone__status__label modal__import-zone__status${
                              successFile ? '__success' : '__error'
                            }`}
                          >
                            {successFile && showEmailInput
                              ? 'Upload completo! Agora insira seu endereço de e-mail,  clique em ‘Enviar arquivo’ e aguarde o envio da planilha.'
                              : dataFile.name}
                          </p>
                        </div>
                        {successFile && showEmailInput && (
                          <>
                            <OutlinedInput placeholder="E-mail" border {...register('email')} />
                            <p className="label-error">
                              {errors.email && errors.email.type === 'required' && <span>Campo obrigatório</span>}
                              {errors.email && errors.email.type === 'email' && <span>E-mail inválido</span>}
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="modal__actions">
                    <Button variant="secondary" onClick={onClose}>
                      <Text fontWeight={700} color={'#EA1D2C'}>
                        Cancelar
                      </Text>
                    </Button>
                    <Button {...sendButtonProps}>
                      <Text fontWeight={700} color={'white'}>
                        Enviar arquivo
                      </Text>
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </>
        ) : (
          hasImport
        )}
      </Modal>

      {loading && <Loading />}
    </>
  );
}

export default ModalImportFile;
