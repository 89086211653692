import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CATEGORY } from '../../../../graphql/category.queries';
import { UPDATE_PRODUCT_VARIATION } from '../../../../graphql/product_variation.mutations';
import { GET_VARIATION_SPECIFICATIONS } from '../../../../graphql/product_variation_specification.queries';
import { GET_PRODUCT_VARIATION } from '../../../../graphql/variation.queries';
import { GET_PRODUCTS_BY_NAME_OR_ID } from '../../../../graphql/product.queries';
import {
  CREATE_VARIATION_SPECIFICATION,
  DELETE_VARIATION_SPECIFICATIONS,
} from '../../../../graphql/product_variation_specification.mutations';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Modal } from 'ifoodshop-react-ui';

import Roles from '@helpers/Roles';
import Decoder from '@helpers/Decoder';
import { ChangeUnitLabel } from '@helpers/ProductVariationHelper';
import { optionsUnits } from '../utils/optionsUnits';
import ProductCustomFields from '../ProductCustomFields';
import Loading from '@components/Loading';

import './formRegister.scss';
import 'react-dropdown/style.css';
import 'react-toggle/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';

const EditVariation = props => {
  const history = useHistory();

  let tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  let roleVarWriter = tokenDecoded.resource_access['manager'].roles.includes(Roles.VARIATION_WR);
  let roleVarReader = tokenDecoded.resource_access['manager'].roles.includes(Roles.VARIATION_RO);

  function ReadOnly(prdWriter) {
    if (!prdWriter) {
      return 'disabled';
    }
    return '';
  }

  const optionsState = ['Habilitado', 'Desabilitado'];

  const [fieldKeys, setFieldKeys] = useState(null);
  const [fields, setFields] = useState(null);
  const [fieldsValues, setFieldsValues] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [modal, setModal] = useState(null);
  const [variationName, setVariationName] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [depth, setDepth] = useState('');
  const [multiple, setMultiple] = useState('');
  const [barcode, setBarCode] = useState('');
  const [state, setState] = useState(optionsState[0]);
  const [unit, setUnit] = useState(optionsUnits[0]);
  const [referenceCode, setReferenceCode] = useState('');
  const [unitLabel, setUnitLabel] = useState('');
  const [requiredFields, setRequiredFields] = useState([]);

  const [getCategoryDetail] = useLazyQuery(GET_CATEGORY, {
    onCompleted: data => {
      let tempfields = [];
      if (data?.getCategoryDetailById.fields) {
        let size = Object.keys(data?.getCategoryDetailById.fields).length - 1;
        Object.values(data?.getCategoryDetailById.fields).map(function (content, index) {
          if (content.length > 0) {
            tempfields.push(content);
          }
          if (size === index) {
            setFields(tempfields);
            setFieldKeys(Object.keys(tempfields));
          }
        });
        Object.values(data.getCategoryDetailById.fields).forEach(content => {
          content.forEach(item => {
            item.required && setRequiredFields(prevState => [...prevState, item]);
          });
        });
      }
    },
  });

  const { data: datavariation, loading } = useQuery(GET_PRODUCT_VARIATION, {
    fetchPolicy: 'no-cache',
    variables: { id: parseInt(props.variationid) },
    onCompleted: data => {
      let upperunit =
        datavariation.getProductVariationById.unity.charAt(0).toUpperCase() +
        datavariation.getProductVariationById.unity.slice(1).toLowerCase();
      setUnit(upperunit);
      setVariationName(datavariation.getProductVariationById.name);
      setBarCode(datavariation.getProductVariationById.barCode);
      setHeight(datavariation.getProductVariationById.height);
      setWeight(datavariation.getProductVariationById.weight);
      setDepth(datavariation.getProductVariationById.length);
      setMultiple(datavariation.getProductVariationById.quantity);
      if (datavariation.getProductVariationById.active) {
        setState(optionsState[0]);
      } else {
        setState(optionsState[1]);
      }
      setWidth(datavariation.getProductVariationById.width);
      setReferenceCode(datavariation.getProductVariationById.referenceCode);
      ChangeUnitLabel(parseInt(datavariation.getProductVariationById.weight), upperunit);
    },
  });

  const [createSpecificationVariation] = useMutation(CREATE_VARIATION_SPECIFICATION, {
    onCompleted: data => {
      history.push(`/catalogos/produtos/editar/${props.productid}`);
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao criar a especificação!');
    },
  });

  const [deleteSpecificationVariation] = useMutation(DELETE_VARIATION_SPECIFICATIONS, {
    onCompleted: data => {
      registerSpecification();
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao excluir as especificações.');
    },
  });

  const { data: dataGetProduct } = useQuery(GET_PRODUCTS_BY_NAME_OR_ID, {
    variables: {
      name: null,
      id: parseInt(props.productid),
    },
    onCompleted: data => {
      setCategoryId(dataGetProduct.getProductByNameOrId[0].category.id);
    },
  });

  const { data: dataspecvariation } = useQuery(GET_VARIATION_SPECIFICATIONS, {
    fetchPolicy: 'no-cache',
    variables: { variation: parseInt(props.variationid) },
  });

  const [updateProductVariation] = useMutation(UPDATE_PRODUCT_VARIATION, {
    onCompleted: data => {
      if (data?.updateProductVariation?.error === 'VARIATION_NAME_NOT_AVAILABLE') {
        changeModal('Atenção', 'Já existe uma variação com o mesmo nome.');
      } else {
        if (fieldsValues) {
          deleteSpecs();
        } else {
          history.push(`/catalogos/produtos/editar/${props.productid}`);
        }
      }
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao editar a variação.');
    },
  });

  useEffect(() => {
    if (categoryId) {
      getCategoryDetail({
        variables: {
          id: categoryId,
          fieldsType: 'SKU',
        },
      });
    }
  }, [categoryId]);

  useEffect(() => {
    props.edit && editProductVariation(props.edit);
  }, [props.edit]);

  function deleteSpecs() {
    deleteSpecificationVariation({
      variables: {
        variation: parseInt(props.variationid),
      },
    });
  }

  function registerSpecification() {
    Object.keys(fieldsValues).forEach(field => {
      let fieldValue = fieldsValues[field].value;

      if (fieldValue !== '')
        Object.values(fields).forEach(content => {
          content.forEach(subField => {
            if (subField.id === parseInt(field)) {
              createSpecificationVariation({
                variables: {
                  variation: parseInt(props.variationid),
                  field: parseInt(field),
                  value: fieldValue,
                },
              });
            }
          });
        });
    });
  }

  function checkRequiredFields() {
    let empty = 1;
    if (requiredFields.length > 0) {
      if (fieldsValues && Object.values(fieldsValues).length < requiredFields.length) {
        empty = -1;
      } else {
        Object.values(fieldsValues).forEach(item => {
          if (
            (requiredFields.findIndex(f => item.id === f.id) !== -1 && item.value === '') ||
            item.value === undefined
          ) {
            empty = -1;
          }
        });
      }

      if (empty === -1) {
        changeModal('Atenção', "As especificações com '*' são obrigatórias.");
        return empty;
      }
    }

    return empty;
  }

  const editProductVariation = ({ e }) => {
    if (checkRequiredFields() === 1) {
      if (ReadOnly(roleVarWriter) != 'disabled') {
        e.preventDefault();

        let floatweight = parseFloat(weight);
        let floatheight = parseFloat(height);
        let floatwidth = parseFloat(width);
        let floatdepth = parseFloat(depth);
        let floatQuantity = parseFloat(multiple);
        let validacao = 1;

        let senditems = {
          id: parseInt(props.variationid),
          name: variationName,
          product: parseInt(props.productid),
          weight: floatweight,
          active: state === 'Habilitado',
          height: floatheight,
          width: floatwidth,
          length: floatdepth,
          quantity: floatQuantity,
          barCode: barcode.toString(),
          unity: unit.toUpperCase(),
        };

        var size = Object.keys(senditems).length - 1;
        Object.keys(senditems).map(function (objectKey, index) {
          var value = senditems[objectKey];
          if (value === '' || value === null || Number.isNaN(value)) {
            changeModal('Atenção', 'Todos os campos da variação são obrigatórios');
            validacao = 0;
          }
          if (index === size && validacao === 1) {
            updateProductVariation({ variables: senditems });
          }
        });
      }
    }
  };

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  useEffect(() => {
    if (unit) {
      setUnitLabel(ChangeUnitLabel(parseInt(weight), unit));
    }
  }, [unit, weight]);

  return (
    <>
      {roleVarReader || roleVarWriter ? (
        <>
          <div>
            {modal}
            <form className="form-register form-register__prdvariation" id="frmeditprod_variation">
              <OutlinedInput
                placeholder="Nome"
                border
                value={variationName}
                onChange={e => setVariationName(e.target.value)}
                maxLength="50"
                disabled={ReadOnly(roleVarWriter)}
              />

              <OutlinedInput
                placeholder="Peso"
                type="number"
                border
                value={weight}
                onChange={e => {
                  setWeight(e.target.value);
                }}
                disabled={ReadOnly(roleVarWriter)}
              />
              <span>Dimensões</span>
              <div className="dimensions">
                <OutlinedInput
                  placeholder="Altura "
                  type="number"
                  border
                  value={height}
                  onChange={e => setHeight(e.target.value)}
                  disabled={ReadOnly(roleVarWriter)}
                />

                <OutlinedInput
                  placeholder="Largura "
                  type="number"
                  border
                  value={width}
                  onChange={e => setWidth(e.target.value)}
                  disabled={ReadOnly(roleVarWriter)}
                />

                <OutlinedInput
                  placeholder="Profundidade "
                  type="number"
                  border
                  value={depth}
                  onChange={e => setDepth(e.target.value)}
                  disabled={ReadOnly(roleVarWriter)}
                />
              </div>

              <OutlinedInput
                placeholder="Qtde Múltiplos"
                type="number"
                border
                value={multiple}
                onChange={e => setMultiple(e.target.value)}
                disabled={ReadOnly(roleVarWriter)}
              />

              <div className="dropdown-label-wrap">
                <Dropdown
                  options={optionsUnits}
                  onChange={e => setUnit(e.value)}
                  placeholder="Selecione"
                  value={unit}
                  className="dropdown-ifood"
                  controlClassName={ReadOnly(roleVarWriter) + ' dropdown-ifood__control'}
                  placeholderClassName="dropdown-ifood__placeholder"
                  menuClassName="dropdown-ifood__menu"
                  arrowClassName="dropdown-ifood__arrow"
                  disabled={ReadOnly(roleVarWriter)}
                />
                <label className="dropdown-label">Unidade de Medida</label>
              </div>

              <OutlinedInput
                placeholder="Código de Barras"
                border
                value={barcode}
                onChange={e => setBarCode(e.target.value.replace(/\D/g, ''))}
                maxLength="13"
                disabled={ReadOnly(roleVarWriter)}
              />

              <div className="dropdown-label-wrap">
                <Dropdown
                  options={optionsState}
                  placeholder=""
                  value={state}
                  className="dropdown-ifood"
                  controlClassName={ReadOnly(roleVarWriter) + ' dropdown-ifood__control'}
                  placeholderClassName="dropdown-ifood__placeholder"
                  menuClassName="dropdown-ifood__menu"
                  arrowClassName="dropdown-ifood__arrow"
                  onChange={e => setState(e.value)}
                  disabled={ReadOnly(roleVarWriter)}
                />
                <label className="dropdown-label">Estado</label>
              </div>

              <OutlinedInput placeholder="Código de referência" border value={referenceCode} disabled />

              <div>
                {fieldKeys ? (
                  <ProductCustomFields
                    readonly={ReadOnly(roleVarWriter)}
                    fields={fields}
                    fieldKeys={fieldKeys}
                    fielddata={dataspecvariation}
                    data={e => setFieldsValues(e)}
                  />
                ) : null}
              </div>
            </form>
            {loading && <Loading />}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditVariation;
