import "./card.scss";

const Card = ({ className, children, props, onClick }) => {
  const classes = ["card"];
  if (className) classes.push(className);

  return (
    <div className={classes.join(" ")} {...props} onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
