/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */

import {
  AddressFormValueType,
  BankFormValueType,
  ContractualFormValueType,
  RegistrationFormValueType,
} from './SellerRegistration/types';
import { ReactNode } from 'react';

export enum SellerFormActions {
  setCurrentStep,
  setRegistrationFormData,
  setAddressFormData,
  setBankFormData,
  setContractualFormData,
  resetAllFormData,
}

export enum pageSteps {
  RegistrationStep,
  AddressStep,
  BankStep,
  ContractualStep,
}

type State = {
  currentStep: pageSteps;
  registrationFormData: RegistrationFormValueType;
  addressFormData: AddressFormValueType;
  bankFormData: BankFormValueType;
  contractualFormData: ContractualFormValueType;
};

type Action = {
  type: SellerFormActions;
  payload: any;
};

type ContextType = {
  state: State;
  dispatch: (action: Action) => void;
};
type SellerFormProviderProps = {
  children: ReactNode;
};

export type { State, Action, ContextType, SellerFormProviderProps };
