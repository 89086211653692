import { ContractualFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import { UseFormReturn } from 'react-hook-form';
import { Input } from '@ifood/pomodoro-components';

type ComercialContactDataFieldsT = {
  form: UseFormReturn<ContractualFormValueType, any>;
};
export default function ComercialContactDataFields({ form }: ComercialContactDataFieldsT) {
  const {
    formState: { errors },
    register,
  } = form;

  return (
    <>
      <Input
        {...register('comercialName')}
        error={errors?.comercialName?.message}
        label="Nome do responsável*"
        marginBottom="large"
        name="comercialName"
      />

      <Input
        {...register('comercialEmail')}
        error={errors?.comercialEmail?.message}
        label="E-mail*"
        name="comercialEmail"
        type="email"
      />
    </>
  );
}
