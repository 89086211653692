/* istanbul ignore file */
import { ObjectStatus, ObjectValidity, ShoppingList, STATUS_ENUM, VALIDITY_ENUM } from './index.d';

export const headerMoreOptions = [
  { name: 'Lista -  modelo importação', value: 'download', type: 'query' },
  { name: 'Lista - Importação', value: 'import', type: 'command' },
];

export const validityOptions = [
  { value: ObjectValidity.ALL, name: VALIDITY_ENUM.ALL },
  { value: ObjectValidity.EXPIRED, name: VALIDITY_ENUM.EXPIRED },
  { value: ObjectValidity.VALID, name: VALIDITY_ENUM.VALID },
];

export const statusOptions = [
  { value: ObjectStatus.ALL, name: STATUS_ENUM.ALL },
  { value: ObjectStatus.ENABLED, name: STATUS_ENUM.ENABLED },
  { value: ObjectStatus.DISABLED, name: STATUS_ENUM.DISABLED },
];

export const columns = [
  {
    id: 0,
    name: 'id',
    label: 'Id',
    formatter: ({ id }: ShoppingList) => id.toString(),
  },
  {
    id: 1,
    name: 'name',
    label: 'Nome da lista',
    formatter: ({ name }: ShoppingList) => name,
  },
  {
    id: 2,
    name: 'rules',
    label: 'Data inicial',
    formatter: ({ rules }: ShoppingList) => (rules?.startDate ? new Date(rules.startDate).toLocaleDateString() : ''),
  },
  {
    id: 3,
    name: 'rules',
    label: 'Data Final',
    formatter: ({ rules }: ShoppingList) => (rules?.endDate ? new Date(rules.endDate).toLocaleDateString() : ''),
  },
  {
    id: 4,
    name: 'status',
    label: 'Estado',
    formatter: ({ status }: ShoppingList) => STATUS_ENUM[status],
  },
];
