import React from 'react';
import Decoder from '../../../../helpers/Decoder';
import Roles from '../../../../helpers/Roles';
import Header from '../../../../components/Header';

import ListPriceMinimum from '../../../../components/PriceMinimum/ListPriceMinimum';
import { getRoles } from "../../../../helpers/rolesUtils";

const PriceMinimumSearch = () => {
  const roles = getRoles();
  const roleMinPrice = roles.includes(Roles.PRICE_MINIMUM_WR);
  const roleMinPriceReadOnly = roles.includes(Roles.PRICE_MINIMUM_RO);

  const roleSeller = roles.includes(Roles.SELLER_WR);

  if (!roleMinPriceReadOnly && !roleMinPrice) {
    window.location.href = '/';
  }

  return (
    <>
      {(roleMinPriceReadOnly || roleMinPrice) && (
        <div className="content-body">
          <Header
            role={roleMinPrice}
            isSeller={roleSeller}
            title="Pedido mínimo"
            description="Use os campos abaixo para filtrar as busca do pedido mínimo."
            backRoute="/fornecedores"
            createRoute="/fornecedores/pedido-minimo/cadastro"
            titleLink="Criar novo pedido mínimo"
          />
          <ListPriceMinimum role={roleMinPrice} isSeller={roleSeller} />
        </div>
      )}
    </>
  );
};

export default PriceMinimumSearch;
