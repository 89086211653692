const CatalogGroup = [
  "PRODUCT-WR",
  "PRODUCT-RO",
  "CATEGORY-WR",
  "CATEGORY-RO",
  "BRAND-WR",
  "BRAND-RO",
  "SUGGESTION-APR",
  "SUGGESTION-WR",
  "SUGGESTION-RO",
  "BAD-WORDS-RO",
  "BAD-WORDS-WR"
]

const CampaingGroup = [
  "REGION-WR",
  "REGION-RO",
  "COMMERCIAL-CONDITION-WR",
  "COMMERCIAL-CONDITION-RO",
  "DISCOUNT-WR",
  "DISCOUNT-RO",
  "VOUCHER-RO",
  "VOUCHER-WR",
  "FIXEDPRICE-RO",
  "FIXEDPRICE-WR",
  "COLLECTION-RO",
  "COLLECTION-WR",
]

const ClientRoles = [
  "CUSTOMER-WR",
  "CUSTOMER-RO"
]

const SellerRoles = [
  "DASHBOARD-RO",
  "DASHBOARD-WR",
  "DATA-SELLER-RO",
  "DATA-SELLER-WR",
  "COMMISSION-RO",
  "COMMISSION-WR",
  "PRICE-MINIMUM-WR",
  "PRICE-MINIMUM-RO",
  "DISTRIBUTION-ADDRESS-WR",
  "DISTRIBUTION-ADDRESS-RO"
]

const OrderRoles = [
  "ORDER-RO",
  "ORDER-WR"
]

export { CatalogGroup, CampaingGroup, ClientRoles, SellerRoles, OrderRoles }
