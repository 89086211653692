import styled from 'styled-components';
import iconArrow from '@assets/icons/icon-goback.svg';
import { Divider, Input, Button } from '@ifood/pomodoro-components';

const Wrapper = styled.section`
  width: 98vw;
`;

const Content = styled.div`
  width: 100%;
  padding: 0 0.625rem 2.5rem 13.438rem;
`;

const HeaderEdit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Form = styled.form`
  width: 100%;
`;

const GoBackArrow = styled.button`
  position: absolute;
  top: 40px;
  left: 210px;
  background: url(${iconArrow});
  width: 10px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
`;

const InputNumber = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonCancel = styled(Button)`
  margin-right: 24px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

const ButtonSave = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

const DividerSection = styled(Divider)`
  border-bottom-color: ${({ theme }) => theme.colors.grayLight};
  border-width: 0 0 2px 0;
`;

export { Wrapper, Content, GoBackArrow, DividerSection, InputNumber, HeaderEdit, ButtonSave, ButtonCancel, Form };
