import { NavLink, useHistory } from 'react-router-dom';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import HeaderRegisterProduct from '../components/HeaderRegisterProduct';
import FormRegisterProductVariation from '../components/FormRegisterProductVariation';

import '../ProductVariationEdit/registerproduct.scss';

const ProductRegisterVariation = props => {
  const history = useHistory();
  const roles = getRoles();
  const roleVarWriter = roles.includes(Roles.VARIATION_WR);

  if (!roleVarWriter) {
    history.push(`/catalogos/produtos/editar/${props.match.params.productid}`);
  }

  return (
    <>
      <NavLink className="link" to={'/catalogos/produtos/editar/' + props.match.params.productid} className="go-back" />
      <div className="content-body prod-variation">
        <HeaderRegisterProduct
          title="Criar uma variação deste produto"
          description="Use os campos abaixo para criar uma variação deste produto."
        />
        <FormRegisterProductVariation productid={props.match.params.productid} />
      </div>
    </>
  );
};

export default ProductRegisterVariation;
