import { useState, useEffect } from 'react';
import { OutlinedInput, Button } from 'ifoodshop-react-ui';
import OutlinedTextarea from '@components/OutlinedTextarea';
import Dropdown from 'react-dropdown';
import client from '@services/apollo';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { notify } from '@helpers/notify';

import './formEditGroup.scss';
import 'react-dropdown/style.css';
import '@components/DropDownIfood/dropdown-ifood.scss';

const FormEditGroup = props => {
  const options = ['Habilitado', 'Desabilitado'];
  const defaultOption = options[0];

  const [groupName, setGroupName] = useState();
  const [groupDesc, setGroupDesc] = useState();
  const [state, setState] = useState(defaultOption);
  const history = useHistory();

  var idGroup = props.paramId;

  useEffect(() => {
    const GET_CUSTOMERSGROUP = gql`{
      getCustomerGroup(groupId: ${idGroup}) {
        name
        description
        active
      }
    }
    `;
    client
      .query({ query: GET_CUSTOMERSGROUP })
      .then(res => {
        setGroupName(res.data.getCustomerGroup.name);
        setGroupDesc(res.data.getCustomerGroup.description);

        if (res.data.getCustomerGroup.active) {
          setState('Habilitado');
        } else {
          setState('Desabilitado');
        }
      })
      .catch(err => {
        return err;
      });
  }, []);

  const editGroup = e => {
    e.preventDefault();

    let stateActive = true;

    if (state === 'Habilitado') {
      stateActive = true;
    } else if (state === 'Desabilitado') {
      stateActive = false;
    }

    const CREATE_CUSTOMER_GROUP = gql`mutation updateCustomerGroup($groupNameValue: String, $groupDescValue: String, $stateActiveValue: Boolean ){
      updateCustomerGroup(
        groupId: ${idGroup}
        group: {
          name: $groupNameValue, description: $groupDescValue, active: $stateActiveValue
        })
      {
        id
        name
      }
    }
    `;
    client
      .mutate({
        mutation: CREATE_CUSTOMER_GROUP,
        variables: { groupNameValue: groupName, groupDescValue: groupDesc, stateActiveValue: stateActive },
      })
      .then(() => {
        notify('Alterações salvas');
        history.push(`/clientes/grupos-clientes/detalhe/${idGroup}`);
      })
      .catch(err => {
        return err;
      });
  };

  return (
    <>
      <form className="form-edit" onSubmit={editGroup}>
        <div className="form-edit__input-group">
          <OutlinedInput
            placeholder="Nome do grupo de clientes"
            border
            value={groupName}
            onChange={e => setGroupName(e.target.value)}
          />
        </div>
        <div className="form-edit__input-group">
          <OutlinedTextarea
            placeholder="Descrição do Grupo"
            value={groupDesc}
            onChange={e => setGroupDesc(e.target.value)}
          />
        </div>
        <div className="form-edit__input-group">
          <div className="dropdown-label-wrap">
            <Dropdown
              options={options}
              value={state}
              onChange={e => setState(e.value)}
              placeholder="Estado"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Estado</label>
          </div>
        </div>
        <div className="form-edit__input-group">
          <Button type="submit">Salvar edição</Button>
        </div>
      </form>
    </>
  );
};

export default FormEditGroup;
