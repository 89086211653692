/* istanbul ignore file */
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 48px 32px 32px 64px;
  margin-left: 180px;
`;

export { Wrapper };
