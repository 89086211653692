/* istanbul ignore file */
import { Dispatch, SetStateAction } from 'react';
import { Button, DatePicker, Flex } from '@ifood/pomodoro-components';
import { invertMonthDay } from '@helpers/FormatHelper';

const ZERO = 0;
const DATE_STRING_LENGTH = 10;

interface IProps {
  setFilters: Dispatch<SetStateAction<{ startDate?: string | undefined; endDate?: string | undefined } | undefined>>;
  handleSearch: () => void;
}

export default function StatementDetailDialogFilter({ setFilters, handleSearch }: IProps) {
  function handleDate(value: string) {
    if (value.length === DATE_STRING_LENGTH) return new Date(invertMonthDay(value)).toISOString().split('T')[ZERO];
  }

  return (
    <Flex alignItems={'end'}>
      <DatePicker
        allowClear
        label={'Data início'}
        marginRight={8}
        placeholder="Data início"
        onChange={({ target: { value } }) => setFilters(prevState => ({ ...prevState, startDate: handleDate(value) }))}
      />
      <DatePicker
        label={'Data fim'}
        placeholder="Data fim"
        onChange={({ target: { value } }) => setFilters(prevState => ({ ...prevState, endDate: handleDate(value) }))}
      />

      <Button height={48} marginLeft={24} type="button" onClick={() => handleSearch()}>
        Buscar
      </Button>
    </Flex>
  );
}
