import { useEffect, useState } from 'react';
import { Pagination, Table } from '@ifood/pomodoro-components';
import FormatHelper from '@helpers/FormatHelper';
import { ITableDiscounts } from '@pages/Campaign/Discount/DiscountsSearch/interfaces/Discounts.interfaces';
import { formatDiscountType } from '@pages/Campaign/Discount/DiscountsSearch/Search.utils';
import ActionLinks from './components/ActionLinks';
import * as S from './Table.styles';

const initialPage = 0;

export default function TableComponent({ content, handlePagination, pagination }: ITableDiscounts): JSX.Element {
  const [page, setpage] = useState<number | null>(null);

  useEffect(() => {
    if (page === null) setpage(initialPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <S.DiscountsTable>
        <Table.Head>
          <S.Row isHeader>
            <S.Header hasBorder>ID</S.Header>
            <S.Header hasBorder>Tipo</S.Header>
            <S.Header hasBorder>Nome</S.Header>
            <S.Header hasBorder>Selo</S.Header>
            <S.Header hasBorder>Estado</S.Header>
            <S.Header hasBorder>Data inicial</S.Header>
            <S.Header hasBorder>Data final</S.Header>
            <S.Header>Cupom</S.Header>
            <S.Header></S.Header>
            <S.Header></S.Header>
          </S.Row>
        </Table.Head>

        <Table.Body>
          {content.map(discount => (
            <S.Row key={`table-discounts-${discount.id}`}>
              <S.Data style={{ display: 'none' }} />
              <S.Data>
                <ActionLinks action="DETAILS" id={discount.id} />
              </S.Data>
              <S.Data>{formatDiscountType[discount.type]}</S.Data>
              <S.Data>{discount.name}</S.Data>
              <S.Data>{discount.highlight || ''}</S.Data>
              <S.Data>{discount.active ? 'Habilitado' : 'Desabilitado'}</S.Data>
              <S.Data>{FormatHelper.format(discount.startDate, 'date')}</S.Data>
              <S.Data>{FormatHelper.format(discount.endDate, 'date')}</S.Data>
              <S.Data>{discount.couponCode}</S.Data>
              <S.Data>
                <ActionLinks action="EDIT" id={discount.id} name="EDIT" />
              </S.Data>
              <S.Data>
                <ActionLinks action="DUPLICATE" id={discount.id} name="DUPLICATE" />
              </S.Data>
            </S.Row>
          ))}
        </Table.Body>
      </S.DiscountsTable>
      {pagination.totalItems > pagination.pageSize && (
        <Pagination.Numbered
          showArrowLabel
          showCounter
          current={pagination.currentPage}
          marginTop={76}
          nextLabel="Proximo"
          ofLabel="de"
          pageSize={pagination.pageSize}
          position="right"
          previousLabel="Anterior"
          showingLabel="Exibindo"
          totalItems={pagination.totalItems}
          onChange={({ currentPage }) => handlePagination(currentPage)}
        />
      )}
    </div>
  );
}
