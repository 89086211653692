import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_OFFER_ID } from '../../../graphql/offer.queries';
import { GET_SPECIAL_PRICE } from '../../../graphql/special_price.queries';
import { GET_PRODUCT_VARIATION } from '../../../graphql/variation.queries';
import { GET_PRODUCT_IMAGES } from '../../../graphql/product.queries';
import { Link } from 'ifoodshop-react-ui';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import { OfferProvider } from '@components/_context/OfferContext';
import FormEditOffer from '../components/FormEditOffer';
import TableSpecialPrice from '../components/TableSpecialPrice';
import FormRegisterProgressivePrice from '../components/FormRegisterProgressivePrice';
import FormRegisterSpecialPrice from '../components/FormRegisterSpecialPrice';
import Card from '@components/Card';
import Drawer from '@components/Drawer';

import { ReactComponent as ChevronIcon } from '../../../assets/icons/Chevron.svg';
import './formRegister.scss';
import 'react-dropdown/style.css';
import '../../../components/DropDownIfood/dropdown-ifood.scss';

const EditOffer = props => {
  const history = useHistory();
  const [modal, setModal] = useState(null);
  const [offer, setOffer] = useState();
  const [drawer, setDrawer] = useState();
  const [priceList, setPriceList] = useState();
  const [specialPriceList, setSpecialPriceList] = useState();
  const [price, setPrice] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [variation, setVariation] = useState();
  const [variationImage, setVariationImage] = useState();
  const [progressivePrices, setProgressivePrices] = useState([]);

  const roles = getRoles();
  const roleOfferWriter = roles.includes(Roles.OFFER_WR);
  const roleOfferReader = roles.includes(Roles.OFFER_RO);

  if (!roleOfferWriter && !roleOfferReader) {
    history.push('/');
  }

  function ReadOnly(prdWriter) {
    if (!prdWriter) {
      return 'disabled';
    }
    return '';
  }

  const offerId = props.match.params.offerid;
  const productId = props.match.params.productid;
  const variationId = props.match.params.variationid;
  let tokenDecodedUserName = null;

  if (props.keycloak) tokenDecodedUserName = props.keycloak.tokenParsed.preferred_username;

  const { data } = useQuery(GET_OFFER_ID, {
    variables: { id: parseInt(offerId) },
    onCompleted: () => {
      setOffer(data?.findOfferById);
    },
  });

  const { data: dataGetSpecialPrice, refetch: refetchSpecialPrices } = useQuery(GET_SPECIAL_PRICE, {
    fetchPolicy: 'no-cache',
    variables: { id: parseInt(offerId) },
    onCompleted: () => {
      setSpecialPriceList(dataGetSpecialPrice?.getOfferPricesByOfferId);
    },
  });

  const { data: dataGetProductVariationById } = useQuery(GET_PRODUCT_VARIATION, {
    variables: { id: variationId },
    onCompleted: () => {
      setVariation(dataGetProductVariationById?.getProductVariationById);
    },
  });
  const { data: findAllVariationImage } = useQuery(GET_PRODUCT_IMAGES, {
    variables: { product: parseInt(productId) },
    onCompleted: () => {
      setVariationImage(findAllVariationImage?.findAllProductImage);
    },
  });

  function setInitialDrawer(e) {
    e.preventDefault();
    setPrice(null);
    setProgressivePrices([]);
    setDrawer(initialDrawer);
    setDrawerOpen(true);
  }

  function registerSpecialPrice(e) {
    e.preventDefault();
    setPrice(null);
    setDrawer(fixedPriceDrawer);
    setDrawerOpen(true);
  }

  function editSpecialPrice(e) {
    setDrawer(fixedPriceDrawer);
    setDrawerOpen(true);
  }

  function registerProgressivePrice(e) {
    e.preventDefault();
    setProgressivePrices([]);
    setDrawer(progressivePriceDrawer);
    setDrawerOpen(true);
  }

  function editProgressivePrice(e) {
    setDrawer(progressivePriceDrawer);
    setDrawerOpen(true);
    setProgressivePrices([]);
  }

  const initialDrawer = (
    <>
      <div className="header-drawer">
        <ChevronIcon className="icon" onClick={() => setDrawerOpen(false)} />
        <h3>{`Criar preço especial`}</h3>
      </div>
      <div className="subtitle-container">
        <h4> Qual tipo de preço você gostaria de cadastrar?</h4>
      </div>
      <div className="container">
        <Card className={`card-container price-type`} onClick={e => registerSpecialPrice(e)}>
          <div className="text-container price-title">
            <h2>Preço Fixo</h2>

            <text>
              Preço diferenciado para um período específico ou uma condição comercial específica. Ex: Preço para MEI
            </text>
          </div>
        </Card>
        <Card className={`card-container price-type`} onClick={e => registerProgressivePrice(e)}>
          <div className="text-container price-title">
            <h2>Desconto Progressivo</h2>

            <text>Preço diferenciado para quantidades específicas.</text>
          </div>
        </Card>
      </div>
    </>
  );

  useEffect(() => {
    !drawer && setDrawer(initialDrawer);
  }, [drawer, initialDrawer]);

  const progressivePriceDrawer = (
    <>
      <div className="header-drawer">
        <ChevronIcon className="icon" onClick={() => (setDrawer(initialDrawer), setProgressivePrices([]))} />
        <h3>{`Desconto progressivo`}</h3>
      </div>
      <div className="subtitle-container">
        <text className="subtitle">
          {' '}
          Insira o valor do preço com desconto e a quantidade mínima para o qual este preço se aplica
        </text>
      </div>
      <FormRegisterProgressivePrice />
    </>
  );
  const fixedPriceDrawer = (
    <>
      <div className="header-drawer">
        <ChevronIcon className="icon" onClick={() => setDrawer(initialDrawer)} />
        <h3>{`Preço fixo`}</h3>
      </div>
      <div className="subtitle-container">
        <text className="subtitle">
          Insira o valor do preço com desconto e a quantidade mínima para o qual este preço se aplica
        </text>
      </div>
      <FormRegisterSpecialPrice edit />
    </>
  );

  const offerProps = {
    offer,
    setPriceList,
    priceList,
    setSpecialPriceList,
    specialPriceList,
    productId,
    variationId,
    ReadOnly,
    editProgressivePrice,
    editSpecialPrice,
    tokenDecodedUserName,
    price,
    setPrice,
    modal,
    registerProgressivePrice,
    registerSpecialPrice,
    priceModal: setModal,
    refetchSpecialPrices,
    variation,
    variationImage,
    drawerOpen,
    setDrawerOpen,
    progressivePrices,
    setProgressivePrices,
  };

  return (
    <OfferProvider {...offerProps}>
      <div className="content-body edit-offer">
        {roleOfferWriter && (
          <Link className="link__special-price" href="#" onClick={e => setInitialDrawer(e)}>
            Criar preço especial
          </Link>
        )}

        <FormEditOffer />
        <div className="header-customer-group header-customer-group--special-price">
          <div className="header-customer-group__info">
            <h2 className="section-title">Preços especiais</h2>
          </div>
          <div className="header-customer-group__new">
            <Drawer isOpen={drawerOpen} position={'right'} onClose={() => setDrawerOpen(false)}>
              {drawer}
            </Drawer>

            {modal}
          </div>
        </div>
        <TableSpecialPrice role={roleOfferWriter} />
      </div>
    </OfferProvider>
  );
};

export default EditOffer;
