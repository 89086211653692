export enum ObjectValidity {
  ALL = 'ALL',
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
}

export enum ObjectStatus {
  ALL = 'ALL',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum STATUS_ENUM {
  ALL = 'Todos',
  ENABLED = 'Habilitado',
  DISABLED = 'Desabilitado',
}

export enum VALIDITY_ENUM {
  ALL = 'Todos',
  VALID = 'Vigente',
  EXPIRED = 'Expirado',
}

export type FormOptions = {
  name: string;
  value?: string;
};

export type FormState = {
  name: string;
  status: ObjectStatus | null;
  validity: ObjectValidity | null;
  startDate: Date | null;
  endDate: Date | null;
};

export type ChangePagination = {
  currentPage: number;
  pageSize: number;
};

export type ShoppingListRules = {
  startDate: Date;
  endDate: Date;
};

export type ShoppingList = {
  id: number;
  name: string;
  status: ObjectStatus;
  rules: ShoppingListRules;
};

export type Column = {
  id: number;
  name: string;
  label: string;
  formatter: ({ id }: ShoppingList) => string;
};

export interface ISearchQueryState extends Partial<FormState> {
  page: number;
  size: number;
}

export interface IPaginationState {
  number: number;
  size: number;
  totalElements: number;
  pageSizes: number[];
}
