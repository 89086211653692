import gql from "graphql-tag";

const GET_RUPTURE_REASON = gql`
  query getRuptureReason {
    getRuptureReason { 
      id
      title
      type
    }
  }
`;


export default GET_RUPTURE_REASON