import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELLER } from '../../graphql/seller.queries';
import { Link as IfoodLink } from 'ifoodshop-react-ui';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionMenu } from '@analytics/aboyeur/entities/sessionMenu';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import { ReactComponent as Logo } from '../../assets/images/Logotipo.svg';
import { useLocation } from 'react-router-dom';
import './navaside.scss';

const NavAside = props => {
  const { pathname } = useLocation();
  let tokenDecoded = null;
  let roles = [];

  if (props.keycloak) tokenDecoded = props.keycloak.tokenParsed;

  if (tokenDecoded) roles = tokenDecoded.resource_access['manager'].roles;

  const [nameSeller, setNameSeller] = useState('');

  const customerRole = roles.includes(Roles.CUSTOMER_RO) || roles.includes(Roles.CUSTOMER_WR);
  const cadCustomerRole = roles.includes(Roles.CAD_CUSTOMER_RO) || roles.includes(Roles.CAD_CUSTOMER_WR);
  const discountRole = roles.includes(Roles.DISCOUNT_RO) || roles.includes(Roles.DISCOUNT_WR);
  const regionRole = roles.includes(Roles.REGION_RO) || roles.includes(Roles.REGION_WR);
  const productRole = roles.includes(Roles.PRODUCT_RO) || roles.includes(Roles.PRODUCT_WR);
  const brandRole = roles.includes(Roles.BRAND_RO) || roles.includes(Roles.BRAND_WR);
  const categoryRole = roles.includes(Roles.CATEGORY_RO) || roles.includes(Roles.CATEGORY_WR);
  const voucherRole = roles.includes(Roles.VOUCHER_RO) || roles.includes(Roles.VOUCHER_WR);
  const orderRole = roles.includes(Roles.ORDER_RO) || roles.includes(Roles.ORDER_WR);
  const fixedPriceRole = roles.includes(Roles.FIXEDPRICE_RO) || roles.includes(Roles.FIXEDPRICE_WR);
  const sellerRole = roles.includes(Roles.SELLER_RO) || roles.includes(Roles.SELLER_WR);
  const collectionRole = roles.includes(Roles.COLLECTION_RO) || roles.includes(Roles.COLLECTION_WR);
  const shopAdminRole = roles.includes(Roles.SELLER_ADMIN_SHOP);
  const carriersRole = roles.includes(Roles.CARRIER_WR) || roles.includes(Roles.CARRIER_RO);

  const commercialConditionRole =
    roles.includes(Roles.COMMERCIAL_CONDITION_RO) || roles.includes(Roles.COMMERCIAL_CONDITION_WR);

  const priceMinRole = roles.includes(Roles.PRICE_MINIMUM_RO) || roles.includes(Roles.PRICE_MINIMUM_WR);

  const distributionAddressRole =
    roles.includes(Roles.DISTRIBUTION_ADDRESS_WR) || roles.includes(Roles.DISTRIBUTION_ADDRESS_RO);

  const dashboardRole = !roles.includes(Roles.DASHBOARD_WR);

  const sellerDataRole = roles.includes(Roles.DATA_SELLER_RO) || roles.includes(Roles.DATA_SELLER_WR);

  const badWordsRole = roles.includes(Roles.BAD_WORDS_RO) || roles.includes(Roles.BAD_WORDS_WR);

  const suggestionRole =
    roles.includes(Roles.SUGGESTION_APR) || roles.includes(Roles.SUGGESTION_RO) || roles.includes(Roles.SUGGESTION_WR);

  const commissionRole = roles.includes(Roles.COMMISSION_RO) || roles.includes(Roles.COMMISSION_WR);

  const shoppingListsRole = roles.includes(Roles.SHOPPING_LIST_RO) || roles.includes(Roles.SHOPPING_LIST_WR);

  const [scrollY, setScrollY] = useState(0);
  const [position, setPosition] = useState(0);

  const sellerId = getRoles();

  const sessionMenu = useAboyeur(SessionMenu);

  const [getSellerId, { data }] = useLazyQuery(GET_SELLER, {
    fetchPolicy: 'no-cache',
    variables: {
      id: parseInt(sellerId),
    },
    onCompleted: () => {
      setNameSeller(data.getSellerById.name);
    },
  });

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    setPosition(
      document.getElementById('nav-aside')?.clientHeight
        ? document.getElementById('nav-aside').clientHeight - window.innerHeight
        : 0
    );

    function watchScroll() {
      window.addEventListener('scroll', logit);
    }

    watchScroll();
    return () => {
      window.removeEventListener('scroll', logit);
    };
  }, []);

  useEffect(() => {
    if (sellerRole) {
      getSellerId({
        variables: {
          id: parseInt(sellerId),
        },
      });
    }
  }, []);

  const handlePositionSidebar = () => {
    if (scrollY > position + 24 && !pathname.includes('listas-sugeridas')) {
      return {
        position: 'fixed',
        top: `-${position}px`,
      };
    }
    return {
      position: 'absolute',
      top: '0px',
    };
  };

  const clickFasterMenuOrders = () => {
    sessionMenu.events.menu.clickOrdersMenu();
  };

  const clickFasterMenuCatalog = () => {
    sessionMenu.events.menu.clickCatalogMenu();
  };

  return (
    <div style={handlePositionSidebar()} className="nav-aside" id="nav-aside">
      <div className="nav-aside__brand">
        <NavLink to="/" className="nav-aside__nav-link" activeClassName="active" exact>
          <Logo />
        </NavLink>
      </div>
      <ul className="nav-aside__nav">
        <li className="nav-aside__nav-menu">
          <NavLink to="/" className="nav-aside__nav-link" activeClassName="active" exact>
            Home
          </NavLink>
          <ul className="nav-aside__submenu">
            {shopAdminRole && (
              <li>
                <NavLink to="/cadastrar-image" className="nav-aside__submenu-link">
                  Upload de imagens
                </NavLink>
              </li>
            )}
            {dashboardRole && (
              <li>
                <NavLink to="/fornecedores/dashboard" className="nav-aside__submenu-link">
                  Dashboard
                </NavLink>
              </li>
            )}
            {orderRole && (
              <li>
                <NavLink to="/pedidos" className="nav-aside__submenu-link" onClick={clickFasterMenuOrders}>
                  Pedidos
                </NavLink>
              </li>
            )}
          </ul>
        </li>
        {customerRole && (
          <li className="nav-aside__nav-menu">
            <NavLink to="/clientes" className="nav-aside__nav-link" exact>
              Clientes
            </NavLink>
            <ul className="nav-aside__submenu">
              <li>
                {customerRole && (
                  <NavLink to="/clientes/grupos-clientes/" className="nav-aside__submenu-link">
                    Grupo de clientes
                  </NavLink>
                )}
              </li>
              <li>
                {cadCustomerRole && (
                  <NavLink to="/clientes/listagem-clientes" className="nav-aside__submenu-link">
                    Clientes
                  </NavLink>
                )}
              </li>
            </ul>
          </li>
        )}
        {(discountRole || regionRole || voucherRole || commercialConditionRole || fixedPriceRole || collectionRole) && (
          <li className="nav-aside__nav-menu">
            <NavLink to="/campanhas" className="nav-aside__nav-link" exact>
              Campanhas
            </NavLink>
            <ul className="nav-aside__submenu">
              <li>
                {commercialConditionRole && (
                  <NavLink to="/campanhas/condicao-comercial" className="nav-aside__submenu-link">
                    Condição comercial
                  </NavLink>
                )}
                {discountRole && (
                  <NavLink to="/campanhas/descontos" className="nav-aside__submenu-link">
                    Descontos
                  </NavLink>
                )}
                {voucherRole && (
                  <NavLink to="/campanhas/vouchers" className="nav-aside__submenu-link">
                    Vouchers
                  </NavLink>
                )}
                {regionRole && (
                  <NavLink to="/campanhas/regioes" className="nav-aside__submenu-link">
                    Regiões
                  </NavLink>
                )}
                {collectionRole && (
                  <NavLink to="/campanhas/colecoes" className="nav-aside__submenu-link">
                    Coleções
                  </NavLink>
                )}
              </li>
            </ul>
          </li>
        )}
        {(productRole || brandRole || categoryRole || suggestionRole || badWordsRole) && (
          <li className="nav-aside__nav-menu">
            <NavLink to="/catalogos" onClick={clickFasterMenuCatalog} className="nav-aside__nav-link" exact>
              Catálogos
            </NavLink>
            <ul className="nav-aside__submenu">
              <li>
                {productRole && (
                  <NavLink to="/catalogos/produtos" className="nav-aside__submenu-link">
                    Produto
                  </NavLink>
                )}
              </li>
              <li>
                {brandRole && (
                  <NavLink to="/catalogos/marcas" className="nav-aside__submenu-link">
                    Marca
                  </NavLink>
                )}
              </li>
              <li>
                {categoryRole && (
                  <NavLink to="/catalogos/categorias" className="nav-aside__submenu-link">
                    Categoria
                  </NavLink>
                )}
              </li>
              <li>
                {suggestionRole && (
                  <NavLink to="/catalogos/sugestoes" className="nav-aside__submenu-link">
                    Cadastro de Produto
                  </NavLink>
                )}
              </li>
              <li>
                {/* Temporário, retirar quando a feature for liberada */}
                {badWordsRole && (
                  <NavLink to="/catalogos/badwords/" className="nav-aside__submenu-link">
                    Palavras proibidas
                  </NavLink>
                )}
              </li>
              <li>
                {shoppingListsRole && (
                  <NavLink to={'/catalogos/listas-sugeridas'} className="nav-aside__submenu-link">
                    Cadastro de listas sugeridas
                  </NavLink>
                )}
              </li>
            </ul>
          </li>
        )}
        {(sellerDataRole ||
          dashboardRole ||
          commissionRole ||
          priceMinRole ||
          distributionAddressRole ||
          shopAdminRole) && (
          <li className="nav-aside__nav-menu">
            {sellerRole ? (
              <NavLink to="/fornecedores" className="nav-aside__nav-link" exact>
                Fornecedores
              </NavLink>
            ) : (
              <NavLink to="/fornecedores" className="nav-aside__nav-link" exact>
                Parceiros
              </NavLink>
            )}

            <ul className="nav-aside__submenu">
              <li>
                {priceMinRole && (
                  <NavLink to="/fornecedores/pedido-minimo/" className="nav-aside__submenu-link">
                    Pedido mínimo
                  </NavLink>
                )}
              </li>
              <li>
                {sellerDataRole && (
                  <NavLink to="/fornecedores/meus-dados" className="nav-aside__submenu-link">
                    Meus Dados
                  </NavLink>
                )}
              </li>
              <li>
                {distributionAddressRole && (
                  <NavLink to="/fornecedores/enderecos-distribuicao" className="nav-aside__submenu-link">
                    Centro de Distribuição
                  </NavLink>
                )}
              </li>
              <li>
                {commissionRole && (
                  <NavLink to="/fornecedores/detail" className="nav-aside__submenu-link">
                    Fornecedores
                  </NavLink>
                )}
              </li>
              {carriersRole && (
                <li>
                  <NavLink to="/fornecedores/transportadoras" className="nav-aside__submenu-link">
                    Transportadoras
                  </NavLink>
                </li>
              )}
            </ul>
          </li>
        )}
        <li className="nav-aside__nav-menu">
          <a
            className="nav-aside__nav-link"
            rel="noopener noreferrer"
            target="_blank"
            href="https://shopifood.zendesk.com/hc/pt-br"
          >
            Ajuda
          </a>
        </li>
      </ul>
      <div className="nav-aside__actions">
        <div className="nav-aside__actions-user">
          <span className="nav-aside__actions-name">
            {sellerRole && data?.getSellerById?.name ? nameSeller.toLowerCase() : tokenDecoded?.preferred_username}
          </span>
        </div>
        <IfoodLink to="/sair" className="nav-aside__btn" href="/logout">
          Sair
        </IfoodLink>
      </div>
    </div>
  );
};

export default NavAside;
