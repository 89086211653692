import gql from 'graphql-tag';

const GET_VARIATION_SPECIFICATIONS = gql `
    query getAllSpecificationByVariation($variation: Int!) {
        getAllSpecificationByVariation(variationId: $variation) {
            id
            variationId
            value
            field{
                id
                name
                customFieldListValue
                fieldType
            }
        }
    }
`;

export {GET_VARIATION_SPECIFICATIONS};
