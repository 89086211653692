import React from 'react';
import Formatter from '@helpers/FormatHelper';
import './sellerAddress.scss';

const SellerAddress = ({ data }) => {
  
  return (
    <div className="sellerAddress">
      <h2 className="sellerAddress__title">Dados de endereço</h2>
      <div className="sellerAddress__name">
        <span className="sellerAddress__attribute">CEP:</span>
        <span className="sellerAddress__value">
          {Formatter.format(data?.zipcode, "cep")}
        </span>
      </div>
      <div className="sellerAddress__name">
        <span className="sellerAddress__attribute">Endereço:</span>
        <span className="sellerAddress__value">
          {data?.address}
        </span>
      </div>
      <div className="sellerAddress__name">
        <span className="sellerAddress__attribute">Número:</span>
        <span className="sellerAddress__value">
          {data?.number}
        </span>
      </div>
      <div className="sellerAddress__name">
        <span className="sellerAddress__attribute">Complemento:</span>
        <span className="sellerAddress__value">{data?.complement}</span>
      </div>
      <div className="sellerAddress__name">
        <span className="sellerAddress__attribute">Bairro:</span>
        <span className="sellerAddress__value">{data?.neighborhood}</span>
      </div>
      <div className="sellerAddress__name">
        <span className="sellerAddress__attribute">Cidade:</span>
        <span className="sellerAddress__value">{data?.city}</span>
      </div>
      <div className="sellerAddress__name">
        <span className="sellerAddress__attribute">Estado:</span>
        <span className="sellerAddress__value">{data?.state}</span>
      </div>
    </div>
  );
};

export default SellerAddress;
