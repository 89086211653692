import React from 'react';
import Decoder from '../../../../helpers/Decoder';
import Roles from '../../../../helpers/Roles';
import Header from '../../../../components/Header';
import FormRegisterEditCommercialCondition from './components/FormRegisterEditCommercialCondition';
import { getRoles } from "../../../../helpers/rolesUtils";

const CommercialConditionRegister = (props) => {
  const { id } = props?.match?.params;

  const roles = getRoles();
  const roleCommercialCondition = roles.includes(Roles.COMMERCIAL_CONDITION_WR);

  if (!roleCommercialCondition) {
    window.location.href = '/';
  }

  return (
    <>
      {roleCommercialCondition && (
        <div className="content-body">
          <Header
            title={
              id ? 'Editar condição comercial' : 'Criar condição comercial'
            }
            description={`Use os campos abaixo para ${id ? 'editar' : 'criar'}
            condições comerciais.`}
            backRoute="/campanhas/condicao-comercial"
          />
          <FormRegisterEditCommercialCondition id={id} role={roleCommercialCondition} />
        </div>
      )}
    </>
  );
};

export default CommercialConditionRegister;
