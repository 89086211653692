import { NavLink } from 'react-router-dom';
import { Button } from 'ifoodshop-react-ui';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import HeaderRegisterProduct from '../components/HeaderRegisterProduct';
import FormRegisterSuggestion from '../components/FormRegisterSuggestion';

import './suggestion.scss';

const SuggestionRegister = props => {
  const roles = getRoles();

  const roleSuggest = roles.includes(Roles.SUGGESTION_WR);

  if (!roleSuggest) window.location.href = '/';

  return (
    <>
      {roleSuggest && (
        <>
          <NavLink to="/catalogos/sugestoes" className="go-back" />
          {roleSuggest ? (
            <div className="content-body suggestion-register">
              <HeaderRegisterProduct
                title="Cadastro de produtos"
                description="Preencha os campos abaixo para cadastrar um produto"
              />
              <FormRegisterSuggestion />
              <div className="button-suggestion">
                <NavLink to="/catalogos/sugestoes" className="btn-secondary">
                  Cancelar
                </NavLink>
                <Button className="btn-primary" form="frm-reg__suggestion" type="submit">
                  Salvar
                </Button>
              </div>
            </div>
          ) : (
            <div className="content-body suggestion-register">
              <HeaderRegisterProduct
                title="Sugestão de produtos"
                description="Você não tem permissão para criar uma sugestão."
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default SuggestionRegister;
