import { Button, Modal, Typography } from 'ifoodshop-react-ui';

import warning from '../../../../../../assets/icons/warning.svg';

import './pixChargebackError.scss';

const PixChargebackError = ({ onClose, onClick }) => {
  const componentClassName = 'pix-chargeback-error';

  return (
    <Modal onCloseAction={onClose}>
      <section className={componentClassName}>
        <img src={warning} alt="Problema ao tentar remover" />

        <Typography size="20px" weight="medium" line="24px">
          Erro ao solicitar estorno
        </Typography>

        <Typography size="16px" line="24px">
          Ocorreu um erro ao remover este produto.
        </Typography>

        <Button white className={`${componentClassName}__button`} onClick={onClick}>
          Tentar novamente
        </Button>
      </section>
    </Modal>
  );
};

export default PixChargebackError;
