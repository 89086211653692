/* eslint-disable react-hooks/exhaustive-deps */
import ModalForm from '@components/ModalForm';
import { DatePicker } from '@ifood/pomodoro-components';
import * as S from '../CustomerFraudReportModal/CustomerFraudReportModal.styles';
import { useCustomerFraudModalsHook } from '../customerFraudModalsHook';
import { useEffect } from 'react';

interface IModalFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const CustomerRemoveFraudModal = ({ isOpen, onClose }: IModalFormProps): JSX.Element => {
  const { onSubmitFraudRemove, formRemoveFraud } = useCustomerFraudModalsHook();
  const { register, getValues } = formRemoveFraud;

  useEffect(() => {
    if (!isOpen) {
      formRemoveFraud.reset();
    }
  }, [formRemoveFraud, isOpen]);

  const handleClickRemove = () => {
    const values = getValues();
    onClose();
    onSubmitFraudRemove(values);
  };

  return (
    <ModalForm
      isOpen={isOpen}
      modalProps={{ size: '700px' }}
      title="Informe o motivo de remoção de fraude"
      onClose={onClose}
    >
      <DatePicker
        disabled
        label="Data do desbloqueio"
        mb={24}
        value={new Date().toLocaleDateString('pt-br')}
        width={'70%'}
      />
      <S.CustomTextArea {...register('note')} label="Motivo de remoção de fraude" placeholder="Digite seu motivo" />
      <S.ButtonsRow justifyContent="flex-end" my={32}>
        <S.ButtonCancel marginRight={18} mr="40px" name="close" variant="secondary" onClick={onClose}>
          Fechar
        </S.ButtonCancel>
        <S.ButtonSave type="submit" onClick={handleClickRemove}>
          Reativar cliente
        </S.ButtonSave>
      </S.ButtonsRow>
    </ModalForm>
  );
};

export default CustomerRemoveFraudModal;
