import { Toggle } from '@ifood/pomodoro-components';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import iconArrow from '@assets/icons/icon-goback.svg';

const Wrapper = styled.section`
  width: 98vw;
`;
const Content = styled.div`
  width: 100%;
  padding: 0 0.625rem 2.5rem 13.438rem;
`;
const GoBackArrow = styled(NavLink)`
  position: absolute;
  top: 40px;
  left: 210px;
  background: url(${iconArrow});
  width: 10px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
`;

const ActivationToggle = styled(Toggle)`
  flex-direction: row;
`;

export { Wrapper, Content, GoBackArrow, ActivationToggle };
