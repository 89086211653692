import { Dispatch, SetStateAction } from 'react';
import { Close } from '@ifood/pomodoro-icons';
import * as S from './TooltipContent.styles';

interface ITootipProps {
  maxWidth?: string;
  width?: string;
  iconClose?: boolean;
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const TooltipContent = ({ iconClose, title, content, maxWidth, width, setIsVisible }: ITootipProps) => {
  return (
    <>
      <S.Wrapper maxWidth={maxWidth} width={width}>
        {(title || iconClose) && (
          <S.Header>
            {iconClose && (
              <S.CloseBox
                aria-label="Close notification"
                role="button"
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                <Close />
              </S.CloseBox>
            )}
            {title && <S.Heading as="h3">{title}</S.Heading>}
          </S.Header>
        )}
        <S.Body>{content && <S.Text as="p">{content}</S.Text>}</S.Body>
      </S.Wrapper>
    </>
  );
};

export default TooltipContent;
