import styled from 'styled-components';
import { Alert } from '@ifood/pomodoro-components';

export const AlertContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > span {
    display: inline-flex;
    gap: ${({ theme }) => theme.space.smaller};
    align-items: center;

    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Feedback = styled(Alert)`
  width: 700px;
  display: flex;

  > div:nth-child(2) {
    flex-grow: 1;
  }
`;
