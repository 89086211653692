import { Fragment } from 'react';
import { Link } from 'ifoodshop-react-ui';

import FormatHelper from '@helpers/FormatHelper';

import { useOfferContext } from '@components/_context/OfferContext';

const TableSpecialPrice = ({ role }) => {
  const { specialPriceList, setPrice, editSpecialPrice, editProgressivePrice } = useOfferContext();

  function editPrice(e, price, priceType) {
    e.preventDefault();
    setPrice(price);
    priceType.callback();
  }

  function checkCondition(condition) {
    if (condition === 1) {
      return 'MEI';
    }

    return 'Nenhuma';
  }

  const handleDate = date => {
    if (date) {
      return FormatHelper.format(date, 'date');
    }

    return 'Nenhuma data selecionada';
  };

  const handleType = price =>
    price.minQuantity
      ? { type: 'PROGRESSIVE_PRICE', name: 'Desc. progressivo', callback: () => editProgressivePrice() }
      : { type: 'FIXED_PRICE', name: 'Preço fixo', callback: () => editSpecialPrice() };

  if (specialPriceList && specialPriceList.length > 0) {
    return (
      <Fragment>
        <table className="table-admin table-admin--sample table-admin--prog-price">
          <thead>
            <tr>
              <th className="table-admin__title">ID</th>
              <th className="table-admin__title">Tipo</th>
              <th className="table-admin__title">Preço</th>
              <th className="table-admin__title">Qnt. min</th>
              <th className="table-admin__title">Condição Comercial</th>
              <th className="table-admin__title">Data inicial</th>
              <th className="table-admin__title">Data final</th>
              <th className="table-admin__title"></th>
            </tr>
          </thead>

          {
            <tbody>
              {specialPriceList
                ? specialPriceList.map(price => (
                    <tr id={price.id} key={price.id}>
                      <td className="table-admin__data">{price.id}</td>
                      <td className="table-admin__data">{handleType(price).name}</td>
                      <td className="table-admin__data">
                        {price.price.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td className="table-admin__data">{price.minQuantity ? price.minQuantity : 0}</td>
                      <td className="table-admin__data">{checkCondition(price.commercialConditionId)}</td>
                      <td className="table-admin__data">{handleDate(price.startDate)}</td>
                      <td className="table-admin__data">{handleDate(price.endDate)}</td>
                      <td className="table-admin__data">
                        {role ? (
                          <Link href="#" onClick={e => editPrice(e, price, handleType(price))}>
                            Editar
                          </Link>
                        ) : (
                          <a className={'link--disabled'}>Editar</a>
                        )}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          }
        </table>
      </Fragment>
    );
  } else return null;
};

export default TableSpecialPrice;
