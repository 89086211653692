import { ContractualFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import * as yup from 'yup';

type ContractualDataSchemaT = { [key in keyof ContractualFormValueType]: any };

const schema = yup.object().shape({
  commission: yup.string().required('Porcentagem de comissão é obrigatório.'),
  minimumOrderPrice: yup.string().required('Valor mínimo é obrigatório.'),
  handlingTime: yup.string().required('Quantidade de dias de separação é obrigatório.'),
  comercialName: yup.string().required('Nome do responsável é obrigatório.'),
  comercialEmail: yup.string().required('Email comercial é obrigatório.'),
} as ContractualDataSchemaT);

export default schema;
