import { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_VARIATION_IMAGE } from '../../../graphql/variation.mutations';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link, Button } from 'ifoodshop-react-ui';

import AuthorizationHeader from '@helpers/AuthorizationHeader';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import FormEditProductVariation from '../components/FormEditProductVariation';
import FormRegisterOffer from '../components/FormRegisterOffer';
import Header from '../components/HeaderRegisterProduct';
import FormEditOffer from '../components/FormEditOffer';
import ListOffer from '../components/ListOffer';
import DragDrop from '@components/DragDrop';
import LabelSuccess from '@components/LabelSuccess';

import './registerproduct.scss';
import { api } from '@services/api';

const EditProductVariation = props => {
  const history = useHistory();
  const roles = getRoles();
  const roleVarWriter = roles.includes(Roles.VARIATION_WR);
  const roleVarReader = roles.includes(Roles.VARIATION_RO);
  const roleOfferReader = roles.includes(Roles.OFFER_RO);
  const roleOfferWriter = roles.includes(Roles.OFFER_WR);

  if (!roleVarWriter && !roleVarReader) {
    history.push(`/catalogos/produtos/editar/${props.match.params.productid}`);
  }

  function ReadOnly(prdWriter) {
    if (!prdWriter) {
      return 'disabled';
    }
    return '';
  }

  

  const [activetab, setActivetab] = useState(0);
  const [imageList, setImageList] = useState(1);
  const [offerEditId, setOfferEditId] = useState(null);
  const [offerTab, setOfferTab] = useState('LIST');
  const [createVariationImage] = useMutation(CREATE_VARIATION_IMAGE);
  const [editBtn, setEditBtn] = useState();
  const [success, setSuccess] = useState(null);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={activetab !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function RenderImageUpload() {
    return (
      <div>
        <form id="frmUpload" onSubmit={ImagesUpload}>
          <input type="file" accept="image/*" id="upload-input" hidden multiple onChange={ImagesUpload} />
        </form>
        <label className="btn-save" htmlFor="upload-input">
          <Link>Adicionar imagens</Link>
        </label>
      </div>
    );
  }

  function TabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setActivetab(newValue);
  };

  const onEditOffer = offer => {
    if (offer) {
      setOfferEditId(offer);
      setOfferTab('EDIT');
    }
  };

  const ImagesUpload = e => {
    e.preventDefault();
    let uploaddata = new FormData();
    let ins = document.getElementById('upload-input').files.length;
    for (let x = 0; x < ins; x++) {
      uploaddata.append('file', document.getElementById('upload-input').files[x]);
    }
    uploaddata.append('variationId', parseInt(props.match.params.variationid));

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');

    api.post('/variation/image/upload', uploaddata)
      .then(res => res.json())
      .then(
        function (data) {
          let size = data.length;
          Object.keys(data).map(function (imagepath, index) {
            createVariationImage({
              variables: {
                imagePath: data[index],
                active: true,
                order: index + 1,
                variation: parseInt(props.match.params.variationid),
              },
            }).then(res2 => {
              if (size == index + 1) {
                setImageList(Math.random());
              }
            });
          });
        },
        function (err) {
          return err;
        }
      );
  };

  function btnEdit(e, random) {
    e.preventDefault();
    setEditBtn({ e, random });
  }

  useEffect(() => {
    if (props?.location?.state?.success) {
      setTimeout(() => {
        setSuccess(<LabelSuccess success content="Cadastro de oferta realizado com sucesso!" />);
      }, 1000);
      setTimeout(() => {
        setSuccess(null);
      }, 4000);
    }
  }, [props?.location?.state?.success]);

  return (
    <>
      {roleVarWriter || roleVarReader ? (
        <>
          <div className="content-body prod-variation">
            <div className="search-suggest__success">{success}</div>
            <NavLink to={`/catalogos/produtos/editar/${props.match.params.productid}`} className="go-back" />
            <Header title="Editar variação" description="Use os campos abaixo para editar a variação." />
            <FormEditProductVariation
              productid={props.match.params.productid}
              variationid={props.match.params.variationid}
              readonly={ReadOnly(roleVarWriter)}
              edit={editBtn}
            />
            <Tabs
              className="product-variation-tabs"
              value={activetab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              {(roleOfferReader || roleOfferWriter) && (
                <Tab label="Ofertas" {...TabProps(roleOfferReader || roleOfferWriter ? 0 : 1)} />
              )}
              <Tab label="Imagens" {...TabProps(roleOfferReader || roleOfferWriter ? 1 : 0)} />
            </Tabs>
            <TabPanel value={activetab} index={roleOfferReader || roleOfferWriter ? 0 : 1}>
              {offerTab == 'CREATE' ? (
                <FormRegisterOffer
                  changeTab={e => setOfferTab(e || 'CREATE')}
                  variationid={props.match.params.variationid}
                  productid={props.match.params.productid}
                />
              ) : offerTab == 'EDIT' ? (
                <FormEditOffer
                  id={offerEditId}
                  changeTab={e => setOfferTab(e || 'EDIT')}
                  variationid={props.match.params.variationid}
                  productid={props.match.params.productid}
                  readonly={ReadOnly(roleVarWriter)}
                />
              ) : (
                <ListOffer
                  variationid={props.match.params.variationid}
                  productid={props.match.params.productid}
                  changeTab={e => setOfferTab(e || 'LIST')}
                  editOffer={e => onEditOffer(e)}
                />
              )}
            </TabPanel>
            <TabPanel className="var-tabs" value={activetab} index={roleOfferReader || roleOfferWriter ? 1 : 0}>
              <div className="header-customer-group header-customer-group--src">
                <div className="header-customer-group__info">
                  <h2 className="section-title">Lista de Imagens</h2>
                </div>
                {roleVarWriter ? RenderImageUpload() : null}
              </div>
              {imageList ? (
                <DragDrop variationid={props.match.params.variationid} readonly={ReadOnly(roleVarWriter)} />
              ) : (
                <a></a>
              )}
            </TabPanel>
            {ReadOnly(roleVarWriter) != 'disabled' ? (
              <Button
                className="btn-save"
                form="frmeditprod_variation"
                type="button"
                onClick={e => btnEdit(e, Math.random())}
              >
                Salvar
              </Button>
            ) : null}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditProductVariation;
