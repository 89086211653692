import React, { useState, useEffect } from 'react';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dropdown from 'react-dropdown';
import debounce from 'lodash.debounce';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { GET_PRODUCTS } from '../../../../../../graphql/product.queries';
import { GET_ALL_COLLECTIONS } from '../../../../../../graphql/collection.queries';

import './headerCollectionSearch.scss';

const OPTIONSVALID = ['Ativo', 'Expirado', 'Todos'];

function HeaderCollectionSearch({ roleCollection, pageCurrent, dataCollections }) {
	const [collections, setCollections] = useState(null);
	const [modal, setModal] = useState(null);
	const [name, setName] = useState(null);
	const [allProducts, setAllProducts] = useState(null);
	const [productId, setProductId] = useState(null);
	const [active, setActive] = useState(OPTIONSVALID[0]);

	let variables = {
		pageRequest: pageCurrent,
		pageSize: 10,
		name,
		productId,
		active: handleActive(),
	};



	const { data, refetch } = useQuery(GET_ALL_COLLECTIONS, {
		variables,
	});

	if (data && collections === null)
		setCollections(data.getAllCollections);

	const [getProducts, { data: dataProducts }] = useLazyQuery(GET_PRODUCTS);
	if (dataProducts && allProducts === null) {
		setAllProducts(dataProducts.getAllProducts.content);
	}

	function handleActive() {
		switch (active) {
			case 'Ativo':
				return true;
			case 'Expirado':
				return false;
			default:
				return null;
		}
	}

	const setSelectProduct = (e) => {
		if (allProducts && e.target.value) {
			const productSelected = allProducts.find(item => item.name === e.target.value);
			setProductId(productSelected ? productSelected.id : null);
		} else {
			setProductId(null);
		}
	}

	const handleSearchProduct = debounce(e => {
		setAllProducts(null);
		getProducts({ variables: { productName: e } });
	}, 1000);


	useEffect(() => {
		setCollections(null);
		refetch().then((res) => setCollections(res.data.getAllColletions))
	}, [pageCurrent]);

	const handleSubmit = (e) => {
		e.preventDefault();

		refetch()
			.then(res => setCollections(res.data.getAllCollections))
			.catch(error => { return error });
	}

	if (collections) {
		dataCollections(collections);
	}

	return (
		<>
			<div className="header-customer-group header-customer-group--src header-collection">
				<div className="header-customer-group__info">
					<h2 className="section-title">Coleção</h2>
					<p className="header-customer-group__description">Busque uma Coleção</p>
				</div>
				<div className="header-customer-group__new">
					{
						roleCollection
							? <>
								<NavLink to="/campanhas/colecoes/cadastro" className="link">Criar nova Coleção</NavLink>
							</>
							: <Tooltip title="Você não tem permissão para criar nova Coleção">
								<a className="link--disabled">Criar nova Coleção</a>
							</Tooltip>
					}
				</div>
				<form className="header-customer-group__form" onSubmit={handleSubmit}>
					<div className="header-customer-group__form-input">
						<OutlinedInput
							placeholder="Nome"
							border
							value={name}
							onChange={e => setName(e.target.value)}
						/>

						<div className="dropdown-label-wrap">
							<Autocomplete
								id="productSearch"
								freeSolo
								onSelect={(e) => setSelectProduct(e)}
								className="autocomplete-ifood"
								noOptionsText="Digite para procurar"
								options={allProducts ? allProducts.map((option) => option.name) : []}
								renderInput={(params) => <TextField {...params} onChange={(e) => handleSearchProduct(e.target.value)} label="Produto" variant="outlined" />}
							/>
						</div>

						<div className="dropdown-label-wrap">
							<Dropdown
								options={OPTIONSVALID}
								value={active}
								onChange={e => setActive(e.value)}
								placeholder="Ativo"
								className="dropdown-ifood"
								controlClassName="dropdown-ifood__control"
								placeholderClassName='dropdown-ifood__placeholder'
								menuClassName="dropdown-ifood__menu"
								arrowClassName='dropdown-ifood__arrow'
							/>
							<label className="dropdown-label">Estado</label>
						</div>
					</div>

					<div className="header-customer-group__actions">
						<Button type="submit">Filtrar Coleções</Button>
					</div>
				</form>
			</div>
			{modal}
		</>
	)
}

export default HeaderCollectionSearch;
