/* istanbul ignore file */
import styled from 'styled-components';
import iconArrow from '@assets/icons/icon-goback.svg';

const HeaderCustomerList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ContentCustomerList = styled.div`
  padding: 0px 32px 32px 64px;
  margin-left: 180px;
`;

const GoBackArrow = styled.button`
  position: absolute;
  top: 40px;
  left: 210px;
  background: url(${iconArrow});
  width: 10px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
`;

export { GoBackArrow, HeaderCustomerList, ContentCustomerList };
