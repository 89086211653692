const ErrorsList = {
  OM0031:() => 'Não foi possível alterar a quantidade',
  OM0035:() => 'Método de entrega não elegível para alterações',
  OM0036:() => 'Pedido finalizado',
  OM0037:() => 'Boleto pós-pago cancelado',
  OM0042:() => 'Este item não pode ser alterado se a nova quantidade for igual a atual',
  OM0044:() => 'Não foi possível encontrar o pedido original',
  OM0047:() => 'Boleto pós-pago finalizado',
}

export function RuptureError(errorCode) {
  return ErrorsList[errorCode];
}
