import React from 'react';
import { Link } from 'ifoodshop-react-ui';
import Tooltip from '@material-ui/core/Tooltip';
import FormatHelper from '@helpers/FormatHelper';

const TableDistributionAddressSearch = ({ role, distributionAddresses }) => {
	return (
		<>
			<h3 className="section-title title-table-groups">Resultado do Filtro</h3>
			<table className="table-admin table-admin--sample">
				<thead>
					<tr>
						<th className="table-admin__title">ID</th>
						<th className="table-admin__title">Endereço</th>
						<th className="table-admin__title">Número</th>
						<th className="table-admin__title">Cidade</th>
						<th className="table-admin__title">Estado</th>
						<th className="table-admin__title">CEP</th>
						<th className="table-admin__title"></th>
					</tr>
				</thead>
				<tbody>
					{
						distributionAddresses.map(address => {
							return (
								<tr id={address.id} key={address.id}>
									<td className="table-admin__data">{address.id}</td>
									<td className="table-admin__data">{address.address}</td>
									<td className="table-admin__data">{address.number}</td>
									<td className="table-admin__data">{address.city}</td>
									<td className="table-admin__data">{address.state}</td>
									<td className="table-admin__data">{address.postalCode ? FormatHelper.format(address.postalCode.toString(), 'cep') : 'CEP não Cadastrado'}</td>
									<td className="table-admin__data">
										{
											role
												? <Link href={"/fornecedores/enderecos-distribuicao/detalhes/" + address.id}>Abrir</Link>
												: <Tooltip title="Você não tem permissão para abrir um Endereço">
													<a className="link--disabled">Abrir</a>
												</Tooltip>
										}
										</td>
								</tr>
							)
						})
					}
				</tbody>
			</table>
		</>
	);
}

export default TableDistributionAddressSearch;
