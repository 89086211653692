import { useState } from 'react';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Button } from 'ifoodshop-react-ui';

import './formEditProductCategory.scss';
import 'react-dropdown/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';

const FormEditProductCategory = () => {
  const options = ['Categoria 1', 'Categoria 2', 'Categoria 3'];

  const defaultOption = options[0];

  const [categoryName, setCategoryName] = useState('');
  const [state, setState] = useState(defaultOption);

  const registerCategory = e => {
    e.preventDefault();

    let stateActive = true;

    if (state === 'Habilitado') {
      stateActive = true;
    } else if (state === 'Desabilitado') {
      stateActive = false;
    }
  };

  return (
    <form className="form-register form-register--category" onSubmit={registerCategory}>
      <OutlinedInput
        placeholder="Nome da especificação"
        border
        value={categoryName}
        onChange={e => setCategoryName(e.target.value)}
      />

      <OutlinedInput
        placeholder="Descrição"
        border
        value={categoryName}
        onChange={e => setCategoryName(e.target.value)}
      />

      <div className="dropdown-label-wrap">
        <Dropdown
          options={options}
          value={defaultOption}
          onChange={e => setState(e.value)}
          placeholder="Estado"
          className="dropdown-ifood"
          controlClassName="dropdown-ifood__control"
          placeholderClassName="dropdown-ifood__placeholder"
          menuClassName="dropdown-ifood__menu"
          arrowClassName="dropdown-ifood__arrow"
        />
        <label className="dropdown-label">Categoria superior</label>
      </div>

      <Button className="btn-save" type="submit">
        Salvar especificação
      </Button>
    </form>
  );
};

export default FormEditProductCategory;
