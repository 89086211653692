import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const TableBadWords = ({ badWords, role }) => {
  if (badWords) {
    return (
      <Fragment>
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">ID</th>
              <th className="table-admin__title">Palavra</th>
              <th className="table-admin__title">Estado</th>
              <th className="table-admin__title" />
            </tr>
          </thead>
          <tbody>
            {badWords.content.map(badWord => (
              <tr id={badWord.id} key={badWord.id}>
                <td className="table-admin__data">{badWord.id}</td>
                <td className="table-admin__data">{badWord.word}</td>
                <td className="table-admin__data">{badWord.active ? <a> Habilitado</a> : <a>Desabilitado</a>} </td>
                <td className="table-admin__data">
                  <NavLink
                    className={role ? 'link' : 'link--disabled'}
                    to={role ? `/catalogos/badwords/editar/${badWord.id}` : '#'}
                  >
                    Editar
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  } else return null;
};
export default TableBadWords;
