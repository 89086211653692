import { Dispatch, SetStateAction } from 'react';

export type DiscountTypeKeys = 'ABSOLUTE_ON_CART' | 'PERCENTUAL_ON_PRODUCT' | 'PERCENTUAL_ON_FREIGHT';

export type DiscountTypeValue = 'Absoluto carrinho' | 'Percentual produto' | 'Percentual entrega';

export enum EnumDiscountTypes {
  ABSOLUTE = 'ABSOLUTE_ON_CART',
  PRODUCT = 'PERCENTUAL_ON_PRODUCT',
  FREIGHT = 'PERCENTUAL_ON_FREIGHT',
}

export interface IDiscountSummary {
  active: boolean;
  couponCode: string;
  endDate: string;
  highlight: string;
  id: number;
  isCoupon: boolean;
  name: string;
  startDate: string;
  type: DiscountTypeKeys;
}

export interface IPagination {
  currentPage: number;
  pageSize: number;
  pageSizes: number[];
  totalItems: number;
}

export interface ITableDiscounts {
  content: IDiscountSummary[];
  pagination: IPagination;
  handlePagination: (currentPage: number) => void;
}

export interface IFilters {
  active: string;
  couponCode: string;
  highlight: string;
  type: string;
  valid: string;
  valueSrcInput: string;
}

export interface ISearchFilterProps {
  filters: IFilters;
  handleSearch: (page: number) => void;
  setFilters: Dispatch<SetStateAction<IFilters>>;
}

export type TableActionTypes = 'DETAILS' | 'DUPLICATE' | 'EDIT';

export enum EnumTableActionTitles {
  DETAILS = 'Detalhes',
  DUPLICATE = 'Duplicar',
  EDIT = 'Editar',
}
