/* eslint-disable no-magic-numbers */
import { Card, Flex, Heading3, Text } from '@ifood/pomodoro-components';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSellerDetailsData } from '../../SellerDetailsHook';

const AddressDataDetails = (): JSX.Element => {
  const { sellerResponse } = useSellerDetailsData();
  const theme = useContext(ThemeContext);

  return (
    <Card>
      <Flex flexDirection="column">
        <Heading3 mb={24} mt={4} variant="smallest">
          Dados contratuais
        </Heading3>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Comissão
        </Text>
        <Text data-testid="comission" mb={24} variant="large">
          {`${sellerResponse?.getSellerV2?.commission}%`}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Valor mínimo
        </Text>
        <Text data-testid="minimum-value" mb={24} variant="large">
          {sellerResponse?.getSellerV2?.minimumOrderPrice?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Dias de separação
        </Text>
        <Text data-testid="separation-days" mb={24} variant="large">
          {sellerResponse?.getSellerV2?.handlingTime}
        </Text>
      </Flex>
    </Card>
  );
};

export default AddressDataDetails;
