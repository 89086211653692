import { AddressFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import * as yup from 'yup';

type AddressDataSchemaT = { [key in keyof AddressFormValueType]: any };

// SCHEMA
const schema = yup.object().shape({
  zipcode: yup
    .string()
    .required('CEP é obrigatório')
    .matches(/^\d{2}\d{3}-\d{3}$/, 'Preencha todos os caracteres.'),
  address: yup.string().required('Logradouro é obrigatório'),
  number: yup.string().required('Número é obrigatório'),
  complement: yup.string(),
  neighborhood: yup.string().required('Bairro é obrigatório'),
  city: yup.string().required('Cidade é obrigatório'),
  state: yup.string().required('Estado é obrigatório'),
} as AddressDataSchemaT);

export default schema;
