import gql from 'graphql-tag';

const GET_ALL_DISTRIBUTION_ADDRESSES = gql`
  query getAllDistributionAddresses(
    $pageRequest: Int,
    $pageSize: Int,
    $city: String,
    $state: String,
    $postalCode: Int,
    $sellerId: Int
  ){
    getAllDistributionAddresses(
      page: $pageRequest,
      size: $pageSize,
      city: $city,
      state: $state,
      postalCode: $postalCode,
      sellerId: $sellerId
    ){
      totalPages,
      content {
        id
        address
        number
        postalCode
        city
        state
      }
    }
  }
`;

const GET_DISTRIBUTION_ADDRESS = gql `
  query getDistributionAddress (
    $id: ID!
  ){
    getDistributionAddress(
      id: $id
    ){
      id
      sellerId
      address
      number
      complement
      neighbourhood
      postalCode
      reference
      city
      state
      country
      active
    }
  }
`;

const CREATE_DISTRIBUTION_ADDRESS = gql `
  mutation createDistributionAddress (
    $id: Int
    $sellerId: Int
    $active: Boolean
    $address: String
    $number: Int
    $complement: String
    $neighbourhood: String
    $postalCode: Int
    $reference: String
    $city: String
    $state: String
    $country: String
  ){
    createDistributionAddress(
      distributionAddress: {
        id: $id
        sellerId: $sellerId
        active: $active
        address: $address
        number: $number
        complement: $complement
        neighbourhood: $neighbourhood
        postalCode: $postalCode
        reference: $reference
        city: $city
        state: $state
        country: $country
      }
    ){
      id
    }
  }
`;

const UPDATE_DISTRIBUTION_ADDRESS = gql `
  mutation updateDistributionAddress (
    $distributionAddressId: ID!
    $id: Int
    $sellerId: Int
    $active: Boolean
    $address: String
    $number: Int
    $complement: String
    $neighbourhood: String
    $postalCode: Int
    $reference: String
    $city: String
    $state: String
    $country: String
  ){
    updateDistributionAddress(
      distributionAddressId: $distributionAddressId, 
      distributionAddress: {
        id: $id
        sellerId: $sellerId
        active: $active
        address: $address
        number: $number
        complement: $complement
        neighbourhood: $neighbourhood
        postalCode: $postalCode
        reference: $reference
        city: $city
        state: $state
        country: $country
      }
    ){
      id
    }
  }
`;

export { GET_ALL_DISTRIBUTION_ADDRESSES, GET_DISTRIBUTION_ADDRESS, CREATE_DISTRIBUTION_ADDRESS, UPDATE_DISTRIBUTION_ADDRESS }