import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';

import FormatHelper from '../../../../../../helpers/FormatHelper';

const TableVoucherSearch = ({vouchers, roleVoucher}) => {
  return(
    <>
      <h3 className="section-title title-table-groups">Resultados do filtro</h3>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">ID</th>
            <th className="table-admin__title">Nome</th>
            <th className="table-admin__title">Código</th>
            <th className="table-admin__title">Estado</th>
            <th className="table-admin__title">Data Inicial</th>
            <th className="table-admin__title">Data Final</th>
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {
            vouchers.map(voucher => {
              return (
                <tr id={voucher.id} key={voucher.id}>
                  <td className="table-admin__data">{voucher.id}</td>
                  <td className="table-admin__data">{voucher.name}</td>
                  <td className="table-admin__data">{voucher.voucherCode}</td>
                  <td className="table-admin__data">{voucher.active ? "Habilitado" : "Desabilitado"}</td>
                  <td className="table-admin__data">{FormatHelper.format(voucher.startDate, 'date')}</td>
                  <td className="table-admin__data">{FormatHelper.format(voucher.endDate, 'date')}</td>
                  <td className="table-admin__data">
                    {roleVoucher
                      ? <NavLink to={"/campanhas/vouchers/editar/" + voucher.id} className="link">Editar</NavLink>
                      : <Tooltip title="Você não tem permissão para editar voucher">
                          <a className="link--disabled">Editar</a>
                        </Tooltip>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </>
  );
}

export default TableVoucherSearch;