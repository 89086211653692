import { useGetWalletsBalanceByEstablishmentIdLazyQuery } from '@services/bff/GraphqlHooks';
import { BFFEstablishment } from '@services/bff/GraphqlTypes';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { PaginationChangeData } from '@ifood/pomodoro-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ESTABLISHMENT_BY_CUSTOMER_ID } from '@graphql/establishment.queries';
import apolloClient from '@services/apolloClient';
import { CHANGE_ESTABLISHMENT_STATUS } from '@graphql/establishment.mutations';

const EMPTY_LIST_LENGTH = 0;
const DEBOUNCE_TIME = 1000;
const ZERO = 0;
const ONE = 1;
const PAGE_SIZE = 10;

export function useEstablishmentListCardHook() {
  const params = useParams<{ id: string }>();
  const [pagination, setPagination] = useState<PaginationChangeData>({
    currentPage: 1,
    pageSize: PAGE_SIZE,
    nextOffset: 0,
    previousOffset: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedEstablishmentId = useRef<string>('');
  const [establishmentListSearch, setEstablishmentListSearch] = useState([] as Pick<BFFEstablishment, 'id' | 'name'>[]);
  const [getWalletsBalance, { data: walletsBalanceData }] = useGetWalletsBalanceByEstablishmentIdLazyQuery();
  const [getEstablishmentsByCustomerId, { data: establishmentsData, loading: fetchingEstablishment }] = useLazyQuery(
    GET_ESTABLISHMENT_BY_CUSTOMER_ID,
    {
      client: apolloClient,
      onCompleted: data => {
        if (data?.getEstablishmentsByCustomerId?.__typename === 'EstablishmentByCustomerResponse') {
          const establishmentIds = data.getEstablishmentsByCustomerId.content?.map(
            (establishment: any) => establishment?.id
          ) as readonly string[];

          getWalletsBalance({ variables: { establishmentIds } });
        }
      },
    }
  );
  const hasEstablishments =
    establishmentsData?.getEstablishmentsByCustomerId?.__typename === 'EstablishmentByCustomerResponse' &&
    establishmentsData?.getEstablishmentsByCustomerId.content?.length !== EMPTY_LIST_LENGTH;
  const establishments = hasEstablishments ? establishmentsData?.getEstablishmentsByCustomerId?.content : [];
  const walletTransferList = walletsBalanceData?.getWalletsBalanceByEstablishmentId?.map(walletTypes =>
    walletTypes?.find(value => value?.type === 'REPASSE')
  );
  const walletPostPaidList = walletsBalanceData?.getWalletsBalanceByEstablishmentId?.map(walletTypes =>
    walletTypes?.find(value => value?.type === 'POST_PAID_BANK_SLIP')
  );

  const [changeStatus, { loading: changingStatus }] = useMutation(CHANGE_ESTABLISHMENT_STATUS, {
    client: apolloClient,
    refetchQueries: [
      {
        query: GET_ESTABLISHMENT_BY_CUSTOMER_ID,
        variables: {
          params: {
            customerId: params.id,
            pageable: {
              size: PAGE_SIZE,
              page: ZERO,
            },
          },
        },
      },
    ],
  });

  const onSearch = debounce(async value => {
    getEstablishmentsByCustomerId({
      variables: { params: { customerId: params.id, name: value } },
    });
  }, DEBOUNCE_TIME);

  function onOpenEstablishmentModal(establishmentId: string) {
    selectedEstablishmentId.current = establishmentId;
    setIsModalOpen(true);
  }

  function onPaginationChange(paginationData: PaginationChangeData) {
    setPagination(paginationData);
    getEstablishmentsByCustomerId({
      variables: {
        params: {
          customerId: params.id,
          pageable: {
            page: paginationData.currentPage - ONE,
            size: PAGE_SIZE,
          },
        },
      },
    });
  }

  function onChangeStatus(id: number, value: boolean) {
    changeStatus({
      variables: { input: { establishmentId: id, active: value } },
    })
      .then(response => {
        if (!response.data.changeEstablishmentStatus.success) {
          throw response;
        }
      })
      .catch(error => {
        console.log('Error', error);
      });
  }

  useEffect(() => {
    getEstablishmentsByCustomerId({
      variables: {
        params: {
          customerId: params.id,
          pageable: {
            size: PAGE_SIZE,
            page: ZERO,
          },
        },
      },
    });
  }, [getEstablishmentsByCustomerId, params.id]);

  return {
    hasEstablishments,
    establishments: establishmentListSearch.length > EMPTY_LIST_LENGTH ? establishmentListSearch : establishments,
    walletTransferList,
    walletPostPaidList,
    fetchingEstablishment,
    changingStatus,
    establishmentListSearch,
    isModalOpen,
    selectedEstablishmentId,
    establishmentsData: hasEstablishments ? establishmentsData.getEstablishmentsByCustomerId : null,
    pagination,
    setIsModalOpen,
    setEstablishmentListSearch,
    onSearch,
    onChangeStatus,
    onOpenEstablishmentModal,
    onPaginationChange,
  };
}
