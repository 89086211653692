/* istanbul ignore file */
import { Box as BoxPomodoro } from '@ifood/pomodoro-components';
import styled, { css } from 'styled-components';

export const Box = styled(BoxPomodoro)`
  ${({ theme }) => css`
    width: 70%;
    min-width: 270px;
    margin-left: ${theme.space.smaller};
  `}
`;

export const TextNotFound = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.ifood.body};
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: ${`${theme.space.regular} 0`};
  `}
`;
