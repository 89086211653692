import { Aboyeur } from '@ifood/aboyeur';
import { getSellerId } from './rolesUtils';
import Cookies from 'js-cookie';

const getDevice = () => {
  const fasterPlugin = Aboyeur.plugins.find(p => p.name === 'Faster');

  return fasterPlugin.instance?.device.get();
};

export const useAboyeur = (aboyeur) => {
  // const establishmentId = Cookies.get('establishmentId');
  const merchantId = Cookies.get('merchantId') === 'null' ? '' : Cookies.get('merchantId');
  const sellerId = parseInt(getSellerId());
  const device = getDevice();
  const accountType = sellerId ? 'seller' : 'internal_team';
  // const longitude = Cookies.get('longitude');
  // const latitude = Cookies.get('latitude');

  return aboyeur.withMetadata({ ...aboyeur.metadata, device, merchantId, sellerId, accountType });
};
