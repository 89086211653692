import HeaderRegisterGroup from '../components/HeaderRegisterGroup';
import FormRegister from '@components/FormRegister';
import { NavLink } from 'react-router-dom';

import Decoder from '@helpers/Decoder';
import Roles from '@helpers/Roles';

import './register-customer-group.scss';

const RegisterCustomerGroup = () => {
  var tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  var roleCustomer = tokenDecoded.resource_access['manager'].roles.includes(Roles.CUSTOMER_WR);
  var roleCustomerReadOnly = tokenDecoded.resource_access['manager'].roles.includes(Roles.CUSTOMER_RO);

  if (!roleCustomerReadOnly && !roleCustomer) {
    window.location.href = '/admin';
  }

  return (
    <>
      {(roleCustomerReadOnly || roleCustomer) && (
        <>
          {roleCustomer ? (
            <>
              <NavLink to="/clientes/grupos-clientes/" className="go-back" />
              <div className="content-body register-group">
                <HeaderRegisterGroup title="Criar novo grupo de clientes" />
                <FormRegister />
              </div>
            </>
          ) : (
            <>
              <NavLink to="/clientes/grupos-clientes/" className="go-back" />
              <div className="content-body register-group">
                <HeaderRegisterGroup
                  title="Criar um novo grupo de clientes"
                  description="você não tem permissão para criar um novo grupo de clientes"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RegisterCustomerGroup;
