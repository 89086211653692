import gql from 'graphql-tag'

const GET_SPECIFICATIONS = gql `
    query getAllSpecificationByProduct($product: Int! ){
        getAllSpecificationByProduct(productId: $product ){
            id
            value
            field{
                id
                name
                customFieldListValue
                fieldType
            }
        }
    }
`;

export {GET_SPECIFICATIONS};