import React, { createContext, useContext, useState } from 'react';

export const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const [keycloak, setKeycloak] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        keycloak,
        setKeycloak,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  const context = useContext(GlobalContext);
  return context;
}
