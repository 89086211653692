/* istanbul ignore file */
import { useCallback, useMemo, useState } from 'react';
import { Button, Table, LinkText } from '@ifood/pomodoro-components';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';

import Autocomplete from '@components/autocomplete';
import { Customer, Establishment, IOwnersConfig } from '@pages/Catalogs/ShoppingListDetail/index.d';

type Props<T> = {
  onChange: (v: T[]) => void;
  onSearch: LazyQueryExecFunction<any, OperationVariables>;
  isFetching: boolean;
  value: T[];
  config: IOwnersConfig<T>;
};

const FormAddOwners = ({
  onChange,
  onSearch,
  isFetching,
  value = [],
  config,
}: Props<Customer | Establishment>): JSX.Element => {
  const isZero = 0;

  const [selectedOption, setSelectedOption] = useState<Customer | Establishment>();
  const [options, setOptions] = useState<Customer[]>([]);

  const handleInclude = () => {
    if (!value || !selectedOption) return;
    if (value.some(item => item.id === selectedOption?.id)) return;
    onChange([...value, selectedOption]);
  };

  const handleRemove = (itemId: string | number) => {
    onChange(value.filter(item => item.id !== itemId));
  };

  const handleSearch = useCallback(
    (value: string) => {
      onSearch({
        variables: { param: value.toLowerCase().trim(), page: 0, size: 5 },
      })
        .then(({ data, error }) => {
          if (error) {
            console.log(error);
          } else {
            const { [config.ownerType.toLowerCase()]: { content = [] } = {} } = data;
            setOptions(content);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    [config.ownerType, onSearch]
  );

  const isNotEmpty = useMemo(() => value.length > isZero, [value]);

  return (
    <div className="form-list-selection">
      <div className="autocomplete-container">
        <Autocomplete
          classes="owner-autocomplete"
          inputLabel={config.label}
          loading={isFetching}
          optionLabel={option => option.name as string}
          optionValue={(option, value) => option.id === value.id}
          options={options}
          onSearch={handleSearch}
          onSelect={v => setSelectedOption(v as Customer | Establishment)}
        />

        <Button type="button" onClick={handleInclude}>
          Adicionar
        </Button>
      </div>

      {isNotEmpty && (
        <div className="table-container">
          <Table>
            <Table.Head>
              <Table.Row>
                {config.tableColumns.map(colum => (
                  <Table.Header key={colum.id}>{colum.label}</Table.Header>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {value.map(item => (
                <Table.Row key={item.id} id={item.id.toString()}>
                  {config.tableColumns.map(column => (
                    <Table.Data key={column.id}>{column.formatter(item)}</Table.Data>
                  ))}
                  <Table.Data>
                    <LinkText onClick={() => handleRemove(item.id)}>Remover</LinkText>
                  </Table.Data>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default FormAddOwners;
