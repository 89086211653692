import FormProductSuggestion from '../FormSuggestion';

import './resumesuggestion.scss';

const ResumeSuggestion = () => {
  return (
    <>
      <FormProductSuggestion />
    </>
  );
};

export default ResumeSuggestion;
