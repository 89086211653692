import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_BRANDS_SUGGESTS, GET_CATEGORIES_SUGGESTS } from '../../../../graphql/product-suggest.queries';
import { GET_SELLERS } from '../../../../graphql/seller.queries';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from 'react-datepicker';
import ptbr from 'date-fns/locale/pt-BR';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import '../../../../components/HeaderGroups/header-groups.scss';
import './suggest-search.scss';

const FormSearchSuggest = props => {
  const roles = getRoles();
  const roleSeller = roles.includes(Roles.SELLER_WR);
  const roleSellerRO = roles.includes(Roles.SELLER_RO);

  const [brandName, setBrandName] = useState('');
  const [brand, setBrand] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [category, setCategory] = useState(null);
  const [sellerName, setSellerName] = useState('');
  const [seller, setSeller] = useState(null);
  const [sellerCode, setSellerCode] = useState(null);
  const [name, setName] = useState(null);

  const [getSellers, { data: dataSellers }] = useLazyQuery(GET_SELLERS);
  const [getBrands, { data: dataBrands }] = useLazyQuery(GET_BRANDS_SUGGESTS);
  const [getCategories, { data: dataCategories }] = useLazyQuery(GET_CATEGORIES_SUGGESTS);

  const [createdAt, setCreatedAt] = useState(null);

  useEffect(() => {
    getBrands({
      variables: {
        brandName: brandName,
      },
    });
  }, []);

  useEffect(() => {
    getCategories({
      variables: {
        name: categoryName,
      },
    });
  }, []);

  useEffect(() => {
    getSellers({
      variables: {
        name: sellerName ? sellerName : '',
      },
    });
  }, [sellerName]);

  const submitForm = e => {
    e.preventDefault();
    props.data({ seller, sellerCode, brand, brandName, category, categoryName, name, createdAt });
  };

  return (
    <form className="form-brand form-brand--suggest" onSubmit={submitForm}>
      <div className="form-brand__suggest">
        {!roleSellerRO || !roleSeller ? (
          <>
            <div className="form-row">
              <OutlinedInput
                name="name"
                className="form-suggest__input"
                placeholder="Nome"
                border
                maxLength={50}
                onChange={e => setName(e.target.value)}
              />
              <OutlinedInput
                name="sellerCode"
                className="form-suggest__input"
                placeholder="Código do fornecedor"
                border
                maxLength={50}
                onChange={e => setSellerCode(e.target.value)}
              />
              <div className={`outlined-label-wrap outlined-date ${createdAt ? 'active' : ''}`}>
                <DatePicker
                  className="form-brand__input"
                  onChange={e => setCreatedAt(e)}
                  locale={ptbr}
                  selected={createdAt}
                  dateFormat="dd/MM/yyyy"
                />
                <label className="outlined-label">Data</label>
              </div>
            </div>
            <div className="form-row">
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="brand"
                  className="dropdown-ifood"
                  popupIcon={<a className="dropdown-ifood__arrow" />}
                  noOptionsText="Sem resultados"
                  options={dataBrands?.getBrandsSuggestion ? dataBrands?.getBrandsSuggestion.filter(item => item) : []}
                  getOptionLabel={option => option}
                  renderInput={params => <TextField {...params} label="Marca" variant="outlined" />}
                  onChange={(e, newValue) => setBrandName(newValue)}
                />
              </div>
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="category"
                  className="dropdown-ifood"
                  popupIcon={<a className="dropdown-ifood__arrow" />}
                  noOptionsText="Sem resultados"
                  options={
                    dataCategories?.getCategoriesSuggestion
                      ? dataCategories?.getCategoriesSuggestion.filter(item => item)
                      : []
                  }
                  getOptionLabel={option => option}
                  renderInput={params => <TextField {...params} label="Categoria" variant="outlined" />}
                  onChange={(e, newValue) => setCategoryName(newValue)}
                />
              </div>
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="seller"
                  className="dropdown-ifood"
                  popupIcon={<a className="dropdown-ifood__arrow" />}
                  noOptionsText="Sem resultados"
                  options={dataSellers && dataSellers.getSellerByName ? dataSellers.getSellerByName : []}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label="Fornecedor" variant="outlined" />}
                  onChange={(e, s) => setSeller(s ? s.id : null)}
                  onInputChange={e => setSellerName(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="form-row">
              <OutlinedInput
                name="name"
                className="form-suggest__input"
                placeholder="Nome"
                border
                maxLength={50}
                onChange={e => setName(e.target.value)}
              />
              <div className={`outlined-label-wrap outlined-date ${createdAt ? 'active' : ''}`}>
                <DatePicker
                  className="form-brand__input"
                  onChange={e => setCreatedAt(e)}
                  locale={ptbr}
                  selected={createdAt}
                  dateFormat="dd/MM/yyyy"
                />
                <label className="outlined-label">Data</label>
              </div>
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="brand"
                  className="dropdown-ifood"
                  popupIcon={<a className="dropdown-ifood__arrow" />}
                  noOptionsText="Sem resultados"
                  options={dataBrands?.getBrandsSuggestion ? dataBrands?.getBrandsSuggestion.filter(item => item) : []}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label="Marca" variant="outlined" />}
                  onChange={(e, newValue) => setBrandName(newValue)}
                />
              </div>
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="category"
                  className="dropdown-ifood"
                  popupIcon={<a className="dropdown-ifood__arrow" />}
                  noOptionsText="Sem resultados"
                  options={
                    dataCategories?.getCategoriesSuggestion
                      ? dataCategories?.getCategoriesSuggestion.filter(item => item)
                      : []
                  }
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label="Categoria" variant="outlined" />}
                  onChange={(e, newValue) => setCategoryName(newValue)}
                />
              </div>
            </div>
          </>
        )}
        <div className="form-row">
          <div className="col-2">
            <Button type="submit">Filtrar sugestões</Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormSearchSuggest;
