import React, {useState, useEffect} from 'react';
import FormRegisterVoucher from '../components/FormRegisterVoucher';
import { NavLink } from 'react-router-dom';
import Loading from '../../../../components/Loading'

import { useLazyQuery } from '@apollo/react-hooks'
import { GET_SELLERS } from '../../../../graphql/seller.queries';
import { getRoles } from '@helpers/rolesUtils';
import Roles from '@helpers/Roles';

const VoucherRegister = () => {
  const [allSeller, setAllSeller] = useState(null)

  const [getSeller, { loading, data: dataSeller }] = useLazyQuery(GET_SELLERS);
  if(dataSeller && allSeller === null) {
    setAllSeller(dataSeller.getSellerByName);
  }

  const roles = getRoles()
  const roleVoucher = roles.includes(Roles.VOUCHER_WR);
  const roleVoucherReadOnly = roles.includes(Roles.VOUCHER_RO);

  if (!roleVoucherReadOnly && !roleVoucher) {
    window.location.href = '/';
  }

  useEffect(() => {
    getSeller({variables: {name: ''}});
  }, []);

  return(
    <>
      {(roleVoucherReadOnly || roleVoucher) &&
      <>
        <NavLink to="/campanhas/vouchers" className="go-back" />
        {roleVoucher
          ?
            <>
              <div className="content-body voucher">
                <h2 className="section-title">Cadastro de voucher</h2>
                <p className="description-form">Use os campos abaixo para criar um novo voucher</p>

                <FormRegisterVoucher sellersList={allSeller} role={roleVoucher} />
              </div>
            </>
          :
            <>
              <div className="content-body voucher">
                <h2 className="section-title">Cadastro de voucher</h2>
                <p className="description-form">Você não tem permissão para criar um novo voucher</p>
              </div>
            </>
        }
        </>
      }
      { loading &&
        <Loading />
      }
    </>
  )
}

export default VoucherRegister
