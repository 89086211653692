import Decoder from './Decoder';

function decodeToken() {
    return Decoder.decode(window.sessionStorage.getItem('token'));
}

export function getRoles() {
    const tokenDecoded = decodeToken();
    return tokenDecoded.resource_access["manager"].roles;
}

export function getSellerId() {
    const tokenDecoded = decodeToken();
	return tokenDecoded.id_seller;
}

export function getSessionEmail() {
    const tokenDecoded = decodeToken();
	return tokenDecoded?.preferred_username;
}