import gql from 'graphql-tag';

const GET_ALL_MINPRICE = gql`
  query getAllMinPrice(
    $page: Int
    $size: Int
    $sellerId: ID
    $regionId: ID
    $active: Boolean
  ) {
    getAllMinPrice(
      page: $page
      size: $size
      sellerId: $sellerId
      regionId: $regionId
      active: $active
    ) {
      content {
        id
        active
        minimumOrder
        createdAt
        updatedAt
        seller {
          name
        }
        region {
          name
        }
      }
      totalPages
      number
    }
  }
`;

const GET_MINPRICE_BY_ID = gql`
  query getMinPriceById($minPriceId: ID!) {
    getMinPriceById(minPriceId: $minPriceId) {
      id
      active
      minimumOrder
      createdAt
      updatedAt
      seller {
        name
      }
      region {
        name
      }
    }
  }
`;

export { GET_ALL_MINPRICE, GET_MINPRICE_BY_ID };

