/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { KeyboardEvent, FormEvent } from 'react';
import { ContractualFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import { Input } from '@ifood/pomodoro-components';
import { UseFormReturn, Controller } from 'react-hook-form';
import CurrencyFormat from 'react-currency-format';
import * as S from '../ContractualDataForm.styles';

const ZERO = 0;

type ContractualDataFormFieldsT = {
  form: UseFormReturn<ContractualFormValueType, any>;
};
export default function ContractualDataFormField({ form }: ContractualDataFormFieldsT) {
  const {
    formState: { errors },
    setValue,
    register,
    control,
  } = form;

  const POW_FUNCTION_BASE_PARAM = 10;
  const POW_FUNCTION_EXPOENT_PARAM = 2;

  const handleChangeNumberField = (event: FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value, name },
    } = event;

    const result = value.replace(/\D/g, '');

    setValue(name as 'commission' | 'minimumOrderPrice' | 'handlingTime', result);
  };

  const enforceMinMax = (event: KeyboardEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value, min, max } = target as HTMLInputElement | any;

    if (!value) return;

    if (parseFloat(value) < parseFloat(min)) {
      setValue('commission', min);
      return;
    }

    if (parseFloat(value) > parseFloat(max)) {
      setValue('commission', max);
      return;
    }

    const isDecimalNumber = value - Math.floor(value) !== ZERO;
    if (isDecimalNumber) {
      const divisor = Math.pow(POW_FUNCTION_BASE_PARAM, POW_FUNCTION_EXPOENT_PARAM);
      const decimal = Math.floor(value * divisor) / divisor;
      setValue('commission', decimal.toString());
    }
  };

  return (
    <>
      <S.InputNumber
        {...register('commission')}
        error={errors?.commission?.message}
        label="Comissão*"
        marginBottom="large"
        max={100}
        min={0.0}
        name="commission"
        prefixText="% "
        step="0.01"
        type="number"
        onKeyUp={enforceMinMax}
      />

      <Controller
        control={control}
        name="minimumOrderPrice"
        render={({ field }) => (
          <CurrencyFormat
            {...field}
            allowNegative={false}
            customInput={Input}
            decimalScale={2}
            decimalSeparator=","
            displayType={'input'}
            error={errors?.minimumOrderPrice?.message}
            label="Valor mínimo*"
            marginBottom="large"
            min="0"
            prefix={'R$ '}
            step="0.01"
            thousandSeparator="."
          />
        )}
      />

      <Input
        {...register('handlingTime')}
        error={errors?.handlingTime?.message}
        label="Dias de separação*"
        marginBottom="large"
        name="handlingTime"
        onChange={handleChangeNumberField}
      />
    </>
  );
}
