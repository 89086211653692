import React, {useEffect, useState} from "react";
import { Button, Modal, OutlinedInput } from "ifoodshop-react-ui";
import {GET_COMMISSIONS} from "@graphql/commission.queries";
import DatePicker from "react-datepicker/es";
import ptbr from 'date-fns/locale/pt-BR';
import {useLazyQuery} from "@apollo/react-hooks";
import Dropdown from "react-dropdown";
import AutocompleteCategory from "@components/AutocompleteCategory";

const HeaderCommissionSearch = (props) => {
  const PAGE_SIZE = 10;
  const [form, setForm] = useState({
    id: "",
    page: "",
    name: "",
    initialDate: "",
    finalDate: "",
    data: "",
    active: "Todos",
  })

  const [modal, setModal] = useState(null)

  const [getCommissions, {data: dataGetCommissions}] = useLazyQuery(
    GET_COMMISSIONS
  )

  const checkDate = (date, name) => {
      if (name === "initialDate" && date) {
        form.finalDate ?
          form.finalDate >= date ? onHandleChange({name: "initialDate", value: new Date(date)}) : changeModal("Data inicial deve ser menor ou igual que a data final", "Atenção")
          :
          onHandleChange({name: "initialDate", value: new Date(date)})
      }
      else if (name === "initialDate" && !date){
        onHandleChange({name: "initialDate", value: ""})
      }
      else if (name === "finalDate" && date) {
        date >= form.initialDate ? onHandleChange({name: "finalDate", value: new Date(date)}) : changeModal("Data final deve ser maior ou igual que a data inicial", "Atenção")
      }
      else if (name === "finalDate" && !date){
        onHandleChange({name: "finalDate", value: ""})
      }
  }

  const changeModal = (message, title) => {
    setModal(<Modal onCloseAction={() => setModal(null)} title={title}>
      <p>{message}</p>
    </Modal>)
  }

  const onHandleChange = (e) => {
    if (e.target) {
      var {name, value} = e.target;
    } else {
      var {name, value} = e
    }

    setForm({
      ...form,
      [name]: value
    })
  }

  useEffect(() => {
    handleRefetch()
  }, [props.currentPage])

  const handleRefetch = (forcepage = null) => {
    getCommissions({
        variables: {
          size: PAGE_SIZE,
          active: form.active === "Todos" ? null : form.active === "Habilitado",
          page: forcepage !== null ? forcepage : props.currentPage,
          sellerId: parseInt(props.sellerId),
          startDate: form.initialDate ? form.initialDate.toISOString().split("T")[0] : null,
          endDate: form.finalDate ? form.finalDate.toISOString().split("T")[0] : null,
          commissionType: props.type === "Padrão" ? "DEFAULT" : props.type === "Categoria" ? "CATEGORY" : "OFFER",
          linkedId: parseInt(form.id)
        }
      }
    )
  }

  if (dataGetCommissions) {
    props.returnData(dataGetCommissions.findPaginateCommission)
  }

  const searchCommissions = (event) => {
    event.preventDefault();
    handleRefetch(0)
  }

  return (
    <>
      {modal}
      <form className="form-brand">
        <div className="form-row">
          {
            props.type === "Categoria" ?
              <>
               <AutocompleteCategory selected={(id) => onHandleChange({name: "id", value: id})} />
              </>
              :  props.type === "Produto" ?
              <>
                <OutlinedInput
                  placeholder={`Id oferta`}
                  name="id"
                  border
                  value={form.id}
                  onChange={e => onHandleChange(e)}
                />
              </>
              : null
          }
          <div className={`outlined-label-wrap outlined-date ${form.initialDate ? 'active' : ''}`}>
            <DatePicker
              className="form-brand__input"
              name="initialDate"
              onChange={date => checkDate(date, "initialDate")}
              locale={ptbr}
              selected={form.initialDate}
              dateFormat="dd/MM/yyyy"
            />
            <label className="outlined-label">Data inicial</label>
          </div>
          <div className={`outlined-label-wrap outlined-date ${form.finalDate ? 'active' : ''}`}>
            <DatePicker
              className="form-brand__input"
              onChange={date => checkDate(date, "finalDate")}
              locale={ptbr}
              selected={form.finalDate}
              dateFormat="dd/MM/yyyy"
            />
            <label className="outlined-label">Data final</label>
          </div>

          <div className="dropdown-label-wrap">
            <Dropdown
              options={['Todos', 'Habilitado', 'Desabilitado']}
              onChange={e => onHandleChange({name: "active", value: e.value})}
              value={form.active ? form.active : null}
              placeholder="Estado"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Estado</label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-2">
            <Button className="btn btn-secondary" onClick={(e) => searchCommissions(e)}>Filtrar comissões</Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default HeaderCommissionSearch;
