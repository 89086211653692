import { createContext, useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PRODUCT_IMAGES } from '../../graphql/product.queries';
import { GET_VARIATION_IMAGES, GET_VARIATIONS_TERM } from '../../graphql/variation.queries';
import { GET_SPECIFICATIONS } from '../../graphql/specification.queries' 
import { GET_CATEGORY } from "../../graphql/category.queries";
import { CREATE_OFFER } from "../../graphql/offer.mutations";
import { Modal } from "ifoodshop-react-ui";

import { getSellerId } from '@helpers/rolesUtils';

import Loading from '@components/Loading';

export const Context = createContext()

export function ProductSuggestionProvider( {children} ) {
  const id_seller = parseInt(getSellerId());
  const history = useHistory();
  const [step, setStep] = useState(1)
  const [productName, setProductName] = useState('');
  const [productContinue, setProductContinue] = useState(true)
  const [productId, setProductId] = useState(null);
  const [variationId, setVariationID] = useState(null)
  const [allProducts, setAllProducts] = useState(null);
  const [price, setPrice] = useState('')
  const [quantityAvailable, setQuantityAvailable] = useState('')
  const [sku, setSku] = useState('')
  const [saleContinue, setSaleContinue] = useState(true)
  const [width, setWidth] = useState(null);
  const [unity, setUnity] = useState("");
  const [height, setHeight] = useState(null);
  const [length, setLength] = useState(null);
  const [weight, setWeight] = useState(null);
  const [barCode, setBarcode] = useState("");
  const [multiples, setMultiples] = useState(null);
  const [description, setDescription] = useState(null);
  const [modal, setModal] = useState()
  const [brandId, setBrandId] = useState(null)
  const [brandName, setBrandName] = useState('')
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [variationName, setVariationName] = useState(null)
  const [productImage, setProductImage] = useState([]);
  const [variationImage, setVariationImage] = useState([]);
  const [fieldKeys, setFieldKeys] = useState(null);
  const [fields,setFields] = useState(null)
  const [fieldsValues,setFieldsValues] = useState([])

  //ProdutoByVariacao
  const [getProducts, { data: dataProducts, loading: loadingGetProducts }] = useLazyQuery(GET_VARIATIONS_TERM);
	if (dataProducts && allProducts === null) {
		setAllProducts(dataProducts.getFindByTerm);
	}

  //imagens
  const [getVariationImages, { data: dataVariationImages}] = useLazyQuery(GET_VARIATION_IMAGES,{
    fetchPolicy: "no-cache",
     onCompleted: () => {
        setVariationImage(dataVariationImages.findAllVariationImage);
     }
  });
  const [getProductImages, { data: dataProductImages}] = useLazyQuery(GET_PRODUCT_IMAGES,{
     fetchPolicy: "no-cache",
      onCompleted: () => {
          setProductImage(dataProductImages.findAllProductImage);
      }
  });

  const [createOffer] = useMutation(CREATE_OFFER, {
    onCompleted: (data) => {
      if (data?.createOffer?.error) handleErrors(data.createOffer.error);
      else
        history.push({
          pathname: `/catalogos/produtos/${productId}/variacoes/editar/${variationId}`,
          state: {success: true},
        });
    },
    onError: (error) => {
      changeModal("Erro ao cadastrar oferta", "Atenção");
    },
  });

  //especificação
    const [getCategoryDetail, { data: dataCategoryDetail }] = useLazyQuery(
    GET_CATEGORY,
    {
      onCompleted: () => {
        setFields(
          dataCategoryDetail?.getCategoryDetailById?.fields
            ? dataCategoryDetail?.getCategoryDetailById?.fields
            : null
        );
        setFieldKeys(
          Object.keys(
            dataCategoryDetail?.getCategoryDetailById?.fields
              ? dataCategoryDetail?.getCategoryDetailById?.fields
              : []
          )
        );
      },
    }
  );

  const [getSpecs, { data: dataSpecs }] = useLazyQuery(GET_SPECIFICATIONS, {
    fetchPolicy: "no-cache",
  });

   const changeModal = (message, title) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  function handleErrors(error) {
    switch (error) {
      case "OFFER_SELLER_ALREADY_HAVE_OFFER":
        changeModal("Lojista já possui oferta cadastrada.", "Atenção");
        break;
      case "OFFER_CODE_NOT_AVAILABLE":
        changeModal("Código do lojista já utilizado", "Atenção");
        break;
      default:
        changeModal("Erro ao cadastrar oferta.", "Atenção");
    }
  }
 
  const productSuggestionProps = {
    step,
    setStep,
    productName,
    setProductName,
    productContinue,
    setProductContinue,
    productId,
    setProductId,
    allProducts,
    setAllProducts,
    getProducts,
    price, 
    setPrice,
    quantityAvailable, 
    setQuantityAvailable,
    sku, 
    setSku,
    brandId, 
    setBrandId,
    brandName,
    setBrandName,
    categoryId, 
    setCategoryId,
    categoryName, 
    setCategoryName,
    saleContinue, 
    setSaleContinue,
    variationName,
    setVariationName,
    width, 
    setWidth,
    unity, 
    setUnity,
    height, 
    setHeight,
    length, 
    setLength,
    weight, 
    setWeight,
    barCode, 
    setBarcode,
    multiples, 
    setMultiples,
    description, 
    setDescription,
    modal, 
    setModal,
    productImage, 
    setProductImage,
    variationImage, 
    setVariationImage,
    getVariationImages,
    getProductImages,
    createOffer,
    fields,
    setFields,
    fieldKeys,
    setFieldKeys,
    getCategoryDetail,
    getSpecs,
    dataSpecs,
    setFieldsValues,
    id_seller,
    variationId, 
    setVariationID
  }
   return(
     <>
        <Context.Provider value={{...productSuggestionProps}}>
          {children}
        </Context.Provider>

        {(
          loadingGetProducts
         ) &&
          <Loading />
        }
    </>
   )
}

export function useProductSuggestionContext(){
  const context = useContext(Context)
  return context
}