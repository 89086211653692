import { Typography } from 'ifoodshop-react-ui';

import '../OrderCardDetails/OrderCardDetails.scss';

function OrderCardDetails(props) {
  const { img, imgAlt, description, sku, skuIntern, value } = props;
  if (!img && !description && !sku && !skuIntern && !value) return <></>;
  return (
    <div className="order-card-detais">
      <div className="order-card-detais__item">
        {img && (
          <figure className="order-card-detais__figure">
            <img className="order-card-detais__img" src={img} alt={imgAlt} />
          </figure>
        )}
        {(description || sku || skuIntern || value) && (
          <div className="order-card-detais__description">
            {description && (
              <Typography
                className="order-card-detais__title"
                size="16px"
                weight="regular"
                line="26px"
                color="black"
                variant="h3"
              >
                {description}
              </Typography>
            )}
            {(sku || skuIntern) && (
              <div className="order-card-detais__columns">
                {sku && (
                  <Typography
                    className="order-card-detais__columns__item"
                    size="14px"
                    weight="normal"
                    line="16px"
                    color="heavygrey"
                    variant="p"
                  >
                    SKU: {sku}
                  </Typography>
                )}
                {skuIntern && (
                  <Typography
                    className="order-card-detais__columns__item"
                    size="14px"
                    weight="normal"
                    line="16px"
                    color="heavygrey"
                    variant="p"
                  >
                    SKU Interno: {skuIntern}
                  </Typography>
                )}
              </div>
            )}
            {value && (
              <Typography size="14px" weight="normal" line="16px" color="black" variant="p">
                Valor: <strong>R$ {value}</strong>
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderCardDetails;
