import React from 'react';

const TableRegisterRegion = ({ data, deleteCommercialCondition }) => {

  const showPostalCode = (postalCode) => {
    const cep = String(postalCode);
    if (cep.length < 8) {
      return /(\d{5})(\d{3})/.exec(`0${cep}`)?.splice(1)?.join('-');
    }
    return /(\d{5})(\d{3})/.exec(cep)?.splice(1)?.join('-');
  };

  return (
    <>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">CEP Inicial</th>
            <th className="table-admin__title">CEP Final</th>
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="table-admin__data">
                  {showPostalCode(item.initialPostalCode)}
                </td>
                <td className="table-admin__data">
                  {showPostalCode(item.finalPostalCode)}
                </td>
                <td className="table-admin__data">
                  <a
                    onClick={() => deleteCommercialCondition(item)}
                    className="link"
                  >
                    Remover
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TableRegisterRegion;
