import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import Toggle from 'react-toggle';
import { OutlinedInput, Modal, Button } from 'ifoodshop-react-ui';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SELLER } from '../../../../graphql/seller.queries';
import { UPDATE_OFFER } from '../../../../graphql/offer.mutations';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionCatalog } from '@analytics/aboyeur/entities/sessionCatalog';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import { useOfferContext } from '@components/_context/OfferContext';
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';
import Radio from '@components/Radio/Input';
import HeaderRegisterProduct from '../../components/HeaderRegisterProduct';

import 'react-dropdown/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';
import './formRegister.scss';

const FormEditOffer = () => {
  const history = useHistory();
  const { productId, variationId, offer } = useOfferContext();
  const roles = getRoles();
  const roleOfferWriter = roles.includes(Roles.OFFER_WR);
  const roleOfferReader = roles.includes(Roles.OFFER_RO);

  const rolePriceKgWR = roles.includes(Roles.PRICE_KG_SELLER_WR);

  if (!roleOfferWriter && !roleOfferReader) {
    history.goBack();
  }

  function ReadOnly(prdWriter) {
    if (!prdWriter) {
      return 'disabled';
    }
    return '';
  }

  const optionsState = ['Habilitado', 'Desabilitado'];
  const [modal, setModal] = useState(null);
  const [price, setPrice] = useState(null);
  const [pricePerKg, setPricePerKg] = useState(null);
  const [isPricePerKg, setIsPricePerKg] = useState(false);
  const [sellerCode, setSellerCode] = useState('');
  const [sellerId, setSellerId] = useState(null);
  const [state, setState] = useState(optionsState[0]);
  const [getSellerQuery] = useLazyQuery(GET_SELLER);
  const [stock, setStock] = useState(null);
  const [committedStock, setCommittedStock] = useState(null);
  const [variableWeight, setVariableWeight] = useState(null);
  const [limitQuantity, setLimitQuantity] = useState('');
  const [specialPrices, setSpecialPrices] = useState([]);

  // Faster
  const sessionCatalog = useAboyeur(SessionCatalog);

  const [updateSeller] = useMutation(UPDATE_OFFER, {
    onCompleted: data => {
      if (data?.updateOffer?.error) handleErrors(data.updateOffer.error);
      else window.location.href = '/catalogos/produtos/' + productId + '/variacoes/editar/' + variationId;
    },
    onError: error => {
      changeModal('Erro ao atualizar oferta', 'Atenção');
    },
  });

  useEffect(() => {
    if (offer) {
      setSellerId(offer.sellerId);
      setPrice(offer.price);
      setPricePerKg(offer?.pricePerKg);
      setIsPricePerKg(offer.isPricePerKg);
      setSellerCode(offer.sellerCode.toString());
      setStock(offer.stock);
      setCommittedStock(offer.committedStock);
      setVariableWeight(offer.variableWeight);
      setLimitQuantity(offer.limitQuantity);
      setState(offer.active ? optionsState[0] : optionsState[1]);

      let cleanedSpecialPrices = offer.specialPrices;
      if (cleanedSpecialPrices) {
        cleanedSpecialPrices.forEach((specialPrice, index) => {
          delete cleanedSpecialPrices[index].__typename;
        });
      }

      sessionCatalog.events.offer.verifyPricePerKgEditOffer({
        //Propriedades > Metadata
        variationId: parseInt(variationId),
        offerId: parseInt(offer.id),
        price: offer.price,
        pricePerKg: offer?.pricePerKg,
        isPriceKg: offer?.isPricePerKg,
      });

      setSpecialPrices(cleanedSpecialPrices);
    }
  }, [offer]);

  useEffect(() => {
    if (sellerId) {
      getSellerQuery({ variables: { id: sellerId } });
    }
  }, [sellerId]);

  function findSpecialItem(array, legalNatureCode) {
    return array?.find(element => element.legalNatureCode === legalNatureCode);
  }

  const handleSpecialPrice = (legalNatureCode, newPrice) => {
    let list = specialPrices || [];
    let floatNewPrice = newPrice;
    if (typeof newPrice === 'string') {
      floatNewPrice = parseFloat(newPrice.toString().replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
    }

    let item = findSpecialItem(list, legalNatureCode);
    if (item) {
      list.forEach((specialPrice, index) => {
        if (specialPrice.legalNatureCode === legalNatureCode) {
          list[index].price = floatNewPrice;
        }
      });
    } else {
      item = {};
      item.id = null;
      item.price = newPrice;
      item.active = null;
      item.legalNatureCode = legalNatureCode;
      list.push(item);
    }

    setSpecialPrices(list);
  };

  const handleSpecialState = (legalNatureCode, newState) => {
    let list = specialPrices || [];

    let item = findSpecialItem(list, legalNatureCode);

    if (item) {
      list.forEach((specialPrice, index) => {
        if (specialPrice.legalNatureCode === legalNatureCode) {
          list[index].active = newState === 'Habilitado';
        }
      });
    } else {
      item = {};
      item.id = null;
      item.price = 0;
      item.active = newState === 'Habilitado';
      item.legalNatureCode = legalNatureCode;
      list.push(item);
    }

    setSpecialPrices(list);
  };

  const editOffer = e => {
    e.preventDefault();

    let floatPrice = price;
    let floatPriceKg = pricePerKg;

    if (typeof price === 'string') {
      floatPrice = parseFloat(price.toString().replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
      floatPriceKg = 0;
    }

    if (typeof pricePerKg === 'string') {
      floatPriceKg = parseFloat(pricePerKg.toString().replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
      floatPrice = 0;
    }

    updateSeller({
      variables: {
        id: parseInt(offer.id),
        variation: parseInt(variationId),
        seller: parseInt(sellerId),
        sellerCode: sellerCode.toString(),
        stock: parseInt(stock),
        active: state === 'Habilitado',
        price: floatPrice,
        pricePerKg: floatPriceKg,
        isPricePerKg: isPricePerKg,
        variableWeight: variableWeight,
        limitQuantity: parseInt(limitQuantity),
        specialPrices: specialPrices,
      },
    });

    sessionCatalog.events.offer.clickPricePerKgEditOffer({
      //Propriedades > Metadata
      variationId: parseInt(variationId),
      offerId: parseInt(offer.id),
      price: floatPrice,
      pricePerKg: floatPriceKg,
      isPriceKg: isPricePerKg,
    });
  };

  const radioButtonChecked = () => {
    let element = document.getElementById('priceRadioKg');

    if (element.checked) {
      setIsPricePerKg(true);
    } else {
      setIsPricePerKg(false);
    }
  };

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  function handleErrors(error) {
    switch (error) {
      case 'OFFER_SELLER_ALREADY_HAVE_OFFER':
        changeModal('Atenção', 'Lojista já possui oferta cadastrada.');
        break;
      case 'OFFER_CODE_NOT_AVAILABLE':
        changeModal('Atenção', 'Código do lojista já utilizado');
        break;
      case 'OFFER_VALUE_OUTSIDE_THE_ALLOWED_RANGE':
        changeModal(
          'Atenção',
          `A diferença do novo valor, não deve ser maior que 50% do valor atual (${offer.price.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}).`
        );
        break;
      default:
        changeModal('Atenção', 'Erro ao atualizar oferta.');
    }
  }

  return (
    <>
      {modal}
      <form className="form-register form-register--offer" id="formEditOffer" onSubmit={editOffer}>
        <div className="headerFormEdit">
          <NavLink to={'/catalogos/produtos/' + productId + '/variacoes/editar/' + variationId} className="go-back" />
          <HeaderRegisterProduct title="Editar oferta" />

          <Button className="btn-save" form="formEditOffer" type="submit">
            Salvar
          </Button>
        </div>

        <div className="contentFormEdit">
          <p id="skuLabelEdit" className="labelOfferEdit">
            SKU*
          </p>
          <OutlinedInput
            defaultValue={sellerCode}
            border
            className="labelOfferInput"
            onChange={e => {
              setSellerCode(e.target.value);
            }}
            disabled={ReadOnly(roleOfferWriter)}
          />

          <div className="dropdown-label-wrap">
            <p className="labelOfferEdit">Estado</p>
            <Dropdown
              options={optionsState}
              onChange={e => setState(e.value)}
              placeholder=""
              value={state}
              className="dropdown-ifood labelOfferInput"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
              disabled={!roleOfferWriter}
            />
          </div>

          <div className="priceEditOffer">
            {rolePriceKgWR ? (
              <>
                <p className="labelOfferEdit">Precificação</p>

                <div className="radioButtonsFormGroup">
                  <div className="radioButtonsEditOffer">
                    <Radio
                      className="containerReason__radios"
                      name="priceRadio"
                      checked={isPricePerKg === false}
                      value={isPricePerKg}
                      onChange={radioButtonChecked}
                    />
                    <Radio
                      id="priceRadioKg"
                      className="containerReason__radios"
                      name="priceRadio"
                      checked={isPricePerKg === true}
                      value={isPricePerKg}
                      onChange={radioButtonChecked}
                    />
                  </div>

                  <div className="radioReasons">
                    <label className="labelRadioReasons">Por unidade</label>
                    <label className="labelRadioReasons">Por kilo</label>
                  </div>
                </div>

                <p className="labelOfferEdit">Preço</p>
                <OutlinedInputCurrency
                  className="priceInput labelOfferInput"
                  value={price || 0}
                  onChange={e => setPrice(e.target.value)}
                  disabled={(ReadOnly(roleOfferWriter), isPricePerKg)}
                />
                {isPricePerKg && (
                  <span className="spanAlertPriceKg">Valor do preço por Kg multiplicado pelo peso da variação.</span>
                )}

                <p className="labelOfferEdit">Preço por Kg</p>
                <OutlinedInputCurrency
                  className="pricePerKgInput labelOfferInput"
                  value={pricePerKg || 0}
                  onChange={e => setPricePerKg(e.target.value)}
                  disabled={(ReadOnly(roleOfferWriter), !isPricePerKg)}
                />
              </>
            ) : (
              <>
                <p className="labelOfferEdit">Preço</p>
                <OutlinedInputCurrency
                  className="priceInputNormal labelOfferInput"
                  value={price || 0}
                  onChange={e => setPrice(e.target.value)}
                  disabled={ReadOnly(roleOfferWriter)}
                />
              </>
            )}
          </div>

          <div className="toggle-wrap">
            <p className="toggle-wrap__label">Peso variável</p>
            <Toggle
              disabled={ReadOnly(roleOfferWriter)}
              defaultChecked={variableWeight}
              checked={variableWeight}
              value={variableWeight}
              onChange={v => setVariableWeight(v.target.checked)}
            />
          </div>

          <p className="labelOfferEdit">Estoque</p>
          <OutlinedInput
            border
            className="labelOfferInput"
            value={stock}
            onChange={e => setStock(e.target.value.replace(/\D/g, ''))}
            disabled={ReadOnly(roleOfferWriter)}
          />

          <p className="labelOfferEdit">Estoque comprometido</p>
          <OutlinedInput className="labelOfferInput" type="number" border value={committedStock || 0} disabled />

          <p className="labelOfferEdit">Limite máx por pedido</p>
          <OutlinedInput
            className="labelOfferInput"
            border
            value={limitQuantity}
            onChange={e => setLimitQuantity(e.target.value.replace(/\D/g, ''))}
            disabled={ReadOnly(roleOfferWriter)}
          />
        </div>
      </form>
    </>
  );
};

export default FormEditOffer;
