import gql from 'graphql-tag';

const UPDATE_PAYMENT_TYPES = gql`
  mutation updatePaymentTypes($input: UpdatePaymentTypesInput) {
    updatePaymentTypes(input: $input) {
      success
      error
      message
    }
  }
`;

export { UPDATE_PAYMENT_TYPES };
