import { snackbar } from '@ifood/pomodoro-components';

const defaultTimeout = 5000;

export const notify = (message, timeout, variant) => {
  snackbar({
    message: message,
    autoClose: true,
    timeout: timeout || defaultTimeout,
    variant: variant || 'success',
  });
};
