import gql from 'graphql-tag';

const CREATE_BADWORD = gql`
  mutation createBadWord($word: String,$active: Boolean){
    createBadWord(input: {word: $word, active: $active}){
      id
      word
      active
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_BADWORD = gql `
  mutation updateBadWord($id: Int!, $word: String, $active: Boolean){
    updateBadWord(id: $id, input: {word: $word, active: $active}){
      id
      word
      active
      createdAt
      updatedAt
    }
  }
`;

export {CREATE_BADWORD, UPDATE_BADWORD}
