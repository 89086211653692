import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import ReactPaginate from 'react-paginate';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import { GET_ALL_BADWORDS } from '../../../graphql/badwords.queries';

import client from '@services/apollo';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import TableBadWords from '../components/TableBadWords';

import IconArrow from '../../../assets/images/arrow.png';

const SearchBadWords = () => {
  const roles = getRoles();
  const roleBadWord = roles.includes(Roles.BAD_WORDS_WR);
  const roleBadWordRO = roles.includes(Roles.BAD_WORDS_RO);
  const history = useHistory();

  if (!roleBadWord && !roleBadWordRO) history.push('/catalogos');

  const [word, setWord] = useState(null);
  const [words, setWords] = useState(null);
  const [state, setState] = useState('Todos');
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const PAGE_SIZE = 10;

  const onPageChange = e => {
    setPage(e.selected);
    handleRequest(e.selected);
  };

  const handleRequest = (p = page) => {
    client
      .query({
        query: GET_ALL_BADWORDS,
        fetchPolicy: 'no-cache',
        variables: {
          word: word,
          page: p,
          size: parseInt(PAGE_SIZE),
          active: state == 'Todos' ? null : state === 'Habilitado',
        },
      })
      .then(res => {
        setWords(res.data.getAllBadWords);
        setLastPage(Math.round(res.data.getAllBadWords.totalPages));
      })
      .catch(err => {
        return err;
      });
  };
  const getWords = event => {
    event.preventDefault();
    setPage(0);
    handleRequest(0);
  };

  if (words == null) {
    handleRequest();
  }

  return (
    <>
      {(roleBadWord || roleBadWordRO) && (
        <>
          <NavLink to="/catalogos" className="go-back" />
          <div className="content-body search-brand">
            <div className="header-customer-group header-customer-group--src">
              <div className="header-customer-group__info">
                <h2 className="section-title">Palavras proibidas</h2>
                <p className="header-customer-group__description">
                  Use os campos abaixo para fazer a busca das palavras.
                </p>
              </div>
              <div className="header-customer-group__new">
                <NavLink
                  className={roleBadWord ? 'link' : 'link--disabled'}
                  to={roleBadWord ? `/catalogos/badwords/cadastro` : '#'}
                >
                  Cadastrar palavra
                </NavLink>
              </div>
              <div className="header-customer-group__actions">
                <form className="form-brand" onSubmit={getWords}>
                  <div className="form-row">
                    <OutlinedInput placeholder="Palavra" border onChange={e => setWord(e.target.value)} />

                    <div className="dropdown-label-wrap">
                      <Dropdown
                        options={['Todos', 'Habilitado', 'Desabilitado']}
                        onChange={e => setState(e.value)}
                        value={state ? state : null}
                        placeholder="Estado"
                        className="dropdown-ifood"
                        controlClassName="dropdown-ifood__control"
                        arrowClassName="dropdown-ifood__arrow"
                      />
                      <label className="dropdown-label">Estado</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-2">
                      <Button type="submit">Filtrar palavras</Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="table-box">
                {words ? <TableBadWords badWords={words} role={roleBadWord} /> : <a> </a>}

                {words && lastPage > 1 ? (
                  <div className="search-brand-paginate">
                    <ReactPaginate
                      containerClassName="pagination-ifoodshop"
                      pageClassName="pagination-ifoodshop__item"
                      pageLinkClassName="pagination-ifoodshop__item-link"
                      activeLinkClassName="active"
                      previousLabel={<img className="previous-arrow" src={IconArrow} />}
                      nextLabel={<img className="next-arrow" src={IconArrow} />}
                      onPageChange={e => onPageChange(e)}
                      pageRangeDisplayed={3}
                      pageCount={lastPage}
                      marginPagesDisplayed={1}
                      initialPage={page}
                      forcePage={page}
                    />
                  </div>
                ) : (
                  <a> </a>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SearchBadWords;
