import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import IconArrow from '@assets/images/arrow.png';
import HeaderDistributionAddressSearch from './components/HeaderDistributionAddressSearch';
import TableDistributionAddressSearch from './components/TableDistributionAddressSearch';
import Roles from '@helpers/Roles';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_DISTRIBUTION_ADDRESSES } from '@graphql/distribution_address.queries.js';
import { getRoles } from "@helpers/rolesUtils";

const DistributionAddressSearch = () => {
  const roles = getRoles();
  const roleDistributionWR = roles.includes(Roles.DISTRIBUTION_ADDRESS_WR);
  const roleDistributionRO = roles.includes(Roles.DISTRIBUTION_ADDRESS_RO);

  const [variables, setVariables] = useState({
    pageRequest: 0,
    pageSize: 10,
    sellerId: null,
  });

  const { data } = useQuery(GET_ALL_DISTRIBUTION_ADDRESSES, { variables });

  if (!roleDistributionWR && !roleDistributionRO) {
    window.location.href = '/';
  }

  return (
    <>
      {(roleDistributionWR || roleDistributionRO) && (
        <>
          <NavLink to="/fornecedores" className="go-back" />
          <div className="content-body">
            <HeaderDistributionAddressSearch
              role={roleDistributionWR}
              onSubmitFilters={(e) =>
                setVariables({
                  ...variables,
                  postalCode: e.postalCode,
                  city: e.city,
                  state: e.state,
                  pageRequest: 0,
                })
              }
            />

            {data?.getAllDistributionAddresses?.content?.length > 0 && (
              <>
                <TableDistributionAddressSearch
                  role={roleDistributionWR}
                  distributionAddresses={
                    data.getAllDistributionAddresses.content
                  }
                />
                <div className="search-customer-paginate">
                  {data?.getAllDistributionAddresses?.totalPages > 1 && (
                    <ReactPaginate
                      containerClassName="pagination-ifoodshop"
                      pageClassName="pagination-ifoodshop__item"
                      pageLinkClassName="pagination-ifoodshop__item-link"
                      activeLinkClassName="active"
                      previousLabel={
                        <img className="previous-arrow" src={IconArrow} />
                      }
                      nextLabel={<img className="next-arrow" src={IconArrow} />}
                      onPageChange={(e) => {
                        setVariables({
                          ...variables,
                          pageRequest: e.selected,
                        });
                      }}
                      pageRangeDisplayed={3}
                      pageCount={data.getAllDistributionAddresses.totalPages}
                      marginPagesDisplayed={1}
                      initialPage={variables.page}
                      forcePage={data.getAllDistributionAddresses.number}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DistributionAddressSearch;
