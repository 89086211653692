import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CATEGORY } from '../../../../graphql/category.queries';
import { CREATE_VARIATION_SPECIFICATION } from '../../../../graphql/product_variation_specification.mutations';
import { CREATE_PRODUCT_VARIATION } from '../../../../graphql/product_variation.mutations';
import { GET_PRODUCTS_BY_NAME_OR_ID } from '../../../../graphql/product.queries';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';

import { ChangeUnitLabel } from '@helpers/ProductVariationHelper';
import { optionsUnits } from '../utils/optionsUnits';

import ProductCustomFields from '../ProductCustomFields';

import 'react-dropdown/style.css';
import 'react-toggle/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';
import '../FormEditProductVariation/formRegister.scss';

const FormRegisterProductVariation = props => {
  const { productid } = props;
  const [fieldKeys, setFieldKeys] = useState(null);
  const [fields, setFields] = useState(null);
  const [fieldsValues, setFieldsValues] = useState([]);
  const optionsState = ['Habilitado', 'Desabilitado'];

  const [modal, setModal] = useState(null);
  const [variationName, setVariationName] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [depth, setDepth] = useState('');
  const [multiple, setMultiple] = useState('');
  const [barcode, setBarCode] = useState('');
  const [state, setState] = useState(optionsState[0]);
  const [unit, setUnit] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const history = useHistory();
  const [unitLabel, setUnitLabel] = useState('');
  const [requiredFields, setRequiredFields] = useState([]);

  const [getCategoryDetail, { data: dataCategoryDetail }] = useLazyQuery(GET_CATEGORY, {
    onCompleted: () => {
      if (dataCategoryDetail.getCategoryDetailById.fields) {
        setFields(dataCategoryDetail.getCategoryDetailById.fields || []);
        setFieldKeys(Object.keys(dataCategoryDetail.getCategoryDetailById.fields || null));

        Object.values(dataCategoryDetail.getCategoryDetailById.fields).forEach(content => {
          content.forEach(item => {
            item.required && setRequiredFields(prevState => [...prevState, item]);
          });
        });
      }
    },
  });

  const [createProductVariation] = useMutation(CREATE_PRODUCT_VARIATION, {
    onCompleted: data => {
      if (data?.createProductVariation?.error === 'VARIATION_NAME_NOT_AVAILABLE') {
        changeModal('Atenção', 'Já existe uma variação com o mesmo nome.');
      } else {
        if (Object.keys(fieldsValues).length > 0) {
          registerSpecification(data);
        } else {
          history.push('/catalogos/produtos/editar/' + productid);
        }
      }
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao cadastrar a variação.');
    },
  });

  const [createSpecificationVariation] = useMutation(CREATE_VARIATION_SPECIFICATION, {
    onCompleted: data => {
      history.push(`/catalogos/produtos/editar/ ${productid}`);
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao cadastrar a especificação.');
    },
  });

  const { data: dataGetProduct } = useQuery(GET_PRODUCTS_BY_NAME_OR_ID, {
    variables: {
      name: null,
      id: parseInt(productid),
    },
    onCompleted: () => {
      setCategoryId(dataGetProduct.getProductByNameOrId[0].category.id);
    },
  });

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  useEffect(() => {
    if (categoryId) {
      getCategoryDetail({
        variables: {
          id: categoryId,
          fieldsType: 'SKU',
        },
      });
    }
  }, [categoryId]);

  function registerSpecification(data) {
    Object.keys(fieldsValues).forEach(field => {
      let fieldValue = fieldsValues[field].value;

      if (fieldValue !== '')
        Object.values(fields).forEach(content => {
          content.forEach(subField => {
            if (subField.id === parseInt(field))
              createSpecificationVariation({
                variables: {
                  variation: parseInt(data.createProductVariation.id),
                  field: parseInt(field),
                  value: fieldValue,
                },
              });
          });
        });
    });
  }

  function checkRequiredFields() {
    let empty = 1;
    if (Object.values(fieldsValues).length < requiredFields.length) {
      empty = -1;
    } else {
      Object.values(fieldsValues).forEach(item => {
        if (item.value === '' || item.value === undefined) {
          empty = -1;
        }
      });
    }

    if (empty === 1) return empty;
    else {
      changeModal('Atenção', "As especificações com '*' são obrigatórias.");
      return empty;
    }
  }

  const registerProductVariation = e => {
    if (checkRequiredFields() === 1) {
      e.preventDefault();

      let floatweight = parseFloat(weight);
      let floatheight = parseFloat(height);
      let floatwidth = parseFloat(width);
      let floatdepth = parseFloat(depth);
      let floatQuantity = parseFloat(multiple);
      let valid = 1;

      let senditems = {
        name: variationName,
        product: parseInt(productid),
        weight: floatweight,
        active: state === 'Habilitado',
        height: floatheight,
        width: floatwidth,
        length: floatdepth,
        quantity: floatQuantity,
        barCode: barcode.toString(),
        unity: unit ? unit.toUpperCase() : '',
      };

      let size = Object.keys(senditems).length - 1;
      Object.keys(senditems).map(function (objectKey, index) {
        let value = senditems[objectKey];
        if (value === '' || value === null || Number.isNaN(value)) {
          setModal(
            <Modal onCloseAction={() => setModal(null)}>
              <h3>Atenção</h3>
              <p>Todos os campos da variação são obrigatórios</p>
            </Modal>
          );
          valid = 0;
        }
        if (index == size && valid == 1) {
          createProductVariation({ variables: senditems });
        }
      });
    }
  };

  function onlyNumbers(e) {
    setBarCode(e.target.value.replace(/\D/g, ''));
  }

  useEffect(() => {
    if (unit) setUnitLabel(ChangeUnitLabel(parseInt(weight), unit));
  }, [unit, weight]);

  return (
    <div>
      {modal}
      <form className="form-register form-register__prdvariation">
        <OutlinedInput
          placeholder="Nome"
          border
          value={variationName}
          onChange={e => setVariationName(e.target.value)}
          maxLength="50"
        />

        <OutlinedInput
          placeholder="Peso"
          type="number"
          border
          value={weight}
          onChange={e => setWeight(e.target.value)}
        />
        <span>Dimensões</span>
        <div className="dimensions">
          <OutlinedInput
            placeholder="Altura"
            type="number"
            border
            value={height}
            onChange={e => setHeight(e.target.value)}
          />

          <OutlinedInput
            placeholder="Largura"
            type="number"
            border
            value={width}
            onChange={e => setWidth(e.target.value)}
          />

          <OutlinedInput
            placeholder="Profundidade"
            type="number"
            border
            value={depth}
            onChange={e => setDepth(e.target.value)}
          />
        </div>

        <OutlinedInput
          placeholder="Qtde Múltiplos"
          type="number"
          border
          value={multiple}
          onChange={e => setMultiple(e.target.value)}
        />

        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsUnits}
            onChange={e => setUnit(e.value)}
            placeholder="Selecione"
            value={unit}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Unidade de Medida</label>
        </div>

        <OutlinedInput
          placeholder="Código de Barras"
          border
          value={barcode}
          onChange={e => onlyNumbers(e)}
          maxLength="13"
        />

        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsState}
            onChange={e => setState(e.value)}
            placeholder=""
            value={optionsState[0]}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Estado</label>
        </div>

        <div>
          {fieldKeys ? (
            <ProductCustomFields fields={fields} fieldKeys={fieldKeys} data={e => setFieldsValues(e)} />
          ) : null}
        </div>

        <Button className="btn-save" type="button" onClick={e => registerProductVariation(e)}>
          Salvar
        </Button>
      </form>
    </div>
  );
};

export default FormRegisterProductVariation;
