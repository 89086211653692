/* istanbul ignore file */

import { useLazyQuery } from '@apollo/react-hooks';
import { GET_ALL_DISCOUNTS_V2 } from '@graphql/discounts.queries';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  handleDiscountState,
  handleDiscountValidaty,
  hasDiscountsRules,
  initialFilterParams,
  initialPaginationParams,
} from './Search.utils';

import {
  IFilters,
  IPagination,
  ITableDiscounts,
} from '@pages/Campaign/Discount/DiscountsSearch/interfaces/Discounts.interfaces';
import Header from '@pages/Campaign/Discount/DiscountsSearch/components/Header';
import Filters from '@pages/Campaign/Discount/DiscountsSearch/components/Filters';
import Table from '@pages/Campaign/Discount/DiscountsSearch/components/Table';
import { Flex, Loading } from '@ifood/pomodoro-components';

const isOne = 1;

export default function Search() {
  const [discounts, setDiscounts] = useState<ITableDiscounts>();
  const [paginationState, setPaginationState] = useState<IPagination>({
    ...initialPaginationParams,
  });
  const [searchFilters, setSearchFilters] = useState<IFilters>({ ...initialFilterParams });

  const history = useHistory();

  const [getDiscounts, { data, loading }] = useLazyQuery(GET_ALL_DISCOUNTS_V2, {
    onCompleted: () => {
      const { getAllDiscountsV2 } = data || {};

      if (getAllDiscountsV2) {
        setDiscounts(getAllDiscountsV2);

        setPaginationState((prevState: IPagination) => ({
          ...prevState,
          totalItems: parseInt(getAllDiscountsV2.totalElements),
        }));
      }
    },
  });

  const handleSearch = useCallback(
    (page = paginationState.currentPage) => {
      getDiscounts({
        variables: {
          active: handleDiscountState(searchFilters.active),
          couponCode: searchFilters.couponCode || null,
          highlight: searchFilters.highlight || null,
          pageRequest: page - isOne,
          pageSize: paginationState.pageSize,
          type: searchFilters.type || null,
          valid: handleDiscountValidaty(searchFilters.valid),
          valueSrcInput: searchFilters.valueSrcInput || null,
        },
      });

      setPaginationState(prevState => ({ ...prevState, currentPage: page }));
    },

    [getDiscounts, paginationState.currentPage, paginationState.pageSize, searchFilters]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSearch(), []);

  const renderContent = useMemo(() => {
    if (loading)
      return (
        <Flex alignItems="center" height="100%" justifyContent="center">
          <Loading color={'primary'} variant="large" />
        </Flex>
      );
    else if (discounts?.content?.length)
      return <Table content={discounts.content} handlePagination={handleSearch} pagination={paginationState} />;
    return null;
  }, [discounts, handleSearch, loading, paginationState]);

  const discountsSearch = useMemo((): JSX.Element => {
    return (
      <div className="content-body">
        <Header />
        <Filters filters={searchFilters} handleSearch={handleSearch} setFilters={setSearchFilters} />
        {renderContent}
      </div>
    );
  }, [handleSearch, renderContent, searchFilters]);

  const renderDiscountsSearch = useMemo(() => {
    if (!hasDiscountsRules()) history.push('/campanhas');

    return discountsSearch;
  }, [discountsSearch, history]);

  return renderDiscountsSearch;
}
