import React, { useState } from 'react';
import { Button, Modal } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import OutlinedInputCurrency from '../../OutlinedInputCurrency';
import Loading from '../../Loading';
import { GET_SELLERS } from '../../../graphql/seller.queries';
import { GET_ALL_REGIONS } from '../../../graphql/region.queries';
import { CREATE_MINPRICE } from '../../../graphql/price_minimum.mutations';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

const FormRegisterPriceMinimum = ({ role, isSeller }) => {
  const [modal, setModal] = useState();
  const [showRegionSearch, setShowRegionSearch] = useState(false);
  const [minPrice, setMinPrice] = useState({
    active: true,
    regionId: null,
    sellerId: null,
  });

  const optionsActive = [
    { value: true, label: 'Habilitado' },
    { value: false, label: 'Desabilitado' },
  ];

  const [getAllRegions, { data: dataRegion }] = useLazyQuery(GET_ALL_REGIONS);
  const [getSeller, { data: dataSeller }] = useLazyQuery(GET_SELLERS);

  const [createPriceMinimum, { loading: loadingCreate }] = useMutation(
    CREATE_MINPRICE,
    {
      onCompleted: (data) =>
        handleConflict(data?.createPriceMinimum?.error),
    },
  );

  function handleConflict(data) {
    if (data === 'MINIMUM_ORDER_CONFLICT') {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Já existe um pedido mínimo habilitado para essa região.
        </Modal>,
      );
    } else {
      window.location.href = '/fornecedores/pedido-minimo/';
    }
  }

  function handleSeller(sellerName) {
    if (sellerName && dataSeller?.getSellerByName) {
      const idSeller = dataSeller.getSellerByName.find(
        (item) => item.name === sellerName,
      );
      setMinPrice({
        ...minPrice,
        sellerId: idSeller?.id,
      });
      getAllRegions({
        variables: { ownerId: idSeller?.id },
      });
      setShowRegionSearch(true);
    } else {
      setMinPrice({
        ...minPrice,
        sellerId: null,
        regionId: null,
      });
      setShowRegionSearch(false);
    }
  }

  function handleRegion(regionName) {
    if (regionName && dataRegion?.getAllRegions?.content) {
      const idRegion = dataRegion.getAllRegions.content.find(
        (item) => item.name === regionName,
      );
      setMinPrice({
        ...minPrice,
        regionId: parseInt(idRegion?.id),
      });
    } else {
      setMinPrice({
        ...minPrice,
        regionId: null,
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!minPrice.regionId) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Preencha todos os campos.
        </Modal>,
      );
    }

    let priceValueEdited;
    if (typeof(minPrice?.minimumPrice) === 'string') {
      const priceValuePrefix = minPrice?.minimumPrice.replace('R$ ', '');
      const priceValueDot = priceValuePrefix.replaceAll('.', '');
      const priceValueDecimal = priceValueDot.replace(',', '.');
      priceValueEdited = parseFloat(priceValueDecimal, 2);
    } else {
      priceValueEdited = minPrice?.minimumPrice
    }

    let variables = {
      regionId: minPrice.regionId,
      sellerId: !isSeller ? minPrice.sellerId : null,
      active: minPrice.active,
      minimumOrder: priceValueEdited || 0
    };

    return createPriceMinimum({ variables });
  }

  return (
    <>
      {(role || isSeller) && (
        <div className="form-region">
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="form-register form-register--product"
          >
            {!isSeller && (
              <div className="dropdown-label-wrap">
                <Autocomplete
                  onChange={(event, sellerName) => handleSeller(sellerName)}
                  className="dropdown-ifood form-search__autocomplete"
                  noOptionsText="Nenhum fornecedor encontrado"
                  options={
                    dataSeller?.getSellerByName
                      ? dataSeller?.getSellerByName.map((option) => option.name)
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      className="form-search__autocomplete"
                      {...params}
                      label="Busque um fornecedor"
                      variant="outlined"
                      onChange={(e) =>
                        getSeller({ variables: { name: e.target.value } })
                      }
                    />
                  )}
                />
              </div>
            )}

            {(showRegionSearch || isSeller) && (
              <div className="dropdown-label-wrap">
                <Autocomplete
                  onChange={(event, regionName) => handleRegion(regionName)}
                  className="dropdown-ifood form-search__autocomplete"
                  noOptionsText="Nenhuma região encontrada"
                  options={
                    dataRegion?.getAllRegions?.content
                      ? dataRegion?.getAllRegions?.content.map(
                          (option) => option.name,
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      className="form-search__autocomplete"
                      {...params}
                      label="Busque uma região"
                      variant="outlined"
                      onChange={(e) =>
                        getAllRegions({
                          variables: {
                            name: e.target.value,
                            ownerId: !isSeller ? minPrice.sellerId : null,
                          },
                        })
                      }
                    />
                  )}
                />
              </div>
            )}

            <div className="dropdown-label-wrap">
              <Dropdown
                options={optionsActive}
                onChange={(e) =>
                  setMinPrice({
                    ...minPrice,
                    active: e.value,
                  })
                }
                value={
                  optionsActive.find((o) => o.value === minPrice?.active) ||
                  optionsActive[0]
                }
                className="dropdown-ifood"
                controlClassName="dropdown-ifood__control"
                placeholderClassName="dropdown-ifood__placeholder"
                menuClassName="dropdown-ifood__menu"
                arrowClassName="dropdown-ifood__arrow"
              />
              <label className="dropdown-label">Estado</label>
            </div>

            <div className="form-admin__group">
              <OutlinedInputCurrency
                className="form-register__input"
                placeholder="Preço mínimo (R$)"
                value={minPrice?.minimumPrice}
                onChange={(e) => setMinPrice({
                  ...minPrice,
                  minimumPrice: e.target.value
                })}
              />
            </div>

            <div className="form-register__button-save">
              <Button type="submit">Salvar</Button>
            </div>
          </form>
        </div>
      )}
      {loadingCreate && <Loading />}
      {modal}
    </>
  );
};

export default FormRegisterPriceMinimum;
