const UnitList = {
  KIT: quantity => (quantity > 1 ? 'Kits' : 'Kit'),
  CONJUNTO: quantity => (quantity > 1 ? 'Conjuntos' : 'Conjunto'),
  KG: quantity => 'kg',
  LITRO: quantity => (quantity > 1 ? 'Litros' : 'Litro'),
  CAIXA: quantity => (quantity > 1 ? 'Caixas' : 'Caixa'),
  UNIDADE: quantity => (quantity > 1 ? 'Unidades' : 'Unidade'),
  PACOTE: quantity => (quantity > 1 ? 'Pacotes' : 'Pacote'),
  BANDEJA: quantity => (quantity > 1 ? 'Bandejas' : 'Bandeja'),
  GALÃO: quantity => (quantity > 1 ? 'Galões' : 'Galão'),
  DÚZIA: quantity => (quantity > 1 ? 'Dúzias' : 'Dúzia'),
  ROLO: quantity => (quantity > 1 ? 'Rolos' : 'Rolo'),
  GR: quantity => 'Gr',
  FARDO: quantity => (quantity > 1 ? 'Fardos' : 'Fardo'),
  PEÇA: quantity => (quantity > 1 ? 'Peças' : 'Peça'),
  DISPLAY: quantity => (quantity > 1 ? 'Displays' : 'Display'),
  EMBALAGEM: quantity => (quantity > 1 ? 'Embalagens' : 'Embalagem'),
  CARTELA: quantity => (quantity > 1 ? 'Cartelas' : 'Cartela'),
  CESTA: quantity => (quantity > 1 ? 'Cestas' : 'Cesta'),
  GARRAFA: quantity => (quantity > 1 ? 'Garrafas' : 'Garrafa'),
  LATA: quantity => (quantity > 1 ? 'Latas' : 'Lata'),
  POTE: quantity => (quantity > 1 ? 'Potes' : 'Pote'),
  SACHÊ: quantity => (quantity > 1 ? 'Sachês' : 'Sachê'),
  SACO: quantity => (quantity > 1 ? 'Sacos' : 'Saco'),
};

export function ChangeUnitLabel(quantity, label) {
  return UnitList[label.toUpperCase()](quantity);
}
