/* istanbul ignore file */
import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import Toggle from 'react-toggle';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { GET_SELLERS } from '../../../../graphql/seller.queries';
import { GET_OFFERS } from '../../../../graphql/offer.queries';
import { CREATE_OFFER } from '../../../../graphql/offer.mutations';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionCatalog } from '@analytics/aboyeur/entities/sessionCatalog';
import Roles from '@helpers/Roles';
import { getRoles, getSellerId } from '@helpers/rolesUtils';
import AuthorizationHeader from '@helpers/AuthorizationHeader';

import OutlinedInputCurrency from '@components/OutlinedInputCurrency';
import Loading from '@components/Loading';
import Radio from '@components/Radio/Input';
import HeaderRegisterProduct from '../HeaderRegisterProduct';

import './formRegister.scss';
import 'react-dropdown/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';
import { api } from '@services/api';

const FormRegisterOffer = props => {
  const history = useHistory();

  const roles = getRoles();
  const roleOfferWriter = roles.includes(Roles.OFFER_WR);
  const roleOfferReader = roles.includes(Roles.OFFER_RO);
  const roleSellerWriter = roles.includes(Roles.SELLER_WR);
  const roleSellerReader = roles.includes(Roles.SELLER_RO);
  const rolePriceKgWR = roles.includes(Roles.PRICE_KG_SELLER_WR);

  if (!roleOfferWriter && !roleOfferReader) {
    history.goBack();
  }

  function ReadOnly(prdWriter) {
    if (!prdWriter) {
      return 'disabled';
    }
    return '';
  }

  const optionsState = ['Habilitado', 'Desabilitado'];
  const [modal, setModal] = useState(null);
  const [price, setPrice] = useState('0');
  const [pricePerKg, setPricePerKg] = useState('0');
  const [isPricePerKg, setIsPricePerKg] = useState(false);
  const [sellerCode, setSellerCode] = useState('');
  const [seller, setSeller] = useState(null);
  const [state, setState] = useState(optionsState[0]);
  const [stock, setStock] = useState(null);
  const [sellerId, setSellerId] = useState(getSellerId);
  const [sellers, setSellers] = useState(null);
  const [variableWeight, setVariableWeight] = useState(false);
  const [limitQuantity, setLimitQuantity] = useState('');

  // Faster
  const sessionCatalog = useAboyeur(SessionCatalog);

  const [getSellerByName, { data: dataSellers }] = useLazyQuery(GET_SELLERS);

  const { data: dataOffers } = useQuery(GET_OFFERS, {
    variables: {
      pageNumber: 0,
      pageSize: 0,
      variation: parseInt(props.variationid),
    },
  });

  const [createOffer] = useMutation(CREATE_OFFER, {
    onCompleted: data => {
      if (data?.createOffer?.error) handleErrors(data.createOffer.error);
      else
        window.location.href =
          '/catalogos/produtos/' + props.productid + '/variacoes/editar/' + props.variationid;
    },
    onError: _error => {
      changeModal('Erro ao cadastrar oferta', 'Atenção');
    },
  });

  

  function RenderLojista() {
    return (
      <>
        <p className="labelOfferEdit">Lojista</p>

        <div className="dropdown-label-wrap__large">
          <Autocomplete
            id="parentSeller"
            freeSolo
            className="dropdown-ifood"
            options={sellers || []}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} variant="outlined" />}
            onSelect={e => setSeller(e.target.value)}
            onChange={(_event, newValue) => {
              if (newValue) {
                setSellerId(newValue.id);
              } else {
                setSellerId(null);
              }
            }}
          />
        </div>
      </>
    );
  }

  function CheckUsedSeller(seller_id) {
    let retorno = true;

    if (dataOffers && dataOffers.findOffersPaginated && dataOffers.findOffersPaginated.content) {
      let offers = dataOffers.findOffersPaginated.content;

      Object.keys(offers).map(function (_objectkey, index) {
        if (offers[index].seller == seller_id) {
          retorno = false;
        }
      });
    }
    return retorno;
  }

  useEffect(() => {
    if (sellerId && CheckUsedSeller(sellerId) === false) {
      changeModal('Lojista já possui oferta para esta variação');
    }
  }, [sellerId]);

  useEffect(() => {
    if (seller && seller !== '') {
      getSellerByName({ variables: { name: seller } });
    } else {
      setSellers(null);
    }
  }, [seller]);

  useEffect(() => {
    if (dataSellers) {
      if (dataSellers.getSellerByName) {
        setSellers(dataSellers.getSellerByName);
      }
    }
  }, [dataSellers]);

  const radioButtonChecked = () => {
    let element = document.getElementById('priceRadioKg');

    if (element.checked) {
      setIsPricePerKg(true);
    } else {
      setIsPricePerKg(false);
    }
  };

  const registerOffer = e => {
    e.preventDefault();

    let floatPrice = price;
    let floatPricePerKg = pricePerKg;

    let validacao = 1;
    let sendItems;

    if (price) {
      floatPrice = parseFloat(price.toString().replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
    }

    if (pricePerKg) {
      floatPricePerKg = parseFloat(pricePerKg.toString().replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
    }

    sendItems = {
      seller: parseInt(sellerId),
      sellerCode: sellerCode,
      price: floatPrice,
      pricePerKg: floatPricePerKg,
      isPricePerKg: isPricePerKg,
      active: state === 'Habilitado',
      stock: parseInt(stock),
      variation: parseInt(props.variationid),
      variableWeight: variableWeight,
      limitQuantity: parseInt(limitQuantity),
    };

    Object.keys(sendItems).map(function (objectkey, index) {
      var size = Object.keys(sendItems).length - 1;
      var value = sendItems[objectkey];

      if (value === '' || value == null) {
        changeModal('Todos os campos são obrigatórios!', 'Atenção');
        validacao = 0;
      }

      if (index == size && validacao == 1) {
        createOffer({ variables: sendItems });
      }
    });

    sessionCatalog.events.offer.clickPricePerKgCreateOffer({
      //Propriedades > Metadata
      variationId: parseInt(props.variationid),
      price: floatPrice,
      pricePerKg: floatPricePerKg,
      isPriceKg: isPricePerKg,
    });
  };

  const changeModal = (message, title) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  function importOffers(e) {
    setModal(<Loading />);

    var fileImport = e.target.files[0];

    var formData = new FormData();

    formData.append('file', fileImport);

    api.post('/offer/import/', formData)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'productImportReturn.csv';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        setModal(null);
      })
      .catch(_error => {
        changeModal('Erro  ao importar arquivo!', 'Atenção');
      });
  }

  function handleErrors(error) {
    switch (error) {
      case 'OFFER_SELLER_ALREADY_HAVE_OFFER':
        changeModal('Lojista já possui oferta cadastrada.', 'Atenção');
        break;
      case 'OFFER_CODE_NOT_AVAILABLE':
        changeModal('Código do lojista já utilizado', 'Atenção');
        break;
      default:
        changeModal('Erro ao cadastrar oferta.', 'Atenção');
    }
  }

  return (
    <div>
      {modal}
      <form className="form-register form-register--offer" onSubmit={registerOffer} data-testid="create-offer-content">
        <div className="headerFormRegister">
          <NavLink
            to={'/catalogos/produtos/' + props.productid + '/variacoes/editar/' + props.variationid}
            className="go-back"
          />
          <HeaderRegisterProduct title="Criar oferta" />

          <div className="imporOffersRegister">
            <label className="link header-customer-group__actions-link" for="importOffers">
              Importar ofertas
            </label>
            <input
              type="file"
              id="importOffers"
              className="header-customer-group__actions-import"
              onChange={e => importOffers(e)}
            />
          </div>

          <Button className="btn-save" type="submit">
            Salvar
          </Button>
        </div>

        <div className="contentFormRegister">
          <div className="spaceContent"></div>

          {!roleSellerReader && !roleSellerWriter ? RenderLojista() : null}

          <p id="skuLabelRegister" className="labelOfferEdit">
            SKU*
          </p>
          <OutlinedInput
            defaultValue={sellerCode}
            border
            className="labelOfferInput"
            onChange={e => {
              setSellerCode(e.target.value);
            }}
            disabled={ReadOnly(roleOfferWriter)}
          />

          <div className="dropdown-label-wrap">
            <p className="labelOfferEdit">Estado</p>
            <Dropdown
              options={optionsState}
              onChange={e => setState(e.value)}
              placeholder=""
              value={state}
              className="dropdown-ifood-register labelOfferInput"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
              disabled={!roleOfferWriter}
            />
          </div>

          <div className="priceEditOffer">
            {rolePriceKgWR ? (
              <>
                <p className="labelOfferEdit">Precificação</p>

                <div className="radioButtonsFormGroup">
                  <div className="radioButtonsEditOffer">
                    <Radio
                      className="containerReason__radios"
                      name="priceRadio"
                      checked={isPricePerKg === false}
                      value={isPricePerKg}
                      onChange={radioButtonChecked}
                    />
                    <Radio
                      id="priceRadioKg"
                      className="containerReason__radios"
                      name="priceRadio"
                      checked={isPricePerKg === true}
                      value={isPricePerKg}
                      onChange={radioButtonChecked}
                    />
                  </div>

                  <div className="radioReasons">
                    <label className="labelRadioReasons">Por unidade</label>
                    <label className="labelRadioReasons">Por kilo</label>
                  </div>
                </div>

                <p className="labelOfferEdit">Preço</p>
                <OutlinedInputCurrency
                  className="priceInput labelOfferInput"
                  value={price || 0}
                  onChange={e => setPrice(e.target.value)}
                  disabled={(ReadOnly(roleOfferWriter), isPricePerKg)}
                />

                <p className="labelOfferEdit">Preço por Kg</p>
                <OutlinedInputCurrency
                  className="pricePerKgInput labelOfferInput"
                  value={pricePerKg || 0}
                  onChange={e => setPricePerKg(e.target.value)}
                  disabled={(ReadOnly(roleOfferWriter), !isPricePerKg)}
                />
              </>
            ) : (
              <>
                <p className="labelOfferEdit">Preço</p>
                <OutlinedInputCurrency
                  className="priceInputNormal labelOfferInput"
                  value={price || 0}
                  onChange={e => setPrice(e.target.value)}
                  disabled={ReadOnly(roleOfferWriter)}
                />
              </>
            )}
          </div>

          <div className="toggle-wrap">
            <p className="toggle-wrap__label">Peso variável</p>
            <Toggle
              disabled={ReadOnly(roleOfferWriter)}
              defaultChecked={variableWeight}
              checked={variableWeight}
              value={variableWeight}
              onChange={v => setVariableWeight(v.target.checked)}
            />
          </div>

          <p className="labelOfferEdit">Estoque</p>
          <OutlinedInput
            border
            className="labelOfferInput"
            value={stock}
            onChange={e => setStock(e.target.value.replace(/\D/g, ''))}
            disabled={ReadOnly(roleOfferWriter)}
          />

          <p className="labelOfferEdit">Limite máx por pedido</p>
          <OutlinedInput
            className="labelOfferInput"
            border
            value={limitQuantity}
            onChange={e => setLimitQuantity(e.target.value.replace(/\D/g, ''))}
            disabled={ReadOnly(roleOfferWriter)}
          />
        </div>
      </form>
    </div>
  );
};

export default FormRegisterOffer;
