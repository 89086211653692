import { Button, Flex, Icon, Input, Segment, Select } from '@ifood/pomodoro-components';
import { SearchOutlined } from '@ifood/pomodoro-icons';
import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import {
  initialSegmentOptions,
  optionsState,
  optionsValid,
} from '@pages/Campaign/Discount/DiscountsSearch/Search.utils';
import {
  EnumDiscountTypes,
  ISearchFilterProps,
} from '@pages/Campaign/Discount/DiscountsSearch/interfaces/Discounts.interfaces';

const firstIndex = 0;

export default function Filters({ filters, handleSearch, setFilters }: ISearchFilterProps): JSX.Element {
  const [segmentsState, setSegmentsState] = useState<Record<string, boolean>>({ ...initialSegmentOptions });

  const handleSegmentOptions = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      const {
        currentTarget: { value },
      } = e;

      setSegmentsState({ ...initialSegmentOptions, [value]: !segmentsState[value] });

      setFilters(prevState => ({ ...prevState, type: segmentsState[value] ? '' : value }));
    },
    [segmentsState, setFilters]
  );

  const handleFilters = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = e;

      setFilters(prevState => ({ ...prevState, [name]: value }));
    },
    [setFilters]
  );

  return (
    <>
      <Segment.Group marginBottom={16}>
        <Segment.Button
          readOnly
          checked={segmentsState.PERCENTUAL_ON_PRODUCT}
          label="% Produto"
          name="type"
          value={EnumDiscountTypes.PRODUCT}
          onClick={handleSegmentOptions}
        />
        <Segment.Button
          readOnly
          checked={segmentsState.PERCENTUAL_ON_FREIGHT}
          label="% Frete"
          name="type"
          value={EnumDiscountTypes.FREIGHT}
          onClick={handleSegmentOptions}
        />
        <Segment.Button
          readOnly
          checked={segmentsState.ABSOLUTE_ON_CART}
          label="% Absoluto"
          name="type"
          value={EnumDiscountTypes.ABSOLUTE}
          onClick={handleSegmentOptions}
        />
      </Segment.Group>

      <Flex gap={16} marginBottom={24}>
        <Input name="valueSrcInput" placeholder="Nome" value={filters.valueSrcInput} onChange={handleFilters} />
        <Input name="highlight" placeholder="Selo" value={filters.highlight} onChange={handleFilters} />
        <Input name="couponCode" placeholder="Cupom" value={filters.couponCode} onChange={handleFilters} />
        <Select
          items={optionsState}
          name="active"
          placeholder="Estado"
          value={optionsState.find(o => o.value === filters.active)}
          onChange={handleFilters}
        />
        <Select
          items={optionsValid}
          name="valid"
          placeholder="Validade"
          value={optionsValid.find(o => o.value === filters.valid)}
          onChange={handleFilters}
        />

        <Button variant="secondary" onClick={() => handleSearch(firstIndex)}>
          <Flex alignItems={'center'} justifyContent="space-between">
            <Icon component={SearchOutlined} marginRight={10} size="s" />
            Filtrar
          </Flex>
        </Button>
      </Flex>
    </>
  );
}
