import FormatHelper from '@helpers/FormatHelper';

function TableInLote({ listData, isSeller }) {
  const handleTotal = item => {
    return FormatHelper.format(isSeller ? item.sellerUnitPrice : item.unitPrice * item.quantity, 'currency');
  };
  return (
    <div className="table-admin--scroll-y table-admin--scroll-y-small">
      <table className="table-admin table-admin--sample table-admin--sample--full table-admin--width-auto">
        <thead>
          <tr>
            <th className="table-admin__title">ID</th>
            <th className="table-admin__title">Cliente</th>
            <th className="table-admin__title">Data</th>
            <th className="table-admin__title">Qtd.</th>
            <th className="table-admin__title">Total</th>
          </tr>
        </thead>
        <tbody>
          {listData.map(sellers => {
            const item = sellers?.orderSellers[0]?.deliveries[0]?.items[0];
            const delivery = sellers?.orderSellers[0]?.deliveries[0];
            const customer = sellers?.customer?.name;
            return (
              <tr id={delivery?.orderSellerId} key={delivery?.orderSellerId}>
                <td className="table-admin__data">{delivery.orderSellerId}</td>
                <td className="table-admin__data">{customer}</td>
                <td className="table-admin__data">{FormatHelper.format(delivery.createdAt, 'simple-date')}</td>
                <td className="table-admin__data">{item.quantity}</td>
                <td className="table-admin__data">R$ {handleTotal(item)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableInLote;
