/* istanbul ignore file */
import { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@ifood/pomodoro-components';
import { allPaymentMethods, checkedPaymentNames, formatPaymentName } from './utils';
import * as S from './EstablishmentPayments.styles';

interface IEstablishmentPayments {
  payments: string[] | undefined;
  updatePayments: (data: string[]) => void;
  hasEditingRule: boolean;
}

export default function EstablishmentPayments({ payments, updatePayments, hasEditingRule }: IEstablishmentPayments) {
  const [chosenMethods, setChosenMethods] = useState(
    payments ? allPaymentMethods.map(({ name }) => ({ name, checked: payments?.some(p => name === p) })) : []
  );

  const selectPaymentMethod = useCallback((event: { target: { checked: boolean; value: string } }) => {
    const {
      target: { value },
    } = event;

    setChosenMethods(prevState =>
      prevState.map(({ checked, name }) => ({ name, checked: value === name ? !checked : checked }))
    );
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updatePayments(checkedPaymentNames(chosenMethods)), [chosenMethods]);

  return (
    <>
      <S.Title variant="small">Métodos de pagamento</S.Title>
      <S.CheckboxGroup>
        {chosenMethods.map(payment => (
          <S.CheckboxButton
            key={payment.name}
            aria-labelledby={payment.name}
            checked={payment.checked}
            disabled={!hasEditingRule}
            label={formatPaymentName(payment.name)}
            value={payment.name}
            onChange={selectPaymentMethod}
          />
        ))}
      </S.CheckboxGroup>
    </>
  );
}
