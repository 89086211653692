import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import * as S from './ModalFlowAccept.styles';
import ImgRestaurantProfile from '../../../../assets/images/order/ModalFlowAccept/restaurant-profile.svg';

const ModalFlowAcceptstep1 = () => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <S.FigureBox marginBottom={theme.space.largest}>
        <S.Image alt="Imagem do perfil do restaurante" src={ImgRestaurantProfile} width="329" />
      </S.FigureBox>
      <S.Text as="p">
        <strong>Ganhe tempo e praticidade</strong> no seu dia a dia
        <strong> editando o peso variável e a quantidade de itens</strong> em pedidos com o pagamento aprovado ou
        faturados.
      </S.Text>
    </>
  );
};

export default ModalFlowAcceptstep1;
