import { Button, Flex, Radio, TextArea, Text as TextPomodoro } from '@ifood/pomodoro-components';
import styled from 'styled-components';

const Text = styled(TextPomodoro)`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.space.larger};
`;

const CustomTextArea = styled(TextArea)`
  height: 132px;
`;

const Form = styled.form`
  min-width: 100%;
`;

const RadioGroup = styled(Radio.Group)`
  flex-direction: row;
`;

const ButtonCancel = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

const ButtonSave = styled(Button)`
  margin-left: 16px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

const ButtonsRow = styled(Flex)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export { Text, CustomTextArea, ButtonSave, ButtonCancel, Form, RadioGroup, ButtonsRow };
