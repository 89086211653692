import gql from 'graphql-tag';

const GET_COMMISSIONS = gql`
    query findPaginateCommission(
        $sellerId: Int!,  $linkedId: Int, $commissionType: String!,
        $startDate: String, $endDate: String, $percentage: Float, $active: Boolean, $page: Int, $size: Int){
        findPaginateCommission(
            sellerId: $sellerId, linkedId: $linkedId, commissionType: $commissionType,startDate:
            $startDate, endDate: $endDate, percentage: $percentage,active: $active, page: $page, size: $size){
            content{
                id
                sellerId
                linkedId
                commissionType
                description
                category{
                    name
                }
                offer{
                    sellerCode
                }
                startDate
                endDate
                percentage
                active
                createdAt
                updatedAt
            }
            empty
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
        }
    }
`;

const GET_COMMISSION_BY_ID = gql`
    query  findCommissionById($id: Int){
        findCommissionById(id: $id){
            content{
                id
                sellerId
                linkedId
                description
                startDate
                endDate
                percentage
                active
                createdAt
                updatedAt
            }
        }
    }
`;

const GET_COMMISSION_RESULT_BY_ID = gql`
    query findCommissionResult($sellerId: Int, $categoryId: Int, $offerId: Int){
        findCommissionResult(sellerId: $sellerId, categoryId: $categoryId, offerId: $offerId){
            content{
                id
                sellerId
                linkedId
                description
                startDate
                endDate
                percentage
                active
                createdAt
                updatedAt
            }
        }
    }
`;

export {GET_COMMISSIONS, GET_COMMISSION_BY_ID, GET_COMMISSION_RESULT_BY_ID};
