import { useState, useRef } from 'react';
import { Link, Modal } from 'ifoodshop-react-ui';

import TemplateOwnerOrder from '@files/templateOwnerOrder.csv';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrders } from '@analytics/aboyeur/entities/sessionOrders';
import { getRoles, getSellerId } from '@helpers/rolesUtils';

import { useOrderContext } from '@components/_context/OrderContext';
import Loading from '@components/Loading';
import ModalSelectSellersExport from '@components/ModalSelectSellersExport';
import ModalUpdateImportNotice from '@components/ModalUpdateImportNotice';

import { ReactComponent as ChevronDownHeader } from '../../../../assets/icons/chevron-down.svg';
import './headerSearchOrder.scss';
import { api } from '@services/api';
import BulkImport from '@components/BulkImport';
import { Flex } from '@ifood/pomodoro-components';
import Roles from '@helpers/Roles';
import { EnumHeaderOptionsType } from '@components/BulkImport';

const HeaderSearchOrder = () => {
  const roles = getRoles();
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seeMore, setSeemore] = useState(false);
  const wrapperRef = useRef(null);
  const { refetchAllOrders } = useOrderContext();

  const sessionOrders = useAboyeur(SessionOrders);
  const roleOrderOwnerWR = roles.includes(Roles.ORDER_OWNER_WR);

  const exportOrders = sellerId => {
    let date = new Date();
    setLoading(true);

    api
      .post(`/order/export/${sellerId}`)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Status pedidos - Exportação ${date.toLocaleDateString()} - ${date.toLocaleTimeString()}.csv`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao exportar arquivo');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const importUpdateStatus = e => {
    let date = new Date();
    setModal(<Loading />);

    var fileImport = e.target.files[0];
    var formData = new FormData();

    formData.append('file', fileImport);

    api
      .post('/order/import', formData)
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw new Error();
        }
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Status pedidos - Importação ${date.toLocaleDateString()} - ${date.toLocaleTimeString()}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        refetchAllOrders();
        setModal(null);
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao importar arquivo');
      });
  };

  const showModalExport = () => {
    const sellerId = getSellerId();

    sessionOrders.events.orders.clickOrderStatusExport();

    if (sellerId) {
      exportOrders(sellerId);
    } else if (!roles.includes('SELLER-WR') && !roles.includes('SELLER-RO')) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)} title="Exportar pedidos">
          <ModalSelectSellersExport onExport={exportOrders} />
        </Modal>
      );
    } else {
      changeModal('Exportar pedidos', 'O usuário não possui um id de fornecedor válido.');
    }
  };

  const showModalImportUpdate = () => {
    sessionOrders.events.orders.clickOrderStatusImport();

    setModal(
      <Modal onCloseAction={() => setModal(null)} title="Importar atualização de status">
        <ModalUpdateImportNotice onImport={importUpdateStatus} onExport={showModalExport} />
      </Modal>
    );
  };

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  const seeMoreToggle = e => {
    if (seeMore) {
      setSeemore(false);
    } else {
      setSeemore(true);

      sessionOrders.events.orders.clickOrderStatus();
    }
  };

  const root = document.getElementById('root');
  root.addEventListener('click', e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSeemore(false);
    }
  });

  return (
    <>
      <div className="header-customer-group header-customer-group--src__order">
        <div className="header-customer-group__info">
          <h2 className="section-title">Pedidos</h2>
          <p className="header-customer-group__description">Busque um pedido</p>
        </div>

        <Flex gap={12}>
          <div id="statusPedidosLinks" className="options-link" ref={wrapperRef}>
            <Link
              className="header-customer-group__actions-link link-see-more"
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
              onClick={e => seeMoreToggle(e)}
            >
              Status pedidos{' '}
              <ChevronDownHeader
                id="chevronDownHeaderOrder"
                fill="#3E3E3E"
                className={seeMore ? 'chevron-down open' : 'chevron-down'}
              />
            </Link>
            {seeMore ? (
              <div id="listLinksHeader" className="header-customer-group__actions-more">
                <div className="dropdown-ifood">
                  <Link className="header-customer-group__actions-link" onClick={showModalExport}>
                    Exportação
                  </Link>
                  <Link className="header-customer-group__actions-link" onClick={showModalImportUpdate}>
                    Importação
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
          {roleOrderOwnerWR && (
            <BulkImport
              dialogTitle="Importar alteração de owner"
              label="Alteração de owner"
              uploadUrl="/order-customer/upload-update-owner-order"
              options={[
                {
                  name: 'Modelo alteração de owner',
                  value: 'order-owner-download',
                  template: TemplateOwnerOrder,
                  roles: [Roles.ORDER_OWNER_WR],
                  type: EnumHeaderOptionsType.DOWNLOAD,
                },
                {
                  name: 'Alteração de owner',
                  value: 'order-owner-import',
                  roles: [Roles.ORDER_OWNER_WR],
                  type: EnumHeaderOptionsType.UPLOAD,
                },
              ]}
            />
          )}
        </Flex>
      </div>
      {modal}
      {loading && <Loading />}
    </>
  );
};

export default HeaderSearchOrder;
