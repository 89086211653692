import * as S from './SellerRegistrationHeader.styles';
import { Flex, Heading1, Stepper } from '@ifood/pomodoro-components';
import { SellerRegistrationHeaderType } from './types';
import { pageSteps } from '../../SellerFormType';

const SellerRegistrationHeader = ({ actualStep, titleHeader }: SellerRegistrationHeaderType): JSX.Element => {
  return (
    <S.ContentHeader>
      <Flex justifyContent="space-between">
        <Heading1 fontWeight="medium" my={10} variant="regular">
          {titleHeader}
        </Heading1>
        {actualStep !== undefined && actualStep in pageSteps && (
          <Stepper activeIndex={actualStep} id="stepper" initialIndex={1} marginTop="0" steps={4} width="60%" />
        )}
      </Flex>
    </S.ContentHeader>
  );
};

export default SellerRegistrationHeader;
