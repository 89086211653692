import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPGRADE_ORDER_DELIVERY_STATUS } from '../../../../../graphql/order-delivery.mutations';
import { OutlinedInput, Button, Typography } from 'ifoodshop-react-ui';
import DatePicker from 'react-datepicker';
import ptbr from 'date-fns/locale/pt-BR';
import Loading from '@components/Loading';

import OutlinedTextarea from '@components/OutlinedTextarea';
import { formatDateToIsoLocal } from '../../orderUtils';

import { ReactComponent as ArrowRight } from '../../../../../assets/icons/order/arrow-right.svg';

function HandleDispatched({ orderDeliveryId, buttonText }) {
  const [errorPage, setErrorPage] = useState(null);
  const [updateTime, setUpdateTime] = useState(null);
  const [errorUpdateTime, setErrorUpdateTime] = useState(null);
  const [updateObservation, setUpdateObservation] = useState(null);
  const [trackingCode, setTrackingCode] = useState(null);
  const [valideteUpdateTime, setValideteUpdateTime] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false);
  const [updateOrderStatus, { error, data, loading }] = useMutation(UPGRADE_ORDER_DELIVERY_STATUS);
  if (data) window.location.reload(false);
  if (error) {
    setErrorPage('Não Foi Possivel Alterar O Status Da Entrega');
  }

  function toDispatched(e) {
    e.preventDefault();
    setDisabledButton(true)

    !updateTime && setErrorUpdateTime('Campo obrigatório');

    if (updateTime)
      updateOrderStatus({
        variables: {
          orderDeliveryId,
          updateStatus: 'IN_TRANSPORTATION',
          updateTime: formatDateToIsoLocal(updateTime),
          updateObservation,
          trackingCode,
        },
      });
  }

  function checkDate(date) {
    const dateNow = new Date();

    if (dateNow < date) {
      setValideteUpdateTime(true);
      setDisabledButton(true);
    } else {
      setUpdateTime(date);
      setValideteUpdateTime(false)
      setDisabledButton(false);
      setErrorUpdateTime(null);
    }
  }

  return (
    <>
      <h2 className="modal-alter-status__title">
        Faturado <ArrowRight className="alter-status-arrow" /> Em transporte
      </h2>
      <h4 className="modal-alter-status__form__error">{errorPage}</h4>
      <form className="modal-alter-status__form">
        <div>
          <OutlinedInput
            placeholder="Código de rastreamento"
            border
            value={trackingCode}
            onChange={e => setTrackingCode(e.target.value)}
          />
        </div>
        <div className={`outlined-label-wrap outlined-date ${updateTime ? 'active' : ''}`}>
          <DatePicker
            className="form-brand__input"
            onChange={date => checkDate(date)}
            locale={ptbr}
            selected={updateTime}
            showTimeSelect
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy hh:mm aaa"
          />
          <label className="outlined-label">Data de Despacho</label>
        </div>
        <p className="modal-alter-status__form__error">{errorUpdateTime}</p>
        <div className="form-edit__input-group">
          <OutlinedTextarea
            placeholder="Observação"
            value={updateObservation}
            onChange={e => setUpdateObservation(e.target.value)}
          />
        </div>
        {valideteUpdateTime && (
          <Typography className="modal-alter-status__error-date" size="12px" weight="thin">
            Data de altereção de status não pode ser superior a data atual
          </Typography>
        )}
        <Button className="modal-alter-status__submit" disabled={disabledButton} onClick={e => toDispatched(e)}>
          {buttonText}
        </Button>
      </form>
      {loading && <Loading />}
    </>
  );
}

export default HandleDispatched;
