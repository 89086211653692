import React, {useEffect, useState} from "react";
import { Modal, OutlinedInput } from "ifoodshop-react-ui";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {GET_SELLER, UPDATE_SELLER} from "../../../../../../graphql/seller.queries";

const HeaderCommissions = (props) => {
	
	const [form, setForm] = useState({
		seller: null,
		percentage: null
	})

	const [modal, setModal] = useState(null)
	const [getSellerById, { data: dataGetSeller}] = useLazyQuery(GET_SELLER)
	const [updateSeller] = useMutation(UPDATE_SELLER)
	
	
	const handleChange = (e) => {
		const {name, value} = e.target;
		setForm({
			...form,
			[name]: value
		});
	};
	
	const percentageLimit = (e) => {
		const {value} = e.target
		if (value > 100) {
			e.target.value = 100
			handleChange(e)
		} else {
			handleChange(e)
		}
	}
	
	useEffect(() => {
		getSellerById({
			variables: {
				id: parseInt(props.sellerId) || null
			}
		})
	}, [])
	
	useEffect(() => {
		if (dataGetSeller && form.seller === null) {
			const e = {name: "seller", value: dataGetSeller.getSellerById}
			setForm({
				...form,
				[e.name]: e.value
			})
		}
	}, [dataGetSeller])

	const changeModal = (message, title) => {
		setModal(<Modal onCloseAction={() => setModal(null)} title={title}>
			<p>{message}</p>
		</Modal>)
	}
	
	const registerCommission = (e) => {
		e.preventDefault()
		
		let validacao = 1;

		if(form.percentage > 0) {
			const {id, login, mobilePhone, residentialPhone} = {...form.seller.user}
			let temp = {idUser: id, login, mobilePhone, residentialPhone}

			delete form.seller.createdAt
			delete form.seller.updatedAt
			delete form.seller.user

			let percent = form.percentage ? parseFloat(form.percentage) : form.seller.commission

			temp = {...temp, commission: percent}

			form.seller = {...form.seller, ...temp}

			Object.keys(form.seller).map((objectkey, index) => {

				let size = Object.keys(form.seller).length - 1
				let value = form.seller[objectkey]

				if (validacao === 1 && index === size) {
					updateSeller({variables: form.seller})
						.then(res => {
							window.location.href = "/fornecedores/detail"
						})
						.catch(error => {
							changeModal("Erro ao atualizar o valor da comissão.", "Atenção")
							return error
						})
				}
			})
		}
		else {
			changeModal("O valor da comissão deve ser maior que zero.", "Atenção")
			validacao = 0
		}
	}
	
	return (
		<>
			{modal}
			<div className="header-customer-group__info">
				<h2 className="section-title">Comissão</h2>
				<p className="header-customer-group__description">Use os campos abaixo para fazer a busca das comissões.</p>
			</div>
			<div className="header-customer-group__actions">
				<form className="form-brand" id="frm-reg__commissions-default" onSubmit={e => registerCommission(e)}>
					<div className="form-row">
						<OutlinedInput
							placeholder="Fornecedor"
							className="form-brand__input"
							name="seller.name"
							value={form.seller?.name ? form.seller.name : ''}
							disabled={true}
						/>
						<OutlinedInput
							placeholder="ID Fornecedor"
							className="form-brand__input"
							name="id"
							value={form.seller?.id ? form.seller.id : ''}
							disabled={true}
						/>
						<OutlinedInput
							placeholder="CNPJ"
							className="form-brand__input"
							name="cnpj"
							value={form.seller?.document ? form.seller.document : ''}
							disabled={true}
						/>
					</div>
				</form>
			</div>
			<div className="default-commission">
				<OutlinedInput
					placeholder="Comissão padrão (máx 100%)"
					type="number"
					step="0.01"
					min="0.01"
					max="100.00"
					className="form-brand__input"
					name="percentage"
					border
					defaultValue={form.seller?.commission ? form.seller.commission : ''}
					onChange={e => percentageLimit(e)}
				/>
			</div>
		</>
	)
}

export default HeaderCommissions;
