import { Typography, Button } from "ifoodshop-react-ui";

import { useOrderContext } from "@components/_context/OrderContext";

import check from "../../../../../../assets/icons/order/vehicle-check.svg";

const RequestConfirmation = (props) => {
  const { refetch, setOrder } = useOrderContext();

  function alterStatus() {
    refetch().then((response) => {
      setOrder(response.data.getOrder);
      props.setModal(null);
    });
  }

  return (
    <div className="request-vehicle">
      <div className="request-vehicle__header-confirmation">
        <div>
          <Typography size="24px" weight="regular">
            Solicitar um veículo iFood
          </Typography>
        </div>
      </div>

      <div className="request-vehicle__confirmation">
        <img src={check} className="icon-check" />

        <div className="request-vehicle__subtitle">
          <Typography size="24px" weight="regular">
            Veículo solicitado com sucesso
          </Typography>
        </div>

        <div className="request-vehicle__confirmation-first">
          <Typography size="16px" weight="thin">
            O entregador já foi notificado com a sua solicitação e chegará em
            seu <br />
            estabelecimento{" "}
          </Typography>

          <Typography size="16px" weight="regular">
            em aproximadamente 2h.
          </Typography>
        </div>
        <div className="request-vehicle__confirmation-second">
          <Typography size="16px" weight="thin">
            Clique no botão abaixo para alterar o status para{" "}
          </Typography>

          <Typography size="16px" weight="regular">
            Veículo Solicitado. <br />
          </Typography>

          <Typography size="16px" weight="thin">
            Você ṕoderá acompanhar o status do veículo em seguida.
          </Typography>
        </div>
      </div>
      <div className="request-vehicle__next-right">
        <Button onClick={() => alterStatus()}>Alterar status</Button>
      </div>
    </div>
  );
};

export default RequestConfirmation;
