import { Link } from 'ifoodshop-react-ui';

const TableDiscountSubsidy = ({ removeSubsidy, subsidy }) => {
  function removeSubsidyLink(e, key) {
    e.preventDefault();
    removeSubsidy(key);
  }

  function formatName(item) {
    if (!item.costCenter) return `${item.name}`;

    return `${item.name}${item.costCenter?.active ? '' : ' - Inativo'}`;
  }

  return (
    <table className="table-admin table-admin--sample">
      <thead>
        <tr>
          <th className="table-admin__title">Tipo</th>
          <th className="table-admin__title">Nome</th>
          <th className="table-admin__title">Porcentagem de Subsídio</th>
          <th className="table-admin__title"></th>
        </tr>
      </thead>
      <tbody>
        {subsidy.length > 0 ? (
          subsidy.map(subsidy => {
            return (
              <tr id={subsidy.id} key={subsidy.key}>
                <td className="table-admin__data">{subsidy.type === 'IFOOD' ? 'Ifood' : 'Fornecedor'}</td>
                <td className="table-admin__data">{formatName(subsidy)}</td>
                <td className="table-admin__data">{subsidy.percentage}%</td>
                <td className="table-admin__data">
                  <Link onClick={e => removeSubsidyLink(e, subsidy.key)}>Remover</Link>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td></td>
            <td>não existe nenhum subsídio</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TableDiscountSubsidy;
