import { useEffect, useState } from "react";
import { Typography } from "ifoodshop-react-ui";
import { stepsList } from "../../../orderUtils";

import "./progress-bar.scss";

const ProgressBar = ({ deliveryStep, validSteps, quoteCode }) => {
  const [steps, setSteps] = useState(validSteps);

  useEffect(() => {
    validSteps.length === 0 &&
      deliveryStep.forEach((d) => {
        d.name === "IN_TRANSPORTATION" &&
          setSteps([
            { code: "DRIVER_ALLOCATED", eventDate: d.date },
            { code: "GOING_TO_DESTINATION" },
            { code: "COMPLETED" },
          ]);
      });
  }, [validSteps]);

  function handleStepsClass(prev, step) {
    if (step.eventDate) {
      if (step.code !== "CANCELLED") return `${prev}--success`;
      return `${prev}--failed`;
    }
    return "";
  }

  return (
    <div className="progress">
      <div className="request-vehicle__header">
        <Typography size="24px" weight="regular">
          Status solicitação de veículo
        </Typography>
      </div>
      <div>
        {steps.map((s, index) => (
          <div className="status">
            <div className={`steps ${handleStepsClass("steps", s)}`}></div>
            <div className="steps__information">
              <Typography
                className={`steps__title ${handleStepsClass(
                  "steps__title",
                  s
                )}`}
                size="16"
                weight="thin"
              >
                {stepsList[s.code]}
              </Typography>
              <div>
              <Typography className="steps__date" size="14" weight="thin">
                {s.eventDate &&
                  new Date(s.eventDate).toLocaleDateString("pt-BR") +
                    " • " +
                    new Date(s.eventDate).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
              </Typography>
              {index === 0 && quoteCode && (
                <Typography className="steps__date" size="14" weight="thin">
                  {` - Cotação nº: ${quoteCode}`}
                </Typography>
              )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
