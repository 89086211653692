import { BankFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import * as yup from 'yup';

type BankDataSchemaT = { [key in keyof BankFormValueType]: any };

// SCHEMA
const schema = yup.object().shape({
  accountType: yup.string().required('Tipo de conta é obrigatório'),
  bankName: yup.string().required('Banco é obrigatório'),
  agency: yup.string().required('Agência é obrigatório'),
  account: yup.string().required('Conta corrente é obrigatório'),
} as BankDataSchemaT);

export default schema;
