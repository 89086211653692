import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { NavLink, useHistory } from 'react-router-dom';

import IconArrow from '../../../../assets/images/arrow.png';
import TableCollectionSearch from './components/TableCollectionSearch';
import HeaderCollectionSearch from './components/HeaderCollectionSearch/HeaderCollectionSearch';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

const CollectionSearch = () => {
	const [collection, setCollection] = useState();
	const [page, setPage] = useState(0);
	const [lastPage, setLastPage] = useState(1);
	const history = useHistory();
	const roles = getRoles();
	const roleCollection = roles.includes(Roles.COLLECTION_WR);
	const roleCollectionReadOnly = roles.includes(Roles.COLLECTION_RO);

	if (!roleCollectionReadOnly && !roleCollection) {
		history.push('');
	}

	function handleCollection(e) {
		const { content, totalPages } = e;
		setCollection(content);
		setLastPage(totalPages);
	}

	return (
		<>
			{(roleCollectionReadOnly || roleCollection) &&
				<>
					<NavLink to="/campanhas" className="go-back" />
					<div className="content-body">

						<HeaderCollectionSearch roleCollection={roleCollection} pageCurrent={page} dataCollections={e => handleCollection(e)} />

						{collection && collection.length > 0 &&
							<>
								<TableCollectionSearch roleCollection={roleCollection} collections={collection} />

								<div className="search-customer-paginate">
									{lastPage > 1 &&
										<ReactPaginate
											containerClassName="pagination-ifoodshop"
											pageClassName="pagination-ifoodshop__item"
											pageLinkClassName="pagination-ifoodshop__item-link"
											activeLinkClassName="active"
											previousLabel={<img className="previous-arrow" src={IconArrow} />}
											nextLabel={<img className="next-arrow" src={IconArrow} />}
											onPageChange={e => setPage(e.selected)}
											pageRangeDisplayed={3}
											pageCount={lastPage}
											marginPagesDisplayed={1}
											initialPage={page} />}
								</div>
							</>
						}
					</div>
				</>
			}
		</>
	)
}

export default CollectionSearch;
