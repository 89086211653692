import gql from 'graphql-tag';

const CANCEL_ORDER = gql`
  mutation cancelMainOrder($orderId: ID!, $reason: String) {
    cancelMainOrder(orderId: $orderId, reason: $reason) {
      id
    }
  }
`;

const REFUND_ORDER = gql`
  mutation processOrderRefund($orderId: ID!) {
    processOrderRefund(orderId: $orderId) {
      success
    }
  }
`;

const REQUEST_CHARGEBACK = gql`
  mutation requestChargeback($orderId: ID!, $reason: String, $amount: Float) {
    requestChargeback(orderId: $orderId, reason: $reason, amount: $amount) {
      success
    }
  }
`;

const UPDATE_WEIGHT_ORDER_ITEM = gql`
  mutation updateWeightOrderItem($itemId: ID!, $variableWeightInformed: Float) {
    updateWeightOrderItem(itemId: $itemId, variableWeightInformed: $variableWeightInformed) {
      categoryId
      categoryName
      commission
      createdAt
      deliveryId
      discounts {
        id
        discountId
        orderItemId
        amount
        name
        createdAt
        updatedAt
      }
      ean
      height
      id
      length
      name
      offerId
      orderId
      productDescription
      quantity
      sellPrice
      sellerUnitPrice
      totalDiscount
      unitPrice
      updatedAt
      variableWeight
      variableWeightInformed
      variationId
      weight
      width
    }
  }
`;

const UPDATE_TOTAL_WEIGHT_ITEM = gql`
  mutation updateTotalWeightItem($itemId: ID!, $variableWeightInformed: Float) {
    updateTotalWeightItem(itemId: $itemId, variableWeightInformed: $variableWeightInformed) {
      categoryId
      categoryName
      commission
      createdAt
      deliveryId
      discounts {
        id
        discountId
        orderItemId
        amount
        name
        createdAt
        updatedAt
      }
      ean
      height
      id
      length
      name
      offerId
      orderId
      productDescription
      quantity
      sellPrice
      sellerUnitPrice
      totalDiscount
      unitPrice
      updatedAt
      variableWeight
      variableWeightInformed
      variationId
      weight
      width
      error
      errorCode
      success
      message
    }
  }
`;

const UPDATE_RUPTURE = gql`
  mutation updateRupture($itemId: ID!, $quantity: Int) {
    updateRupture(itemId: $itemId, quantity: $quantity) {
      id
      errorCode
      error
    }
  }
`;

const UPDATE_RUPTURE_LOTE = gql`
  mutation updateRuptureLote($sellerId: ID!, $input: [InputRuptureLote]) {
    updateRuptureLote(sellerId: $sellerId, input: $input)
  }
`;

export {
  UPDATE_WEIGHT_ORDER_ITEM,
  CANCEL_ORDER,
  UPDATE_RUPTURE,
  UPDATE_RUPTURE_LOTE,
  REQUEST_CHARGEBACK,
  UPDATE_TOTAL_WEIGHT_ITEM,
  REFUND_ORDER,
};
