import { OutlinedInput, Button } from 'ifoodshop-react-ui';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form'
import * as yup from "yup";

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';
import { yupResolver } from '@hookform/resolvers/yup';

import './style.scss';


function FilterFreightRangeSearch() {

    const {
    setPageSearchFreightRange,
    setFormSearch,
    setFreightRangeFilterCalled,
    freightRangeFilterCalled
  } = useCarrierFreightRangeContext();


  // schema
  const schema = yup.object().shape({
    region: yup.string(),
    postcodeStart: yup.string(),
    postcodeEnd: yup.string()
  })


  //
  // Form validation
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: {
      region: '',
      postcodeStart: '',
      postcodeEnd: ''
    }
  })


  function search(data) {
    if (!freightRangeFilterCalled) {
      setFreightRangeFilterCalled(true)
    }

    setPageSearchFreightRange(0);

    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key] == null) {
        delete data[key];
      }
    });

      setFormSearch({
        ...data,
        postcodeStart:  Number(data?.postcodeStart?.replace('-', '')),
        postcodeEnd: Number(data?.postcodeEnd?.replace('-', ''))
      });
    // }
  }

  return (
    <div className="header-customer-group header-customer-group--src header-search-freigth-range">

      <form className="header-customer-group__form" onSubmit={handleSubmit(search)}>
        <div className="header-customer-group__form-input">
        <Grid container spacing={2} className="container form-filter-freight-range" justify="space-between" alignItems="baseline">
          <Grid item xs={5}>
            <OutlinedInput name="name" {...register('region')} placeholder="Região" border />
          </Grid>
          <Grid item xs={5}>
            <OutlinedInput name="name" {...register('postcodeStart')} placeholder="Cep inicial" mask={'99999-999'} border />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput name="name" {...register('postcodeEnd')} placeholder="Cep final" mask={'99999-999'} border />
          </Grid>
        </Grid>
        </div>
        <div className="header-customer-group__actions">
          <Button type="submit">Filtrar faixas de Frete</Button>
        </div>
      </form>
    </div>
  );
}

export default FilterFreightRangeSearch;
