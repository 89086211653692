import gql from 'graphql-tag';

const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!, $active: Boolean!, $parent: Int){
    createCategory(input: {name: $name, active: $active, parentId: $parent}){
      id
      name
      active
      createdAt
      error
      errorCode
    }
  }
`;
const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: ID!, $name: String, $active: Boolean, $parent: Int, $menus: [CategoryMenuInput]){
    updateCategory(
      id: $id,
      input: {
        name: $name,
        active: $active,
        parentId: $parent,
        menus: $menus
      },
    ){
      name
      id
      active
      error
      errorCode
    }
  }
`;
export { CREATE_CATEGORY, UPDATE_CATEGORY };
