/* istanbul ignore file */
import styled, { css } from 'styled-components';

const sharedStyle = css`
  border-right: 1px solid #dcdcdc !important;
`;

const TableHeadItem = styled.th`
  ${sharedStyle}
`;

const TableContentItem = styled.td`
  ${sharedStyle}
`;

const FirstTableContentItem = styled.td`
  ${sharedStyle}
  border-left: 1px solid #dcdcdc;
`;

export { TableHeadItem, FirstTableContentItem, TableContentItem };
