import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const baseTheme = {
  palette,
  typography,
  overrides
};

export const theme = createTheme(baseTheme, ptBR);
export const themeWithRtl = createTheme({ ...baseTheme, direction: 'rtl' });
