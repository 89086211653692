import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { KeycloakError } from '@helpers/KeycloakErrors';
import { ReactComponent as CopyLink } from '@assets/icons/copyLink.svg';
import './tableSellers.scss';

const TableSellers = ({ data, role }) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  // todayDate: Data, hora, minuto, segundo
  const todayDate = new Date();

  useEffect(() => {
    if (openTooltip) setTimeout(() => setOpenTooltip(false), 1000);
  }, [openTooltip]);

  const copyLink = e => {
    e.preventDefault();

    const targetLink = document.querySelector('#pwLinkTemporary').href;

    if (navigator.clipboard.writeText(targetLink)) {
      setOpenTooltip(true);
    } else {
      setOpenTooltip(false);
    }
  };

  // Obs: Implementação futura para exibição de erros, no momento estão sendo capturados no console
  const returnErrorMessages = (sellerId, name, error) => {
    if (error === 'KEYCLOAK_ERROR') {
      console.log(`sellerId: ${sellerId}, name: ${name}, error: ${KeycloakError.KEYCLOAK_ERROR}`);
    } else if (error === 'KEYCLOAK_CONFLICT_ERROR') {
      console.log(`sellerId: ${sellerId}, name: ${name}, error: ${KeycloakError.KEYCLOAK_CONFLICT_ERROR}`);
    } else if (error === 'PW_PUSH_ERROR') {
      console.log(`sellerId: ${sellerId}, name: ${name}, error: ${KeycloakError.PW_PUSH_ERROR}`);
    }
  };

  return (
    <>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">ID</th>
            <th className="table-admin__title">Fornecedor</th>
            <th className="table-admin__title">Estado</th>
            <th className="table-admin__title">Detalhes</th>
            <th className="table-admin__title">Comissão</th>
            <th className="table-admin__title">Dashboard</th>
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {data
            ? data.content.map(seller => (
                <tr id={seller.id} key={seller.id}>
                  <td className="table-admin__data">{seller.id}</td>
                  <td className="table-admin__data">{seller.name}</td>
                  <td className="table-admin__data">{seller.active === false ? 'Desabilitado' : 'Habilitado'}</td>

                  <td className="table-admin__data">
                    {role.roleCadSellerRO ? (
                      <NavLink className="link" to={`/fornecedores/detalhe/${seller.id}`}>
                        Abrir
                      </NavLink>
                    ) : (
                      <a className="link--disabled">Abrir</a>
                    )}
                  </td>

                  <td className="table-admin__data">
                    {returnErrorMessages(seller.userKeycloakInfo.entityId, seller.name, seller.userKeycloakInfo.status)}
                    {role.roleCommissionWR ? (
                      <NavLink className="link" to={`/fornecedores/${seller.id}/comissoes/`}>
                        Abrir
                      </NavLink>
                    ) : (
                      <a className="link--disabled">Abrir</a>
                    )}
                  </td>
                  <td className="table-admin__data">
                    {role.roleCommissionWR ? (
                      <NavLink className="link" to={`/fornecedores/dashboard/${seller.id}`}>
                        Abrir
                      </NavLink>
                    ) : (
                      <a className="link--disabled">Abrir</a>
                    )}
                  </td>

                  <td className="table-admin__data">
                    {role.roleCommissionWR &&
                    seller.userKeycloakInfo.entityId &&
                    seller.userKeycloakInfo.status === 'CREATED' &&
                    new Date(seller.userKeycloakInfo.expiresAt) >= todayDate ? (
                      <a
                        href={seller.userKeycloakInfo.pwLink}
                        id="pwLinkTemporary"
                        className="link"
                        onClick={e => copyLink(e)}
                      >
                        Copiar senha temporária
                      </a>
                    ) : null}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>

      {openTooltip && (
        <span id="link-copy-info">
          <CopyLink />
        </span>
      )}
    </>
  );
};

export default TableSellers;
