import styled from 'styled-components';

const Table = styled.table.attrs({ className: 'table-admin table-admin--sample' })`
  min-width: unset;
  width: 100%;
`;
const Th = styled.th.attrs({ className: 'table-admin__title' })``;
const Td = styled.td.attrs({ className: 'table-admin__data' })``;

export const TableAdmin = {
  Table,
  Th,
  Td,
};
