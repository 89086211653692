import ModalForm from '@components/ModalForm';
import { useCustomerFraudModalsHook } from '../customerFraudModalsHook';
import * as S from '../CustomerFraudReportModal/CustomerFraudReportModal.styles';
import { useEffect } from 'react';

interface IModalFormProps {
  isOpen: boolean;
  onFinish: () => void;
  onClose: () => void;
}

const CustomerFraudFinishModal = ({ isOpen, onClose, onFinish }: IModalFormProps): JSX.Element => {
  const { formReportFraud } = useCustomerFraudModalsHook();

  useEffect(() => {
    if (!isOpen) {
      formReportFraud.reset();
    }
  }, [formReportFraud, isOpen]);

  const handleClickReport = () => {
    onFinish();
    onClose();
  };

  return (
    <ModalForm isOpen={isOpen} modalProps={{ size: '655px' }} title="Concluir bloqueio por fraude?" onClose={onClose}>
      <S.Text>
        Ao realizar o bloqueio, o cliente não poderá realizar compras na plataforma, entre em contato com o mesmo para
        entender o ocorrido e realizar um acordo para reativa-lo
      </S.Text>

      <S.ButtonsRow alignItems="center" justifyContent="flex-end">
        <S.ButtonCancel name="close" variant="secondary" onClick={onClose}>
          Fechar
        </S.ButtonCancel>
        <S.ButtonSave onClick={handleClickReport}>Concluir bloqueio</S.ButtonSave>
      </S.ButtonsRow>
    </ModalForm>
  );
};

export default CustomerFraudFinishModal;
