/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSellerPayload } from '@pages/Partners/Seller/hooks/sellerPayloadHook';
import { EditSellerForm } from './EditForm';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSellerDetailsData } from '@pages/Partners/Seller/SellerDetails/SellerDetailsHook';
import schema from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { api } from '@services/api';
import { snackbar } from '@ifood/pomodoro-components';
import { useUpdateSellerV2Mutation } from '@services/bff/GraphqlHooks';

export function useEditSellerHook() {
  const { getEditSellerPayload } = useSellerPayload();
  const [updateSeller] = useUpdateSellerV2Mutation({
    onCompleted: () => {
      snackbar({
        message: 'Salvo com sucesso!',
        variant: 'success',
        timeout: 5000,
        autoClose: true,
      });
    },
    onError: () => onEditError(),
  });

  const [isUpdatingSeller, setIsUpdatingSeller] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { sellerResponse, getPhoneNumberWithDDD } = useSellerDetailsData();
  const sellerData = sellerResponse?.getSellerV2;
  const form = useForm<EditSellerForm>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {},
  });
  const urlDetails = `/fornecedores/detalhe/${params.id}`;
  const sellerLogo = form.watch('logo');

  useEffect(() => {
    if (sellerData) {
      form.reset({
        ...sellerData,
        ...sellerData.address,
        ...sellerData.bankAccount,
        ...sellerData.user,
        email: sellerData.email,
        comercialName: sellerData.user?.name,
        comercialEmail: sellerData.user?.email,
        sellerName: sellerData.name,
        segment: sellerData?.type?.name,
        commission: sellerData?.commission || '',
        minimumOrderPrice: sellerData?.minimumOrderPrice || '',
        integrated: sellerData.apiIntegration ? 'yes' : 'no',
        isTestMerchant: sellerData.isTestMerchant ? 'yes' : 'no',
        isNewIntegration: sellerData.isNewIntegration ? 'yes' : 'no',
        merchantId: sellerData?.merchantId || '',
        frnId: sellerData?.frnId || '',
        mobilePhone:
          sellerData?.user?.mobilePhone &&
          getPhoneNumberWithDDD(sellerData?.user?.mobilePhone, sellerData?.user?.dddMobilePhone),
        cellPhone:
          sellerData?.user?.cellPhone &&
          getPhoneNumberWithDDD(sellerData?.user?.cellPhone, sellerData?.user?.dddCellPhone),
      } as unknown as EditSellerForm);
    }
  }, [form, sellerData]);

  const onSubmit: SubmitHandler<EditSellerForm> = async data => {
    setIsUpdatingSeller(true);

    const hasNewLogo = sellerLogo && typeof sellerLogo !== 'string';
    const hasDeletedLogo = !sellerLogo && sellerData?.logo;

    const sellerV2 = getEditSellerPayload(data, {
      sellerId: sellerData?.id as number,
      addressId: sellerData?.address?.id as number,
      userId: sellerData?.user?.id as number,
    });

    await updateSeller({ variables: { sellerV2 } });

    if (hasNewLogo) {
      uploadLogo();
    } else if (hasDeletedLogo) {
      deleteLogo();
    } else {
      setIsUpdatingSeller(false);
      redirectToDetails();
    }
  };

  async function uploadLogo() {
    if (sellerLogo?.file) {
      const formData = new FormData();
      formData.append('logo', sellerLogo.file);

      try {
        await api.post(`seller/logo/internal/${params.id}`, formData);
      } catch (error) {
        onEditError('Erro ao enviar logo, por favor tente novamente');
      }
    }

    setIsUpdatingSeller(false);
    redirectToDetails();
  }

  async function deleteLogo() {
    setIsUpdatingSeller(false);
    try {
      await api.delete(`seller/logo/internal/${params.id}`);
    } catch (error) {
      onEditError('Erro ao excluir logo, por favor tente novamente');
    }

    redirectToDetails();
  }

  function redirectToDetails() {
    history.push({
      pathname: urlDetails,
      state: { success: true },
    });
  }

  function onEditError(message?: string) {
    setIsUpdatingSeller(false);

    snackbar({
      message: message || 'Erro ao editar seller, por favor tente novamente.',
      variant: 'error',
      timeout: 4000,
      autoClose: true,
    });
  }

  return {
    onSubmit,
    sellerData,
    form,
    redirectToDetails,
    urlDetails,
    isUpdatingSeller,
    onEditError,
  };
}
