import { useEffect } from 'react';
import { NavLink } from 'react-router-dom'

import HeaderForm from '../../components/HeaderForm';
import FormFreightRange from '../../components/FormFreightRange';

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

function EditFreightRange() {
  const {getFreightRangeByCarrierId, getCarrierById, dataCarrier, carrierId} = useCarrierFreightRangeContext();

  useEffect(() => {
    getFreightRangeByCarrierId();

    if(!dataCarrier && carrierId){
      getCarrierById()
    }

  }, []);

  return (
    <>
      <NavLink to="/fornecedores/transportadoras" className="go-back" />
      <div className="content-body">
        <HeaderForm />
        <FormFreightRange />
      </div>
    </>
  );
}

export default EditFreightRange;