import React, {useState} from 'react';
import FormRegisterDiscountV2 from '../components/FormRegisterDiscount/FormRegisterDiscountV2'
import { Modal, Button } from 'ifoodshop-react-ui';
import { NavLink } from 'react-router-dom'

import Roles from '../../../../helpers/Roles'
import { getRoles } from "../../../../helpers/rolesUtils";

const DiscountClone = (props) => {

  var cloneId = parseInt(props.match.params.cloneId);

  var tokenDecodedUserName = null;

  if (props.keycloak)
    tokenDecodedUserName = props.keycloak.tokenParsed.preferred_username;

  const [modal, setModal] = useState(null)

  const roles = getRoles();
  const roleDiscount = roles.includes(Roles.DISCOUNT_WR);
  const roleDiscountReadOnly = roles.includes(Roles.DISCOUNT_RO);

  if (!roleDiscountReadOnly && !roleDiscount) {
    window.location.href = '/'
  }

  return(
    <>
      {(roleDiscountReadOnly || roleDiscount) &&
        <>
        <NavLink to="/campanhas/descontos" className="go-back" />
        {roleDiscount
          ?
            <>
              <div className="content-body discount">
                <h2 className="section-title">Duplicação de desconto</h2>
                <p className="description-form">Use os campos abaixo para duplicar o desconto</p>

                <FormRegisterDiscountV2 cloneId={cloneId} userName={tokenDecodedUserName} role={roleDiscount}/>
              </div>
              {modal}
            </>
          :
            <>
              <div className="content-body discount">
                <h2 className="section-title">Duplicação de desconto</h2>
                <p className="description-form">Você não tem permissão para duplicar um desconto</p>
              </div>
            </>
        }
        </>
      }
    </>
  )
}

export default DiscountClone;
