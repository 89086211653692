import React, {Fragment} from 'react';
import {NavLink} from "react-router-dom";
import './tableBrand.scss';


const tableBrand = (props) => {
    const {brands, roles} = props;
    const { roleBrandWriter,roleBrandReader } = roles;

    return(
      <Fragment>
        {
          brands.length > 0 ?
            <>
              <table className="table-admin table-admin--sample">
                <thead>
                <tr>
                  <th className="table-admin__title">ID</th>
                  <th className="table-admin__title">Nome</th>
                  <th className="table-admin__title">Estado</th>
                  <th className="table-admin__title"></th>
                </tr>
                </thead>
                <tbody>
                {brands.map(brand =>(
                  <tr id={brand.id} key={brand.id}>
                    <td className="table-admin__data">{brand.id}</td>
                    <td className="table-admin__data">{brand.name}</td>
                    <td className="table-admin__data">{ brand.active ? <a> Habilitado</a> : <a>Desabilitado</a> } </td>
                    <td className="table-admin__data">
                      <NavLink
                        className={ roleBrandWriter ? "link" : "link--disabled" }
                        to={roleBrandWriter ? `/catalogos/marcas/editar/${brand.id}` : '#'}
                      >
                        Editar
                      </NavLink>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </>
            :
            null
        }
      </Fragment>
    )
}
export default tableBrand;
