import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './index.scss'

const FilterCustomerTable = ({customerGroups }) =>{
  return(
    <Fragment>
      <h3 className="section-title title-table-groups">Resultados do filtro</h3>
      <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title table-admin__title-name">Grupos de clientes</th>
            <th className="table-admin__title">Estado</th>
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {
            customerGroups.map(group => {
              return (
                <tr id={group.id} key={group.id}>
                  <td className="table-admin__data">{group.name}</td>
                  <td className="table-admin__data">{group.active ? "Habilitado" : "Desabilitado"}</td>
                  <td className="table-admin__data"><NavLink to={"/clientes/grupos-clientes/detalhe/"+group.id} className="link">Abrir</NavLink></td>
                </tr>
              )
            })
          }

        </tbody>
      </table>
    </Fragment>
  );
}

export default FilterCustomerTable;
