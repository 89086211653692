import { Button, Dialog, Heading3, LinkText, snackbar } from '@ifood/pomodoro-components';
import { useSellerResetPasswordMutation } from '@services/bff/GraphqlHooks';
import { useState } from 'react';
import styled from 'styled-components';

export const CustomDialog = styled(Dialog)`
  max-width: none;
  width: 40%;
`;

interface IResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  sellerId: number;
}

const ResetPasswordModal = ({ isOpen, onClose, sellerId }: IResetPasswordModalProps): JSX.Element => {
  const [resetPassword] = useSellerResetPasswordMutation();
  const [newPasswordLink, setNewPasswordLink] = useState<string>();

  async function onResetPassword() {
    const { data } = await resetPassword({ variables: { sellerId } });

    setNewPasswordLink(data?.sellerResetPassword?.pwLink);
  }

  function onCopyPassword() {
    try {
      navigator.clipboard.writeText(newPasswordLink || '');
      onClose();
      snackbar({
        message: 'Link copiado com sucesso!',
        variant: 'success',
        timeout: 4000,
        autoClose: true,
      });
    } catch (error) {
      snackbar({
        message: 'Erro ao copiar o link, por favor tente novamente.',
        variant: 'error',
        timeout: 4000,
        autoClose: true,
      });
    }
  }

  return (
    <CustomDialog open={isOpen} onClose={onClose}>
      <Dialog.Header>
        <Heading3 my={2} variant="medium">
          Deseja resetar senha de login?
        </Heading3>
      </Dialog.Header>
      <Dialog.Body>
        Ao resetar a senha, um link temporario é gerado e deve ser enviado ao fornecedor para o cadastrar uma nova senha
        de acesso.
        {newPasswordLink && (
          <p>
            <LinkText href={newPasswordLink} target="_blank">
              {newPasswordLink}
            </LinkText>
          </p>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Button marginRight={16} variant="secondary" onClick={onClose}>
          Fechar
        </Button>

        {!newPasswordLink && (
          <Button variant="primary" onClick={onResetPassword}>
            Resetar Senha
          </Button>
        )}

        {newPasswordLink && (
          <Button variant="primary" onClick={onCopyPassword}>
            Copiar Link
          </Button>
        )}
      </Dialog.Footer>
    </CustomDialog>
  );
};

export default ResetPasswordModal;
