import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_PENDING_ORDERS } from "@graphql/order.queries";
import IconArrow from "@assets/images/arrow.png";
import Formatter from "@helpers/FormatHelper";
import Grid from "@material-ui/core/Grid";
import ReactPaginate from "react-paginate";
import Highlight from "../Highlight";
import TableOrderSearch from "@pages/Order/components/TableOrderSearch";


export default function TableOrderDashboard({
  finalDate,
  initialDate,
  sellerId,
  rolesOrder,
}) {
  const [page, setPage] = useState(0);
  const [allOrders, setAllOrders] = useState(null);
  const orderStatusList = [
    "PAYMENT_ACCEPTED",
    "IN_STOCK_SEPARATION",
    "BILLED",
    "IN_TRANSPORTATION",
    "WAITING_PICKUP",
  ];

  useEffect(() =>{ 
    setPage(0)
    if(initialDate && finalDate) getPendingOrders()
  }, [initialDate, finalDate]);


 
  const [getPendingOrders, { data: dataGetPendingOrders }] = useLazyQuery(GET_PENDING_ORDERS, {
    fetchPolicy: "no-cache",
    variables: {
      sellerId: parseInt(sellerId) || null,
      pageRequest: page,
      startDate: initialDate ? Formatter.format(initialDate, "date-formated") : null,
      endDate: finalDate ? Formatter.format(finalDate, "date-formated") : null,
      orderStatusList: [...orderStatusList],
    },
    onCompleted: () => {
      setAllOrders(dataGetPendingOrders.getPendingOrders);
    },
  });

  return (
    <>
      {allOrders?.content?.length > 0 ? (
        <div className="table-box">
          <TableOrderSearch
            rolesOrder={rolesOrder}
            orders={allOrders?.content}
          />
          {allOrders.totalPages > 1 && (
            <div className="search-brand-paginate">
              <ReactPaginate
                containerClassName="pagination-ifoodshop"
                pageClassName="pagination-ifoodshop__item"
                pageLinkClassName="pagination-ifoodshop__item-link"
                activeLinkClassName="active"
                previousLabel={
                  <img
                    alt="next-arrow"
                    className="previous-arrow"
                    src={IconArrow}
                  />
                }
                nextLabel={
                  <img
                    alt="next-arrow"
                    className="next-arrow"
                    src={IconArrow}
                  />
                }
                onPageChange={(e) => setPage(e.selected)}
                pageRangeDisplayed={3}
                pageCount={allOrders.totalPages}
                marginPagesDisplayed={1}
                initialPage={0}
                forcePage={allOrders.number}
              />
            </div>
          )}
        </div>
      ) : (
        <Grid item xs={12} className="dashboard__grid-item">
          <Highlight
            className="dashboard__card"
            value={"Não há pedidos pendentes!"}
            icon="happy"
            variant="success"
          />
        </Grid>
      )}
    </>
  );
}
