import { useEffect, useState } from 'react';
import { Alert, Button, Dialog, Heading3, Upload, Text, LinkText } from '@ifood/pomodoro-components';
import { FileWithPath } from 'file-selector';
import { DocumentOutlined, SyncOutlined } from '@ifood/pomodoro-icons';
import * as S from './style';

type Props = {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (d: FormData) => void;
  title: string;
  uploadFileProps: {
    accept: string;
    maxFiles: number;
    multiple: boolean;
  };
};

const FileUpload = ({ isOpen, onClose, onSubmit, loading, title, uploadFileProps }: Props): JSX.Element => {
  const FIRST_FILE = 0;

  const [file, setFile] = useState<FileWithPath | null>(null);

  const handleSubmit = () => {
    if (loading) return;
    const formData = new FormData();
    if (!file) return;
    formData.append('file', file);

    onSubmit(formData);
  };

  useEffect(() => {
    if (!isOpen) setFile(null);
  }, [isOpen]);

  return (
    // @ts-ignore
    <Dialog open={isOpen} size="xl" onClose={onClose}>
      <Dialog.Header>
        <Heading3 variant="medium">{title}</Heading3>
      </Dialog.Header>
      <Dialog.Body>
        {file ? (
          <S.Feedback title="Arquivo selecionado com sucesso" variant="success">
            <Alert.Description style={{ width: '100%' }}>
              <S.AlertContent>
                <span>
                  <DocumentOutlined fontSize={16} />
                  <Text> {file.path} </Text>
                </span>

                <span>
                  <LinkText onClick={() => setFile(null)}>Substituir arquivo</LinkText>
                  <SyncOutlined color="primary" />
                </span>
              </S.AlertContent>
            </Alert.Description>
          </S.Feedback>
        ) : (
          <Upload
            description="Arraste e solte o(s) arquivo(s) no formato .csv aqui ou selecione arquivo(s) do seu dispositivo"
            uploadConfig={uploadFileProps}
            onUploadChange={acc => setFile(acc[FIRST_FILE].file)}
          />
        )}
      </Dialog.Body>

      <Dialog.Footer>
        <Button isLoading={loading} onClick={handleSubmit}>
          Enviar arquivo
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default FileUpload;
