import Decoder from '@helpers/Decoder';
import Roles from '@helpers/Roles';
import React from 'react';
import { NavLink } from 'react-router-dom'
import FormRegisterCollection from '../components/FormRegisterCollection'


const CollectionRegister = (props) =>  {

  var tokenDecodedUserName = null;

  if (props.keycloak)
    tokenDecodedUserName = props.keycloak.tokenParsed.preferred_username;

  const tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  const roleCollectionWrite = tokenDecoded.resource_access["manager"].roles.includes(Roles.COLLECTION_WR);
  const roleCollectionReadOnly = tokenDecoded.resource_access["manager"].roles.includes(Roles.COLLECTION_RO);

  if(!roleCollectionWrite && !roleCollectionReadOnly) {
    window.location.href = '/'
  }


  return(
    <>
      {(roleCollectionWrite || roleCollectionReadOnly) &&
        <>
          <NavLink to="/campanhas/colecoes" className="go-back" />
          {roleCollectionWrite
            ?
              <div className="content-body">
                <h2 className="section-title">Cadastro de coleções</h2>
                <p className="description-form">Use os campos abaixo para criar uma nova coleção</p>

                <FormRegisterCollection userName={tokenDecodedUserName}/>
              </div>
            :
              <div className="content-body">
                <h2 className="section-title">Cadastro de coleções</h2>
                <p className="description-form">Você não tem permissão para criar uma coleção</p>
              </div>
          }
        </>
      }
    </>
  )
}

export default CollectionRegister;