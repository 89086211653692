import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Button } from 'ifoodshop-react-ui'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import _ from "lodash";

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext'

import { useLazyQuery } from '@apollo/react-hooks';
import { VALIDATION_CNPJ } from '../../../../graphql/seller.queries';

import './style.scss';


 const carrierPeriods =  [
  {
    selected: false,
    title: "Manhã",
    startTime: 480,
    endTime: 720
  },
  {
    selected: false,
    title: "Tarde",
    startTime: 720,
    endTime: 1080
  },
  {
    selected: false,
    title: "Noite",
    startTime: 1080,
    endTime: 1320
    }
  ]



function FormCarrier() {

  const [periods, setPeriods] = useState(carrierPeriods);
  const [periodsInitialState, setPeriodsInitialState] = useState(carrierPeriods);
  const [optionType, setOptionType] = useState([])
  const [errorColorCode, setErrorColorCode] = useState('error-class')
  const [errorColorUtil, setErrorColorUtil] = useState('error-class')

  const {
    dataCarrier,
    carrierId,
    createCarrier,
    updateCarrier,
    getSellerByName,
    dataSellers,
    getSellerById,
    isEdit,
    roleSellerWriteRead,
    roleSellerReadOnly,
    userSellerId,
    dataCarrierDeliveryType
  } = useCarrierFreightRangeContext();


  //
  // SCHEMA
  const schema = yup.object().shape({
    document: yup
      .string()
      .required('CNPJ é obrigatório'),
    sellerName: yup
      .string()
      .required('Fornecedor é obrigatório')
      .test('slect-option-seller',
      'Selecione um fornecedor válido',
      (value) => dataCarrier?.getCarrierById?.seller?.name === value ||
      (dataSellers?.getSellerByName.some(element => element.name === value))),
    name: yup
      .string()
      .required('Nome é obrigatório'),
    code:yup.object().shape({
        value: yup.string().required(),
        label:  yup.string().required()
      }),
    owner: yup
      .string(),
    mobilePhone: yup
      .string()
      .matches(/.{15,}/, {
        excludeEmptyString: true,
        message: 'Celular inválido',
      }),
    email: yup
      .string()
      .email('Formato de email inválido'),
    phone: yup
    .string()
    .matches(/.{14,}/, {
      excludeEmptyString: true,
      message: 'Telefone inválido',
    }),
    branch: yup.string(),
    cubingFactor: yup.object(),
    active: yup.object(),
    notes: yup.string(),
    stateRegistration: yup
    .string()
        .when("code", {
           is: field => field?.label === "Correios" || field?.label === "Retirada",
           then: yup.string().required('Inscrição Estadual é obrigatório'),
           otherwise: yup.string()
        }),
    cityRegistration: yup.string(),
    sellerId: yup.number(),
    address: yup.object().when("code", {
      is: field => field?.label === "Correios" || field?.label === "Retirada",
      then: yup.object().shape({
        postalCode: yup
        .string()
        .required('CEP é obrigatório')
        .nullable(),
        address: yup.string()
        .required('Logradouro é obrigatório')
        .nullable(),
        number: yup.string()
        .required('Número é obrigatório')
        .nullable(),
        complement: yup.string(),
        neighborhood: yup.string()
        .required('Bairro é obrigatório')
        .nullable(),
        city: yup.string()
        .required('Cidade é obrigatório')
        .nullable(),
        state: yup.string()
        .required('Estado é obrigatório')
        .nullable(),
      }),
    }),
    utils:yup.object().when("code", {
      is: field => field?.label === "Correios",
      then: yup.object().shape({
        value: yup.string().required(),
        label:  yup.string().required()
      }),
      otherwise: yup.object()
   }),
    site: yup.string()
  })

  //
  // QUERIES
  const [validationCnpj, { loading }] = useLazyQuery(VALIDATION_CNPJ, {
    onCompleted: data => {
      const { errorCode } = data?.getSellerValidationCnpj;

      setValue('address.postalCode', data?.getSellerValidationCnpj?.zipcode);
      setValue('address.address', data?.getSellerValidationCnpj?.street);
      setValue('address.number', data?.getSellerValidationCnpj?.addressNumber);
      setValue('address.complement', data?.getSellerValidationCnpj?.addressComplement);
      setValue('address.neighborhood', data?.getSellerValidationCnpj?.neighbourhood);
      setValue('address.city', data?.getSellerValidationCnpj?.city);
      setValue('address.state', data?.getSellerValidationCnpj?.uf);

      if (errorCode === 'SL0008')
        setError('document', {
          message: 'CNPJ inválido'
        });
    },
    onError: () => {
      setError('document', {
        message: 'CNPJ inválido'
      });
    }
  });

  //
  // FORM VALIDATION
  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    trigger,
    watch,
    formState: { errors, dirtyFields, touchedFields }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      sellerId: userSellerId,
      active: !isEdit && {
        value: true,
        label: "Sim"
      },
      utils: {
        value: '',
        label: 'Selecione'
      },
      carrierPeriods: periods,
      sellerName: '',
      phone:'',
      mobilePhone: '',
      cubingFactor: !isEdit && {
        value: 300,
        label: "300"
      },
      code: '',
      document: ''
    }
  });
  const codeValue = watch(["code"]);
  const utilsValue = watch(["utils"]);

  useEffect(() => {

    if (dataCarrierDeliveryType?.getCarrierDeliveryType !== undefined) {

      const codeOption = dataCarrierDeliveryType?.getCarrierDeliveryType

      setOptionType(
          [
            { value: Object.values(codeOption)[0], label: "Próprio" },
            { value: Object.values(codeOption)[1], label: "Ifood" },
            { value: Object.values(codeOption)[2], label: "Terceiros" },
            { value: Object.values(codeOption)[3], label: "Retirada" },
            { value: Object.values(codeOption)[4], label: "Correios" },
            { value: Object.values(codeOption)[5], label: "Teste" },
            { value: Object.values(codeOption)[6], label: "Fullservice" }
          ]
        )
    }

    if (dataCarrier && isEdit) {
      const carrier = dataCarrier?.getCarrierById;
      const varPeriodsSelected = carrier?.carrierPeriods;
      const newArray = periods?.reduce((acc, period) => {
        const aux = varPeriodsSelected?.find(defaultPeriod => defaultPeriod.title === period.title && defaultPeriod.endTime === period.endTime && defaultPeriod.startTime === period.startTime &&
            defaultPeriod?.carrierId === carrierId)
        if(aux){
          return [
            ...acc, {
            ...aux,
            selected: true
          }]
        }
        return [...acc, period]

      },[]);

      const carrierOnlyString = Object.keys(carrier).reduce((acc, key) => {acc[key] = carrier[key] === null ? '' : carrier[key]; return acc; }, {})
      const deliveryTypeInserted  = optionType.find((item) => item.value.toLowerCase() === carrierOnlyString.code.toLowerCase())

      setPeriods(newArray)
      setPeriodsInitialState(newArray)
      getSellerById({variables: {id: carrier?.seller?.id}})
      setValue('sellerId', carrierOnlyString.seller?.id)
      setValue('sellerName', carrierOnlyString.seller?.name)
      setValue('name', carrierOnlyString.name)
      if (deliveryTypeInserted) {
        setValue('code', deliveryTypeInserted)
      }

      setValue('owner', carrierOnlyString.owner)
      setValue('mobilePhone', carrierOnlyString.mobilePhone ?? '')
      setValue('email', carrierOnlyString.email)
      setValue('phone', carrierOnlyString.phone ?? '')
      setValue('branch', carrierOnlyString.branch ?? '')
      setValue('cubingFactor',
                carrierOnlyString.cubingFactor === 300 ||
                carrierOnlyString.cubingFactor === 167 ?
                {
                  value: carrierOnlyString.cubingFactor,
                  label: carrierOnlyString.cubingFactor.toString()
                }
                :
                {
                  value: 300,
                  label: '300'
                }
              )
      setValue('notes', carrierOnlyString.notes)
      setValue('carrierPeriods', newArray)
      setValue('document', carrierOnlyString.document)
      setValue('stateRegistration', carrierOnlyString.stateRegistration)
      setValue('cityRegistration', carrierOnlyString.cityRegistration)
      setValue('address.id', carrierOnlyString.address?.id);
      setValue('address.carrierId', carrierOnlyString.address?.carrierId);
      setValue('address.postalCode', carrierOnlyString.address?.postalCode);
      setValue('address.address', carrierOnlyString.address?.address);
      setValue('address.number', carrierOnlyString.address?.number);
      setValue('address.complement', carrierOnlyString.address?.complement);
      setValue('address.neighborhood', carrierOnlyString.address?.neighborhood);
      setValue('address.city', carrierOnlyString.address?.city);
      setValue('address.state', carrierOnlyString.address?.state);
      setValue('site', carrierOnlyString.site);
      setValue('active', {
        value: carrierOnlyString.active,
        label: carrierOnlyString.active ? 'Sim' : 'Não'
      })
      setValue('utils', {
        value: carrierOnlyString.utils,
        label: carrierOnlyString.utils === '' ? 'Selecione' : (carrierOnlyString.utils === 'br.com.stoom.ifood.util.CepUtils$CorreioPacUtils' ? 'PAC' : 'Sedex')
      })
      trigger()
    }

    if(!isEdit){
      setErrorColorCode('')
    }

  }, [dataCarrier, dirtyFields, dataCarrierDeliveryType])

  useEffect(() => {
    if (codeValue.some(item => item.value)) {
      setErrorColorCode('')
    }

    if (utilsValue[0]?.label === 'Selecione' && codeValue[0]?.label === 'Correios') {
      setErrorColorUtil('error-class')
    }else{
      setErrorColorUtil('')
    }
  }, [isEdit, codeValue, utilsValue, errorColorUtil, errorColorCode]);

  useEffect(() => {
    trigger('code');
  }, [watch('code')]);

  useEffect(() => {
    trigger('utils');
  }, [watch('utils')]);

  useEffect(() => {
    if (touchedFields.document) {
      trigger('document');
    }
  }, [watch('document')]);

  useEffect(() => {
    if (getValues('sellerName') !== '') {
      trigger('sellerName');
    }
  }, [watch('sellerName')]);

  const validateCnpj = (value) => {

    const rawCnpjNumber = value.replaceAll(/\D/g, '')

    if(rawCnpjNumber.length === 14) {
      validationCnpj({ variables: { document: rawCnpjNumber } })
    }
  }

  const onSelectSellerHandleField = (e) => {

    getSellerByName({ variables: { name: e.target.value } });

    setValue('sellerName', e.target.value);
  }

  const onChangeSellerHandleField = (newValue) => {

    getSellerById({variables: {id: newValue?.id}})

    if (newValue) {
      setValue('sellerId', newValue.id);
      setValue('sellerName', newValue.name);
    } else {
      setValue('sellerId', null);
      setValue('sellerName', '');
    }
  }

  const onErrorSubmit = (err) => {
    if(err.code) {
      setErrorColorCode('error-class')
    }
  }

  const onSubmit = (data) => {

   delete data['sellerName']

   const valueConditionToSend = data?.code?.value === 'PICK_UP' ||  data?.code?.value === 'CORREIOS'

   if(!isEdit) {
    const carrierPeriods = periods?.filter(item => item.selected).reduce((acc, {title, endTime, startTime}) => [
      ...acc, {
        title, endTime, startTime
      }
    ], [])

    createCarrier({variables: {
      input: {
        ...data,
        carrierPeriods,
        code: data?.code?.value,
        cubingFactor: Number(data?.cubingFactor?.value),
        pickup: data?.code?.value === 'PICK_UP' ? true : false,
        active: data?.active?.value,
        utils: data?.utils?.value && (valueConditionToSend) ? data?.utils?.value : null,
        printBatchScheduleLabels: true,
        stateRegistration: data?.stateRegistration && (valueConditionToSend) ? data?.stateRegistration : '',
        cityRegistration: data?.cityRegistration && (valueConditionToSend) ? data?.cityRegistration : '',
        address: data?.address && (valueConditionToSend) ? data?.address : {},
        site: data?.site && (valueConditionToSend) ? data?.site : '',
      }
    }})
    } else {
       const carrierPeriods = periods?.filter(item => item.selected).reduce((acc, {title, endTime, startTime, id, carrierId: carrier}) => [
      ...acc, {
        title, endTime, startTime, id: id || null, carrierId: carrier || carrierId
      }
    ], [])

       updateCarrier({variables: {
         input: {
           ...data,
           id: carrierId,
           carrierPeriods,
           code: data?.code?.value,
           cubingFactor: Number(data?.cubingFactor?.value),
           pickup: data?.code?.value === 'PICK_UP' ? true : false,
           active: data?.active?.value,
           utils: data?.utils?.value && (valueConditionToSend) ? data?.utils?.value : null,
           printBatchScheduleLabels: true,
           stateRegistration: data?.stateRegistration && (valueConditionToSend) ? data?.stateRegistration : '',
           cityRegistration: data?.cityRegistration && (valueConditionToSend) ? data?.cityRegistration : '',
           address: data?.address && (valueConditionToSend) ? data?.address : {},
           site: data?.site && (valueConditionToSend) ? data?.site : '',
         }
       }})
     }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onErrorSubmit)} className="form-carrier">
        <Grid container className="container address" spacing={2}>
          <Grid item xs={12} >
            <h3 className="form-subtitle">Informações principais</h3>
          </Grid>
          <Grid item xs={12}>
              <div className="dropdown-label-wrap">
                <Controller
                  name="sellerName"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                    id="seller"
                    className="dropdown-ifood"
                    popupIcon={<span className="dropdown-ifood__arrow" />}
                    noOptionsText="Sem resultados"
                    options={dataSellers?.getSellerByName || []}
                    getOptionLabel={(option) => option.name}
                    inputValue={getValues('sellerName')}
                    getOptionSelected={(option) => option.name }
                    disabled={isEdit}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fornecedor"
                        variant="outlined"
                        {...field}
                      />
                    )}
                    onSelect={onSelectSellerHandleField}
                    onChange={(_event, value) => onChangeSellerHandleField(value)}
                    />
                    )
                  }
                />
                {errors.sellerName && <p className="label-error">{errors.sellerName.message}</p>}
              </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput border {...register("name")} placeholder="Nome" />
            {errors.name && <p className="label-error">{errors.name.message}</p>}
          </Grid>
          <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <div className="dropdown-label-wrap">
                      <Dropdown
                      options={optionType}
                      {...field}
                      placeholder="Selecione um código:"
                      className={`dropdown-ifood ${errorColorCode}`}
                      controlClassName="dropdown-ifood__control"
                      arrowClassName="dropdown-ifood__arrow"
                      />

                    <label className="dropdown-label">Código</label>
                  </div>
                  )
                }
              />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput border {...register("owner")} placeholder="Contato" />
            {errors.owner && <p className="label-error">{errors.owner.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <Controller
                name="mobilePhone"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    border
                    {...field}
                    placeholder="DDD + Celular"
                    mask={"(99) 99999-9999"}
                 />
                )
              }
            />
            {errors.mobilePhone && <p className="label-error">{errors.mobilePhone.message}</p>}
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput border {...register("email")} placeholder="E-mail" />
            {errors.email && <p className="label-error">{errors.email.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  border
                  {...field}
                  placeholder="Telefone"
                  mask={'(99) 9999-9999'}
                />
                )
              }
            />
            {errors.phone && <p className="label-error">{errors.phone.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput border {...register("branch")} placeholder="Ramal" mask={"9999999999"} />
            {errors.branch && <p className="label-error">{errors.branch.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="cubingFactor"
              control={control}
              render={({ field }) => (
                <div className="dropdown-label-wrap">
                  <Dropdown
                    options={[
                      { value: 300, label: "300" },
                      { value: 167, label: "167" },
                    ]}
                    {...field}
                    placeholder="Fator de cubagem"
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    arrowClassName="dropdown-ifood__arrow"
                  />
                  <label className="dropdown-label">Fator de cubagem</label>
                </div>)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <div className="dropdown-label-wrap">
                  <Dropdown
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" },
                    ]}
                    {...field}
                    placeholder="Ativo"
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    arrowClassName="dropdown-ifood__arrow"
                  />
                  <label className="dropdown-label">Ativo</label>
                </div>)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput border {...register("notes")} placeholder="Observação" />
          </Grid>
          <Grid item xs={12}>
            <h3 className="form-subtitle">Outras informações</h3>
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              border
              {...register("document", {
                onChange: (e) => validateCnpj(e.target.value)
                })
              }
              placeholder="CNPJ"
              mask={'99.999.999/9999-99'}
            />
            {errors.document && <p className="label-error">{errors.document.message}</p>}
          </Grid>

          {(codeValue[0]?.label === "Correios" || codeValue[0]?.label === "Retirada") && (
            <>
            <Grid item xs={6}>
              <OutlinedInput border {...register("stateRegistration")} placeholder="Inscrição Estadual" />
              {errors?.stateRegistration && <p className="label-error">{errors.stateRegistration.message}</p>}

            </Grid>
            <Grid item xs={6}>
              <OutlinedInput border {...register("cityRegistration")} placeholder="Inscrição Municipal" />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput border {...register("address.postalCode")} placeholder="CEP" />
              {errors?.address?.postalCode && <p className="label-error">{errors.address.postalCode.message}</p>}
            </Grid>
            <Grid item xs={8} className="address__address-field">
              <OutlinedInput border {...register("address.address")} placeholder="Logradouro"/>
              {errors?.address?.address && <p className="label-error">{errors.address.address.message}</p>}
            </Grid>
            <Grid item xs={4} className="address__number-field">
              <OutlinedInput border {...register("address.number")} placeholder="Número"/>
              {errors?.address?.number && <p className="label-error">{errors.address.number.message}</p>}
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput border {...register("address.complement")} placeholder="Complemento" />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput border {...register("address.neighborhood")} placeholder="Bairro" />
              {errors?.address?.neighborhood && <p className="label-error">{errors.address.neighborhood.message}</p>}
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput border {...register("address.city")} placeholder="Cidade" />
              {errors?.address?.city && <p className="label-error">{errors.address.city.message}</p>}
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput border {...register("address.state")} placeholder="UF" />
              {errors?.address?.state && <p className="label-error">{errors.address.state.message}</p>}
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput border {...register("site")} placeholder="Site" />
              {errors.site && <p className="label-error">{errors.site.message}</p>}
            </Grid>
            <Grid item xs={6}>
            {codeValue[0]?.label === "Correios" && (
              <Controller
                name="utils"
                control={control}
                render={({ field }) => (
                  <div className="dropdown-label-wrap">
                    <Dropdown
                      options={[
                        {
                          value: '',
                          label: 'Selecione'
                        },
                        {
                          value: 'br.com.stoom.ifood.util.CepUtils$CorreioPacUtils',
                          label: 'PAC'
                        },
                        {
                          value: 'br.com.stoom.ifood.util.CepUtils$CorreioSedexUtils',
                          label: 'Sedex'
                        },
                      ]}
                      {...field}
                      placeholder="Classe do útil"
                      className={`dropdown-ifood ${errorColorUtil}`}
                      controlClassName="dropdown-ifood__control"
                      arrowClassName="dropdown-ifood__arrow"
                      />
                    <label className="dropdown-label">Classe do útil</label>
                  </div>)
                }
              />
            )}
              </Grid>
             </>
           )}

          <Grid item xs={12}>
            <Grid container justify="flex-end" spacing={2} className="form-carrier__actions">
              <Grid item xs={3}>
                <NavLink
                  to="/fornecedores/transportadoras"
                  className="button btn-secondary button--block"
                >
                  Cancelar
                </NavLink>
              </Grid>
              <Grid item xs={3}>
                <Button
                  block
                  type="submit"
                  disabled={isEdit && !(!!Object.keys(dirtyFields).length)}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </>
  );
}

export default FormCarrier;