// Context, Reducer, Provider, Hook
import { createContext, useContext, useReducer } from 'react';
import { Action, ContextType, pageSteps, SellerFormActions, SellerFormProviderProps, State } from '../SellerFormType';

const initialData: State = {
  currentStep: pageSteps.RegistrationStep,
  registrationFormData: {},
  addressFormData: {},
  bankFormData: {},
  contractualFormData: {},
} as State;

// Context
const FormContext = createContext<ContextType | undefined>(undefined);

// Reducer
export const sellerFormReducer = (state: State, action: Action) => {
  switch (action.type) {
    case SellerFormActions.setCurrentStep:
      return { ...state, currentStep: action.payload };
    case SellerFormActions.setRegistrationFormData:
      return { ...state, registrationFormData: action.payload };
    case SellerFormActions.setAddressFormData:
      return { ...state, addressFormData: action.payload };
    case SellerFormActions.setBankFormData:
      return { ...state, bankFormData: action.payload };
    case SellerFormActions.setContractualFormData:
      return { ...state, contractualFormData: action.payload };
    case SellerFormActions.resetAllFormData:
      return initialData;
    default:
      return state;
  }
};

// Provider
export const SellerFormProvider = ({ children }: SellerFormProviderProps) => {
  const [state, dispatch] = useReducer(sellerFormReducer, initialData);
  const value = { state, dispatch };
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

// Context Hook
export const useSellerFormContext = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useSellerFormContext precisa ser usado dentro do SellerFormProvider');
  }

  return context;
};
