/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-no-undef */
import * as S from './EditForm.styles';
import { UseFormReturn } from 'react-hook-form';
import { Card, Grid, Input, Heading3, Flex, LinkText } from '@ifood/pomodoro-components';
import SellerRegistrationHeader from '../../../components/SellerRegistrationHeader';
import {
  AddressFormValueType,
  BankFormValueType,
  ContractualFormValueType,
  RegistrationFormValueType,
} from '@pages/Partners/Seller/SellerRegistration/types';
import BankDataFormFields from '../../../components/FormSteps/BankDataForm/components/BankDataFormFields';
import BasicDataFormFields from '../../../components/FormSteps/RegistrationDataForm/components/BasicDataFormFields';
import LegalRegistrationDataFormFields from '../../../components/FormSteps/RegistrationDataForm/components/LegalRegistrationDataFormFields';
import AddressDataFormField from '../../../components/FormSteps/AdressDataForm/components/AddressDataFormFields';
import Loading from '@components/Loading';
import { useEditSellerHook } from './editFormHook';
import ModalBackRegister from '../../../components/ModalBackRegister';
import { useState } from 'react';
import ContractualDataFormField from '../../../components/FormSteps/ContractualDataForm/components/ContractualDataFormFields';
import PasswordCriterea from '@components/PasswordCriterea';
import ComercialContactDataFields from '../../../components/FormSteps/ContractualDataForm/components/ComercialContactDataFields';
import ResetPasswordModal from '../../../components/FormSteps/RegistrationDataForm/components/ResetPasswordModal';

export type EditSellerForm = RegistrationFormValueType &
  BankFormValueType &
  AddressFormValueType &
  ContractualFormValueType;

const EditForm = (): JSX.Element => {
  const [modalBackRegister, setModalBackRegister] = useState(false);
  const { sellerData, form, onSubmit, redirectToDetails, urlDetails, isUpdatingSeller } = useEditSellerHook();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onResetPassword() {
    setIsModalOpen(true);
  }

  return (
    <>
      <S.Form onSubmit={form.handleSubmit(onSubmit)}>
        <S.HeaderEdit>
          <S.GoBackArrow data-testid="back-button" type="button" onClick={() => setModalBackRegister(true)} />
          <SellerRegistrationHeader titleHeader="Editar fornecedor" />

          <Flex alignItems="center" justifyContent="flex-end" mr={10}>
            <S.ButtonCancel variant="primary-inverted" onClick={redirectToDetails}>
              Cancelar
            </S.ButtonCancel>
            <S.ButtonSave
              disabled={isUpdatingSeller || !form.formState.isValid}
              isLoading={isUpdatingSeller}
              type="submit"
            >
              Salvar
            </S.ButtonSave>
          </Flex>
        </S.HeaderEdit>

        {!sellerData && <Loading />}

        {sellerData && (
          <S.Content data-testid="seller-content">
            <Grid gridGap={30} gridTemplateColumns="1fr 1fr">
              <Grid gridAutoRows="min-content" gridGap={30}>
                <Card>
                  <Flex flexDirection="column">
                    <Heading3 mb={24} mt={4} variant="smallest">
                      Dados cadastrais
                    </Heading3>
                  </Flex>

                  <Flex flexDirection="column">
                    <ResetPasswordModal
                      isOpen={isModalOpen}
                      sellerId={sellerData?.id}
                      onClose={() => setIsModalOpen(false)}
                    />

                    <BasicDataFormFields
                      sellerData={sellerData}
                      form={form as unknown as UseFormReturn<RegistrationFormValueType, any>}
                    />

                    <LinkText onClick={onResetPassword}>Resetar senha</LinkText>
                  </Flex>

                  <S.DividerSection marginBottom="large" />

                  <LegalRegistrationDataFormFields
                    form={form as unknown as UseFormReturn<RegistrationFormValueType, any>}
                  />

                  <S.DividerSection marginBottom="large" marginTop="large" />

                  <Flex flexDirection="column">
                    <Input
                      {...form.register('email')}
                      label="Email*"
                      name="email"
                      supportText="Usado para recebimento de cópia de pedido."
                      type="email"
                    />

                    <Input
                      {...form.register('password')}
                      error={form.formState.errors?.password?.message}
                      id="password"
                      label="Senha"
                      name="password"
                      supportText="Senha de acesso para autenticação da integração"
                      type="password"
                    />
                    <PasswordCriterea input="#password" />
                  </Flex>
                </Card>
              </Grid>

              <Grid gridAutoRows="min-content" gridGap={30}>
                <Card>
                  <Flex flexDirection="column">
                    <Heading3 mb={24} mt={4} variant="smallest">
                      Dados bancários
                    </Heading3>
                  </Flex>

                  <BankDataFormFields
                    bankFormData={sellerData.bankAccount as BankFormValueType}
                    form={form as unknown as UseFormReturn<BankFormValueType, any>}
                  />
                </Card>

                <Card>
                  <Flex flexDirection="column">
                    <Heading3 mb={24} mt={4} variant="smallest">
                      Endereço
                    </Heading3>
                  </Flex>

                  <AddressDataFormField form={form as unknown as UseFormReturn<AddressFormValueType, any>} />
                </Card>

                <Card>
                  <Flex flexDirection="column">
                    <Heading3 mb={24} mt={4} variant="smallest">
                      Dados contratuais
                    </Heading3>
                  </Flex>

                  <ContractualDataFormField form={form as unknown as UseFormReturn<ContractualFormValueType, any>} />
                </Card>

                <Card>
                  <Flex flexDirection="column">
                    <Heading3 mb={24} mt={4} variant="smallest">
                      Contato comercial
                    </Heading3>
                  </Flex>

                  <ComercialContactDataFields form={form as unknown as UseFormReturn<ContractualFormValueType, any>} />
                </Card>
              </Grid>
            </Grid>
          </S.Content>
        )}
      </S.Form>
      <ModalBackRegister
        description="Ao voltar, você perderá toda as informações que ainda não foram salvas do cadastro do fornecedor."
        isOpen={modalBackRegister}
        setIsOpen={setModalBackRegister}
        title="Deseja realmente voltar?"
        url={urlDetails}
      />
    </>
  );
};

export default EditForm;
