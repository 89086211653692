import { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Button } from 'ifoodshop-react-ui'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';

import TableWeightRange from '../../components/TableWeightRange';
import ModalCreateFreightRange from '../../components/ModalCreateFreightRange';

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';
import FormatHelper from '@helpers/FormatHelper';

import "./formFreightRange.scss";

function FormFreightRange() {
  const [dataWeightRange, setDataWeightRange] = useState([]);
  const [inputCarrier, setInputCarrier] = useState('');
  const historty = useHistory();

  const {
    isEdit,
    carrierId,
    freightRangeSelected,
    dataCarrier,
    formSearch,
    getAllCarriers,
    createFreightRange,
    updateFreightRange,
    setModal,
  } = useCarrierFreightRangeContext();


  //
  // SCHEMA
  const schema = yup.object().shape({
    region: yup.string().required('Região é obrigatório'),
    initialPostalCode: yup.string().required('CEP Inicial é obrigatório'),
    finalPostalCode: yup
      .string()
      .required('CEP Final é obrigatório'),
    additionalKGValue: yup
      .string()
      .typeError('Valor por KG é obrigatório')
      .required('Valor por KG é obrigatório'),
    additionalPercentageValue: yup
      .number()
      .typeError('Ad Valorem (%) é obrigatório')
      .required('Ad Valorem (%) é obrigatório'),
    deliveryTimeInHours: yup
      .number()
      .typeError('Prazo de Entrega em Horas é obrigatório')
      .positive('Prazo de Entrega em Horas deve ser maior que 0')
      .required('Prazo de Entrega em Horas é obrigatório'),
    active: yup.object(),
    carrierId: yup.number(),
    saturdayDelivery: yup.object(),
    weightRange: yup.object()
  })


  //
  // FORM VALIDATION
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    formState: {
      errors,
      isValid,
       }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      saturdayDelivery: !isEdit && {
        value: false,
        label: "Não"
      },
      active: !isEdit && {
        value: true,
        label: "Sim"
      },
    }
  });

  const onBlurTriggerField = (field) => {
    trigger(field);
  }

  const parseFloatConverter = (value) => {
    return parseFloat(value.replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
  }

  const convertValueToString = (value) => {
    return value.toString().replace('.', ',');
  }

  const convertValueToStringAndSplitComma =(value) => {
    const convertValueTrailZeros = convertValueToString(value);
    return convertValueTrailZeros.split(',');
  }

  useEffect(() => {

    if (freightRangeSelected && isEdit) {
      setValue('additionalKGValue', convertValueToStringAndSplitComma(freightRangeSelected?.additionalKGValue)?.length === 2 ?
      `${convertValueToString(freightRangeSelected?.additionalKGValue)}0`:
         convertValueToString(freightRangeSelected?.additionalKGValue))

      setValue('additionalPercentageValue', convertValueToStringAndSplitComma(freightRangeSelected?.additionalPercentageValue)[1]?.length === 1 ?
      `${freightRangeSelected.additionalPercentageValue}0`:
         freightRangeSelected.additionalPercentageValue)

      setValue('carrierId', freightRangeSelected.carrierId)
      setValue('deliveryTimeInHours', freightRangeSelected.deliveryTimeInHours)
      setValue('finalPostalCode', freightRangeSelected?.finalPostalCode.toString().padStart(8, '0'))
      setValue('initialPostalCode', freightRangeSelected?.initialPostalCode.toString().padStart(8, '0'))
      setValue('region', freightRangeSelected.region)
      setValue('active', {
        value: freightRangeSelected?.active === false  ? false : true,
        label: freightRangeSelected?.active === false  ? 'Não' : 'Sim'
      })
      setValue('saturdayDelivery', {
        value: freightRangeSelected.saturdayDelivery,
        label: freightRangeSelected?.saturdayDelivery ? 'Sim' : 'Não'
      })

      setDataWeightRange(
        freightRangeSelected.weightRange.map((item) => {
          item.cost = FormatHelper.format(item.cost, 'currency')
          item.value = FormatHelper.format(item.value, 'currency')

          return item;
        }))

    } else if (!freightRangeSelected && isEdit) {
      historty.push(`/fornecedores/transportadoras/editar/${carrierId}/faixa-frete/`)
    }
  }, [freightRangeSelected])

  useEffect(() => {
    if (freightRangeSelected?.saturdayDelivery !== watch('saturdayDelivery.value') ) {
      trigger('saturdayDelivery');
    }
  }, [watch('saturdayDelivery')]);

  useEffect(() => {
    if (freightRangeSelected?.active !== watch('active.value') ) {
      trigger('active');
    }
  }, [watch('active')]);

  useEffect(() => {
    getAllCarriers()
  }, [formSearch])

  useEffect(() => {
    if(dataCarrier?.getCarrierById) {
      setInputCarrier(dataCarrier?.getCarrierById?.name);
    }
  }, [dataCarrier]);

  useEffect(() => {
    if (JSON.stringify(dataWeightRange) !== JSON.stringify(freightRangeSelected?.weightRange) &&
        dataWeightRange.length) {
      trigger()
    }
  }, [dataWeightRange]);

  function onSubmit(data) {
    const dataWeightRangeEdited = dataWeightRange.map((item) => {

      if (item.cost.toString().includes("R$")) {
        item.cost = parseFloatConverter(item.cost)
      }

      if (item.value.toString().includes("R$")) {
        item.value = parseFloatConverter(item.value)
      }

      delete item.key
      delete item.__typename
      return item
    })

    const additionalKGValueConverted = parseFloatConverter(data.additionalKGValue)

    if(!isEdit) {
      createFreightRange({variables: {
        input: {
          ...data,
          additionalKGValue: additionalKGValueConverted,
          carrierId,
          initialPostalCode: Number(data?.initialPostalCode.replace(/\D/g, '')),
          finalPostalCode: Number(data?.finalPostalCode.replace(/\D/g, '')),
          weightRange: dataWeightRangeEdited,
          saturdayDelivery: data?.saturdayDelivery?.value,
          active: data?.active?.value,
        }
      }}).then((response) => {
        if(response.data.createFreightValue.region)
          setModal(<ModalCreateFreightRange resetForm={resetForm} setModal={setModal} />)
      })
    } else {
      updateFreightRange({variables: {
        input: {
          ...data,
          additionalKGValue: additionalKGValueConverted,
          carrierId,
          initialPostalCode: Number(data?.initialPostalCode.replace(/\D/g, '')),
          finalPostalCode: Number(data?.finalPostalCode.replace(/\D/g, '')),
          weightRange: dataWeightRangeEdited,
          saturdayDelivery: data?.saturdayDelivery?.value,
          active: data?.active?.value,
          freightIdLegacy: freightRangeSelected?.freightIdLegacy
        }
      }})
    }
  }

  function insertWeightRange() {
    const weightRange = getValues('weightRange')
    const {initialWeight, finalWeight, cost, value, volume} = weightRange;

    if(
      initialWeight.length &&
      finalWeight.length &&
      cost.length &&
      value.length &&
      volume.length
    ) {

      setDataWeightRange([
        ...dataWeightRange,
        {
          initialWeight: Number(initialWeight),
          finalWeight: Number(finalWeight),
          cost,
          value,
          volume: Number(volume),
          key: Math.random()
        }
      ])
      setValue('weightRange.initialWeight', '');
      setValue('weightRange.finalWeight', '');
      setValue('weightRange.cost', '');
      setValue('weightRange.value', '');
      setValue('weightRange.volume', '');
    }
  }

  function resetForm() {
    reset({
      region: '',
      initialPostalCode: '',
      finalPostalCode: '',
      additionalKGValue: '',
      additionalPercentageValue: '',
      deliveryTimeInHours: '',
      active: {
        value: true,
        label: 'Sim'
      },
      saturdayDelivery: {
        value: false,
        label: 'Não'
      }
    });
    setDataWeightRange([]);
    setModal(null);
  }


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-carrier">
        <Grid container className="container" spacing={2}>
          <Grid item xs={12} >
            <h3 className="form-subtitle">Faixa de Frete</h3>
          </Grid>

          <Grid item xs={12}>
            <OutlinedInput border {...register("region")} placeholder="Região" />
            {errors.region && <p className="label-error">{errors.region.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              border
              {...register("initialPostalCode")}
              placeholder="CEP Inicial"
              mask={'99999-999'}
              key={() => getValues('initialPostalCode')}
            />
            {errors.initialPostalCode && <p className="label-error">{errors.initialPostalCode.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              border
              {...register("finalPostalCode")}
              placeholder="CEP Final"
              mask={"99999-999"}
              key={() => getValues('finalPostalCode')}
            />
            {errors.finalPostalCode && <p className="label-error">{errors.finalPostalCode.message}</p>}
          </Grid>
          <Grid item xs={6}>
              <Controller
                name="additionalKGValue"
                control={control}
                render={({ field }) => (
                <OutlinedInputCurrency
                  border
                  {...field}
                  placeholder="Valor por KG adicional"
                  onBlur={() => onBlurTriggerField('additionalKGValue')}
                  />
                  )
                }
            />
            {errors.additionalKGValue && <p className="label-error">{errors.additionalKGValue.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
             border {...register("additionalPercentageValue")}
             type="number"
             step="0.01"
             min="0"
             max="10000000.00"
             placeholder="Ad Valorem (%)" />
            {errors.additionalPercentageValue && <p className="label-error">{errors.additionalPercentageValue.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <Controller
                name="deliveryTimeInHours"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    border
                    {...field}
                    placeholder="Prazo de Entrega em Horas"
                    mask={"999999999999999"}
                 />
                )
              }
            />
            {errors.deliveryTimeInHours && <p className="label-error">{errors.deliveryTimeInHours.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="saturdayDelivery"
              control={control}
              render={({ field }) => (
                <div className="dropdown-label-wrap">
                  <Dropdown
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" },
                    ]}
                    {...field}
                    placeholder="Entrega em sábado"
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    arrowClassName="dropdown-ifood__arrow"
                  />
                  <label className="dropdown-label">Entrega aos sábados</label>
                </div>)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <div className="dropdown-label-wrap">
                  <Dropdown
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" },
                    ]}
                    {...field}
                    placeholder="Ativo"
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    arrowClassName="dropdown-ifood__arrow"
                  />
                  <label className="dropdown-label">Ativo</label>
                </div>)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <h3 className="form-subtitle">Faixas de Peso</h3>
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              border
              type="number"
              step="0.01"
              min="0"
              max="10000000.00"
              className="activeLabel"
              {...register("weightRange.initialWeight")}
              placeholder="Peso Inicial (Kg)" />
            {errors?.weightRange?.initialWeight && <p className="label-error">{errors?.weightRange?.initialWeight?.message}</p>}
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              className="activeLabel"
              type="number"
              step="0.01"
              min="0"
              max="10000000.00"
              border
              {...register("weightRange.finalWeight")}
              placeholder="Peso Final (Kg)" />
            {errors?.weightRange?.finalWeight && <p className="label-error">{errors?.weightRange?.finalWeight?.message}</p>}
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput type="number" className="activeLabel labelFaixaPeso" border {...register("weightRange.volume")} placeholder="Volume" />
            {errors?.weightRange?.volume && <p className="label-error">{errors?.weightRange?.volume?.message}</p>}
          </Grid>
          <Grid item xs={4}>
            <Controller
                name="weightRange.cost"
                control={control}
                render={({ field }) => (
                  <OutlinedInputCurrency
                    type="number"
                    border
                    className="activeLabel labelFaixaPeso"
                    {...field}
                    placeholder="Custo"
                />
                )
              }
            />
            {errors?.weightRange?.cost && <p className="label-error">{errors?.weightRange?.cost?.message}</p>}
          </Grid>
          <Grid item xs={4}>
            <Controller
                name="weightRange.value"
                control={control}
                render={({ field }) => (
                  <OutlinedInputCurrency
                    type="number"
                    border
                    className="activeLabel labelFaixaPeso"
                    {...field}
                    placeholder="Valor"
                />
                )
              }
            />
            {errors?.weightRange?.value && <p className="label-error">{errors?.weightRange?.finalWeight?.message}</p>}
          </Grid>
          <Grid item xs={12} justify="flex-end" className="display-flex">
            <span onClick={() => insertWeightRange()} className="link">Inserir</span>
          </Grid>

          <Grid item xs={12}>
            <TableWeightRange dataWeightRange={dataWeightRange} setDataWeightRange={setDataWeightRange} />
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="flex-end" spacing={2} className="form-carrier__actions">
              <Grid item xs={3}>
                <NavLink
                  to="/fornecedores/transportadoras"
                  className="button btn-secondary button--block"
                >
                  Cancelar
                </NavLink>
              </Grid>
              <Grid item xs={3}>
                <Button
                  block
                  type="submit"
                  disabled={!isValid || !dataWeightRange?.length}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </>
  );
}

export default FormFreightRange;