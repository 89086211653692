import * as S from './RegistrationDataForm.styles';
import { Grid, Input, Text, Button, Heading3, Flex } from '@ifood/pomodoro-components';
import { useSellerFormContext } from '../../../context/SellerFormContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RegistrationFormValueType, ComponentCommonPropsType } from '@pages/Partners/Seller/SellerRegistration/types';
import PasswordCriterea from '@components/PasswordCriterea';
import { SellerFormActions, pageSteps } from '../../../SellerFormType';
import schema from './schema';
import BasicDataFormFields from './components/BasicDataFormFields';
import LegalRegistrationDataFormFields from './components/LegalRegistrationDataFormFields';
import { useRegistrationDataForm } from './registrationDataFormHook';
import { handleMaskSetValue } from '@helpers/mask';

const RegistrationDataForm = ({ triggerChangeEvent }: ComponentCommonPropsType): JSX.Element => {
  const { state } = useSellerFormContext();
  const form = useForm<RegistrationFormValueType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: { ...state.registrationFormData },
  });
  const { register, handleSubmit, formState, setValue } = form;
  const { onCnpjInputChange } = useRegistrationDataForm({ setValue });

  const onSubmit: SubmitHandler<RegistrationFormValueType> = data => {
    triggerChangeEvent(data, SellerFormActions.setRegistrationFormData);
    triggerChangeEvent(pageSteps.AddressStep, SellerFormActions.setCurrentStep);
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.DividerSection marginBottom="large" />
      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Flex flexDirection="column">
          <Heading3 my={4} variant="smallest">
            Dados cadastrais
          </Heading3>
          <Text variant="regular">Dados e informações de cadastro do fornecedor</Text>
        </Flex>

        <Flex flexDirection="column">
          <Input
            {...register('document')}
            error={formState.errors?.document?.message}
            label="CNPJ*"
            marginBottom="large"
            name="document"
            onChange={event => onCnpjInputChange(event.target.value)}
            onInput={e =>
              handleMaskSetValue({
                mask: '99.999.999/9999-99',
                event: e,
                fieldName: 'document',
                setValue: form.setValue,
              })
            }
          />
          <BasicDataFormFields form={form} />
        </Flex>
      </Grid>

      <S.DividerSection marginBottom="large" />

      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Flex flexDirection="column">
          <Heading3 my={4} variant="smallest">
            Inscrições
          </Heading3>
          <Text variant="regular">Inscrição estadual e municipal do fornecedor</Text>
        </Flex>
        <LegalRegistrationDataFormFields form={form} />
      </Grid>

      <S.DividerSection marginBottom="large" />

      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Flex flexDirection="column">
          <Heading3 my={4} variant="smallest">
            Acesso
          </Heading3>
          <Text variant="regular">Informações de acesso e tipo do fornecedor</Text>
        </Flex>
        <Flex flexDirection="column">
          <Input
            {...register('email')}
            error={formState.errors?.email?.message}
            label="Email*"
            name="email"
            supportText="Usado para recebimento de cópia de pedido."
            type="email"
          />
          <Input
            {...register('password')}
            error={formState.errors?.password?.message}
            id="password"
            label="Senha*"
            name="password"
            supportText="Senha de acesso para autenticação da integração"
            type="password"
          />
          <PasswordCriterea input="#password" />
          <Input
            {...register('confirmPassword')}
            error={formState.errors?.confirmPassword?.message}
            label="Confirme a Senha*"
            name="confirmPassword"
            type="password"
          />
        </Flex>
      </Grid>
      <S.DividerSection marginBottom="large" />
      <Flex alignItems="flex-end" justifyContent="flex-end">
        <Button type="submit">Avançar</Button>
      </Flex>
    </S.Form>
  );
};

export default RegistrationDataForm;
