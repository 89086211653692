import gql from 'graphql-tag';

const GET_FIELD = gql `
    query getCustomField($id: ID!){
        getCustomField(id: $id){
            id
            name
            active
            order
            type
            fieldType
            isFilter
            required
            selector
        }
    }
`;

const GET_FIELD_VALUE_TYPE = gql `
    query{
        types:getFieldType
    }
`;

const GET_FIELDS_BY_CATEGORY_ID = gql`
  query getFieldsByCategoryId($categoryId: ID!) {
    getFieldsByCategoryId(categoryId: $categoryId) {
      id
      name
      active
      categoryId
      order
      type
      fieldType
      isFilter
      required
      selector
    }
  }
`;

export { GET_FIELD, GET_FIELD_VALUE_TYPE, GET_FIELDS_BY_CATEGORY_ID };
