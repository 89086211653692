import introspection from '../services/bff/schema.json';
import { faker } from '@faker-js/faker';
import { addMocksToSchema } from '@graphql-tools/mock';
import { buildClientSchema, GraphQLSchema } from 'graphql';

export type MocksT = Record<string, any>;
const schema = buildClientSchema(introspection as any);

const defaultMocks = {
  Int: () => faker.datatype.number({ min: 0, max: 100 }),
  Float: () => faker.datatype.float({ min: 0, max: 100 }),
  SellerV2AddressType: () => ({
    number: faker.datatype.number({ min: 0, max: 100 }),
    zipcode: faker.address.zipCode('#####-###'),
  }),
  SellerV2: () => ({
    email: 'email@gmail.com',
    postalCode: faker.address.zipCode('#####-###'),
  }),
  SellerV2UserType: () => ({
    email: 'email@gmail.com',
  }),
};

function mergeSchema(mocks?: MocksT): GraphQLSchema {
  return addMocksToSchema({
    schema,
    mocks: {
      ...defaultMocks,
      ...mocks,
    },
  });
}

export { mergeSchema };
