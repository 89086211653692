/* istanbul ignore file */
import { Heading3 as HeadingPomodoro, Text as TextPomodoro } from '@ifood/pomodoro-components';
import styled, { css } from 'styled-components';

interface ITitle {
  isCredit?: boolean;
}
export const Heading = styled(HeadingPomodoro)<ITitle>`
  ${({ theme, isCredit }) => css`
    font-size: ${theme.fontSizes.tsm};
    line-height: ${theme.lineHeights.s};
    margin: ${theme.space.none};
    color: ${isCredit ? theme.colors.positive : ''};
    font-weight: ${isCredit ? theme.fontWeights.bold : theme.fontWeights.regular};
  `}
`;

export const AvailableAmount = styled(TextPomodoro)<ITitle>`
  ${({ theme, isCredit }) => css`
    font-size: ${theme.fontSizes.tsm};
    line-height: ${theme.lineHeights.s};
    color: ${isCredit ? theme.colors.positive : theme.colors.grayDarkest};
    font-weight: ${isCredit ? theme.fontWeights.bold : theme.fontWeights.medium};
  `}
`;

export const TextFormat = styled(TextPomodoro)`
  ${({ theme }) => css`
    color: ${theme.colors.grayDarker};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.fontSizes.s};
  `}
`;

export const Text = styled(TextFormat)`
  margin-top: ${({ theme }) => theme.space.smallest};
`;

export const Paragraph = styled(TextFormat)`
  margin: ${({ theme }) => `${theme.space.smallest} 0 0`};
`;
