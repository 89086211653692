import gql from 'graphql-tag';

const GET_ESTABLISHMENT_BY_ID = gql`
  query getEstablishmentById($establishmentId: ID!) {
    getEstablishmentById(establishmentId: $establishmentId) {
      id
      name
      stateRegistration
      companyName
      municipalRegistration
      document
      phone
      phoneDialCode
      paymentTypes
      sintegrawsBypass
      address {
        id
        address
        number
        city
        state
        postalCode
        neighborhood
      }
    }
  }
`;

export { GET_ESTABLISHMENT_BY_ID };
