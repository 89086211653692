import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckedIcon from '../../assets/icons/checked.svg'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 24,
    height: 24,
    backgroundColor: '#f2f2f2',
    border: '1px solid transparent',
  },
  checkedIcon: {
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage:
        `url(${CheckedIcon})`,
      backgroundSize: 'cover',
      content: '""',
    },
  },
});


const useStylesSecondary = makeStyles({
  icon: {
    borderColor: '#DCDCDC',
    backgroundColor: '#fff'
  },
});

export default function CheckboxIfood(props) {
  const classes = useStyles();
  const classesSecondary = useStylesSecondary();
  const classIconComponent = clsx({
    [classesSecondary.icon]: Boolean(props.checkboxSecondary),
    [classes.icon]: true,
  })

  return (
    <Checkbox
      className={classes.root}
      defaultChecked
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classIconComponent} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}
