import { Fragment, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_CATEGORIES_BY_NAME, GET_CATEGORIES_TREE } from '../../graphql/category.queries';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const AutocompleteCategory = props => {
  const [categoryId, setCategoryId] = useState(props?.data?.category?.categoryId || null);
  const [categoryName, setCategoryName] = useState(props?.data?.category?.categoryName || null);
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [getCategoryTree, { data: dataCategoryTree }] = useLazyQuery(GET_CATEGORIES_TREE);
  const [getCategories] = useLazyQuery(GET_CATEGORIES_BY_NAME);
  const [category, setCategory] = useState(props?.data);

  const mountOptions = (category, parentName = null) => {
    setCategoryOptions(options => [
      ...options,
      {
        id: category.id,
        name: parentName ? parentName + ' > ' + category.name : category.name,
      },
    ]);
    if (category.subCategories) {
      category.subCategories.map(subCategory => {
        mountOptions(subCategory, parentName ? parentName + ' > ' + category.name : category.name);
      });
    }
  };

  useEffect(() => {
    getCategories({
      variables: {
        name: '',
      },
    });
  }, []);

  useEffect(() => {
    setCategoryOptions([]);
    if (dataCategoryTree) {
      dataCategoryTree.getCategoriesTree.map(category => {
        mountOptions(category);
      });
    }
  }, [dataCategoryTree]);

  useEffect(() => {
    categoryName &&
      getCategoryTree({
        variables: {
          name: categoryName || '',
        },
      });
  }, [categoryName]);

  useEffect(() => {
    if (categoryId && categoryName) {
      props.selected(categoryId, categoryName);
    } else if (!props.data && !(categoryId && categoryName)) props.selected(null, null);
  }, [categoryName]);

  useEffect(() => {
    if (!category && props?.data) setCategory(props.data);
  }, [props]);

  return (
    <Fragment>
      <div className="dropdown-label-wrap__category">
        <Autocomplete
          id="productCategory"
          className="dropdown-ifood"
          value={category}
          options={categoryOptions || []}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Categoria" variant="outlined" />}
          onChange={(e, c, d) => {
            setCategoryId(c ? c.id : null);
            setCategoryName(c ? c.name : '');
            setCategory(c);
          }}
          onInputChange={e => setCategoryName(e ? e.target.value : '')}
          disabled={props.disabled}
        />
      </div>
    </Fragment>
  );
};

export default AutocompleteCategory;
