import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Item = styled.div`
  position: relative;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  :last-child {
    ::before,
    ::after {
      background-color: ${({ theme }) => theme.colors.grayLight};
    }
  }
  :first-child {
    ::before,
    ::after {
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }
  ::before,
  ::after {
    content: '';
    position: absolute;
  }
  ::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    border: 0;
    background-color: ${({ theme }) => theme.colors.gray};
  }
  ::after {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.gray};
    top: 14px;
    left: -6px;
    border: 3px solid ${({ theme }) => theme.colors.secondary};
  }
`;
