import { useEffect, useState } from 'react';
import { Link, Modal } from 'ifoodshop-react-ui';

import OrderHelper from '@helpers/OrderHelper';
import FormatHelper from '@helpers/FormatHelper';

import ModalPaymentsCancelHistory from './ModalPaymentsCancelHistory';

function OrderPayments({ payments, orderChild, original }) {
  const [modal, setModal] = useState(null);
  const [hasPaymentsHistory, setHasPaymentsHistory] = useState(false);
  
  useEffect(() => {
    if (orderChild) {
      const hasPayHistory =
        orderChild?.paymentsHistory?.filter(paymentHistory => paymentHistory.originPaymentHistory !== 'CREATED')
          ?.length > 0;
      setHasPaymentsHistory(hasPayHistory);
    }
  }, [orderChild]);

  const showPaymentsHistory = () => {
    setModal(
      <Modal onCloseAction={() => setModal(null)}>
        <ModalPaymentsCancelHistory history={payments} orderChild={orderChild} />
      </Modal>
    );
  };

  return (
    <div className="order-detail__card order-detail__card--payments">
      <h3 className="order-detail__card-title">Pagamentos</h3>
      {payments?.map(item => (
        <div key={item.id} className="order-detail__card-list">
          <p className="order-detail__card-item">
            Tipo:
            <span className="order-detail__card-value">{OrderHelper.handlePaymentType(item.type)}</span>
          </p>
          <p className="order-detail__card-item">
            Status do pagamento:
            <span className="order-detail__card-value">{OrderHelper.handlePaymentStatus(item.currentStatus)}</span>
          </p>
          {item.creditCardLastNumbers && (
            <p className="order-detail__card-item">
              Últimos números do cartão:
              <span className="order-detail__card-value">{item.creditCardLastNumbers}</span>
            </p>
          )}
          {item.bankSlipUrl && (
            <p className="order-detail__card-item">
              Boleto:
              <Link href={item.bankSlipUrl} className="order-detail__card-value" target="_blank">
                Imprimir
              </Link>
            </p>
          )}
          {item.redemptionCode && (
            <p className="order-detail__card-item">
              RedemptionCode:
              <Link href={item.redemptionCode} className="order-detail__card-value">
                {item.redemptionCode}
              </Link>
            </p>
          )}
          <p className="order-detail__card-item">
            Data de pagamento:
            <span className="order-detail__card-value">{FormatHelper.format(item.createdAt, 'simple-date')}</span>
          </p>
          <p className="order-detail__card-item">
            Total:
            <span className="order-detail__card-value">{FormatHelper.format(item.totalAmount, 'currency')}</span>
          </p>
          <p className="order-detail__card-item">
            PayIn:
            <span className="order-detail__card-value">{item?.payInProcess ? 'Sim' : 'Não'}</span>
          </p>
        </div>
      ))}

      {hasPaymentsHistory && !original && (
        <p className="order-detail__card-item">
          &nbsp;
          <span className="order-detail__card-value">
            <Link onClick={showPaymentsHistory} className="order-detail__card-value" target="_blank">
              Ver mais
            </Link>
          </span>
        </p>
      )}
      {modal}
    </div>
  );
}

export default OrderPayments;
