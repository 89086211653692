import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_BADWORD } from '../../../graphql/badwords.mutations';
import { GET_BADWORD_BY_WORD } from '../../../graphql/badwords.queries';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import Toggle from 'react-toggle';

import client from '@services/apollo';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import './badwords.scss';

const BadWordsRegister = () => {
  const roles = getRoles();
  const roleBadWord = roles.includes(Roles.BAD_WORDS_WR);
  const history = useHistory();

  if (!roleBadWord) history.push('/catalogos');

  const [word, setWord] = useState(null);
  const [state, setState] = useState(true);
  const [modal, setModal] = useState(null);
  const [createBadWord] = useMutation(CREATE_BADWORD);

  function changeModal(message) {
    setModal(<Modal onCloseAction={() => setModal(null)} title={message}></Modal>);
  }

  const registerBadWords = e => {
    e.preventDefault();
    let validacao = 1;

    let sendItems = {
      word: word,
      active: state,
    };

    Object.keys(sendItems).map(function (objectkey, index) {
      var size = Object.keys(sendItems).length - 1;
      var value = sendItems[objectkey];

      if (value === '' || value == null) {
        changeModal('Todos os campos são obrigatórios');
        validacao = 0;
      }

      if (index == size && validacao == 1) {
        client
          .query({
            query: GET_BADWORD_BY_WORD,
            variables: {
              word: word,
            },
          })
          .then(res => {
            var size = Object.keys(res.data.getBadWordByWord).length - 1;
            var valida = 1;

            if (size > 0) {
              res.data.getBadWordByWord.map((w, index) => {
                if (w.word.toUpperCase() == word.toUpperCase()) {
                  changeModal('Essa palavra já está cadastrada!');
                  valida = 0;
                }
                if (index == size && valida == 1) {
                  createBadWord({ variables: sendItems })
                    .then(res => {
                      history.push('/catalogos/badwords');
                    })
                    .catch(error => {
                      changeModal('Erro ao gravar palavra.');
                    });
                }
              });
            } else {
              createBadWord({ variables: sendItems })
                .then(res => {
                  history.push('/catalogos/badwords');
                })
                .catch(error => {
                  changeModal('Erro ao gravar palavra.');
                });
            }
          })
          .catch(err => {
            return err;
          });
      }
    });
  };

  return (
    <>
      {roleBadWord && (
        <>
          <NavLink className="go-back" to="/catalogos/badwords" />
          <div className="content-body badwords">
            {modal}
            <div className="brand-reg__title">
              <h2 className="section-title">Cadastrar palavra</h2>
              <p className="brand-reg__text">Use os campos abaixo para cadastrar uma palavra proibida.</p>
            </div>
            <form onSubmit={e => registerBadWords(e)}>
              <div className="badwords-register">
                <OutlinedInput
                  className="outlined-label-wrap"
                  placeholder="Palavra"
                  border
                  onChange={e => setWord(e.target.value)}
                />
                <div className="toggle-wrap">
                  <span className="toggle-wrap__label">Ativo</span>
                  <Toggle defaultChecked={state} value={state} onChange={v => setState(v.target.checked)} />
                </div>
                <Button className="btn">Salvar</Button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default BadWordsRegister;
