import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  height: 51px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    display: flex;
    align-items: center;
    gap: 16px;

    .go-back {
      top: 54px;
    }
  }

  button {
    background: white;
    color: #ea1d2c;
    border: 1px solid #ea1d2c;
    font-weight: bold;

    &:focus {
      outline: none;
    }

    :hover {
      background-color: transparent !important;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  margin-top: 51.5px;

  svg {
    height: 14px;
    width: 14px;
  }

  p {
    margin: 0 0 8px;
    min-width: 200px;
  }

  .content-wrapper {
    width: 560px;
    border: 1px solid #f2f2f2;
    border-radius: 6px;

    display: flex;
    flex-direction: column;

    gap: 24px;
    padding: 24px;

    .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: #3e3e3e;
      margin: 0;
    }

    .react-toggle--checked .react-toggle-track {
      background-color: #ea1d2c;
    }

    .react-toggle--checked .react-toggle-thumb {
      background-color: white;
      border-color: #ea1d2c;

      :focus {
        outline: none;
      }
    }
  }
`;

export const ContentTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #3e3e3e;
`;

export const ContentSubtitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #717171;
`;

export const ContentLabel = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #3e3e3e;
`;

export const DateLabel = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #3e3e3e;
`;

export const InlineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  gap: 110px;
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #f2f2f2;
`;
