import { Checkbox, Heading5 } from '@ifood/pomodoro-components';
import styled, { css } from 'styled-components';

export const Title = styled(Heading5)`
  margin-block: 0 12px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px 84px;
  margin-bottom: 32px;
  width: max-content;
`;

export const CheckboxButton = styled(Checkbox.Button)(
  ({ theme, disabled, checked }) => css`
    label {
      color: ${disabled && checked && theme.colors.black};
    }
    span[role='checkbox'] {
      background-color: ${disabled && checked && theme.colors.primary} !important;
      border-color: ${disabled && checked && theme.colors.primary} !important;
    }
  `
);
