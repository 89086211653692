import gql from 'graphql-tag';

const CREATE_FIELD = gql `
    mutation createCustomField($name: String, $categoryId: Int, $active: Boolean, $isFilter: Boolean, $type: String, $fieldValueType:String, $fieldListValue: String, $required: Boolean, $selector: Boolean){
        createCustomField(input: {name: $name, categoryId: $categoryId, type: $type, active: $active, isFilter: $isFilter,fieldValueType:$fieldValueType, fieldListValue: $fieldListValue, required: $required, selector: $selector}){
            id
            name
            order
            isFilter
            type
            fieldType
            required
            selector
        }
        
    }
`;

const UPDATE_FIELD = gql `
    mutation updateCustomField($id: ID!, $name: String, $categoryId: Int, $active: Boolean, $isFilter: Boolean, $type: String, $order: Int, $fieldValueType:String, $required: Boolean, $selector: Boolean){
        updateCustomField(id: $id, input: {name: $name, categoryId: $categoryId, type: $type, active: $active, isFilter: $isFilter, order: $order, fieldValueType:$fieldValueType, required: $required, selector: $selector}){
            id
            name
            order
            isFilter
            active
            fieldType
            required
            selector
        }
        
    }
`;


export {CREATE_FIELD, UPDATE_FIELD};
