import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_BRANDS } from '../../../../graphql/brand.queries';
import { GET_VARIATIONS_BY_PRODUCT } from '../../../../graphql/variation.queries';
import { GET_VARIATION_SPECIFICATIONS } from '../../../../graphql/product_variation_specification.queries';
import { GET_CATEGORIES_TREE, GET_CATEGORY, GET_CATEGORIES_BY_NAME } from '../../../../graphql/category.queries';
import { UPDATE_PRODUCT_SUGGESTION } from '../../../../graphql/suggestion.mutation';
import { GET_PRODUCT_SUGGESTS_BY_ID } from '../../../../graphql/product-suggest.queries';
import { OutlinedInput, Link, Modal, Typography } from 'ifoodshop-react-ui';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dropdown from 'react-dropdown';

import AuthorizationHeader from '@helpers/AuthorizationHeader';
import { ChangeUnitLabel } from '@helpers/ProductVariationHelper';

import TinyEditor from '@components/TextEditor';
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';
import OutlinedNumberInput from '@components/OutlinedNumberInput';
import Loading from '@components/Loading';
import { SuggestionCustomFields as PrdCustomFields } from '@components/SuggestionCustomFields';
import { DragDrop as DragDropPrd } from '../ExternalImageList';

import ImportImage from '../../../../assets/images/importImage.svg';
import './form-suggestion.scss';
import { api } from '@services/api';

const FormEditSuggestion = props => {
  const unityOptions = ['Kg', 'Litro', 'Caixa', 'Bandeja', 'Galão', 'Dúzia', 'Rolo', 'gr', 'Fardo', 'Peça', 'Display'];
  const productTypeOptions = [
    'Eletrodoméstico',
    'Embalagem',
    'FLV',
    'Insumo',
    'Móveis',
    'Outras Categorias',
    'Roupas',
    'Utensílios',
  ];
  const optionsActive = ['Habilitado', 'Desabilitado'];
  const [variableWeight, setVariableWeight] = useState(optionsActive[0]);
  const history = useHistory();
  const [price, setPrice] = useState('');
  const [modal, setModal] = useState(null);
  const [width, setWidth] = useState(null);
  const [unity, setUnity] = useState('');
  const [productType, setProductType] = useState('');
  const [height, setHeight] = useState(null);
  const [length, setLength] = useState(null);
  const [weight, setWeight] = useState(null);
  const [barcode, setBarcode] = useState('');
  const [multiples, setMultiples] = useState(null);
  const [description, setDescription] = useState(null);
  const [quantityAvailable, setQuantityAvailable] = useState(null);
  const [unitLabel, setUnitLabel] = useState('');

  //PRODUCT
  const [productName, setProductName] = useState('');
  const [btnSuggestion, setBtnSuggestion] = useState(true);

  //CATEGORIES
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [catSugestion, setcatSugestion] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [getCategoryTree, { data: dataCategoryTree }] = useLazyQuery(GET_CATEGORIES_TREE);
  const [getCategorybyName] = useLazyQuery(GET_CATEGORIES_BY_NAME);

  //PRODUCT FIELDS
  const [fields, setFields] = useState(null);
  const [fieldKeys, setFieldKeys] = useState(null);
  const [fieldsValues, setFieldsValues] = useState(null);
  const [getCategoryDetail, { data: dataCategoryDetail }] = useLazyQuery(GET_CATEGORY);
  const [getVariationsFields, { data: dataVarFields }] = useLazyQuery(GET_CATEGORY);

  //VARIATIONS
  const [variations, setVariations] = useState(null);
  const [getVariations, { data: dataVariations }] = useLazyQuery(GET_VARIATIONS_BY_PRODUCT);
  const [getVarSpecs] = useLazyQuery(GET_VARIATION_SPECIFICATIONS);

  //VARIATION FIELDS
  const [variationFields, setVariationFields] = useState(null);
  const [variationFieldsKeys, setVariationFieldsKeys] = useState(null);
  const [variationFieldsValues, setVariationFieldsValues] = useState(null);

  //BRANDS
  const [brandId, setBrandId] = useState(null);
  const [brandName, setBrandName] = useState('');
  const [brandSugestion, setBrandSugestion] = useState(true);
  const [getBrands, { data: dataGetBrands }] = useLazyQuery(GET_BRANDS);

  //IMAGES
  const [productImageList, setProductImageList] = useState([]);
  const [variationImageList, setVariationImageList] = useState([]);
  const [product, setProduct] = useState(null);
  const [removeImageIndex, setRemoveImageIndex] = useState(null);
  const [removeVarImageIndex, setRemoveVarImageIndex] = useState(null);
  const [drag, setDrag] = useState(false);

  //SKU
  const [sku, setSku] = useState(null);

  const [updateSuggestion] = useMutation(UPDATE_PRODUCT_SUGGESTION);

  useEffect(() => {
    getPrdSuggestion({
      variables: {
        suggestionId: parseInt(props.suggestionid),
      },
    });
  }, []);

  const [getPrdSuggestion, { data: dataGetPrdSuggestion }] = useLazyQuery(GET_PRODUCT_SUGGESTS_BY_ID);

  if (dataGetPrdSuggestion && productName === '') {
    setProductName(dataGetPrdSuggestion.getProductSuggestionById.name);
    setDescription(dataGetPrdSuggestion.getProductSuggestionById.description);
    setSku(dataGetPrdSuggestion.getProductSuggestionById.sku);
    setBarcode(dataGetPrdSuggestion.getProductSuggestionById.barCode);
    setWeight(dataGetPrdSuggestion.getProductSuggestionById.weight);
    setHeight(dataGetPrdSuggestion.getProductSuggestionById.height);
    setWidth(dataGetPrdSuggestion.getProductSuggestionById.width);
    setLength(dataGetPrdSuggestion.getProductSuggestionById.length);
    setUnity(dataGetPrdSuggestion.getProductSuggestionById.unity);
    setProductType(dataGetPrdSuggestion.getProductSuggestionById.productType);
    setPrice(dataGetPrdSuggestion.getProductSuggestionById.price);
    setVariableWeight(
      dataGetPrdSuggestion.getProductSuggestionById.variableWeight ? optionsActive[0] : optionsActive[1]
    );
    setQuantityAvailable(dataGetPrdSuggestion.getProductSuggestionById.stock);
    setMultiples(dataGetPrdSuggestion.getProductSuggestionById.quantity);

    //CATEGORY
    dataGetPrdSuggestion.getProductSuggestionById.category
      ? setCategoryName(dataGetPrdSuggestion.getProductSuggestionById.category)
      : setCategoryName(dataGetPrdSuggestion.getProductSuggestionById.categoryinfo.name);

    //BRAND
    dataGetPrdSuggestion.getProductSuggestionById.brand
      ? setBrandName(dataGetPrdSuggestion.getProductSuggestionById.brand)
      : setBrandName(dataGetPrdSuggestion.getProductSuggestionById.brandinfo.name);

    if (dataGetPrdSuggestion.getProductSuggestionById.productImageList) {
      setProductImageList(Object.values(JSON.parse(dataGetPrdSuggestion.getProductSuggestionById.productImageList)));
    }
    if (dataGetPrdSuggestion.getProductSuggestionById.variationImageList) {
      setVariationImageList(
        Object.values(JSON.parse(dataGetPrdSuggestion.getProductSuggestionById.variationImageList))
      );
    }
    if (dataGetPrdSuggestion.getProductSuggestionById.productSpecificationList) {
      let tempField = null;
      let tempArray = [];
      tempField = Object.values(JSON.parse(dataGetPrdSuggestion?.getProductSuggestionById?.productSpecificationList));

      tempField.forEach(f => {
        var test = { ...f, fieldType: 'TEXTFIELD', type: 'PRODUCT' };
        tempArray.push([test]);
      });
      setFields(tempArray);
      setFieldKeys(Object.keys(tempArray));
    }

    if (dataGetPrdSuggestion.getProductSuggestionById.skuSpecificationList) {
      let tempField = null;
      let tempArray = [];
      tempField = Object.values(JSON.parse(dataGetPrdSuggestion.getProductSuggestionById.skuSpecificationList));

      tempField.forEach(f => {
        var test = { ...f, fieldType: 'TEXTFIELD', type: 'SKU' };
        tempArray.push([test]);
      });
      setVariationFields(tempArray);
      setVariationFieldsKeys(Object.keys(tempArray));
    }
  }

  const mountOptions = (category, parentName = null) => {
    setCategoryOptions(options => [
      ...options,
      {
        id: category.id,
        name: parentName ? parentName + ' > ' + category.name : category.name,
      },
    ]);
    if (category.subCategories) {
      category.subCategories.map(subCategory => {
        mountOptions(subCategory, parentName ? parentName + ' > ' + category.name : category.name);
      });
    }
  };

  useEffect(() => {
    let tempImageList = productImageList;
    if (removeImageIndex !== null) {
      tempImageList.splice(removeImageIndex, 1);
      setProductImageList(tempImageList);
      setRemoveImageIndex(null);
    }
  }, [removeImageIndex]);

  useEffect(() => {
    let tempImageList = variationImageList;

    if (removeVarImageIndex !== null) {
      tempImageList.splice(removeVarImageIndex, 1);
      setVariationImageList(tempImageList);
      setRemoveVarImageIndex(null);
    }
  }, [removeVarImageIndex]);

  useEffect(() => {
    setCategoryOptions([]);
    if (dataCategoryTree) {
      dataCategoryTree.getCategoriesTree.map(category => mountOptions(category));
    }
  }, [dataCategoryTree]);

  useEffect(() => {
    getCategoryTree({
      variables: {
        name: categoryName || '',
      },
    });

    getCategorybyName({
      variables: {
        name: categoryName,
      },
    });
  }, [categoryName]);

  useEffect(() => {
    if (product) {
      getCategoryDetail({
        variables: {
          id: parseInt(product.category.id),
          fieldsType: 'PRODUCT',
        },
      });

      getVariationsFields({
        variables: {
          id: parseInt(product.category.id),
          fieldsType: 'SKU',
        },
      });
    }
  }, [product]);

  useEffect(() => {
    if (dataCategoryDetail) {
      setFields(dataCategoryDetail.getCategoryDetailById.fields);
      setFieldKeys(Object.keys(dataCategoryDetail.getCategoryDetailById.fields));
    }
  }, [dataCategoryDetail]);

  useEffect(() => {
    if (dataVarFields) {
      setVariationFields(dataVarFields.getCategoryDetailById.fields);
      setVariationFieldsKeys(Object.keys(dataVarFields.getCategoryDetailById.fields));
    }
  }, [dataVarFields]);

  useEffect(() => {
    getBrands({
      variables: {
        brandName: brandName,
      },
    });
  }, [brandName]);

  useEffect(() => {
    if (product) {
      getVariations({
        variables: {
          productId: product.id,
        },
      });
    }
  }, [product]);

  useEffect(() => {
    if (variations) {
      getVarSpecs({
        variables: {
          variation: parseInt(variations),
        },
      });
    }
  }, [variations]);

  if (document.body.querySelector('#suggestionBrand')) {
    document.body.querySelector('#suggestionBrand').addEventListener('blur', function () {
      if (!brandId && document.getElementById('suggestionBrand').value !== '') {
        setBrandSugestion(false);
      } else {
        setBrandSugestion(true);
      }
    });
  }

  if (document.body.querySelector('#suggestionCategory')) {
    document.body.querySelector('#suggestionCategory').addEventListener('blur', function () {
      if (!categoryId && document.getElementById('suggestionCategory').value !== '') {
        setcatSugestion(false);
      } else {
        setcatSugestion(true);
      }
    });
  }

  useEffect(() => {
    if (productName != '') {
      setBtnSuggestion(false);
    } else {
      setBtnSuggestion(true);
    }
  }, [productName]);

  useEffect(() => {
    if (unity) setUnitLabel(ChangeUnitLabel(parseInt(weight), unity));
  }, [weight, unity]);

  function changeModal(message) {
    setModal(<Modal onCloseAction={e => setModal(null)} title={message} />);
  }

  function InsertImageListDrop(e) {
    setModal(<Loading />);
    e.preventDefault();
    setDrag(false);

    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let uploaddata = new FormData();

    var ins = files?.length;
    for (var x = 0; x < ins; x++) {
      uploaddata.append('files', files[x]);
    }

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');

    api.post('/suggestion/image/upload', uploaddata)
      .then(res => {
        return res.json();
      })
      .then(data => {
        for (var i in data) {
          setVariationImageList(prev => [...prev, data[i]]);
          setModal(null);
        }
      })
      .catch(error => {
        setModal(<Modal onCloseAction={() => setModal(null)} title="Erro  ao importar arquivo" />);
      });
  }

  function dragOverHandler(e) {
    e.preventDefault();
    setDrag(true);
  }

  if (dataVariations && variations == null) {
    setVariations(dataVariations.getAllProductVariations.content);
  }

  const editSuggestion = e => {
    e.preventDefault();

    let floatPrice = price;

    if (typeof value === 'string') {
      floatPrice = parseFloat(price.toString().replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
    }

    let validacao = 1;

    if (brandId) setBrandName(null);
    if (categoryId) setCategoryName(null);

    let sendItems = {
      id: props.suggestionid,
      approved: true,
      name: productName,
      description: description,
      price: floatPrice,
      width: parseFloat(width?.replaceAll('.', '').replace(',', '.')),
      weight: parseFloat(weight?.replaceAll('.', '').replace(',', '.')),
      height: parseFloat(height?.replaceAll('.', '').replace(',', '.')),
      length: parseFloat(length?.replaceAll('.', '').replace(',', '.')),
      multiples: parseInt(multiples),
      barCode: barcode.toString(),
      unity: unity.toUpperCase(),
      productType: productType.toUpperCase(),
      sellerId: 2,
      sku: sku,
      stock: parseInt(quantityAvailable),
    };

    if (
      brandName === '' ||
      brandName === null ||
      brandId === '' ||
      brandId === null ||
      categoryName === '' ||
      categoryName === null ||
      (categoryId === '' && categoryId === null) ||
      productName === '' ||
      productName === null ||
      productType === '' ||
      productType === null ||
      description === '' ||
      description === null ||
      sku === '' ||
      sku === null ||
      description === '' ||
      description === null ||
      unity === '' ||
      unity === null ||
      multiples === '' ||
      multiples === null ||
      price === '' ||
      price === null ||
      variableWeight === '' ||
      variableWeight === null ||
      quantityAvailable === '' ||
      quantityAvailable === null ||
      height === '' ||
      height === null ||
      width === '' ||
      width === null ||
      weight === '' ||
      weight === null ||
      length === '' ||
      length === null
    ) {
      validacao = 0;
    }

    if ((productType.toUpperCase() === 'FLV' || productType.toUpperCase() === 'INSUMO') && barcode === '') {
      validacao = 0;
    }

    if (validacao === 1) {
      Object.keys(sendItems).map(function (objectKey, index) {
        var size = Object.keys(sendItems).length - 1;
        var value = sendItems[objectKey];

        if (index === size && validacao === 1) {
          if (brandId) {
            sendItems.brandId = brandId;
          } else {
            sendItems.brand = brandName;
          }

          if (categoryId) {
            sendItems.categoryId = categoryId;
          } else {
            sendItems.category = categoryName;
          }

          if (variationFieldsValues) {
            sendItems.skuSpecificationList = JSON.stringify(variationFieldsValues);
          }

          if (fieldsValues) {
            sendItems.productSpecificationList = JSON.stringify(fieldsValues);
          }

          if (productImageList && productImageList.length > 0) {
            sendItems.productImageList = JSON.stringify(productImageList);
          }

          if (variationImageList && variationImageList.length > 0) {
            sendItems.variationImageList = JSON.stringify(variationImageList);
          }

          updateSuggestion({ variables: sendItems })
            .then(res => {
              history.push('/catalogos/sugestoes');
            })
            .catch(error => {
              changeModal('Erro ao atualizar a sugestão.');
            });
        }
      });
    } else if ((productType.toUpperCase() === 'FLV' || productType.toUpperCase() === 'INSUMO') && barcode === '') {
      changeModal('Código de barras obrigatório para o tipo de produto');
    } else {
      changeModal('Todos campos com * são obrigatórios');
    }
  };

  function onlyNumbers(e) {
    return e.target.value.replace(/\D/g, '');
  }

  return (
    <div>
      {modal}
      <form className="form-register edit-suggestion" id="frm-reg__suggestion" onSubmit={editSuggestion}>
        <div className="dropdown-label-wrap">
          <Dropdown
            options={productTypeOptions}
            onChange={e => setProductType(e.value)}
            value={productType || ''}
            placeholder="Selecione"
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Tipo de produto*</label>
        </div>
        <div className="product-name">
          <OutlinedInput
            placeholder="Produto *"
            value={productName}
            border
            onChange={e => setProductName(e.target.value)}
          />
        </div>

        <TinyEditor width={1045} data={e => setDescription(e)} initialValue={description} />

        <div className="autocomplete">
          <div className="autocomplete__brand">
            <Autocomplete
              id="suggestionBrand"
              className="dropdown-ifood"
              popupIcon={<a className="dropdown-ifood__arrow" />}
              options={dataGetBrands && dataGetBrands.getAllBrands ? dataGetBrands.getAllBrands.content : []}
              inputValue={brandName ? brandName : ''}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label="Marca" variant="outlined" />}
              onChange={(e, b) => {
                setBrandId(b ? b.id : null);
                setBrandName(b ? b.name : '');
              }}
              onInput={e => setBrandName(e.target.value)}
            />

            <Autocomplete
              id="suggestionCategory"
              className="dropdown-ifood"
              popupIcon={<a className="dropdown-ifood__arrow" />}
              options={categoryOptions || []}
              inputValue={categoryName}
              getOptionLabel={option => option.name || ''}
              renderInput={params => <TextField {...params} label="Categoria" variant="outlined" />}
              onChange={(e, c) => {
                setCategoryId(c ? c.id : null);
                setCategoryName(c ? c.name : '');
              }}
              onInput={e => setCategoryName(e.target.value)}
            />
          </div>
          <div className="product-itens">
            <OutlinedInput placeholder="SKU *" border value={sku} onChange={e => setSku(e.target.value)} />

            <OutlinedInput
              placeholder="Código de barras"
              border
              value={barcode}
              maxLength="13"
              onChange={e => setBarcode(onlyNumbers(e))}
            />
          </div>
        </div>
        <div className="product-unity">
          <div className="dropdown-label-wrap">
            <Dropdown
              value={unity}
              options={unityOptions}
              onChange={e => setUnity(e.value)}
              placeholder="Selecione"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Unidade *</label>
          </div>
          <OutlinedInput
            placeholder="Quantidade múltiplos *"
            border
            value={multiples}
            onChange={e => setMultiples(onlyNumbers(e))}
          />
        </div>
        <p className="product-section"></p>
        <div className="header-product__description">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Preço
          </Typography>
        </div>
        <div className="product-price">
          <OutlinedInputCurrency value={price} placeholder="Preço (R$) *" onChange={e => setPrice(e.target.value)} />
          <div className="dropdown-label-wrap">
            <Dropdown
              options={optionsActive}
              onChange={e => setVariableWeight(e.value)}
              value={variableWeight}
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Peso Variável</label>
          </div>
        </div>
        <p className="product-section"></p>
        <div className="header-product__description">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Estoque
          </Typography>
        </div>
        <div className="product-inventory">
          <OutlinedInput
            placeholder="Quantidade disponível *"
            border
            value={quantityAvailable}
            onChange={e => setQuantityAvailable(onlyNumbers(e))}
          />
        </div>
        <p className="product-section"></p>
        <div className="header-product__description">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Dimensões para cálculo de frete
          </Typography>
        </div>
        <div className="dimensions">
          <OutlinedNumberInput
            placeholder="Altura *"
            border
            value={height || ''}
            decimalScale={3}
            min="0.001"
            step="0.001"
            onChange={e => setHeight(e.target.value)}
          />

          <OutlinedNumberInput
            placeholder="Largura *"
            border
            value={width || ''}
            decimalScale={3}
            min="0.001"
            step="0.001"
            onChange={e => setWidth(e.target.value)}
          />
          <OutlinedNumberInput
            placeholder="Comprimento *"
            border
            value={length || ''}
            decimalScale={3}
            min="0.001"
            step="0.001"
            onChange={e => setLength(e.target.value)}
          />
          <OutlinedNumberInput
            placeholder="Peso (g)*"
            border
            value={weight || ''}
            decimalScale={3}
            min="0.001"
            step="0.001"
            onChange={e => setWeight(e.target.value)}
          />
        </div>
        {fields?.length > 0 || variationFields?.length > 0 ? (
          <>
            <p className="product-section"></p>
            <div className="header-product__description__title">
              <Typography size="20px" weight="regular" line="24px" color="heavygrey">
                Ficha Técnica
              </Typography>
            </div>
            <div className="header-product__description">
              <Typography size="18px" weight="normal" line="24px" color="heavygrey">
                Com a ficha completa, seus compradores terão todas as informações necessárias para comprarem de você
              </Typography>
            </div>
            <div className="product-Datasheet">
              {fields && (
                <PrdCustomFields
                  fields={fields}
                  fieldKeys={fieldKeys}
                  data={e =>
                    setFieldsValues(f => {
                      return { ...f, ...e };
                    })
                  }
                />
              )}
              {variationFields && (
                <PrdCustomFields
                  fields={variationFields}
                  fieldKeys={variationFieldsKeys}
                  data={e =>
                    setVariationFieldsValues(f => {
                      return { ...f, ...e };
                    })
                  }
                />
              )}
            </div>
          </>
        ) : null}
        <p className="product-section"></p>
        <div className="header-product__description__title">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Imagens
          </Typography>
        </div>
        <div className="product-images">
          <div
            id="drop_zone"
            htmlFor="drop_zone"
            className={`modal-import ${variationImageList.length > 0 ? 'modal-import__zone-active' : ''}`}
            onDrop={e => InsertImageListDrop(e)}
            onDragOver={e => dragOverHandler(e)}
            onDragLeave={() => setDrag(false)}
          >
            <div className={`modal-import-itens ${variationImageList.length > 0 ? 'modal-import__zone-active' : ''}`}>
              <div className="import-image">
                <img src={ImportImage} alt="Importação Imagem" />
              </div>
              <div className="header-customer-group__new">
                <input
                  type="file"
                  accept="image/*"
                  id="upload-input-var"
                  hidden
                  multiple
                  onChange={e => InsertImageListDrop(e, 'variation')}
                />
                <label className="btn-save" htmlFor="upload-input-var">
                  <Link>Adicione ou arraste as fotos do produto aqui</Link>
                </label>
              </div>
            </div>
          </div>
          {variationImageList && variationImageList.length > 0 ? (
            <div className="product-images__item">
              <DragDropPrd id="vardragdrop" imageList={variationImageList} imageOut={i => setRemoveVarImageIndex(i)} />
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default FormEditSuggestion;
