import './headerRegisterPrd.scss';

const HeaderRegisterProduct = props => {
  return (
    <div className="header-product">
      <div className="header-product__info">
        <h2 className="section-title">{props.title}</h2>
        <p className="header-product__description">{props.description}</p>
      </div>
    </div>
  );
};

export default HeaderRegisterProduct;
