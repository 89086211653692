import "./cardGrey.scss";

const CardGrey = ({ className, children, props }) => {
  const classes = ["cardGrey"];
  if (className) classes.push(className);

  return (
    <div className={classes.join(" ")} {...props}>
      {children}
    </div>
  );
};

export default CardGrey;
