import './bounceLoader.scss';

const BounceLoader = ({ color, ...rest }) => {
	const classes = ['dot-flashing'];
	if (color) classes.push(`dot-flashing--${color}`);

	return (
		<div {...rest}>
			<div className={classes.join(' ')} />
		</div>
	);
};

export default BounceLoader;
