import ChangeOrderStatus from "./ChangeOrderStatus";
import ModalRequestVehicle from "./ModalRequestVehicle";
import RequestConfirmation from "./RequestConfirmation";
import { useOrderContext } from "@components/_context/OrderContext";

const RequestVehicle = ({delivery}) => {

  const { stepVehicle } = useOrderContext();
  const { orderDeliveryId, setModal } = delivery;

  function handleModal() {
    if (stepVehicle === 0) return <ChangeOrderStatus />;
    if (stepVehicle === 1) return <ModalRequestVehicle orderDeliveryId={orderDeliveryId} />;
    if (stepVehicle === 2) return <RequestConfirmation setModal={setModal} />;
  }

  return handleModal();
};

export default RequestVehicle;
