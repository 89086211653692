import Decoder from "@helpers/Decoder";
import { shouldMockApollo } from "@services/apollo";
import React, { createContext, useContext, useEffect, useState } from "react";

import { initKeycloak } from "../../services/keycloak";

const Context = createContext();

export function KeycloakProvider({ children }) {
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    if (shouldMockApollo) {
      const mockedKeycloack = {
        token: process.env.REACT_APP_MOCK_TOKEN,
        tokenParsed: Decoder.decode(process.env.REACT_APP_MOCK_TOKEN),
        authenticated: true,
      };
      sessionStorage.setItem("token", mockedKeycloack.token);
      setKeycloak(mockedKeycloack);

    } else {
      initKeycloak()
      .then((response) => setKeycloak(response))
      .catch((error) => console.error(`[Keycloak context]: ${error}`));
    }
  }, []);

  return <Context.Provider value={{ keycloak }}>{children}</Context.Provider>;
}

export function useKeycloakContext() {
  return useContext(Context);
}
