/* eslint-disable no-magic-numbers */
import React, { useEffect } from 'react';
import { Button, Upload, UploadableFile, UploadProps } from '@ifood/pomodoro-components';
import { TrashOutlined } from '@ifood/pomodoro-icons';
import { useImageUpload } from './imageUploadHook';
import * as S from './ImageUpload.styles';

const MAX_FILES_UPLOAD = 1;
const MAX_SIZE_IMG = 20 * 1000 * 1024;

interface ImageUploadI {
  onChange: (file?: UploadableFile) => void;
  uploadComponentProps: Partial<UploadProps>;
  selectedImage?: UploadableFile;
}

export function ImageUpload({ onChange, uploadComponentProps, selectedImage }: ImageUploadI) {
  const { image, onImageChange, onDeleteImage } = useImageUpload({ onChange, selectedImage });

  useEffect(() => {
    if (selectedImage && typeof selectedImage !== 'string') {
      onImageChange([selectedImage]);
    }
  }, [onImageChange, selectedImage]);

  return (
    <>
      <Upload
        data-testid="image-upload"
        uploadConfig={{
          accept: ['image/*'],
          maxFiles: MAX_FILES_UPLOAD,
          maxSize: MAX_SIZE_IMG,
        }}
        uploadImage={
          image || selectedImage
            ? {
                src: image ? image : `${window.config?.StaticAssetsURL}${selectedImage}`,
                alt: 'Logo',
              }
            : undefined
        }
        variant="small-white"
        onUploadChange={onImageChange}
        {...uploadComponentProps}
      />

      {(image || selectedImage) && (
        <Button
          size="small"
          textAlign="left"
          type="button"
          variant="tertiary"
          verticalAlign="center"
          onClick={onDeleteImage}
        >
          <S.CenteredIcon component={TrashOutlined} size="xs" />
          Excluir
        </Button>
      )}
    </>
  );
}
