import { Button } from 'ifoodshop-react-ui';

import check from '../../../../assets/icons/check.svg';
import warning from '../../../../assets/icons/warning.svg';

import TableInLote from '../Rupture/InLote/TableInLote';
import { ModalRuptureFooter } from '@components/Modal/ModalFooter';

import '../../../../components/Modal/ModalFooter/ModalFooter.scss';
import './ModalRuptureConfirmation.scss';

function ModalRuptureConfirmation({
  setModal,
  success,
  errorMessage,
  successMessage,
  dataError,
  successTitle,
  successButton,
  errorButton,
  successFooterAlign,
  errorFooterAlign,
}) {
  function closeModal() {
    setModal(null);
  }

  return (
    <>
      {success ? (
        <div className="modal-content modal-content--success">
          <img src={check} alt="ìcone de check" className="modal-content__icon" />
          <h1 className="modal-content__title">{successTitle ? successTitle : 'Pedido Alterado'}</h1>
          <p className="modal-content__description">
            {successMessage ? successMessage : 'As informações do pedido serão atualizadas e o estorno será realizado para o cliente automaticamente.'}
          </p>
          <ModalRuptureFooter footerAlign={successFooterAlign}>
            <Button onClick={() => closeModal()} className="button-lg">
              {successButton ? successButton : 'Ok, entendi'}
            </Button>
          </ModalRuptureFooter>
        </div>
      ) : (
        <div className="modal-content modal-content--error">
          <img src={warning} alt="Ícone de warning" className="modal-content__icon" />
          <h1 className="modal-content__title">Erro ao remover o produto</h1>
          <p className="modal-content__description">{errorMessage}</p>
          {dataError && <TableInLote listData={dataError} />}
          <ModalRuptureFooter footerAlign={errorFooterAlign}>
            <Button onClick={() => closeModal()} className="button-lg">
              {errorButton ? errorButton : 'Tentar novamente'}
            </Button>
          </ModalRuptureFooter>
        </div>
      )}
    </>
  );
}

export default ModalRuptureConfirmation;
