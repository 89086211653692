import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GET_RUPTURE_REASON from '../../../../../graphql/order-delivery.queries';

import { getSessionEmail } from '@helpers/rolesUtils';

import ModalProductInLote from '../InLote/ModalProductInLote';
import ModalReasonInLote from '../InLote/ModalReasonInLote';
import ModalRuptureConfirmation from '../../ModalRuptureConfirmation';

import '../../ModalsStepsOrder/ModalsStepsOrder.scss';

function RuptureInLote({ step, setModal, dataOrders, isSeller, checkedRupture, sellerCode }) {
  const { data } = useQuery(GET_RUPTURE_REASON);
  const sessionEmail = getSessionEmail();

  const [modalStep, setModalStep] = useState(step);
  const [success, setSuccess] = useState(false);
  const handleSetModal = stepModal => {
    setModalStep(stepModal);
  };

  return (
    <>
      {modalStep === 'PRODUCT' ? (
        <ModalProductInLote
          handleSetModal={handleSetModal}
          setModal={setModal}
          dataOrders={dataOrders}
          footerAlign="right"
          isSeller={isSeller}
        />
      ) : modalStep === 'REASON' ? (
        <ModalReasonInLote
          setModal={setModal}
          reasonRuture={data?.getRuptureReason}
          handleSetModal={handleSetModal}
          footerAlign="right"
          checkedRupture={checkedRupture}
          sellerCode={sellerCode}
          setSuccess={setSuccess}
        />
      ) : (
        <ModalRuptureConfirmation
          setModal={setModal}
          successButton="Fechar"
          errorButton="Fechar"
          successTitle="Pedidos editados"
          successMessage={`As informações do(s) pedido(s) serão atualizadas e um e-mail será enviado para ${sessionEmail} com o resultado da operação`}
          success={success}
          errorMessage="Não foi possível fazer a edição"
          successFooterAlign="center"
          errorFooterAlign="right"
          reload={true}
        />
      )}
    </>
  );
}

export default RuptureInLote;
