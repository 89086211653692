import imgEditweight from '../../../../assets/images/order/ModalFlowAccept/example-table-edit-weight.svg';
import * as S from './ModalFlowAccept.styles';

const ModalFlowAcceptstep2 = () => {
  return (
    <>
      <S.Heading as="h3">
        Agora, é possível <strong>ajustar o peso variável de itens</strong> e atualizar as informações do pedido
        automaticamente.
      </S.Heading>
      <S.FigureBox>
        <S.Image alt="Exemplo de tabela de editar peso" src={imgEditweight} width="100%" />
      </S.FigureBox>
    </>
  );
};

export default ModalFlowAcceptstep2;
