import { useState } from 'react';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import PixChargebackRequest from './components/PixChargebackRequest';
import PixChargebackSuccess from './components/PixChargebackSuccess';
import PixChargebackError from './components/PixChargebackError';
import { pixChargebackTypes } from './PixChargeback.utils';

import './pixChargeback.scss';

const PixChargeback = ({ refetch }) => {
  const [modal, setModal] = useState();

  const componentClassName = 'pix-chargeback';
  const roles = getRoles();
  const roleFinancialWR = roles.includes(Roles.FINANCIAL_WR);

  if (!roleFinancialWR) {
    return null;
  }

  const handleOnClose = () => {
    setModal(null);
    refetch();
  };

  const handleFeedbackModal = type => {
    const feedbackModal =
      type === pixChargebackTypes.SUCCESS ? (
        <PixChargebackSuccess onClose={handleOnClose} />
      ) : (
        <PixChargebackError onClose={handleOnClose} onClick={handleOnClick} />
      );

    setModal(feedbackModal);
  };

  const handleOnClick = () => {
    setModal(<PixChargebackRequest onClose={() => setModal(null)} setFeedbackModal={handleFeedbackModal} />);
  };

  return (
    <div className={componentClassName}>
      <button className={`${componentClassName}__button`} type="button" onClick={handleOnClick}>
        Solicitar estorno de Pix
      </button>

      {modal}
    </div>
  );
};

export default PixChargeback;
