import { NavLink, useHistory } from 'react-router-dom';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import HeaderRegisterCategory from '../components/HeaderRegisterCategory';
import FormRegisterSpecification from '../components/FormRegisterSpecification';

import './register-specification.scss';

const SpecificationRegister = props => {
  const { id } = props.match.params;

  const history = useHistory();
  const roles = getRoles();
  const roleCategoryWR = roles.includes(Roles.CATEGORY_WR);
  const roleCategoryRO = roles.includes(Roles.CATEGORY_RO);

  if (!roleCategoryRO && !roleCategoryWR) history.push('/catalogos');

  return (
    <>
      <NavLink to={'/catalogos/categorias/detalhe/' + id} className="go-back" />
      <div className="content-body register-specification">
        <HeaderRegisterCategory
          title="Criar uma especificação"
          description="Use os campos abaixo para criar uma especificação."
        />
        <FormRegisterSpecification categoryId={props.match.params.id} />
      </div>
    </>
  );
};

export default SpecificationRegister;
