import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { GET_BRAND } from '../../../graphql/brand.queries';
import { UPDATE_BRAND } from '../../../graphql/brand.mutations';
import { Button, OutlinedInput, Modal } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';

import client from '@services/apollo';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import 'react-dropdown/style.css';
import '../../../components/DropDownIfood/dropdown-ifood.scss';

const BrandEdit = props => {
  const history = useHistory();
  const roles = getRoles();

  const roleBrandWriter = roles.includes(Roles.BRAND_WR);

  if (!roleBrandWriter) history.push('/catalogos');

  const stateOptions = ['Habilitado', 'Desabilitado'];
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [state, setState] = useState('');
  const [modal, setModal] = useState(null);

  const [updateBrand] = useMutation(UPDATE_BRAND, {
    onCompleted: data => {
      if (data.updateBrand.error === 'BRAND_NAME_NOT_AVAILABLE') {
        changeModal('Atenção', 'Marca já cadastrada com o mesmo nome!');
      } else {
        window.location.href = '/catalogos/marcas';
      }
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao atualizar marca');
    },
  });

  useEffect(() => {
    client
      .query({
        query: GET_BRAND,
        variables: {
          id: parseInt(props.match.params.id),
        },
      })
      .then(res => {
        const { data } = res;
        setName(data.getBrand.name);
        setActive(data.getBrand.active);
        setState(data.getBrand.active ? 'Habilitado' : 'Desabilitado');
      })
      .catch(err => {
        return 'Error in fetching data' + err;
      });
  }, [modal]);

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  const makeChanges = e => {
    e.preventDefault();

    if (name.length > 0) {
      updateBrand({
        variables: {
          id: parseInt(props.match.params.id),
          name: name,
          active: state === 'Habilitado',
        },
      });
    } else {
      changeModal('Atenção', 'Nome da marca é obrigatório!');
    }
  };

  return (
    <>
      <NavLink to="/catalogos/marcas" className="go-back" />
      <div className="content-body brand-reg">
        {modal}
        <div>
          <form onSubmit={makeChanges}>
            <div className="brand-reg__title">
              <h2 className="section-title">Editar uma marca</h2>
              <p className="brand-reg__text">Use os campos abaixo para editar uma marca</p>
            </div>
            <div className="brand-reg__fields">
              <OutlinedInput
                className="name"
                placeholder="Marca"
                border="true"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <div className="dropdown-label-wrap">
                <Dropdown
                  options={stateOptions}
                  value={state}
                  onChange={e => setState(e.value)}
                  placeholder="Estado"
                  className="dropdown-ifood"
                  controlClassName="dropdown-ifood__control"
                  placeholderClassName="dropdown-ifood__placeholder"
                  menuClassName="dropdown-ifood__menu"
                  arrowClassName="dropdown-ifood__arrow"
                />
                <label className="dropdown-label">Estado</label>
              </div>
              <Button className="btn">Salvar edição</Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BrandEdit;
