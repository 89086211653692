import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';

import FormatHelper from '../../../../../../helpers/FormatHelper';

function TableCollectionSearch({ roleCollection, collections }) {
  return (
    <>
      <h3 className="section-title title-table-groups">Resultados do filtro</h3>
      <table className="table-admin-fixed-price">
        <thead>
          <tr>
            <th className="table-admin-fixed-price__title">ID</th>
            <th className="table-admin-fixed-price__title">Nome</th>
            <th className="table-admin-fixed-price__title">Data Inicial</th>
            <th className="table-admin-fixed-price__title">Data Final</th>
            <th className="table-admin-fixed-price__title"></th>
          </tr>
        </thead>
        <tbody>
          {
            collections.map(collection => {
              return (
                <tr id={collection.id} key={collection.id}>
                  <td className="table-admin-fixed-price__data">{collection.id}</td>
                  <td className="table-admin-fixed-price__data">{collection.name}</td>
                  <td className="table-admin-fixed-price__data">{FormatHelper.format(collection.startDate, 'date')}</td>
                  <td className="table-admin-fixed-price__data">{FormatHelper.format(collection.endDate, 'date')}</td>
                  <td className="table-admin-fixed-price__data">
                    {
                      roleCollection
                        ? <NavLink to={"/campanhas/colecoes/editar/" + collection.id} className="link" >Editar</NavLink>
                        : <Tooltip title="Você não tem permissão para editar Coleção">
                          <a className="link--disabled">Editar</a>
                        </Tooltip>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </>
  );
}

export default TableCollectionSearch;