export default class OrderHelper {
  static handleOrderStatus(statusType) {
    const options = {
      default: "",
      PROCESSING: "Processando",
      DONE: "Finalizado",
    };

    return options[statusType] || options.default;
  }

  static handleOrderDeliveryStatus(statusType, phraseVariation = null) {
    const options = {
      default: "Indefinido",
      BILLED: "Faturado",
      CANCELLED: "Cancelado",
      DELIVERY_PENDING: "Entrega Pendente",
      FINISHED: "Entregue",
      IN_STOCK_SEPARATION: "Em Separação",
      IN_TRANSPORTATION: "Em Transporte",
      PAYMENT_ACCEPTED: phraseVariation ? "Confirmado" : "Pagamento confirmado",
      PAYMENT_CANCELLED: "Pagamento Cancelado",
      PAYMENT_PENDING: "Pagamento Pendente",
      WAITING_PAYMENT: phraseVariation ? "Aguardando" : "Aguardando Pagamento",
      WAITING_PICKUP: "Aguardando Retirada",
    };

    return options[statusType] || options.default;
  }

  static handlePaymentType(statusType) {
    const options = {
      default: "Forma de pagamento",
      BANK_SLIP: "Boleto",
      POST_PAID_BANK_SLIP: "Boleto a prazo",
      CREDIT_CARD: "Cartão de crédito",
      BONUS_SHOP: "Bônus Shop",
      REPASSE: "Saldo de Repasse",
      PIX: "Pix",
    };

    return options[statusType] || options.default;
  }

  static handlePaymentStatus(statusType) {
    const options = {
      default: null,
      PROCESSING: "Processando",
      APPROVED: "Aprovado",
      DECLINED: "Negado",
      CANCELLED: "Cancelado",
    };

    return options[statusType] || options.default;
  }

  static handleTransactionTypePaymentHistory(statusType) {
    const options = {
      default: null,
      CREDIT: 'Credito',
      DEBIT: 'Debito',
    };

    return options[statusType] || options.default;
  }

  static handleOriginPaymentHistory(statusType) {
    const options = {
      default: null,
      CREATED: 'Criado',
      CANCELLED: 'Cancelamento',
      EDITED: 'Edição de pedido',
    };

    return options[statusType] || options.default;
  }

  static handleRefundType(automaticRefund) {
    return automaticRefund ? 'Automàtico' : 'Abertura de ticket';
  }
}
