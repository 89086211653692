import { Button, Typography } from 'ifoodshop-react-ui';

import { ModalRuptureFooter } from '@components/Modal/ModalFooter';
import OrderCardDetais from '../../InLote/OrderCardDetails';
import TableInLote from '../../InLote/TableInLote';

import '../../../../../../components/Modal/ModalFooter/ModalFooter.scss';

function ModalProductInLote({ handleSetModal, setModal, dataOrders, footerAlign, isSeller }) {
  const product = dataOrders[0]?.orderSellers[0]?.deliveries[0]?.items[0];
  return (
    <>
      <div className="content-steps-order">
        <Typography className="modal__title" size="20px" weight="regular" line="32px" color="black" variant="h2">
          Deseja remover esse produto dos pedidos?
        </Typography>
        <OrderCardDetais
          img={product?.imagePath}
          imgAlt={product?.name}
          description={product?.name}
          sku={product?.sku}
          skuIntern={product?.skuIntern}
          value={isSeller ? product?.sellerUnitPrice : product?.unitPrice}
        />

        <TableInLote listData={dataOrders} isSeller={isSeller} />

        <ModalRuptureFooter footerAlign={footerAlign}>
          <Button className="btn-secondary button-lg" onClick={() => setModal(null)}>
            Fechar
          </Button>
          <Button type="submit" className="button-lg" onClick={() => handleSetModal('REASON')}>
            Quero continuar
          </Button>
        </ModalRuptureFooter>
      </div>
    </>
  );
}

export default ModalProductInLote;
