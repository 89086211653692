import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_BRAND } from '../../../graphql/brand.mutations';
import { Button, OutlinedInput, Modal } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import 'react-dropdown/style.css';
import '../../../components/DropDownIfood/dropdown-ifood.scss';
import './brandRegister.scss';

const BrandRegister = () => {
  const history = useHistory();
  const roles = getRoles();
  const roleBrandWriter = roles.includes(Roles.BRAND_WR);

  if (!roleBrandWriter) history.push('/catalogos');

  const stateOptions = ['Habilitado', 'Desabilitado'];
  const defaultStateOption = stateOptions[0];
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [state, setState] = useState('Habilitado');
  const [modal, setModal] = useState(null);

  const [brandCreate] = useMutation(CREATE_BRAND, {
    onCompleted: data => {
      if (data.createBrand.error === 'BRAND_NAME_NOT_AVAILABLE') {
        changeModal('Atenção', 'Marca já cadastrada com o mesmo nome!');
      } else {
        window.location.href = '/catalogos/marcas';
      }
    },
    onError: error => {
      changeModal('Atenção', 'Erro ao atualizar marca');
    },
  });

  const createBrand = e => {
    e.preventDefault();
    setActive(state === 'Habilitado');

    if (name.length > 0) {
      brandCreate({
        variables: {
          name: name,
          active: state === 'Habilitado',
        },
      });
    } else {
      changeModal('Atenção', 'Nome da marca é obrigatório!');
    }
  };

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  return (
    <>
      <NavLink to="/catalogos/marcas" className="go-back" />
      <div className="content-body brand-reg">
        {modal}
        <div className="brand-reg__title">
          <h2 className="section-title">Criar uma nova marca</h2>
          <p className="brand-reg__text">Use os campos abaixo para criar uma marca</p>
        </div>
        <form onSubmit={createBrand}>
          <div className="brand-reg__fields">
            <OutlinedInput placeholder="Marca" border="true" onChange={e => setName(e.target.value)} />
            <div className="dropdown-label-wrap">
              <Dropdown
                options={stateOptions}
                value={defaultStateOption}
                onChange={e => setState(e.value)}
                placeholder="Estado"
                className="dropdown-ifood"
                controlClassName="dropdown-ifood__control"
                placeholderClassName="dropdown-ifood__placeholder"
                menuClassName="dropdown-ifood__menu"
                arrowClassName="dropdown-ifood__arrow"
              />
              <label className="dropdown-label">Estado</label>
            </div>
            <Button className="btn">Criar nova marca</Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BrandRegister;
