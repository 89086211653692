import React, { useState } from 'react';
import { ReactComponent as HidePassword } from '@assets/icons/Hide.svg';
import { ReactComponent as ShowPassword } from '@assets/icons/Show.svg';
import './showorhidepassword.scss';

const ShowOrHidePassword = ({ actionPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  function handleAction() {
    setShowPassword(!showPassword);
    actionPassword();
  }

  return (
    <>
      {showPassword ? (
        <ShowPassword onClick={() => handleAction()} className="eyeshow" />
      ) : (
        <HidePassword onClick={() => handleAction()} className="eyehide" />
      )}
    </>
  );
};

export default ShowOrHidePassword;
