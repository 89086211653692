import React, { useState, useRef } from 'react';
import { Link } from 'ifoodshop-react-ui'

import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';

import './style.scss';

function SelectLinks({ children, disabled}) {
  const [seeMore, setSeeMore] = useState(false);
  const wrapperRef = useRef(null);

  const root = document.getElementById('root');
  root.addEventListener('click', (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSeeMore(false);
    }
  });

  return (
    <div className={`select-links ${disabled ? 'disabled' : ''}`} ref={wrapperRef}>
      <span
        className="select-links__button"
        onClick={() => setSeeMore(!seeMore)}
      >
        Ver mais ações{' '}
        <ChevronDown
          fill={disabled ? '#A6A6A6' : '##EA1D2C'}
          className={`select-links__icon ${(seeMore && !disabled) ? 'open' : ''}`}
        />
      </span>
      {(seeMore && !disabled) ? (
        <div className="select-links__list">
          {/* 
            insert children with className "item" 
          */}
          {children}
        </div>
      ) : null}
    </div>
  );
}

export default SelectLinks;