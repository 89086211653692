import { Typography } from 'ifoodshop-react-ui';

import Formatter from '@helpers/FormatHelper';
import OrderHelper from '@helpers/OrderHelper';

import './modalPaymentsCancelHistory.scss';

const ModalPaymentsCancelHistory = ({ orderChild }) => {
  return (
    <div className="audit-history">
      <Typography variant="h2">Histórico de reembolsos</Typography>
      <div className="audit-history__table">
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">Pedido</th>
              <th className="table-admin__title">Data</th>
              <th className="table-admin__title">Pagamento</th>
              <th className="table-admin__title">Motivo do estorno</th>
              <th className="table-admin__title">Tipo de estorno</th>
              <th className="table-admin__title">Valor de reembolso</th>
            </tr>
          </thead>
          <tbody>
            {orderChild.paymentsHistory?.map(
              (item, index) =>
                item.originPaymentHistory !== 'CREATED' && (
                  <tr key={`history-item--${index}`}>
                    <td>{`#${item.orderDeliveryId}`}</td>
                    <td>{Formatter.format(item.createdAt, 'date')}</td>
                    <td>{OrderHelper.handlePaymentType(item.type)}</td>
                    <td>{OrderHelper.handleOriginPaymentHistory(item.originPaymentHistory)}</td>
                    <td>{OrderHelper.handleRefundType(item.automaticRefund)}</td>
                    <td>{Formatter.format(item.totalAmount, 'currency')}</td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModalPaymentsCancelHistory;
