import { gql } from 'graphql-tag';

const GET_ESTABLISHMENT_BY_CUSTOMER_ID = gql`
  query getEstablishmentsByCustomerId($params: EstablishmentByCustomerInput!) {
    getEstablishmentsByCustomerId(params: $params) {
      __typename
      ... on EstablishmentByCustomerResponse {
        totalElements
        content {
          id
          name
          active
        }
      }
      ... on NotFound {
        error
        message
      }
    }
  }
`;

export { GET_ESTABLISHMENT_BY_CUSTOMER_ID };
