import React, {useState} from 'react';
import FormRegisterDiscountV2 from '../components/FormRegisterDiscount/FormRegisterDiscountV2'
import { Modal, Button } from 'ifoodshop-react-ui';
import { NavLink } from 'react-router-dom'

import './discountRegister.scss'

import Decoder from '@helpers/Decoder';
import Roles from '@helpers/Roles';

  const DiscountRegister = (props) => {

    var tokenDecodedUserName = null;

    if (props.keycloak)
      tokenDecodedUserName = props.keycloak.tokenParsed.preferred_username;

    const [modal, setModal] = useState(null)

    var tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
    var roleDiscount = tokenDecoded.resource_access["manager"].roles.includes(Roles.DISCOUNT_WR);
    var roleDiscountReadOnly = tokenDecoded.resource_access["manager"].roles.includes(Roles.DISCOUNT_RO);

    if (!roleDiscountReadOnly && !roleDiscount) {
      window.location.href = '/'
    }

    //=========================
    //Feature destivada no momento para ser repensada.
    //=========================
    // function addEvent(obj, evt, fn) {
    //   if (obj.addEventListener) {
    //     obj.addEventListener(evt, fn, false);
    //   }
    //   else if (obj.attachEvent) {
    //     obj.attachEvent("on" + evt, fn);
    //   }
    // }
    // function inactiveModal() {
    //   window.sessionStorage.setItem("modalCloseEver", true)
    //   setModal(null)
    // }

    // addEvent(window,"load",function(e) {
    //   addEvent(document, "mouseout", function(e) {
    //     e = e ? e : window.event;
    //     var from = e.relatedTarget || e.toElement;
    //     if (!from || from.nodeName === "HTML") {
    //       // stop your drag event here
    //       // for now we can just use an alert
    //       if(!window.sessionStorage.getItem("modalCloseEver")) {
    //         setModal(
    //           <Modal className="modal-warning" onCloseAction={() => setModal(null)}>
    //             <p className="modal-warning__description">Você ainda não salvou suas alterações</p>
    //             <Button onClick={() => inactiveModal()}>Ok, entendi</Button>
    //           </Modal>
    //         );
    //       }
    //     }
    //   });
    // });

  return(
    <>
      {(roleDiscountReadOnly || roleDiscount) &&
      <>
        <NavLink to="/campanhas/descontos" className="go-back" />
        {roleDiscount
          ?
            <>
              <div className="content-body discount">
                <h2 className="section-title">Cadastro de descontos</h2>
                <p className="description-form">Use os campos abaixo para criar um novo desconto</p>

                <FormRegisterDiscountV2 userName={tokenDecodedUserName} />
              </div>
              {modal}
            </>
          :
            <>
              <div className="content-body discount">
                <h2 className="section-title">Cadastro de descontos</h2>
                <p className="description-form">Você não tem permissão para criar um novo desconto</p>
              </div>
            </>
        }
        </>
      }
    </>
  )
}

export default DiscountRegister