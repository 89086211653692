import { useEffect, useState, useContext } from 'react';
import { Card, Flex, Text, Input, LinkText, Box } from '@ifood/pomodoro-components';
import { BFFGetCustomerByIdQuery } from '@services/bff/GraphqlOperations';
import { RowWrapper, TitleCard } from '../CustomerDetail.styles';
import { getRegistrationType } from '../customerDetailHelper';
import { CustomerDetailsFormT } from '../types';
import { useGetCustomerFraudListLazyQuery } from '@services/bff/GraphqlHooks';
import CustomerFraudListModal from './CustomerFraudListModal';
import { ThemeContext } from 'styled-components';

type RegistrationDataCardT = {
  customerData: BFFGetCustomerByIdQuery['getCustomerById'];
  isEditMode: boolean;
  form: CustomerDetailsFormT;
};

const ZERO = 0;
const PAGE_SIZE = 10;

export function RegistrationDataCard({ customerData, isEditMode, form }: RegistrationDataCardT) {
  const { register, formState } = form;
  const { errors } = formState;
  const [isModalFraudHistoryOpen, setModalFraudHistory] = useState(false);
  const [getCustomerFraudList, { data: customerFraudListResponse, loading: isLoadingCustomerFraud }] =
    useGetCustomerFraudListLazyQuery();
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (customerData?.id) {
      getCustomerFraudList({
        variables: {
          params: {
            customerId: customerData?.id,
            pageable: { page: ZERO, size: PAGE_SIZE },
          },
        },
      });
    }
  }, [customerData?.id, getCustomerFraudList]);

  return (
    <>
      <Box>
        <Card padding={20}>
          <TitleCard variant="regular">Dados</TitleCard>

          <RowWrapper>
            <Flex flexDirection={'column'} gap={8}>
              <Text color={theme.colors.grayDarkest} variant="regular">
                ID
              </Text>
              {customerData?.id && <Text variant="large">{customerData?.id}</Text>}
            </Flex>
          </RowWrapper>

          <RowWrapper>
            <Flex flexDirection={'column'} gap={8}>
              <Text color={theme.colors.grayDarkest} variant="regular">
                Parceiro iFood
              </Text>
              {customerData?.iderp && <Text variant="large">{customerData?.iderp}</Text>}
            </Flex>
          </RowWrapper>

          <RowWrapper>
            <Flex flexDirection={'column'} gap={8}>
              <Text color={theme.colors.grayDarkest} variant="regular">
                Tipo de cadastro
              </Text>
              {customerData?.kind && <Text variant="large">{getRegistrationType(customerData?.kind)}</Text>}
            </Flex>
          </RowWrapper>

          <RowWrapper>
            <Flex flexDirection={'column'} gap={8}>
              <Text color={theme.colors.grayDarkest} variant="regular">
                Executivo
              </Text>
              {isEditMode && <Input {...register('owner')} error={errors?.owner?.message} />}
              {!isEditMode && <Text variant="large">{customerData?.owner}</Text>}
            </Flex>
          </RowWrapper>

          {customerData?.hasFraudHistory && (
            <Box mb={24} mt={8}>
              <LinkText variant="regular" onClick={() => setModalFraudHistory(true)}>
                Ver histórico de fraudes
              </LinkText>
            </Box>
          )}
        </Card>

        <CustomerFraudListModal
          customerFraud={customerFraudListResponse?.getCustomerFraudList}
          getCustomerFraudList={getCustomerFraudList}
          isLoading={isLoadingCustomerFraud}
          isOpen={isModalFraudHistoryOpen}
          onClose={() => setModalFraudHistory(false)}
        />
      </Box>
    </>
  );
}
