import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPGRADE_ORDER_DELIVERY_STATUS } from "../../../../../../graphql/order-delivery.mutations";
import { Typography, Button } from "ifoodshop-react-ui";

import { useOrderContext } from "@components/_context/OrderContext";
import { formatDateToIsoLocal } from "../../../orderUtils";
import Check from "@components/Check";
import BounceLoader from "@components/Loader/Bounce";

const ModalRequestVehicle = ({ orderDeliveryId }) => {
  const { setStepVehicle } = useOrderContext();

  const [disableButton, setDisableButton] = useState(false)

  const updateOrderStatus = () => {
    setDisableButton(true);
    
     updateOrderDeliveryStatus ({
       variables: {
        orderDeliveryId,
        updateStatus: "IN_TRANSPORTATION",
        updateTime: formatDateToIsoLocal(new Date()),
        trackingCode: null,
      },
     })
  }
   
 const [updateOrderDeliveryStatus,  { loading }] = useMutation( UPGRADE_ORDER_DELIVERY_STATUS ,{
   onCompleted: () => {
        setStepVehicle(2);
      },
 })

  return (
    <div className="modal-request modal-request__column">
      <div className="request-column">
        <div className="request-vehicle">
          <div className="request-vehicle__header">
            <Typography size="24px" weight="regular">
              Solicitar um veículo iFood
            </Typography>
          </div>
          <div className="request-vehicle__text">
            <Typography size="16px" weight="thin">
              Agora que o pedido foi faturado, você <br />
              pode solicitar um{" "}
            </Typography>
            <Typography size="16px" weight="regular">
              veículo iFood{" "}
            </Typography>
            <Typography size="16px" weight="thin">
              para <br /> realizar a entrega do pedido.
            </Typography>
          </div>
          <div className="request-vehicle__instructions">
            <Typography size="16px" weight="regular">
              Instruções
            </Typography>
          </div>
          <div className="request-vehicle__topic">
            <Check />
            <Typography size="16px" weight="thin">
              Entre em contato com o restaurante para <br />
              confirmar que terá alguém no local neste <br />
              período para receber o pedido.
            </Typography>
          </div>
          <div className="request-vehicle__topic">
            <Check />
            <div>
              <Typography size="16px" weight="thin">
                Solicite o veículo.
              </Typography>
            </div>
          </div>
          <div className="request-vehicle__topic">
            <Check />
            <Typography size="16px" weight="thin">
              Identifique o pacote com o número do <br />
              pedido
            </Typography>
          </div>
        </div>
        <div className="request-vehicle__modal" />
      </div>
      <div className="request-vehicle__next-left">
        <Button
          disabled={disableButton}
          onClick={() => updateOrderStatus()}>
          {loading ? <BounceLoader /> : "Solicitar veículo"}
        </Button>
      </div>
    </div>
  );
};
export default ModalRequestVehicle;
