import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_FIELD } from '../../../../graphql/field.mutations';
import { GET_FIELD_VALUE_TYPE } from '../../../../graphql/field.queries';
import Toggle from 'react-toggle';
import Dropdown from 'react-dropdown';
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import ReactTagInput from '@pathofdev/react-tag-input';

import Loading from '@components/Loading';

import '@pathofdev/react-tag-input/build/index.css';
import 'react-dropdown/style.css';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';
import './formSpecification.scss';

const FormRegisterSpecification = ({ categoryId }) => {
  const optionsState = ['Habilitado', 'Desabilitado'];
  let optionsTypeValues = ['Selecione'];
  const optionsType = ['Produto', 'SKU'];
  const [state, setState] = useState('Habilitado');
  const [modal, setModal] = useState(null);
  const [specificationName, setSpecificationName] = useState('');
  const [filter, setFilter] = useState(true);
  const [fieldValues, setFieldsValues] = useState(null);
  const [type, setType] = useState('Produto');
  const [typeValue, setTypeValue] = useState('Selecione');
  const [createField, { error: error, data: data }] = useMutation(CREATE_FIELD);
  const [requiredOption, setRequiredOption] = useState(true);
  const [selectorType, setSelectorType] = useState(true);

  const { data: dataspec, loading } = useQuery(GET_FIELD_VALUE_TYPE);

  if (dataspec && optionsTypeValues.length === 1) {
    let cap = [];
    dataspec['types'].forEach(v => {
      cap.push(v.charAt(0).toUpperCase() + v.slice(1).toLowerCase());
    });
    optionsTypeValues = optionsTypeValues.concat(cap);
  }

  const registerSpecification = e => {
    e.preventDefault();

    if (fieldValues !== null) {
      createField({
        variables: {
          name: specificationName,
          categoryId: parseInt(categoryId),
          active: state === 'Habilitado',
          isFilter: filter,
          type: type === 'Produto' ? 'PRODUCT' : 'SKU',
          fieldValueType: typeValue === 'Selecione' ? null : typeValue.toUpperCase(),
          fieldListValue: fieldValues ? fieldValues.join(',') : fieldValues,
          required: requiredOption,
          selector: selectorType,
        },
      });
    } else {
      changeModal('Atenção', 'Deve ter ao mínimo uma opção disponível.');
    }
  };

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)}>
        <h1>{title}</h1>
        <p>{message}</p>
      </Modal>
    );
  };

  if (error && modal == null) {
    setModal(<Modal onCloseAction={() => setModal('')} title="Erro ao adicionar Especificação"></Modal>);
  }

  if (data && modal == null) {
    window.location.href = '/catalogos/categorias/detalhe/' + categoryId;
  }

  return (
    <div>
      {modal}

      <form className="form-register form-register--specification" onSubmit={registerSpecification}>
        <OutlinedInput
          placeholder="Nome da especificação"
          border
          value={specificationName}
          onChange={e => setSpecificationName(e.target.value)}
        />
        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsState}
            onChange={e => setState(e.value)}
            placeholder=""
            value={optionsState[0]}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Estado</label>
        </div>
        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsType}
            onChange={e => setType(e.value)}
            placeholder=""
            value={type}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Tipo</label>
        </div>

        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsTypeValues}
            onChange={e => setTypeValue(e.value)}
            placeholder=""
            value={typeValue}
            className="dropdown-ifood"
            controlClassName="dropdown-ifood__control"
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
          />
          <label className="dropdown-label">Tipo de campo</label>
        </div>

        {(typeValue === 'List' || typeValue === 'Multivalue') && (
          <div>
            <div>
              <label>Digite as opções</label>
            </div>
            <div className="form-register__tags">
              <ReactTagInput
                tags={fieldValues || []}
                onChange={e => setFieldsValues(e)}
                placeholder="Digite e aperte enter"
              />
            </div>
          </div>
        )}

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Filtrar</span>
          <Toggle defaultChecked={filter} value={filter} onChange={v => setFilter(v.target.checked)} />
        </div>

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Seletor</span>
          <Toggle
            defaultChecked={selectorType}
            value={selectorType}
            onChange={v => setSelectorType(v.target.checked)}
          />
        </div>

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Obrigatório</span>
          <Toggle
            defaultChecked={requiredOption}
            value={requiredOption}
            onChange={v => setRequiredOption(v.target.checked)}
          />
        </div>

        <Button className="btn-save" type="submit">
          Salvar especificação
        </Button>
      </form>
      {loading && <Loading />}
    </div>
  );
};

export default FormRegisterSpecification;
