import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Decoder from '../../../helpers/Decoder';
import Roles from '../../../helpers/Roles';
import Loading from '../../../components/Loading';
import { Link, Modal } from 'ifoodshop-react-ui';

import AuthorizationHeader from '../../../helpers/AuthorizationHeader';

import './uploadImage.scss';
import { api } from '@services/api';



const UploadImage = () => {
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(null);
  const [imagens, setImagens] = useState([]);
  const [imgCounter, setImgcounter] = useState(0);

  const tokenDecoded = Decoder.decode(window.sessionStorage.getItem('token'));
  const sellerAdminShop = tokenDecoded.resource_access['manager'].roles.includes(Roles.SELLER_ADMIN_SHOP);

  if (!sellerAdminShop) {
    window.location.href = '/';
  }

  async function ImagesUpload(event) {
    event.preventDefault();

    const { files } = event.target;

    let images = [];
    let imgSize = [];

    const readFilePromise = file =>
      new Promise(resolve => {
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function (e) {
          let image = new Image();

          image.src = e.target.result;

          images.push(image.src);
          setImagens(images);

          image.onload = function () {
            let height = this.height;
            let width = this.width;

            imgSize.push({ width: width, height: height });
            resolve();
          };
        };
      });

    await Promise.all(
      Array.from(files).map(async file => {
        await readFilePromise(file);
      })
    );
    setData(imgSize.every(item => item.width >= 130 && item.height >= 120));
  }

  function handleImage(e) {
    e.preventDefault();
    setModal(<Loading />);

    let formData = new FormData();

    let ins = document.getElementById('upload-input').files.length;
    for (let x = 0; x < ins; x++) {
      formData.append('files', document.getElementById('upload-input').files[x]);
    }

    api.post('/seller/logo', formData)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setModal(
          <Modal title="Imagens importadas com sucesso.">
            <ul className="modal--link">
              {data.map(item => (
                <li>{item.split('.com')[1]}</li>
              ))}
            </ul>
            <div className="modal--button">
              <button onClick={() => setModal(null)} className="button">
                Ok, entendi
              </button>
            </div>
          </Modal>
        );
      })
      .catch(() => {
        setModal(
          <Modal title="Erro ao importar imagens, tente novamente.">
            <div className="modal--button">
              <button onClick={() => setModal(null)} className="button">
                Ok, entendi
              </button>
            </div>
          </Modal>
        );
      });
  }

  function handleClearClick(e) {
    e.preventDefault();
    setData(null);
  }

  function previousImage(e) {
    e.preventDefault();
    if (imgCounter == 0) {
      setImgcounter(imgCounter);
    } else {
      setImgcounter(imgCounter - 1);
    }
  }

  function nextImage(e) {
    e.preventDefault();
    if (imgCounter == imagens.length - 1) {
      setImgcounter(imgCounter);
    } else {
      setImgcounter(imgCounter + 1);
    }
  }

  return (
    <>
      {sellerAdminShop && (
        <>
          <NavLink to="/fornecedores" className="go-back" />
          <div className="content-body logo-upload">
            <div>
              <h2 className="section-title">Cadastro de logotipo</h2>
              <p className="logo-upload__description">
                Formato: JPG, PNG <br /> Tamanho minimo: 130x120px
              </p>
            </div>
            <div>
              <form id="frmUpload">
                <input type="file" accept="image/*" id="upload-input" multiple hidden onChange={e => ImagesUpload(e)} />
                <label className="logo-upload__button" htmlFor="upload-input">
                  <Link>Adicionar imagens</Link>
                </label>
                {data && data !== null && (
                  <>
                    <div className="logo-upload__preview">
                      <img src={imagens[imgCounter]} alt="Preview imagens" />
                    </div>

                    <div className="logo-upload__gallery-button">
                      <button className="logo-upload__iconPrev" onClick={e => previousImage(e)} />

                      <p>{`${imgCounter + 1} de ${imagens.length}`}</p>

                      <button className="logo-upload__iconNext" onClick={e => nextImage(e)} />
                    </div>

                    <div className="logo-upload__group">
                      <button className="button" type="button" onClick={e => handleClearClick(e)}>
                        Remover Imagens
                      </button>
                      <button onClick={e => handleImage(e)} className="button" type="button">
                        Enviar Imagens
                      </button>
                    </div>
                  </>
                )}
                {!data && data !== null && (
                  <div className="logo-upload__group">
                    <p>Uma ou mais imagens estão menor que o tamanho mínimo exigido</p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </>
      )}
      {modal}
    </>
  );
};

export default UploadImage;
