import { mergeSchema } from './schema';
import { rest } from 'msw';
import { execute } from 'graphql';
import { gql } from 'graphql-tag';
import { urlGraphqlLocal } from '@services/apollo';

export const getHandlers = () => [
  rest.post<{ query: string; variables: any }>(urlGraphqlLocal, async (req, res, ctx) => {
    const result = await execute({
      document: gql`
        ${req.body.query}
      `,
      schema: mergeSchema(),
      variableValues: req.body.variables,
    });

    return res(ctx.json(result));
  }),
];
