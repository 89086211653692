import gql from "graphql-tag";

const GET_ADDRESS = gql`
  query getAddress($postalCode: String) {
	getAddress(postalCode: $postalCode) {
		city
		state
		neighborhood
		address
		postalCode
	}
}
`;

export {
  GET_ADDRESS,
};