import { useEffect } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { OutlinedInput, Typography } from 'ifoodshop-react-ui';

import { useProductSuggestionContext } from '@components/_context/ProductSuggestioContext';
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';

import './productsaleconditions.scss';

const ProductSaleCondition = () => {
  const history = useHistory();
  const {
    setStep,
    price,
    setPrice,
    quantityAvailable,
    setQuantityAvailable,
    sku,
    setSku,
    saleContinue,
    setSaleContinue,
    productId,
  } = useProductSuggestionContext();

  function onlyNumbers(e) {
    return e.target.value.replace(/\D/g, '');
  }

  useEffect(() => {
    if (price !== '' && quantityAvailable !== '' && sku !== '') {
      setSaleContinue(false);
    } else {
      setSaleContinue(true);
    }
  }, [price, quantityAvailable, sku]);

  useEffect(() => {
    if (productId === null || productId === '') {
      history.push('/catalogos/sugestao/cadastro');
    }
  }, []);
  return (
    <>
      <div className="sale-condition">
        <div className="sale-condition sale-condition__title">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Preço
          </Typography>
        </div>
        <div className="sale-condition sale-condition__fields">
          <OutlinedInputCurrency value={price} placeholder="Preço (R$) *" onChange={e => setPrice(e.target.value)} />
        </div>
        <div className="sale-condition sale-condition__title">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Estoque
          </Typography>
        </div>
        <div className="sale-condition sale-condition__fields">
          <OutlinedInput
            placeholder="Quantidade disponível *"
            border
            value={quantityAvailable}
            onChange={e => setQuantityAvailable(onlyNumbers(e))}
          />
        </div>
        <div className="sale-condition sale-condition__title">
          <Typography size="20px" weight="regular" line="24px" color="heavygrey">
            Informações Adicionais
          </Typography>
        </div>
        <div className="sale-condition sale-condition__fields">
          <OutlinedInput placeholder="SKU *" value={sku} border onChange={e => setSku(e.target.value)} />
        </div>
        <div className="button-suggestion">
          <NavLink onClick={() => setStep(1)} to="/catalogos/sugestao/cadastro" className="btn-secondary">
            Voltar
          </NavLink>
          <Link
            onClick={!saleContinue ? () => setStep(3) : ''}
            disabled={saleContinue}
            className={`button btn--red ${saleContinue ? 'active' : ''}`}
            to={!saleContinue && '/catalogos/sugestao/cadastro/resumo'}
          >
            Continuar
          </Link>
        </div>
      </div>
    </>
  );
};

export default ProductSaleCondition;
