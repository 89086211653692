import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { REQUEST_CHARGEBACK } from '../../../../../../graphql/order.mutations';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Typography } from 'ifoodshop-react-ui';

import { pixChargebackTypes } from '../../PixChargeback.utils';
import OutlinedTextarea from '@components/OutlinedTextarea';
import OutlinedInputCurrency from '@components/OutlinedInputCurrency';

import './pixChargebackRequest.scss';

const isZero = 0;

const PixChargebackRequest = ({ onClose, setFeedbackModal, match }) => {
  const { id: orderId } = match.params;
  const [requestChargeback, { error: requestError, data: requestData }] = useMutation(REQUEST_CHARGEBACK);

  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');

  const componentClassName = 'pix-chargeback-request';

  const convertPriceStringToFloat = amountToConvert =>
    parseFloat(amountToConvert.replace(/(R\$ |\.)/g, '').replace(',', '.'));

  const handleOnSubmit = event => {
    event.preventDefault();

    const floatAmount = convertPriceStringToFloat(amount);

    const variables = { amount: floatAmount, reason, orderId };

    requestChargeback({ variables });
  };

  const handleDisabled = () => {
    const isAllowedAmount = convertPriceStringToFloat(amount) > isZero;

    return !reason || !isAllowedAmount;
  };

  // Handle submission feedback
  useEffect(() => {
    if (requestData?.requestChargeback) {
      requestData?.requestChargeback?.success
        ? setFeedbackModal(pixChargebackTypes.SUCCESS)
        : setFeedbackModal(pixChargebackTypes.ERROR);
    }

    requestError && setFeedbackModal(pixChargebackTypes.ERROR);
  }, [setFeedbackModal, requestError, requestData]);

  const maxLengthReasonForWithdraw = 250;

  return (
    <Modal onCloseAction={onClose} title="Deseja solicitar o estorno do pedido?" className={componentClassName}>
      <form onSubmit={handleOnSubmit}>
        <OutlinedInputCurrency
          className="form-register__input"
          placeholder="Informe o valor que deseja solicitar."
          id="chargebackValue"
          value={amount}
          border
          onChange={e => setAmount(e.target.value)}
        />
        <div className={`${componentClassName}__info-reason`}>
          <Typography size="16px" weight="bold" line="24px">
            Informe o motivo da desistência do pedido:
          </Typography>
        </div>

        <OutlinedTextarea
          placeholder="Digite aqui o motivo da desistência"
          value={reason}
          onChange={e => setReason(e.target.value)}
          maxLength={maxLengthReasonForWithdraw}
        />

        <div className={`${componentClassName}__button-wrapper`}>
          <Button white className={`${componentClassName}__close-button`} onClick={onClose} type="button">
            Fechar
          </Button>

          <Button type="submit" disabled={handleDisabled()}>
            Estornar valor
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default withRouter(PixChargebackRequest);
