import { Card, Flex, Text, Box } from '@ifood/pomodoro-components';
import { BFFGetCustomerByIdQuery } from '@services/bff/GraphqlOperations';
import { useContext } from 'react';
import { TitleCard, RowWrapper } from '../CustomerDetail.styles';
import { getIsActiveEmailScale } from '../customerDetailHelper';
import { ThemeContext } from 'styled-components';

type EstablishmentsDataCardT = {
  customerData: BFFGetCustomerByIdQuery['getCustomerById'];
};

export function ContactDataCard({ customerData }: EstablishmentsDataCardT) {
  const theme = useContext(ThemeContext);

  return (
    <Box>
      <Card padding={20}>
        <TitleCard variant="regular">Contato</TitleCard>

        <RowWrapper>
          <Flex flexDirection={'column'} gap={8}>
            <Text color={theme.colors.grayDarkest} variant="regular">
              Responsável
            </Text>
            {customerData?.contactName && <Text variant="large">{customerData?.contactName}</Text>}
          </Flex>
        </RowWrapper>

        <RowWrapper>
          <Flex flexDirection={'column'} gap={8}>
            <Text color={theme.colors.grayDarkest} variant="regular">
              Telefone
            </Text>
            {customerData?.phoneDialCode && customerData?.phone && (
              <Text variant="large">{`(${customerData?.phoneDialCode}) ${customerData?.phone}`}</Text>
            )}
          </Flex>
        </RowWrapper>

        <RowWrapper>
          <Flex flexDirection={'column'} gap={8}>
            <Text color={theme.colors.grayDarkest} variant="regular">
              E-mail
            </Text>
            {customerData?.email && <Text variant="large">{customerData?.email}</Text>}
          </Flex>
        </RowWrapper>

        <RowWrapper>
          <Flex flexDirection={'column'} gap={8}>
            <Text color={theme.colors.grayDarkest} variant="regular">
              Régua de e-mail
            </Text>
            {customerData?.canReceiveNewsletter && (
              <Text variant="large">{getIsActiveEmailScale(customerData?.canReceiveNewsletter)}</Text>
            )}
          </Flex>
        </RowWrapper>
      </Card>
    </Box>
  );
}
