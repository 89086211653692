import React, { FormEvent } from 'react';
import { AddressFormValueType } from '@pages/Partners/Seller/SellerRegistration/types';
import { GET_ADDRESS } from '../../../../../../../graphql/via-cep.queries';
import { handleMaskSetValue, maskInput } from '@helpers/mask';
import { Flex, Grid, Input } from '@ifood/pomodoro-components';
import { UseFormReturn } from 'react-hook-form';
import { useLazyQuery } from '@apollo/react-hooks';
import * as S from '../AdressDataForm.styles';
import Loading from '../../../../../../../components/Loading';

type AddressDataFormFieldsT = {
  form: UseFormReturn<AddressFormValueType, any>;
};

export default function AddressDataFormField({ form }: AddressDataFormFieldsT) {
  const {
    formState: { errors },
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    register,
  } = form;
  const zipcodeLength = 9;
  const resetAddress = () => {
    reset({
      ...getValues(),
      number: '',
      complement: '',
      address: '',
      city: '',
      state: '',
      neighborhood: '',
    });
  };

  const [getAddress, { error: addressError, data: address, loading }] = useLazyQuery(GET_ADDRESS, {
    onCompleted: () => {
      const { getAddress } = address;

      if (!addressError && getAddress?.postalCode === null) {
        return true;
      }

      if (!addressError && !address?.getAddress.error) {
        setValue('zipcode', getAddress?.postalCode);
        setValue('address', getAddress?.address);
        setValue('city', getAddress?.city);
        setValue('neighborhood', getAddress?.neighborhood);
        setValue('state', getAddress?.state);
      } else {
        setError('zipcode', {
          type: 'validate',
        });

        setError('city', { type: 'required' });

        resetAddress();
      }
    },
  });

  const handleInputZipCode = (e: FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = e;

    const valueMask = maskInput({ value, mask: '99999-999' });

    if (valueMask.length === zipcodeLength) {
      getAddress({
        variables: { postalCode: valueMask },
      });
    }

    clearErrors('zipcode');
    setValue('zipcode', valueMask);
    resetAddress();
  };

  return (
    <Flex flexDirection="column">
      <Input
        {...register('zipcode')}
        error={errors?.zipcode?.message}
        label="CEP*"
        marginBottom="large"
        name="zipcode"
        onInput={handleInputZipCode}
      />
      <Input
        {...register('address')}
        error={errors?.address?.message}
        label="Logradouro*"
        marginBottom="large"
        name="address"
      />
      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <S.InputNumber
          {...register('number')}
          error={errors?.number?.message}
          label="Número*"
          marginBottom="large"
          name="number"
        />
        <Input
          {...register('complement')}
          error={errors?.complement?.message}
          label="Complemento"
          marginBottom="large"
          name="complement"
        />
      </Grid>

      <Input
        {...register('neighborhood')}
        error={errors?.neighborhood?.message}
        label="Bairro*"
        marginBottom="large"
        name="neighborhood"
      />
      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Input
          {...register('state')}
          error={errors?.state?.message}
          label="Estado*"
          marginBottom="large"
          maxLength={2}
          name="state"
          onInput={e =>
            handleMaskSetValue({
              mask: 'AA',
              event: e,
              fieldName: 'state',
              setValue,
            })
          }
        />
        <Input {...register('city')} error={errors?.city?.message} label="Cidade*" marginBottom="large" name="city" />
      </Grid>

      {loading && <Loading />}
    </Flex>
  );
}
