import React, { createContext, useContext } from "react";

const Context = createContext();

export function DataSellerProvider({ children, ...props }) {
  return <Context.Provider value={ { ...props } }>{ children }</Context.Provider>
}

export function useDataSellerContext() {
  const context = useContext(Context)
  return context
}
