import { NavLink, Link, useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { Typography } from 'ifoodshop-react-ui';
import debounce from 'lodash.debounce';

import { useProductSuggestionContext } from '@components/_context/ProductSuggestioContext';

import './identifyproduct.scss';

const IdentifyProduct = () => {
  const history = useHistory();
  const {
    setStep,
    productName,
    setProductName,
    setDescription,
    setBrandId,
    setBrandName,
    setCategoryId,
    setCategoryName,
    productContinue,
    setProductContinue,
    setProductId,
    allProducts,
    setAllProducts,
    getProducts,
    setVariationID,
    setVariationName,
    setBarcode,
    barCode,
    setUnity,
    setHeight,
    setWeight,
    setLength,
    setMultiples,
    setWidth,
  } = useProductSuggestionContext();

  const getOptionSelected = option => {
    const values = option.split('--');
    return values[0];
  };

  const setSelectProduct = e => {
    if (allProducts && e.target.value) {
      const optionSelected = getOptionSelected(e.target.value).trim();
      const productSelected = allProducts.find(item => item.name.trim() === optionSelected);
      setVariationID(productSelected ? productSelected.id : null);
      setProductId(productSelected ? productSelected.product.id : null);
      setProductName(productSelected ? productSelected.name : null);
      setProductContinue(productSelected?.id ? false : true);
      setDescription(productSelected?.id ? productSelected.product.description : null);
      setBrandId(productSelected?.id ? productSelected.product.brand.id : null);
      setBrandName(productSelected?.id ? productSelected.product.brand.name : null);
      setCategoryId(productSelected?.id ? productSelected.product.category.id : null);
      setCategoryName(productSelected?.id ? productSelected.product.category.name : null);
      setUnity(productSelected ? productSelected?.unity : null);
      setVariationName(productSelected ? productSelected?.name : null);
      setBarcode(productSelected ? productSelected?.barCode : null);
      setHeight(productSelected ? productSelected?.height : null);
      setWeight(productSelected ? productSelected?.weight : null);
      setLength(productSelected ? productSelected?.length : null);
      setMultiples(productSelected ? productSelected?.quantity : null);
      setWidth(productSelected ? productSelected?.width : null);
    } else {
      setProductId(null);
      setProductName(null);
    }
  };

  const handleSearchProduct = debounce(e => {
    setAllProducts(null);
    getProducts({ variables: { term: e } });
  }, 1000);

  const handleContinue = () => {
    if (!productContinue) {
      setStep(2);
      history.push('/catalogos/sugestao/cadastro/condicao');
    }
  };

  return (
    <>
      <div className="identify-product">
        <div>
          <Autocomplete
            id="productSearch"
            freeSolo
            style={{ width: 1080 }}
            onSelect={e => setSelectProduct(e)}
            value={productName}
            className="autocomplete-ifood"
            noOptionsText="Digite para procurar"
            options={allProducts ? allProducts?.map(option => `${option.name} -- ${option.barCode}`) : []}
            renderInput={params => (
              <TextField
                {...params}
                onChange={e => handleSearchProduct(e.target.value)}
                label="Digite o nome do produto ou código de barras (EAN)"
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setProductContinue(newValue ? false : true);
              }
            }}
          />
        </div>
        <div className="button-suggestion">
          <Link
            onClick={handleContinue}
            disabled={productContinue}
            className={`button btn--red ${productContinue ? 'active' : ''}`}
          >
            Continuar
          </Link>
        </div>
        <div className="suggestion-title">
          <Typography size="20px" weight="regular" line="24px" color="black">
            Não encontrou o produto desejado na lista?
          </Typography>
        </div>
        <div className="suggestion-description">
          <Typography size="18px" weight="regular" line="24px" color="heavygrey">
            Isso significa que este produto ainda não existe em nosso site e você poderá fazer a sugestão deste novo
            produto.
          </Typography>
        </div>
        <div className="suggestion-link">
          <NavLink className="link" to="/catalogos/sugestao/cadastro/produto">
            Quero cadastrar um produto novo
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default IdentifyProduct;
