import { gql } from '@apollo/client';

const GET_SHOPPING_LIST = gql`
  query ShoppingList($shoppingListId: ID!) {
    shoppingList: getSuggestedShoppingListById(shoppingListId: $shoppingListId) {
      ... on ShoppingList {
        id
        name
        url
        status
        rules {
          cuisine
          startDate
          endDate
          id
        }
        items {
          ... on PaginatedShoppingListItem {
            content {
              id
              seller {
                id
                name
              }
              variation {
                id
                name
              }
            }
            numberOfElements
            totalPages
            totalElements
            number
            size
            first
            last
            empty
          }
          ... on CustomError {
            code
            error
            message
          }
        }
      }
      ... on CustomError {
        code
        error
        message
      }
    }
  }
`;

const GET_CUISINES = gql`
  query cuisines($country: String) {
    cuisines: getCuisines(COUNTRY_CODE: $country) {
      content {
        code
        friendlyName
      }
    }
  }
`;

const GET_SELLERS = gql`
  query Sellers($page: Int, $size: Int, $name: String) {
    sellers: getPaginateSeller(companyName: $name, active: true, page: $page, size: $size) {
      content {
        id
        name
      }
      numberOfElements
      totalPages
      totalElements
      number
      size
      size
      first
      last
      empty
    }
  }
`;

const GET_PRODUCT_VARIATIONS_BY_SELLER = gql`
  query variations($page: Int, $size: Int, $sellerId: Int, $name: String) {
    variations: getAllProductVariations(pageNumber: $page, pageSize: $size, sellerId: $sellerId, name: $name) {
      content {
        id
        name
      }
      numberOfElements
      totalPages
      totalElements
      number
      size
      first
      last
      empty
    }
  }
`;

const GET_CUSTOMERS_BY_PARAMS = gql`
  query Customers($param: String, $page: Int, $size: Int) {
    customers: getCustomerListByEmailNameOrId(param: $param, page: $page, size: $size) {
      id
      name
      email
      phone
      phoneDialCode
    }
  }
`;

const GET_ESTABLISHMENTS_BY_NAME = gql`
  query Establishments($param: String, $page: Int, $size: Int) {
    establishments: getEstablishmentListByName(establishmentName: $param, page: $page, size: $size) {
      id
      name
      phoneDialCode
      phone
      address {
        address
        number
        complement
        neighborhood
        city
        state
        country
      }
    }
  }
`;

const CREATE_SHOPPING_LIST = gql`
  mutation createShoppingList($createShoppingListInput: CreateShoppingListInput) {
    shoppingList: createSuggestedShoppingList(createShoppingListInput: $createShoppingListInput) {
      id
      name
      status
      url
      rules {
        startDate
        endDate
      }
    }
  }
`;

const UPDATE_SHOPPING_LIST = gql`
  mutation updateShoppingList($updateShoppingListInput: UpdateShoppingListInput) {
    shoppingList: updateSuggestedShoppingList(updateShoppingListInput: $updateShoppingListInput) {
      id
      name
      status
      url
      rules {
        startDate
        endDate
      }
    }
  }
`;

export {
  GET_SHOPPING_LIST,
  GET_CUISINES,
  GET_SELLERS,
  GET_PRODUCT_VARIATIONS_BY_SELLER,
  GET_CUSTOMERS_BY_PARAMS,
  GET_ESTABLISHMENTS_BY_NAME,
  UPDATE_SHOPPING_LIST,
  CREATE_SHOPPING_LIST,
};
