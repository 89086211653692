/* istanbul ignore file */
import { Flex } from '@ifood/pomodoro-components';
import { BFFBonusShopStatementContent } from '@services/bff/GraphqlTypes';
import Formatter from '@helpers/FormatHelper';
import * as S from './StatementDetailPaymentType.styles';

interface IStatementDetailPaymentType {
  data: BFFBonusShopStatementContent;
}
export function StatementDetailPaymentType(props: IStatementDetailPaymentType) {
  const { data } = props;
  const transactionType = data.transactionType as keyof typeof typePayments;
  const transactionData = data.transactionDate as string;
  const orderDescription = data?.description ? data.description : 'Bônus Shop';
  const bonusDescription = data?.description ? data.description : 'Saldo adicionado';
  const amountDescription = Formatter.currency(data?.amount);
  const transactionDateFormat = (date: string) => {
    return `${new Date(date).toLocaleDateString('pt-bt')} às ${new Date(date).toLocaleTimeString('pt-bt', {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };
  const typePayments = {
    DEBIT: (
      <>
        <Flex flexDirection={'column'}>
          <Flex justifyContent={'space-between'}>
            <S.Heading>Pedido - Nº {data?.orderId}</S.Heading>
            <S.AvailableAmount>- {amountDescription}</S.AvailableAmount>
          </Flex>
          <Flex flexDirection={'column'}>
            <S.Paragraph as={'p'}>{orderDescription}</S.Paragraph>
            <S.Text>
              ID: {data?.customerId} - {transactionDateFormat(transactionData)}
            </S.Text>
          </Flex>
        </Flex>
      </>
    ),
    CREDIT: (
      <>
        <Flex flexDirection={'column'}>
          <Flex justifyContent={'space-between'}>
            <S.Heading isCredit>Bônus Shop</S.Heading>
            <S.AvailableAmount isCredit>{amountDescription}</S.AvailableAmount>
          </Flex>
          <Flex flexDirection={'column'}>
            <S.Paragraph as={'p'}>{bonusDescription}</S.Paragraph>
            <S.Text>Criado em {Formatter.ptBrDate(data?.startDate, 'simple')}</S.Text>
            <S.Text>Expiração em {Formatter.ptBrDate(data?.endDate, 'simple')}</S.Text>
          </Flex>
        </Flex>
      </>
    ),
  };
  return typePayments[transactionType];
}
