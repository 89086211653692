/* eslint-disable no-magic-numbers */
import { Flex, Grid, Text, Button, Heading3 } from '@ifood/pomodoro-components';
import { useSellerFormContext } from '../../../context/SellerFormContext';
import * as S from './BankDataForm.styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BankFormValueType, ComponentCommonPropsType } from '@pages/Partners/Seller/SellerRegistration/types';
import { pageSteps, SellerFormActions } from '../../../SellerFormType';
import schema from './schema';
import BankDataFormFields from './components/BankDataFormFields';

const BankDataForm = ({ triggerChangeEvent }: ComponentCommonPropsType): JSX.Element => {
  const { state } = useSellerFormContext();
  const form = useForm<BankFormValueType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      ...state.bankFormData,
    },
  });
  const { handleSubmit } = form;

  const handlePreviousStep = () => {
    triggerChangeEvent(pageSteps.AddressStep, SellerFormActions.setCurrentStep);
  };

  const onSubmit: SubmitHandler<BankFormValueType> = data => {
    triggerChangeEvent(data, SellerFormActions.setBankFormData);
    triggerChangeEvent(pageSteps.ContractualStep, SellerFormActions.setCurrentStep);
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.DividerSection marginBottom="large" />
      <Grid gridGap={10} gridTemplateColumns="1fr 1Fr">
        <Flex flexDirection="column">
          <Heading3 my={4} variant="smallest">
            Dados bancários
          </Heading3>
          <Text variant="regular">Dados e informações de cadastro do fornecedor</Text>
        </Flex>
        <BankDataFormFields bankFormData={state.bankFormData} form={form} />
      </Grid>

      <S.DividerSection marginBottom="large" />
      <Flex alignItems="flex-end" justifyContent="flex-end">
        <Button variant="primary-inverted" onClick={handlePreviousStep}>
          Voltar
        </Button>
        <Button type="submit">Avançar</Button>
      </Flex>
    </S.Form>
  );
};

export default BankDataForm;
