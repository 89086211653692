/* eslint-disable @typescript-eslint/no-explicit-any */
import './App.scss';
import { HeadProvider, Title } from 'react-head';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import { PomodoroProvider } from '@ifood/pomodoro-components';
import '@ifood/pomodoro-fonts';

import { initializeAboyeur } from '@analytics/aboyeur';
import { KeycloakProvider } from '@context/KeycloakContext';
import { GlobalProvider } from '@context/GlobalContext';
import { hotjar } from '@helpers/Hotjar';
import apolloClient from '@services/apollo';
import Secured from '@pages/Secured';
import { SellerFormProvider } from './pages/Partners/Seller/context/SellerFormContext';

const HeadProviderAsAny = HeadProvider as any;
const HeadTitleAsAny = Title as any;
const PomodoroProviderAsAny = PomodoroProvider as any;

const App = (): JSX.Element => {
  const hotjarKey = 1925368;

  return (
    <ApolloProvider client={apolloClient}>
      <KeycloakProvider>
        <GlobalProvider>
          <PomodoroProviderAsAny locale="pt-br" snackbarPosition="top-right">
            <SellerFormProvider>
              <div className="main">
                <HeadProviderAsAny>
                  <HeadTitleAsAny>Portal iFood Shop</HeadTitleAsAny>
                  {hotjar.initialize(hotjarKey, '')}
                  {initializeAboyeur()}
                </HeadProviderAsAny>
                <BrowserRouter basename="/">
                  <Secured />
                </BrowserRouter>
              </div>
            </SellerFormProvider>
          </PomodoroProviderAsAny>
        </GlobalProvider>
      </KeycloakProvider>
    </ApolloProvider>
  );
};

export default App;
