import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const TableProductVariation = ({ variations, role, productid }) => {
  if (variations.length > 0) {
    return (
      <Fragment>
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">ID</th>
              <th className="table-admin__title">Nome</th>
              <th className="table-admin__title">Produto</th>
              <th className="table-admin__title">Categoria</th>
              <th className="table-admin__title">Marca</th>
              <th className="table-admin__title">Ativo</th>
              <td className="table-admin__title">Código referência</td>
              <th className="table-admin__title"></th>
            </tr>
          </thead>
          <tbody>
            {variations.map(prod => (
              <tr id={prod.id} key={prod.id}>
                <td className="table-admin__data">{prod?.id}</td>
                <td className="table-admin__data">{prod?.name}</td>
                <td className="table-admin__data">{prod?.product ? prod?.product?.name : ''}</td>
                <td className="table-admin__data">{prod?.product ? prod?.product?.category?.name : ''}</td>
                <td className="table-admin__data">{prod?.product ? prod?.product?.brand?.name : ''}</td>
                <td className="table-admin__data">{prod?.active ? <a> Habilitado</a> : <a>Desabilitado</a>} </td>
                <td className="table-admin__data">{prod?.referenceCode ? prod?.referenceCode : ''}</td>
                <td className="table-admin__data">
                  <NavLink className="link" to={`/catalogos/produtos/${productid}/variacoes/editar/${prod.id}`}>
                    Editar
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  } else {
    return <a></a>;
  }
};

export default TableProductVariation;
