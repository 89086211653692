import { ReactElement } from 'react';

export enum LIST_OWNERS {
  CUSTOMER = 'CUSTOMER',
  ESTABLISHMENT = 'ESTABLISHMENT',
}

export type LIST_OWNERS_KEYS = CUSTOMER | ESTABLISHMENT;

export enum LIST_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export type Seller = {
  id: string | number;
  name: string;
};

export type Variation = {
  id: string | number;
  name: string;
};

export type Address = {
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
};

export type Establishment = {
  id: string | number;
  name: string;
  address?: Address;
  phoneDialCode: string;
  phone: string;
};

export type Customer = {
  id: string | number;
  name: string;
  email?: string;
  phoneDialCode: string;
  phone: string;
};

export type ShoppingListItem = {
  id: string | number | null;
  seller?: Seller;
  variation: Variation;
  uiId?: string | null;
};

export type ShoppingListItemInput = {
  id: number | string | null;
  variationId: number | string;
  sellerId: number | string | null;
  quantity: number;
  uiId?: string;
};

export type ShoppingListRules = {
  id: string | number;
  customers: Customer[];
  establishments: Establishment[];
  startDate: Date;
  endDate: Date;
  cuisine: string;
};

export type ShoppingListRulesInput = {
  id: string | number;
  customerId: (string | number)[];
  establishmentId: (string | number)[];
  startDate: Date | null;
  endDate: Date | null;
  cuisine: string;
};

export type ShoppingList = {
  id?: string | number;
  name: string;
  status: LIST_STATUS;
  items: ShoppingListItem[];
  rules: ShoppingListRules;
};

export type ShoppingListInput = {
  id?: string | number;
  name: string;
  status: LIST_STATUS;
  items: ShoppingListItemInput[];
  rules: ShoppingListRulesInput;
};

export interface ITableColum<T> {
  id: number;
  name: string;
  label: string;
  alignment: 'left' | 'center' | 'right';
  formatter: (v: T, i?: number) => ReactElement | HTMLElement | ReactNode | string;
}

export interface IOwnersConfig<T> {
  field: string;
  label: string;
  ownerType: LIST_OWNERS;
  tableColumns: ITableColum<T>[];
}
