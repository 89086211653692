import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { OutlinedInput, Button, Modal } from 'ifoodshop-react-ui';
import { useHistory } from 'react-router-dom'
import Toggle from 'react-toggle'

import FormAddProductVariation from './FormAddProductVariation';
import Loading from '@components/Loading';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_COLLECTION, CHECK_UNIOQUE_NAME, CREATE_COLLECTION, EDIT_COLLETION } from '@graphql/collection.queries'

import ptbr from 'date-fns/locale/pt-BR';

const FormRegisterCollection = ({ idCollection, userName }) => {

  const [dataCollection, setDataColletion] = useState(null)
  const [nameCollection, setNameCollection] = useState();
  const [initialNameCollectionEdit, setInitialNameCollectionEdit] = useState()
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [dataAddVariation, setDataAddVariation] = useState()
  const [productVariation, setProductVariation] = useState([])
  const [active, setActive] = useState(true);
  const [modal, setModal] = useState();
  const [messageError, setMessageError] = useState(false);
  const currentDate = new Date();

  // requests
  const [getCollectionById, { loading: loadingGetCollection, data: dataGetCollection }] = useLazyQuery(GET_COLLECTION);
  const [checkUniqueName, { data: dataCheckUniqueName }] = useLazyQuery(CHECK_UNIOQUE_NAME, {
    variables: { name: nameCollection }
  });
  const [createCollection, { loading: loadingCreate, data: dataCreate }] = useMutation(CREATE_COLLECTION);
  const [editColletion, { loading: loadingEdit, data: dataEdit }] = useMutation(EDIT_COLLETION);

  if (dataCreate || dataEdit) {
    window.location.href = '/campanhas/colecoes'
  };
  if (dataGetCollection && dataCollection === null) {
    setDataColletion(dataGetCollection);
    setNameCollection(dataGetCollection.getCollection.name);
    setInitialNameCollectionEdit(dataGetCollection.getCollection.name);
    setInitialDate(new Date(dataGetCollection.getCollection.startDate));
    setFinalDate(new Date(dataGetCollection.getCollection.endDate));
    setDataAddVariation(dataGetCollection.getCollection.variations);
    setActive(dataGetCollection.getCollection.active);
    var dataProduct = []
    if (dataGetCollection.getCollection.productCollectionOrders) {
      dataGetCollection.getCollection.productCollectionOrders.sort((a, b) => a.productOrder - b.productOrder)
      dataGetCollection.getCollection.productCollectionOrders.map(item => {
        dataProduct.push({
          idProductOrder: item.id,
          key: item.product.id,
          product: item.product.name
        })
      })

      setProductVariation(dataProduct)
    }
  }

  useEffect(() => {
    if (idCollection) {
      getCollectionById({
        variables: {
          idCollection: parseInt(idCollection)
        }
      })
    }
  }, []);

  useEffect(() => {
    if (dataCheckUniqueName) {
      setMessageError(dataCheckUniqueName.checkUniqueName)
    }
  }, [dataCheckUniqueName]);

  function handleSubmit(e) {
    e.preventDefault()


    let tempProductVariation = []
    dataAddVariation.map((item, index) => {
      tempProductVariation.push({
        id: item.idProductOrder ? item.idProductOrder : null,
        productId: item.key,
        productOrder: index
      })
    });

    function showModal(message) {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          <h3>Atenção</h3>
          <p>{message}</p>
        </Modal>
      )
    }

    if (!nameCollection || !initialDate || !finalDate) {
      showModal('Preencha todos os campos do formulário');
    } else if (dataAddVariation.length < 1) {
      showModal('Você precisa adicionar pelo menos um produto');
    } else if (messageError) {
      showModal('Você precisa mudar o nome da colação, o nome inserido já existe');
    } else if (initialDate > finalDate) {
      showModal('Você precisa mudar a data final, data inválida menor que a de início');
    } else if (currentDate > initialDate) {
      showModal('Você precisa mudar a data de início, data inválida menor que a data atual');
    } else {
      if (!idCollection) {
        createCollection({
          variables: {
            userName: userName,
            name: nameCollection,
            productId: tempProductVariation,
            startDate: initialDate,
            endDate: finalDate,
            active: active
          }
        })
      } else {
        editColletion({
          variables: {
            userName: userName,
            collectionId: idCollection,
            name: nameCollection,
            productId: tempProductVariation,
            startDate: initialDate,
            endDate: finalDate,
            active: active
          }
        })
      }
    }
  }

  function focusOut(e) {
    if (nameCollection && (initialNameCollectionEdit !== nameCollection)) {
      checkUniqueName();
    }
  }

  return (
    <>
      <form onSubmit={e => handleSubmit(e)} className="form-admin form-admin--discount">

        <div className="form-admin__group">
          <div className="toggle-wrap">
            <span className="toggle-wrap__label">Ativo</span>
            <Toggle checked={active} value={active} onChange={v => setActive(v.target.checked)} />
          </div>
        </div>
        <div className="form-admin__group">
          <OutlinedInput
            placeholder="Nome da coleção"
            onBlur={(e) => focusOut(e)}
            border
            maxlength="50"
            id="voucherName"
            value={nameCollection}
            onChange={(e) => setNameCollection(e.target.value)}
          />
          {messageError &&
            <span className="form-add-customer--label-error">* nome já existe </span>
          }
        </div>

        <div className="form-admin__group">
          <div className={`outlined-label-wrap outlined-date ${initialDate ? 'active' : ''}`}>
            <DatePicker
              className="form-brand__input"
              onChange={date => setInitialDate(new Date(date))}
              locale={ptbr}
              showTimeSelect
              timeIntervals={15}
              selected={initialDate}
              dateFormat="dd/MM/yyyy hh:mm aa"
              minDate={new Date()}
            />
            <label className="outlined-label">Data Inicial</label>
          </div>
          <div className={`outlined-label-wrap outlined-date ${finalDate ? 'active' : ''}`}>
            <DatePicker
              className="form-brand__input"
              onChange={date => setFinalDate(new Date(date))}
              locale={ptbr}
              showTimeSelect
              timeIntervals={15}
              selected={finalDate}
              dateFormat="dd/MM/yyyy hh:mm aa"
              minDate={initialDate || new Date()}
            />
            <label className="outlined-label">Data Final</label>
          </div>
        </div>

        <div className="form-admin__area form-admin__area--variations">
          <FormAddProductVariation
            title="Produtos e variação"
            description="Digite para buscar um produto"
            dragContent
            multipleProducts
            dataAddVariation={e => setDataAddVariation(e)}
            productVariation={productVariation}
          />
        </div>

        <div className="form-admin__area">
          <Button type="submit">{!idCollection ? 'Criar coleção' : 'Salvar coleção'}</Button>
        </div>
      </form>

      {modal}
      {(loadingCreate || loadingEdit || loadingGetCollection) &&
        <Loading />
      }
    </>
  )
}

export default FormRegisterCollection;