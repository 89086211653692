import { useEffect, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import client from '@services/apollo';
import { GET_SELLER } from '../../../../graphql/seller.queries';

import './tableOffer.scss';

const TableOffer = ({ offers, role, rolePriceKgSeller, productid, variationid }) => {
  const [PreData, setPredata] = useState(offers);
  const [TableData, setTableData] = useState([]);

  const populatetable = data => {
    let temparray = [];
    let size = data.length;
    let count = 0;

    data.map(function (offer, index) {
      client
        .query({
          query: GET_SELLER,
          variables: {
            id: offer.sellerId,
          },
        })
        .then(res => {
          let tempitem = {
            id: offer.id,
            seller: res.data.getSellerById.name,
            price: offer.price,
            pricePerKg: offer?.pricePerKg,
            stock: offer.stock,
            active: offer.active,
          };
          temparray.push(tempitem);
          count++;
          if (size == count) {
            setTableData(temparray);
          }
        });
    });
  };

  useEffect(() => {
    populatetable(PreData);
  }, [PreData]);

  if (offers.length > 0) {
    return (
      <Fragment>
        <table className="table-admin table-admin--sample table-admin--offer">
          <thead>
            <tr>
              <th className="table-admin__title">Lojista</th>
              <th className="table-admin__title">Preço</th>
              {rolePriceKgSeller && <th className="table-admin__title">Preço por Kg</th>}
              <th className="table-admin__title">Estoque</th>
              <th className="table-admin__title">Ativo</th>
              <th className="table-admin__title" />
            </tr>
          </thead>
          <tbody>
            {TableData ? (
              TableData.map(offer => (
                <tr id={offer.id} key={offer.id}>
                  <td className="table-admin__data">{offer.seller}</td>
                  <td className="table-admin__data">
                    {offer.price.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  {rolePriceKgSeller && (
                    <td className="table-admin__data">
                      {offer?.pricePerKg?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                  )}
                  <td className="table-admin__data">{offer.stock}</td>
                  <td className="table-admin__data">{offer.active ? <a> Habilitado</a> : <a>Desabilitado</a>}</td>
                  <td className="table-admin__data">
                    {
                      <NavLink
                        className={'link'}
                        to={`/catalogos/produtos/${productid}/variacoes/${variationid}/ofertas/editar/${offer.id}`}
                      >
                        Editar
                      </NavLink>
                    }
                  </td>
                </tr>
              ))
            ) : (
              <a></a>
            )}
          </tbody>
        </table>
      </Fragment>
    );
  } else return null;
};

export default TableOffer;
