import gql from 'graphql-tag';

const GET_VARIATIONS_BY_PRODUCT = gql`
  query getAllProductVariations(
    $page: Int
    $size: Int
    $name: String
    $createdAt: String
    $productId: Int
    $active: Boolean
  ) {
    getAllProductVariations(
      pageNumber: $page
      pageSize: $size
      name: $name
      createdAt: $createdAt
      active: $active
      productId: $productId
    ) {
      content {
        id
        name
        active
        referenceCode
        product {
          id
          name
          category {
            id
            name
          }
          brand {
            id
            name
          }
        }
      }
      totalPages
      size
    }
  }
`;

const GET_PRODUCT_VARIATION = gql`
  query getProductVariationById($id: ID) {
    getProductVariationById(id: $id) {
      name
      active
      price
      width
      weight
      height
      length
      quantity
      barCode
      unity
      createdAt
      updatedAt
      referenceCode
      error
      errorCode
    }
  }
`;

const GET_VARIATIONS_TERM = gql`
  query getFindByTerm($term: String) {
    getFindByTerm(term: $term) {
      id
      name
      active
      price
      width
      weight
      height
      length
      quantity
      barCode
      unity
      createdAt
      updatedAt
      referenceCode
      error
      errorCode
      product {
        id
        name
        description
        category {
          id
          name
        }
        brand {
          id
          name
        }
      }
    }
  }
`;

const GET_VARIATION_IMAGES = gql`
  query findAllVariationImage($variation: Int) {
    findAllVariationImage(variationId: $variation) {
      id
      imagePath
      active
      order
    }
  }
`;

export { GET_VARIATIONS_BY_PRODUCT, GET_PRODUCT_VARIATION, GET_VARIATION_IMAGES, GET_VARIATIONS_TERM };
