import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_RUPTURE_LOTE } from '../../../../../../graphql/order.mutations';
import { Button, Typography } from 'ifoodshop-react-ui';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrders } from '@analytics/aboyeur/entities/sessionOrders';

import Radio from '@components/Radio/Input';
import OutlinedTextarea from '@components/OutlinedTextarea';
import Loading from '@components/Loading';
import { ModalRuptureFooter } from '@components/Modal/ModalFooter';

import '../../../ModalsStepsOrder/ListReason.scss';
import '../../../../../../components/Modal/ModalFooter/ModalFooter.scss';

const ModalReasonInLote = ({
  setModal,
  reasonRuture,
  handleSetModal,
  footerAlign,
  checkedRupture,
  sellerCode,
  setSuccess,
}) => {
  const [stateReasonValue, setStateReasonValue] = useState(null);
  const [stateDisabledButton, setStateDisabledButton] = useState(true);
  const [stateTextareaReason, setStateTextareaReason] = useState(null);
  const otherReason = 'Outro motivo';
  //faster
  const sessionOrders = useAboyeur(SessionOrders);

  const [updateLote, { loading: loadingUpdate }] = useMutation(UPDATE_RUPTURE_LOTE, {
    onError: () => {
      handleSetModal('CONFIRMATION');
      setSuccess(false);
    },
    onCompleted: data => {
      data?.updateRuptureLote?.errorCode ? setSuccess(false) : setSuccess(true);
      handleSetModal('CONFIRMATION');
    },
  });

  const handleSubmitForm = e => {
    e.preventDefault();
    const valueReason = stateTextareaReason === null ? stateReasonValue.value : stateTextareaReason;
    const itemSellerSetId = checkedRupture.map(item => item.sellerSetId).join(',');
    const InputValues = checkedRupture.map(item => ({
      orderId: item.orderId,
      sellerCode: sellerCode,
      weight: null,
      quantity: 0,
      reason: valueReason,
    }));
    updateLote({
      variables: {
        sellerId: checkedRupture[0]?.seller?.id,
        input: InputValues,
      },
    });
    //faster - Session
    sessionOrders.events.orders.clickButtonMultipleQuantityConfirm({
      //Propriedades > Metadata
      childOrderId: itemSellerSetId,
    });
  };

  const handleChangeRadio = e => {
    setStateReasonValue({
      id: e.target.id,
      value: e.target.value,
    });
    setStateTextareaReason(null);
    setStateDisabledButton(false);
  };

  return (
    <>
      <div className="content-steps-order">
        <Typography className="modal__title" size="20px" weight="regular" line="32px" color="black" variant="h2">
          Remoção de produto
        </Typography>

        <div className="wrapper-reason">
          <Typography size="16px" weight="bold" line="24px" color="black" variant="h2">
            Informe o motivo da remoção dos produtos
          </Typography>

          <form onSubmit={handleSubmitForm}>
            <ul className="list-reason">
              {reasonRuture?.map(item => {
                return (
                  <li key={item.id} className="list-reason__item">
                    <Radio id={item.id} name="order" value={item.title} onChange={e => handleChangeRadio(e)} />
                    <label for={item.id}>{item.title}</label>
                  </li>
                );
              })}
            </ul>
            {stateReasonValue?.value === otherReason && (
              <OutlinedTextarea
                placeholder="Digite aqui o motivo da desistência"
                onChange={e => setStateTextareaReason(e.target.value)}
              />
            )}
            <ModalRuptureFooter footerAlign={footerAlign}>
              <Button className="btn-secondary button-lg" onClick={() => setModal(null)}>
                Fechar
              </Button>
              <Button type="submit" className="button-lg" disabled={stateDisabledButton}>
                Concluir remoção
              </Button>
            </ModalRuptureFooter>
          </form>
        </div>
      </div>
      {loadingUpdate && <Loading />}
    </>
  );
};

export default ModalReasonInLote;
