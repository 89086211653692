import FormEditProductCategory from '../components/FormEditProductCategory';
import HeaderRegisterCategory from '../components/HeaderRegisterCategory';

import './editProductCategory.scss';

const EditProductCategory = props => {
  const { id } = props.match.params;

  return (
    <>
      <a href={'/catalogos/categorias/detalhe/' + id} className="go-back"></a>
      <div className="register-category">
        <HeaderRegisterCategory
          title="Editar especificação"
          description="Use os campos abaixo para uma especificação."
        />
        <FormEditProductCategory />
      </div>
    </>
  );
};

export default EditProductCategory;
