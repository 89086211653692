import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { InputLabel, TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { debounce, throttle } from 'throttle-debounce';

type Props<T> = {
  inputLabel: string;
  loading: boolean;
  options?: T[];
  classes?: string;
  onSearch(v: string): void;
  onSelect: (v: T) => void;
  optionLabel: (o: T) => string;
  optionValue: (o: T, v: T) => boolean;
};

const Autocomplete = ({
  inputLabel,
  loading,
  options,
  classes,
  onSearch,
  onSelect,
  optionLabel,
  optionValue,
}: Props<Record<string, unknown>>): JSX.Element => {
  const debounceTimer = 2000;
  const throttleTimer = 4000;
  const minTermLength = 5;

  const [term, setTerm] = useState<string>('');

  const handleOptionSelection = (option: Record<string, unknown> | null) => {
    if (!option) return;
    onSelect(option);
  };

  const autocompleteSearch = useCallback((value: string) => onSearch(value), [onSearch]);

  const debounceFunc = useMemo(() => debounce(debounceTimer, autocompleteSearch), [autocompleteSearch]);

  const throttleFunc = useMemo(() => throttle(throttleTimer, autocompleteSearch), [autocompleteSearch]);

  useEffect(() => {
    if (!term) return;
    term.length < minTermLength ? debounceFunc(term) : throttleFunc(term);
  }, [term, debounceFunc, throttleFunc]);

  return (
    <MuiAutocomplete
      className={classes}
      getOptionLabel={optionLabel}
      getOptionSelected={optionValue}
      loading={loading}
      loadingText="Carregando"
      noOptionsText="Sem opções"
      options={options || []}
      renderInput={params => (
        <>
          <InputLabel style={{ marginBottom: 4, lineHeight: '24px' }}>{inputLabel}</InputLabel>
          <TextField {...params} InputProps={{ ...params.InputProps }} name="search-input" variant="outlined" />
        </>
      )}
      onChange={(_event: ChangeEvent<any>, v: Record<string, unknown> | null) => handleOptionSelection(v)}
      onInputChange={(event: ChangeEvent<Record<string, unknown>>) => setTerm(event.target.value as string)}
    />
  );
};

export default Autocomplete;
