import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import TableComissions from "./components/TableComissions";
import Roles from "@helpers/Roles"
import Decoder from "@helpers/Decoder";
import Box from "@material-ui/core/Box";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from "@material-ui/core/Typography";
import './comission.scss'
import {Button, Link, Modal} from "ifoodshop-react-ui";
import ReactPaginate from "react-paginate";
import IconArrow from "@assets/images/arrow.png";
import HeaderCommissions from "./components/HeaderCommissions";
import HeaderCommissionSearch from "./components/HeaderCommissionsSearch";
import {useMutation} from "@apollo/react-hooks";
import {UPDATE_COMMISSION} from "@graphql/commission.mutations";
import Loading from "@components/Loading";
import { getRoles } from "@helpers/rolesUtils";

const Commission = (props) => {
  const [page, setPage] = useState(0);
  const [activetab, setActivetab] = useState(0);
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(null);

  const items = [{name: "Padrão", index: 0},
    {name: "Categorias", index: 1},
    {name: "Produtos", index: 2}]

  const [name, setName] = useState("Padrão");
  const [index, setIndex] = useState(0);

  const [updateCommissions] = useMutation(UPDATE_COMMISSION);

  //AUTHORIZATION
  const roles = getRoles()
  const roleCommissionWR = roles.includes(Roles.COMMISSION_WR);
  const roleCommissionRO = roles.includes(Roles.COMMISSION_RO);

  if (!roleCommissionRO || !roleCommissionWR) {
    window.location.href = '/fornecedores'
  }

  const modalRemove = (commission) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} className="modal-remove"
             title="Tem certeza que deseja desabilitar a comissão selecionada?"
      >
        <div className="modal-remove__actions">
          <Link onClick={() => setModal(null)}>Cancelar</Link>
          <Button onClick={() => {
            deleteCommission(commission)
          }}>Desabilitar</Button>
        </div>
      </Modal>
    )
  }


  function deleteCommission(commission) {
    setModal(<Loading/>)
    updateCommissions({
      variables: commission
    }).then(res => {
      setModal(null)
    }).catch(error => {
      setModal(
        <Modal onCloseAction={() => setModal(null)} title="Atenção">
          <p>Erro ao desabilitar a comissão</p>
        </Modal>
      )
    })
  }


  function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={activetab !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function TabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    const {name, index} = items.find(item => item.index === newValue);
    setName(name);
    setIndex(index);
    setActivetab(newValue);
    setPage(0)
  };

  return (
    <>
      {(roleCommissionWR && roleCommissionRO) &&
      <>
        <NavLink to="/fornecedores/detail" className="go-back"/>
        <div className="content-body search-commissions">

          <HeaderCommissions sellerId={props.match.params.id}/>

          <div className="custom-commission">
            <h2 className="section-title">Comissões customizadas</h2>
            <Tabs value={activetab} onChange={handleTabChange}
                  aria-label="simple tabs example">
              <Tab label="Padrão" {...TabProps(0)} />
              <Tab label="Categorias" {...TabProps(1)} />
              <Tab label="Produtos" {...TabProps(2)} />
            </Tabs>

            <div>
              {modal}
              <TabPanel value={activetab} index={index}>
                <div
                  className={`${data ? "header-customer-group" : "header-customer-group header-customer-group--table-commissions"} `}>
                  <div className="header-customer-group__info">
                    <h2 className="section-title">{name}</h2>
                  </div>
                  <div className="header-customer-group__new">
                    <NavLink className="link"
                             to={`/fornecedores/${props.match.params.id}/comissoes/cadastro/${name.toLowerCase()}`}>Adicionar {name.toLowerCase()}
                    </NavLink>
                  </div>
                </div>
              </TabPanel>

              <>
                <HeaderCommissionSearch
                  key={index}
                  type={
                    name === 'Categorias' ? 'Categoria' :
                      name === 'Produtos' ? 'Produto'
                        : "Padrão"}
                  returnData={e => setData(e)} currentPage={page} sellerId={props.match.params.id}/>
                {data ?
                  <div className="form-row">
                    <div className="table-box">
                      <TableComissions
                        data={data}
                        type={
                          name === "Padrão" ? "DEFAULT" :
                            name === "Categorias" ? "CATEGORY" : "OFFER"
                        }
                        removeCommissions={e => modalRemove(e)}
                      />
                      {data && data.totalPages > 1
                        ?
                        <div className="search-brand-paginate">
                          <ReactPaginate
                            containerClassName="pagination-ifoodshop"
                            pageClassName="pagination-ifoodshop__item"
                            pageLinkClassName="pagination-ifoodshop__item-link"
                            activeLinkClassName="active"
                            previousLabel={<img className="previous-arrow" src={IconArrow}/>}
                            nextLabel={<img className="next-arrow" src={IconArrow}/>}
                            onPageChange={e => setPage(e.selected)}
                            pageRangeDisplayed={3}
                            pageCount={data.totalPages}
                            marginPagesDisplayed={1}
                            initialPage={data.number}
                            forcePage={data.number != page ? data.number : page}
                          />
                        </div>
                        :
                        null}
                    </div>
                  </div>
                  : null}
              </>
            </div>
          </div>
          <div className="save-commissions">
            <Button className='btn' form="frm-reg__commissions-default">Salvar comissão padrão</Button>
          </div>
        </div>
      </>
      }
    </>
  )
}

export default Commission;
