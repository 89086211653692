import { Card, Flex, Heading3, Text, Grid } from '@ifood/pomodoro-components';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSellerDetailsData } from '../../SellerDetailsHook';

const AddressDataDetails = (): JSX.Element => {
  const { sellerResponse } = useSellerDetailsData();
  const theme = useContext(ThemeContext);

  return (
    <Card>
      <Flex flexDirection="column">
        <Heading3 mb={24} mt={4} variant="smallest">
          Endereço
        </Heading3>
        <Text color={theme.colors.grayDarkest} variant="regular">
          CEP
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.address?.zipcode}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Logradouro
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.address?.address}
        </Text>
        <Grid gridTemplateColumns="1fr 1Fr">
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Número
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.address?.number}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Complemento
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.address?.complement}
            </Text>
          </Flex>
        </Grid>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Bairro
        </Text>
        <Text mb={12} variant="large">
          {sellerResponse?.getSellerV2?.address?.neighborhood}
        </Text>
        <Grid gridTemplateColumns="1fr 1Fr">
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Cidade
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.address?.city}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Estado
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.address?.stateName}
            </Text>
          </Flex>
        </Grid>
        <Grid gridTemplateColumns="1fr 1Fr">
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Latitude
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.address?.latitude}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text color={theme.colors.grayDarkest} variant="regular">
              Longitude
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.address?.longitude}
            </Text>
          </Flex>
        </Grid>
      </Flex>
    </Card>
  );
};

export default AddressDataDetails;
