import gql from 'graphql-tag';

const CREATE_SPECIFICATION = gql `
    mutation createSpecification($field: Int, $product: Int, $value: String){
    createSpecification(input: {fieldId: $field, productId: $product, value: $value}){
        id
        value
    }
}
`;

const UPDATE_SPECIFICATION = gql `
    mutation updateSpecification($id: Int, $field: Int, $product: Int, $value: String){
        updateSpecification(id: $id, input: {fieldId: $field, productId: $product, value: $value}){
        id
        value
        
    }
}
`;

const DELETE_SPECIFICATIONS = gql `
    mutation deleteSpecification($product: Int) {
        deleteSpecification (product: $product)
    }
`;

export {CREATE_SPECIFICATION, UPDATE_SPECIFICATION, DELETE_SPECIFICATIONS};