import iFood from '@assets/icons/iFood.svg';
import Correios from '@assets/icons/correios.svg';
import Default from '@assets/icons/Combined Shape.svg';

function checkContractsNames(names, compare) {
  let listNames = names.slice().sort();
  let params = compare.slice().sort();

  let equal = params.every((val, idx) => val === listNames[idx]);

  return equal;
}

const contractCombinations = {
  CORREIOS: 'Shop',
  SELLER: 'Própria',
  IFOOD: 'iFood',
  DARKSTORE: 'DarkStore',
  FULLSERVICE: 'Fullservice',
};

export function handleTypes(contracts) {
  const contractNames = contracts.map(contract => contractCombinations[contract]).filter(Boolean);

  const matchingDescription = contractNames
    ? new Intl.ListFormat('pt-BR', { style: 'long', type: 'conjunction' }).format(contractNames)
    : '';
  return `Logística ${matchingDescription || 'não identificada'}`;
}

const Logo = {
  IFOOD: () => iFood,
  CORREIOS: () => Correios,
  SELLER: () => Default,
  DARKSTORE: () => Default,
  FULLSERVICE: () => Default,
};

const Description = {
  IFOOD: () =>
    'A intermediação do processo de logístico será realizada pelo iFood Shop. As entregas serão realizadas de acordo com os termos de contratação de Logística Parceira iFood (1p)',
  FULLSERVICE: () =>
    'A intermediação do processo de logístico será realizada pelo iFood Shop. As entregas serão realizadas de acordo com os termos de contratação de Logística Parceira iFood (1p) - Fullservice',
  CORREIOS: () =>
    'O processo logístico é de responsabilidade do iFood Shop. As entregas serão realizadas pelo parceiro logístico Correios.',
  SELLER: () =>
    'Todo o processo logístico será de sua responsabilidade. As entregas serão realizadas pela sua própria transportadora.',
  DARKSTORE: () =>
    'Todo o processo logístico será de sua responsabilidade. As entregas serão realizadas pela sua própria transportadora.',
};

const Title = {
  IFOOD: () => 'Logística Parceira iFood',
  CORREIOS: () => 'Logística Correios',
  SELLER: () => 'Logística Própria',
  DARKSTORE: () => 'Logística DarkStore',
  FULLSERVICE: () => 'Logística Fullservice',
};

export function formatDataSeller(type, item) {
  if (type.toUpperCase() === 'TITLE') return Title[item.toUpperCase()]();
  else if (type.toUpperCase() === 'DESCRIPTION') return Description[item.toUpperCase()]();
  else if (type.toUpperCase() === 'LOGO') return Logo[item.toUpperCase()]();
  return '';
}
