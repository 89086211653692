import { Button, Flex, Text } from '@ifood/pomodoro-components';
import { NavLink, useHistory } from 'react-router-dom';

export default function Header() {
  const history = useHistory();

  function goToRegisterDiscount() {
    history.push('/campanhas/descontos/cadastro');
  }
  return (
    <Flex alignItems="center" justifyContent="space-between" marginBottom={70}>
      <div>
        <NavLink className="go-back" style={{ top: '55px' }} to={'/campanhas'} />
        <Text fontSize={20} fontWeight={500} paddingBottom={20}>
          Campanhas de descontos
        </Text>
      </div>

      <Button size="medium" onClick={goToRegisterDiscount}>
        <Text color="white" fontWeight={700} variant="medium">
          Criar nova campanha
        </Text>
      </Button>
    </Flex>
  );
}
