/* istanbul ignore file */
import { BFFFraudType, BFFOrdersStatus } from '@services/bff/GraphqlTypes';

export function getRegistrationType(value?: string) {
  return value === 'PERSON' ? 'Pessoa Física' : 'Pessoa Jurídica';
}

export function getIsActiveEmailScale(value?: boolean) {
  return value ? 'Ativa' : 'Inativa';
}

export function getFraudReasonByType(fraudEnum?: BFFFraudType) {
  const fraudTypes: Record<BFFFraudType, string> = {
    CHARGEBACK_FRAUD: 'Fraude por chargeback',
    COUPON_FRAUD: 'Fraude por cupom',
  };

  return fraudTypes[fraudEnum as BFFFraudType];
}

export function getFraudStatus(active?: boolean) {
  return active ? 'Bloqueio' : 'Ativação';
}

export function getEstablishmentOrderStatus(statusType?: BFFOrdersStatus) {
  if (!statusType) return '';

  const statusTypes: Record<BFFOrdersStatus, string> = {
    CANCELLED: 'Cancelado',
    DELIVERY_PENDING: 'Entrega pendente',
    FINISHED: 'Finalizado',
    ORDER_PLACED: 'Pedido realizado',
    PAYMENT_CANCELLED: 'Pagamento cancelado',
    PAYMENT_PENDING: 'Aguardando pagamento',
  };

  return statusTypes[statusType];
}
