/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
import {
  useGetCustomerByIdQuery,
  useReportFraudLogMutation,
  useRemoveFraudBlockMutation,
} from '@services/bff/GraphqlHooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { snackbar } from '@ifood/pomodoro-components';
import { getSessionEmail } from '@helpers/rolesUtils';
import { useParams } from 'react-router-dom';
import schemaReportFraud, { CustomerFraudReportType } from './CustomerFraudReportModal/schema';
import schemaRemoveFraud, { CustomerFraudRemoveType } from './CustomerRemoveFraudModal/schema';
import { useState } from 'react';

const TIMEOUT_INTERVAL = 1000;

export function useCustomerFraudModalsHook() {
  const params = useParams<{ id: string }>();
  const { data } = useGetCustomerByIdQuery({ variables: { customerId: params.id } });
  const customerData = data?.getCustomerById;
  const [fraudReport, { loading: isReportingFraud }] = useReportFraudLogMutation();
  const [removeFraud, { loading: isRemovingFraudBlock }] = useRemoveFraudBlockMutation();
  const [modalFraudReport, setModalFraudReport] = useState<boolean>(false);
  const [modalFraudRemove, setModalFraudRemove] = useState<boolean>(false);
  const [modalFraudFinishReport, setModalFraudFinishReport] = useState<boolean>(false);
  const [modalFraudData, setModalFraudData] = useState({ reasons: '', note: '' });

  const formReportFraud = useForm<CustomerFraudReportType>({
    resolver: yupResolver(schemaReportFraud),
    mode: 'onChange',
    defaultValues: {},
  });

  const formRemoveFraud = useForm<CustomerFraudRemoveType>({
    resolver: yupResolver(schemaRemoveFraud),
    mode: 'onChange',
    defaultValues: {},
  });

  const sessionEmail = getSessionEmail();
  const getCurrentDate = () => new Date().toLocaleString('sv-SE').replace(' ', 'T');

  async function onSubmitFraudReport(values: CustomerFraudReportType) {
    const actualDate = getCurrentDate();

    try {
      await fraudReport({
        variables: {
          fraud: {
            customerId: parseInt(params.id),
            fraudType: values.reasons,
            details: values.note,
            reportedAt: actualDate,
            reportedBy: sessionEmail,
          },
        },
      });
      alertSuccess();
    } catch (error) {
      alertError();
    }
  }

  async function onSubmitFraudRemove(values: CustomerFraudRemoveType) {
    const actualDate = getCurrentDate();

    try {
      await removeFraud({
        variables: {
          inactivation: {
            customerId: parseInt(params.id),
            details: values.note,
            inactivatedAt: actualDate,
            inactivatedBy: sessionEmail,
          },
        },
      });
      alertSuccess();
    } catch (error) {
      alertError();
    }
  }

  const alertSuccess = () => {
    snackbar({
      message: 'Atualização de fraude concluída!',
      variant: 'success',
      timeout: 4000,
      autoClose: true,
    });
    setTimeout(() => {
      refreshPage();
    }, TIMEOUT_INTERVAL);
  };

  const alertError = () => {
    snackbar({
      message: 'Erro ao salvar, por favor tente novamente mais tarde.',
      variant: 'error',
      timeout: 4000,
      autoClose: true,
    });
  };

  function refreshPage() {
    window.location.reload();
  }

  return {
    formReportFraud,
    formRemoveFraud,
    customerData,
    isReportingFraud,
    params,
    isRemovingFraudBlock,
    onSubmitFraudRemove,
    onSubmitFraudReport,
    modalFraudReport,
    setModalFraudReport,
    modalFraudRemove,
    setModalFraudRemove,
    modalFraudFinishReport,
    setModalFraudFinishReport,
    modalFraudData,
    setModalFraudData,
  };
}
