import { Button, Dialog, TextArea } from '@ifood/pomodoro-components';
import styled from 'styled-components';

const CustomDialog = styled(Dialog)`
  max-width: none;
  width: 35%;
`;

const CustomTextArea = styled(TextArea)`
  height: 132px;
`;

const Form = styled.form`
  width: 100%;
`;

const ButtonCancel = styled(Button)`
  margin-right: 24px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

const ButtonSave = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
`;

export { CustomDialog, CustomTextArea, ButtonSave, ButtonCancel, Form };
