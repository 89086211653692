/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-no-undef */
import { Dialog, Heading3, DialogProps } from '@ifood/pomodoro-components';
import { ReactNode } from 'react';
import * as S from './ModalForm.styles';

interface IModalFormProps {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  footer?: ReactNode;
  // eslint-disable-next-line no-unused-vars
  onClose: () => void;
  modalProps?: Partial<DialogProps>;
}

const ModalForm = ({ isOpen, title, children, onClose, footer, modalProps }: IModalFormProps): JSX.Element => {
  return (
    <S.CustomDialog {...modalProps} open={isOpen} onClose={onClose}>
      <Dialog.Header>
        <Heading3 my={2} variant="medium">
          {title}
        </Heading3>
      </Dialog.Header>
      <Dialog.Body>{children}</Dialog.Body>
      {footer && <Dialog.Footer>{footer}</Dialog.Footer>}
    </S.CustomDialog>
  );
};

export default ModalForm;
