/* istanbul ignore file */
import { Box, Card } from '@ifood/pomodoro-components';
import { StatementDetailTimeline } from './StatementDetailTimeline';
import { StatementDetailAmount } from './StatementDetailAmount';
import { TitleCard, RowWrapper } from '../../CustomerDetail.styles';

export function StatementDetailCard() {
  return (
    <Box>
      <Card padding={20}>
        <TitleCard variant="regular">Extrato</TitleCard>
        <RowWrapper>
          <StatementDetailAmount />
        </RowWrapper>
        <StatementDetailTimeline />
      </Card>
    </Box>
  );
}
