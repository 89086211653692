import * as yup from 'yup';

export type CustomerFraudReportType = {
  reasons: string;
  note: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomerFraudReportTypeT = { [key in keyof CustomerFraudReportType]: any };

// SCHEMA
const schemaReportFraud = yup.object().shape({
  reasons: yup.string().required(),
  note: yup.string(),
} as CustomerFraudReportTypeT);

export default schemaReportFraud;
