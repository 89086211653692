import gql from 'graphql-tag';

const GET_BANK = gql`
  query getBankBySellerId($sellerId: Int!) {
    getBankBySellerId(sellerId: $sellerId) {
      account
      accountType
      agency
      bankAccountId
      bankCode
      bankId
      bankName
      bankShortName
    }
  }
`;

const GET_ALL_BANKS = gql`
  query getAllBanks($code: String, $id: Int, $name: String) {
    getAllBanks(code: $code, id: $id, name: $name) {
      id
      code
      name
    }
  }
`;

export { GET_BANK, GET_ALL_BANKS };
