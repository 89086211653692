import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_OFFERS, GET_OFFERS_BY_SELLER } from '../../../../graphql/offer.queries';

import Roles from '@helpers/Roles';
import { getRoles, getSellerId } from '@helpers/rolesUtils';

import TableOffer from '../TableOffer';

const ListOffer = props => {
  const roles = getRoles();
  const roleOfferWriter = roles.includes(Roles.OFFER_WR);
  const roleOfferReader = roles.includes(Roles.OFFER_RO);
  const rolePriceKgSellerRO = roles.includes(Roles.PRICE_KG_SELLER_RO);
  const sellerId = getSellerId();

  function ReadOnly(prdWriter) {
    if (!prdWriter) {
      return 'disabled';
    }
    return '';
  }

  const [createOffer, setCreateOffer] = useState(null);
  const [offers, setOffers] = useState(null);
  const [getOffers, { data: dataoffer }] = useLazyQuery(GET_OFFERS);
  const [getOffersBySeller, { data: dataofferbySeller }] = useLazyQuery(GET_OFFERS_BY_SELLER);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (sellerId) {
      getOffersBySeller({
        variables: {
          variation: parseInt(props.variationid),
        },
      });
    } else {
      getOffers({
        variables: {
          variation: parseInt(props.variationid),
          pageNumber: page,
          pageSize: 10,
        },
      });
    }
  }, [page]);

  useEffect(() => {
    if (dataoffer && dataoffer.findOffersPaginated.content) {
      setOffers(dataoffer.findOffersPaginated.content);
    }
    if (ReadOnly(roleOfferWriter) != 'disabled') {
      setCreateOffer(1);
    } else {
      setCreateOffer(null);
    }
  }, [dataoffer]);

  useEffect(() => {
    if (dataofferbySeller && dataofferbySeller.findOffersBySeller) {
      setOffers(dataofferbySeller.findOffersBySeller);

      if (dataofferbySeller.findOffersBySeller.length === 0 && ReadOnly(roleOfferWriter) != 'disabled') {
        setCreateOffer(1);
      } else {
        setCreateOffer(null);
      }
    }
  }, [dataofferbySeller]);

  return (
    <>
      {roleOfferReader || roleOfferWriter ? (
        <>
          <div className="header-customer-group header-customer-group--src">
            <div className="header-customer-group__info">
              <h2 className="section-title">Lista de Ofertas</h2>
            </div>

            <div className="header-customer-group__new">
              {roleOfferWriter ? (
                <NavLink
                  className={'link'}
                  to={`/catalogos/produtos/${props.productid}/variacoes/${props.variationid}/ofertas/criar`}
                >
                  Criar nova oferta
                </NavLink>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 interface-search-brand__table-box">
              {offers ? (
                <TableOffer
                  offers={offers}
                  variationid={props.variationid}
                  productid={props.productid}
                  rolePriceKgSeller={rolePriceKgSellerRO}
                  role={roleOfferWriter}
                />
              ) : (
                <a> </a>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default ListOffer;
