import gql from 'graphql-tag';

const CREATE_BRAND = gql `
    mutation createBrand($name: String, $active: Boolean){
      createBrand(input: {name: $name, active: $active}){
        id
        name
        active
        createdAt
        error
      }
    }
`;
const UPDATE_BRAND = gql `
      mutation updateBrand($id: ID!, $name: String, $active: Boolean){
        updateBrand(id: $id, input: {name: $name, active: $active}){
          id
          name
          active
          createdAt
          error
        }
      }
    `;
export {CREATE_BRAND, UPDATE_BRAND}
