import { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELLERS } from '../../graphql/seller.queries';
import { Typography, Button, Grid } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import './modalSelectSellersExport.scss';

const ModalSelectSellersExport = ({ onExport }) => {
  const [sellerId, setSellerId] = useState(null);
  const [getSeller, { data: dataSeller }] = useLazyQuery(GET_SELLERS);

  const handleSelectSeller = e => {
    const { value } = e.target;
    if (dataSeller?.getSellerByName && value) {
      const sellerFounded = dataSeller.getSellerByName.find(seller => seller.name === value);
      setSellerId(sellerFounded?.id);
    } else {
      setSellerId(null);
    }
  };

  return (
    <div className="modal-select-sellers-export">
      <Grid wrap>
        <Grid.Item xs={12}>
          <div className="modal-select-sellers-export__subtitle">
            <Typography size="14px" weight="thin" line="14px" color="heavygrey">
              Selecione os fornecedores para a exportação
            </Typography>
          </div>
        </Grid.Item>
      </Grid>
      <Grid wrap className="modal-select-sellers-export__search">
        <Grid.Item xs={9}>
          <Autocomplete
            id="sellersSearch"
            freeSolo
            onSelect={e => handleSelectSeller(e)}
            className="autocomplete-ifood"
            noOptionsText="Digite para procurar"
            options={dataSeller?.getSellerByName ? dataSeller.getSellerByName.map(option => option.name) : []}
            renderInput={params => (
              <TextField
                {...params}
                onChange={e => getSeller({ variables: { name: e.target.value } })}
                label="Fornecedor"
                variant="outlined"
              />
            )}
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <div className="modal-select-sellers-export__button-add">
            <Button type="button" onClick={() => onExport(sellerId)} disabled={!sellerId}>
              Exportar
            </Button>
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default ModalSelectSellersExport;
