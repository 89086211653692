/* istanbul ignore file */
import { useEffect, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Text, Button } from '@ifood/pomodoro-components';
import Toggle from 'react-toggle';
import {
  Content,
  ContentLabel,
  ContentSubtitle,
  ContentTitle,
  DateLabel,
  Divider,
  Header,
  InlineContainer,
} from './styles';
import { Status } from '../components/Status';
import { ReactComponent as Info } from '@assets/icons/Info.svg';
import { ReactComponent as Arrow } from '@assets/icons/arrow-right.svg';
import FormatHelper from '@helpers/FormatHelper';
import { DISCOUNT_TYPE } from '@pages/Campaign/Discount/utils/types';
import { useGetDiscountV2LazyQuery } from '@services/bff/GraphqlHooks';

const ZERO = 0;

export function DiscountDetails(props: any) {
  const history = useHistory();
  const id = parseInt(props.match.params.id);
  const [getDiscount, { data: dataGetDiscount }] = useGetDiscountV2LazyQuery();
  const discountData = dataGetDiscount?.getDiscountV2;
  const discountType = DISCOUNT_TYPE.get(discountData?.type || ``);
  const rules = useMemo(() => {
    return discountData?.rules?.flatMap(rule => rule?.values?.map(value => value));
  }, [discountData]);

  function goToEditCampaign() {
    history.push(`/campanhas/descontos/editar/${id}`);
  }

  useEffect(() => {
    getDiscount({ variables: { idDiscount: id.toString() } });
  }, [getDiscount, id]);

  return (
    <div className="content-body">
      <Header>
        <div className="title">
          <NavLink className="go-back" to="/campanhas/descontos" />

          <Text fontSize={'20px'} fontWeight={'500'} lineHeight={'24px'}>
            {discountData?.name}
          </Text>

          <Status active={discountData?.showSite} />
        </div>
        <Button onClick={goToEditCampaign}>Editar campanha</Button>
      </Header>

      <Content>
        <div className="content-wrapper">
          <h1 className="title">Campanha</h1>
          <div>
            <ContentTitle>Campanha ativa</ContentTitle>
            <Toggle checked={discountData?.active} />
          </div>
          <div>
            <ContentSubtitle>Nome de exibição</ContentSubtitle>
            <ContentLabel>{discountData?.couponCode}</ContentLabel>
          </div>
          <div>
            <ContentSubtitle>
              Selo produto <Info />
            </ContentSubtitle>
            <ContentLabel>{discountData?.highlight}</ContentLabel>
          </div>
          <InlineContainer>
            <div>
              <ContentSubtitle>Tipo</ContentSubtitle>
              <ContentLabel>{discountType}</ContentLabel>
            </div>
            {discountData?.store && (
              <div>
                <ContentSubtitle>Canais</ContentSubtitle>
                <ContentLabel>{discountData.store}</ContentLabel>
              </div>
            )}
          </InlineContainer>
          <div>
            <ContentSubtitle>Descrição</ContentSubtitle>
            <ContentLabel>{discountData?.description}</ContentLabel>
          </div>

          <Divider />

          <h1 className="title">Desconto</h1>
          <div>
            <ContentSubtitle>Valor de desconto (%)</ContentSubtitle>
            <ContentLabel>{discountData?.subsidies && discountData?.subsidies[ZERO]?.percentage}%</ContentLabel>
          </div>

          <InlineContainer>
            <div>
              <ContentSubtitle>Data inicial</ContentSubtitle>
              <DateLabel>{FormatHelper.format(discountData?.startDate, 'date')}</DateLabel>
            </div>
            <div>
              <ContentSubtitle>Data inicial</ContentSubtitle>
              <DateLabel>{FormatHelper.format(discountData?.endDate, 'date')}</DateLabel>
            </div>
          </InlineContainer>

          <InlineContainer>
            <div>
              <ContentSubtitle>
                Valor mínimo de compra <Info />
              </ContentSubtitle>
              <DateLabel>{FormatHelper.format(discountData?.minimumValue, 'currency')}</DateLabel>
            </div>
            <div>
              <ContentSubtitle>
                Valor máximo de desconto <Info />
              </ContentSubtitle>
              <DateLabel>{FormatHelper.format(discountData?.maxValue, 'currency')}</DateLabel>
            </div>
          </InlineContainer>

          <Divider />

          <h1 className="title">Condições</h1>
          <InlineContainer>
            <div>
              <ContentSubtitle>
                Limite de utilização por cliente <Info />
              </ContentSubtitle>
              <ContentLabel>{discountData?.customerMaxUsage}</ContentLabel>
            </div>
            <div>
              <ContentSubtitle>
                Limite de utilizações <Info />
              </ContentSubtitle>
              <ContentLabel>{discountData?.couponMaxUsage}</ContentLabel>
            </div>
          </InlineContainer>

          <div>
            <ContentSubtitle>Quantidade utilizada</ContentSubtitle>
            <ContentLabel>{(discountData?.couponMaxUsage || ZERO) - (discountData?.couponUsage || ZERO)}</ContentLabel>
          </div>
        </div>

        <div className="content-wrapper">
          <h1 className="title">Subsídio</h1>

          {discountData?.subsidies?.map((subsidy, index) => (
            <div key={index}>
              <ContentTitle>Centro de custo</ContentTitle>
              <ContentLabel>{subsidy?.costCenter?.name}</ContentLabel>
            </div>
          ))}

          <Divider />

          {rules?.map((rule, index) => (
            <DateLabel key={index}>
              <Arrow /> {rule?.name}
            </DateLabel>
          ))}
        </div>
      </Content>
    </div>
  );
}
