import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CATEGORY } from '../../../../graphql/category.queries';
import { GET_BRANDS } from '../../../../graphql/brand.queries';
import { UPDATE_PRODUCT } from '../../../../graphql/product.mutations';
import { CREATE_SPECIFICATION, DELETE_SPECIFICATIONS } from '../../../../graphql/specification.mutation';
import { GET_SPECIFICATIONS } from '../../../../graphql/specification.queries';
import { GET_PRODUCT_BY_ID } from '../../../../graphql/product.queries';
import Dropdown from 'react-dropdown';
import Toggle from 'react-toggle';
import { OutlinedInput, Modal } from 'ifoodshop-react-ui';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ProductCustomFields from '../ProductCustomFields';
import AutocompleteCategory from '@components/AutocompleteCategory';
import TinyEditor from '@components/TextEditor';
import Loading from '@components/Loading';

import 'react-dropdown/style.css';
import 'react-toggle/style.css';
import './formEdit.scss';
import '../../../../components/DropDownIfood/dropdown-ifood.scss';

const FormEditProduct = props => {
  const readonly = props.readonly;
  const [requiredFields, setRequiredFields] = useState([]);
  const [category, setCategory] = useState(null);
  const [brand, setBrand] = useState(null);
  const [fieldKeys, setFieldKeys] = useState(null);
  const [fields, setFields] = useState(null);
  const optionsState = ['Habilitado', 'Desabilitado'];
  const [modal, setModal] = useState(null);
  const [productName, setProductName] = useState('');
  const [state, setState] = useState(optionsState[0]);
  const [visible, setVisible] = useState(false);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [fieldsValues, setFieldsValues] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandOptions, setBrandOptions] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [productDescription, setDescription] = useState(null);

  const [getSpecs, { data: dataSpecs }] = useLazyQuery(GET_SPECIFICATIONS, {
    fetchPolicy: 'no-cache',
  });

  const [getBrands, { data: dataBrands }] = useLazyQuery(GET_BRANDS, {
    onCompleted: () => {
      setBrandOptions(dataBrands.getAllBrands.content);
    },
  });

  const [getCategoryDetail, { data: dataCategoryDetail }] = useLazyQuery(GET_CATEGORY, {
    onCompleted: () => {
      setFields(
        dataCategoryDetail?.getCategoryDetailById?.fields ? dataCategoryDetail?.getCategoryDetailById?.fields : null
      );
      setFieldKeys(
        Object.keys(
          dataCategoryDetail?.getCategoryDetailById?.fields ? dataCategoryDetail?.getCategoryDetailById?.fields : []
        )
      );

      if (dataCategoryDetail?.getCategoryDetailById.fields) {
        Object.values(dataCategoryDetail?.getCategoryDetailById?.fields).forEach(content => {
          content.forEach(item => {
            item.required && setRequiredFields(prevState => [...prevState, item]);
          });
        });
      }
    },
  });

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: data => {
      if (data?.updateProduct?.error === 'PRODUCT_NAME_NOT_AVAILABLE') {
        changeModal('Atenção', 'Já existe um produto cadastrado com o mesmo nome!');
      } else {
        deleteSpecs();
      }
    },
    onError: () => {
      changeModal('Atenção', 'Erro ao adicionar produto');
    },
  });

  const [deleteSpecifications] = useMutation(DELETE_SPECIFICATIONS, {
    onCompleted: () => {
      if (fields && Object.entries(fieldsValues).length > 0) {
        registerSpecifications();
      } else window.location.href = '/catalogos/produtos/';
    },
    onError: () => {
      changeModal('Atenção', 'Erro ao atualizar especificações.');
    },
  });

  const [createSpecification] = useMutation(CREATE_SPECIFICATION, {
    onCompleted: () => {
      window.location.href = '/catalogos/produtos/';
    },
    onError: () => {
      changeModal('Atenção', 'Erro ao atualizar especificações.');
    },
  });

  const { data: dataGetProduct, loading } = useQuery(GET_PRODUCT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: { id: parseInt(props.id) },
    onCompleted: () => {
      if (productName === '') {
        setProductName(dataGetProduct.getProductV2.name);
        setDescription(dataGetProduct.getProductV2.description);
        setCategoryId(dataGetProduct.getProductV2.category.id);
        setCategoryName(dataGetProduct.getProductV2.category.name);
        setBrandId(dataGetProduct.getProductV2.brand.id);
        setBrandName(dataGetProduct.getProductV2.brand.name);
        setBrand(dataGetProduct.getProductV2.brand);
        setCategory(dataGetProduct.getProductV2.category);
        dataGetProduct.getProductV2.active ? setState('Habilitado') : setState('Desabilitado');
        setVisible(dataGetProduct.getProductV2.visible);
      }
    },
  });

  useEffect(() => {
    getSpecs({
      variables: {
        product: parseInt(props.id),
      },
    });
  }, [dataGetProduct]);

  useEffect(() => {
    setFieldsValues([]);
    setFields(null);
    setRequiredFields([]);
    setFieldKeys(null);
    categoryId &&
      getCategoryDetail({
        variables: {
          id: categoryId,
          fieldsType: 'PRODUCT',
        },
      });
  }, [categoryId]);

  useEffect(() => {
    getBrands({
      variables: {
        brandName: brandName,
      },
    });
  }, [brandName]);

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  function deleteSpecs() {
    deleteSpecifications({ variables: { product: parseInt(props.id) } });
  }

  function registerSpecifications() {
    let list = Object.keys(fieldsValues);
    list.forEach(field => {
      let fieldValue = fieldsValues[field].value;

      Object.values(fields).map(content => {
        content.forEach(subField => {
          if (subField.id === parseInt(field) && fieldValue) {
            createSpecification({
              variables: {
                product: parseInt(props.id),
                field: parseInt(field),
                value: fieldValue ?? '',
              },
            });
          }
        });
      });
    });

    window.location.href = '/catalogos/produtos/';
  }

  useEffect(() => {
    props.edit && editProduct(props.edit);
  }, [props]);

  function checkRequiredFields() {
    let empty = 1;
    if (requiredFields.length > 0) {
      if (Object.values(fieldsValues).length < requiredFields.length) {
        empty = -1;
      } else {
        Object.values(fieldsValues).forEach(item => {
          if (item.value === '' || item.value === undefined) {
            empty = -1;
          }
        });
      }
    }

    if (empty === 1) return empty;
    else {
      changeModal('Atenção', "As especificações com '*' são obrigatórias.");
      return empty;
    }
  }

  const editProduct = ({ e }) => {
    if (checkRequiredFields() === 1) {
      e.preventDefault();
      let validacao = 1;
      let senditems = {
        id: parseInt(props.id),
        name: productName,
        categoryId: categoryId,
        brandId: brandId,
        active: state === 'Habilitado',
        searchVisible: visible,
        description: productDescription === null ? ' ' : productDescription,
      };
      Object.keys(senditems).map(function (objectKey, index) {
        let size = Object.keys(senditems).length - 1;
        let value = senditems[objectKey];
        if (value === '' || value === null) {
          changeModal('Atenção', 'Todos os campos são obrigatórios');
          validacao = 0;
        }
        if (index === size && validacao === 1) {
          updateProduct({ variables: senditems });
        }
      });
    }
  };

  function handleFieldsValue(e) {
    setFieldsValues(e);
  }

  return (
    <div>
      {modal}
      <form className="form-register form-register--product" id="frmeditproduct">
        <OutlinedInput
          placeholder="Nome do Produto"
          border
          value={productName}
          onChange={e => setProductName(e.target.value)}
          disabled={readonly}
        />

        <div className="dropdown-label-wrap">
          <Dropdown
            options={optionsState}
            onChange={e => setState(e.value)}
            placeholder=""
            value={state}
            className="dropdown-ifood"
            controlClassName={readonly + ' dropdown-ifood__control'}
            placeholderClassName="dropdown-ifood__placeholder"
            menuClassName="dropdown-ifood__menu"
            arrowClassName="dropdown-ifood__arrow"
            disabled={!!readonly}
          />
          <label className="dropdown-label">Estado</label>
        </div>

        <div className="toggle-wrap">
          <span className="toggle-wrap__label">Visível</span>
          <Toggle
            disabled={!!readonly}
            checked={visible}
            value={visible}
            onChange={v => setVisible(v.target.checked)}
          />
        </div>

        <div className="dropdown-label-wrap__category">
          <AutocompleteCategory
            data={category}
            selected={(id, name) => (setCategoryId(id), setCategoryName(name))}
            disabled={!!readonly}
          />
        </div>

        <div className="dropdown-label-wrap">
          <Autocomplete
            id="productBrand"
            freeSolo
            className="dropdown-ifood"
            options={dataBrands && dataBrands.getAllBrands ? dataBrands.getAllBrands.content : []}
            value={brand}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Marca" variant="outlined" />}
            onChange={(e, b) => {
              setBrandId(b ? b.id : null);
              setBrandName(b ? b.name : '');
            }}
            onInput={e => setBrandName(e.target.value)}
            disabled={!!readonly}
          />
        </div>

        <TinyEditor readonly={readonly} data={e => setDescription(e)} initialValue={productDescription} />

        <div>
          {fieldKeys ? (
            <ProductCustomFields
              readonly={readonly}
              fields={fields}
              fieldKeys={fieldKeys}
              fielddata={dataSpecs}
              data={e => handleFieldsValue(e)}
            />
          ) : null}
        </div>
      </form>
      {loading && <Loading />}
    </div>
  );
};

export default FormEditProduct;
