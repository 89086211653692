import * as yup from 'yup';

export type InactiveReasonFormValueType = {
  newSellerId: number | undefined;
  reasons: number;
  note: string;
};

type InactiveReasonFormValueTypeT = { [key in keyof InactiveReasonFormValueType]: any };

const BAUStoresRolloutReasonId = 9;

// SCHEMA
const schema = yup.object().shape({
  reasons: yup.number().required(),
  note: yup.string(),
  newSellerId: yup
    .number()
    .transform(value => (Number.isNaN(value) ? null : value))
    .when('reasons', {
      is: (val: number) => val === BAUStoresRolloutReasonId,
      then: schema => schema.required().typeError('Informe o ID'),
      otherwise: schema => schema.nullable(),
    }),
} as InactiveReasonFormValueTypeT);

export default schema;
