import { NavLink } from 'react-router-dom'

import HeaderForm from '../../components/HeaderForm';
import FormCarrier from '../../components/FormCarrier';


function RegisterCarrier() {
  return (
    <>
      <NavLink to="/fornecedores/transportadoras" className="go-back" />
      <div className="content-body">
        <HeaderForm />
        <FormCarrier />
      </div>
    </>
  );
}

export default RegisterCarrier;