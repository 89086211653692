import React, { useState, useEffect } from 'react';
import { Button, Modal, OutlinedInput } from 'ifoodshop-react-ui';
import TableRegisterRegion from './TableRegisterRegion';
import InputMask from 'react-input-mask';

const PostalCodesAdd = ({ ruleList, onChangeList }) => {
  const [modal, setModal] = useState();
  const [list, setList] = useState(ruleList || []);

  const [postalCodes, setPostalCode] = useState({
    initialPostalCode: null,
    finalPostalCode: null,
  });

  useEffect(() => {
    setList(ruleList || []);
  }, [ruleList]);

  const formatCep = (cep) => {
    const cepFormat = cep.replace('-', '');
    return cepFormat;
  };

  const addRule = (e) => {
    e.preventDefault();

    if (
      !postalCodes?.initialPostalCode ||
      !postalCodes?.finalPostalCode ||
      String(postalCodes.initialPostalCode).length < 8 ||
      String(postalCodes.finalPostalCode).length < 8
    ) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Preencha corretamente os campos de faixa de CEP.
        </Modal>,
      );
    }
    const existRule = list.findIndex(
      (i) =>
        i.initialPostalCode == postalCodes.initialPostalCode &&
        i.finalPostalCode == postalCodes.finalPostalCode,
    );
    if (existRule !== -1) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Já existe uma faixa de cep igual cadastrada para essa região.
        </Modal>,
      );
    }
    if (postalCodes?.initialPostalCode > postalCodes?.finalPostalCode) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)}>
          O CEP inicial não pode ser maior que o CEP final.
        </Modal>,
      );
    }
    setList([...list, postalCodes]);
    onChangeList([...list, postalCodes]);
  };

  const handleDelete = (item) => {
    const index = list.findIndex((i) => i == item);
    if (index !== -1) {
      const newData = [...list];
      newData.splice(index, 1);
      setList(newData);
      onChangeList(newData);
    }
  };

  return (
    <div>
      <h2 className="section-title">Adicione as faixas de CEP</h2>
      <p className="header-customer-group__description form-register__description">
        Utilize os campos abaixo para adicionar faixas de CEP a sua região.
      </p>
      <div className="form-register__flex">
        <div className="outlined-label-wrap">
          <InputMask
            placeholder="CEP inicial"
            mask="99999-999"
            maskChar={null}
            className="form-register__input input outlined-label-field outlined-label-field--border"
            value={postalCodes?.initialPostalCode || ''}
            onChange={(e) =>
              setPostalCode({
                ...postalCodes,
                initialPostalCode: formatCep(e.target.value),
              })
            }
          />
          <label className="outlined-label form-register__flex--label">
            CEP inicial
          </label>
        </div>
        <div className="outlined-label-wrap">
          <InputMask
            placeholder="CEP final"
            mask="99999-999"
            maskChar={null}
            className="form-register__input input outlined-label-field outlined-label-field--border"
            value={postalCodes?.finalPostalCode || ''}
            onChange={(e) =>
              setPostalCode({
                ...postalCodes,
                finalPostalCode: formatCep(e.target.value),
              })
            }
          />
          <label className="outlined-label">CEP final</label>
        </div>
        <Button
          onClick={(e) => addRule(e)}
          className="btn btn-secondary form-register__button"
        >
          Adicionar
        </Button>
      </div>

      {list.length > 0 && (
        <TableRegisterRegion
          deleteCommercialCondition={handleDelete}
          data={list}
        />
      )}
      {modal}
    </div>
  );
};

export default PostalCodesAdd;
