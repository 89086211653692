/* istanbul ignore file */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Dialog, Loading, Pagination } from '@ifood/pomodoro-components';
import { Timeline, TimelineItem } from '@components/Timeline';
import { BFFBonusShopStatementContent } from '@services/bff/GraphqlTypes';
import Filter from './StatementDetailDialogFilter';
import { TextNotFound } from '../StatementDetailTimeline/StatementDetailTimeline.styles';
import { StatementDetailPaymentType } from '../StatementDetailTimeline/StatementDetailPaymentType';
import { useStatementDetailCardHook } from '../StatementDetailCardHook';
import * as S from './StatementDetailDialog.styles';
import { useParams } from 'react-router-dom';
import { IPagination } from '@pages/Campaign/Discount/components/Rules';

const ONE = 1;
const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 6;
interface TProps {
  isOpen: boolean;
  close: () => void;
}

export default function StatementDetailDialog({ isOpen, close }: TProps) {
  const params = useParams<{ id: string }>();

  const { bonusShopStatementData, loadingBonusShopStatement, errorBonusShopStatement, refetchBonusShopStatement } =
    useStatementDetailCardHook({ pageSize: DEFAULT_PAGE_SIZE });

  const [data, setData] = useState<BFFBonusShopStatementContent[] | []>([]);

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalItems: INITIAL_PAGE,
    pageSizes: [DEFAULT_PAGE_SIZE],
  });

  const [filters, setFilters] = useState<{ startDate?: string; endDate?: string }>();

  useEffect(
    () => setData(bonusShopStatementData?.content ? [...bonusShopStatementData.content] : []),

    [bonusShopStatementData]
  );

  const handleSearch = useCallback(
    async (page = INITIAL_PAGE) => {
      return refetchBonusShopStatement({
        input: {
          ...filters,
          customerId: params.id,
          page,
          size: pagination.pageSize,
        },
      })
        .then(res => {
          if (res?.data?.getBonusShopStatement?.content) {
            const { content, pageable, totalPages } = res.data.getBonusShopStatement;

            setPagination(prevState => ({ ...prevState, currentPage: pageable.page, totalItems: totalPages }));
            return setData(content as BFFBonusShopStatementContent[]);
          }
          return setData([]);
        })
        .catch(e => console.error(`[Error on refetchBonusShopStatement]: ${e?.message}`));
    },
    [filters, pagination.pageSize, params.id, refetchBonusShopStatement]
  );

  const renderContent = useMemo(() => {
    if (loadingBonusShopStatement) return <Loading color="primary" variant="large" />;
    else if (errorBonusShopStatement || !data?.length)
      return (
        <>
          <TextNotFound as={'p'}>
            Não há transações. Para visualizar movimentações de outros períodos, insira a data início e/ou data fim
            desejada.
          </TextNotFound>
        </>
      );

    return (
      <Box>
        <Timeline>
          {data?.map(item => (
            <TimelineItem key={item?.transactionDate}>
              <StatementDetailPaymentType data={item} />
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    );
  }, [data, errorBonusShopStatement, loadingBonusShopStatement]);

  return (
    <S.DialogWrapper open={isOpen} style={{ maxHeight: '100%' }} onClose={close}>
      <Dialog.Header>
        <S.Title>Histórico de extrato</S.Title>
        <Filter handleSearch={handleSearch} setFilters={setFilters} />
      </Dialog.Header>
      <Dialog.Body maxWidth={500} minHeight={540}>
        {renderContent}
      </Dialog.Body>
      <Dialog.Footer>
        {bonusShopStatementData?.pageable && (bonusShopStatementData?.totalPages || INITIAL_PAGE) > ONE && (
          <Pagination.Numbered
            showArrowLabel
            current={bonusShopStatementData?.pageable.pageNumber + ONE}
            nextLabel="Proximo"
            pageSize={pagination.pageSize}
            position="right"
            previousLabel="Anterior"
            totalItems={bonusShopStatementData?.totalElements || ONE}
            onChange={({ currentPage }) => handleSearch(currentPage - ONE)}
          />
        )}
      </Dialog.Footer>
    </S.DialogWrapper>
  );
}
