import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPGRADE_ORDER_DELIVERY_STATUS } from '../../../../../graphql/order-delivery.mutations';
import { Button } from 'ifoodshop-react-ui';
import DatePicker from 'react-datepicker';
import ptbr from 'date-fns/locale/pt-BR';

import { formatDateToIsoLocal } from '../../orderUtils';
import OutlinedTextarea from '@components/OutlinedTextarea';

import { ReactComponent as ArrowRight } from '../../../../../assets/icons/order/arrow-right.svg';

function HandleWaitingWithdrawal({ orderDeliveryId, buttonText }) {
  const [errorPage, setErrorPage] = useState(null);
  const [updateTime, setUpdateTime] = useState(null);
  const [errorUpdateTime, setErrorUpdateTime] = useState(null);
  const [updateObservation, setUpdateObservation] = useState(null);
  const [updateOrderStatus, { error, data }] = useMutation(UPGRADE_ORDER_DELIVERY_STATUS);
  if (data) window.location.reload(false);
  if (error) {
    setErrorPage('Não Foi Possivel Alterar O Status Da Entrega');
  }

  function toWaitingWithdrawal(e) {
    e.preventDefault();

    if (!updateTime) {
      setErrorUpdateTime('Campo Obrigatório');
      return;
    }

    updateOrderStatus({
      variables: {
        orderDeliveryId,
        updateStatus: 'WAITING_PICKUP',
        updateTime: formatDateToIsoLocal(updateTime),
        updateObservation,
        trackingCode: null,
      },
    });
  }

  return (
    <>
      <h2 className="modal-alter-status__title">
        Em Separação <ArrowRight className="alter-status-arrow" /> Aguardando Retirada
      </h2>
      <h4 className="modal-alter-status__form__error">{errorPage}</h4>
      <form className="modal-alter-status__form">
        <div className={`outlined-label-wrap outlined-date ${updateTime ? 'active' : ''}`}>
          <DatePicker
            className="form-brand__input"
            onChange={date => setUpdateTime(new Date(date))}
            locale={ptbr}
            selected={updateTime}
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm aa"
          />
          <label className="outlined-label">Data de Pronto</label>
          <p className="modal-alter-status__form__error">{errorUpdateTime}</p>
        </div>
        <div className="form-edit__input-group">
          <OutlinedTextarea
            placeholder="Observação"
            value={updateObservation}
            onChange={e => setUpdateObservation(e.target.value)}
          />
        </div>
        <Button className="modal-alter-status__submit" onClick={e => toWaitingWithdrawal(e)}>
          {buttonText}
        </Button>
      </form>
    </>
  );
}

export default HandleWaitingWithdrawal;
