import React, {Fragment} from 'react';
import Toggle from "react-toggle";

const TableComissions = ({type, data, removeCommissions}) => {
  function select(value, commission) {
    commission = {...commission, active: value}
    removeCommissions(commission)
  }

  function formatDate(date) {
    let dateFormatted = {
      day: date.split("-")[2],
      month: date.split("-")[1],
      year: date.split("-")[0]
    }
    return `${dateFormatted.day}/${dateFormatted.month}/${dateFormatted.year}`
  }

  return (
    <>
      {type === data.content[0]?.commissionType &&
      <Fragment>
        <table className="table-admin table-admin--sample">
          <thead>
          <tr>
            <th className="table-admin__tiel">ID</th>
            {
              type === "CATEGORY" ?
                <th className="table-admin__title">Categoria</th>
                : type === "OFFER" ? <th className="table-admin__title">SKU</th> : null
            }
            <th className="table-admin__title">Data inicial</th>
            <th className="table-admin__title">Data final</th>
            <th className="table-admin__title">Percentual</th>
            <th className="table-admin__title">Ativo</th>
            <th className="table-admin__title"></th>
          </tr>
          </thead>
          <tbody>
          {
            data.content.length > 0 ?
              data.content.map((commission, index) => (
                commission.commissionType === type &&
                <tr id={commission.id} key={commission.id}>
                  <td className="table-admin__data">{commission.id}</td>
                  {
                    type === "CATEGORY" ?
                      <>
                        <td className="table-admin__title">{commission.category.name}</td>
                      </>
                      : type === "OFFER" ?
                      <>
                        <td className="table-admin__title">{commission.offer.sellerCode}</td>
                      </> : null
                  }
                  <td className="table-admin__data">{formatDate(commission.startDate)}</td>
                  <td className="table-admin__data">{formatDate(commission.endDate)}</td>
                  <td className="table-admin__data">{commission.percentage}</td>
                  <td className="table-admin__data">{commission.active ? "Habilitado" : "Desabilitado"}</td>
                  <td>
                    {
                      commission.active === true ?
                        <div className="toggle-wrap toggle-wrap__table">
                          <Toggle
                            checked={commission.active}
                            value={commission.active ? "true" : ""}
                            onChange={e => select(e.target.checked, commission)}/>
                        </div>
                        :
                        <div className="toggle-wrap toggle-wrap__table">
                          <Toggle
                            checked={commission.active}
                            readOnly
                          />
                        </div>
                    }
                  </td>
                </tr>
              ))
              : null
          }
          </tbody>
        </table>
      </Fragment>
      }
    </>
  )
}

export default TableComissions;