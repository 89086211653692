import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Formatter from '@helpers/FormatHelper';
import './mainData.scss';

const MainData = ({ role, setEdit, data }) => {
  const content = useRef();

  return (
    <div className="mainData">
      <h2 className="mainData__title">Dados do fornecedor</h2>
      <div className="mainData__name">
        <span className="mainData__attribute">Nome do Fornecedor:</span>
        <span className="mainData__value">{data?.name}</span>
      </div>
      <div className="mainData__tel">
        <span className="mainData__attribute">Telefone Residencial:</span>
        <span className="mainData__value">
          {Formatter.format(data?.user?.residentialPhone, "phone")}
        </span>
      </div>
      <div className="mainData__cel">
        <span className="mainData__attribute">Telefone Celular:</span>
        <span className="mainData__value">
          {Formatter.format(data?.user?.mobilePhone, "mobile-phone")}
        </span>
      </div>
      <div className="mainData__name">
        <span className="mainData__attribute">Razão Social:</span>
        <span className="mainData__value">{data?.companyName}</span>
      </div>
      <div className="mainData__name">
        <span className="mainData__attribute">CNPJ:</span>
        <span className="mainData__value">
          {Formatter.format(data?.document, "document")}
        </span>
      </div>
      <div className="mainData__name">
        <span className="mainData__attribute">MCC:</span>
        <span className="mainData__value">{data?.mcc}</span>
      </div>
      <div className="mainData__name">
        <span className="mainData__attribute">CNAE:</span>
        <span className="mainData__value">{data?.cnae}</span>
      </div>
      <div className="mainData__link">
        <NavLink
          ref={content}
          onClick={
            role ? () => setEdit({ edit: true, type: content.current?.id }) : ""
          }
          id="mainData"
          to="#"
          className={role ? "link" : "link--disabled"}
        >
          Editar informações
        </NavLink>
      </div>
    </div>
  );
};

export default MainData;
