import { NavLink, useHistory } from 'react-router-dom';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import HeaderRegisterProduct from '../components/HeaderRegisterProduct';
import FormRegisterProduct from '../components/FormRegisterProduct';

const ProductRegister = () => {
  const history = useHistory();
  const roles = getRoles();
  const rolePrdWriter = roles.includes(Roles.PRODUCT_WR);

  if (!rolePrdWriter) {
    history.push('/catalogos/produtos');
  }

  return (
    <>
      <NavLink to="/catalogos/produtos/" className="go-back" />
      <div className="content-body register-product">
        <HeaderRegisterProduct title="Criar um Produto" description="Use os campos abaixo para criar um produto." />
        <FormRegisterProduct />
      </div>
    </>
  );
};

export default ProductRegister;
