/* istanbul ignore file */
import { gql } from '@apollo/client';

export const GET_BONUS_SHOP_STATEMENT = gql`
  query getBonusShopStatement($input: InputBonusShopStatement) {
    getBonusShopStatement(input: $input) {
      content {
        customerId
        type
        orderId
        walletId
        amount
        startDate
        endDate
        description
        transactionType
        transactionDate
      }

      first
      numberOfElements
      size
      totalElements
      totalPages

      pageable {
        pageNumber
        pageSize
        offset
      }
    }
  }
`;
