import gql from 'graphql-tag';

const GET_OFFERS = gql`
  query findOffersPaginated($pageNumber: Int, $pageSize: Int, $variation: Int) {
    findOffersPaginated(variationId: $variation, pageNumber: $pageNumber, pageSize: $pageSize) {
      content {
        id
        sellerId
        sellerCode
        price
        pricePerKg
        isPricePerKg
        stock
        active
        variableWeight
        limitQuantity
      }
      totalPages
    }
  }
`;

const GET_OFFERS_BY_SELLER = gql`
  query findOffersBySeller($variation: ID!) {
    findOffersBySeller(variation: $variation) {
      id
      sellerId
      sellerCode
      stock
      committedStock
      active
      price
      pricePerKg
      isPricePerKg
      variableWeight
      limitQuantity
    }
  }
`;

const GET_OFFER_ID = gql`
  query findofferById($id: ID!) {
    findOfferById(id: $id) {
      id
      sellerId
      sellerCode
      price
      pricePerKg
      isPricePerKg
      stock
      active
      committedStock
      variableWeight
      limitQuantity
      specialPrices {
        id
        price
        active
        legalNatureCode
      }
    }
  }
`;

const GET_OFFERS_SELLERCODE = gql`
  query findOfferBySellerAndCode($sellerId: Int, $sellerCode: String) {
    findOfferBySellerAndCode(sellerId: $sellerId, sellerCode: $sellerCode) {
      id
      sellerId
      sellerCode
    }
  }
`;

const GET_OFFERS_BY_VARIATION = gql`
  query findOffersByVariationId($variation: ID!) {
    findOffersByVariationId(variation: $variation) {
      id
      sellerId
      sellerCode
      stock
      committedStock
      active
      price
      variableWeight
      limitQuantity
    }
  }
`;

export { GET_OFFERS, GET_OFFER_ID, GET_OFFERS_SELLERCODE, GET_OFFERS_BY_SELLER, GET_OFFERS_BY_VARIATION };
