import * as yup from 'yup';
import { basicFieldsSchema } from '../../../components/FormSteps/RegistrationDataForm/schema';
import contractualSchema from '../../../components/FormSteps/ContractualDataForm/schema';
import addressSchema from '../../../components/FormSteps/AdressDataForm/schema';
import bankSchema from '../../../components/FormSteps/BankDataForm/schema';
import { PASSWORD_REGEX } from '@components/PasswordCriterea/PasswordCriterea';

// SCHEMA
const schema = yup.object().shape({
  ...basicFieldsSchema.fields,
  ...contractualSchema.fields,
  ...addressSchema.fields,
  ...bankSchema.fields,
  password: yup
    .string()
    .test('oneOfRequired', 'Padrão de senha incorreta.', item => !!item?.match(PASSWORD_REGEX) || !item),
});

export default schema;
