import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  align-items: center;
  justify-content: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 40px 0 16px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    width: 405px;
    text-align: center;
    color: #717171;

    label {
      color: #3e3e3e;
    }
  }

  .link-download {
    display: flex;
    color: #717171;
    text-decoration: none;
    position: fixed;
    bottom: 41px;
    left: 256px;

    svg {
      margin-right: 7px;
    }

    a {
      position: relative;
      left: 0;
      bottom: 0;

      text-decoration: underline;
      color: #ea1d2c;
      margin-left: 8px;
    }
  }
`;
