const orderStatusLabel = {
    "ORDER_PLACED": 'Pedido realizado',
    "PAYMENT_PENDING": 'Aguardando pagamento',
    "PAYMENT_CANCELLED": 'Pagamento cancelado',
    "DELIVERY_PENDING": 'Aguardando entrega',
    "CANCELLED": 'Cancelado',
    "FINISHED": 'Finalizado',
    "WAITING_PAYMENT": 'Aguardando pagamento',
    "PAYMENT_ACCEPTED": 'Pagamento Confirmado',
    "IN_STOCK_SEPARATION" :'Em separação',
    "BILLED": 'Faturado',
    "IN_TRANSPORTATION": 'Em transporte',
    "WAITING_PICKUP": 'Aguardando coleta'
}

const orderDeliveryStatus = {
    "WAITING_PAYMENT": 1,
    "PAYMENT_ACCEPTED": 2,
    "IN_STOCK_SEPARATION": 3,
    "BILLED": 4,
    "IN_TRANSPORTATION": 5,
    "WAITING_PICKUP": 6,
    "FINISHED": 7,
    "CANCELLED": 8
}

const paymentStatusLabel = {
    "PROCESSING": 'Processando',
    "APPROVED": 'Aprovado',
    "DECLINED": 'Negado',
    "CANCELLED": 'Cancelado',
    "UNKNOWN": 'Desconhecido'
}

const stepsList = {
  "DRIVER_ALLOCATED": "Veículo solicitado",
  "ENABLE_TRACKING": "Habilitar rastreamento",
  "GOING_TO_ORIGIN": "A caminho do emissor",
  "COLLECTED": "Pedido coletado.",
  "GOING_TO_DESTINATION": "Veículo confirmado e já está a caminho",
  "ARRIVED_AT_DESTINATION": "Pedido chegou ao destino",
  "COMPLETED": "Pedido entregue",
  "CANCELLED": "Cliente não encontrado. Pedido retornado ao fornecedor."
}

const validSteps = [
  "DRIVER_ALLOCATED",
  "GOING_TO_DESTINATION",
  "COMPLETED",
  "CANCELLED",
];

function formatDateToIsoLocal(date) {
    let iso = null;

    if (date) {
        const offsetMs = date.getTimezoneOffset() * 60000;
        const msLocal = date.getTime() - offsetMs;
        const dateLocal = new Date(msLocal);
        iso = dateLocal.toISOString();
    }

    return iso;
}

export {
    orderStatusLabel,
    paymentStatusLabel,
    orderDeliveryStatus,
    formatDateToIsoLocal,
    stepsList,
    validSteps
}