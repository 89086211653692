import { ThemeContext } from 'styled-components';
import { Card, Flex, Heading3, Text } from '@ifood/pomodoro-components';
import { useContext } from 'react';
import { useSellerDetailsData } from '../../SellerDetailsHook';
import * as S from './RegistrationDataDetails.styles';
import { SuccessFilled } from '@ifood/pomodoro-icons';
import noLogoImage from '@assets/images/seller/no-logo.png';

const RegistrationDataDetails = (): JSX.Element => {
  const { sellerResponse, imageSettingsHandle, stringImgBytes, getPhoneNumberWithDDD } = useSellerDetailsData();

  const theme = useContext(ThemeContext);

  const capitalizeCharAt = 0;
  const lowercaseSlice = 1;

  const businessType = sellerResponse?.getSellerV2?.businessType ?? '';

  return (
    <Card>
      <Flex flexDirection="column">
        <Heading3 mb={24} mt={4} variant="smallest">
          Dados cadastrais
        </Heading3>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Nome do fornecedor
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.name}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Razão social
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.companyName}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          CNPJ
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.document}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          CNAE
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.cnae}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          MCC
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.mcc}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Tipo de negócio
        </Text>
        <Text mb={24} variant="large">
          {businessType.charAt(capitalizeCharAt).toUpperCase() + businessType.slice(lowercaseSlice).toLowerCase()}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Segmento
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.type?.name}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Telefone Fixo
        </Text>
        <Text mb={24} variant="large">
          {getPhoneNumberWithDDD(
            sellerResponse?.getSellerV2?.user?.mobilePhone,
            sellerResponse?.getSellerV2?.user?.dddMobilePhone
          )}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Celular
        </Text>
        <Text mb={24} variant="large">
          {getPhoneNumberWithDDD(
            sellerResponse?.getSellerV2?.user?.cellPhone,
            sellerResponse?.getSellerV2?.user?.dddCellPhone
          )}
        </Text>
        <Text color={theme.colors.grayDarkest} mb={10} variant="regular">
          Logotipo
        </Text>
        <Card variant="primary">
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Flex>
              <S.ImageLogo
                src={
                  sellerResponse?.getSellerV2?.logo
                    ? `${window.config?.StaticAssetsURL}${sellerResponse?.getSellerV2?.logo}`
                    : noLogoImage
                }
                onLoad={() => imageSettingsHandle(sellerResponse?.getSellerV2?.logo)}
              />
              <Flex alignSelf="flex-start" flexDirection="column">
                <Text
                  color={theme.colors.grayDarkest}
                  fontFamily={theme.font.family.ifood.headings}
                  fontWeight={700}
                  variant="medium"
                >
                  {sellerResponse?.getSellerV2?.logo?.split('/').pop()}
                </Text>
                <Text color={theme.colors.grayDarkest} fontSize={14} lineHeight="16px">
                  {stringImgBytes}
                </Text>
              </Flex>
            </Flex>
            {sellerResponse?.getSellerV2?.logo && <SuccessFilled color={theme.colors.positive} />}
          </Flex>
        </Card>
      </Flex>
    </Card>
  );
};

export default RegistrationDataDetails;
