import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { GET_ALL_MENUS } from '../../../../graphql/cms-home-api.queries';
import { GET_CATEGORYMENU_BY_MENUID } from '../../../../graphql/category.queries';
import { Input, Modal } from 'ifoodshop-react-ui';
import Tooltip from '@material-ui/core/Tooltip';

import MenusHnHelper from '@helpers/MenusHnHelper';

import Loading from '@components/Loading';

import { ReactComponent as VisualizeIcon } from '../../../../assets/icons/visualize.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/Trash.svg';
import './tableCaterogyRank.scss';

const TableCategoryRank = ({ menuRankArray, setMenuRankArray, categoryName, categoryId }) => {
  const [availableMenus, setAvailableMenus] = useState([]);
  const [modal, setModal] = useState();
  const [getAllMenus, { data: dataAllMenus, loading: loadingAllMenus }] = useLazyQuery(GET_ALL_MENUS);
  const { loading: loadingMenuByCategory, refetch: refetchMenuById } = useQuery(GET_CATEGORYMENU_BY_MENUID, {
    variables: { id: 1 },
  });

  useEffect(() => {
    getAllMenus();
  }, [getAllMenus]);

  useEffect(() => {
    if (loadingAllMenus || !dataAllMenus) {
      setAvailableMenus([]);
    } else {
      let menus = dataAllMenus.getAllHnMenus.map(menu => {
        let selected_menu_ids = menuRankArray.map(categoryMenu => categoryMenu.menu.id);

        if (selected_menu_ids.includes(menu.id)) {
          let index = selected_menu_ids.indexOf(menu.id);
          menu.rank = menuRankArray[index].rank;
          menu.selected = true;
        } else {
          menu.rank = 0;
          menu.selected = false;
        }

        return menu;
      });
      setAvailableMenus(menus);
    }
  }, [dataAllMenus, loadingAllMenus, menuRankArray]);

  const selectMenuRank = (menu_id, selected) => {
    let new_available = JSON.parse(JSON.stringify(availableMenus));
    new_available.find(m => m.id === menu_id).selected = selected;
    setAvailableMenus(new_available);

    if (selected) {
      const itemSelected = new_available.find(m => m.id === menu_id);
      const categoryMenu = {
        id: null,
        rank: itemSelected.rank,
        menu: {
          id: itemSelected.id,
          name: itemSelected.name,
        },
      };
      setMenuRankArray([...menuRankArray, categoryMenu]);
    } else {
      setMenuRankArray(menuRankArray.filter(categoryMenu => categoryMenu.menu.id !== menu_id));
    }
  };

  const updateRank = (menu_id, rank) => {
    let new_available = JSON.parse(JSON.stringify(availableMenus));
    new_available.find(m => m.id === menu_id).rank = rank;
    setAvailableMenus(new_available);

    menuRankArray.find(item => item.menu?.id === menu_id).rank = parseInt(rank);
    setMenuRankArray(menuRankArray);
  };

  const getFormattedAvailableMenus = menus => {
    return menus.map(m => {
      return (
        <a key={m.id} className={m.selected ? 'selected' : null} onClick={() => selectMenuRank(m.id, !m.selected)}>
          {m.id} - {MenusHnHelper.handleNameMenu(m.name)}
        </a>
      );
    });
  };

  const rankForm = array => {
    return array.map(item => (
      <tr key={item.id}>
        <td className="table-admin__data">{MenusHnHelper.handleNameMenu(item.menu?.name)}</td>
        <td className="table-admin__data">
          <Input
            className="input-rank"
            type={'number'}
            value={item.rank < 1 ? '' : item.rank}
            onChange={e => updateRank(item.menu?.id, e.target.value)}
          />
        </td>
        <td className="table-admin__data">
          <VisualizeIcon
            onClick={() => viewMenu(item.menu?.id, item.menu?.name, item.rank)}
            className={'icon-button'}
          />
        </td>
        <td className="table-admin__data">
          <TrashIcon className="icon-trash" width={15} onClick={() => selectMenuRank(item.menu?.id, false)} />
        </td>
      </tr>
    ));
  };

  const viewMenu = async (id, name, rank) => {
    var menuSelected = [];
    await refetchMenuById({ id })
      .then(res => {
        res.data.getAllCategoryMenuByMenuId.map(item => {
          if (item.category.id !== categoryId) {
            menuSelected.push(item);
          }
        });
      })
      .catch(error => {
        return error;
      });

    menuSelected.sort((a, b) => a.rank - b.rank).splice(5, Number.MAX_VALUE);
    menuSelected.splice(rank - 1, 0, { id: null, rank: parseInt(rank), category: { name: categoryName } });

    if (rank && parseInt(rank) > 0) {
      setModal(
        <Modal
          onCloseAction={() => setModal(null)}
          title={`Ordenação do ${MenusHnHelper.handleNameMenu(name)}`}
          className="modal-view-rank"
        >
          <ul className="list-sample">
            {menuSelected.map((item, index) => {
              if (index < 5) {
                return (
                  <li className={item.category.name.includes(categoryName) ? 'active' : null}>{item.category.name}</li>
                );
              } else {
                return (
                  <Tooltip title='Esta categoria aparecerá em "Mais Categorias"'>
                    <li className={` more-category ${item.category.name.includes(categoryName) ? 'active' : null}`}>
                      {item.category.name}
                    </li>
                  </Tooltip>
                );
              }
            })}
          </ul>
        </Modal>
      );
    } else {
      setModal(
        <Modal onCloseAction={() => setModal(null)} title="Atenção" className="modal-view-rank">
          <p>insira um valor em "Posição" para visualizar o menu</p>
        </Modal>
      );
    }
  };

  return (
    <>
      <div className={'table-category-rank'}>
        <h2 className="section-title">Ordenação no menu</h2>
        <p className="header-category__description">
          Selecione quais menus a categoria aparecerá, defina em qual posição digitando o valor em "Posição"{' '}
        </p>
        <div className={'menu-rank-options'}>{getFormattedAvailableMenus(availableMenus)}</div>

        {menuRankArray.length > 0 && (
          <table className="table-admin table-admin--sample table-admin--rank">
            <thead>
              <tr>
                <th className="table-admin__title">Menu</th>
                <th className="table-admin__title">Posição</th>
                <th className="table-admin__title"></th>
              </tr>
            </thead>
            <tbody>{rankForm(menuRankArray)}</tbody>
          </table>
        )}
      </div>
      {modal}
      {(loadingAllMenus || loadingMenuByCategory) && <Loading />}
    </>
  );
};

export default TableCategoryRank;
