import gql from "graphql-tag";

const UPDATE_DATA_SELLER = gql`
  mutation updateDataSeller($name: String, $user: UserInput, $mcc: String, $cnae: String) {
    updateDataSeller(input: { name: $name, user: $user, mcc: $mcc, cnae: $cnae }) {
      error
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation updatePassword($currentPassword: String, $password: String) {
    updatePassword(
      input: { currentPassword: $currentPassword, password: $password }
    ) {
      error
    }
  }
`;

const ACCEPT_SHIPPING_BY_SHOP = gql`
  mutation acceptShippingByShop($accept: Boolean, $logisticTypes: [Int]) {
    acceptShippingByShop(accept: $accept, logisticTypes: $logisticTypes)
  }
`;

const SET_LOGISTIC_TYPES_ALLOWED = gql`
  mutation setLogisticTypesAllowed($ids: [Int]) {
    setLogisticTypesAllowed(ids: $ids)
  }
`;

const SET_SELLER_ADMIN_NOTIFICATION = gql`
  mutation setSellerAdminNotification($modalAdmin: Boolean) {
    setSellerAdminNotification(modalAdmin: $modalAdmin)
  }
`;

export {
  UPDATE_DATA_SELLER,
  UPDATE_PASSWORD,
  ACCEPT_SHIPPING_BY_SHOP,
  SET_LOGISTIC_TYPES_ALLOWED,
  SET_SELLER_ADMIN_NOTIFICATION,
};
