import { Text as TextPomodoro } from '@ifood/pomodoro-components';
import styled from 'styled-components';

interface IWrapper {
  maxWidth?: string;
  width?: string;
}

export const CloseBox = styled.button`
  background: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  top: 2px;
  padding: ${({ theme }) => `${theme.space.none}`};
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
`;

export const TextColor = styled(TextPomodoro)`
  color: ${({ theme }) => theme.colors.secondary};
  line-height: ${({ theme }) => theme.lineHeights.m};
  margin: ${({ theme }) => `${theme.space.none}`};
`;

export const Heading = styled(TextColor)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Text = styled(TextColor)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const Wrapper = styled.section<IWrapper>`
  padding: ${({ theme }) => `${theme.space.smallest} ${theme.space.smaller}`};
  max-width: ${({ maxWidth = '258px' }) => maxWidth};
  width: ${({ width = 'auto' }) => width};
`;

export const Header = styled.header`
  display: flex;
  position: relative;
  min-height: 24px;
  margin-bottom: ${({ theme }) => `${theme.space.smaller}`};
  ${CloseBox} + ${Heading} {
    margin-right: ${({ theme }) => `${theme.space.large}`};
  }
`;

export const Body = styled.div`
  width: 100%;
`;
