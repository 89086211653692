import { useState } from 'react';

import { api } from '@services/api';

const useRequest = () => {
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRequest = async (path: string, data: FormData) => {
    setIsLoading(true);

    api
      .post(path, data)
      .then(response => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error();
      })
      .then(response => {
        setHasSuccess(true);
        setHasError(false);
        console.log('success');
      })
      .catch(() => {
        setHasSuccess(false);
        setHasError(true);
        console.log('error');
      })
      .finally(() => setIsLoading(false));
  };

  return { handleRequest, isLoading, hasSuccess, hasError };
};

export default useRequest;
