import { Button, Flex, Text } from '@ifood/pomodoro-components';

interface IProps {
  onClose: () => void;
}

export default function ConfirmationDialog({ onClose }: IProps) {
  return (
    <Flex flexDirection={'column'} my={8} width={452}>
      <Text fontSize={18} lineHeight={'24px'} variant="regular">
        Tem certeza de que deseja fazer o upload do arquivo selecionado? Este processo não pode ser desfeito.
      </Text>

      <Flex gap={16} justifyContent={'flex-end'} mt={32}>
        <Button variant="secondary" onClick={onClose}>
          <Text color={'#EA1D2C'} fontWeight={700}>
            Cancelar
          </Text>
        </Button>
        <Button form="file-submit" type="submit">
          <Text color={'white'} fontWeight={700}>
            Confirmar
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
}
