import { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Link, Button, OutlinedInput, Modal } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import { useLazyQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Toggle from 'react-toggle';

import { GET_CATEGORIES_BY_NAME, GET_CATEGORIES_TREE } from '../../../../graphql/category.queries';
import { GET_PRODUCTS } from '../../../../graphql/product.queries';
import { GET_BRANDS } from '../../../../graphql/brand.queries';
import { GET_SELLERS } from '../../../../graphql/seller.queries';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionCatalog } from '@analytics/aboyeur/entities/sessionCatalog';
import AuthorizationHeader from '@helpers/AuthorizationHeader';
import Roles from '@helpers/Roles';
import { getRoles, getSellerId } from '@helpers/rolesUtils';
import client from '@services/apollo';

import Loading from '@components/Loading';
import ModalSelectSellersExport from '@components/ModalSelectSellersExport';
import ModalUpdateImportNotice from './ModalUpdateImportNotice';
import ModalImportFile from '@components/ModalImportFile';

import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevron-down.svg';
import './product-search.scss';
import '../../../../components/HeaderGroups/header-groups.scss';
import { api } from '@services/api';

const FormSearchProduct = props => {
  //SINGLE VARS
  const [productName, setProductName] = useState(null);
  const [referenceCode, setReferenceCode] = useState(null);
  const [productId, setProductId] = useState(null);
  const [category, setCategory] = useState('');
  const [categoryid, setCategoryid] = useState(null);
  const [seller, setSeller] = useState(null);
  const [sellerid, setSellerid] = useState(null);
  const [brand, setBrand] = useState(null);
  const [brandid, setBrandid] = useState(null);
  const [active, setActive] = useState('Habilitado');
  const [options, setOptions] = useState();
  const PAGE_SIZE = 10;
  const wrapperRef = useRef(null);

  //FASTER
  const sessionCatalog = useAboyeur(SessionCatalog);

  //COLLECTIONS
  const [products, setProducts] = useState(null);
  const [sellers, setSellers] = useState(null);
  const [brands, setBrands] = useState(null);

  //QUERIES
  const [getSellerByName, { data: dataSellers }] = useLazyQuery(GET_SELLERS);
  const [GetAllBrands, { data: dataBrands }] = useLazyQuery(GET_BRANDS);
  const { data: dataProducts } = useLazyQuery(GET_PRODUCTS);
  const [getCategoriesByName] = useLazyQuery(GET_CATEGORIES_BY_NAME);
  const [getCategoryTree, { data: dataCategoryTree }] = useLazyQuery(GET_CATEGORIES_TREE);
  const [categoryName, setCategoryName] = useState(null);
  const [seeMore, setSeemore] = useState(false);

  const [modal, setModal] = useState(null);
  const [deleteImages, setDeleteImages] = useState(false);
  const [handleImages, setHandleImages] = useState();
  const [imgCounter, setImgcounter] = useState(0);

  const [loading, setLoading] = useState(false);
  const [returnUploadImagens, setReturnUploadImagens] = useState([
    'Id Produto,Ordem,Path da imagem,Imagens excluidas,Nome da imagem,Resultado da importacao,Erro\n',
  ]);
  const [upImgCheck, setUpImgCheck] = useState(false);
  const [excludedImages, setExcludedImages] = useState([]);
  const [includedImages, setIncludedImages] = useState([]);

  
  
  
  
  
  

  const roles = getRoles();
  const roleProductWR = roles.includes(Roles.PRODUCT_WR);
  const roleProductRO = roles.includes(Roles.PRODUCT_RO);
  const roleSellerWR = roles.includes(Roles.SELLER_WR);
  const roleSellerRO = roles.includes(Roles.SELLER_RO);

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  useEffect(() => {
    if (categoryName && categoryName !== '')
      getCategoryTree({
        variables: {
          name: categoryName || '',
        },
      });
  }, [categoryName]);

  const mountOptions = (category, parentName = null) => {
    setOptions(options => [
      ...options,
      {
        id: category.id,
        name: parentName ? parentName + ' > ' + category.name : category.name,
      },
    ]);
    if (category.subCategories) {
      category.subCategories.map(subCategory => {
        mountOptions(subCategory, parentName ? parentName + ' > ' + category.name : category.name);
      });
    }
  };

  useEffect(() => {
    setOptions([]);
    if (dataCategoryTree) {
      dataCategoryTree.getCategoriesTree.map(category => {
        mountOptions(category);
      });
    }
  }, [dataCategoryTree]);

  //AUTOCOMPLETE CATEGORIA
  useEffect(() => {
    if (category != '') {
      getCategoriesByName({ variables: { name: category } });
    }
  }, [category]);

  //AUTOCOMPLETE FORNECEDOR
  useEffect(() => {
    if (seller && seller !== '') {
      getSellerByName({ variables: { name: seller } });
    } else {
      setSellers(null);
    }
  }, [seller]);

  useEffect(() => {
    if (dataSellers) {
      if (dataSellers.getSellerByName) {
        setSellers(dataSellers.getSellerByName);
      }
    }
  }, [dataSellers]);

  //AUTOCOMPLETE MARCA
  useEffect(() => {
    if (brand != '') {
      GetAllBrands({ variables: { brandName: brand } });
    } else {
      setBrands(null);
    }
  }, [brand]);

  useEffect(() => {
    if (dataBrands) {
      if (dataBrands.getAllBrands && dataBrands.getAllBrands.content) {
        setBrands(dataBrands.getAllBrands.content);
      }
    }
  }, [dataBrands]);

  const handleRequest = (forcepage = null) => {
    client
      .query({
        query: GET_PRODUCTS,
        fetchPolicy: 'no-cache',
        variables: {
          page: forcepage != null ? forcepage : props.currentpage,
          size: PAGE_SIZE,
          brandId: brandid,
          categoryId: categoryid,
          productName: productName != '' && productName != null ? productName : null,
          referenceCode: referenceCode != '' && referenceCode != null ? referenceCode : null,
          active: getState(active),
          id: parseInt(productId),
          sellerId: roleSellerWR || roleSellerRO ? parseInt(getSellerId()) : parseInt(sellerid),
        },
      })
      .then(res => {
        var returndata = res.data.getAllProducts;
        returndata.currentpage = forcepage ? forcepage : props.currentpage;
        props.data(returndata);
      })
      .catch(err => {
        return err;
      });
  };

  const getProducts = event => {
    event.preventDefault();
    handleRequest(0);
  };

  const getState = status => {
    switch (status) {
      case 'Habilitado':
        return true;
        break;
      case 'Desabilitado':
        return false;
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    handleRequest();
  }, [props.currentpage]);

  function seeMoreToggle(e) {
    if (seeMore) {
      setSeemore(false);
    } else {
      setSeemore(true);

      sessionCatalog.events.product.clickButtomSeeActions();
    }
  }

  const root = document.getElementById('root');
  root.addEventListener('click', e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSeemore(false);
    }
  });

  function importOffers(e) {
    setModal(<Loading />);

    const fileImport = e.target.files[0];
    const formData = new FormData();

    formData.append('file', fileImport);

    api.post('/offer/import/', formData)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'offersImportReturn.csv';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();

        setModal(null);
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao importar arquivo');
      });
  }

  function importImages(e) {
    e.preventDefault();

    setModal(
      <Modal onCloseAction={() => setModal(null)} title="Importação em lote">
        <h2>Os produtos podem conter imagens</h2>
        <h3>
          <strong>Atenção:</strong> Tamanho máximo por imagem de 1MB
        </h3>
        <div className="options-btn">
          <div className="toggle-wrap">
            <span className="toggle-wrap__label">Apagar imagens</span>
            <Toggle id="delete" onChange={t => setDeleteImages(t.target.checked)} />
          </div>

          <div>
            <form id="frmUpload" onSubmit={checkImagesSize}>
              <input
                type="file"
                accept="image/*"
                id="upload-input"
                hidden
                multiple
                onChange={e => setHandleImages(e)}
              />
            </form>
            <label className="btn-save" htmlFor="upload-input">
              <Link>Adicionar imagens</Link>
            </label>
          </div>
        </div>
      </Modal>
    );
  }

  if (dataProducts && products === null) {
    setProducts(dataProducts.getAllProducts.content);
  }

  useEffect(() => {
    handleImages && checkImagesSize(handleImages, deleteImages);
  }, [handleImages]);

  async function checkImagesSize(e, d) {
    let initialList = Array.from(document.getElementById('upload-input').files);
    let list = [];
    let totalSize = 0;
    let imagesToExclude = [];
    let imagesToExcludeSize = 0;

    Object.values(initialList).forEach(image => {
      if (image.size < 1e6) {
        list.push(image);
        totalSize += image.size;

        setIncludedImages(prevState => [...prevState, { name: image.name, size: (image.size / 1024).toFixed(2) }]);

        setImgcounter(initialList.length);
      } else if (initialList.length === 1 && image.size > 1e6) {
        return warnExcludedImages([{ name: image.name, size: (image.size / 1e6).toFixed(2) }]);
      } else {
        setExcludedImages(prevState => [...prevState, { name: image.name, size: (image.size / 1e6).toFixed(2) }]);
      }
    });

    if (d)
      list.forEach(l => {
        let a = l.name.slice(0, l.name.indexOf('-'));
        !imagesToExclude.includes(a) && imagesToExclude.push(a);
      });

    imagesToExclude.forEach((e, i) => {
      let x = list.find(f => f.name.slice(0, f.name.indexOf('-')) === e);
      imagesToExclude[i] = x;
      imagesToExcludeSize += x.size;
      removeOnce(list, x);
    });

    if (imagesToExclude.length > 0) await sendImages(e, d, imagesToExclude, imagesToExcludeSize, list.length);

    if (list.length > 0) sendImages(e, false, list, totalSize);
  }

  function removeOnce(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) arr.splice(index, 1);
  }

  function getListSize(lista) {
    let x = 0;
    lista.forEach(l => (x += l.size));
    return x;
  }

  async function sendImages(e, d, originalList, initialSize, final) {
    let currentSize = 0;
    let totalSize = initialSize;
    let list = originalList;
    let listUpload = [];
    const ONE_MB = 9e5;

    const reset = () => {
      currentSize = 0;
      listUpload = [];
      totalSize = getListSize(list);
    };

    do {
      if (totalSize > ONE_MB) {
        const nextImage = list[list.length - 1];

        const nextSize = currentSize + nextImage.size;

        if (nextSize > ONE_MB) {
          await ImagesUpload(e, d, listUpload, false);
          reset();

          continue;
        }

        listUpload.push(list.pop());
        currentSize += nextImage.size;
      } else {
        await ImagesUpload(e, d, list, !final);
        list = [];
      }
    } while (list.length > 0);
  }

  useEffect(() => {
    upImgCheck && createCSV();
  }, [upImgCheck]);

  function createCSV() {
    let csvContent = '';

    returnUploadImagens.forEach(function (row) {
      csvContent += row;
    });

    setModal(null);
    setHandleImages(null);
    setUpImgCheck(false);
    setDeleteImages(false);
    setReturnUploadImagens(['productId,order,uploadName,excludedImages,name,uploaded,error\n']);

    includedImages.length > 0 && warnIncludedImages(includedImages);
    setIncludedImages([]);

    excludedImages.length > 0 && warnExcludedImages(excludedImages);
    setExcludedImages([]);
  }

  async function addImagesReturn(res, final) {
    let lines = res.split('\n');

    await lines.forEach((l, index) => {
      if (l !== '' && index !== 0) setReturnUploadImagens(prevState => [...prevState, l]);
    });

    if (final === true) setUpImgCheck(final);
  }

  const ImagesUpload = async (e, d, list, final) => {
    e.preventDefault();
    setModal(<Loading />);

    let uploaddata = new FormData();

    list.forEach(img => uploaddata.append('files', img));
    uploaddata.append('excludeImages', d);

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');

    api.post('/product/image/import', uploaddata)
      .then(res => {
        res.text().then(res => addImagesReturn(res, final));
      })
      .catch(error => {
        setHandleImages(null);
        setDeleteImages(false);
        changeModal('Atenção', 'Erro  ao importar arquivo');
      });
  };

  function warnExcludedImages(excluded) {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title="Atenção">
        <div className="product-images">
          {excluded.length > 1 ? (
            <span>As imagens abaixo não foram importadas.</span>
          ) : (
            <>
              <span>A imagem abaixo não foi importada.</span>
            </>
          )}
          <span className="product-images__label">O tamanho excede o máximo por imagem de 1MB.</span>
          <ul className="product-images__list">
            {excluded.map(e => (
              <li className="product-images__list--item">
                <span>{e.name} - </span>
                <span>{e.size}MB</span>
              </li>
            ))}
          </ul>
          <Button onClick={() => setModal(null)}>Ok, entendi</Button>
        </div>
      </Modal>
    );
  }

  function warnIncludedImages(included) {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title="Atenção">
        <div className="product-images">
          {included.length > 1 ? (
            <span className="product-images__label">As imagens abaixo foram importadas.</span>
          ) : (
            <>
              <span className="product-images__label">A imagem abaixo foi importada.</span>
            </>
          )}

          <ul className="product-images__list">
            {included.map(e => (
              <li className="product-images__list--item">
                <span>{e.name} - </span>
                <span>{e.size}KB</span>
              </li>
            ))}
          </ul>

          <div className="countImages">
            <strong>Total de imagens: {`${included.length} / ${imgCounter}`}</strong>
          </div>
          <Button onClick={() => setModal(null)}>Ok, entendi</Button>
        </div>
      </Modal>
    );
  }

  const exportProducts = e => {
    if (sellerid) {
      setLoading(true);

      const exportUrl = new URL(window.location.protocol + window.location.hostname + '/product/export');
      exportUrl.searchParams.append('sellerId', sellerid);
      if (brandid) exportUrl.searchParams.append('brandId', brandid);
      if (categoryid) exportUrl.searchParams.append('categoryId', categoryid);
      if (productName) exportUrl.searchParams.append('productName', productName);
      if (referenceCode) exportUrl.searchParams.append('referenceCode', referenceCode);

      if (active === 'Habilitado' || active === 'Desabilitado') {
        exportUrl.searchParams.append('active', getState(active));
      }

      if (productId) exportUrl.searchParams.append('id', parseInt(productId));

      const urlFormatted = exportUrl.href.replace(`${window.location.protocol}//${window.location.hostname}`, '');
      api.get(urlFormatted)
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = 'productExport.xlsx';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        })
        .catch(error => {
          changeModal('Atenção', 'Erro  ao exportar arquivo');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setModal(
        <Modal onCloseAction={() => setModal(null)} title="Atenção">
          <div>
            <p>O filtro deve ter selecionado um fornecedor.</p>
            <div className="modal--button">
              <Button onClick={() => setModal(null)}>Ok, entendi</Button>
            </div>
          </div>
        </Modal>
      );
    }
  };

  const importModel = () => {
    setLoading(true);

    api.get('/product/export/model')
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'productImportModel.xlsx';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        setLoading(false);
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao exportar arquivo');
      });
  };

  const importOffersModel = () => {
    setLoading(true);

    api.get('/offer/importmodel')
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'Ofertas - Modelo importação.csv';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        setLoading(false);
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao exportar arquivo');
      });
  };

  const exportOffers = sellerId => {
    setLoading(true);
    let date = new Date();

    api.get(`/offer/export/seller/${sellerId}`)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Preço/Estoque - Exportação ${date.toLocaleDateString()} - ${date.toLocaleTimeString()}.csv`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao exportar arquivo');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const importUpdateOffers = e => {
    setModal(<Loading />);

    let date = new Date();
    var fileImport = e.target.files[0];

    var formData = new FormData();

    formData.append('file', fileImport);

    api.post('/offer/importupdate', formData)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Preço/Estoque - Importação ${date.toLocaleDateString()} - ${date.toLocaleTimeString()}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        setModal(null);
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao importar arquivo');
      });
  };

  const showModalExport = () => {
    const sellerId = getSellerId();

    sessionCatalog.events.product.clickPriceStockExport();

    if (sellerId) {
      exportOffers(sellerId);
    } else if (!roleSellerRO && !roleSellerWR) {
      return setModal(
        <Modal onCloseAction={() => setModal(null)} title="Exportar ofertas">
          <ModalSelectSellersExport onExport={exportOffers} />
        </Modal>
      );
    } else {
      changeModal('Exportar ofertas', 'O usuário não possui um id de fornecedor válido.');
    }
  };

  const showModalImportUpdate = () => {
    sessionCatalog.events.product.clickPriceStockImport();

    setModal(
      <Modal onCloseAction={() => setModal(null)} title="Importar ofertas">
        <ModalUpdateImportNotice onImport={importUpdateOffers} onExport={showModalExport} />
      </Modal>
    );
  };

  const handleImportProduct = () => {
    setSeemore(false);
    setModal(
      <ModalImportFile
        setModal={setModal}
        fileType="xlsx"
        urlImport="product/import"
        title="Importação planilha de produtos"
        description="Aqui você pode fazer a importação da planilha de produtos."
      />
    );
  };

  return (
    <div>
      <div className="header-customer-group header-customer-group--src">
        <div className="header-customer-group__info">
          <h2 className="section-title">Produtos</h2>
          <p className="header-customer-group__description">Use os campos abaixo para fazer a busca dos produtos.</p>
        </div>

        <div className="header-customer-group__new">
          {roleProductWR && !roleSellerRO && !roleSellerWR ? (
            <>
              <NavLink className="link new-product" to="/catalogos/produtos/cadastro">
                Criar novo produto
              </NavLink>
              <Link className="header-customer-group__actions-link--export" onClick={e => exportProducts(e)}>
                Exportar Produtos
              </Link>
            </>
          ) : (
            <></>
          )}

          <div className="options-link" ref={wrapperRef}>
            <Link className="header-customer-group__actions-link link-see-more" onClick={e => seeMoreToggle(e)}>
              Ver mais ações <ChevronDown fill="#EA1D2C" className={seeMore ? 'chevron-down open' : 'chevron-down'} />
            </Link>
            {seeMore ? (
              <div className="header-customer-group__actions-more">
                <div className="dropdown-ifood">
                  <Link className="header-customer-group__actions-link" onClick={showModalExport}>
                    Preço/Estoque - Exportação
                  </Link>
                  <label className="link header-customer-group__actions-link" htmlFor="importUpdateOffers">
                    Preço/Estoque - Importação
                  </label>
                  <input
                    type=""
                    id="importUpdateOffers"
                    className="header-customer-group__actions-import"
                    onClick={showModalImportUpdate}
                  />
                  {roleProductWR && roleProductRO ? (
                    <>
                      <label className="link header-customer-group__actions-link" onClick={handleImportProduct}>
                        Produtos - Importação
                      </label>

                      <Link className="header-customer-group__actions-link" onClick={() => importModel()}>
                        Produtos - Modelo Importação
                      </Link>

                      <label className="link header-customer-group__actions-link" htmlFor="importImages">
                        Importar imagens
                      </label>
                      <input
                        type=""
                        id="importImages"
                        className="header-customer-group__actions-import"
                        onClick={e => importImages(e)}
                      />

                      <label className="link header-customer-group__actions-link" htmlFor="importOffers">
                        Importar ofertas
                      </label>
                      <input
                        type="file"
                        id="importOffers"
                        className="header-customer-group__actions-import"
                        onChange={e => importOffers(e)}
                      />
                      <Link className="header-customer-group__actions-link" onClick={() => importOffersModel()}>
                        Ofertas - Modelo Importação
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <form className="form-brand form-brand--product" onSubmit={getProducts}>
          <div className="form-row">
            <div className="form-brand__search">
              <OutlinedInput
                name="productId"
                className="form-brand__input"
                placeholder="Id"
                border
                maxLength={50}
                onChange={e => setProductId(e.target.value)}
              />

              <OutlinedInput
                name="productName"
                className="form-brand__input"
                placeholder="Produto"
                border
                maxLength={50}
                onChange={e => setProductName(e.target.value)}
              />

              <OutlinedInput
                name="referenceCode"
                className="form-brand__input"
                placeholder="SKU"
                border
                maxLength={50}
                onChange={e => setReferenceCode(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-brand__search">
              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="category"
                  className="dropdown-ifood"
                  popupIcon={<a className="dropdown-ifood__arrow" />}
                  noOptionsText="Sem resultados"
                  options={options || []}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label="Categoria" variant="outlined" />}
                  onSelect={e => setCategory(e.target.value)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCategoryid(newValue.id);
                    } else {
                      setCategoryid(null);
                    }
                  }}
                  onInputChange={e => setCategoryName(e ? e.target.value : '')}
                />
              </div>

              {!roleSellerWR && !roleSellerRO && (
                <div className="dropdown-label-wrap">
                  <Autocomplete
                    id="seller"
                    className="dropdown-ifood"
                    popupIcon={<span className="dropdown-ifood__arrow" />}
                    noOptionsText="Sem resultados"
                    options={sellers || []}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField {...params} label="Fornecedor" variant="outlined" />}
                    onSelect={e => setSeller(e.target.value)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSellerid(newValue.id);
                      } else {
                        setSellerid(null);
                      }
                    }}
                  />
                </div>
              )}

              <div className="dropdown-label-wrap">
                <Autocomplete
                  id="brand"
                  className="dropdown-ifood"
                  popupIcon={<span className="dropdown-ifood__arrow" />}
                  noOptionsText="Sem resultados"
                  options={brands || []}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label="Marca" variant="outlined" />}
                  onSelect={e => setBrand(e.target.value)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setBrandid(newValue.id);
                    } else {
                      setBrandid(null);
                    }
                  }}
                />
              </div>

              <div className="dropdown-label-wrap">
                <Dropdown
                  options={['Todos', 'Habilitado', 'Desabilitado']}
                  onChange={e => setActive(e.value)}
                  value={active ? active : null}
                  placeholder="Estado"
                  className="dropdown-ifood"
                  controlClassName="dropdown-ifood__control"
                  arrowClassName="dropdown-ifood__arrow"
                />
                <label className="dropdown-label">Estado</label>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-2">
              <Button type="submit">Filtrar produtos</Button>
            </div>
          </div>
        </form>
      </div>
      {modal}
      {loading && <Loading />}
    </div>
  );
};

export default FormSearchProduct;
