import styled from 'styled-components';

export const HandleWrapper = styled.div<{ isDragging?: boolean }>`
  height: 1rem;
  vertical-align: text-top;
  display: inline-block;
  margin-right: 0.5rem;
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};

  svg {
    width: 100%;
    height: 100%;
  }
`;
