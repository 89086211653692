import React from 'react'
import FormRegisterDistributionAddress from '../components/FormRegisterDistributionAddress'
import Roles from '@helpers/Roles'
import { getRoles } from "@helpers/rolesUtils";
import { NavLink } from "react-router-dom";

function DistributionAddressRegister(props) {
	const roles = getRoles();
  const roleDistributionWR = roles.includes(Roles.DISTRIBUTION_ADDRESS_WR);

  if (!roleDistributionWR) {
    window.location.href = '/';
	}

	return (
    <>
			<NavLink to="/fornecedores/enderecos-distribuicao" className="go-back"/>
			<div className="content-body">
				<h2 className="section-title">Cadastro de Centro de Distribuição</h2>
				{
					roleDistributionWR
						? <>
								<p className="description-form">Use os campos abaixo para criar um novo Centro de Distribuição</p>
								<FormRegisterDistributionAddress />
							</>
						: <p className="description-form">Você não tem permissão para criar um novo voucher</p>
				}
			</div>
    </>
	);
}

export default DistributionAddressRegister;
