/* eslint-disable react-hooks/exhaustive-deps */
import ModalForm from '@components/ModalForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { Flex, Input, Radio } from '@ifood/pomodoro-components';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import schema, { InactiveReasonFormValueType } from './schema';
import * as S from './InactiveReasonModalForm.styles';
import { useSellerDetailsData } from '../../SellerDetailsHook';

interface IModalFormProps {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (v: boolean) => void;
  setToggle: (v: boolean) => void;
}

const InactiveReasonModalForm = ({ isOpen, setIsOpen, setToggle }: IModalFormProps): JSX.Element => {
  const BAUStoresRolloutReasonId = 9;

  const form = useForm<InactiveReasonFormValueType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {},
  });

  const { sellerResponse, sellerReasonResponse, sellerChangeStatus, setIsActiveWord } = useSellerDetailsData();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { isValid, errors },
  } = form;

  const selectedReason = watch('reasons');

  useEffect(() => {
    trigger('reasons');
  }, [watch('reasons')]);

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [isOpen]);

  const onSubmit: SubmitHandler<InactiveReasonFormValueType> = data => {
    setIsActiveWord('inativado');

    sellerChangeStatus({
      variables: {
        sellerStatus: {
          sellerId: sellerResponse?.getSellerV2?.id,
          reasonId: data?.reasons,
          details: data?.note,
          newSellerId: data?.newSellerId,
          isActive: false,
        },
      },
    });

    setToggle(false);
    setIsOpen(false);
  };
  return (
    <ModalForm isOpen={isOpen} title="Informe o motivo na inativação" onClose={() => setIsOpen(false)}>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Radio.Group aria-labelledby="inativação-motivos" data-testid="inactive-reasons" mb={10} role="group">
          {sellerReasonResponse?.getInactivationReasons?.map(item => (
            <Radio.Button
              key={item?.id}
              {...register('reasons')}
              label={item?.reason as string}
              mb={12}
              value={item?.id as number}
            />
          ))}
        </Radio.Group>

        {Number(selectedReason) === BAUStoresRolloutReasonId && (
          <Input
            {...register('newSellerId', { valueAsNumber: true })}
            error={errors.newSellerId?.message}
            label="Novo Seller ID"
            mb={20}
            type="number"
            onWheel={e => e.currentTarget.blur()}
          />
        )}

        <S.CustomTextArea {...register('note')} placeholder="Observação do motivo da inativação" />

        <Flex gap={20} justifyContent="flex-end" my={32}>
          <S.ButtonCancel marginRight={18} variant="secondary" onClick={() => setIsOpen(false)}>
            Fechar
          </S.ButtonCancel>
          <S.ButtonSave disabled={!isValid}>Concluir inativação</S.ButtonSave>
        </Flex>
      </S.Form>
    </ModalForm>
  );
};

export default InactiveReasonModalForm;
