import { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PRODUCT_SUGGESTS } from '../../../graphql/product-suggest.queries';
import ReactPaginate from 'react-paginate';
import { Modal, Link } from 'ifoodshop-react-ui';
import { Tooltip } from '@material-ui/core';

import AuthorizationHeader from '@helpers/AuthorizationHeader';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import FormSearchSuggest from '../components/FormSearchSuggest';
import TableSuggests from '@components/TableSuggests';
import Loading from '@components/Loading';
import AlertModal from '@components/AlertModal';
import ModalImportFile from '@components/ModalImportFile';

import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import IconArrow from '../../../assets/images/arrow.png';
import './suggestSearch.scss';
import { api } from '@services/api';

const SuggestSearch = props => {
  const roles = getRoles();
  const history = useHistory();
  const wrapperRef = useRef(null);

  const roleSuggest = roles.includes(Roles.SUGGESTION_WR);
  const roleSuggestRO = roles.includes(Roles.SUGGESTION_RO);
  const roleSuggestAPR = roles.includes(Roles.SUGGESTION_APR);

  if (!roleSuggest && !roleSuggestRO && !roleSuggestAPR) history.push('/catalogos');

  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(null);
  const [dataForm, setDataForm] = useState(null);
  const [seeMore, setSeemore] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getSuggests, { data, error }] = useLazyQuery(GET_PRODUCT_SUGGESTS);

  

  useEffect(() => {
    setPage(0);
    if (dataForm) {
      handleRequest();
    }
  }, [dataForm]);

  useEffect(() => {
    handleRequest();
  }, [page]);

  useEffect(() => {
    if (props?.location?.state?.successSuggest) {
      setTimeout(() => {
        setModal(
          <AlertModal
            className="modal-suggest"
            title="A sugestão de produto foi realizada com sucesso! "
            description="O time de cadastro do iFood Shop irá avaliar a sugestão e fazer a aprovação. Assim que o produto for aprovado, o status do produto será atualizado para Habilitado."
            titleButton="Ok, entendi!"
            setModal={setModal}
          />
        );
      }, 1000);
    }
  }, [props?.location?.state?.successSuggest]);

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  const handleRequest = () => {
    getSuggests({
      variables: {
        page: parseInt(page),
        pageSize: 20,
        name: dataForm ? dataForm.name : null,
        sellerId: dataForm?.sellerCode
          ? parseInt(dataForm.sellerCode)
          : dataForm?.seller
          ? parseInt(dataForm.seller)
          : null,
        category: dataForm?.categoryName ? dataForm.categoryName : null,
        categoryId: dataForm ? parseInt(dataForm.category) : null,
        brand: dataForm?.brandName ? dataForm.brandName : null,
        brandId: dataForm ? parseInt(dataForm.brand) : null,
        createdAt: dataForm && dataForm.createdAt ? dataForm.createdAt.toISOString().split('T')[0] : null,
      },
    });
  };

  const ImagesUpload = e => {
    e.preventDefault();
    setModal(<Loading />);

    let uploaddata = new FormData();

    var ins = document.getElementById('importImages').files.length;
    for (var x = 0; x < ins; x++) {
      uploaddata.append('files', document.getElementById('importImages').files[x]);
    }

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');

    api.post('/productsuggestion/imageimport', uploaddata)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'imagesImportReturn.csv';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        setModal(null);
      })
      .catch(error => {
        setModal(<Modal onCloseAction={() => setModal(null)} title="Erro  ao importar arquivo" />);
      });
  };

  const root = document.getElementById('root');
  root.addEventListener('click', e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSeemore(false);
    }
  });

  function seeMoreToggle(e) {
    if (seeMore) {
      setSeemore(false);
    } else {
      setSeemore(true);
    }
  }

  const importModel = () => {
    setLoading(true);

    api.get('/suggestion/export/model')
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'productSuggestiontImportModel.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();

        setLoading(false);
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao exportar arquivo');
        setLoading(false);
      });
  };

  const handleImportProduct = () => {
    setSeemore(false);
    setModal(
      <ModalImportFile
        setModal={setModal}
        fileType="xlsx"
        uploadS3="fileengine/upload"
        title="Importação planilha cadastro de produtos"
        description="Aqui você pode fazer a importação da planilha de cadastro de produtos."
      />
    );
  };

  return (
    <>
      {modal}
      {(roleSuggestAPR || roleSuggestRO || roleSuggest) && (
        <>
          <NavLink to="/catalogos" className="go-back" />
          <div className="content-body search-suggest">
            <div className="header-customer-group header-customer-group--src">
              <div className="header-customer-group__info--suggest">
                <h2 className="section-title">Cadastro de Produtos</h2>
                <p className="header-customer-group__description">
                  Use os campos abaixo para fazer uma busca ou filtrar os produtos
                </p>
              </div>
              <div className="header-customer-group__new">
                {roleSuggest ? (
                  <>
                    <NavLink className="link" to="/catalogos/sugestao/cadastro">
                      Cadastrar produto
                    </NavLink>

                    <div className="options-link" ref={wrapperRef}>
                      <Link
                        className="header-customer-group__actions-link link-see-more"
                        onClick={e => seeMoreToggle(e)}
                      >
                        Ver mais ações{' '}
                        <ChevronDown fill="#EA1D2C" className={seeMore ? 'chevron-down open' : 'chevron-down'} />
                      </Link>
                      {seeMore && (
                        <div className="header-customer-group__actions-more">
                          <div className="dropdown-ifood">
                            <Link className="header-customer-group__actions-link" onClick={() => importModel()}>
                              Produtos - Modelo Importação
                            </Link>
                            <label className="link header-customer-group__actions-link" onClick={handleImportProduct}>
                              Produtos - Importação
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <Tooltip title={'Você não tem permissão para criar uma sugestão.'}>
                    <span className={'link--disabled'}>Cadastrar produto</span>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="header-suggest__actions">
              <FormSearchSuggest data={e => setDataForm(e)} currentpage={page} />
            </div>
            <div className="table-box">
              {data && data.getAllProductSuggestions && (
                <TableSuggests roleSuggest={roleSuggestAPR} suggests={data.getAllProductSuggestions.content} />
              )}
              {data && data.getAllProductSuggestions.totalPages > 1 && (
                <div className="search-brand-paginate">
                  <ReactPaginate
                    containerClassName="pagination-ifoodshop"
                    pageClassName="pagination-ifoodshop__item"
                    pageLinkClassName="pagination-ifoodshop__item-link"
                    activeLinkClassName="active"
                    previousLabel={<img className="previous-arrow" src={IconArrow} />}
                    nextLabel={<img className="next-arrow" src={IconArrow} />}
                    pageRangeDisplayed={3}
                    pageCount={data.getAllProductSuggestions.totalPages}
                    marginPagesDisplayed={1}
                    initialPage={page}
                    forcepage={page}
                    onPageChange={e => setPage(e.selected)}
                  />
                </div>
              )}
            </div>
            {loading && <Loading />}
          </div>
        </>
      )}
    </>
  );
};
export default SuggestSearch;
