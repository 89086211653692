const paymentOrderSequence: Record<string, number> = {
  BONUS_SHOP: 0,
  POST_PAID_BANK_SLIP: 1,
  PIX: 2,
  REPASSE: 3,
  BANK_SLIP: 4,
  CREDIT_CARD: 5,
};

const methodName: Record<string, string> = {
  BANK_SLIP: 'Boleto à vista',
  BONUS_SHOP: 'Bônus Shop',
  DEFAULT: 'Pagamento',
  CREDIT_CARD: 'Cartão de Crédito',
  PIX: 'Pix',
  POST_PAID_BANK_SLIP: 'Boleto à prazo',
  REPASSE: 'Repasse',
};

export const allPaymentMethods = [
  { name: 'BONUS_SHOP', checked: false },
  { name: 'POST_PAID_BANK_SLIP', checked: false },
  { name: 'PIX', checked: false },
  { name: 'REPASSE', checked: false },
  { name: 'BANK_SLIP', checked: false },
  { name: 'CREDIT_CARD', checked: false },
];

export const methodAllowedToEdit = ['CREDIT_CARD', 'PIX'];

export const sortPayments = (a: string, b: string) => paymentOrderSequence[a] - paymentOrderSequence[b];

export const formatPaymentName = (type: string) => {
  return methodName[type] || methodName.DEFAULT;
};

const checkedPaymentMethods = (paymentsList: { name: string; checked: boolean }[]) =>
  paymentsList.filter(method => method.checked);

export const checkedPaymentNames = (paymentsList: { name: string; checked: boolean }[]) =>
  checkedPaymentMethods(paymentsList).map(method => method.name);
