import "./infinite-scroll.scss";
import React, { useState, useEffect } from "react";
import InfiniteLoading from "react-simple-infinite-loading";
import { GET_PRODUCTS } from '../../graphql/product.queries'
import { useLazyQuery } from "@apollo/react-hooks";

const InfiniteScroll = ({ productName, selected }) => {

  const pageSize = 30;
  const [items, setItems] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [getProducts, { data: dataGetProducts }] = useLazyQuery(GET_PRODUCTS, {
      onCompleted: () => {
        setItems(items.concat(dataGetProducts.getAllProducts.content))
      }
    }
  )

  const loadMoreItems = () => {
    setNewPage(newPage + 1);
  }

  useEffect(() => {
    handleRequest(productName, newPage)
  }, [newPage])

  useEffect(() => {
    setItems([])
    handleRequest(productName, 0)
  }, [productName])

  const handleRequest = (product, page) => {
    getProducts({
      variables: {
        page: page,
        size: pageSize,
        productName: product
      }
    })
  }

  const selectProduct = (e, item) => {
    selected(item)
    setItems([])
  }

  return (
    items.length > 0 ?
      <div className="scroll">
        <InfiniteLoading
          customScrollbar={true}
          hasMoreItems
          itemHeight={ 48 }
          loadMoreItems={ loadMoreItems }
        >
          { items.map((item) => (
            <div
              className="item"
              key={ item.id }
              onClick={ (e) => selectProduct(e, item) }
            >
              { item.name }
            </div>
          )) }
        </InfiniteLoading>
      </div>
      : null
  );
}

export default InfiniteScroll;
