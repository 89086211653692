import gql from 'graphql-tag';

const GET_ALL_BADWORDS = gql`
  query getAllBadWords($page: Int, $pageSize: Int, $word: String, $createdAt: String, $active: Boolean){
    getAllBadWords(page: $page, pageSize: $pageSize, word: $word, createdAt: $createdAt, active: $active){
      content{
        id
        word
        active
        createdAt
        updatedAt
      }

      empty
      first
      last
      number
      numberOfElements
      size
      totalElements
      totalPages

    }
  }
`;

const GET_BADWORD_BY_WORD = gql`
  query getBadWordByWord($word: String!){
    getBadWordByWord(word: $word){
      id
      word
      active
      createdAt
      updatedAt
    }
  }
`;

const GET_BADWORD_BY_ID = gql`
  query getBadWord($id: ID!){
    getBadWord(id: $id){
      id
      word
      active
      createdAt
      updatedAt
    }
  }

`;

export { GET_ALL_BADWORDS, GET_BADWORD_BY_WORD, GET_BADWORD_BY_ID }