import { NavLink, useHistory } from 'react-router-dom';

import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import FormRegisterCategory from '../components/FormRegisterCategory';
import HeaderRegisterCategory from '../components/HeaderRegisterCategory';

import './registercategory.scss';

const CategoryRegister = () => {
  const history = useHistory();
  const roles = getRoles();
  const roleCategoryWriter = roles.includes(Roles.CATEGORY_WR);

  if (!roleCategoryWriter) history.push('/catalogos');

  return (
    <>
      <NavLink to="/catalogos/categorias" className="go-back" />
      <div className="content-body register-category">
        <HeaderRegisterCategory
          title="Criar uma categoria"
          description="Use os campos abaixo para criar uma categoria."
        />
        <FormRegisterCategory />
      </div>
    </>
  );
};

export default CategoryRegister;
