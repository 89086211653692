import { useCallback, useContext } from 'react';
import { DragAndDrop } from '@ifood/pomodoro-icons';
import { Table, Text } from '@ifood/pomodoro-components';
import { ThemeContext } from 'styled-components';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as S from './style';

type Props = {
  columns: any[];
  item: any;
  position?: number;
  isStatic?: boolean;
};

const DraggableTableRow = ({ columns, item, position, isStatic }: Props): JSX.Element => {
  const theme = useContext(ThemeContext);
  const isZero = 0;

  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: item.uiId,
  });

  const isFirstColumn = useCallback((index: number) => index === isZero, []);

  return (
    <Table.Row
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
      }}
    >
      {isDragging ? (
        <Table.Data colSpan={4} style={{ backgroundColor: theme.colors.primaryLightest }}>
          &nbsp;
        </Table.Data>
      ) : (
        columns.map((column, index) => {
          if (isFirstColumn(index)) {
            return (
              <Table.Data key={column.name + item.uiId}>
                <S.HandleWrapper {...attributes} {...listeners} isDragging={isStatic}>
                  <DragAndDrop />
                </S.HandleWrapper>
                {column.formatter(item, position)}
              </Table.Data>
            );
          }
          return (
            <Table.Data key={column.name + item.uiId}>
              <Text as="p" style={{ textAlign: column.alignment || 'left' }}>
                {column.formatter(item, position)}
              </Text>
            </Table.Data>
          );
        })
      )}
    </Table.Row>
  );
};

export default DraggableTableRow;
