/* istanbul ignore file */
import ModalForm from '@components/ModalForm';
import { TableAdmin } from '@components/Table/Table.styles';
import FormatHelper, { invertMonthDay } from '@helpers/FormatHelper';
import { Button, DatePicker, Flex, Loading, Pagination, PaginationChangeData } from '@ifood/pomodoro-components';
import { BFFGetCustomerFraudListQuery } from '@services/bff/GraphqlOperations';
import * as S from '../../CustomerDetail.styles';
import { getFraudReasonByType, getFraudStatus } from '../../customerDetailHelper';
import { useState } from 'react';
import { QueryLazyOptions } from '@apollo/react-hooks';
import { BFFFraudHistoryInput, Exact, Maybe, BFFCustomerFraudContent } from '@services/bff/GraphqlTypes';
import { useParams } from 'react-router-dom';

interface IModalFormProps {
  isOpen: boolean;
  customerFraud: BFFGetCustomerFraudListQuery['getCustomerFraudList'];
  isLoading: boolean;
  getCustomerFraudList: (
    options?:
      | QueryLazyOptions<
          Exact<{
            params?: Maybe<BFFFraudHistoryInput>;
          }>
        >
      | undefined
  ) => void;
  onClose: () => void;
}

const ZERO = 0;
const ONE = 1;
const PAGE_SIZE = 10;
const { Table, Th, Td } = TableAdmin;

const CustomerFraudListModal = ({
  isOpen,
  onClose,
  getCustomerFraudList,
  isLoading,
  customerFraud,
}: IModalFormProps): JSX.Element => {
  const currentDate = FormatHelper.ptBrDate(new Date(), 'simple');
  const [pagination, setPagination] = useState<PaginationChangeData>({
    currentPage: 1,
    pageSize: PAGE_SIZE,
    nextOffset: 0,
    previousOffset: 0,
  });
  const fraudList = customerFraud?.content || [];
  const fraudListComplete = fraudList.flatMap(item => {
    const itemList = item as BFFCustomerFraudContent;
    return itemList.active
      ? [itemList]
      : [
          {
            ...itemList,
            active: false,
            reportedAt: itemList?.inactivatedAt,
            reportedBy: itemList?.inactivatedBy,
            reportDetails: itemList?.inactivationDetails,
          },
          {
            ...itemList,
            active: true,
          },
        ];
  });

  const { id: customerId } = useParams<{ id: string }>();
  const hasFrauds = fraudList && fraudList?.length > ZERO;
  const [stateDate, setStateDate] = useState(currentDate);

  function onPaginationChange(paginationData: PaginationChangeData) {
    setPagination(paginationData);
    getCustomerFraudList({
      variables: {
        params: {
          customerId: parseInt(customerId),
          pageable: { page: paginationData.currentPage - ONE, size: PAGE_SIZE },
        },
      },
    });
  }

  const handleDate = () => {
    const reportedAt = new Date(invertMonthDay(stateDate)).toISOString();
    getCustomerFraudList({
      variables: {
        params: {
          customerId: parseInt(customerId),
          reportedAt,
          pageable: { page: pagination.currentPage - ONE, size: PAGE_SIZE },
        },
      },
    });
  };

  function onDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setStateDate(value);
  }

  return (
    <ModalForm isOpen={isOpen} modalProps={{ size: '80%' }} title="Histórico de fraudes" onClose={onClose}>
      <S.ModalWrapper>
        <Flex alignItems={'flex-end'} mb={24}>
          <DatePicker
            maxWidth={'274px'}
            mr={24}
            name="datePick"
            value={stateDate}
            width={'100%'}
            onChange={onDateChange}
          />
          <Button variant="secondary" onClick={handleDate}>
            Buscar
          </Button>
        </Flex>

        {isLoading && <Loading color="primary" variant="large" />}
        {!isLoading && !hasFrauds && <S.NoItemsFound>Nenhuma fraude encontrada</S.NoItemsFound>}
        {!isLoading && hasFrauds && (
          <Table>
            <thead>
              <tr>
                <Th>Motivo</Th>
                <Th>Status</Th>
                <Th>Data</Th>
                <Th>Usuário responsável</Th>
                <Th>Observação</Th>
              </tr>
            </thead>
            <tbody>
              {fraudListComplete ? (
                fraudListComplete.map(fraud => (
                  <tr key={fraud?.customerId}>
                    <Td>{getFraudReasonByType(fraud?.fraudType)}</Td>
                    <Td>{getFraudStatus(fraud?.active)}</Td>
                    <Td>{FormatHelper.format(fraud?.reportedAt, 'date')}</Td>
                    <Td>{fraud?.reportedBy}</Td>
                    <Td>{fraud?.reportDetails}</Td>
                  </tr>
                ))
              ) : (
                <>Nenhum resultado encontrado.</>
              )}
            </tbody>
          </Table>
        )}
      </S.ModalWrapper>

      <Flex justifyContent="right" mb="60px" mt="60px">
        <Pagination.Numbered
          showArrowLabel
          showCounter
          current={pagination.currentPage}
          nextLabel="Próximo"
          ofLabel="de"
          pageSize={pagination.pageSize}
          previousLabel="Anterior"
          showingLabel="Exibindo"
          totalItems={customerFraud?.totalElements || ZERO}
          onChange={onPaginationChange}
        />
      </Flex>
    </ModalForm>
  );
};

export default CustomerFraudListModal;
