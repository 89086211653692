import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ReactPaginate from 'react-paginate';

import TableFreightRangeSearch from '../../components/TableFreightRangeSearch';
import FilterFreightRangeSearch from '../../components/FilterFreightRangeSearch';
import HeaderForm from '../../components/HeaderForm';

import { useCarrierFreightRangeContext } from '@components/_context/CarrierFreightRangeContext';

import IconArrow from '../../../../assets/images/arrow.png';


function ListFreightRange() {
  const {
    getFreightRangeByCarrierId,
    getCarrierById,
    dataCarrier,
    carrierId,
    pageSearchFreightRange,
    setPageSearchFreightRange,
    lastPageFreightRange,
    getAllCarriers,
    setFormSearch,
  } = useCarrierFreightRangeContext();

  useEffect(() => {
    if(!dataCarrier && carrierId){
      getCarrierById()
    }

    getFreightRangeByCarrierId();

  }, [dataCarrier]);

  useEffect(() => {
    setFormSearch({});
    getAllCarriers();
  }, []);


  return (
    <>
      <NavLink to="/fornecedores/transportadoras/" className="go-back" />
      <div className="content-body">
        <HeaderForm />
        <Grid container spacing={2} className="container form-carrier" justify="space-between" alignItems="baseline">
          <Grid item xs={6}>
            <h3 className="form-subtitle">Faixas de Frete</h3>
          </Grid>
          <Grid item xs={4} className="text--right">
            <NavLink to={`/fornecedores/transportadoras/cadastro/${carrierId}/faixa-frete`} className="link">Nova faixa frete</NavLink>
          </Grid>
          <Grid item xs={12}>
            <FilterFreightRangeSearch />
          </Grid>
          <Grid item xs={12}>
            <TableFreightRangeSearch />
          </Grid>
          {lastPageFreightRange > 1 &&
          <div className="search-freight-range-paginate">
            <ReactPaginate
              containerClassName="pagination-ifoodshop"
              pageClassName="pagination-ifoodshop__item"
              pageLinkClassName="pagination-ifoodshop__item-link"
              activeLinkClassName="active"
              previousLabel={
                <img className="previous-arrow" src={IconArrow} />
              }
              nextLabel={
                <img className="next-arrow" src={IconArrow} />
              }
              onPageChange={(e) => setPageSearchFreightRange(e.selected)}
              pageRangeDisplayed={3}
              pageCount={lastPageFreightRange}
              marginPagesDisplayed={1}
              initialPage={pageSearchFreightRange}
            />
          </div>
          }
        </Grid>
      </div>
    </>
  )
}

export default ListFreightRange;