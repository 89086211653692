import React from "react";
import { NavLink } from "react-router-dom";
import "./header.scss";

const Header = ({
  title,
  description,
  backRoute,
  createRoute,
  titleLink,
  role,
  isStep,
  step,
  setStep
}) => {

  function handleClick(e) {
    if (step > 0) {
      e.preventDefault();
      setStep(step - 1);
    }
  }

  return (
    <div className="header">
      <NavLink
        to={!isStep || step < 1 ? backRoute : ""}
        onClick={(e) => handleClick(e)}
        className="go-back"
      />
      <div className="header-customer-group header-customer-group--src">
        <div className="header-customer-group__info">
          <h2 className="section-title">{title}</h2>
          <p className="header-customer-group__description">{description}</p>
        </div>
        {createRoute && (
          <div className="header-customer-group__new">
            <NavLink
              to={role ? createRoute : "#"}
              className={role ? "link" : "link--disabled"}
            >
              {titleLink}
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
