import { Aboyeur } from '@ifood/aboyeur';
import { FasterPlugin } from '@ifood/aboyeur-plugin-faster';

const API_KEY = process.env.REACT_APP_API_KEY_FASTER || window?.config?.FasterK;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY_FASTER || window?.config?.FasterS;
const APP_VERSION = process.env.NODE_ENV || '';

const PLUGINS = {
	[FasterPlugin.displayName]: FasterPlugin,
};

const ALL_ABOYEUR_METADATA = {
	faster: {
		version: APP_VERSION,
		apiKey: API_KEY,
		secret: SECRET_KEY
	},
};

export function initializeAboyeur() {
	Aboyeur.initialize(ALL_ABOYEUR_METADATA, PLUGINS);
}
