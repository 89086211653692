import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_PARENTS, GET_CATEGORY_FORM_TREE } from '../../../graphql/category.queries';
import Dropdown from 'react-dropdown';
import { Input } from '@ifood/pomodoro-components';
import SuperTreeView from 'react-super-treeview';

import client from '@services/apollo';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';

import Loading from '@components/Loading';

import './categories.scss';
import './supertree.scss';

const Categories = () => {
  const history = useHistory();
  const roles = getRoles();
  const roleCategoryWriter = roles.includes(Roles.CATEGORY_WR);
  const roleCategoryRead = roles.includes(Roles.CATEGORY_RO);
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);

  if (!roleCategoryWriter && !roleCategoryRead) history.push('/catalogos');

  const [categories, setCategories] = useState(null);
  const [render, setRender] = useState(null);
  const optionsState = ['Todos', 'Habilitado', 'Desabilitado'];
  const [state, setState] = useState(optionsState[0]);

  const { data } = useQuery(GET_PARENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      if (data && data.getAllParentCategories) {
        let tempdata = data.getAllParentCategories.map((e, index) => ({
          ...e,
          renderdata: (
            <>
              <div className="tree__item" id={'cattree_div_' + e.id}>
                <div className="tree__status idCategoryTree" title={e.id} data-level={index.toString()}>
                  {e.id}
                </div>
                <div className="tree__name" id={'id_' + e.id} title={e.name} data-level={index.toString()}>
                  {e.name}
                </div>
                {e.active == true ? (
                  <div className="tree__status">
                    <span className="tree__status-label enabled"> Habilitado</span>
                  </div>
                ) : (
                  <div className="tree__status">
                    <span className="tree__status-label disabled">Desabilitado</span>
                  </div>
                )}
                <NavLink
                  id={'cattree_link_' + e.id}
                  className={roleCategoryWriter ? 'link tree__edit' : 'link--disabled'}
                  to={roleCategoryWriter ? `/catalogos/categorias/detalhe/${e.id}` : '#'}
                >
                  Editar
                </NavLink>
              </div>
            </>
          ),
        }));
        setCategories(tempdata);
        setRender(crypto.getRandomValues(array));
      }
    },
  });

  const handleFilter = () => {
    let inputElement = document.querySelector('#inputFilterCategory');
    let listElement = document.querySelector('.super-treeview');
    let itemElement = listElement.querySelectorAll('.super-treeview-node');

    inputElement.addEventListener('input', e => {
      let inputed = e.target.value.toLowerCase();

      itemElement.forEach(item => {
        let categoryValue = item.textContent.toLowerCase();

        if (categoryValue.includes(inputed)) {
          item.style.display = 'block';
        } else {
          item.style.display = 'none';
        }
      });
    });
  };

  const filterState = value => {
    let listElement = document.querySelector('.super-treeview');
    let itemElement = listElement.querySelectorAll('.super-treeview-node');

    setState(value);

    itemElement.forEach(item => {
      let categoryValue = item.textContent;

      if (categoryValue.includes(value)) {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }

      if (value === 'Todos') {
        item.style.display = 'block';
      }
    });
  };

  return (
    <>
      <NavLink to="/catalogos" className="go-back" />
      <main className="content-body categories">
        <div className="categories__header">
          <h2 className="section-title">Categorias</h2>

          <NavLink
            id="buttonNewCategory"
            className={roleCategoryWriter ? 'link' : 'link--disabled'}
            to={roleCategoryWriter ? `/catalogos/categorias/cadastro` : '#'}
          >
            Nova categoria
          </NavLink>
        </div>

        <div className="categoryInputsFilter">
          <div className="inputCategoryDiv">
            <Input id="inputFilterCategory" placeholder="Buscar pelo nome ou ID da categoria" onChange={handleFilter} />
          </div>

          <div id="dropDownCategory" className="dropdown-label-wrap">
            <Dropdown
              id="dropDownCategorySelect"
              options={optionsState}
              value={state}
              onChange={e => filterState(e.value)}
              placeholder="Status"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Status</label>
          </div>
        </div>

        {render ? (
          <>
            <div className="categoryList">
              <div className="categoryTitleHeader">
                <p id="categoryIdP" className="categoryP">
                  ID
                </p>
                <p id="categoryIdent" className="categorycategoryP">
                  Identificador
                </p>
                <p id="categoryStatus" className="categoryP">
                  Status
                </p>
              </div>

              <SuperTreeView
                id="categorytree"
                data={categories}
                onUpdateCb={updatedData => {
                  setCategories(updatedData);
                }}
                isCheckable={() => false}
                isDeletable={() => false}
                isExpanded={() => false}
                loadingElement={<div>Carregando ...</div>}
                transitionEnterTimeout={0}
                transitionExitTimeout={0}
                keywordLabel="renderdata"
                isExpandable={(node, depth) => {
                  if (node.haveChildren == true) {
                    return true;
                  }
                  return false;
                }}
                onExpandToggleCb={(node, depth) => {
                  if (node.isExpanded === true) {
                    let updatedData = categories;
                    let level = document.getElementById('id_' + node.id.toString()).dataset.level;
                    // This will show the loading sign
                    node.isChildrenLoading = true;

                    client
                      .query({
                        query: GET_CATEGORY_FORM_TREE,
                        variables: {
                          parent: parseInt(node.id),
                        },
                      })
                      .then(res => {
                        if (node.parentId) {
                          let levels = level.split('.');
                          let children = [];
                          levels.map((item, index) => {
                            if (index == 0) {
                              children = 'updatedData[' + item + ']';
                            } else {
                              children += '.children[' + item + ']';
                            }
                          });
                          // Make sure node is expanded
                          eval(children + '.isExpanded = true');
                          // Remove loading sign
                          eval(children + '.isChildrenLoading = false;');
                          let spacebefore = 30 * (depth + 1);
                          let divStyle = {
                            paddingLeft: spacebefore.toString() + 'px',
                          };
                          let tempdata = res.data.getCategoriesToCreateTree.map((e, index) => ({
                            ...e,
                            renderdata: (
                              <div className="tree__item">
                                <div
                                  className="tree__status idCategoryTree"
                                  title={e.id}
                                  data-level={level + '.' + index.toString()}
                                  style={divStyle}
                                >
                                  {e.id}
                                </div>
                                <div
                                  className="tree__name"
                                  id={'id_' + e.id}
                                  title={e.name}
                                  data-level={level + '.' + index.toString()}
                                  style={divStyle}
                                >
                                  {e.name}
                                </div>
                                {e.active == true ? (
                                  <div className="tree__status">
                                    <span className="tree__status-label enabled"> Habilitado</span>
                                  </div>
                                ) : (
                                  <div className="tree__status">
                                    <span className="tree__status-label disabled">Desabilitado</span>
                                  </div>
                                )}
                                <NavLink
                                  className={roleCategoryWriter ? 'link tree__edit' : 'link--disabled'}
                                  to={roleCategoryWriter ? `/catalogos/categorias/detalhe/${e.id}` : '#'}
                                >
                                  Editar
                                </NavLink>
                              </div>
                            ),
                          }));
                          eval(children + '.children = tempdata;');
                          setCategories(updatedData);
                        } else {
                          let index = updatedData.findIndex(x => x.id === node.id);
                          // Make sure node is expanded
                          updatedData[index].isExpanded = true;
                          // Remove loading sign
                          updatedData[index].isChildrenLoading = false;
                          let spacebefore = 30 * (depth + 1);
                          let divStyle = {
                            paddingLeft: spacebefore.toString() + 'px',
                          };
                          let tempdata = res.data.getCategoriesToCreateTree.map((e, index) => ({
                            ...e,
                            renderdata: (
                              <div className="tree__item">
                                <div
                                  className="tree__status idCategoryTree"
                                  title={e.id}
                                  data-level={level + '.' + index.toString()}
                                  style={divStyle}
                                >
                                  {e.id}
                                </div>
                                <div
                                  className="tree__name"
                                  id={'id_' + e.id}
                                  title={e.name}
                                  data-level={level + '.' + index.toString()}
                                  style={divStyle}
                                >
                                  {e.name}
                                </div>
                                {e.active == true ? (
                                  <div className="tree__status">
                                    <span className="tree__status-label enabled"> Habilitado</span>
                                  </div>
                                ) : (
                                  <div className="tree__status">
                                    <span className="tree__status-label disabled">Desabilitado</span>
                                  </div>
                                )}
                                <NavLink
                                  className={roleCategoryWriter ? 'link tree__edit' : 'link--disabled'}
                                  to={roleCategoryWriter ? `/catalogos/categorias/detalhe/${e.id}` : '#'}
                                >
                                  Editar
                                </NavLink>
                              </div>
                            ),
                          }));
                          updatedData[index].children = tempdata;
                          setCategories(updatedData);
                        }
                      })
                      .catch(err => {
                        return err;
                      });
                  }
                }}
              />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </main>
    </>
  );
};

export default Categories;
