import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PRODUCT_IMAGES } from '../../graphql/product.queries';
import { DISABLE_PRODUCT_IMAGE } from '../../graphql/product.mutations';
import { UPDATE_VARIATION_IMAGES } from '../../graphql/variation.mutations';
import { GET_VARIATION_IMAGES } from '../../graphql/variation.queries';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Modal, Button } from 'ifoodshop-react-ui';
import Gallery from 'react-photo-gallery';

import './dragdrop.scss';

const DragDrop = props => {
  const imgWithClick = { cursor: 'pointer' };
  const [items, setItems] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [modal, setModal] = useState(null);

  const [disableImage] = useMutation(DISABLE_PRODUCT_IMAGE, {
    onCompleted: () => {
      refetchProductImages().then(res => setPhotos(res.data.findAllProductImage));
      setModal(null);
    },
  });
  const [findAllProductImage, { data: dataImages, refetch: refetchProductImages }] = useLazyQuery(GET_PRODUCT_IMAGES, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setPhotos(dataImages.findAllProductImage);
    },
  });
  const [findAllVariationImage, { data: dataVarAllImages, refetch: refetchVariationImages }] = useLazyQuery(
    GET_VARIATION_IMAGES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setPhotos(dataVarAllImages.findAllVariationImage);
      },
    }
  );
  const [updateVarImage] = useMutation(UPDATE_VARIATION_IMAGES, {
    onCompleted: () => {
      refetchVariationImages().then(res => setPhotos(res.data.findAllVariationImage));
      setModal(null);
    },
  });

  useEffect(() => {
    if (props.variationid) {
      findAllVariationImage({ variables: { variation: parseInt(props.variationid) } });
    }
    if (props.productid) {
      findAllProductImage({ variables: { product: parseInt(props.productid) } });
    }
  }, [props]);

  useEffect(() => {
    if (photos.length > 0) {
      let temparray = [];
      let size = Object.keys(photos).length;
      let count = 0;
      Object.keys(photos).map(function (objectKey, index) {
        let tempimage = {
          src: photos[index].imagePath,
          width: 4,
          height: 4,
          id: photos[index].id,
        };
        temparray.push(tempimage);
        count++;
        if (count == size) {
          setItems(temparray);
        }
      });
    }
  }, [photos]);

  const DisableImage = imagesrc => {
    let imageid = null;
    Object.keys(photos).map(function (objectKey, index) {
      if (photos[index].imagePath == imagesrc) {
        imageid = photos[index].id;
        if (props.productid) {
          disableImage({
            variables: {
              listImages: [
                {
                  id: parseInt(imageid),
                  imagePath: imagesrc,
                  active: false,
                  order: 0,
                  productId: parseInt(props.productid),
                },
              ],
            },
          });
        } else if (props.variationid) {
          updateVarImage({
            variables: {
              listImages: [
                {
                  id: parseInt(imageid),
                  imagePath: imagesrc,
                  active: false,
                  order: 0,
                  variationId: parseInt(props.variationid),
                },
              ],
            },
          });
        }
      }
    });
  };

  const SortablePhoto = SortableElement(item => <Photo {...item} />);

  const SortableGallery = SortableContainer(({ items }) => (
    <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (props.readonly !== 'disabled') {
      setItems(arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleDisable = (e, data) => {
    if (props.readonly !== 'disabled') {
      e.preventDefault();
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Deseja remover esta imagem?
          <div className="modalbtns">
            <Button onClick={() => DisableImage(data.src)}>Excluir</Button>
            <Button className="btn-secondary" onClick={() => setModal(null)}>
              Cancelar
            </Button>
          </div>
        </Modal>
      );
    }
  };

  const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
    const imgStyle = { margin: margin };
    if (direction === 'column') {
      imgStyle.position = 'absolute';
      imgStyle.left = left;
      imgStyle.top = top;
    }

    const handleClick = event => {
      onClick(event, { photo, index });
    };

    return (
      <div className="image-list">
        <img
          className="image-item"
          style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
          {...photo}
          onClick={onClick ? handleClick : null}
          alt="img"
        />
        {props.readonly !== 'disabled' && <button className="btn-trash" onMouseUp={e => handleDisable(e, photo)} />}
      </div>
    );
  };

  return (
    <>
      <div className="grid-list-container">
        {photos.length > 0 ? <SortableGallery items={items} onSortEnd={onSortEnd} axis={'xy'} /> : <a> </a>}
      </div>
      {modal}
    </>
  );
};

export default DragDrop;
