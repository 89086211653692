import { Select, snackbar } from '@ifood/pomodoro-components';
import { ReactNode, useEffect, useState } from 'react';
import FileUpload from '@components/fileUpload';
import useRequest from '@components/BulkImport/useRequest';
import { getRoles } from '@helpers/rolesUtils';
import { EnumHeaderOptionsType, HeaderOptionsType } from './index';

type TProps = {
  dialogTitle: string;
  label: string;
  options: HeaderOptionsType[];
  uploadUrl: string;
};

const BulkImport = ({ options, dialogTitle, label, uploadUrl }: TProps) => {
  const TIMEOUT_TIMER = 2000;
  const MAX_FILE_TO_UPLOAD = 1;

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const { handleRequest, hasSuccess, hasError, isLoading } = useRequest();
  const [children, setChildren] = useState<ReactNode | null>(null);

  const downloadModel = ({ filePath, name }: { filePath: string | undefined; name: string }) => {
    if (!filePath) return;

    const a = document.createElement('a');
    a.href = filePath;
    a.download = `${name}.csv`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const filterHeaderOptionsByRoles = () => {
    return options.filter(option => option.roles.some((role: string) => getRoles().includes(role)));
  };

  const handleOptionWithChildren = (action: ReactNode) => {
    setChildren(action);
  };

  const handleChangePageOptions = (value: string) => {
    const optionSelected = options.find(option => option.value === value);

    if (!optionSelected) return false;

    const { type } = optionSelected || {};

    switch (type) {
      case EnumHeaderOptionsType.DOWNLOAD:
        downloadModel({ filePath: optionSelected.template, name: optionSelected.name });
        break;
      case EnumHeaderOptionsType.UPLOAD:
        if (optionSelected?.action) {
          return handleOptionWithChildren(optionSelected.action({ closeModal: () => setChildren(null) }));
        }
        setUploadDialogOpen(true);
        break;

      default:
        return false;
    }
  };

  useEffect(() => {
    if (hasSuccess) {
      snackbar({
        variant: 'success',
        message: 'Arquivo importado com sucesso',
        autoClose: true,
        timeout: TIMEOUT_TIMER,
      });
      setUploadDialogOpen(false);
    }
    if (hasError) {
      snackbar({
        variant: 'error',
        message: 'Houve um erro ao importar o arquivo',
        autoClose: true,
        timeout: TIMEOUT_TIMER,
      });
    }
  }, [hasError, hasSuccess]);

  return (
    <div>
      <Select
        itemListStyleProps={{ maxHeight: 240 }}
        items={filterHeaderOptionsByRoles()}
        placeholder={label}
        value={undefined}
        width={240}
        onChange={({ target }) => handleChangePageOptions(target.value)}
      />
      {children}
      <FileUpload
        isOpen={uploadDialogOpen}
        loading={isLoading}
        title={dialogTitle}
        uploadFileProps={{
          accept: '.csv',
          maxFiles: MAX_FILE_TO_UPLOAD,
          multiple: false,
        }}
        onClose={() => {
          if (!isLoading) {
            setUploadDialogOpen(false);
          }
        }}
        onSubmit={data => handleRequest(uploadUrl, data)}
      />
    </div>
  );
};

export default BulkImport;
