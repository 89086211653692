import gql from "graphql-tag";

const VEHICLE_TRACKING = gql`
  query getVehicleSteps($deliveryId: Int!) {
    getVehicleSteps(deliveryId: $deliveryId) {
      orderLaasId
      orderDeliveryId
      laasDeliveryCode
      accepted
      currentStep
      steps {
        code
        eventDate
        active
      }
    }
  }
`;

export { VEHICLE_TRACKING };
