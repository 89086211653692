import React, { useEffect, useRef, MutableRefObject } from 'react';
import { QueryLazyOptions } from '@apollo/react-hooks';
import { Button, Grid, Input, Select, Flex, Heading3 } from '@ifood/pomodoro-components';
import { BFFPageableInputSmall, Exact, Maybe } from '@services/bff/GraphqlTypes';

interface EstablishmentOrdersSearchProps {
  establishmentId: string;
  fetchOrders: (
    options?:
      | QueryLazyOptions<
          Exact<{
            establishmentId: string;
            pageable?: Maybe<BFFPageableInputSmall>;
            orderId?: Maybe<string>;
            minCreatedAt?: Maybe<string>;
          }>
        >
      | undefined
  ) => void;
}

const selectItens = [
  {
    name: 'Última semana',
    value: '7',
  },
  {
    name: 'Últimos 15 dias',
    value: '15',
  },
  {
    name: 'Últimos 30 dias',
    value: '30',
  },
  {
    name: 'Últimos 2 meses',
    value: '60',
  },
];

export function EstablishmentOrdersSearch({ fetchOrders, establishmentId }: EstablishmentOrdersSearchProps) {
  const searchOrder = useRef<HTMLInputElement>();
  const searchDays = useRef<HTMLInputElement>();

  const handleFilter = () => {
    const orderId = searchOrder?.current?.value || '';
    const daysValue = Number(searchDays?.current?.value || '0');
    const today = new Date();
    const dateMinCreatedAt = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysValue);
    const [minCreatedAt] = dateMinCreatedAt.toISOString().split('T');
    fetchOrders({ variables: { orderId, establishmentId, minCreatedAt } });
  };

  useEffect(() => {
    if (establishmentId) {
      fetchOrders({ variables: { establishmentId } });
    }
  }, [establishmentId, fetchOrders]);

  return (
    <>
      <Grid gridGap={24}>
        <Heading3 m={0} variant="small">
          Pedidos
        </Heading3>
        <Grid gridGap={16} gridTemplateColumns="21% 32% 1fr" mb={32}>
          <Select
            ref={searchDays as MutableRefObject<HTMLInputElement>}
            items={selectItens}
            label="Filtrar por"
            name="Selecione um período"
            placeholder="Selecione um período"
          />
          <Input
            ref={searchOrder as MutableRefObject<HTMLInputElement>}
            label="Filtrar por"
            min={1}
            name="Busque por número"
            placeholder="Busque por número"
            type="number"
          />
          <Flex alignItems={'flex-end'} height={'100%'}>
            <Button variant="secondary" onClick={handleFilter}>
              Exibir pedidos
            </Button>
          </Flex>
        </Grid>
      </Grid>
    </>
  );
}
