import { ReactNode } from 'react';
import { Heading1, Text, Icon } from '@ifood/pomodoro-components';
import { ChevronLeft } from '@ifood/pomodoro-icons';

import * as S from './style';
import Link from '@components/link';

type Props = {
  title: string;
  subTitle: string;
  back?: string;
  actions?: ReactNode;
};

const Index = ({ back, title, subTitle, actions }: Props): JSX.Element => {
  return (
    <S.MainHeader>
      {back && (
        <S.NavigationContainer>
          <Icon component={ChevronLeft} height="20" svgProps={{ viewBox: '14 8 4 16' }} width="10" />
          <Link text="Voltar" to={back} />
        </S.NavigationContainer>
      )}
      <S.ContentWrapper>
        <S.ContentContainer>
          <Heading1 variant="large">{title}</Heading1>
          <Text variant="large">{subTitle}</Text>
        </S.ContentContainer>
        {actions && <S.ActionsContainer>{actions}</S.ActionsContainer>}
      </S.ContentWrapper>
    </S.MainHeader>
  );
};

export default Index;
