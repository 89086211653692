import { useEffect, useRef } from 'react';

import './drawer.scss';

import cn from 'classnames';

const Drawer = ({
	isOpen = 'false',
	children,
	className,
	onClose,
	position = 'right'
}) => {
	const bodyRef = useRef(document?.querySelector('body'));

	useEffect(() => {
		const onKeyPress = e => {
			if (e.key === 'Escape') onClose();
		};

		const updatePageScroll = () => {
			if (isOpen) return (bodyRef.current.style.overflow = 'hidden');
			return (bodyRef.current.style.overflow = '');
		};

		updatePageScroll();

		if (isOpen) window.addEventListener('keyup', onKeyPress);

		return () => {
			window.removeEventListener('keyup', onKeyPress);
		};
	}, [isOpen, onClose]);

	return (
		<div
			aria-hidden={isOpen ? 'false' : 'true'}
			className={cn('drawer-container', {
				open: isOpen,
				className
			})}
		>
			<div className={cn('drawer', position)} role="dialog">
				{children}
			</div>
			<div className="backdrop" onClick={onClose} />
		</div>
	);
};

export default Drawer;
