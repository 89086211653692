/* istanbul ignore file */
import styled from 'styled-components';
import { Button, Input } from '@ifood/pomodoro-components';

const Content = styled.div`
  width: 100%;
  padding: 0 0.625rem 2.5rem 13.438rem;
`;

const Form = styled.form`
  width: 100%;
`;

const ButtonSearch = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
  transition: none;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.white};
  }
`;

const InputString = styled(Input)`
  width: 100%;
`;

const InputsContent = styled.div`
  display: flex;
  align-items: center;
  width: content;
`;

export { Content, ButtonSearch, Form, InputString, InputsContent };
