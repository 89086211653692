import './betaHeader.scss';

import React from 'react';

export function BetaHeader(){
    return (
        <header className="header-beta">
        Você está utilizando a versão beta do iFood Shop{' '}
        <a href={`/beta/off`}>
          sair do beta
        </a>
      </header>
    )
}