import styled from 'styled-components';
import { Divider } from '@ifood/pomodoro-components';

const DividerSection = styled(Divider)`
  border-bottom-color: ${({ theme }) => theme.colors.grayLight};
  border-width: 0 0 2px 0;
`;

const Form = styled.form`
  width: 100%;
  padding: 0 0.625rem 2.5rem 13.438rem;
`;

export { DividerSection, Form };
