import gql from 'graphql-tag';

const GET_ALL_VOUCHERS = gql`
  query getAllVouchers(
    $pageRequest: Int,
    $pageSize: Int,
    $valueSrcInput: String,
    $voucherCode: String,
    $valid: Boolean,
    $active: Boolean
  ){
    getAllVouchers(
      page: $pageRequest,
      size: $pageSize,
      name: $valueSrcInput,
      voucherCode: $voucherCode,
      valid: $valid,
      active: $active
    ){
      totalPages,
      content {
        id
        name
        voucherCode
        active
        startDate
        endDate
      }
    }
  }
`;

const GET_VOUCHER = gql `
  query getVoucher (
    $voucherId: ID!
  ){
    getVoucher(
      voucherId: $voucherId
    ){
      active
      name
      document
      voucherCode
      startDate
      endDate
      value
      balance
      comment
      seller {
        id
        name
      }
      subsidies {
        id
        active
        percentage
        type
        seller {
          id
          name
        }
        costCenter {
          id
          name
          code
          active
        }
      }
    }
  }
`;

const CREATE_VOUCHER = gql `
  mutation createVoucher(
    $active: Boolean,
    $name: String,
    $document: String,
    $voucherCode: String,
    $startDate: String,
    $endDate: String,
    $value: Float,
    $sellerId: Int,
    $comment: String,
    $subsidies: [SubsidyInput],
  ){
    createVoucher(
      voucher: {
        active: $active,
        name: $name,
        document: $document,
        voucherCode: $voucherCode,
        startDate: $startDate,
        endDate: $endDate,
        value: $value,
        sellerId: $sellerId
        comment: $comment,
        subsidies: $subsidies
      }
    )
    {
      id
    }
}
`;

const UPDATE_VOUCHER = gql `
  mutation updateVoucher(
    $voucherId: Int
    $active: Boolean,
    $name: String,
    $document: String,
    $seller: Int,
    $voucherCode: String,
    $startDate: String,
    $endDate: String,
    $value: Float,
    $balance: Float,
    $comment: String,
    $subsidies: [SubsidyInput],
  ){
    updateVoucher(
      voucher: {
        id: $voucherId,
        active: $active,
        name: $name,
        document: $document,
        sellerId: $seller,
        voucherCode: $voucherCode,
        startDate: $startDate,
        endDate: $endDate,
        value: $value,
        balance: $balance,
        comment: $comment,
        subsidies: $subsidies
      }
    )
    {
      id
    }
}
`;

export { GET_ALL_VOUCHERS, CREATE_VOUCHER, UPDATE_VOUCHER, GET_VOUCHER }