import React, { useState } from 'react';
import { UPDATE_MINPRICE } from '../../../graphql/price_minimum.mutations';
import { useMutation } from '@apollo/react-hooks';
import { Modal, Button } from 'ifoodshop-react-ui';
import Formatter from '../../../helpers/FormatHelper';

const TableSearchPriceMinimum = ({ data, role, isSeller, refetch }) => {
  const [modal, setModal] = useState();

  const [updatePriceMinimum] = useMutation(UPDATE_MINPRICE, {
    onCompleted: (data) => handleConflict(data?.updatePriceMinimum?.error),
  });

  function handleConflict(data) {
    if (data === 'MINIMUM_ORDER_CONFLICT') {
      setModal(
        <Modal onCloseAction={() => setModal(null)}>
          Já existe um pedido mínimo habilitado para essa região.
        </Modal>,
      );
    } else {
      refetch().then(setModal(null));
    }
  }

  function handleActive(id, enabled, regionName) {
    if (data) {
      if (role) {
        setModal(
          <Modal
            title={`Deseja ${
              enabled ? 'desabilitar' : 'habilitar'
            } o pedido mínimo para a região ${regionName}?`}
          >
            <div className="modal--button">
              <Button
                onClick={() =>
                  updatePriceMinimum({
                    variables: {
                      minPriceId: parseInt(id),
                      active: enabled ? false : true,
                    },
                  })
                }
                className="button"
              >
                Confirmar
              </Button>
              <Button
                onClick={() => setModal(null)}
                className="button btn--white"
              >
                Cancelar
              </Button>
            </div>
          </Modal>,
        );
      }
    }
  }

  return (
    <>
      {data?.length > 0 ? (
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">ID</th>
              {!isSeller && <th className="table-admin__title">Fornecedor</th>}
              <th className="table-admin__title">Região</th>
              <th className="table-admin__title">Pedido mínimo</th>
              <th className="table-admin__title">Data criação</th>
              <th className="table-admin__title">Data alteração</th>
              <th className="table-admin__title">Estado</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr key={item.id}>
                  <td className="table-admin__data">{item.id}</td>
                  {!isSeller && (
                    <td className="table-admin__data">{item.seller.name}</td>
                  )}
                  <td className="table-admin__data">{item.region.name}</td>
                  <td className="table-admin__data">
                    {item.minimumOrder.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td className="table-admin__data">
                    {Formatter.format(item?.createdAt, 'simple-date')}
                  </td>
                  <td className="table-admin__data">
                    {Formatter.format(item?.updatedAt, 'simple-date')}
                  </td>
                  <td className="table-admin__data">
                    {item?.active ? 'Habilitado' : 'Desabilitado'}
                  </td>
                  <td className="table-admin__data">
                    <a
                      onClick={() =>
                        handleActive(item.id, item.active, item.region.name)
                      }
                      className={role ? 'link' : 'link--disabled'}
                    >
                      {item?.active ? 'Desabilitar' : 'Habilitar'}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        'Nenhum resultado encontrado.'
      )}
      {modal}
    </>
  );
};

export default TableSearchPriceMinimum;
