import { useEffect, useState, useRef, useMemo } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Button, Modal, Link } from 'ifoodshop-react-ui';
import { Tooltip } from '@ifood/pomodoro-components';

import { useAboyeur } from '@helpers/useAboyeur';
import { SessionOrderDetail } from '@analytics/aboyeur/entities/sessionOrderDetail';
import FormatHelper from '@helpers/FormatHelper';

import { useOrderContext } from '@components/_context/OrderContext';
import HasHistory from '../HasHistory';
import Rupture from '../Rupture';

import { ReactComponent as PenLineIcon } from '../../../../assets/icons/pen-line.svg';
import './deliveryItemTable.scss';

function DeliveryItemTable({ items, step, basePrice, rupturePaymentProcessed }) {
  const [modal, setModal] = useState(null);
  const [stepModal, setStepModal] = useState('');
  const { order, refetch, setOrder, isSeller, original, setOriginal } = useOrderContext();
  const [hasHistory, setHasHistory] = useState(false);

  const sessionOrderDetail = useAboyeur(SessionOrderDetail);
  const itemDiscount = (items || [])[0]?.discounts.length > 0;

  const refTable = useRef(null);

  const isNewIntegratedSeller = useMemo(() => order?.orderSellers[0].seller?.isNewIntegration,[order])

  const enumbasePrice = {
    MEI_PRICE: 'MEI',
    FIXED_PRICE: 'Preço Fixo',
    PROGRESSIVE_PRICE: 'Preço Progressivo',
    OFFER_PRICE: 'Preço Oferta',
  };
  const stepsEnableRuptureSeller = ['PAYMENT_ACCEPTED', 'IN_STOCK_SEPARATION'];
  const stepsNotAcceptableRuptureAdmin = ['WAITING_PAYMENT', 'CANCELLED'];

  function basePriceStatus(item) {
    if (item?.basePrice?.type === 'MEI_PRICE') {
      return enumbasePrice.MEI_PRICE;
    } else if (item?.basePrice?.type === 'FIXED_PRICE') {
      return enumbasePrice.FIXED_PRICE;
    } else if (item?.basePrice?.type === 'PROGRESSIVE_PRICE') {
      return enumbasePrice.PROGRESSIVE_PRICE;
    } else if (item?.basePrice?.type === 'OFFER_PRICE') {
      return enumbasePrice.OFFER_PRICE;
    } else {
      return '';
    }
  }

  function handleTotalValue({ sellerSellPrice, sellPriceWithoutDiscount, adminSellPrice }, withoutDiscount) {
    if (isSeller && !withoutDiscount) return sellerSellPrice;
    else if (withoutDiscount) return sellPriceWithoutDiscount;
    else return adminSellPrice;
  }

  function handleUnitPrice({ sellerUnitPrice, unitPrice }) {
    if (isSeller) return sellerUnitPrice;
    else return unitPrice;
  }

  function calculateUnitOrder() {
    const qtdProductActive = items.reduce((prevVal, item) => prevVal + item.quantity, 0);
    if (qtdProductActive > 1) {
      return items.reduce((prevVal, item) => prevVal + item.quantity, 0);
    } else {
      return items.length;
    }
  }


  useEffect(()=> {
    stepModal === 'CONFIRM' && modal === null && 
      window.location.reload();
  },[stepModal, modal])

  const openModal = (e, item, type) => {
    if (item) {
      e.preventDefault();
      setModal(
        <Modal
          onCloseAction={() => {
            setModal(null);
          }}
        > 
          <Rupture
            setModal={() => setModal(null)}
            modal={modal}
            item={item}
            items={items}
            qtdProduct={item}
            qtdProductsOrder={calculateUnitOrder()}
            type={type}
            setStepModal={setStepModal}
          />

        </Modal>
      );
      if (type === 'QUANTITY') {
        sessionOrderDetail.events.order_detail.clickQuantityEdit({
          childOrderId: item.deliveryId,
          itemId: item.id,
          itemQty: item.quantity,
          totalItemValue: item.sellPrice,
        });
      }
    }
  };

  const showRuptureStock = item =>
    rupturePaymentProcessed
      ? !stepsNotAcceptableRuptureAdmin.includes(step) && item.quantity > 0 && !original
      : order?.betaFeatures &&
        !order?.paymentProcessed &&
        !original &&
        item.hasHistory !== undefined &&
        item.quantity > 0 &&
        stepsEnableRuptureSeller.includes(step);

  useEffect(() => {
    items.forEach(data => {
      if (data.hasHistory) {
        setHasHistory(true);
      }
    });
  }, [items, order, basePrice]);

  useEffect(() => {
    if (isSeller) {
      const urlParams = new URLSearchParams(window.location.search);
      const urlLink = urlParams.get('link');
      if (urlLink?.length > 0) {
        refTable.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [order, isSeller]);

  function Status(item) {
    const orderItemStatus = item?.history && item?.history[item?.history?.length - 1]?.orderItemStatus;
    if (item.hasHistory && orderItemStatus === 'REMOVED') {
      return 'table-admin__data table-admin__data--disabled';
    }
    return 'table-admin__data';
  }

  function status(item) {
    const orderItemStatus = item?.history && item?.history[item?.history?.length - 1]?.orderItemStatus;
    if (item.hasHistory && (orderItemStatus === 'QUANTITY_UPDATED' || orderItemStatus === 'WEIGHT_UPDATE')) {
      return 'Alterado';
    } else if (item.hasHistory && orderItemStatus === 'REMOVED') {
      return 'Removido';
    } else {
      return '';
    }
  }

  return (
    <>
      {modal}
      <div className="delivery-item-table" ref={refTable}>
        <table className="table-admin table-admin--sample">
          <thead>
            <tr>
              <th className="table-admin__title">SKU</th>
              <th className="table-admin__title">SKU Lojista</th>
              <th className="table-admin__title">Produto</th>
              <th className="table-admin__title">Qtd.</th>
              <th className="table-admin__title">Valor unitário</th>
              {!isSeller && <th className="table-admin__title">Desconto</th>}
              <th className="table-admin__title">Total</th>
              {!isSeller && itemDiscount && <th className="table-admin__title">Total s/ desconto</th>}
              <th className="table-admin__title">Tipo de Preço</th>
              {hasHistory && <th className="table-admin__title">Status</th>}
              <th className="table-admin__title"></th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => {
              return (
                <tr id={item.id} key={item.id}>
                  <td className={Status(item)}>{item.variation?.referenceCode}</td>
                  <td className={Status(item)}>{item.sellerCode}</td>
                  <td className={Status(item)}>{item.name}</td>
                  <td className={Status(item)}>
                    <div className="rupture-table__edit">
                      {item.quantity}
                      {showRuptureStock(item) && !isNewIntegratedSeller && (
                        <Tooltip content="Editar quantidade" placement="top">
                          <button className="btn-rupture" onClick={e => openModal(e, item , 'QUANTITY')}>
                            <PenLineIcon className="icon-pen-line" />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </td>
                  <td className={Status(item)}>{FormatHelper.format(handleUnitPrice(item), 'currency')}</td>
                  {!isSeller && (
                    <td className={Status(item)}>
                      {item?.discounts.length > 0 ? <ItemDiscounts discounts={item.discounts} /> : <span>R$ 0,00</span>}
                    </td>
                  )}
                  <td className={Status(item)}>{FormatHelper.format(handleTotalValue(item), 'currency')}</td>
                  {!isSeller && item?.discounts.length > 0 && (
                    <td className={Status(item)}>{FormatHelper.format(handleTotalValue(item, true), 'currency')}</td>
                  )}
                  <td className={Status(item)}>{basePriceStatus(item)}</td>
                  {hasHistory && <td className="table-admin__data">{status(item)}</td>}
                  <td className="table-admin__data">
                    {showRuptureStock(item) && item.variableWeight && (
                      <Tooltip content="Editar peso" placement="top">
                         <div className="item-variable-weight-text">
                            <Link onClick={e => openModal(e, item, 'WEIGHT')}>Editar peso</Link>
                         </div>
                      </Tooltip>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <HasHistory setHistory={setHasHistory} items={items} />

        {original && (
          <div className="btn-originalOrder">
            <Button
              onClick={() => {
                setOriginal(false);
                refetch().then(response => {
                  setOrder(response.data.getOrder);
                  window.scrollTo(0, 0);
                });
              }}
              className="button btn-secondary"
            >
              {' '}
              Voltar ao pedido atual{' '}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

function ItemDiscounts({ discounts }) {
  const [expanded, setExpanded] = useState(false);
  const [title, setTitle] = useState('Show More');
  const [modal, setModal] = useState();

  function handleClassName(index) {
    return index ? 'delivery-item-table__discount display-none' : 'delivery-item-table__discount ';
  }

  function showDiscounts(discounts) {
    discounts.map((discount, index) => {
      if (index > 0) {
        const element = document.getElementById(`${discount.sku}-${discount.discountId}-${index}`);
        if (element.classList.contains('display-none')) {
          element.classList.remove('display-none');
          setExpanded(true);
          setTitle('Hide Details');
        } else {
          element.classList.add('display-none');
          setExpanded(false);
          setTitle('Show More');
        }
      }
    });
  }

  function seeDiscounts(e) {
    e.preventDefault();

    setModal(
      <Modal onCloseAction={() => setModal(null)} className="order-detail__modal-discount">
        <h3>Desconto:</h3>
        {discounts.map((discount, index) => {
          let typeDiscount;
          if (discount.discountType === 'PERCENTUAL_ON_PRODUCT') {
            typeDiscount = '% produto';
          } else if (discount.discountType === 'PERCENTUAL_ON_FREIGHT') {
            typeDiscount = '% frete';
          } else {
            typeDiscount = '$ absoluto';
          }

          return (
            <>
              {discount.amount > 0 ? (
                <div
                  className="order-detail__card-list"
                  key={`${discount.orderSellerId}-${index}`}
                  id={`${discount.orderSellerId}-${index}`}
                >
                  <p className="order-detail__card-item">
                    Nome do desconto:
                    <span className="order-detail__delivery-value">{discount.name}</span>
                  </p>
                  <p className="order-detail__card-item">
                    ID desconto:
                    <span className="order-detail__delivery-value">{discount.discountId}</span>
                  </p>
                  <p className="order-detail__card-item">
                    Tipo do desconto:
                    <span className="order-detail__delivery-value">{typeDiscount}</span>
                  </p>
                  <p className="order-detail__card-item">
                    Total:
                    <span className="order-detail__delivery-value discount-colored">
                      {discount.amount > 0 ? FormatHelper.format(discount.amount, 'currency') : 'R$ 0,00'}
                    </span>
                  </p>
                </div>
              ) : (
                ''
              )}
            </>
          );
        })}
      </Modal>
    );
  }
  return (
    <>
      <div className="display-flex">
        <div>
          {discounts.map((discount, index) => {
            return (
              <div
                key={`${discount.discountId}-${index}`}
                id={`${discount.sku}-${discount.discountId}-${index}`}
                className={handleClassName(index)}
              >
                {discount?.amount && discounts ? (
                  <a onClick={e => seeDiscounts(e)} className="discountLink">
                    {FormatHelper.format(discount.amount, 'currency')}
                  </a>
                ) : (
                  <span>R$ 0,00</span>
                )}
              </div>
            );
          })}
        </div>
        {discounts.length > 1 && (
          <Tooltip content={title} placement="top">
            {expanded ? (
              <ArrowDropUpIcon onClick={() => showDiscounts(discounts)}></ArrowDropUpIcon>
            ) : (
              <ArrowDropDownIcon onClick={() => showDiscounts(discounts)}></ArrowDropDownIcon>
            )}
          </Tooltip>
        )}
      </div>
      {modal}
    </>
  );
}

export default DeliveryItemTable;
