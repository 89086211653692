import React, { useState } from 'react';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';
import DatePicker from 'react-datepicker';
import ptbr from 'date-fns/locale/pt-BR';
import './formSearchCommercialCondition.scss';

const FormSearchCommercialCondition = ({ onChangeFilter }) => {
  const [filters, setFilters] = useState({
    idOrName: null,
    creationDate: null,
    isEnabled: true,
  });

  const optionsActive = [
    { value: true, label: 'Habilitado' },
    { value: false, label: 'Desabilitado' },
    { value: null, label: 'Todos' },
  ];

  function handleSubmit(e) {
    e.preventDefault();
    onChangeFilter(filters);
  }

  return (
    <>
      <div className="header-customer-group header-customer-group--src">
        <form
          className="header-customer-group__form form-commercial"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="header-customer-group__form-input">
            <OutlinedInput
              name="name"
              placeholder="Nome ou ID"
              border
              value={filters.idOrName || ''}
              onChange={(e) =>
                setFilters({ ...filters, idOrName: e.target.value })
              }
            />
            <div
              className={`outlined-label-wrap outlined-date ${
                filters.creationDate ? 'active' : ''
              }`}
            >
              <DatePicker
                className="form-brand__input"
                onChange={(date) =>
                  setFilters({ ...filters, creationDate: date })
                }
                locale={ptbr}
                selected={filters.creationDate}
                dateFormat="dd/MM/yyyy"
              />
              <label className="outlined-label">Data</label>
            </div>

            <div className="dropdown-label-wrap">
              <Dropdown
                options={optionsActive}
                value={
                  optionsActive.find((o) => o.value === filters.isEnabled) ||
                  optionsActive[0]
                }
                onChange={(e) => setFilters({ ...filters, isEnabled: e.value })}
                placeholder="Estado"
                className="dropdown-ifood"
                controlClassName="dropdown-ifood__control"
                placeholderClassName="dropdown-ifood__placeholder"
                menuClassName="dropdown-ifood__menu"
                arrowClassName="dropdown-ifood__arrow"
              />
              <label className="dropdown-label">Estado</label>
            </div>
          </div>
          <div className="header-customer-group__actions">
            <Button type="submit">Filtrar condição comercial</Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormSearchCommercialCondition;
