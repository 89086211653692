/* eslint-disable prettier/prettier */
import { Card, Flex, Heading3, Text } from '@ifood/pomodoro-components';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSellerDetailsData } from '../../SellerDetailsHook';

const AddressDataDetails = (): JSX.Element => {
  const { sellerResponse } = useSellerDetailsData();
  const theme = useContext(ThemeContext);

  return (
    <Card>
      <Flex flexDirection="column">
        <Heading3 mb={24} mt={4} variant="smallest">
          Acessos
        </Heading3>
        <Text color={theme.colors.grayDarkest} data-testid="login-email-label" variant="regular">
          Email
        </Text>
        <Text data-testid="login-email" mb={24} variant="large">
          {sellerResponse?.getSellerV2?.email}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Senha do ADM associado
        </Text>
        <Text mb={24} variant="large">
          **************
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Integrado
        </Text>
        <Text data-testid="integrated" mb={24} variant="large">
          {sellerResponse?.getSellerV2?.apiIntegration ? 'Sim' : 'Não'}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Nova Integração
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.isNewIntegration ? 'Sim' : 'Não'}
        </Text>
        <Text color={theme.colors.grayDarkest} variant="regular">
          Loja Teste
        </Text>
        <Text mb={24} variant="large">
          {sellerResponse?.getSellerV2?.isTestMerchant ? 'Sim' : 'Não'}
        </Text>
        {sellerResponse?.getSellerV2?.merchantId && (
          <>
            <Text color={theme.colors.grayDarkest} variant="regular">
              Merchant ID
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.merchantId}
            </Text>
          </>
        )}
        {sellerResponse?.getSellerV2?.frnId && (
          <>
            <Text color={theme.colors.grayDarkest} variant="regular">
              Fornecedor ID
            </Text>
            <Text mb={24} variant="large">
              {sellerResponse?.getSellerV2?.frnId}
            </Text>
          </>
        )}
      </Flex>
    </Card>
  );
};

export default AddressDataDetails;
