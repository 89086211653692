import React from 'react';

function TableWeightRange({dataWeightRange, setDataWeightRange}) {

  function excludeWeight(key) {
    setDataWeightRange(dataWeightRange.filter(item => {
      if(item.id) return item.id !== key

      return item.key !== key
    }))
  }
  
  return (
    <table className="table-admin table-admin--sample">
        <thead>
          <tr>
            <th className="table-admin__title">Peso Inicial (Kg)</th>
            <th className="table-admin__title">Peso Final (Kg)</th>
            <th className="table-admin__title">Valor</th>
            <th className="table-admin__title"></th>
          </tr>
        </thead>
        <tbody>
          {dataWeightRange.length
            ? dataWeightRange.map(item => (
                <tr key={item.id || item.key}>
                  <td className="table-admin__data">{item.initialWeight}</td>
                  <td className="table-admin__data">{item.finalWeight}</td>
                  <td className="table-admin__data">{item.value}</td>
                  <td className="table-admin__data"><span onClick={() => excludeWeight(item.id || item.key)} className="link">Excluir</span></td>
                </tr>
              ))
            
            : <tr>
                <td className="table-admin__data table-admin__data--empty" colSpan="4">Nenhuma faixa de peso cadastrada</td>
              </tr>
          }
        </tbody>
      </table>
  );
}

export default TableWeightRange;