import styled, { css } from 'styled-components';
import { Table } from '@ifood/pomodoro-components';

type THeader = {
  hasBorder?: boolean;
};

type TRow = {
  isHeader?: boolean;
};

export const DiscountsTable = styled(Table)`
  font-family: ${({ theme }) => theme.fonts.primary};

  &.link {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Row = styled(Table.Row)<TRow>`
  height: 40px;

  border: none;

  ${({ isHeader }) =>
    !isHeader &&
    css`
      &:nth-child(odd) {
        background-color: ${({ theme }) => theme.colors.grayLight};
      }
    `}

  &:hover {
    > td {
      background-color: inherit !important;
    }
  }
`;

export const Header = styled(Table.Header)<THeader>`
  text-align: left;

  ${({ hasBorder }) => {
    if (hasBorder)
      return css`
        border-right: 1px solid ${({ theme }) => theme.colors.gray};
      `;
  }}
`;

export const Data = styled(Table.Data)`
  padding: 8px 16px;

  border-right: 1px solid ${({ theme }) => theme.colors.gray};
  border-bottom: 0px;

  text-align: left;

  &:last-child {
    border-right: 0;
  }
`;
