import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_BANKS } from '@graphql/bank_account.queries';

export function useBankTypeOptions() {
  const { data: dataBankTypes } = useQuery(GET_ALL_BANKS);
  const bankTypeOptions = dataBankTypes?.getAllBanks?.map((item: { id: number; code: string; name: string }) => ({
    id: item.id,
    code: item.code,
    name: `${item.code} - ${item.name}`,
    value: item.name,
  }));

  return {
    bankTypeOptions,
  };
}
