import React  from "react";
import Routes from "../../routes";
import { useKeycloakContext } from "../../components/_context/KeycloakContext";
import "./secured.scss";

const Secured = () => {
  const { keycloak } = useKeycloakContext();

  return (
    <div className="div-wrapper">
      {keycloak?.authenticated ? (
        <Routes keycloak={keycloak} />
      ) : (
        <div>Connecting</div>
      )}
    </div>
  );

};

export default Secured;