import styled from 'styled-components';

interface StatusProps {
  active?: boolean;
}

export const Status = styled.div<StatusProps>`
  height: 18px;
  padding: 0px 8px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  color: ${props => (props.active ? '#50A773' : '#A6A6A5')};
  border: 1px solid ${props => (props.active ? '#50A773' : '#A6A6A5')};

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 1px 0 0;
  }
`;
