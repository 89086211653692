import React, { useState } from 'react';
import { Button, OutlinedInput } from 'ifoodshop-react-ui';
import Dropdown from 'react-dropdown';

const CustomerGroupFilters = ({ onSubmitFilters }) => {
    const [ filters, setFilters ] = useState({
        groupName: '',
        state: true
    });
    const optionsActive = [
        { value: true, label: 'Habilitado' },
        { value: false, label: 'Desabilitado' },
        { value: null, label: 'Todos' }
    ];
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmitFilters(filters);
    }

    return (
        <form className="header-customer-group__form" onSubmit={e => handleSubmit(e)}>
            <div className="header-customer-group__form-input">
                <OutlinedInput
                name="name"
                placeholder="Grupo de clientes"
                border
                onChange={e => setFilters({
                    ...filters, 
                    groupName: e.target.value
                })} />

                <div className="dropdown-label-wrap">
                <Dropdown
                    options={optionsActive}
                    value={optionsActive.find(
                    o => o.value === filters.state) || optionsActive[0]
                    }
                    onChange={e => setFilters({
                        ...filters, 
                        state: e.value
                    })}
                    placeholder="Estado"
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    placeholderClassName='dropdown-ifood__placeholder'
                    menuClassName="dropdown-ifood__menu"
                    arrowClassName='dropdown-ifood__arrow'
                />
                <label className="dropdown-label">Estado</label>
                </div>
            </div>
            <div className="header-customer-group__actions">
                <Button type="submit">Filtrar grupos de clientes</Button>
            </div>
        </form>
    )
}

export default CustomerGroupFilters;