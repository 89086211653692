import { Aboyeur } from '@ifood/aboyeur';

export const SessionCustomer = new Aboyeur('sessao_customer', {
  customer: {
    /**
     * @when Sessao customer is rendered
     */
    viewAdminCustomer: () => ({
      label: 'sessao_customer',
      metadata: {},
    }),
    /**
     * @when Clicou no nome do cliente para acessar a página de detalhes do cliente
     */
    clickCustomerDetails: () => ({
      label: 'click_customer_details',
      metadata: {},
    }),
  },
  customerDetails: {
    /**
     * @when Clicou em editar dados na página de detalhes do cliente
     */
    clickEditCustomerDetails: () => ({
      label: 'click_edit_customer_details',
      metadata: {},
    }),
    /**
     * @when Clicou em relatar fraude e enviou o motivo da fraude na página de detalhes do cliente
     */
    clickReportFraud: obj => ({
      label: 'click_report_fraud',
      metadata: obj,
    }),
    /**
     * @when Clicou em remover fraude e enviou o motivo de remoção na página de detalhes do cliente
     */
    clickRemoveFraud: obj => ({
      label: 'click_remove_fraud',
      metadata: obj,
    }),
    /**
     * @when Clicou em listagem de estabelecimentos na página de detalhes do cliente
     */
    clickEstablishmentList: () => ({
      label: 'click_establishment_list',
      metadata: {},
    }),
    /**
     * @when Clicou em ver pedidos no modal de listagem de estabelecimentos
     */
    clickEstablishmentOrders: () => ({
      label: 'click_establishment_orders',
      metadata: {},
    }),
  },
}).withMetadata({ revision: 0 });
