import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_ORDERS } from '../../../graphql/order.queries';
import Dropdown from 'react-dropdown';
import ReactPaginate from 'react-paginate';
import { Typography } from 'ifoodshop-react-ui';
import moment from 'moment';

import { getRoles, getSellerId } from '@helpers/rolesUtils';
import Roles from '@helpers/Roles';
import { useAboyeur } from '@helpers/useAboyeur';
import { hotjar } from '@helpers/Hotjar';

import { SessionOrders } from '@analytics/aboyeur/entities/sessionOrders';
import { OrderProvider } from '@components/_context/OrderContext';
import Loading from '@components/Loading';
import FiltersOrderSearch from '../components/FilterOrderSearch';
import HeaderSearchOrder from '../components/HeaderSearchOrder';
import TableOrderSearch from '../components/TableOrderSearch';
import IconArrow from '../../../assets/images/arrow.png';

const OrderSearch = () => {
  const roles = getRoles();
  const history = useHistory();
  const sessionOrders = useAboyeur(SessionOrders);
  const roleSeller = roles.includes(Roles.SELLER_WR);
  const roleOrderWR = roles.includes(Roles.ORDER_WR);
  const roleOrderRO = roles.includes(Roles.ORDER_RO);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const statusRupture = ['IN_STOCK_SEPARATION', 'PAYMENT_ACCEPTED'];

  useEffect(() => {
    sessionOrders.events.orders.viewAdminOrders();
  }, [sessionOrders]);

  const toBool = string => (string === 'isLater' ? true : false);

  const id_seller = parseInt(getSellerId());
  const [modal, setModal] = useState(null);
  const [variables, setVariables] = useState({
    pageRequest: parseInt(params.get('pageRequest')) || 0,
    pageSize: parseInt(params.get('pageSize')) || 10,
    sellerId: roleSeller && id_seller ? id_seller : null,
    orderNumber: parseInt(params.get('orderNumber')) || null,
    orderClient: params.get('orderClient') || null,
    orderStatus: params.get('orderStatus') || null,
    paymentStatus: params.get('paymentStatus') || null,
    invoiceNumber: params.get('invoiceNumber') || null,
    isLater: toBool(params.get('isLater')) || null,
    startDate: params.get('startDate') || null,
    endDate: params.get('endDate') || null,
    orderDeliveryCode: params.get('orderDeliveryCode') || null,
    orderSellerUuid: params.get('orderSellerUuid') || null,
  });

  useEffect(() => {
    hotjar.identify(id_seller, '');
  }, []);

  const {
    data,
    loading,
    refetch: refetchAllOrders,
  } = useQuery(GET_ALL_ORDERS, {
    variables,
  });

  if (!roleOrderRO && !roleOrderWR) {
    history.push('');
  }

  const getDate = date => {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  };

  const orderProps = {
    refetchAllOrders,
    modal,
    setModal,
  };

  const onSubmitFilters = e => {
    setVariables({
      ...variables,
      pageRequest: e.pageRequest || 0,
      pageSize: e.product ? 300 : e.pageSize || 10,
      orderNumber: e.orderNumber,
      orderClient: e.orderClient,
      orderStatus: e.orderStatus,
      orderStatusList: e.product ? statusRupture : null,
      orderDeliveryCode: e.orderDeliveryCode,
      paymentStatus: e.paymentStatus,
      sellerId: e.sellerId,
      invoiceNumber: e.invoiceNumber,
      isLater: e.isLater,
      startDate: getDate(e.startDate),
      endDate: getDate(e.endDate),
      product: e.product,
      orderSellerUuid: e.orderSellerUuid,
    });

    const filledParams = Object.entries(e).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
    if (filledParams.sellerId) delete filledParams.sellerId;

    history.push({
      pathname: '/pedidos',
      search: new URLSearchParams(filledParams).toString(),
    });
  };

  const pageSizeOpts = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  return (
    <>
      {(roleOrderRO || roleOrderWR) && (
        <OrderProvider {...orderProps}>
          <a href="/" className="go-back"></a>
          <div className="content-body">
            <HeaderSearchOrder />
            <FiltersOrderSearch
              isSeller={roleSeller}
              idSeller={id_seller}
              variables={variables}
              sessionOrders={sessionOrders}
              setVariables={setVariables}
              onSubmitFilters={onSubmitFilters}
            />

            {data?.getAllOrders?.content?.length > 0 ? (
              <>
                <TableOrderSearch
                  rolesOrder={{ roleOrderWR, roleOrderRO }}
                  orders={data.getAllOrders.content}
                  isSeller={roleSeller}
                  idSeller={id_seller}
                  variables={variables}
                  setVariables={setVariables}
                  renderTitle
                />

                <div className="search-customer-paginate">
                  <Dropdown
                    options={pageSizeOpts}
                    value={pageSizeOpts.find(o => o.value === variables.pageSize) || pageSizeOpts[0]}
                    onChange={e =>
                      onSubmitFilters({
                        ...variables,
                        pageSize: e.value,
                      })
                    }
                    placeholder="Status"
                    className="dropdown-ifood"
                    controlClassName="dropdown-ifood__control"
                    placeholderClassName="dropdown-ifood__placeholder"
                    menuClassName="dropdown-ifood__menu"
                    arrowClassName="dropdown-ifood__arrow"
                  />
                  {data?.getAllOrders?.totalPages > 0 && (
                    <ReactPaginate
                      containerClassName="pagination-ifoodshop"
                      pageClassName="pagination-ifoodshop__item"
                      pageLinkClassName="pagination-ifoodshop__item-link"
                      activeLinkClassName="active"
                      previousLabel={<img className="previous-arrow" src={IconArrow} />}
                      nextLabel={<img className="next-arrow" src={IconArrow} />}
                      onPageChange={e => {
                        onSubmitFilters({
                          ...variables,
                          pageRequest: e.selected,
                        });
                      }}
                      pageRangeDisplayed={3}
                      pageCount={data.getAllOrders.totalPages}
                      marginPagesDisplayed={1}
                      initialPage={variables.page}
                      forcePage={data.getAllOrders.number}
                    />
                  )}
                </div>
              </>
            ) : (
              <Typography>Nenhum resultado encontrado</Typography>
            )}
          </div>
        </OrderProvider>
      )}
      {loading && <Loading />}
    </>
  );
};

export default OrderSearch;
