import * as yup from 'yup';

// SCHEMA
const schema = yup.object().shape({
  name: yup.string(),
  sellerId: yup.number(),
  active: yup.object(),
});

export default schema;
