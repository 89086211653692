import gql from 'graphql-tag';

const GET_ALL_REGIONS = gql`
  query getAllRegions(
    $page: Int
    $pageSize: Int
    $name: String
    $active: Boolean
    $ownerId: Int
    $ownerType: String
  ) {
    getAllRegions(
      page: $page
      size: $pageSize
      name: $name
      active: $active
      ownerId: $ownerId
      ownerType: $ownerType
    ) {
      content {
        id
        name
        active
        createdAt
        ownerType
        seller {
          name
        }
      }
      totalPages
    }
  }
`;

const GET_REGION_BY_ID = gql`
  query getRegion($regionId: ID!) {
    getRegion(regionId: $regionId) {
      id
      name
      description
      active
      createdAt
      ownerType
      postalCodes {
        initialPostalCode
        finalPostalCode
      }
      seller {
        name
        id
      }
    }
  }
`;

export { GET_ALL_REGIONS, GET_REGION_BY_ID };
