import { useQuery } from '@apollo/react-hooks';
import { GET_SELLER_TYPES } from '@graphql/seller.queries';

export function useSellerTypeOptions() {
  const { data: dataSellerTypes } = useQuery(GET_SELLER_TYPES);
  const sellerTypeOptions = dataSellerTypes?.getSellerTypes?.map((item: { name: string; id: number }) => ({
    name: item.name,
    value: item.name,
    id: item.id,
  }));

  return {
    sellerTypeOptions,
  };
}
