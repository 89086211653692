const HeaderEditCategory = props => {
  return (
    <div className="header-category">
      <div className="header-category__info">
        <h2 className="section-title">{props.title}</h2>
        <p className="header-category__description">{props.description}</p>
      </div>
    </div>
  );
};

export default HeaderEditCategory;
