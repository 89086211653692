import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_ORIGINAL_ORDER } from '../../../../graphql/order.queries';
import { Button, Modal } from 'ifoodshop-react-ui';

import { useOrderContext } from '@components/_context/OrderContext';
import UpdatesHistoricModal from '../UpdatesHistoricModal';

import './HasHistory.scss';

function HasHistory({ items, setHistory }) {
  const [hasHistory, setHasHistory] = useState(false);
  const [modal, setModal] = useState(null);
  const { setOrder, order, setOriginal } = useOrderContext();
  const [getOriginalOrder, { data }] = useLazyQuery(GET_ORIGINAL_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { orderId: order.id },
    onCompleted: () => {
      if (data.getOriginalOrder.error) {
        setModal(
          <Modal
            onCloseAction={() => {
              setModal(null);
            }}
            title="Atenção"
          >
            <p> Erro ao buscar pedido original </p>
          </Modal>
        );
      } else {
        setOrder(data.getOriginalOrder);
        setHistory(false);
        setHasHistory(false);
        setOriginal(true);
        window.scrollTo(0, 0);
      }
    },
  });

  useEffect(() => {
    items.forEach(data => {
      if (data.hasHistory === true) {
        setHasHistory(true);
      }
    });
  }, [items]);

  function openModal() {
    setModal(
      <Modal
        onCloseAction={() => {
          setModal(null);
        }}
      >
        <UpdatesHistoricModal items={items} />
      </Modal>
    );
  }
  return (
    hasHistory && (
      <div className="hasHistory">
        {modal}
        <Button
          onClick={() => {
            getOriginalOrder();
          }}
          className="button btn-secondary"
        >
          Ver pedido original
        </Button>
        <Button
          onClick={() => {
            openModal();
          }}
        >
          Ver histórico de alterações
        </Button>
      </div>
    )
  );
}

export default HasHistory;
