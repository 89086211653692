import styled from 'styled-components';
import { EmptyState } from '@ifood/pomodoro-components';

export const ResponseFallback = styled(EmptyState)`
  img {
    width: 300px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 54px;

  &.table-container {
    flex-grow: 1;
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 24px;
`;

export const Controllers = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.large};
  align-items: center;
`;
