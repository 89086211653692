import ImgTableEditQuantity from '../../../../assets/images/order/ModalFlowAccept/exemple-table-edit-quantity.svg';
import * as S from './ModalFlowAccept.styles';

const ModalFlowAcceptstep3 = () => {
  return (
    <>
      <S.Heading as="h3">
        Quando não tiver <strong>estoque do item suficiente</strong> para a entrega,
        <strong> basta atualizar a quantidade </strong>
        clicando no lápis!
      </S.Heading>
      <S.FigureBox>
        <S.Image alt="Exemplo de tabela de editar quantidade" src={ImgTableEditQuantity} width="100%" />
      </S.FigureBox>
    </>
  );
};

export default ModalFlowAcceptstep3;
