import { ReactNode } from 'react';
import { Text, TextVariants } from '@ifood/pomodoro-components';
import * as S from './style';

type Props = {
  to: string;
  variant?: TextVariants;
  text?: string;
  children?: ReactNode;
};

const Link = ({ to, variant = 'medium', text = '', children }: Props): JSX.Element => {
  return <S.Link to={to}>{children ? children : <Text variant={variant}>{text}</Text>}</S.Link>;
};

export default Link;
