import React, { useState } from 'react';
import { Button, OutlinedInput, Modal } from 'ifoodshop-react-ui';
import { ReactComponent as ChevronIcon } from '@assets/icons/Chevron.svg';
import Loading from '@components/Loading';
import ModalSaveEdit from '../ModalSaveEdit';
import AlertModal from '@components/AlertModal';
import ShowOrHidePassword from '../ShowOrHidePassword';
import {
  UPDATE_PASSWORD,
  UPDATE_DATA_SELLER
} from '@graphql/seller.mutations';
import { useMutation } from '@apollo/react-hooks';
import './editSeller.scss';

const EditSeller = ({ typeEdit, setOpenEdit, data, refetch }) => {

  const [modal, setModal] = useState(null);

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const [variables, setVariables] = useState({
    id: data?.user?.id || '',
    name: data?.name || '',
    residentialPhone: data?.user?.residentialPhone || '',
    mobilePhone: data?.user?.mobilePhone || '',
    email: data?.email || '',
    currentPassword: '',
    newPass: '',
    confirmNewpass: '',
    mcc: data?.mcc || '',
    cnae: data?.cnae || '',
  });

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: (data) => handleError(data?.updatePassword?.error),
  });

  const [updateDataSeller, { loading: loadingPass }] = useMutation(
    UPDATE_DATA_SELLER,
    {
      onCompleted: (data) => handleError(data?.updateDataSeller?.error),
    },
  );

  function handleError(data) {
    if (data === 'SELLER_UNAUTHORIZED') {
      setModal(
        <AlertModal
          title="Atenção!"
          description="Senha atual incorreta."
          titleButton="Ok, entendi!"
          setModal={setModal}
        />,
      );
    } else {
      refetch();
      setModal(
        <AlertModal
          title="Beleza!"
          description="Alteração realizada com sucesso."
          titleButton="Ok, entendi!"
          setModal={setModal}
          actionButton={() => setOpenEdit(false)}
        />,
      );
    }
  }

  function handleConfirm(sendItens, pass) {
    sendItens.user.password = btoa(pass);
    if (!sendItens.user.password) {
      setModal(
        <AlertModal
          title="Atenção!"
          description="A senha é um campo obrigatório."
          titleButton="Ok, entendi!"
          setModal={setModal}
        />,
      );
    } else {
      updateDataSeller({
        variables: sendItens,
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    let sendItems;

    if (typeEdit === 'mainData') {
      if (!variables.name) {
        setModal(
          <AlertModal
            title="Atenção!"
            description="É obrigatório inserir um nome."
            titleButton="Ok, entendi!"
            setModal={setModal}
          />,
        );
      } else if (!variables.residentialPhone && !variables.mobilePhone) {
        setModal(
          <AlertModal
            title="Atenção!"
            description="É necessário adicionar pelo menos um número de telefone."
            titleButton="Ok, entendi!"
            setModal={setModal}
          />,
        );
      } else if (
        variables.residentialPhone &&
        variables.residentialPhone.length < 10
      ) {
        setModal(
          <AlertModal
            title="Atenção!"
            description="Número do telefone inválido."
            titleButton="Ok, entendi!"
            setModal={setModal}
          />,
        );
      } else if (variables.mobilePhone && variables.mobilePhone.length < 11) {
        setModal(
          <AlertModal
            title="Atenção!"
            description="Número do celular inválido."
            titleButton="Ok, entendi!"
            setModal={setModal}
          />,
        );
      } else {
        sendItems = {
          name: variables.name,
          user: {
            id: variables.id || 1,
            residentialPhone: variables.residentialPhone || null,
            mobilePhone: variables.mobilePhone || null,
          },
          mcc: variables.mcc,
          cnae: variables.cnae,
        };

        return setModal(
          <Modal onCloseAction={() => setModal(null)}>
            <ModalSaveEdit
              handleConfirm={handleConfirm}
              sendItems={sendItems}
            />
          </Modal>,
        );
      }
    }

    if (typeEdit === 'AccessData') {
      sendItems = {
        currentPassword: btoa(variables.currentPassword),
        password: btoa(variables.newPass),
      };

      const isInvalid = Object.values(sendItems).some(
        (i) => i === null || i === '',
      );

      if (isInvalid || !variables.confirmNewpass) {
        setModal(
          <AlertModal
            title="Atenção!"
            description="Todos os campos são obrigatórios."
            titleButton="Ok, entendi!"
            setModal={setModal}
          />,
        );
      } else if (sendItems.password !== btoa(variables.confirmNewpass)) {
        setModal(
          <AlertModal
            title="Atenção!"
            description="Nova senha ou confirmação de nova senha estão incorretas."
            titleButton="Ok, entendi!"
            setModal={setModal}
          />,
        );
      } else {
        updatePassword({
          variables: sendItems,
        });
      }
    }
  }

  return (
    <>
      {modal}
      <div className="editSeller">
        <div className="editSeller__data">
          <div className="editSeller__data--title">
            <ChevronIcon onClick={() => setOpenEdit(false)} />
            <h2>Editar informações</h2>
          </div>
          {typeEdit === 'mainData' && (
            <div className="editSeller__data--input">
              <OutlinedInput
                value={variables.name}
                onChange={(e) =>
                  setVariables({ ...variables, name: e.target.value })
                }
                placeholder="Nome do fornecedor"
                border
              />
              <OutlinedInput
                value={variables.residentialPhone}
                onChange={(e) =>
                  setVariables({
                    ...variables,
                    residentialPhone: e.target.value.replace(/\D/g, ''),
                  })
                }
                placeholder="Telefone Residencial"
                mask={'(99) 9999-9999'}
                border
              />
              <OutlinedInput
                value={variables.mobilePhone}
                onChange={(e) =>
                  setVariables({
                    ...variables,
                    mobilePhone: e.target.value.replace(/\D/g, ''),
                  })
                }
                placeholder="Telefone Celular"
                border
                mask={'(99) 99999-9999'}
              />
              <OutlinedInput
                value={variables.mcc}
                onChange={(e) =>
                  setVariables({
                    ...variables,
                    mcc: e.target.value
                  })
                }
                placeholder="MCC"
                border
              />
              <OutlinedInput
              value={variables.cnae}
                onChange={(e) =>
                  setVariables({
                    ...variables,
                    cnae: e.target.value
                  })
                }
                placeholder="CNAE"
                border
            />
            </div>
          )}
          
          {typeEdit === 'AccessData' && (
            <div className="editSeller__data--inputAccess">
              <OutlinedInput
                value={variables.email}
                placeholder="E-mail"
                border
                disabled
              />
              <div className="editSeller__data--eyeContainer">
                <OutlinedInput
                  value={variables.currentPassword}
                  onChange={(e) =>
                    setVariables({
                      ...variables,
                      currentPassword: e.target.value,
                    })
                  }
                  placeholder="Senha Atual"
                  border
                  type={showPassword.currentPassword ? 'text' : 'password'}
                />
                <ShowOrHidePassword
                  actionPassword={() =>
                    setShowPassword({
                      ...showPassword,
                      currentPassword: !showPassword.currentPassword,
                    })
                  }
                />
              </div>
              <div className="editSeller__data--eyeContainer">
                <OutlinedInput
                  value={variables.newPass}
                  onChange={(e) =>
                    setVariables({ ...variables, newPass: e.target.value })
                  }
                  placeholder="Nova Senha"
                  border
                  type={showPassword.newPassword ? 'text' : 'password'}
                />
                <ShowOrHidePassword
                  actionPassword={() =>
                    setShowPassword({
                      ...showPassword,
                      newPassword: !showPassword.newPassword,
                    })
                  }
                />
              </div>
              <div className="editSeller__data--eyeContainer">
                <OutlinedInput
                  value={variables.confirmNewpass}
                  onChange={(e) =>
                    setVariables({
                      ...variables,
                      confirmNewpass: e.target.value,
                    })
                  }
                  placeholder="Confirmar Senha"
                  border
                  type={showPassword.confirmNewPassword ? 'text' : 'password'}
                />
                <ShowOrHidePassword
                  actionPassword={() =>
                    setShowPassword({
                      ...showPassword,
                      confirmNewPassword: !showPassword.confirmNewPassword,
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="editSeller__data--button">
            <Button type="submit" onClick={(e) => handleSubmit(e)}>
              Salvar
            </Button>
          </div>
        </div>
      </div>
      {(loading || loadingPass) && <Loading />}
    </>
  );
};

export default EditSeller;
