import Keycloak from "keycloak-js"; 

const redirectUri = window.location;
const keycloakJson = process.env.REACT_APP_KEYCLOAK_JSON ?? '/keycloak.json';

const initKeycloak = async () => {
  const _keycloak = Keycloak(keycloakJson);

  try {
    let initialized = await _keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false,
      checkLoginIframeInterval: 1,
      enableLogging: true,
    });

    if (initialized) {
      sessionStorage.setItem("token", _keycloak.token);

      return await updateKeycloak(_keycloak);
    }

    loginKeycloak(_keycloak);
  } catch (error) {
    console.error(`[Keycloak error]: Init keycloak`);
  }
};

const loginKeycloak = (keycloakInstance) => {
  keycloakInstance
    .login({ redirectUri })
    .then((login) => {
      if (login) console.log(`[Keycloak]: User logged`);
    })
    .catch((error) => {
      console.error(`[Keycloak error]: Failed to login`);
    });
};

const updateKeycloak = async (keycloakInstance) => {
  try {
    const refreshed = await keycloakInstance.updateToken();
    console.log(refreshed ? "Token was successfully refreshed" : "Token is still valid");

    return keycloakInstance;
  } catch (error) {
    console.error(`[Keycloak error]: Failed to refresh token`);
    keycloakInstance.logout({ redirectUri });
  }
};

export { initKeycloak, loginKeycloak, updateKeycloak };
