import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import React from 'react';
import Header from '../../../../components/Header';
import ListCommercialCondition from './components/ListCommercialCondition';

const CommercialConditionSearch = () => {
  const roles = getRoles();
  const roleCommercialCondition = roles.includes(Roles.COMMERCIAL_CONDITION_WR);
  const roleCommercialConditionReadOnly = roles.includes(Roles.COMMERCIAL_CONDITION_RO);

  if (!roleCommercialCondition && !roleCommercialConditionReadOnly) {
    window.location.href = '/';
  }

  return (
    <>
      {(roleCommercialCondition || roleCommercialConditionReadOnly) && (
        <div className="content-body">
          <Header
            role={roleCommercialCondition}
            title="Condição comercial"
            description="Use os campos abaixo para fazer a busca das condições comerciais."
            backRoute="/campanhas"
            createRoute="/campanhas/condicao-comercial/cadastro"
            titleLink="Criar nova condição"
          />
          <ListCommercialCondition role={roleCommercialCondition} />
        </div>
      )}
    </>
  );
};

export default CommercialConditionSearch;
